import MaxWidthContainer from "../../common/MaxWidthContainer"
import { theme } from "../../../../styles/stitches.config"
import { PropsWithChildren } from "react"

export function TopMenuContainer({ children }: PropsWithChildren<unknown>) {
  return (
    <MaxWidthContainer
      variant={{ "@initial": "mobile", "@lg": "top" }}
      css={{
        position: "sticky",
        zIndex: 10,

        background: `linear-gradient(0deg, ${theme.colors.transparent}, ${theme.colors.bg} 40%)`,
        top: theme.space.s0.value,
      }}
    >
      {children}
    </MaxWidthContainer>
  )
}
