import { CSSProp, fontVariants, styled, theme } from "../../styles/stitches.config"
import * as SelectPrimitives from "@radix-ui/react-select"
import { ArrowDown, ArrowUp } from "../../images/xd/icons"

const arrowStyles: CSSProp = {
  height: theme.sizes.s6,
  width: theme.sizes.s6,
}
export const SArrowDown = styled(ArrowDown, arrowStyles)
export const SArrowUp = styled(ArrowUp, arrowStyles)

const scrollButtonStyles: CSSProp = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.colors.white,
  color: theme.colors.secondary,
  cursor: "default",
  height: theme.space.s10,
}

export const SScrollDownButton = styled(SelectPrimitives.ScrollDownButton, scrollButtonStyles)
export const SScrollUpButton = styled(SelectPrimitives.ScrollUpButton, scrollButtonStyles)

const smallPadding = theme.space.s2_5
const defaultPadding = theme.space.s6

export const SIcon = styled(SelectPrimitives.Icon, {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  color: theme.colors.secondary,
  height: "100%",

  borderStyle: "solid",
  borderWidth: 0,
  borderLeftWidth: theme.space.spx,
  borderColor: theme.colors.lines,

  variants: {
    size: {
      default: {
        paddingInline: defaultPadding,
      },
      small: {
        paddingInline: smallPadding,
      },
    },
  },

  defaultVariants: {
    size: "default",
  },
})

export const SViewport = styled(SelectPrimitives.Viewport, {
  padding: theme.space.spx,
})

export const STrigger = styled(SelectPrimitives.Trigger, {
  all: "unset",
  ...fontVariants.inputs,

  paddingLeft: defaultPadding,

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.space.s6,

  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.medium,
  cursor: "pointer",

  "& >:first-child": {
    marginRight: "auto",
  },

  "&:first-child": {
    whiteSpace: "nowrap",
  },

  variants: {
    isFullWidth: {
      true: {
        // subtracting padding from 100% because radix-ui primitives internally somehow modify the trigger width when side icon is present
        // when setting just 100%, the select is wider than the parent container exactly by the size of padding
        width: "calc(100% - $$sidePadding)",
      },
    },

    size: {
      default: {
        $$sidePadding: defaultPadding,

        height: theme.sizes.s14,
        width: theme.space.s75,

        ...fontVariants.inputs,
      },
      small: {
        $$sidePadding: smallPadding,

        height: theme.sizes.s10_5,
        width: "initial",

        ...fontVariants.textsSmall,
      },
    },
  },

  defaultVariants: {
    size: "default",
  },
})

export const SContent = styled(SelectPrimitives.Content, {
  overflow: "hidden",
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.medium,
})

export const SItem = styled(SelectPrimitives.Item, {
  display: "flex",
  alignItems: "center",
  width: "100%",

  hover: {
    cursor: "pointer",
  },

  "&[data-disabled]": {
    pointerEvents: "none",
    ...fontVariants.placeholders,
  },

  '&[data-state="checked"]': {
    backgroundColor: theme.colors.secondary,
  },

  variants: {
    size: {
      default: {
        ...fontVariants.inputs,

        paddingLeft: defaultPadding,
        paddingRight: defaultPadding,

        height: theme.space.s14,
      },
      small: {
        ...fontVariants.textsSmall,

        paddingLeft: defaultPadding,
        paddingRight: smallPadding,

        height: theme.space.s10_5,
      },
    },
  },

  defaultVariants: {
    size: "default",
  },
})

export const SGroupLabel = styled(SelectPrimitives.Label, {
  width: "100%",

  paddingLeft: theme.space.s6,
  paddingTop: theme.space.s2,
  paddingBottom: theme.space.s2,

  ...fontVariants.headerH4,
})

export const SLabel = styled("label", {
  ...fontVariants.headerH5,
  marginLeft: theme.space.s6,
})

export const SContainer = styled("div", {
  width: "100%",
})
