import * as Modal from "@radix-ui/react-dialog"
import { useTranslation } from "react-i18next"
import { Button } from "../../../../components/button/Button"
import { Flex } from "../../../../components/flex/Flex"
import { Spacer } from "../../../../components/spacer/Spacer"
import Text from "../../../../components/Text"
import { theme } from "../../../../styles/stitches.config"
import { SImage } from "./PasswordEditSuccess.styled"

export default function PasswordEditSuccess() {
  const { t } = useTranslation(["auth", "common"])

  return (
    <>
      <Modal.Title asChild>
        <Text type="headerH1">{t("auth:profile_password_edit_header")}</Text>
      </Modal.Title>

      <Flex direction="column" align="center" css={{ gap: theme.space.s4 }}>
        <SImage />

        <Modal.Description>
          <Text type="textsSmall">{t("auth:profile_password_edit_success_description")}</Text>
        </Modal.Description>
      </Flex>

      <Spacer size={theme.space.s6} />

      <Modal.Close asChild>
        <Button isFullwidth>{t("common:continue")}</Button>
      </Modal.Close>
    </>
  )
}
