import { PropsWithChildren } from "react"
import { trpc } from "../api/trpc/trpc"
import PolicyConsent from "../pages/policyConsent/PolicyConsent"
import LoadingPage from "../pages/Loading"

export function ConsentRoute(props: PropsWithChildren<unknown>) {
  const { children } = props

  const consentsQuery = trpc.useQuery(["users.getConsents"])
  const userQuery = trpc.useQuery(["users.myself"])

  const updateConsentMutation = trpc.useMutation(["users.updateConsent"])

  if (
    consentsQuery.isLoading ||
    consentsQuery.isError ||
    !consentsQuery.data ||
    userQuery.isLoading ||
    userQuery.isError ||
    !userQuery.data
  ) {
    return <LoadingPage />
  }

  const consentGdpr = consentsQuery.data.find((c) => c.consentType === "GDPR")
  const gdprAgreed = consentGdpr && consentGdpr.disagreedAt === null

  const consentTermsAndConditions = consentsQuery.data.find((c) => c.consentType === "TERMS_AND_CONDITIONS")
  const termsAndConditionsAgreed = consentTermsAndConditions && consentTermsAndConditions.disagreedAt === null

  const onConsentsAgree = async () => {
    await updateConsentMutation.mutateAsync({ agree: true, type: "GDPR" })
    await updateConsentMutation.mutateAsync({ agree: true, type: "TERMS_AND_CONDITIONS" })
    await consentsQuery.refetch()
  }

  // gdpr and conditions not agreed and user is not logged in from backoffice (shadow login)
  if ((!gdprAgreed || !termsAndConditionsAgreed) && !userQuery.data.externalUserId) {
    return (
      <PolicyConsent
        onAgree={() => void onConsentsAgree()}
        gdprChecked={!!gdprAgreed}
        termsAndConditionChecked={!!termsAndConditionsAgreed}
      />
    )
  }

  return <>{children}</>
}
