import { styled, theme } from "../../styles/stitches.config"

const SButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: theme.space.s9,
  height: theme.space.s9,

  border: "unset",
})

export const SArrowButton = styled(SButton, {
  boxShadow: theme.shadows.default,

  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        backgroundColor: theme.colors.bg,
        cursor: "default",
      },
      false: {
        backgroundColor: theme.colors.secondary,
        cursor: "pointer",
      },
    },
  },

  defaultVariants: {
    disabled: false,
  },
})

export const SPageButton = styled(SButton, {
  cursor: "pointer",

  backgroundColor: theme.colors.transparent,

  variants: {
    disabled: {
      true: {
        cursor: "default",
      },
      false: {
        cursor: "pointer",
      },
    },
  },

  defaultVariants: {
    disabled: false,
  },
})
