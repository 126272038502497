import { memo, ReactNode } from "react"
import * as HoverCardPrimitive from "@radix-ui/react-hover-card"
import Circle from "./components/Circle"
import { Spacer } from "../spacer/Spacer"
import Text from "../Text"
import InfoBox from "../info/InfoBox"
import { ColorType, IconType, styled, theme } from "../../styles/stitches.config"
import * as icons from "../../images/xd/icons"
import { useTouchTooltip } from "../../hooks/useTouchTooltip"

const SItemContainer = styled("div", {
  display: "flex",
  alignItems: "center",
})

const SInlineTooltipContent = styled(HoverCardPrimitive.Content, {
  backgroundColor: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.default})`,
  padding: theme.space.s6,
  maxWidth: `80vw`,
})

export const STooltipArrow = styled(HoverCardPrimitive.Arrow, {
  fill: theme.colors.white,
  width: theme.space.s5,
  height: theme.space.s4,
})

export interface LegendItem {
  icon?: IconType
  color: ColorType
  label: string
  tooltipContent?: ReactNode
  onClick?: () => void
  hidden?: boolean
  tooltipType?: "infoBox" | "inline"
}

const ChartLegendItem = ({ icon, color, label, hidden, onClick, tooltipContent }: Omit<LegendItem, "tooltipType">) => {
  const Icon = icon ? icons[icon] : null
  return (
    <SItemContainer key={label} onClick={onClick} css={onClick ? { cursor: "pointer" } : undefined}>
      {Icon ? (
        <Icon color={theme.colors[color].value} opacity={hidden ? 0.3 : 1} height={"12px"} />
      ) : (
        <Circle color={hidden ? "white" : color} borderColor={hidden ? color : undefined} />
      )}
      <Spacer size={theme.space.s4} />
      <Text type="labelsAlt">{label}</Text>
      {tooltipContent && (
        <>
          <Spacer size={theme.space.s2} />
          <InfoBox variant="default">{tooltipContent}</InfoBox>
        </>
      )}
    </SItemContainer>
  )
}

const LegendItemWithInlineTooltip = ({
  tooltipContent,
  alignEnd,
  ...props
}: Omit<LegendItem, "tooltipType"> & { alignEnd?: boolean }) => {
  const { open, handleToggle } = useTouchTooltip()
  const openDelay = 150
  const offset = 8

  return (
    <HoverCardPrimitive.Root openDelay={openDelay} open={open} onOpenChange={handleToggle}>
      <HoverCardPrimitive.Trigger onTouchEnd={handleToggle}>
        <ChartLegendItem {...props} />
      </HoverCardPrimitive.Trigger>
      <SInlineTooltipContent sideOffset={offset} avoidCollisions side="top" align={alignEnd ? "end" : "center"}>
        <STooltipArrow offset={offset} />
        {tooltipContent}
      </SInlineTooltipContent>
    </HoverCardPrimitive.Root>
  )
}

interface Props {
  items: LegendItem[]
}

const ChartLegend = ({ items }: Props) => {
  return (
    <>
      {items.map(({ tooltipType = "infoBox", ...props }, index) =>
        tooltipType === "infoBox" ? (
          <ChartLegendItem {...props} key={props.label} />
        ) : (
          <LegendItemWithInlineTooltip {...props} alignEnd={index === items.length - 1} key={props.label} />
        )
      )}
    </>
  )
}

export default memo(ChartLegend)
