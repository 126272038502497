import { theme } from "../../../../../styles/stitches.config"
import { SupplyPoints } from "../../../../../images/xd/icons"
import DeliveryPointDetails from "../DeliveryPointDetails"
import { Flex } from "../../../../flex/Flex"
import { DeliveryPointType } from "@nano-portal/shared"

interface Props {
  deliveryPointId: number
  type: DeliveryPointType[]
  ean: string
  address: string
  isDeliveryPointActive: boolean
  isSelected: boolean
  onClick?: (id: number) => void
}

export default function SelectItem({
  deliveryPointId,
  type,
  ean,
  address,
  isDeliveryPointActive,
  isSelected,
  onClick,
}: Props) {
  const interactiveCss = onClick ? { hover: { cursor: "pointer" } } : undefined

  return (
    <Flex
      css={{ gap: theme.space.s4, ...interactiveCss, minWidth: theme.sizes.s64 }}
      onClick={() => onClick?.(deliveryPointId)}
    >
      <SupplyPoints
        width={theme.space.s6.value}
        color={isDeliveryPointActive ? theme.colors.textsAlt.value : theme.colors.textsDisabeled.value}
      />
      <DeliveryPointDetails
        ean={ean}
        type={type}
        address={address}
        isActive={isDeliveryPointActive}
        isSelected={isSelected}
      />
    </Flex>
  )
}
