import * as React from "react"

function SvgNoGraphData(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoGraphData_svg__a">
          <path data-name="Rectangle 16833" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9141" clipPath="url(#_NoGraphData_svg__a)">
        <path
          data-name="Path 1989"
          d="M15.898 17.709H3.582V5.534a.659.659 0 00-1.317 0v12.83a.658.658 0 00.659.659h12.974a.659.659 0 100-1.317"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1990"
          d="M17.623 7.3a.658.658 0 00-.931 0l-4.733 4.733-1.793-1.792a.658.658 0 00-.931 0l-4.348 4.348a.658.658 0 10.931.931l3.883-3.883 1.795 1.795a.659.659 0 00.931 0l5.2-5.2a.658.658 0 000-.931"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1991"
          d="M20.167 18.465l1.717-1.717a.603.603 0 00-.856-.85l-1.716 1.713-1.716-1.716-.013-.012a.605.605 0 00-.855.857l1.729 1.728-1.716 1.715-.012.013a.605.605 0 00.855.857l1.729-1.728 1.715 1.716.012.012a.606.606 0 10.861-.852h-.005z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgNoGraphData
