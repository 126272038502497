import * as React from "react"

function SvgLeaves2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.092 289.876" {...props}>
      <defs>
        <clipPath id="_Leaves2_svg__a">
          <path data-name="Rectangle 509" fill="none" d="M0 0h207.092v289.876H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1146" clipPath="url(#_Leaves2_svg__a)">
        <path
          data-name="Path 366"
          d="M176.516 282.619a4.98 4.98 0 01-2.5-.675c-43.939-25.449-75.662-68.144-89.323-120.212C71.072 109.819 76.684 53.4 100.5 2.869a5 5 0 016.863-2.286c44.44 23.573 76.952 64.842 91.551 116.207 14.563 51.244 9.56 106.5-14.089 155.6a305.468 305.468 0 01-3.846 7.534 4.967 4.967 0 01-2.126 2.112 5.026 5.026 0 01-2.337.578m-69.19-270.64c-42.9 96.68-13.858 208.313 67.218 258.623.442-.876.872-1.735 1.291-2.586 45.646-94.768 14.832-208.353-68.509-256.037"
          fill="#00515f"
        />
        <path
          data-name="Path 367"
          d="M114.587 289.876c-42.82 0-86.039-24.511-110.361-66.636a162.729 162.729 0 01-3.668-6.718 5 5 0 012.151-6.735 5.117 5.117 0 01.585-.257C64.226 183.084 137.36 204 181.25 260.471a5 5 0 01-.894 7.026 109.35 109.35 0 01-19.141 11.844 107.034 107.034 0 01-46.628 10.535M12.038 216.75q.417.744.849 1.491c30.765 53.286 94.01 76.172 143.979 52.1a99.324 99.324 0 0013.263-7.743c-40.183-48.4-103.814-66.824-158.091-45.843"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgLeaves2
