import { httpLink } from "@trpc/client/links/httpLink"
import "normalize.css"
import { Suspense, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import superjson from "superjson"
import { trpc, TRPC_URL } from "./api/trpc/trpc"
import LoadingPage from "./pages/Loading"
import { Router } from "./Router"
import { defaultGlobalStyles } from "./styles/stitches.config"
import { useDefaultZodErrorMap } from "./utils/zod"
import { z } from "zod"
import { ModalProvider } from "./components/ModalProvider"
import { isDate } from "lodash"
import { formatIsoDateTime } from "./utils/date"
import { useGlobalErrorHandler } from "./utils/error"

// custom date serialization to avoid timezones issues
superjson.registerCustom<Date, string>(
  {
    isApplicable: (v): v is Date => isDate(v),
    serialize: (v) => formatIsoDateTime(v),
    deserialize: (v) => {
      // TODO bez toho T to nefunguje v safari
      return new Date(v.replace(" ", "T"))
    },
  },
  "Date"
)

export function App() {
  defaultGlobalStyles()

  const { i18n } = useTranslation("common")
  const onError = useGlobalErrorHandler()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError,
            retry: 1,
          },
          mutations: {
            onError,
          },
        },
      }),
    [onError]
  )

  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [httpLink({ url: TRPC_URL })],
    })
  )

  const errorMap = useDefaultZodErrorMap()
  useEffect(() => {
    z.setErrorMap(errorMap)
  }, [errorMap, i18n.language])

  return (
    <>
      <Suspense fallback={<LoadingPage />}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <ModalProvider>
              <Router />
            </ModalProvider>
          </QueryClientProvider>
        </trpc.Provider>
      </Suspense>

      <ToastContainer />
    </>
  )
}
