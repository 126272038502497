import { styled, theme } from "../../../../../styles/stitches.config"
import * as Dropdown from "@radix-ui/react-dropdown-menu"

export default styled(Dropdown.Item, {
  display: "flex",
  alignItems: "center",
  gap: theme.space.s4,
  hover: {
    cursor: "pointer",
  },
})
