import * as React from "react"

function SvgPayments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 50" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 51"
        d="M20 4H4a1.985 1.985 0 00-1.99 2L2 18a1.993 1.993 0 002 2h16a1.993 1.993 0 002-2V6a1.993 1.993 0 00-2-2zm-1 14H5a1 1 0 01-1-1v-5h16v5a1 1 0 01-1 1zm1-10H4V6h16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPayments
