import * as React from "react"

function SvgOpenInNew(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 52657" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 52658"
        d="M18 19H6a1 1 0 01-1-1V6a1 1 0 011-1h5a1 1 0 000-2H5a2 2 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2v-6a1 1 0 00-2 0v5a1 1 0 01-1 1zM14 4a1 1 0 001 1h2.59l-9.13 9.13a1 1 0 001.41 1.41L19 6.41V9a1 1 0 002 0V4a1 1 0 00-1-1h-5a1 1 0 00-1 1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgOpenInNew
