import * as React from "react"

function SvgTurnOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 135" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 136"
        d="M12 3a1 1 0 00-1 1v8a1 1 0 102 0V4a1 1 0 00-1-1zm5.14 2.86a.99.99 0 00-.01 1.39A6.864 6.864 0 0119 11.82a7.084 7.084 0 01-6.91 7.17A6.991 6.991 0 016.87 7.24a.99.99 0 10-1.44-1.36A8.919 8.919 0 003 11.74a9.1 9.1 0 008.71 9.25 9 9 0 009.29-9 8.891 8.891 0 00-2.42-6.11 1 1 0 00-1.44-.02z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTurnOff
