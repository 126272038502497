import { SContainer } from "./SideMenu.styled"
import SideMenuContent from "./SideMenuContent"

function SideMenu() {
  return (
    <SContainer>
      <SideMenuContent />
    </SContainer>
  )
}

export default SideMenu
