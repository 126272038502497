import * as React from "react"

function SvgFaceGood(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_FaceGood_svg__a">
          <path
            data-name="Rectangle 17084"
            transform="translate(-2907 947)"
            fill="#8ee57e"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 25" transform="translate(2907 -947)" clipPath="url(#_FaceGood_svg__a)">
        <path
          data-name="Union 50"
          d="M-2906 971.261a23.261 23.261 0 1123.261 23.261A23.287 23.287 0 01-2906 971.261zm2.2 0a21.06 21.06 0 1021.061-21.061 21.084 21.084 0 00-21.061 21.061zm16.035-1.294a1.1 1.1 0 111.556-1.557 4.912 4.912 0 006.938 0 1.1 1.1 0 011.556 1.557 7.115 7.115 0 01-10.05 0zm18.006-1.227a2.208 2.208 0 112.208 2.208 2.208 2.208 0 01-2.207-2.209zm-30.378 0a2.208 2.208 0 112.208 2.208 2.208 2.208 0 01-2.207-2.209z"
          fill="#8ee57e"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgFaceGood
