import { useTranslation } from "react-i18next"
import {
  AmountCell,
  AmountCellValue,
  DateIconCell,
  DateIconCellValue,
  IconCell,
  IconCellValue,
  TextCellValue,
  TextCell,
  DateRangeCellValue,
  MultiActionCellValue,
  MultiActionCell,
  DateRangeCell,
} from "../../components/table/renderers"
import TableComponent, { ColumnAccessors, TableColumn, TableRow } from "../../components/table/Table"
import EmptyTablePlaceholder from "../../components/table/EmptyTablePlaceholder"
import { theme } from "../../styles/stitches.config"
import { useMemo } from "react"

export type PaymentsTableData = {
  colDueDate: DateIconCellValue | null
  colPeriod?: DateRangeCellValue | null
  colAmount?: AmountCellValue | null
  colState: IconCellValue | null
  colAction: MultiActionCellValue | null
  colType: TextCellValue
}

interface Props {
  data: TableRow<PaymentsTableData>[]
}

const minimalViewColumns: ColumnAccessors<PaymentsTableData> = ["colDueDate", "colAmount", "colAction"]

const PaymentsTable = ({ data }: Props) => {
  const { t } = useTranslation("payments")

  const columns = useMemo<TableColumn<PaymentsTableData>[]>(
    () => [
      {
        Header: t("due_date"),
        accessor: "colDueDate", // accessor is the "key" in the data
        Cell: DateIconCell,
      },
      {
        Header: t("period"),
        accessor: "colPeriod",
        Cell: DateRangeCell,
      },
      {
        Header: t("amount"),
        accessor: "colAmount",
        Cell: AmountCell,
      },
      {
        Header: t("state"),
        accessor: "colState",
        Cell: IconCell,
      },
      {
        Header: t("type"),
        accessor: "colType",
        Cell: TextCell,
      },
      {
        Header: "",
        accessor: "colAction",
        Cell: MultiActionCell,
      },
    ],
    [t]
  )

  return (
    <TableComponent
      data={data}
      columns={columns}
      minimalViewColumns={minimalViewColumns}
      headerAlignment="center"
      emptyPlaceholder={
        <EmptyTablePlaceholder
          css={{ height: theme.sizes.s90.value }}
          icon={"NoInvoices"}
          mainText={t("you_have_no_payments")}
          explanationText={t("you_have_no_payments_explanation")}
        />
      }
    />
  )
}

export default PaymentsTable
