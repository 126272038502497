import { STablePlaceholderContainer } from "./Table.styled"
import { ColorType, IconType, theme } from "../../styles/stitches.config"
import { Text } from "../Text"
import * as icons from "../../images/xd/icons"
import { Spacer } from "../spacer/Spacer"

export interface EmptyTablePlaceholderProps extends React.ComponentProps<typeof STablePlaceholderContainer> {
  icon?: IconType
  mainText?: string
  explanationText?: string
  iconColor?: ColorType
}

function EmptyTablePlaceholder({
  icon,
  iconColor: color,
  mainText,
  explanationText,
  ...props
}: EmptyTablePlaceholderProps) {
  const Icon = icon ? icons[icon] : undefined
  const iconColor = color ? theme.colors[color].value : undefined

  return (
    <STablePlaceholderContainer {...props}>
      {Icon && <Icon width={theme.sizes.s40.value} color={iconColor} />}
      <Spacer size={theme.space.s4} />
      {mainText && <Text type="headerH2Neutral"> {mainText}</Text>}
      <Spacer size={theme.space.s4} />
      {explanationText && <Text type="textsLarge"> {explanationText}</Text>}
    </STablePlaceholderContainer>
  )
}

export default EmptyTablePlaceholder
