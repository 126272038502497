import * as React from "react"

function SvgSipo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_SIPO_svg__a">
          <path data-name="Rectangle 420" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 815" clipPath="url(#_SIPO_svg__a)" fill="currentColor">
        <path
          data-name="Path 322"
          d="M12.764 10.945c-1.668-.553-2.187-.825-2.187-1.459 0-.722.838-.991 1.367-.991a1.453 1.453 0 011.231.591 1.021 1.021 0 001.8-.357.986.986 0 00-.175-.859 3.269 3.269 0 00-1.878-1.213V6.53a1.036 1.036 0 10-2.071 0v.134A2.979 2.979 0 008.515 9.5c0 2.1 1.937 2.767 2.978 3.128l.178.063c1.4.5 1.871.9 1.871 1.577 0 .939-.954 1.189-1.517 1.189a1.816 1.816 0 01-1.674-1.092 1.04 1.04 0 00-1.3-.454 1.069 1.069 0 00-.6.625.975.975 0 00.036.793 3.82 3.82 0 002.375 1.954v.182a1.036 1.036 0 102.071 0v-.125a3.139 3.139 0 002.7-3.081c0-1.611-.88-2.633-2.859-3.318"
        />
        <path
          data-name="Path 323"
          d="M13.188 19.629A7.72 7.72 0 1119.719 12a1 1 0 002 0 9.71 9.71 0 10-8.226 9.606 1 1 0 10-.305-1.977"
        />
        <path
          data-name="Path 324"
          d="M19.961 19.8a.531.531 0 00-.905.378v.383a2.137 2.137 0 01-1.807-2.108 2.065 2.065 0 01.157-.8.72.72 0 00-.165-.782.739.739 0 00-1.206.248 3.54 3.54 0 00-.257 1.332 3.609 3.609 0 003.278 3.589v.422a.53.53 0 00.331.493.539.539 0 00.2.039.525.525 0 00.374-.157L21.1 21.7a.528.528 0 000-.753z"
        />
        <path
          data-name="Path 325"
          d="M19.708 14.867v-.422a.528.528 0 00-.9-.374l-1.148 1.142a.53.53 0 000 .753l1.14 1.148a.536.536 0 00.581.107.531.531 0 00.323-.49v-.382a2.135 2.135 0 011.807 2.108 2.067 2.067 0 01-.155.8.717.717 0 00.164.784.738.738 0 001.205-.247 3.54 3.54 0 00.257-1.332 3.61 3.61 0 00-3.278-3.59"
        />
      </g>
    </svg>
  )
}

export default SvgSipo
