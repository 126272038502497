import { useMemo } from "react"
import Text, { TextType } from "../../../components/Text"
import { InferQueryOutput } from "../../../api/trpc/trpcHelper"
import { useTranslation } from "react-i18next"
import { formatDate } from "../../../utils/date"
import { SPaymentsLink, STooltipDepositStateContainer, STooltipSingleDepositState } from "./TooltipDepositState.styled"
import { DueDateStateEnum, mapDueDateState } from "../../../utils/payments"
import { assertUnreachable } from "../../../utils/types"
import Separator from "../../../components/Separator"
import { Flex } from "../../../components/flex/Flex"
import { Div } from "../../../components/div/Div"
import { Spacer } from "../../../components/spacer/Spacer"
import { theme } from "../../../styles/stitches.config"
import { ArrowForward } from "../../../images/xd/icons"

type DeliveryPointAdvancesState = NonNullable<
  InferQueryOutput<"deliveryPoints.deliveryPointPaymentState">["advancesState"]
>

type Props = {
  advancesState: DeliveryPointAdvancesState
  paymentsLink: string
}

const TooltipSingleDepositState = ({ state }: { state: NonNullable<DeliveryPointAdvancesState["current"]> }) => {
  const { t } = useTranslation(["delivery_point", "payments", "common"])

  const [text, textType] = useMemo((): [string, TextType] => {
    const dueDateState = mapDueDateState(state.dueDate)

    if (state.amountToPair !== 0) {
      switch (dueDateState.state) {
        case DueDateStateEnum.OK:
          return [t("delivery_point:supply_point_future_due_date"), "headerH5Neutral"]
        case DueDateStateEnum.PAST:
          return [t("payments:days_after_payment", { count: dueDateState.days }), "headerH5Negative"]
        case DueDateStateEnum.FUTURE:
          return [
            t("delivery_point:supply_point_overview_advance_days_before_payment", { count: dueDateState.days }),
            "headerH5Warning",
          ]
        default:
          assertUnreachable(dueDateState.state)
      }
    }

    return [t("delivery_point:supply_point_overview_advance_paid"), "headerH5Positive"]
  }, [t, state])

  return (
    <STooltipSingleDepositState>
      <Text type="labelsAlt">{t("common:due_date")}</Text>
      <Text type="headerH3">{formatDate(state.dueDate)}</Text>
      <Text type={textType}>{text}</Text>
    </STooltipSingleDepositState>
  )
}

const TooltipDepositState = ({ advancesState, paymentsLink }: Props) => {
  const { t } = useTranslation(["delivery_point"])
  return (
    <Div>
      <STooltipDepositStateContainer>
        <>
          {advancesState.previous && (
            <>
              <TooltipSingleDepositState state={advancesState.previous} />
              <Flex direction="column">
                <Separator orientation="vertical" />
              </Flex>
            </>
          )}
          {advancesState.current && <TooltipSingleDepositState state={advancesState.current} />}
          {advancesState.next && (
            <>
              <Flex direction="column">
                <Separator orientation="vertical" />
              </Flex>
              <TooltipSingleDepositState state={advancesState.next} />
            </>
          )}
        </>
      </STooltipDepositStateContainer>
      <Spacer size={theme.space.s4} />
      <Separator orientation="horizontal" />
      <Spacer size={theme.space.s2} />

      <Text type="labelsAlt">
        <SPaymentsLink to={paymentsLink}>
          {t("delivery_point:supply_points_deposit_state_payments_link")}
          <ArrowForward width={20} />
        </SPaymentsLink>
      </Text>
    </Div>
  )
}

export default TooltipDepositState
