import { ComponentProps, ReactNode, useMemo } from "react"
import InfoCard from "../infoCard/InfoCard"
import { useTranslation } from "react-i18next"
import { SContainer, SInfoCard } from "./ContractInfo.styled"
import SubSection from "../../../../components/subSection/SubSection"
import { useFormatDeliveryPointStatus } from "../../../../utils/format"
import { DeliveryPointStatus } from "@nano-portal/shared"
import { SubSectionTitle } from "../../../../components/subSection/SubSection.styled"

type ContractInfoContentParams = {
  contractNumber: number | string
  status: DeliveryPointStatus
  product?: string
}

const useContractInfoContent = (params: ContractInfoContentParams) => {
  const { t } = useTranslation("delivery_point")
  const formatStatus = useFormatDeliveryPointStatus()
  const header = t("contract_info_header")
  const data: ComponentProps<typeof InfoCard>["data"] = useMemo(
    () => [
      {
        label: t("contract_info_number"),
        value: params.contractNumber,
      },
      {
        label: t("contract_info_state"),
        value: params.status as ReactNode,
        formatter: formatStatus as (v: ReactNode) => ReactNode,
      },
      {
        label: t("contract_info_product"),
        value: params.product,
      },
    ],
    [params, t, formatStatus]
  )

  return [header, data] as const
}

type ContractInfoProps = ContractInfoContentParams

const ContractInfo = (props: ContractInfoProps) => {
  const [header, data] = useContractInfoContent(props)

  return (
    <SContainer>
      <SubSection
        title={<SubSectionTitle>{header}</SubSectionTitle>}
        content={<SInfoCard data={data} />}
        width="fixed"
      />
    </SContainer>
  )
}

export default ContractInfo
