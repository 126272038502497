import { Fragment, useRef } from "react"
import ContentLoader from "react-content-loader"
import useMeasureDirty from "react-use/lib/useMeasureDirty"

const rows = 5
const headerHeight = 33
const rowHeight = 56
const margin = 8

const TablePlaceholder = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useMeasureDirty(ref)

  const height = headerHeight + margin + (rowHeight + margin) * rows

  return (
    <div ref={ref} style={{ width: "100%", height }}>
      <ContentLoader viewBox={`0 0 ${width} ${height}`}>
        <rect x="0" y="0" width={width} height={headerHeight} />

        {Array.from({ length: rows })
          .fill(" ")
          .map((_, index) => {
            return (
              <Fragment key={index}>
                <rect x="0" y={headerHeight + margin + index * (rowHeight + margin)} width={width} height={rowHeight} />
              </Fragment>
            )
          })}
      </ContentLoader>
    </div>
  )
}

export default TablePlaceholder
