import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Div } from "../../components/div/Div"
import { Flex } from "../../components/flex/Flex"
import { AuthLayout } from "../../components/layouts/authLayout/AuthLayout"
import { Spacer } from "../../components/spacer/Spacer"
import Text from "../../components/Text"
import { LINKS } from "../../constants/links"
import { theme } from "../../styles/stitches.config"
import { SLogo } from "./ForgotPasswordSuccessContent.styled"

export function ForgotPasswordSuccessContent() {
  const { t } = useTranslation("auth")

  return (
    <AuthLayout>
      <Div css={{ width: theme.space.s80 }}>
        <Flex justify="center">
          <SLogo />
        </Flex>

        <Spacer size={theme.space.s14} />

        <Text type="textsSmall">{t("reset_password_success_text")}</Text>

        <Spacer size={theme.space.s14} />

        <Link to={LINKS.authLogin}>
          <Text type="headerH4">{t("reset_password_success_login_link")}</Text>
        </Link>
      </Div>
    </AuthLayout>
  )
}
