import * as React from "react"

function SvgWaiting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 50">
        <path data-name="Path 42" d="M0 0h24v24H0z" fill="none" />
      </g>
      <g data-name="Group 51">
        <path
          data-name="Path 43"
          d="M11.99 2A10 10 0 1022 12 10 10 0 0011.99 2zM16 16a1 1 0 01-1.41 0l-3.29-3.29A1 1 0 0111 12V8a1 1 0 011-1 1 1 0 011 1v3.59l3 3A1 1 0 0116 16z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgWaiting
