export const variants = {
  buttons: {
    color: "rgb(0,81,95,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  disabledButtons: {
    color: "rgb(197,211,214,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  headerH1: {
    color: "rgb(0,81,95,1)",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  headerH2: {
    color: "rgb(0,81,95,1)",
    fontSize: "21px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH2Negative: {
    color: "rgb(236,93,92,1)",
    fontSize: "21px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH2Neutral: {
    color: "rgb(124,167,173,1)",
    fontSize: "21px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH2Positive: {
    color: "rgb(12,171,155,1)",
    fontSize: "21px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH3: {
    color: "rgb(0,81,95,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH3Negative: {
    color: "rgb(236,93,92,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH3Neutral: {
    color: "rgb(124,167,173,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH3Positive: {
    color: "rgb(12,171,155,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH3Warning: {
    color: "rgb(255,152,74,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH4: {
    color: "rgb(0,81,95,1)",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH5: {
    color: "rgb(0,81,95,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 700,
  },
  headerH5Negative: {
    color: "rgb(236,93,92,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  headerH5Neutral: {
    color: "rgb(124,167,173,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  headerH5Positive: {
    color: "rgb(12,171,155,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  headerH5Warning: {
    color: "rgb(255,152,74,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  inputs: {
    color: "rgb(117,117,117,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  labelsAlt: {
    color: "rgb(124,167,173,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  menuItem: {
    color: "rgb(124,167,173,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  menuItemActive: {
    color: "rgb(255,194,0,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  placeholders: {
    color: "rgb(197,211,214,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  tagDark: {
    color: "rgb(0,81,95,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  tagLight: {
    color: "rgb(255,255,255,1)",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  textsLarge: {
    color: "rgb(124,167,173,1)",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  textsSmall: {
    color: "rgb(117,117,117,1)",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
};
export const colors = {
  bg: "rgb(246,248,249,1)",
  currentColor: "rgb(0,255,255,1)",
  errors: "rgb(236,93,92,1)",
  errorsHover: "rgb(203,46,45,1)",
  errorsLight: "rgb(255,210,197,1)",
  lines: "rgb(238,244,246,1)",
  primary: "rgb(0,81,95,1)",
  secondary: "rgb(255,194,0,1)",
  successHover: "rgb(36,119,111,1)",
  successes: "rgb(12,171,155,1)",
  successesLight: "rgb(189,243,238,1)",
  texts: "rgb(117,117,117,1)",
  textsAlt: "rgb(124,167,173,1)",
  textsDisabeled: "rgb(197,211,214,1)",
  warning: "rgb(255,152,74,1)",
  warningHover: "rgb(214,104,20,1)",
  warningLight: "rgb(255,234,197,1)",
  white: "rgb(255,255,255,1)",
};
