import { styled, theme } from "../../../../styles/stitches.config"
import * as Toolbar from "@radix-ui/react-toolbar"

export const SContainer = styled(Toolbar.Root, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: theme.space.s14,

  paddingRight: theme.space.s4,

  "@lg": {
    paddingLeft: theme.space.s6,
    paddingRight: theme.space.s0,
    height: theme.space.s17,
  },
})
