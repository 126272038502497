import { useTranslation } from "react-i18next"
import * as zod from "zod"
import { usePasswordSchema } from "../../utils/zod"

export function useResetPasswordFormSchema() {
  const { t } = useTranslation("validation")
  const passwordSchema = usePasswordSchema()

  const schema = zod
    .object({
      password: passwordSchema,
      passwordAgain: zod.string().min(1),
    })
    .refine(({ password, passwordAgain }) => password === passwordAgain, {
      message: t("passwords_must_match"),
      path: ["passwordAgain"],
    })

  return { schema }
}

export type ResetPasswordFormValues = zod.infer<ReturnType<typeof useResetPasswordFormSchema>["schema"]>
