import { styled, theme } from "../../styles/stitches.config"

export const SContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s6,

  "@lg": {
    gap: theme.space.s10_5,
  },
})

export const SContractInfo = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s4,
  maxWidth: theme.space.s220,

  "@md": {
    flexDirection: "row",
  },
})
