import * as React from "react"

function SvgFaceBest(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_FaceBest_svg__a">
          <path
            data-name="Rectangle 17083"
            transform="translate(-2907 894)"
            fill="#0cab9b"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 21" transform="translate(2907 -894)" clipPath="url(#_FaceBest_svg__a)">
        <path
          data-name="Union 29"
          d="M-2906 918a23 23 0 1123 23 23.026 23.026 0 01-23-23zm2.176 0A20.824 20.824 0 10-2883 897.176 20.847 20.847 0 00-2903.824 918zm13.8-4.677a1.088 1.088 0 011.088-1.088h11.875a1.088 1.088 0 011.088 1.088 7.027 7.027 0 11-14.054 0zm7.024 4.851a4.859 4.859 0 004.727-3.762h-9.454a4.859 4.859 0 004.727 3.762zm16.2-5.864a1.672 1.672 0 00-2.362 0 1.088 1.088 0 11-1.538-1.54 3.846 3.846 0 015.439 0 1.088 1.088 0 11-1.539 1.539zm-30.037 0a1.671 1.671 0 00-2.362 0 1.089 1.089 0 11-1.539-1.54 3.846 3.846 0 015.439 0 1.088 1.088 0 11-1.539 1.539z"
          fill="#0cab9b"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgFaceBest
