import { IconCopy } from "@tabler/icons-react"
import { styled, theme } from "../../styles/stitches.config"
import { InfoOutlined } from "../../images/xd/icons"

export const SPaymentInfo = styled("div", {
  width: "100%",
  "@sm": {
    width: theme.space.s105,
  },
})

export const SPaymentAccountInfo = styled("div", {
  display: "flex",
  width: "100%",

  border: `${theme.sizes.spx} solid ${theme.colors.textsAlt}`,

  paddingInline: theme.space.s9_5,
  paddingBlock: theme.space.s7,

  flexDirection: "column",
  gap: theme.space.s2,

  "@sm": {
    flexDirection: "row",
    gap: theme.space.s0,
  },
})

export const SAdvanceInfoCard = styled("div", {
  width: "100%",

  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  paddingInline: theme.space.s9_5,
  paddingBlock: theme.space.s7,
})

export const SAdvanceInfo = styled("div", {
  display: "flex",
  width: "100%",

  flexDirection: "column",
  gap: theme.space.s2,

  "@sm": {
    flexDirection: "row",
    gap: theme.space.s0,
  },
})

export const PaymentInfoCell = styled("div", {
  flexShrink: 0,
  textAlign: "start",
  "@sm": {
    width: "50%",
  },

  variants: {
    position: {
      relative: {
        position: "relative",
      },
      default: {
        position: "initial",
      },
    },
  },

  defaultVariants: {
    position: "default",
  },
})

export const SIconCopy = styled(IconCopy, {
  width: theme.sizes.s3_5,
  color: theme.colors.textsAlt.value,
})

export const SInfoIcon = styled(InfoOutlined, {
  color: theme.colors.textsAlt,

  width: theme.sizes.s4_5,
  position: "absolute",
  top: `-${theme.space.s2_5.value}`,
  left: `-${theme.space.s5.value}`,
})
