import { Link } from "react-router-dom"
import { styled, theme } from "../../../styles/stitches.config"

export const STooltipSingleDepositState = styled("div", {
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.space.s6,
  paddingBottom: theme.space.s6,
  "&:first-of-type": {
    paddingTop: 0,
  },
  "&:last-of-type": {
    paddingBottom: 0,
  },
  "@md": {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.space.s6,
    paddingRight: theme.space.s6,
    "&:first-of-type": {
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingRight: 0,
    },
  },
})

export const STooltipDepositStateContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  "@md": {
    flexDirection: "row",
  },
})

export const SPaymentsLink = styled(Link, {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  color: theme.colors.textsAlt,

  hover: {
    color: theme.colors.secondary,
  },
})
