import { Flex } from "../../../flex/Flex"
import Text from "../../../Text"
import { DeliveryPointType } from "@nano-portal/shared"
import { Electricity, Sun } from "../../../../images/xd/icons"
import { assertUnreachable } from "../../../../utils/types"
import { Fragment } from "react"
import { uniq } from "lodash"
import { theme } from "../../../../styles/stitches.config"
import { SIconWrapper } from "./DeliveryPointDetails.styles"
import { splitAddressToStreetAndTown } from "../../../../utils/string"

interface Props {
  ean: string
  type: DeliveryPointType[]
  address: string
  isActive: boolean
  isSelected?: boolean
}

function renderTypeIcon(type: DeliveryPointType) {
  switch (type) {
    case DeliveryPointType.Consumption:
      return <Electricity />
    case DeliveryPointType.Production:
      return <Sun />
    default:
      return assertUnreachable(type)
  }
}

export default function DeliveryPointDetails({ type, isActive, address, ean, isSelected }: Props) {
  const [street] = splitAddressToStreetAndTown(address)
  const eanText = `EAN ${ean}`

  return (
    <Flex direction="column" align="start">
      <Text type={"headerH4"} color={!isActive ? "textsDisabeled" : isSelected ? "secondary" : "textsAlt"}>
        {street}
      </Text>

      <Flex direction="row" align="center" css={{ gap: theme.space.s0_5 }}>
        {uniq(type).map((t) => (
          <SIconWrapper variant={isActive ? "tertiary" : "inactive"} key={t}>
            {renderTypeIcon(t)}
          </SIconWrapper>
        ))}
        <Text
          type={"textsSmall"}
          color={isActive ? "primary" : "textsDisabeled"}
          css={{ fontSize: "9px", fontWeight: "normal" }}
        >
          {eanText}
        </Text>
      </Flex>
    </Flex>
  )
}
