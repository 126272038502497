import * as React from "react"

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 55">
        <path data-name="Path 55" d="M0 0h24v24H0z" fill="none" />
      </g>
      <path
        d="M8.051 12.414h1.828v7.344a.266.266 0 00.269.262h3.1a.266.266 0 00.269-.262v-7.311h2.1a.267.267 0 00.268-.233l.32-2.7a.258.258 0 00-.066-.2.273.273 0 00-.2-.088h-2.414V7.525c0-.511.282-.77.839-.77h1.585a.266.266 0 00.269-.262V4.011a.266.266 0 00-.269-.264H13.665a4.256 4.256 0 00-2.737 1.005 2.752 2.752 0 00-.954 2.486V9.22H8.051a.266.266 0 00-.269.262v2.665a.266.266 0 00.269.267z"
        fill="#3b5998"
      />
    </svg>
  )
}

export default SvgFacebook
