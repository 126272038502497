import * as React from "react"

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 51367" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 51368"
        d="M20 4H4a2 2 0 00-1.99 2L2 18a2.006 2.006 0 002 2h16a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm-1 14H5a1 1 0 01-1-1V8l6.94 4.34a1.981 1.981 0 002.12 0L20 8v9a1 1 0 01-1 1zm-7-7L4 6h16z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEmail
