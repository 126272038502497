import * as React from "react"

function SvgArrowForward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 21" d="M24 0v24H0V0z" fill="none" opacity={0.87} />
      <path
        data-name="Path 22"
        d="M9.29 8.12L13.17 12l-3.88 3.88a1 1 0 001.41 1.41l4.59-4.59a1 1 0 000-1.41L10.7 6.7a1 1 0 00-1.41 0 1.017 1.017 0 000 1.42z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowForward
