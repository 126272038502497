import { styled, theme } from "../../styles/stitches.config"
import { ArrowBack } from "../../images/xd/icons"

export const SSectionWrapper = styled("div", {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  display: "flex",
  flexDirection: "column",
})

export const SSectionInfo = styled(SSectionWrapper, {
  padding: `${theme.space.s7_5} ${theme.space.s10_5}`,
  justifyContent: "space-between",
  width: "92%",
  "@lg": {
    width: theme.space.s133_5,
  },
})

export const SSectionPayment = styled(SSectionWrapper, {
  padding: `${theme.space.s7_5} ${theme.space.s10_5} ${theme.space.s12}`,
  height: theme.space.s64,
  width: "100%",
  "@lg": {
    width: "auto",
  },
})

export const SChevronLeft = styled(ArrowBack, {
  width: theme.sizes.s9,
  height: theme.space.s9,
  color: theme.colors.secondary,
})

export const SSectionPaymentDetail = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  "@lg": {
    justifyContent: "space-between",
  },

  flex: 1,
})

export const SIConContainer = styled("div", {
  with: theme.sizes.s4,
  height: theme.sizes.s4,
  display: "flex",
})

export const SHeaderMessageWrapper = styled("div", {
  paddingTop: theme.sizes.spx,
})

export const SFlexMobile = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@lg": {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  variants: {
    alignCenter: {
      true: {
        alignItems: "center",
      },
      false: {
        alignItems: "flex-start",
      },
    },
  },
})

export const SHeaderContainer = styled("div", {
  display: "flex",
  paddingInline: theme.space.s6,

  "@lg": {
    paddingInline: theme.space.s0,
  },
})

export const SHeaderCaptionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  "@lg": {
    flexDirection: "row",
    alignItems: "center",
  },
})
