import { styled } from "@stitches/react"
import { theme } from "../../../../styles/stitches.config"

export const SIconWrapper = styled("div", {
  width: theme.space.s3,
  height: theme.space.s3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    variant: {
      primary: {
        color: theme.colors.secondary,
      },
      secondary: {
        color: theme.colors.primary,
      },
      tertiary: {
        color: theme.colors.textsAlt,
      },
      inactive: {
        color: theme.colors.textsDisabeled,
      },
    },
  },
})
