import { styled, theme } from "../../../../styles/stitches.config"
import Separator from "../../../../components/Separator"

export const SContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s6,

  "@md": {
    flexDirection: "row",
  },
})

export const SElectricityMeterCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  padding: `${theme.space.s8} ${theme.space.s10}`,
  gap: theme.space.s6,

  "@md": {
    padding: `${theme.space.s7_5} ${theme.space.s10_5}`,
    gap: theme.space.s4,
    height: theme.space.s48,
  },
})

export const SSeparator = styled(Separator, {
  display: "none",

  "@md": {
    display: "initial",
  },
})

export const SButtonsContainer = styled("div", {
  display: "flex",
  gap: theme.space.s4,

  flexDirection: "column",
  alignItems: "center",
  margin: `0 ${theme.space.s9} ${theme.space.s9} ${theme.space.s9}`,

  "@md": {
    flexDirection: "row-reverse",
    margin: 0,
    marginBottom: theme.space.s9,
  },
})
