import * as React from "react"

function SvgPaymentsAll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_PaymentsAll_svg__a">
          <path data-name="Rectangle 390" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 703" clipPath="url(#_PaymentsAll_svg__a)" fill="currentColor">
        <path
          data-name="Path 270"
          d="M18.018 5.999H2.006a1.985 1.985 0 00-1.991 1.98v.02l-.009 12a1.993 1.993 0 001.986 2h16.014a2 2 0 002-1.987V7.999a1.993 1.993 0 00-1.988-2m-.012 13a1 1 0 01-1 1h-14a1 1 0 01-1-1v-5h16zm0-9h-16v-2h16z"
        />
        <path
          data-name="Path 271"
          d="M22.004 2.006H5.992a1.984 1.984 0 00-1.989 1.981v.018h17.99v14a1.993 1.993 0 002-1.988V4.006a2 2 0 00-1.987-2"
        />
      </g>
    </svg>
  )
}

export default SvgPaymentsAll
