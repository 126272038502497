import * as React from "react"

function SvgPassword(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_Password_svg__a">
          <path data-name="Rectangle 577" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1386" clipPath="url(#_Password_svg__a)">
        <path
          data-name="Path 448"
          d="M12.516 10.411a4.769 4.769 0 100 3.179h3.456v1.589a1.589 1.589 0 103.179 0V13.59a1.59 1.59 0 000-3.179zm-4.49 3.179A1.59 1.59 0 119.615 12a1.594 1.594 0 01-1.589 1.59"
          fill="#00515f"
        />
      </g>
    </svg>
  )
}

export default SvgPassword
