import { styled } from "../../../../styles/stitches.config"
import StateContentContainer from "./StateContentContainer"

const duration = 150
const from = -100
const to = 0

export const SContainer = styled(StateContentContainer, {
  "&.supplyPointsState-enter": {
    transform: `translateX(${from}%)`,
  },
  "&.supplyPointsState-enter-active": {
    transform: `translateX(${to}%)`,
    transition: `transform ${duration}ms linear`,
  },
  "&.supplyPointsState-exit": {
    transform: `translateX(${to}%)`,
  },
  "&.supplyPointsState-exit-active": {
    transform: `translateX(${from}%)`,
    transition: `transform ${duration}ms linear`,
  },
})
