import React, { useCallback } from "react"
import { DateRange } from "../../../utils/types"
import { SContainer } from "./YearRangePicker.styled"
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from "date-fns"
import YearSelect from "./YearSelect"
import { theme } from "../../../styles/stitches.config"
import Text from "../../Text"
import { updateYear } from "../../../utils/date"

const yearSpan = 20

const Separator = () => {
  return (
    <Text type="headerH3" css={{ marginInline: theme.space.s4 }}>
      -
    </Text>
  )
}

interface Props {
  selected: DateRange
  maxDate?: Date
  minDate?: Date
  onSelect: (range: DateRange) => void
  singleYear?: boolean
}

export function YearRangePicker({ selected, minDate, maxDate, onSelect, singleYear }: Props) {
  const checkMinDate = useCallback(
    (newSelected: DateRange) => {
      if (!minDate) {
        return
      }

      if (newSelected.from < minDate) {
        newSelected.from = minDate
      }
    },
    [minDate]
  )

  const checkMaxDate = useCallback(
    (newSelected: DateRange) => {
      if (!maxDate) {
        return
      }

      if (newSelected.from > maxDate) {
        newSelected.from = startOfMonth(maxDate)
        newSelected.to = maxDate
        return
      }

      if (newSelected.to > maxDate) {
        newSelected.to = maxDate
      }
    },
    [maxDate]
  )

  const handleSelect = useCallback(
    (newSelected: DateRange) => {
      const { from: newFrom, to: newTo } = newSelected
      if (newFrom >= newTo) {
        newSelected.to = endOfMonth(newFrom)
      }

      checkMinDate(newSelected)
      checkMaxDate(newSelected)
      onSelect(newSelected)
    },
    [onSelect, checkMinDate, checkMaxDate]
  )

  return (
    <SContainer>
      <YearSelect
        minYear={minDate ? minDate.getFullYear() : selected.from.getFullYear() - yearSpan}
        maxYear={maxDate ? maxDate.getFullYear() : selected.from.getFullYear() + yearSpan}
        value={selected.from.getFullYear()}
        onSelect={(year) =>
          handleSelect({
            from: startOfYear(updateYear(year, selected.from)),
            to: singleYear ? endOfYear(updateYear(year, selected.from)) : selected.to,
          })
        }
      />
      {!singleYear && (
        <>
          <Separator />
          <YearSelect
            minYear={selected.from.getFullYear()}
            maxYear={maxDate ? maxDate.getFullYear() : selected.to.getFullYear() + yearSpan}
            value={selected.to.getFullYear()}
            onSelect={(year) => handleSelect({ from: selected.from, to: endOfYear(updateYear(year, selected.to)) })}
          />
        </>
      )}
    </SContainer>
  )
}
