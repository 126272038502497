import { styled, theme } from "../styles/stitches.config"

const Card = styled("div", {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: `${theme.space.s8} ${theme.space.s10}`,

  display: "flex",
  flexDirection: "column",
  gap: theme.space.s4,

  "@md": {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.space.s0,
    justifyContent: "space-between",
  },
})

export default Card
