import React from "react"

function SvgGoodwe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 15" width="101" height="15" fill="none" {...props}>
      <mask id="a" width="101" height="15" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "luminance" }}>
        <path fill="#fff" d="M100.073.076H.305V14.91h99.768V.076z" />
      </mask>
      <g fill="#E70013" mask="url(#a)">
        <path d="M27.896.16h-5.954a4.884 4.884 0 00-4.89 4.889v4.856a4.884 4.884 0 004.89 4.889h5.954a4.897 4.897 0 004.89-4.89V5.05a4.883 4.883 0 00-4.89-4.89zm2.062 9.745a2.062 2.062 0 01-2.062 2.062h-5.954a2.062 2.062 0 01-2.062-2.062V5.049a2.062 2.062 0 012.062-2.064h5.954a2.061 2.061 0 012.062 2.064v4.856zM13.859 5.98H8.653a1.526 1.526 0 00-1.53 1.528v.033a1.523 1.523 0 001.53 1.514h3.493v2.644H6.425a3.044 3.044 0 01-3.043-3.044V6.296A3.044 3.044 0 016.425 3.25h7.2a1.53 1.53 0 001.53-1.546 1.523 1.523 0 00-1.53-1.528h-7.2a6.125 6.125 0 00-6.12 6.12v2.361a6.125 6.125 0 006.12 6.12h8.798V7.345a1.37 1.37 0 00-1.365-1.365m84.667 5.856H93.07a3.079 3.079 0 01-3.068-2.88h8.523a1.546 1.546 0 100-3.093H90.04a3.078 3.078 0 013.03-2.56h5.454a1.577 1.577 0 001.514-1.514 1.57 1.57 0 00-1.514-1.6H93.07a6.178 6.178 0 00-6.17 6.17v2.38a6.178 6.178 0 006.17 6.17h5.455a1.539 1.539 0 000-3.077M45.306.16H39.35a4.884 4.884 0 00-4.888 4.889v4.856a4.882 4.882 0 004.889 4.889h5.954a4.883 4.883 0 004.89-4.89V5.05a4.885 4.885 0 00-4.89-4.89zm2.062 9.745a2.062 2.062 0 01-2.062 2.062H39.35a2.062 2.062 0 01-2.062-2.062V5.049a2.064 2.064 0 012.062-2.064h5.955a2.062 2.062 0 012.062 2.064v4.856zM60.473.143L52.34.16v14.652h8.116A6.172 6.172 0 0066.61 8.66V6.296A6.137 6.137 0 0060.473.143zm3.06 8.516a3.053 3.053 0 01-3.06 3.06H55.45V3.236h5.023a3.052 3.052 0 013.06 3.06V8.66zM82.976.076h-.05a1.44 1.44 0 00-1.43 1.43v8.286L79.252 7.46a1.46 1.46 0 00-2.129 1.996l4.74 4.922c.224.25.532.41.865.449.054.014.11.02.166.017a1.476 1.476 0 001.514-1.447V1.506a1.42 1.42 0 00-1.43-1.43zM74.044 7.46L71.8 9.792V1.506a1.43 1.43 0 00-1.432-1.43h-.067a1.42 1.42 0 00-1.43 1.43v11.891a1.415 1.415 0 001.036 1.375c.159.054.327.079.495.072a.559.559 0 00.166-.017c.333-.039.64-.198.865-.448l4.74-4.922a1.433 1.433 0 00-.067-2.047 1.451 1.451 0 00-2.062.05" />
      </g>
    </svg>
  )
}

export default SvgGoodwe
