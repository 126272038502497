import { ReactElement, ReactNode, useCallback, useState } from "react"
import {
  SClose,
  SCloseButton,
  SContent,
  SModalClose,
  SModalRoot,
  SModalTrigger,
  SOverlay,
  SPortal,
} from "./Modal.styled"
import { ObjectValues } from "../../utils/types"
import { theme } from "../../styles/stitches.config"

function eventPreventer(e: Event) {
  e.preventDefault()
}

export interface ModalContentProps {
  close: () => void
}

interface ContentProps {
  children?: ReactNode
  isFullscreenOnMobile?: boolean
  padding?: ObjectValues<typeof theme.space>
  hideCloseButton?: boolean
  disableEscapeKeyToClose?: boolean
  disablePointerOutsideToClose?: boolean
}

function Content({
  children,
  isFullscreenOnMobile,
  padding,
  hideCloseButton = false,
  disableEscapeKeyToClose = false,
  disablePointerOutsideToClose = false,
}: ContentProps) {
  return (
    <SPortal>
      <SOverlay />
      <SContent
        onPointerDownOutside={disablePointerOutsideToClose ? eventPreventer : undefined}
        onEscapeKeyDown={disableEscapeKeyToClose ? eventPreventer : undefined}
        isFullscreenOnMobile={isFullscreenOnMobile}
        css={{
          padding,
        }}
      >
        {children}
        <SModalClose asChild>
          {!hideCloseButton && (
            <SCloseButton aria-label="Close">
              <SClose />
            </SCloseButton>
          )}
        </SModalClose>
      </SContent>
    </SPortal>
  )
}

interface Props {
  trigger?: ReactElement
  children?: ReactNode | ((props: ModalContentProps) => ReactNode)
  openOnMount?: boolean
  onOpenChange?: (open: boolean) => void
  isFullscreenOnMobile?: boolean
  padding?: ObjectValues<typeof theme.space>
  hideCloseButton?: boolean
  disableEscapeKeyToClose?: boolean
  disablePointerOutsideToClose?: boolean
}

export default function Modal({
  trigger,
  children,
  openOnMount = false,
  onOpenChange,
  isFullscreenOnMobile,
  padding,
  hideCloseButton,
  disableEscapeKeyToClose,
  disablePointerOutsideToClose,
}: Props) {
  const [open, setOpen] = useState(openOnMount)

  const handleOpenChange = useCallback(
    (value: boolean) => {
      setOpen(value)
      onOpenChange?.(value)
    },
    [onOpenChange]
  )

  return (
    <SModalRoot open={open} onOpenChange={handleOpenChange}>
      <SModalTrigger asChild>{trigger}</SModalTrigger>

      <Content
        isFullscreenOnMobile={isFullscreenOnMobile}
        padding={padding}
        hideCloseButton={hideCloseButton}
        disableEscapeKeyToClose={disableEscapeKeyToClose}
        disablePointerOutsideToClose={disablePointerOutsideToClose}
      >
        {children && typeof children === "function" ? children({ close: () => setOpen(false) }) : children}
      </Content>
    </SModalRoot>
  )
}
