import { TFuncKey, Trans, useTranslation } from "react-i18next"
import Circle from "../../components/chart/components/Circle"
import { Flex } from "../../components/flex/Flex"
import { FormattedUnit, TextStyle } from "../../components/FormattedUnit"
import {
  AmountCellValue,
  CustomUnitCellProps,
  getCustomUnitCell,
  RValueCellProps,
  withNullableValue,
} from "../../components/table/renderers"
import Text, { TextType } from "../../components/Text"
import { getCurrency } from "../../i18n/config"
import { ColorType, theme } from "../../styles/stitches.config"
import { formatDay, formatHour, formatYear, getLocalizedMonth } from "../../utils/date"
import { assertUnreachable } from "../../utils/types"
import * as HoverCard from "@radix-ui/react-hover-card"
import { InfoText } from "../../components/info/InfoText"
import { HeaderTooltipArrow, HeaderTooltipContent, HeaderTooltipTrigger } from "./periodOverviewRenderers.styled"
import { ComponentProps } from "react"
import { OverviewGroup, EnergyUnit } from "@nano-portal/shared"
import InfoBox from "../../components/info/InfoBox"
import { Div } from "../../components/div/Div"
import { addMinutes } from "date-fns"
import { useTouchTooltip } from "../../hooks/useTouchTooltip"

export interface PeriodCellValue {
  group: OverviewGroup
  from: Date
  to?: Date
}

export const defaultValueStyle: TextStyle = { fontSize: 14 }
export const defaultUnitStyle: TextStyle = { fontSize: 10 }

const tooltipOpenDelay = 150
const tooltipCloseDelay = 0
const tooltipArrowOffset = 8

export const cellOptions = ({
  unit,
  unitStyle = defaultUnitStyle,
  valueStyle = defaultValueStyle,
}: {
  unit: "currency" | EnergyUnit
  unitStyle?: TextStyle
  valueStyle?: TextStyle
}) => ({
  unit: unit === "currency" ? getCurrency().symbol : unit,
  valueStyle,
  unitStyle,
})

export const ColoredPeriodCell = withNullableValue(
  ({ value }: RValueCellProps<PeriodCellValue & { circleColor?: ColorType }>) => {
    const renderPeriodValue = () => {
      switch (value.group) {
        case OverviewGroup.Years:
          return formatYear(value.from, "yyyy")
        case OverviewGroup.Months:
          return getLocalizedMonth(value.from)
        case OverviewGroup.Days:
          return `${formatDay(value.from)}. ${getLocalizedMonth(value.from, "long", false)} ${formatYear(
            value.from,
            "yyyy"
          )}`
        case OverviewGroup.Hours:
          return `${formatHour(value.from)}${value.to ? ` - ${formatHour(addMinutes(value.to, 1))}` : ""}`
        default:
          assertUnreachable(value.group)
      }
    }

    return (
      <Flex align="center" justify="between" css={{ gap: theme.space.s8, whiteSpace: "nowrap" }}>
        <Circle color={value.circleColor} size={theme.sizes.s1} />
        <Flex style={defaultValueStyle}>{renderPeriodValue()}</Flex>
      </Flex>
    )
  }
)

export const CustomUnitFooterCell = ({
  amount,
  unit,
  unitStyle = defaultUnitStyle,
  valueStyle = defaultValueStyle,
}: CustomUnitCellProps & { amount: number | null }) => {
  if (amount === null) {
    return (
      <Div css={{ textAlign: "center" }}>
        <Text type={valueStyle?.textType || "textsLarge"}>-</Text>
      </Div>
    )
  }

  return (
    <Flex direction="row" justify="end">
      <FormattedUnit
        value={amount}
        unit={unit}
        unitTextType={unitStyle?.textType || "textsLarge"}
        unitFontSize={unitStyle?.fontSize}
        valueTextType={valueStyle?.textType || "textsLarge"}
        valueFontSize={valueStyle?.fontSize}
      />
    </Flex>
  )
}

export const SettlementCell = withNullableValue((props: RValueCellProps<AmountCellValue>) => {
  const amount = props.value.amount

  const textType: TextType = amount < 0 ? "headerH5Negative" : amount === 0 ? "headerH5Neutral" : "headerH5Positive"
  return getCustomUnitCell({
    unit: getCurrency().symbol,
    valueStyle: { textType, ...defaultValueStyle },
    unitStyle: { textType, ...defaultUnitStyle },
  })(props)
})

export const Header = (props: {
  tooltipKey?: TFuncKey<"overview">
  headerKey: TFuncKey<"overview">
  tooltipAlign?: ComponentProps<typeof HeaderTooltipContent>["align"]
  textAlign?: ComponentProps<typeof Flex>["justify"]
}) => {
  const { t } = useTranslation("overview")
  const { open: tooltipOpen, handleToggle: handleTooltipToggle } = useTouchTooltip()

  if (!props.tooltipKey) {
    return (
      <Flex align="center" justify={props.textAlign} css={{ textAlign: "center" }}>
        <Trans t={t} i18nKey={props.headerKey}>
          <br />
        </Trans>
      </Flex>
    )
  }

  return (
    <HoverCard.Root
      openDelay={tooltipOpenDelay}
      closeDelay={tooltipCloseDelay}
      open={tooltipOpen}
      onOpenChange={handleTooltipToggle}
    >
      <HeaderTooltipTrigger asChild onTouchEnd={handleTooltipToggle}>
        <Flex align="center" justify={props.textAlign} css={{ textAlign: "center", gap: theme.space.s1 }}>
          <Trans t={t} i18nKey={props.headerKey}>
            <br />
          </Trans>
          <InfoBox size="small" />
        </Flex>
      </HeaderTooltipTrigger>
      <HeaderTooltipContent align={props.tooltipAlign} sideOffset={tooltipArrowOffset}>
        <HeaderTooltipArrow offset={tooltipArrowOffset} />
        <InfoText type="textsSmall">{t(props.tooltipKey)}</InfoText>
      </HeaderTooltipContent>
    </HoverCard.Root>
  )
}
