import { useState } from "react"
import { getDateWithMonth, getLocalizedMonth } from "../../../utils/date"
import { range } from "lodash"
import { SelectContainer } from "../DatePicker.styled"

interface Props {
  value: number
  onSelect?: (value: number) => void
}

export default function MonthSelect({ value = 0, onSelect }: Props) {
  const [selected, setSelected] = useState(value)

  const handleSelect = (value: number) => {
    setSelected(value)
    onSelect?.(value)
  }

  return (
    <SelectContainer value={selected} onChange={(e) => handleSelect(Number(e.currentTarget.value))} color="primary">
      {range(12).map((month) => (
        <option key={month} value={month}>
          {getLocalizedMonth(getDateWithMonth(month))}
        </option>
      ))}
    </SelectContainer>
  )
}
