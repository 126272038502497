import { styled, theme, fontVariants } from "../../styles/stitches.config"

export const STitleContainer = styled("span", {
  alignSelf: "center",

  "@md": {
    paddingLeft: theme.space.s5,
    alignSelf: "start",
  },
})

export const SubSectionTitle = styled("h2", {
  ...fontVariants.headerH1,
  fontWeight: theme.fontWeights.bold,
})

export const SContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  minWidth: 0,

  variants: {
    width: {
      max: {
        "@lg": {
          maxWidth: theme.space.s220,
        },
      },
      fixed: {
        flexShrink: 0,
      },
      stretch: {
        width: "stretch",
      },
    },
  },
})
