import { VisibilityOff, VisibilityOn } from "../../images/xd/icons"
import { SPasswordButton, SPasswordIcon } from "./PasswordInputRightContent.styled"

type Props = {
  isPasswordVisible?: boolean
  setIsPasswordVisible: (val: boolean) => void
}

export function PasswordInputRightContent(props: Props) {
  const { setIsPasswordVisible, isPasswordVisible } = props

  return (
    <SPasswordButton type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
      <SPasswordIcon as={isPasswordVisible ? VisibilityOff : VisibilityOn} />
    </SPasswordButton>
  )
}
