import * as Popover from "@radix-ui/react-popover"
import { fontVariants, styled, theme } from "../../styles/stitches.config"
import { Flex } from "../flex/Flex"

export const PickerContainer = styled(Flex, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  variants: {
    size: {
      regular: {
        flex: 1,
      },
      small: {
        flex: 0,
      },
    },
  },

  defaultVariants: {
    size: "regular",
  },
})

export const HeaderContainer = styled(Flex, {
  paddingTop: theme.space.s4,
  paddingBottom: theme.space.s2,
  borderBottom: `1px solid ${theme.colors.lines.value}`,
})

export const PickerControlContainer = styled(Flex, {
  flex: 1,
  height: theme.space.s11_5,
})

export const DateRangeContainer = styled("button", {
  cursor: "pointer",
  background: "transparent",
  border: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: theme.space.s11_5,
  width: theme.space.s44,
  ...fontVariants.headerH5,
  "@md": {
    ...fontVariants.headerH4,
    width: theme.space.s52,
    marginLeft: theme.space.s2,
    marginRight: theme.space.s2,
  },

  variants: {
    size: {
      regular: {
        width: theme.space.s44,

        "@md": {
          width: theme.space.s52,
        },
      },
      small: {
        width: theme.space.s28,
      },
    },
  },

  defaultVariants: {
    size: "regular",
  },
})

export const ArrowButton = styled(Flex, {
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  width: theme.space.s11_5,
  height: theme.space.s11_5,
  backgroundColor: theme.colors.white,
  borderStyle: "solid",
  borderWidth: 0,
  variants: {
    side: {
      left: {
        borderRightWidth: theme.space.spx,
        borderColor: theme.colors.lines,
      },
      right: {
        borderLeftWidth: theme.space.spx,
        borderColor: theme.colors.lines,
      },
    },
    disabled: {
      true: {
        cursor: "default",
      },
      false: {
        cursor: "pointer",
      },
    },
  },
})

export const DropdownContent = styled(Popover.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  marginTop: theme.space.s2,
  marginBottom: theme.space.s2,
})

export const PresetItem = styled("button", {
  borderWidth: 0,
  backgroundColor: "transparent",
  padding: 0,
  textAlign: "left",
  cursor: "pointer",
  ...fontVariants.menuItem,
  variants: {
    active: {
      true: {
        ...fontVariants.menuItemActive,
      },
    },
  },
})

export const ConfirmButtonWrapper = styled(Popover.PopoverClose, {
  backgroundColor: theme.colors.white,
  border: 0,
})

export const SelectContainer = styled("select", {
  backgroundColor: theme.colors.transparent,
  border: "none",
  ...fontVariants.headerH2,
  padding: theme.space.s1,
  marginInline: theme.space.s2,

  "@sm": {
    marginInline: 0,
  },

  variants: {
    color: {
      primary: {
        color: theme.colors.primary,
      },
      secondary: {
        color: theme.colors.secondary,
      },
    },
  },

  defaultVariants: {
    color: "primary",
  },
})
