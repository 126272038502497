import * as React from "react"

function SvgFilter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 303" d="M0 0h24m0 24H0" fill="none" />
      <path
        data-name="Path 304"
        d="M4.25 5.61C6.57 8.59 10 13 10 13v5a2.006 2.006 0 002 2 2.006 2.006 0 002-2v-5s3.43-4.41 5.75-7.39a1 1 0 00-.8-1.61H5.04a1 1 0 00-.79 1.61z"
        fill="currentColor"
      />
      <path data-name="Path 305" d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export default SvgFilter
