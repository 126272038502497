import * as React from "react"

function SvgLightbulb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 51122" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 51123"
        d="M9 21a1 1 0 001 1h4a1 1 0 001-1v-1H9zm3-19a7 7 0 00-4 12.74V17a1 1 0 001 1h6a1 1 0 001-1v-2.26A7 7 0 0012 2zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6a5 5 0 115.7 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLightbulb
