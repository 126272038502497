import * as React from "react"

function SvgGoodweInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.942 9.508" {...props}>
      <defs>
        <clipPath id="_Goodwe_inactive_svg__a">
          <path data-name="Rectangle 17261" fill="#c5d3d6" d="M0 0h63.942v9.508H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17668" clipPath="url(#_Goodwe_inactive_svg__a)" fill="#c5d3d6">
        <path
          data-name="Path 52626"
          d="M17.683.054h-3.815a3.132 3.132 0 00-3.134 3.134V6.3a3.132 3.132 0 003.134 3.134h3.815A3.139 3.139 0 0020.817 6.3V3.188A3.132 3.132 0 0017.683.054M19.004 6.3a1.321 1.321 0 01-1.321 1.321h-3.815A1.321 1.321 0 0112.547 6.3V3.188a1.321 1.321 0 011.321-1.322h3.815a1.322 1.322 0 011.321 1.322z"
        />
        <path
          data-name="Path 52627"
          d="M8.687 3.784H5.351a.978.978 0 00-.981.981v.021a.976.976 0 00.981.97h2.238V7.45H3.922a1.951 1.951 0 01-1.95-1.95V3.986a1.951 1.951 0 011.95-1.95h4.616a.981.981 0 00.981-.991.978.978 0 00-.981-.981H3.922A3.926 3.926 0 000 3.986V5.5a3.926 3.926 0 003.922 3.922h5.639V4.658a.878.878 0 00-.874-.874"
        />
        <path
          data-name="Path 52628"
          d="M62.95 7.536h-3.5a1.973 1.973 0 01-1.965-1.844h5.461a.992.992 0 000-1.983h-5.433a1.973 1.973 0 011.941-1.639h3.5a1.011 1.011 0 00.97-.97 1 1 0 00-.97-1.023h-3.5A3.96 3.96 0 0055.5 4.031v1.522a3.96 3.96 0 003.954 3.954h3.5a.986.986 0 000-1.972"
        />
        <path
          data-name="Path 52629"
          d="M28.843.054h-3.816a3.132 3.132 0 00-3.133 3.134V6.3a3.132 3.132 0 003.134 3.134h3.816A3.132 3.132 0 0031.978 6.3V3.188A3.132 3.132 0 0028.843.054M30.164 6.3a1.321 1.321 0 01-1.321 1.321h-3.816A1.321 1.321 0 0123.706 6.3V3.188a1.321 1.321 0 011.321-1.322h3.816a1.322 1.322 0 011.321 1.322z"
        />
        <path
          data-name="Path 52630"
          d="M38.563.043l-5.212.011v9.39h5.2A3.955 3.955 0 0042.495 5.5V3.983a3.932 3.932 0 00-3.932-3.94M40.525 5.5a1.956 1.956 0 01-1.961 1.961h-3.22V2.026h3.219a1.956 1.956 0 011.962 1.957z"
        />
        <path
          data-name="Path 52631"
          d="M52.985 0h-.032a.924.924 0 00-.916.916v5.311l-1.439-1.495a.935.935 0 00-1.364 1.279l3.038 3.155a.881.881 0 00.554.287.359.359 0 00.106.011.946.946 0 00.97-.927V.916A.91.91 0 0052.985 0"
        />
        <path
          data-name="Path 52632"
          d="M47.261 4.732l-1.438 1.495V.916A.917.917 0 0044.905 0h-.043a.91.91 0 00-.916.916v7.622a.907.907 0 00.663.881.872.872 0 00.317.046.359.359 0 00.106-.011.881.881 0 00.554-.287l3.037-3.155a.918.918 0 00-.041-1.312.93.93 0 00-1.321.032"
        />
      </g>
    </svg>
  )
}

export default SvgGoodweInactive
