import { useIsMobileVersion } from "../../hooks/mediaHooks"
import { theme } from "../../styles/stitches.config"
import { Flex } from "../flex/Flex"
import { Spacer } from "../spacer/Spacer"
import RectPlaceholder from "./components/RectPlaceholder"

const InvoiceDetailPlaceholder = () => {
  const isMobile = useIsMobileVersion()

  return (
    <>
      <Spacer size={theme.space.s18} />
      <Flex direction={isMobile ? "column" : "row"}>
        <RectPlaceholder />
        <Spacer size={theme.space.s6} />
        <RectPlaceholder />
      </Flex>
    </>
  )
}

export default InvoiceDetailPlaceholder
