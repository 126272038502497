import React from "react"
import Text from "../../../Text"
import { SInfoBar } from "./InfoBar.styled"

const InfoBar = ({ text }: { text: string }) => {
  return (
    <SInfoBar>
      <Text type="headerH3">{text}</Text>
    </SInfoBar>
  )
}

export default InfoBar
