import { ComponentProps, ElementType, PropsWithChildren } from "react"
import { ColorType, CSSProp, fontVariants, styled, theme } from "../styles/stitches.config"

function styledTypography(element: ElementType) {
  return styled(element, {
    variants: {
      type: fontVariants,
    },
  })
}

const StyledSpan = styledTypography("span")
const HeaderH1 = styledTypography("h1")
const HeaderH2 = styledTypography("h2")

export type TextType = keyof typeof fontVariants

interface Props {
  type: TextType
  color?: ColorType
  css?: CSSProp
  as?: ElementType
}

function getTag(variant: ComponentProps<typeof StyledSpan>["type"]) {
  switch (variant) {
    case "headerH1":
      return HeaderH1
    case "headerH2":
    case "headerH2Positive":
    case "headerH2Neutral":
    case "headerH2Negative":
      return HeaderH2
    default:
      return StyledSpan
  }
}

export function Text(props: PropsWithChildren<Props>) {
  const { type, children, color, css, as } = props
  const cssColor = color ? theme.colors[color] : undefined

  const Tag = getTag(type)

  return (
    <Tag type={type} css={{ ...css, color: cssColor }} as={as}>
      {children}
    </Tag>
  )
}

export default Text
