import * as React from "react"

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 396" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 397"
        d="M6 19a2.006 2.006 0 002 2h8a2.006 2.006 0 002-2V9a2.006 2.006 0 00-2-2H8a2.006 2.006 0 00-2 2zM18 4h-2.5l-.71-.71a1.009 1.009 0 00-.7-.29H9.91a1.009 1.009 0 00-.7.29L8.5 4H6a1 1 0 000 2h12a1 1 0 000-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTrash
