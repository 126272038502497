import * as icons from "../../images/xd/icons"

import { useTranslation } from "react-i18next"
import { useIsMaxSm } from "../../hooks/mediaHooks"
import { IconType, fontVariants, theme } from "../../styles/stitches.config"
import { OverviewType } from "../../sections/overview/overview.utils"

interface ChartDimensions {
  width: number
  height: number
}

interface ChartDataPlaceholderProps extends ChartDimensions {
  icon: IconType
  header: string
  label: string
}

const ChartDataPlaceholder = ({ width, height, icon, header, label }: ChartDataPlaceholderProps) => {
  const Icon = icons[icon]

  const iconSize = 164
  const canvasCenterX = width / 2
  const canvasCenterY = height / 2

  const isSmall = useIsMaxSm()
  const textsOffsetIconSizeDenominator = 3
  const descriptionYOffset = 10

  return (
    <>
      <Icon x={canvasCenterX - iconSize / 2} y={canvasCenterY - iconSize / 1.2} width={iconSize} height={iconSize} />
      <text
        x={canvasCenterX}
        y={canvasCenterY + iconSize / textsOffsetIconSizeDenominator - descriptionYOffset / 2}
        style={isSmall ? { ...fontVariants.headerH5Neutral } : { ...fontVariants.headerH2Neutral }}
        fill={theme.colors.textsAlt.value}
        textAnchor="middle"
      >
        {header}
      </text>
      <foreignObject
        x={canvasCenterX - width / 3}
        y={canvasCenterY + iconSize / textsOffsetIconSizeDenominator + descriptionYOffset}
        height={(isSmall ? 10 : 16) * 3}
        width={width / 1.5}
      >
        <p
          style={{
            ...fontVariants.textsLarge,
            fontSize: isSmall ? 10 : 16,
            color: theme.colors.textsAlt.value,
            lineHeight: 1.5,
            textAlign: "center",
          }}
        >
          {label}
        </p>
      </foreignObject>
    </>
  )
}
export const ChartErrorStatePlaceholder = (props: ChartDimensions) => {
  const { t } = useTranslation(["overview"])
  return (
    <ChartDataPlaceholder
      {...props}
      icon="MissingGraphData"
      header={t("overview:error_chart_header")}
      label={t("overview:error_overview_label")}
    />
  )
}

export const ChartUnknownStatePlaceholder = (props: ChartDimensions & { type: OverviewType }) => {
  const { t } = useTranslation(["overview"])
  return (
    <ChartDataPlaceholder {...props} icon="NoGraphData" header={t("overview:unknown_overview_state_header")} label="" />
  )
}

export const ChartFixedPricelistHidden = (props: ChartDimensions & { type: OverviewType }) => {
  const { t } = useTranslation(["overview"])
  return (
    <ChartDataPlaceholder
      {...props}
      icon="NoGraphData"
      header={t("overview:unknown_fix_pricelist_prices_header")}
      label={t("overview:unknown_fix_pricelist_prices_label")}
    />
  )
}
