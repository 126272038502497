import React from "react"
import { Flex } from "../components/flex/Flex"
import Loader from "../components/loader/Loader"

const LoadingPage = () => {
  return (
    <Flex justify="center" align="center" style={{ width: "100vw", height: "100vh" }}>
      <Loader />
    </Flex>
  )
}

export default LoadingPage
