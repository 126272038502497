import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { z, ZodErrorMap } from "zod"
import { hasLowercase, hasNumber, hasUppercase } from "./string"

export function usePasswordSchema() {
  const { t } = useTranslation("validation")

  // TODO: figure out how to share this schema with BE
  return z
    .string()
    .min(8)
    .superRefine((val, ctx) => {
      if (!hasUppercase(val)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: t("string_no_uppercase") })
      }

      if (!hasLowercase(val)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: t("string_no_lowercase") })
      }

      if (!hasNumber(val)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: t("string_no_number") })
      }
    })
}

export function useDefaultZodErrorMap(): ZodErrorMap {
  const { t } = useTranslation("validation")

  return useCallback(
    (error, ctx) => {
      if (error.message) {
        return { message: error.message }
      }

      if (error.code === "invalid_type") {
        return { message: t("required") }
      }

      if (error.code === "too_big") {
        if (error.type === "number") {
          return {
            message: error.inclusive
              ? t("number_too_big_inclusive", { count: Number(error.maximum) })
              : t("number_too_big", { count: Number(error.maximum) }),
          }
        }

        return {
          message: error.inclusive
            ? t("string_too_big_inclusive", { count: Number(error.maximum) })
            : t("string_too_big", { count: Number(error.maximum) }),
        }
      }

      if (error.code === "too_small") {
        if (error.type === "number") {
          return {
            message: error.inclusive
              ? t("number_too_small_inclusive", { count: Number(error.minimum) })
              : t("number_too_small", { count: Number(error.minimum) }),
          }
        }

        return {
          message: error.inclusive
            ? t("string_too_small_inclusive", { count: Number(error.minimum) })
            : t("string_too_small", { count: Number(error.minimum) }),
        }
      }

      if (error.code === "invalid_enum_value" && (ctx.data === undefined || ctx.data === null)) {
        return { message: t("required") }
      }

      if (error.code === "invalid_string" && error.validation === "email") {
        return { message: t("invalid_email") }
      }

      if (error.code === "custom") {
        return { message: t("invalid_format") }
      }

      return { message: ctx.defaultError }
    },
    [t]
  )
}
