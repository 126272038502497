import * as React from "react"

function SvgMissingInvoices(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_MissingInvoices_svg__a">
          <path data-name="Rectangle 16831" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9137" clipPath="url(#_MissingInvoices_svg__a)">
        <path
          data-name="Path 1983"
          d="M14.196 8.428H3.59a1.314 1.314 0 00-1.319 1.307v.016l-.006 7.947a1.32 1.32 0 001.315 1.325h10.61a1.325 1.325 0 001.325-1.316V9.754A1.32 1.32 0 0014.2 8.428m-.01 8.607a.662.662 0 01-.662.662H4.252a.662.662 0 01-.662-.662v-3.308h10.6zm0-5.962H3.59V9.752h10.6z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1984"
          d="M16.829 12.239a3.714 3.714 0 011.325-.5V7.108a1.326 1.326 0 00-1.311-1.325H6.23a1.314 1.314 0 00-1.317 1.311v.013h11.916z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1985"
          d="M17.178 14.99a.573.573 0 01-.313-.748.554.554 0 01.045-.087 2.265 2.265 0 011.926-1.014 2.186 2.186 0 011.946.981 1.957 1.957 0 01.012 1.995c-.488.721-.957.94-1.209 1.4a.941.941 0 00-.122.383.593.593 0 01-.582.529.6.6 0 01-.605-.6.571.571 0 010-.063 1.653 1.653 0 01.187-.627c.313-.566.916-.9 1.266-1.4a.959.959 0 00-.888-1.506 1.118 1.118 0 00-.977.546.556.556 0 01-.688.2m2.475 5.073a.814.814 0 11-.814-.814.814.814 0 01.814.814"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgMissingInvoices
