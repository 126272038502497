import { useMedia } from "react-use"
import { media } from "../styles/stitches.config"

export function useIsMinWidth(breakpoint: number) {
  return useMedia(`(min-width: ${breakpoint}px)`)
}

export function useIsMaxWidth(breakpoint: number) {
  return !useIsMinWidth(breakpoint)
}

export function useIsDesktopVersion() {
  return useIsMinWidth(media.lg)
}

export function useIsMobileVersion() {
  return !useIsDesktopVersion()
}

export function useIsMaxMd() {
  return !useIsMinWidth(media.md)
}

export function useIsMaxXl() {
  return !useIsMinWidth(media.xl)
}

export function useIsMaxXxl() {
  return !useIsMinWidth(media.xxl)
}

export function useIsMaxSm() {
  return !useIsMinWidth(media.sm)
}

export function useIsMinXl() {
  return useIsMinWidth(media.xl)
}

export function useIsMinSm() {
  return useIsMinWidth(media.sm)
}
