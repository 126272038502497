import { lazy } from "react"
import { Route, Routes } from "react-router"

const WebRouter = lazy(() => import("./web/WebRouter").then((module) => ({ default: module.WebRouter })))

const links = {
  web: "/web/*",
}

export const MicrositeRouter = () => {
  return (
    <Routes>
      <Route path={links.web} element={<WebRouter />} />
    </Routes>
  )
}
