import { ComponentProps, ReactNode } from "react"
import { theme } from "../../styles/stitches.config"
import { Spacer } from "../spacer/Spacer"
import { SIconWrapper, SText, SWrapper } from "./Tag.styled"

type TagVariant = ComponentProps<typeof SWrapper>["variant"]

interface Props {
  text: string
  variant: TagVariant
  icon?: ReactNode
}

export default function Tag(props: Props) {
  const { text, icon, ...rest } = props

  return (
    <SWrapper {...rest} hasIcon={!!icon}>
      {icon && (
        <>
          <SIconWrapper>{icon}</SIconWrapper>

          <Spacer size={theme.space.s1} />
        </>
      )}

      <SText type="textsSmall">{text}</SText>
    </SWrapper>
  )
}
