import { useTranslation } from "react-i18next"
import MeasurementsContent from "./MeasurementsContent"
import SubSection from "../../../../components/subSection/SubSection"
import { ComponentProps } from "react"
import { SubSectionTitle } from "../../../../components/subSection/SubSection.styled"

type Props = ComponentProps<typeof MeasurementsContent>

export default function Measurements(props: Props) {
  const { t } = useTranslation("delivery_point")

  return (
    <SubSection
      width="max"
      title={<SubSectionTitle>{t("measurements_header")}</SubSectionTitle>}
      content={<MeasurementsContent {...props} />}
    />
  )
}
