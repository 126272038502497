import * as RadioGroup from "@radix-ui/react-radio-group"
import { Button } from "../../components/button/Button"
import { Flex } from "../../components/flex/Flex"
import { AddPhoto } from "../../images/xd/icons"
import { fontVariants, styled, theme } from "../../styles/stitches.config"
import { SModalTitle } from "../../components/modal/Modal.styled"

export const SFlex = styled(Flex, {
  width: "100%",
  "@lg": {
    maxWidth: theme.space.s132,
  },
})

export const SFlexFullSize = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
})

export const SFlexButton = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: theme.space.s3,
})

export const SAddPhoto = styled(AddPhoto, {
  width: theme.space.s12,
  height: theme.space.s12,
  color: theme.colors.secondary,
})

export const SButton = styled(Button, {
  height: theme.space.s52,
  padding: 0,
})

export const SInputWrapper = styled("div", {
  display: "flex",
  direction: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  height: theme.space.s32,
})

export const SFitImage = styled("img", {
  width: "100%",
  height: "100%",
  objectFit: "cover",
})

export const SLink = styled("a", {
  cursor: "pointer",
})

export const SListItem = styled("li", {
  color: theme.colors.secondary,
  fontSize: 20,
})

export const SDeliveryPointDescription = styled("div", {
  textAlign: "end",
  maxWidth: theme.sizes.s87,
  ellipsis: {},
})

export const SRadioGroupRoot = styled(RadioGroup.Root, {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s2_5,
})

export const SRadioGroupIndicator = styled(RadioGroup.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: theme.colors.secondary,
  },
})

export const SRadioGroupLabel = styled("label", {
  ...fontVariants.textsSmall,
  fontSize: theme.sizes.s4,

  paddingLeft: theme.space.s6,
})

export const SRadioGroupItemWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: "default",
      },
    },
  },
})

export const SRadioGroupItem = styled(RadioGroup.Item, {
  all: "unset",
  backgroundColor: "white",
  width: 16,
  height: 16,
  borderRadius: "100%",
  flexShrink: 0,

  borderColor: theme.colors.texts,
  borderWidth: theme.sizes.spx,
  borderStyle: "solid",

  "&[data-state='checked']": {
    borderColor: theme.colors.secondary,
  },
})

export const STitle = styled(SModalTitle, {
  display: "block",
})
