import * as React from "react"

function SvgApple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 55">
        <path data-name="Path 55" d="M0 0h24v24H0z" fill="none" />
      </g>
      <path
        data-name="Path 255"
        d="M9.751 20.02c-2.422-.014-4.425-4.958-4.425-7.476 0-4.113 3.085-5.013 4.275-5.013a4.919 4.919 0 011.613.4 3.605 3.605 0 00.921.264 2.866 2.866 0 00.661-.214 5.3 5.3 0 011.991-.481 4.082 4.082 0 013.416 1.725l.249.374-.358.271a3.017 3.017 0 00-1.447 2.488 2.808 2.808 0 001.567 2.6c.224.135.457.274.457.579 0 .2-1.587 4.468-3.891 4.468a3.129 3.129 0 01-1.314-.319 2.664 2.664 0 00-1.17-.282 3.008 3.008 0 00-.926.25 4.535 4.535 0 01-1.606.375z"
      />
      <path data-name="Path 256" d="M15.031 3.747c.06 2.164-1.487 3.665-3.033 3.571a3.448 3.448 0 013.033-3.571z" />
    </svg>
  )
}

export default SvgApple
