import { UserDetailContainer } from "./UserMenu.styled"
import Text from "../../../../Text"
import { Flex } from "../../../../flex/Flex"
// import { AvatarFallback, AvatarImage, StyledAvatar } from "./Avatar.styled"

interface UserDetailsProps {
  email: string
}

function UserDetails({ email }: UserDetailsProps) {
  return (
    <Flex align="center">
      {/*
      TODO: schovana nehotova cast
      <StyledAvatar>
        <AvatarImage alt={name.charAt(0)?.toUpperCase()} />
        <AvatarFallback>{name.charAt(0)?.toUpperCase()}</AvatarFallback>
      </StyledAvatar>
      */}
      <UserDetailContainer>
        <Text type="headerH4">{email}</Text>
      </UserDetailContainer>
    </Flex>
  )
}

export default UserDetails
