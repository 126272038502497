import { useTranslation } from "react-i18next"
import { toasts } from "./toasts"

function useCopyToClipboardUnsafe() {
  const { t } = useTranslation(["common"])
  return async (text: string) => {
    const toastOptions = { autoClose: 1000 }
    try {
      await navigator.clipboard.writeText(text)
      toasts.success(t("common:copied_to_clipboard"), toastOptions)
    } catch {
      toasts.error(t("common:failed_to_copy_to_clipboard"), toastOptions)
    }
  }
}

async function copyWithPermissions(copyFn: (text: string) => Promise<void>, text: string) {
  try {
    // typing is incomplete, so we need to cast to unknown and then to PermissionName
    const result = await navigator.permissions.query({ name: "clipboard-write" as unknown as PermissionName })
    if (result.state === "granted") {
      await copyFn(text)
    }
  } catch {
    // on some browsers the clipboard permissions API is not supported, therefor etrying the copying directly
    await copyFn(text)
  }
}

export function useCopyToClipboard() {
  const copyWithoutPermissions = useCopyToClipboardUnsafe()
  return (text: string) => copyWithPermissions(copyWithoutPermissions, text)
}
