import { HomeContent } from "../sections/home/HomeContent"
import { AppLayout } from "../components/layouts/appLayout/AppLayout"
import { trpc } from "../api/trpc/trpc"
import HomePlaceholder from "../components/placeholder/HomePlaceholder"

const HomeWithData = () => {
  const deliveryPoints = trpc.useQuery(["deliveryPoints.list"])

  if (deliveryPoints.isLoading || !deliveryPoints.data) {
    return <HomePlaceholder />
  }
  return <HomeContent deliveryPoints={deliveryPoints.data} />
}

const Home = () => {
  return (
    <AppLayout hideSideMenu hideSupplyPointSelect>
      <HomeWithData />
    </AppLayout>
  )
}

export default Home
