import { differenceInCalendarDays, endOfDay } from "date-fns"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ColorType, IconType } from "../styles/stitches.config"
import { assertUnreachable } from "./types"
import { PaymentType, PaymentMethod } from "@nano-portal/shared"

export enum DueDateStateEnum {
  OK,
  FUTURE,
  PAST,
}

export type DueDateState = {
  state: DueDateStateEnum
  days: number
}

export type DueDateStateStyle = {
  icon?: IconType
  iconColor: ColorType
  textType: string | undefined
  state: DueDateStateEnum
}

const DaysBeforeDueDateWarning = 5

export const mapDueDateState = (dueDate: Date | null): DueDateState => {
  if (!dueDate) {
    return {
      state: DueDateStateEnum.OK,
      days: 0,
    }
  }

  const now = endOfDay(new Date())
  const daysDiff = differenceInCalendarDays(dueDate, now)

  if (daysDiff < 0) {
    return {
      state: DueDateStateEnum.PAST,
      days: Math.abs(daysDiff),
    }
  } else if (daysDiff === 0 || (daysDiff > 0 && daysDiff <= DaysBeforeDueDateWarning)) {
    return {
      state: DueDateStateEnum.FUTURE,
      days: daysDiff,
    }
  } else {
    return {
      state: DueDateStateEnum.OK,
      days: daysDiff,
    }
  }
}

export const mapDueDateStateStyle = (dueDateState: DueDateState) => {
  const dueDateStateStyle: DueDateStateStyle = {
    icon: undefined,
    iconColor: "textsAlt",
    textType: undefined,
    state: dueDateState.state,
  }

  switch (dueDateState.state) {
    case DueDateStateEnum.PAST:
      dueDateStateStyle.textType = "Negative"
      dueDateStateStyle.icon = "Error"
      dueDateStateStyle.iconColor = "errors"
      break
    case DueDateStateEnum.FUTURE:
      dueDateStateStyle.textType = "Warning"
      dueDateStateStyle.icon = "Error"
      dueDateStateStyle.iconColor = "warning"
      break
    case DueDateStateEnum.OK:
      break
    default:
      assertUnreachable(dueDateState.state)
  }

  return dueDateStateStyle
}

export const usePaymentTypeToText = () => {
  const { t } = useTranslation("payments")

  return useCallback(
    (paymentType: PaymentType) => {
      switch (paymentType) {
        case PaymentType.Advance:
          return t("advance")
        case PaymentType.Billing:
          return t("billing")
        case PaymentType.Invoice:
          return t("invoice")
        case PaymentType.CreditNote:
          return t("credit_note")
        case PaymentType.DebitNote:
          return t("debit_note")
        case PaymentType.Fine:
          return t("fine")
        case PaymentType.Inclusion:
          return t("inclusion")
        case PaymentType.Claim:
          return t("claim")
        case PaymentType.SelfBillingInvoice:
          return t("self_billing_invoice")
        case PaymentType.ProductionBilling:
          return t("production_billing")
        case PaymentType.ProductionInvoiceBasis:
          return t("production_invoice_basis")
        default:
          return assertUnreachable(paymentType)
      }
    },
    [t]
  )
}

export const usePaymentMethodToTexts = (paymentMethod: PaymentMethod) => {
  const { t } = useTranslation("payments")

  switch (paymentMethod) {
    case PaymentMethod.BankOrder:
      return {
        paymentMethodName: t("payment_method_bank_order"),
        paymentMethodDescription: t("payment_method_bank_order_description"),
      }
    case PaymentMethod.BankEncashment:
      return {
        paymentMethodName: t("payment_method_bank_encashment"),
        paymentMethodDescription: t("payment_method_bank_encashment_description"),
      }
    case PaymentMethod.PostOrder:
      return {
        paymentMethodName: t("payment_method_post_order"),
        paymentMethodDescription: t("payment_method_post_order_description"),
      }
    case PaymentMethod.PostSipo:
      return {
        paymentMethodName: t("payment_method_post_sipo"),
        paymentMethodDescription: t("payment_method_post_sipo_description"),
      }
    case PaymentMethod.NextAdvances:
      return { paymentMethodName: t("payment_method_next_advances") }
    case PaymentMethod.PostRefund:
      return { paymentMethodName: t("payment_method_post_refund") }
    case PaymentMethod.BankRefund:
      return { paymentMethodName: t("payment_method_bank_refund") }
    default:
      return assertUnreachable(paymentMethod)
  }
}
