import { ColorType, IconType, theme } from "../../../styles/stitches.config"
import * as icons from "../../../images/xd/icons"

const LargeBarRadius = 8
const NormalBarRadius = 4
const SmallBarRadius = 2

export type BarSize = "large" | "normal" | "small" | "tiny"
export type BarOrientation = "up" | "down"

export const sizeToRadius = {
  large: LargeBarRadius,
  normal: NormalBarRadius,
  small: NormalBarRadius,
  tiny: SmallBarRadius,
} as const

interface Props {
  startX?: number
  startY?: number
  width: number
  height: number
  fill: ColorType
  radius?: BarSize
  orientation?: BarOrientation
  icon?: IconType
  iconColor?: ColorType
  borderColor?: ColorType
  borderWidth?: number
}

const Bar = ({
  startX: x,
  startY: y,
  width,
  height,
  radius = "large",
  fill,
  orientation,
  icon,
  iconColor,
  borderColor,
  borderWidth,
}: Props) => {
  const r = radius ? sizeToRadius[radius] : LargeBarRadius
  const h = height - r
  const w = width - 2 * r

  const Icon = icon ? icons[icon] : null
  const iconSize = width / 2

  // prevents radius inverted on other side of axis when value is too small
  if (height < sizeToRadius[radius]) {
    return null
  }

  return orientation === "down" ? (
    <g>
      <path
        d={`M${x},${y}
        v${h}
        q0,${r} ${r},${r}
        h${w}
        q${r},0 ${r},${-r}
        v${-h}
        z
    `}
        fill={theme.colors[fill].value}
        stroke={borderColor}
        strokeWidth={borderWidth}
        strokeLinecap={borderWidth ? "round" : undefined}
        strokeDasharray="2 5"
      />
      {Icon && height > iconSize ? (
        <Icon
          x={(x || 0) + iconSize / 2}
          y={(y || 0) + h - iconSize}
          width={iconSize}
          height={iconSize}
          color={iconColor ? iconColor : theme.colors.white.value}
        />
      ) : null}
    </g>
  ) : (
    <g>
      <path
        d={`M${x},${y}
       v${-h}
       q0,${-r} ${r},${-r}
       h${w}
       q${r},0 ${r},${r}
       v${h}
       z
    `}
        fill={theme.colors[fill].value}
        stroke={borderColor && theme.colors[borderColor].value}
        strokeWidth={borderWidth}
        strokeLinecap={borderWidth ? "round" : undefined}
        strokeDasharray="3"
      />
      {Icon && height > iconSize ? (
        <Icon
          x={(x || 0) + iconSize / 2}
          y={(y || 0) - h}
          width={iconSize}
          height={iconSize}
          color={iconColor ? theme.colors[iconColor].value : theme.colors.white.value}
        />
      ) : null}
    </g>
  )
}

export default Bar
