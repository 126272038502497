import * as React from "react"

function SvgAddPhoto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_AddPhoto_svg__a">
          <path data-name="Path 335" d="M366.143 127.19h24v24h-24z" fill="currentColor" />
        </clipPath>
      </defs>
      <g data-name="Group 9076" transform="translate(-366.143 -127.19)" clipPath="url(#_AddPhoto_svg__a)">
        <g data-name="Group 9075" fill="currentColor">
          <path
            data-name="Path 1980"
            d="M382.79 138.53a4.477 4.477 0 100 1.164 4.482 4.482 0 000-1.164zm-1.806 1.123a2.676 2.676 0 11.055-.528 2.685 2.685 0 01-.057.528z"
          />
          <path
            data-name="Path 1981"
            d="M389.249 144.488H387.3v-1.948a.894.894 0 10-1.788 0v1.948h-1.948a.894.894 0 100 1.788h1.948v1.948a.894.894 0 101.788 0v-1.948h1.948a.894.894 0 100-1.788z"
          />
          <path
            data-name="Path 1982"
            d="M371.19 133.745h2.82a1.819 1.819 0 001.345-.592l.794-.866a1.02 1.02 0 01.733-.331h2.926a1.017 1.017 0 01.751.331l.8.87a1.808 1.808 0 001.335.588h2.822v6.469h1.784v-6.469a1.8 1.8 0 00-1.787-1.791h-2.033a1.823 1.823 0 01-1.345-.592l-.557-.608a1.826 1.826 0 00-1.345-.592h-3.76a1.832 1.832 0 00-1.351.6l-.554.6a1.827 1.827 0 01-1.346.592h-2.034a1.8 1.8 0 00-1.789 1.789v10.743a1.8 1.8 0 001.789 1.791h10.051v-1.791H371.19z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgAddPhoto
