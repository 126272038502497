// src/utils/zod.ts
import { z } from "zod";
function objectKeysToZodEnum(object, params) {
  const keys = Object.keys(object).map(
    (key) => typeof key === "number" ? `${key}` : key
  );
  const firstKey = keys[0];
  if (firstKey === void 0) {
    throw new Error("Expecting at least one key");
  }
  const values = [firstKey, ...keys.slice(1)];
  return z.enum(values, params);
}
function preprocessEmptyStringToUndefined(schema) {
  return z.preprocess((value) => value === "" ? void 0 : value, schema);
}
function withOptionalEmptyStringAsUndefined(type) {
  return type.optional().or(z.literal("")).transform((value) => value === "" ? void 0 : value);
}

// src/types/deliveryPoints.ts
var DeliveryPointType = /* @__PURE__ */ ((DeliveryPointType2) => {
  DeliveryPointType2["Consumption"] = "consumption";
  DeliveryPointType2["Production"] = "production";
  return DeliveryPointType2;
})(DeliveryPointType || {});
var DeliveryPointStatusDescription = /* @__PURE__ */ ((DeliveryPointStatusDescription2) => {
  DeliveryPointStatusDescription2["Ending"] = "Ukon\u010Dov\xE1n\xED";
  DeliveryPointStatusDescription2["Ended"] = "Ukon\u010Deno";
  DeliveryPointStatusDescription2["Cancelled"] = "Stornov\xE1no";
  return DeliveryPointStatusDescription2;
})(DeliveryPointStatusDescription || {});
var DeliveryPointStatus = /* @__PURE__ */ ((DeliveryPointStatus2) => {
  DeliveryPointStatus2["APPROVED"] = "APPROVED";
  DeliveryPointStatus2["ACTIVE"] = "ACTIVE";
  DeliveryPointStatus2["ENDING"] = "ENDING";
  DeliveryPointStatus2["ENDED"] = "ENDED";
  DeliveryPointStatus2["CANCELLED"] = "CANCELLED";
  DeliveryPointStatus2["IN_TRANSFER"] = "IN_TRANSFER";
  return DeliveryPointStatus2;
})(DeliveryPointStatus || {});
var PaymentsFrequency = /* @__PURE__ */ ((PaymentsFrequency2) => {
  PaymentsFrequency2[PaymentsFrequency2["NoPayments"] = 0] = "NoPayments";
  PaymentsFrequency2[PaymentsFrequency2["Monthly"] = 1] = "Monthly";
  PaymentsFrequency2[PaymentsFrequency2["Quarterly"] = 3] = "Quarterly";
  PaymentsFrequency2[PaymentsFrequency2["SemiAnnually"] = 6] = "SemiAnnually";
  PaymentsFrequency2[PaymentsFrequency2["Annually"] = 12] = "Annually";
  return PaymentsFrequency2;
})(PaymentsFrequency || {});
var MeasurementType = /* @__PURE__ */ ((MeasurementType2) => {
  MeasurementType2["A"] = "A";
  MeasurementType2["B"] = "B";
  MeasurementType2["C"] = "C";
  MeasurementType2["CM"] = "CM";
  return MeasurementType2;
})(MeasurementType || {});
var ProductPriceType = /* @__PURE__ */ ((ProductPriceType2) => {
  ProductPriceType2["Fix"] = "fix";
  ProductPriceType2["Spot"] = "spot";
  return ProductPriceType2;
})(ProductPriceType || {});
var CommodityType = /* @__PURE__ */ ((CommodityType2) => {
  CommodityType2[CommodityType2["Electricity"] = 1] = "Electricity";
  return CommodityType2;
})(CommodityType || {});
var VoltageType = /* @__PURE__ */ ((VoltageType2) => {
  VoltageType2["Low"] = "Low";
  VoltageType2["High"] = "High";
  return VoltageType2;
})(VoltageType || {});
var readingTypes = {
  CONTROL: "CONTROL",
  EXTRA_INVOICE: "EXTRA_INVOICE"
};
var readingTypeSchema = objectKeysToZodEnum(readingTypes);

// src/types/flexi2022.ts
var Flexi2022Variant = /* @__PURE__ */ ((Flexi2022Variant2) => {
  Flexi2022Variant2["Flexi2022"] = "flexi2022";
  Flexi2022Variant2["Flexi1222"] = "flexi1222";
  Flexi2022Variant2["FlexiGreen24"] = "flexigreen24";
  return Flexi2022Variant2;
})(Flexi2022Variant || {});

// src/types/grid.ts
var Tdd = /* @__PURE__ */ ((Tdd2) => {
  Tdd2["TDD1"] = "TDD1";
  Tdd2["TDD2"] = "TDD2";
  Tdd2["TDD3"] = "TDD3";
  Tdd2["TDD4"] = "TDD4";
  Tdd2["TDD5"] = "TDD5";
  Tdd2["TDD6"] = "TDD6";
  Tdd2["TDD7"] = "TDD7";
  Tdd2["TDD8"] = "TDD8";
  return Tdd2;
})(Tdd || {});
var DistributionTariff = /* @__PURE__ */ ((DistributionTariff2) => {
  DistributionTariff2["D01d"] = "D01d";
  DistributionTariff2["D02d"] = "D02d";
  DistributionTariff2["D25d"] = "D25d";
  DistributionTariff2["D26d"] = "D26d";
  DistributionTariff2["D27d"] = "D27d";
  DistributionTariff2["D35d"] = "D35d";
  DistributionTariff2["D45d"] = "D45d";
  DistributionTariff2["D56d"] = "D56d";
  DistributionTariff2["D57d"] = "D57d";
  DistributionTariff2["D61d"] = "D61d";
  DistributionTariff2["C01d"] = "C01d";
  DistributionTariff2["C02d"] = "C02d";
  DistributionTariff2["C03d"] = "C03d";
  DistributionTariff2["C25d"] = "C25d";
  DistributionTariff2["C26d"] = "C26d";
  DistributionTariff2["C27d"] = "C27d";
  DistributionTariff2["C35d"] = "C35d";
  DistributionTariff2["C45d"] = "C45d";
  DistributionTariff2["C46d"] = "C46d";
  DistributionTariff2["C55d"] = "C55d";
  DistributionTariff2["C56d"] = "C56d";
  DistributionTariff2["C62d"] = "C62d";
  return DistributionTariff2;
})(DistributionTariff || {});

// src/types/invoices.ts
import { z as z2 } from "zod";
var invoiceSpotDataSchema = z2.object({
  dateTime: z2.date(),
  date: z2.date().nullish().describe("DEPRECATED - for backwards compatibility with cached client only"),
  hour: z2.number().nullish().describe("DEPRECATED - for backwards compatibility with cached client only"),
  unitPriceKWh: z2.number(),
  ratio: z2.number().optional(),
  constant: z2.number().optional(),
  quantityKWh: z2.number(),
  totalPrice: z2.number(),
  vatMultiplier: z2.number()
});
var spotInvoiceDetailSchema = z2.object({
  contractId: z2.number().int(),
  ean: z2.string(),
  billedSince: z2.date(),
  billedUntil: z2.date(),
  deliveryPointType: z2.enum(["consumption", "production"]),
  deliveryPointId: z2.number().int(),
  spotData: z2.array(invoiceSpotDataSchema)
});

// src/types/overview.ts
var OverviewUnit = /* @__PURE__ */ ((OverviewUnit2) => {
  OverviewUnit2["Currency"] = "currency";
  OverviewUnit2["Energy"] = "energy";
  return OverviewUnit2;
})(OverviewUnit || {});
var OverviewChartType = /* @__PURE__ */ ((OverviewChartType2) => {
  OverviewChartType2["TotalPaymentChart"] = "totalPaymentChart";
  OverviewChartType2["ConsumptionChart"] = "consumptionChart";
  OverviewChartType2["ProductionChart"] = "productionChart";
  OverviewChartType2["PricePerUnitChart"] = "pricePerUnitChart";
  return OverviewChartType2;
})(OverviewChartType || {});
var OverviewGroupParam = /* @__PURE__ */ ((OverviewGroupParam2) => {
  OverviewGroupParam2["Months"] = "months";
  OverviewGroupParam2["Years"] = "years";
  OverviewGroupParam2["Days"] = "days";
  OverviewGroupParam2["Hours"] = "hours";
  return OverviewGroupParam2;
})(OverviewGroupParam || {});
var OverviewGroup = /* @__PURE__ */ ((OverviewGroup2) => {
  OverviewGroup2["Months"] = "months";
  OverviewGroup2["Years"] = "years";
  OverviewGroup2["Days"] = "days";
  OverviewGroup2["Hours"] = "hours";
  return OverviewGroup2;
})(OverviewGroup || {});
var OverviewState = /* @__PURE__ */ ((OverviewState2) => {
  OverviewState2["Known"] = "known";
  OverviewState2["Unknown"] = "unknown";
  OverviewState2["UnknownVolumes"] = "unknownVolumes";
  return OverviewState2;
})(OverviewState || {});
var EnergyUnit = /* @__PURE__ */ ((EnergyUnit2) => {
  EnergyUnit2["Wh"] = "Wh";
  EnergyUnit2["kWh"] = "kWh";
  EnergyUnit2["MWh"] = "MWh";
  EnergyUnit2["GWh"] = "GWh";
  EnergyUnit2["TWh"] = "TWh";
  return EnergyUnit2;
})(EnergyUnit || {});
var ConsumptionPeriodType = /* @__PURE__ */ ((ConsumptionPeriodType2) => {
  ConsumptionPeriodType2["ConfirmedHistory"] = "confirmedHistory";
  ConsumptionPeriodType2["UnconfirmedHistory"] = "unconfirmedHistory";
  ConsumptionPeriodType2["Prediction"] = "consumptionPrediction";
  ConsumptionPeriodType2["Billing"] = "billing";
  return ConsumptionPeriodType2;
})(ConsumptionPeriodType || {});
var ProductionPeriodType = /* @__PURE__ */ ((ProductionPeriodType2) => {
  ProductionPeriodType2["ConfirmedHistory"] = "confirmedHistory";
  ProductionPeriodType2["Invoiced"] = "invoiced";
  ProductionPeriodType2["NonInvoiced"] = "nonInvoiced";
  ProductionPeriodType2["Prediction"] = "productionPrediction";
  return ProductionPeriodType2;
})(ProductionPeriodType || {});

// src/types/payments.ts
var PaymentState = /* @__PURE__ */ ((PaymentState2) => {
  PaymentState2["Invoiced"] = "invoiced";
  PaymentState2["Uninvoiced"] = "uninvoiced";
  PaymentState2["Paid"] = "paid";
  PaymentState2["Unpaid"] = "unpaid";
  PaymentState2["Cancelled"] = "cancelled";
  PaymentState2["Included"] = "included";
  PaymentState2["Unknown"] = "unknownPaymentState";
  return PaymentState2;
})(PaymentState || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Advance"] = "advance";
  PaymentType2["Invoice"] = "invoice";
  PaymentType2["Billing"] = "billing";
  PaymentType2["CreditNote"] = "creditNote";
  PaymentType2["DebitNote"] = "debitNote";
  PaymentType2["Fine"] = "fine";
  PaymentType2["Inclusion"] = "inclusion";
  PaymentType2["Claim"] = "claim";
  PaymentType2["SelfBillingInvoice"] = "selfBillingInvoice";
  PaymentType2["ProductionBilling"] = "productionBilling";
  PaymentType2["ProductionInvoiceBasis"] = "productionInvoiceBasis";
  return PaymentType2;
})(PaymentType || {});
var productionPaymentTypes = [
  "productionBilling" /* ProductionBilling */,
  "productionInvoiceBasis" /* ProductionInvoiceBasis */,
  "selfBillingInvoice" /* SelfBillingInvoice */
];
var PaymentDueDateState = /* @__PURE__ */ ((PaymentDueDateState2) => {
  PaymentDueDateState2["FarFromDueDate"] = "farFromDueDate";
  PaymentDueDateState2["ApproachingDueDate"] = "approachingDueDate";
  PaymentDueDateState2["AfterDueDate"] = "afterDueDate";
  PaymentDueDateState2["Unknown"] = "dueDateUnknown";
  return PaymentDueDateState2;
})(PaymentDueDateState || {});
var PaymentMethod = /* @__PURE__ */ ((PaymentMethod2) => {
  PaymentMethod2["BankEncashment"] = "bankEncashment";
  PaymentMethod2["PostOrder"] = "postOrder";
  PaymentMethod2["PostSipo"] = "postSipo";
  PaymentMethod2["BankOrder"] = "bankOrder";
  PaymentMethod2["BankRefund"] = "bankRefund";
  PaymentMethod2["NextAdvances"] = "nextAdvances";
  PaymentMethod2["PostRefund"] = "postRefund";
  return PaymentMethod2;
})(PaymentMethod || {});
var TypeFilter = /* @__PURE__ */ ((TypeFilter2) => {
  TypeFilter2["Advances"] = "advances";
  TypeFilter2["Invoices"] = "invoices";
  TypeFilter2["Billing"] = "billing";
  TypeFilter2["CreditNote"] = "creditNote";
  TypeFilter2["DebitNote"] = "debitNote";
  TypeFilter2["Fine"] = "fine";
  TypeFilter2["Inclusion"] = "inclusion";
  TypeFilter2["Claim"] = "claim";
  TypeFilter2["SelfBillingInvoice"] = "selfBillingInvoice";
  TypeFilter2["ProductionBilling"] = "productionBilling";
  TypeFilter2["ProductionInvoiceBasis"] = "productionInvoiceBasis";
  return TypeFilter2;
})(TypeFilter || {});
var StateFilter = /* @__PURE__ */ ((StateFilter2) => {
  StateFilter2["Paid"] = "paid";
  StateFilter2["Unpaid"] = "unpaid";
  StateFilter2["Cancelled"] = "cancelled";
  StateFilter2["Included"] = "included";
  StateFilter2["Invoiced"] = "invoiced";
  StateFilter2["Uninvoiced"] = "uninvoiced";
  StateFilter2["Unknown"] = "unknownState";
  return StateFilter2;
})(StateFilter || {});
var DueDateFilter = /* @__PURE__ */ ((DueDateFilter2) => {
  DueDateFilter2["ApproachingDueDate"] = "approachingDueDate";
  DueDateFilter2["AfterDueDate"] = "afterDueDate";
  DueDateFilter2["FarFromDueDate"] = "farFromDueDate";
  DueDateFilter2["Unknown"] = "unknownDueDate";
  return DueDateFilter2;
})(DueDateFilter || {});

// src/types/web.ts
var optimizations = {
  "0": "0",
  "25": "25",
  "50": "50",
  "75": "75",
  "100": "100"
};
function isOptimization(value) {
  return value in optimizations;
}
var ProdAndConsGroup = /* @__PURE__ */ ((ProdAndConsGroup2) => {
  ProdAndConsGroup2["Months"] = "months";
  ProdAndConsGroup2["Hours"] = "hours";
  return ProdAndConsGroup2;
})(ProdAndConsGroup || {});

// src/utils/array.ts
function dropDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
function safeIndex(arr, index) {
  const item = arr[index];
  if (item === void 0) {
    throw new Error(`Property in Array length of ${arr.length} at index ${index} is undefined.`);
  }
  return item;
}
function genArray(length) {
  return [...Array.from({ length }).keys()];
}

// src/utils/convert.ts
var megawattHourToKilowattHour = (megawattHour) => {
  return megawattHour * 1e3;
};
var killowattHourToMegawattHour = (kilowattHour) => {
  return kilowattHour / 1e3;
};
var pricePerMwhToPricePerKwh = (pricePerMwh) => {
  return pricePerMwh / 1e3;
};
var pricePerKwhToPricePerMwh = (pricePerMwh) => {
  return pricePerMwh * 1e3;
};

// src/utils/date.ts
var getThisYear = () => {
  const now = /* @__PURE__ */ new Date();
  return {
    // this year, january 1st at 00:00, december 31st at 23:59 - no date-fns in shared
    from: new Date(now.getFullYear(), 0, 1, 0, 0, 0),
    to: new Date(now.getFullYear(), 11, 31, 23, 59, 59)
  };
};
var getDefaultFilterDateRange = () => {
  return getThisYear();
};
function maxOfTwoDates(first, second) {
  return first > second ? first : second;
}
function minOfTwoDates(first, second) {
  return first < second ? first : second;
}

// src/utils/deliveryPoints.ts
var defaultMeasurementsPerPage = 5;
var defaultMeasurementsOffset = 0;
var CEZ_DISTRIBUTOR_CODE = "cez";
function isCezDistributor(distributorCode) {
  return distributorCode.toLowerCase() === CEZ_DISTRIBUTOR_CODE;
}
function isContinuousMeasurement(measurementType) {
  if (!measurementType) {
    return false;
  }
  return measurementType === "B" /* B */ || measurementType === "A" /* A */;
}

// src/utils/object.ts
function copyKeysToValues(record) {
  return Object.fromEntries(Object.keys(record).map((key) => [key, key]));
}

// src/utils/overview.ts
var defaultOverviewUnit = "currency" /* Currency */;
var defaultOverviewGroup = "months" /* Months */;
var defaultConsumptionChartType = "totalPaymentChart" /* TotalPaymentChart */;
var defaultProductionChartType = "totalPaymentChart" /* TotalPaymentChart */;

// src/utils/types.ts
var enumFromStringValue = (enm, value) => {
  for (const key in enm) {
    const enmValue = enm[key];
    if (enmValue === value) {
      return value;
    }
  }
};
var enumFromStringValueWithDefault = (enm, value, defaultValue) => enumFromStringValue(enm, value) ?? defaultValue;
var getAllEnumValues = (enm) => {
  const enumValues = [];
  for (const key in enm) {
    const value = enm[key];
    if (!value) {
      throw new Error(`Error while parsing enum - ${key} not found`);
    }
    enumValues.push(value);
  }
  return enumValues;
};
function isPresent(value) {
  return !!value;
}

// src/utils/payments.ts
var defaultPaymentTypeFilter = getAllEnumValues(TypeFilter);
var defaultPaymentStateFilter = getAllEnumValues(StateFilter);
var defaultPaymentDueDateFilter = getAllEnumValues(DueDateFilter);
var defaultPaymentsFilter = {
  state: defaultPaymentStateFilter,
  type: defaultPaymentTypeFilter,
  dueDate: defaultPaymentDueDateFilter
};

// src/utils/string.ts
function containsOnlyDigits(string) {
  for (const char of string) {
    if (char < "0" || char > "9") {
      return false;
    }
  }
  return string.length > 0;
}
function normalized(string, options) {
  if (isNil(string)) {
    return void 0;
  }
  let result = string.toLowerCase().trim();
  if (options?.stripDiactrics) {
    result = result.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  }
  return result;
}

// src/utils/validation.ts
function isPhoneNumber(phone) {
  return /^(\+\d{1,3})?\d{9,12}$/i.test(phone);
}
function isOrientationNumber(string) {
  if (string === void 0) {
    return true;
  }
  if (string.length > 4) {
    return false;
  }
  for (let i = 0; i < string.length; ++i) {
    const char = string[i] ?? "";
    if (i < 3 && (char < "0" || char > "9")) {
      return false;
    }
    if (i === 3 && (char.toLocaleLowerCase() < "a" || char.toLowerCase() > "z")) {
      return false;
    }
    return true;
  }
}
var EAN_LENGTH = 18;
var EAN_PREFIX = "8591824";
function isValidEan(value) {
  if (value.length !== EAN_LENGTH) {
    return false;
  }
  const barcode = value.slice(0, -1);
  const lastIndex = barcode.length - 1;
  let sum = 0;
  for (let i = 0; i <= lastIndex; ++i) {
    const digit = Number.parseInt(barcode[lastIndex - i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += i % 2 === 0 ? digit * 3 : digit;
  }
  const mod = sum % 10;
  const calculatedChecksum = mod === 0 ? 0 : 10 - mod;
  const givenChecksum = value.slice(-1);
  return calculatedChecksum.toString() === givenChecksum;
}
function isValidBankAccountPrefix(value) {
  if (value === void 0) {
    return true;
  }
  const prefixWeights = [10, 5, 8, 4, 2, 1];
  const string = value.padStart(prefixWeights.length, "0");
  let sum = 0;
  for (const [i, prefixWeight] of prefixWeights.entries()) {
    const digit = Number.parseInt(string[i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += digit * prefixWeight;
  }
  return sum % 11 === 0;
}
function isValidBankAccountNumber(value) {
  const baseWeights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
  const string = value.padStart(baseWeights.length, "0");
  let sum = 0;
  for (const [i, baseWeight] of baseWeights.entries()) {
    const digit = Number.parseInt(string[i] ?? "");
    if (Number.isNaN(digit)) {
      return false;
    }
    sum += digit * baseWeight;
  }
  return sum % 11 === 0;
}
var POSTAL_CODE_LENGTH = 5;
function isPostalCode(value) {
  if (value.length !== POSTAL_CODE_LENGTH) {
    return false;
  }
  const firstDigit = value[0] ?? "";
  if (firstDigit < "1" || firstDigit > "9") {
    return false;
  }
  return containsOnlyDigits(value);
}
function isNil(value) {
  return value === null || value === void 0;
}
function isNotNil(value) {
  return value !== null && value !== void 0;
}

// src/utils/format.ts
function formatStreet(address) {
  return `${address.street} ${address.descriptiveNumber}${address.orientationNumber ? `/${address.orientationNumber}` : ""}`;
}
function formatCircuitBreaker(circuitBreaker) {
  return `${circuitBreaker.phases} X ${circuitBreaker.capacity}A`;
}
function formatBankAccount(bankAccount) {
  return `${bankAccount.prefix ? `${bankAccount.prefix}-` : ""}${bankAccount.number}/${bankAccount.bankCode}`;
}

// src/utils/number.ts
function sumBy(items, key) {
  let sum = 0;
  for (const item of items) {
    sum += item[key];
  }
  return sum;
}
function round(num, decimals = 2) {
  return Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;
}
function negative(num) {
  return isNil(num) ? null : -Math.abs(num);
}
function positive(num) {
  return isNil(num) ? null : Math.abs(num);
}
function fixNegativeZero(value) {
  return value === 0 ? 0 : value;
}
var sumNullishValues = (a, b) => {
  if (isNotNil(a) && isNotNil(b)) {
    return a + b;
  }
  if (isNotNil(a)) {
    return a;
  }
  if (isNotNil(b)) {
    return b;
  }
  return null;
};

// src/constants.ts
var METER_READING_DAYS_UNTIL = 25;
export {
  CommodityType,
  ConsumptionPeriodType,
  DeliveryPointStatus,
  DeliveryPointStatusDescription,
  DeliveryPointType,
  DistributionTariff,
  DueDateFilter,
  EAN_LENGTH,
  EAN_PREFIX,
  EnergyUnit,
  Flexi2022Variant,
  METER_READING_DAYS_UNTIL,
  MeasurementType,
  OverviewChartType,
  OverviewGroup,
  OverviewGroupParam,
  OverviewState,
  OverviewUnit,
  PaymentDueDateState,
  PaymentMethod,
  PaymentState,
  PaymentType,
  PaymentsFrequency,
  ProdAndConsGroup,
  ProductPriceType,
  ProductionPeriodType,
  StateFilter,
  Tdd,
  TypeFilter,
  VoltageType,
  containsOnlyDigits,
  copyKeysToValues,
  defaultConsumptionChartType,
  defaultMeasurementsOffset,
  defaultMeasurementsPerPage,
  defaultOverviewGroup,
  defaultOverviewUnit,
  defaultPaymentDueDateFilter,
  defaultPaymentStateFilter,
  defaultPaymentTypeFilter,
  defaultPaymentsFilter,
  defaultProductionChartType,
  dropDuplicates,
  enumFromStringValue,
  enumFromStringValueWithDefault,
  fixNegativeZero,
  formatBankAccount,
  formatCircuitBreaker,
  formatStreet,
  genArray,
  getAllEnumValues,
  getDefaultFilterDateRange,
  invoiceSpotDataSchema,
  isCezDistributor,
  isContinuousMeasurement,
  isNil,
  isNotNil,
  isOptimization,
  isOrientationNumber,
  isPhoneNumber,
  isPostalCode,
  isPresent,
  isValidBankAccountNumber,
  isValidBankAccountPrefix,
  isValidEan,
  killowattHourToMegawattHour,
  maxOfTwoDates,
  megawattHourToKilowattHour,
  minOfTwoDates,
  negative,
  normalized,
  objectKeysToZodEnum,
  positive,
  preprocessEmptyStringToUndefined,
  pricePerKwhToPricePerMwh,
  pricePerMwhToPricePerKwh,
  productionPaymentTypes,
  readingTypeSchema,
  readingTypes,
  round,
  safeIndex,
  spotInvoiceDetailSchema,
  sumBy,
  sumNullishValues,
  withOptionalEmptyStringAsUndefined
};
