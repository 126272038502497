import { SContainer } from "./TopMenu.styled"
import UserMenu from "./userMenu/UserMenu"
import { Flex } from "../../../flex/Flex"
import { theme } from "../../../../styles/stitches.config"
import { Div } from "../../../div/Div"
import UserSideMenu from "../userSideMenu/UserSideMenu"
import TopMenuNanoEnergiesLogo from "./TopMenuNanoEnergiesLogo"
import DeliveryPointSelect from "./deliveryPointSelect/DeliveryPointSelect"
import useDeliveryPointMenuData from "./supplyPointSelect/hooks/useDeliveryPointMenuData"
import { trpc } from "../../../../api/trpc/trpc"

interface Props {
  hideSupplyPointSelect?: boolean
}

function TopMenu({ hideSupplyPointSelect }: Props) {
  const [selected, deliveryPoints] = useDeliveryPointMenuData()
  const { data } = trpc.useQuery(["users.myself"])

  return (
    <SContainer>
      <Flex align="center" justify="center" css={{ height: "inherit" }}>
        <Flex
          align="center"
          justify="center"
          css={{ borderRight: theme.borderStyles.thin, "@lg": { display: "none" } }}
        >
          <UserSideMenu />
        </Flex>
        <TopMenuNanoEnergiesLogo />
        {!hideSupplyPointSelect && <DeliveryPointSelect available={deliveryPoints.data} selected={selected} />}
      </Flex>

      <Flex css={{ height: "inherit" }}>
        <Div css={{ display: "none", "@lg": { display: "flex" } }}>
          <UserMenu email={data?.email || ""} />
        </Div>
      </Flex>
    </SContainer>
  )
}

export default TopMenu
