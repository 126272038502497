import { useNavigate } from "react-router-dom"
import { Button } from "../components/button/Button"
import { Flex } from "../components/flex/Flex"
import { AuthLayout } from "../components/layouts/authLayout/AuthLayout"
import { Spacer } from "../components/spacer/Spacer"
import Text from "../components/Text"
import { LINKS } from "../constants/links"
import { theme } from "../styles/stitches.config"

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <AuthLayout>
      <Flex direction="column" justify="center" align="center">
        <Text type="headerH1">Page not found</Text>

        <Spacer size={theme.space.s12} />

        <Button onClick={() => navigate(LINKS.home)}>Go home</Button>
      </Flex>
    </AuthLayout>
  )
}
