import * as React from "react"

function SvgFaceNeutral(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_FaceNeutral_svg__a">
          <path
            data-name="Rectangle 17077"
            transform="translate(-2907 999)"
            fill="#ffc200"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 22" transform="translate(2907 -999)" clipPath="url(#_FaceNeutral_svg__a)">
        <path
          data-name="Union 30"
          d="M-2906 1023a23 23 0 1123 23 23.026 23.026 0 01-23-23zm2.176 0a20.824 20.824 0 1020.824-20.824 20.847 20.847 0 00-20.824 20.824zm33.659 0a2.183 2.183 0 112.183 2.183 2.184 2.184 0 01-2.183-2.183zm-30.035 0a2.184 2.184 0 112.184 2.183 2.184 2.184 0 01-2.184-2.183zm11.263 2.072a1.088 1.088 0 010-2.176h11.876a1.088 1.088 0 110 2.176z"
          fill="#ffc200"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgFaceNeutral
