import { range } from "lodash"
import { useState } from "react"
import { theme } from "../../styles/stitches.config"
import { Flex } from "../flex/Flex"
import { Spacer } from "../spacer/Spacer"
import Text from "../Text"
import { SArrowButton, SPageButton } from "./Pagination.styled"
import * as icons from "../../images/xd/icons"

const displayedPageButtonsCount = 7
const continuousButtonSequenceSize = 5

interface PageButtonProps {
  pageNumber: number
  active?: boolean
  onClick?: (pageNumber: number) => void
}

const PageButton = ({ pageNumber, active, onClick }: PageButtonProps) => {
  return (
    <SPageButton onClick={() => onClick?.(pageNumber)}>
      <Text type={active ? "menuItemActive" : "textsLarge"}>{pageNumber}</Text>
    </SPageButton>
  )
}

interface ArrowButtonProps {
  disabled?: boolean
  arrow: "ArrowForward" | "ArrowBack"
  onClick?: () => void
}

const ArrowButton = ({ disabled, arrow, onClick }: ArrowButtonProps) => {
  const Arrow = icons[arrow]
  return (
    <SArrowButton disabled={disabled} onClick={disabled ? undefined : onClick}>
      <Arrow width={24} color={disabled ? theme.colors.textsDisabeled.value : undefined} />
    </SArrowButton>
  )
}

interface PaginationProps {
  itemsPerPage: number
  totalItems: number
  onPageChange?: (pageNumber: number) => void
}

export const Pagination = ({ itemsPerPage, totalItems, onPageChange }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    onPageChange?.(newPage)
  }

  const handlePageShift = (direction: "increment" | "decrement") => {
    setCurrentPage((prev) => {
      const newPage = direction === "increment" ? prev + 1 : prev - 1
      onPageChange?.(newPage)
      return newPage
    })
  }

  const pageCount = Math.ceil(totalItems / itemsPerPage)
  if (pageCount === 1) {
    return null
  }

  const pageRange = range(1, pageCount + 1)

  return (
    <Flex css={{ gap: theme.space.s1 }}>
      <ArrowButton
        arrow="ArrowBack"
        disabled={currentPage === 1 || totalItems === 0}
        onClick={() => handlePageShift("decrement")}
      />

      <Spacer size={theme.space.s2} />

      {pageRange.map((p) => {
        if (
          pageCount <= displayedPageButtonsCount || // if there's just few pages, rendering all page numbers
          p === 1 || // always rendering first page number
          p === pageCount || // always rendering last page number
          p - 1 === currentPage || // always rendering page number next to the current page
          p + 1 === currentPage ||
          p === currentPage ||
          (pageCount - currentPage <= continuousButtonSequenceSize - 2 && // if current page number is in the longest possible page sequence, we render the whole sequence
            pageCount - p <= continuousButtonSequenceSize - 1) ||
          (currentPage <= continuousButtonSequenceSize - 1 && p <= continuousButtonSequenceSize)
        ) {
          return <PageButton key={p} pageNumber={p} active={p === currentPage} onClick={handlePageChange} />
        }

        if (p - 1 === 1 || pageCount - p === 1) {
          // if current page is somewhere in the middle, we render three dots next to first and last page
          return (
            <SPageButton key={p} disabled>
              <Text type="textsLarge">...</Text>
            </SPageButton>
          )
        }
      })}
      <Spacer size={theme.space.s2} />

      <ArrowButton
        arrow="ArrowForward"
        disabled={currentPage === pageCount || totalItems === 0}
        onClick={() => handlePageShift("increment")}
      />
    </Flex>
  )
}
