import React, { useCallback, useMemo } from "react"
import { FilterContent, CheckboxContainer } from "./Filter.styled"
import * as Popover from "@radix-ui/react-popover"
import Text from "../Text"
import { Spacer } from "../spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import Checkbox from "../checkbox/Checkbox"
import { Flex } from "../flex/Flex"
import { groupBy } from "lodash"
import SquareButton from "../button/SquareButton"

export interface FilterItem<T extends string> {
  id: T
  name: string
  group: string
  enabled?: boolean
}
interface Props<T extends string = string> {
  items: FilterItem<T>[]
  onChange: (changedItems: FilterItem<T>[]) => void
}

export default function Filter<T extends string = string>({ items, onChange }: Props<T>) {
  const groups = useMemo(() => groupBy(items, "group"), [items])

  const _onChanged = useCallback(
    (item: FilterItem<T>) => {
      const newItems = [...items]
      const indexOfItem = newItems.findIndex((i) => i.id === item.id)

      if (indexOfItem !== -1) {
        newItems[indexOfItem] = { ...item, enabled: !item.enabled }
      }

      onChange(newItems)
    },
    [items, onChange]
  )

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <SquareButton icon="Filter" />
      </Popover.Trigger>
      <FilterContent align="end">
        {Object.keys(groups).map((groupName) => (
          <React.Fragment key={groupName}>
            <Text type="headerH5">{groupName}</Text>
            <Spacer size={theme.space.s2} />
            {groups[groupName]?.map((item) => (
              <React.Fragment key={item.id}>
                <Flex justify="between" align="center">
                  <Text type="textsLarge">{item.name}</Text>
                  <CheckboxContainer>
                    <Checkbox
                      checked={item.enabled}
                      onChange={() => {
                        _onChanged(item)
                      }}
                    />
                  </CheckboxContainer>
                </Flex>
                <Spacer size={theme.space.s2} />
              </React.Fragment>
            ))}
            <Spacer size={theme.space.s6} />
          </React.Fragment>
        ))}
      </FilterContent>
    </Popover.Root>
  )
}
