import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/react"
import { TRPCClientError } from "@trpc/client"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { trpc } from "../../api/trpc/trpc"
import { Button } from "../../components/button/Button"
import { Flex } from "../../components/flex/Flex"
import { AuthLayout } from "../../components/layouts/authLayout/AuthLayout"
import PasswordInput from "../../components/passwordInput/PasswordInput"
import { Spacer } from "../../components/spacer/Spacer"
import { LINKS } from "../../constants/links"
import LoadingPage from "../../pages/Loading"
import { theme } from "../../styles/stitches.config"
import { toasts } from "../../utils/toasts"
import { SForm, SLogo } from "./ResetPasswordContent.styled"
import { ResetPasswordFormValues, useResetPasswordFormSchema } from "./ResetPasswordContent.utils"

export function ResetPasswordContent() {
  const { t } = useTranslation(["auth", "common"])

  const navigate = useNavigate()
  const [search] = useSearchParams()
  const token = search.get("token") ?? ""

  const resetPasswordSessionQuery = trpc.useQuery(["auth.resetPasswordSession", { token }])
  const resetPasswordMutation = trpc.useMutation("auth.resetPassword", { onError: undefined })

  const { schema } = useResetPasswordFormSchema()
  const form = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  async function onSubmit(formValues: ResetPasswordFormValues) {
    try {
      await resetPasswordMutation.mutateAsync({ newPassword: formValues.password, token })
      navigate(LINKS.authLogin)
      toasts.success(t("auth:reset_password_success"))
    } catch (error) {
      if (error instanceof TRPCClientError) {
        if (error.data.code === "NOT_FOUND") {
          toasts.error(t("auth:reset_password_user_does_not_exist"))
          return
        }

        if (error.data.code === "UNAUTHORIZED") {
          toasts.error(t("common:generic_error"))
          return
        }
      }

      captureException(error)
      toasts.error(t("common:generic_error"))
    }
  }

  if (resetPasswordSessionQuery.isLoading) {
    return <LoadingPage />
  }

  if (!resetPasswordSessionQuery.data?.valid || resetPasswordSessionQuery.isError) {
    navigate(LINKS.authLogin)
    toasts.error(t("auth:token_is_not_valid"))
    return null
  }

  return (
    <AuthLayout>
      <SForm
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Flex justify="center">
          <SLogo />
        </Flex>

        <Spacer size={theme.space.s14} />

        <PasswordInput
          label={t("auth:reset_password_first_input_label")}
          error={form.formState.errors.password?.message}
          formRegisterProps={form.register("password")}
          placeholder={t("auth:reset_password_first_input_placeholder")}
        />

        <Spacer size={theme.space.s12} />

        <PasswordInput
          label={t("auth:reset_password_second_input_label")}
          error={form.formState.errors.passwordAgain?.message}
          formRegisterProps={form.register("passwordAgain")}
          placeholder={t("auth:reset_password_second_input_placeholder")}
        />

        <Spacer size={theme.space.s16} />

        <Button variant="primary" type="submit" isFullwidth disabled={form.formState.isSubmitting}>
          {t("auth:reset_password_submit_button")}
        </Button>
      </SForm>
    </AuthLayout>
  )
}
