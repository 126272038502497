import { IconHelp } from "@tabler/icons-react"
import { NoDocs } from "../../../../images/xd/icons"
import { keyframes, styled, theme } from "../../../../styles/stitches.config"
import { contractInfoCardHeight } from "../contractInfo/ContractInfo.styled"
import * as HoverCard from "@radix-ui/react-hover-card"

export const SMissingDocuments = styled("button", {
  all: "unset",
  cursor: "pointer",

  boxSizing: "border-box",

  display: "flex",
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s4,
  gap: theme.space.s4,

  minHeight: contractInfoCardHeight,
  alignItems: "center",

  "&:focus": {
    borderColor: theme.colors.secondary,
    borderWidth: theme.sizes.spx,
    borderStyle: "solid",
  },
})

export const SIcon = styled(NoDocs, {
  width: theme.sizes.s15.value,
  height: theme.sizes.s15.value,
  flexShrink: 0,
})

export const SIconHelp = styled(IconHelp, {
  width: theme.sizes.s5,
  flexShrink: 0,
  alignSelf: "flex-end",

  color: theme.colors.textsAlt,
})

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideUpAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(-2px)" },
})

export const SHoverContent = styled(HoverCard.Content, {
  padding: theme.space.s6,
  backgroundColor: theme.colors.white,

  boxShadow: theme.shadows.default,
  maxWidth: theme.sizes.s90,

  noReducedMotion: {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",

    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
    },
    '&[data-state="closed"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
    },
  },
})

export const SStrong = styled("span", {
  color: theme.colors.warning,
  fontWeight: theme.fontWeights.semiBold,
})
