import * as React from "react"

function SvgImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 268" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 269"
        d="M21 19V5a2.006 2.006 0 00-2-2H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2zM8.9 13.98l2.1 2.53 3.1-3.99a.5.5 0 01.8.01l3.51 4.68a.5.5 0 01-.4.8H6.02a.5.5 0 01-.39-.81L8.12 14a.493.493 0 01.78-.02z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgImage
