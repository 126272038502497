import { useTranslation } from "react-i18next"
import * as zod from "zod"

export function useForgotPasswordFormSchema() {
  const { t } = useTranslation("validation")

  const schema = zod.object({
    email: zod.string().email(t("invalid_email")),
  })

  return { schema }
}

export type ForgotPasswordFormValues = zod.infer<ReturnType<typeof useForgotPasswordFormSchema>["schema"]>
