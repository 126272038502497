import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/react"
import { TRPCClientError } from "@trpc/client"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { trpc } from "../../api/trpc/trpc"
import { Button } from "../../components/button/Button"
import { Flex } from "../../components/flex/Flex"
import { FormControl } from "../../components/form/FormControl"
import { AuthLayout } from "../../components/layouts/authLayout/AuthLayout"
import { Spacer } from "../../components/spacer/Spacer"
import Text from "../../components/Text"
import { LINKS } from "../../constants/links"
import { theme } from "../../styles/stitches.config"
import { toasts } from "../../utils/toasts"
import { SChevronLeft, SForm, SLogo } from "./ForgotPasswordContent.styled"
import { ForgotPasswordFormValues, useForgotPasswordFormSchema } from "./ForgotPasswordContent.utils"

export function ForgotPasswordContent() {
  const { t } = useTranslation(["auth", "common"])
  const navigate = useNavigate()

  const forgotPasswordMutation = trpc.useMutation("auth.forgotPassword", { onError: undefined })

  const { schema } = useForgotPasswordFormSchema()
  const form = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  async function onSubmit(formValues: ForgotPasswordFormValues) {
    try {
      await forgotPasswordMutation.mutateAsync({ email: formValues.email })
      navigate(LINKS.authForgotPasswordSuccess)
    } catch (error) {
      if (error instanceof TRPCClientError && error.data.code === "NOT_FOUND") {
        toasts.error(t("auth:forgot_password_user_does_not_exist"))
        return
      }

      captureException(error)
      toasts.error(t("common:generic_error"))
    }
  }

  return (
    <AuthLayout>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <SForm onSubmit={form.handleSubmit(onSubmit)}>
        <Flex justify="center">
          <SLogo />
        </Flex>

        <Spacer size={theme.space.s14} />

        <Flex justify="center">
          <Spacer size={theme.space.s6} />

          <div>
            <Text type="headerH1">{t("auth:forgot_password_title")}</Text>

            <Spacer size={theme.space.s6} />

            <Text type="textsSmall">{t("auth:forgot_password_subtitle")}</Text>
          </div>
        </Flex>

        <Spacer size={theme.space.s10} />

        <FormControl label={t("common:email")} error={form.formState.errors.email?.message}>
          <input
            placeholder={t("auth:forgot_password_email_placeholder")}
            autoCapitalize="none"
            {...form.register("email")}
          />
        </FormControl>

        <Spacer size={theme.space.s12} />

        <Button variant="primary" type="submit" isFullwidth disabled={form.formState.isSubmitting}>
          {t("auth:forgot_password_submit_button")}
        </Button>

        <Spacer size={theme.space.s8} />

        <Link to={LINKS.authLogin}>
          <Flex align="center" css={{ color: theme.colors.primary }}>
            <SChevronLeft />

            <Spacer size={theme.space.s2} />

            <Text type="headerH4">{t("auth:forgot_password_login_link")}</Text>
          </Flex>
        </Link>
      </SForm>
    </AuthLayout>
  )
}
