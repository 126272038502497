import SubSection from "../../../../components/subSection/SubSection"
import Text from "../../../../components/Text"
import Document from "./Document"
import { ComponentProps } from "react"
import { useTranslation } from "react-i18next"
import { SDocumentList, SShowMoreButton, SShowMoreWrapper } from "./Documents.styled"
import Modal from "../../../../components/modal/Modal"
import { DocumentsModalContent } from "./DocumentsModalContent"
import { Spacer } from "../../../../components/spacer/Spacer"
import { theme } from "../../../../styles/stitches.config"
import { MissingDocuments } from "./MissingDocuments"
import { SubSectionTitle } from "../../../../components/subSection/SubSection.styled"

type DocumentsDataParams = {
  data: ComponentProps<typeof Document>[]
}
const useDocumentsData = ({ data }: DocumentsDataParams) => {
  const { t } = useTranslation("delivery_point")
  const displayableDocuments = data.slice(0, 3)

  return {
    header: t("documents_header"),
    showAllButtonText: t("documents_show_more"),
    renderShowAllButton: true,
    displayableDocuments,
    allDocuments: data,
  }
}

type DocumentsProps = DocumentsDataParams

export default function Documents(props: DocumentsProps) {
  const data = useDocumentsData(props)
  const { t } = useTranslation(["delivery_point"])

  function renderContent() {
    if (data.allDocuments.length === 0) {
      return <MissingDocuments />
    }

    return (
      <>
        <SDocumentList>
          {data.displayableDocuments.map((document) => (
            <Document key={document.id} {...document} />
          ))}
        </SDocumentList>
        {data.renderShowAllButton && (
          <>
            <Spacer size={theme.space.s3} />
            <SShowMoreWrapper>
              <Modal
                trigger={
                  <SShowMoreButton>
                    <Text type="textsLarge" css={{ textDecoration: "underline" }}>
                      {t("delivery_point:documents_show_more")}
                    </Text>
                  </SShowMoreButton>
                }
              >
                <DocumentsModalContent data={data.allDocuments} />
              </Modal>
            </SShowMoreWrapper>
          </>
        )}
      </>
    )
  }

  return (
    <SubSection title={<SubSectionTitle>{data.header}</SubSectionTitle>} width="stretch" content={renderContent()} />
  )
}
