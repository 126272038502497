import { SMenuItemContainer, SMenuItem, iconStyles } from "./MenuItem.styled"
import Text from "../../../Text"
import { IconType, fontVariants, getStyledIcon } from "../../../../styles/stitches.config"
import { ReactNode } from "react"

interface Props {
  title: string
  active: boolean
  icon: IconType
  right?: ReactNode
}

function MenuItem({ title, active, icon, right }: Props) {
  const textType = active ? "menuItemActive" : "menuItem"
  const Icon = icon ? getStyledIcon(icon, { color: fontVariants[textType].color, ...iconStyles }) : null

  return (
    <SMenuItemContainer active={active}>
      <SMenuItem>
        {Icon && <Icon />}
        <Text type={textType}>{title}</Text>
      </SMenuItem>
      {right}
    </SMenuItemContainer>
  )
}

export default MenuItem
