import { ComponentProps, ReactNode } from "react"
import { styled, theme } from "../../styles/stitches.config"
import { Lightbulb } from "../../images/xd/icons"

const SWrapper = styled("div", {
  width: "100%",

  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  display: "flex",
  flexDirection: "row",

  variants: {
    size: {
      small: {
        minHeight: theme.sizes.s14,
      },
      medium: {
        minHeight: theme.sizes.s18,
      },
    },
  },

  defaultVariants: {
    size: "small",
  },
})

const SInfoIconWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: theme.colors.primary,

  flexShrink: 0,

  variants: {
    size: {
      small: {
        width: theme.sizes.s14,
      },
      medium: {
        width: theme.sizes.s24,
      },
    },
    type: {
      info: {
        backgroundColor: theme.colors.primary,
      },
      warning: {
        backgroundColor: theme.colors.warning,
      },
    },
  },

  defaultVariants: {
    size: "small",
    type: "info",
  },
})

type StripeSize = ComponentProps<typeof SWrapper>["size"]
type NotificationType = ComponentProps<typeof SInfoIconWrapper>["type"]

function stripeSizeToIconSize(size: StripeSize) {
  switch (size) {
    case "small":
    default:
      return theme.sizes.s7.value
    // case "medium":
    // return theme.sizes.s9.value
  }
}

type Props = ComponentProps<typeof SWrapper> & {
  size?: StripeSize
  content: ReactNode
  type?: NotificationType
}

export function StripeNotification(props: Props) {
  const { size, content, type, ...wrapperProps } = props
  const iconSize = stripeSizeToIconSize(size)
  return (
    <SWrapper size={size} {...wrapperProps}>
      <SInfoIconWrapper size={size} type={type}>
        <Lightbulb color={theme.colors.white.value} width={iconSize} height={iconSize} />
      </SInfoIconWrapper>
      {content}
    </SWrapper>
  )
}
