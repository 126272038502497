import * as Dropdown from "@radix-ui/react-dropdown-menu"
import { theme } from "../../../../../styles/stitches.config"
import { Flex } from "../../../../flex/Flex"
import { ArrowDown } from "../../../../../images/xd/icons"
import DeliveryPointDetails from "../DeliveryPointDetails"
import { SContent, SScrollableContent, STrigger } from "./DeliveryPointSelect.styled"
import Separator from "../../../../Separator"
import UserMenuItem from "../userMenu/UserMenuItem"
import { useTranslation } from "react-i18next"
import { ComponentProps, useState } from "react"
import { Link } from "react-router-dom"
import { LINKS } from "../../../../../constants/links"
import SelectItemList from "./SelectItemList"
import SelectItem from "./SelectItem"
import { defaultAnimationDuration } from "../../../../../styles/utils"
import AlternateRotatingIcon from "../../../../rotatingIcon/AlternateRotatingIcon"
import { DeliveryPointType } from "@nano-portal/shared"

type ItemProps = ComponentProps<typeof DeliveryPointDetails> & { deliveryPointId: number; type: DeliveryPointType[] }

interface Props {
  selected?: ItemProps
  available?: ItemProps[]
}

export default function DeliveryPointSelect({ selected, available }: Props) {
  const [toggled, setToggled] = useState(false)
  const { t } = useTranslation("delivery_point")

  if (!available || !selected) {
    return null
  }

  return (
    <Dropdown.Root open={toggled} onOpenChange={(open) => setToggled(open)}>
      <STrigger>
        <Flex direction={"column"} css={{ gap: theme.space.s1 }}>
          <Flex direction={"row"}>
            {selected && <SelectItem {...selected} isSelected isDeliveryPointActive={selected.isActive} />}
            <AlternateRotatingIcon
              direction="right"
              duration={defaultAnimationDuration}
              toggled={toggled}
              icon={ArrowDown}
            />
          </Flex>
          <Separator orientation="horizontal" decorative />
        </Flex>
      </STrigger>

      <SContent sideOffset={12} align="end">
        <SScrollableContent>
          <SelectItemList items={available} selectedEan={selected.ean} />
        </SScrollableContent>
        <Separator orientation="horizontal" decorative />

        <Link to={LINKS.home}>
          <Flex align="center" css={{ padding: theme.space.s6, gap: theme.space.s4 }}>
            <UserMenuItem text={t("supply_points_select_overview")} textType="menuItem" icon="Dashboard" />
          </Flex>
        </Link>
      </SContent>
    </Dropdown.Root>
  )
}
