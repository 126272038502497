import * as React from "react"

function SvgTable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 52399" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 52400"
        d="M12 9h4a1 1 0 000-2h-4a1 1 0 000 2zm0 4h4a1 1 0 000-2h-4a1 1 0 000 2zm0 4h4a1 1 0 000-2h-4a1 1 0 000 2zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20 3H4a1 1 0 00-1 1v16a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1zm-1 16H5V5h14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTable
