import { Trans, useTranslation } from "react-i18next"
import { theme } from "../../styles/stitches.config"
import Text from "../Text"
import { Flex } from "../flex/Flex"
import { StripeNotification } from "./StripeNotification"
import { Span } from "../span/Span"
import { useIsMobileVersion } from "../../hooks/mediaHooks"

export function ProductionDataTariffNotification() {
  const { t } = useTranslation(["notifications"])

  const isMobile = useIsMobileVersion()

  return (
    <StripeNotification
      size={isMobile ? "medium" : "small"}
      content={
        <Flex align="center" justify="center" css={{ paddingLeft: theme.space.s8 }}>
          <Text type="headerH3" css={{ fontWeight: theme.fontWeights.regular }}>
            <Trans t={t} i18nKey="notifications:production_data_tariff_notification">
              <Span css={{ color: theme.colors.secondary }} />
            </Trans>
          </Text>
        </Flex>
      }
    />
  )
}
