import AppContainer from "../../components/layouts/common/AppContainer"
import { useNavigate, useParams } from "react-router"
import { LINKS } from "../../constants/links"
import Text from "../../components/Text"
import { Trans, useTranslation } from "react-i18next"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { NanoEnergiesLogo, NewReading } from "../../images/xd/icons"
import { Flex } from "../../components/flex/Flex"
import { Button } from "../../components/button/Button"
import { useIsMinSm } from "../../hooks/mediaHooks"

export const MeterReading = () => {
  const { ean, signature } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(["delivery_point"])
  const isDesktop = useIsMinSm()

  if (!ean || !signature) {
    navigate(LINKS.authLogin)
    return null
  }

  return (
    <AppContainer>
      <Flex direction="column" align="center" css={{ padding: theme.space.s6 }}>
        {isDesktop && <Spacer size={theme.space.s24} />}

        <NanoEnergiesLogo width={200} />

        <Spacer size={theme.space.s6} />

        <NewReading width={200} height={200} />
        <Spacer size={theme.space.s6} />
        <Text type="headerH2">{t("delivery_point:external_meter_readings_not_supported_header")}</Text>
        <Spacer size={theme.space.s6} />

        <Text type="textsLarge">
          <Trans t={t} i18nKey="delivery_point:external_meter_readings_not_supported_description">
            <Text type="headerH3" />
          </Trans>
        </Text>

        <Spacer size={theme.space.s6} />

        <Button type="button" onClick={() => navigate(LINKS.authLogin)}>
          {t("delivery_point:external_meter_readings_go_to_login")}
        </Button>
      </Flex>
    </AppContainer>
  )
}
