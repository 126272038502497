import { useState } from "react"
import { range } from "lodash"
import { SelectContainer } from "../DatePicker.styled"

interface Props {
  minYear: number
  maxYear: number
  value?: number
  onSelect?: (value: number) => void
}

export default function YearSelect({ minYear, maxYear, value = 0, onSelect }: Props) {
  const [selected, setSelected] = useState(value < minYear ? minYear : value)

  const handleSelect = (value: number) => {
    setSelected(value)
    onSelect?.(value)
  }

  return (
    <SelectContainer value={selected} onChange={(e) => handleSelect(Number(e.currentTarget.value))} color="primary">
      {range(minYear, maxYear + 1).map((year) => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        )
      })}
    </SelectContainer>
  )
}
