import { styled, theme } from "../../../../styles/stitches.config"

export const InfoSectionBaseContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: theme.space.s4,
  maxWidth: theme.space.s72,
  textAlign: "end",
})

export const InfoSectionContainer = styled(InfoSectionBaseContainer, {
  gap: theme.space.s4,
})
