import { ComponentProps, ReactNode } from "react"
import SideMenu from "./sideMenu/SideMenu"
import TopMenu from "./topMenu/TopMenu"
import { SBodyContainer, SSideMenuContainer } from "./AppLayout.styled"
import AppContainer from "../common/AppContainer"
import { TopMenuContainer } from "./topMenu/TopMenuContainer"
import MaxWidthContainer from "../common/MaxWidthContainer"
import InfoBar from "./informationBar/InfoBar"
import { trpc } from "../../../api/trpc/trpc"
import { useTranslation } from "react-i18next"
import { theme } from "../../../styles/stitches.config"
import { Spacer } from "../../spacer/Spacer"

interface Props extends ComponentProps<typeof TopMenu> {
  hideSideMenu?: boolean
  hideLeaves?: boolean
  children?: ReactNode
}

const responsiveVariant: ComponentProps<typeof SBodyContainer>["variant"] = { "@initial": "mobile", "@lg": "desktop" }

export function AppLayout({ children, hideSideMenu, hideSupplyPointSelect }: Props) {
  const layoutVariant = !hideSideMenu ? responsiveVariant : "mobile"

  const { t } = useTranslation("auth")

  const { data } = trpc.useQuery(["users.myself"])
  const showInfoBar = !!(data && data.externalUserId)

  return (
    <AppContainer>
      {showInfoBar && (
        <InfoBar text={t("login_as_user_warning", { user: `${data.email} (${data.customerIds?.join(", ")})` })} />
      )}
      <TopMenuContainer>
        <TopMenu hideSupplyPointSelect={hideSupplyPointSelect} />
        {!hideSideMenu && (
          <>
            <Spacer size={theme.space.s7} />
            <SSideMenuContainer>
              <SideMenu />
            </SSideMenuContainer>
          </>
        )}
      </TopMenuContainer>

      <MaxWidthContainer variant={{ "@initial": "mobile", "@lg": "bottom" }}>
        <SBodyContainer variant={layoutVariant}>{children}</SBodyContainer>
      </MaxWidthContainer>
    </AppContainer>
  )
}
