import { OverviewChartType, OverviewGroup } from "@nano-portal/shared"
import { BarSize } from "../../components/chart/components/Bar"
import { formatDate, formatDay, formatHour, formatIsoDateTime, formatYear, getLocalizedMonth } from "../../utils/date"
import { formatCurrency, formatCurrencyPerEnergy, formatEnergy } from "../../utils/format"
import { assertUnreachable } from "../../utils/types"
import { addMinutes } from "date-fns"

export const getValueFormatter = (chartType: OverviewChartType) => {
  switch (chartType) {
    case OverviewChartType.ConsumptionChart:
    case OverviewChartType.ProductionChart:
      return formatEnergy
    case OverviewChartType.PricePerUnitChart:
      return formatCurrencyPerEnergy
    case OverviewChartType.TotalPaymentChart:
      return formatCurrency
    default:
      assertUnreachable(chartType)
  }
}

export const getBarSize = (group: OverviewGroup): BarSize => {
  return group === OverviewGroup.Hours ? "normal" : "large"
}

export const formatTooltipDate = (group: OverviewGroup, from: Date, to: Date) => {
  switch (group) {
    case OverviewGroup.Hours:
      return `${formatHour(from)} - ${formatHour(addMinutes(to, 1))}`
    case OverviewGroup.Days:
      return `${formatDay(from)}. ${getLocalizedMonth(from, "long", false)} ${formatYear(from, "yyyy")}`
    case OverviewGroup.Months:
      return `${getLocalizedMonth(from)} ${formatYear(from, "yyyy")}`
    case OverviewGroup.Years:
      return formatYear(from, "yyyy")
    default:
      return assertUnreachable(group)
  }
}

export const getDateFormatter = (group: OverviewGroup) => {
  switch (group) {
    case OverviewGroup.Hours:
      return formatHour
    case OverviewGroup.Days:
      return formatDay
    case OverviewGroup.Months:
      return getLocalizedMonth
    case OverviewGroup.Years:
      return (date: Date) => formatYear(date, "yyyy")
    default:
      return assertUnreachable(group)
  }
}

export function getCsvExportPeriodFormatter(group: OverviewGroup) {
  switch (group) {
    case OverviewGroup.Hours:
    case OverviewGroup.Days:
      return formatIsoDateTime
    case OverviewGroup.Months:
    case OverviewGroup.Years:
      return formatDate
    default:
      return assertUnreachable(group)
  }
}

export type OverviewType = "consumption" | "production"
