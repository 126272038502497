import Text from "../../components/Text"
import { Flex } from "../../components/flex/Flex"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { SSectionPayment, SSectionPaymentDetail } from "./InvoiceDetailContent.styled"
import { useTranslation } from "react-i18next"
import { QRCodeSVG } from "qrcode.react"
import { Div } from "../../components/div/Div"

interface Props {
  bankAccount?: string | null
  variableCode?: string | null
  paymentQrCodeString?: string | null
}

export function InvoiceDetailPaymentSection({ bankAccount, variableCode, paymentQrCodeString }: Props) {
  const { t } = useTranslation("payments")
  return (
    <Div>
      <Text type="headerH3">{t("invoice_detail_direct_payment_details")}</Text>
      <Spacer size={theme.space.s8} />
      <SSectionPaymentDetail>
        <Flex direction={"column"} justify={"between"}>
          <Flex direction={"column"}>
            <Text type={"headerH5"}>{t("invoice_detail_account_number")}</Text>
            <Spacer size={theme.space.s2} />
            <Text type={"textsLarge"}>{bankAccount}</Text>
          </Flex>

          <Flex direction={"column"}>
            <Text type={"headerH5"}>{t("invoice_detail_variable_symbol")}</Text>
            <Spacer size={theme.space.s2} />
            <Text type={"textsLarge"}>{variableCode}</Text>
          </Flex>
        </Flex>
        <Spacer size={theme.space.s4} />

        {paymentQrCodeString && <QRCodeSVG value={paymentQrCodeString} />}
      </SSectionPaymentDetail>
    </Div>
  )
}

export function InvoiceDetailPaymentSectionCard(props: Props) {
  return (
    <SSectionPayment>
      <InvoiceDetailPaymentSection {...props} />
    </SSectionPayment>
  )
}
