import { Flex } from "../../../flex/Flex"
import Separator from "../../../Separator"
import UserDetails from "../topMenu/userMenu/UserDetails"
import { ComponentProps, useCallback, useRef, useState } from "react"
import DeliveryPointDetails from "../topMenu/DeliveryPointDetails"
import MenuStateSwitch from "./MenuStateSwitch"
import { SSwitchContainer, SUserDetailsContainer } from "./SideMenuStateContent.styled"
import MenuContent from "./MenuContent"
import useDeliveryPointMenuData from "../topMenu/supplyPointSelect/hooks/useDeliveryPointMenuData"
import { trpc } from "../../../../api/trpc/trpc"

export default function UserSideMenuContent() {
  const [state, setState] = useState<ComponentProps<typeof MenuContent>["state"]>("menu")
  const [selected] = useDeliveryPointMenuData()
  const { data } = trpc.useQuery(["users.myself"])
  const detailsRef = useRef<HTMLDivElement>(null)

  const handleStateSwitch = useCallback((toggled: boolean) => {
    toggled ? setState("points") : setState("menu")
  }, [])

  return (
    <>
      <Flex ref={detailsRef} direction="column" css={{ width: "stretch" }}>
        <SUserDetailsContainer>
          <UserDetails email={data?.email || ""} />
        </SUserDetailsContainer>

        {selected && (
          <SSwitchContainer>
            <Separator orientation="horizontal" decorative />
            <MenuStateSwitch
              supplyPointDetails={selected && <DeliveryPointDetails {...selected} isSelected />}
              onSwitch={handleStateSwitch}
            />
            <Separator orientation="horizontal" decorative />
          </SSwitchContainer>
        )}
      </Flex>
      <MenuContent state={state} topDetailsRef={detailsRef} selectedEan={selected?.ean} />
    </>
  )
}
