import * as React from "react"

function SvgFaceWorst(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_FaceWorst_svg__a">
          <path
            data-name="Rectangle 17075"
            transform="translate(-2907 1110)"
            fill="#ec5d5c"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 24" transform="translate(2907 -1110)" clipPath="url(#_FaceWorst_svg__a)">
        <path
          data-name="Union 32"
          d="M-2906 1134a23 23 0 1123 23 23.026 23.026 0 01-23-23zm2.176 0a20.824 20.824 0 1020.824-20.824 20.847 20.847 0 00-20.824 20.824zm22.794 10.13a1.97 1.97 0 10-3.941 0 1.088 1.088 0 01-2.176 0 4.147 4.147 0 118.294 0 1.088 1.088 0 01-2.176 0zm10.33-4.136a1.088 1.088 0 111.539-1.539 1.672 1.672 0 002.362 0 1.088 1.088 0 111.539 1.539 3.846 3.846 0 01-5.439 0zm-30.037 0a1.088 1.088 0 011.537-1.539 1.67 1.67 0 002.362 0 1.088 1.088 0 011.538 1.539 3.846 3.846 0 01-5.439 0z"
          fill="#ec5d5c"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgFaceWorst
