import React from "react"
import { Flex } from "../components/flex/Flex"
import { Spacer } from "../components/spacer/Spacer"
import Text from "../components/Text"
import { Settings } from "../images/xd/icons"
import { theme } from "../styles/stitches.config"

interface Props {
  screenType: "MAINTENANCE" | "MIGRATION"
}

const Maintenance = ({ screenType }: Props) => {
  return (
    <Flex justify="center" align="center" direction="column" css={{ minHeight: "100vh", width: "100%" }}>
      <div style={{ width: theme.space.s48.value, height: theme.space.s48.value }}>
        <Settings color={theme.colors.secondary.value} />
      </div>
      <Spacer size={theme.space.s12} />
      <Text type="headerH1">Probíhá údržba</Text>
      <Spacer size={theme.space.s3} />
      <div style={{ margin: theme.space.s3.value, textAlign: "center" }}>
        {screenType == "MAINTENANCE" ? (
          <Text type="textsLarge">Na portálu probíhá údržba. Prosíme, zkuste se přihlásit později.</Text>
        ) : (
          <>
            <Text type="textsLarge">
              Omlouváme se, z důvodu přechodu na nový systém je portál momentálně nedostupný.
            </Text>
            <Spacer size={theme.space.s3} />
            <Text type="textsLarge">
              Jsme vám k dispozici na telefonním čísle{" "}
              {/* je to napraseny protoze zadani na to den pripravovali tri lidi a bude se to predelavat */}
              <a href="tel:+420226257257" style={{ whiteSpace: "nowrap", color: "#00515f" }}>
                +420 226 257 257
              </a>{" "}
              nebo e-mailu{" "}
              <a href="mailto:info@deltagreen.cz" style={{ color: "#00515f" }}>
                info@deltagreen.cz
              </a>
              , kam také můžete zasílat své samoodečty.
            </Text>
            <Spacer size={theme.space.s3} />
            <Text type="textsLarge">Děkujeme za trpělivost a pochopení.</Text>
          </>
        )}
      </div>
    </Flex>
  )
}

export default Maintenance
