import React from "react"

function SvgInfigy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 29" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#071326"
          d="M55.104 6.132v15.286h-3.107V6.132h3.107zm0-5.141v3.16h-3.107V.99h3.107zm-31.14 5.141v15.286h-3.107V6.132h3.107zm0-5.141v3.16h-3.107V.99h3.107zm13.414 5.637c.935.495 1.666 1.238 2.191 2.219.526.98.79 2.16.79 3.546v9.015H37.28v-8.54c0-1.367-.34-2.417-1.013-3.15-.672-.733-1.587-1.1-2.756-1.1-1.17 0-2.085.367-2.767 1.1-.681.733-1.022 1.784-1.022 3.15v8.54h-3.108V6.132h3.108v1.744A5.133 5.133 0 0131.67 6.41a6.227 6.227 0 012.523-.525c1.178 0 2.24.247 3.175.752l.01-.01zm12.108 2.08H46.7v12.7h-3.137v-12.7H41.79V6.132h1.773v-1.08c0-1.753.458-3.041 1.374-3.843C45.853.406 47.294 0 49.262 0v2.635c-.945 0-1.608.178-1.987.545-.38.357-.575.98-.575 1.872v1.08h2.786v2.576zm17.787-2.12c.896.475 1.607 1.06 2.114 1.763V6.132h3.136v15.534c0 1.406-.292 2.654-.877 3.754a6.417 6.417 0 01-2.522 2.596c-1.101.634-2.416.94-3.945.94-2.036 0-3.731-.485-5.076-1.455-1.344-.971-2.113-2.289-2.288-3.953h3.078c.233.792.74 1.436 1.51 1.932.769.495 1.694.733 2.766.733 1.256 0 2.27-.387 3.039-1.17.77-.772 1.16-1.901 1.16-3.387V19.1c-.527.723-1.238 1.327-2.144 1.813-.896.485-1.919.733-3.068.733a6.652 6.652 0 01-3.585-1.01c-1.081-.674-1.938-1.616-2.562-2.834-.623-1.218-.944-2.586-.944-4.121 0-1.536.311-2.893.944-4.082.624-1.178 1.48-2.1 2.562-2.764 1.081-.653 2.28-.98 3.585-.98 1.159 0 2.191.237 3.097.703l.02.03zm1.47 4.428c-.428-.773-.983-1.367-1.675-1.773a4.362 4.362 0 00-2.24-.614 4.44 4.44 0 00-2.24.594c-.692.396-1.247.98-1.676 1.743-.428.763-.643 1.675-.643 2.735 0 1.06.215 1.981.643 2.774.429.792.994 1.396 1.695 1.812a4.286 4.286 0 002.22.625c.78 0 1.55-.209 2.241-.615.692-.406 1.247-1 1.676-1.793.428-.782.642-1.704.642-2.764 0-1.06-.214-1.971-.642-2.744v.02zm20.493-4.884L80.021 28.6h-3.214l3.058-7.43-5.922-15.038h3.468l4.227 11.65 4.393-11.65h3.215-.01z"
        />
        <path fill="#FF9B3D" d="M7.666 1.082h-3.83v3.896h3.83V1.082z" />
        <path
          fill="#071326"
          fillRule="evenodd"
          d="M.007 4.978h3.83V17.53h3.831V4.978h3.83v16.447H.008V4.978z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h90v29H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgInfigy
