import { AppLayout } from "../../components/layouts/appLayout/AppLayout"
import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { Navigate } from "react-router"
import { LINKS } from "../../constants/links"
import OverviewPlaceholder from "../../components/placeholder/OverviewPlaceholder"
import { useDeliveryPointDetail } from "../../hooks/query/useDeliveryPointDetail"
import { DeliveryPointType, VoltageType } from "@nano-portal/shared"

const OverviewGeneralRoute = () => {
  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const deliveryPointsQuery = useDeliveryPointDetail(deliveryPointId)

  const isLoading = deliveryPointsQuery.isLoading

  if (isLoading || !deliveryPointsQuery.data) {
    return <AppLayout hideLeaves>{isLoading && <OverviewPlaceholder />}</AppLayout>
  }

  const isProduction = deliveryPointsQuery.data.type.includes(DeliveryPointType.Production)
  const isConsumption = deliveryPointsQuery.data.type.includes(DeliveryPointType.Consumption)
  const isHighVoltage = deliveryPointsQuery.data.voltageType === VoltageType.High

  const redirectLink =
    isConsumption && !isHighVoltage
      ? LINKS.pdtConsumption(deliveryPointId)
      : isProduction
      ? LINKS.pdtProduction(deliveryPointId)
      : LINKS.pdtPayments(deliveryPointId)

  return <Navigate to={redirectLink} />
}

export default OverviewGeneralRoute
