import * as d3 from "d3"
import { PropsWithChildren, useCallback, useState } from "react"
import { assertUnreachable } from "../../utils/types"
import { BarSize } from "./components/Bar"
import { useIsMaxMd, useIsMaxSm } from "../../hooks/mediaHooks"

export const TRANSPARENT_OPACITY = 0.25

function getBarSizeWidthMultiplier(size: BarSize) {
  switch (size) {
    case "large":
      return 1
    case "normal":
      return 0.5
    case "small":
      return 0.34
    case "tiny":
      return 0.18
    default:
      return assertUnreachable(size)
  }
}

function getCenteredBarOffsetMultiplier(barSize: BarSize) {
  switch (barSize) {
    case "large":
      return 0
    case "normal":
      return 0.25
    case "small":
      return 0.33
    case "tiny":
      return 0.41
    default:
      return assertUnreachable(barSize)
  }
}

export function getBarWidth(bandwidth: number, barSize: BarSize) {
  return bandwidth * getBarSizeWidthMultiplier(barSize)
}

export function getBarOffset(bandwidth: number, barSize: BarSize) {
  return bandwidth * getCenteredBarOffsetMultiplier(barSize)
}

export function getOuterPadding<TDomain extends { toString: () => string }>(scale: d3.ScaleBand<TDomain>) {
  const domain = scale.domain()

  if (!domain[0]) {
    return 0
  }

  return scale(domain[0]) ?? 0
}

export function useBarRadius() {
  const isSmall = useIsMaxSm()
  const isMedium = useIsMaxMd()

  return useCallback(
    (size: BarSize): BarSize => {
      switch (size) {
        case "large":
          return "large"
        case "normal":
          return isSmall ? "small" : isMedium ? "normal" : "large"
        case "small":
          return isSmall ? "small" : "normal"
        case "tiny":
          return isSmall ? "tiny" : "small"
        default:
          return assertUnreachable(size)
      }
    },
    [isSmall, isMedium]
  )
}

export function useBandAxisMouseMovedHandler<TGroup, TDomain extends { toString: () => string }>(
  scale: d3.ScaleBand<TDomain>,
  data: TGroup[],
  bandwidth: number,
  computeYPosition: (value: TGroup) => number,
  tooltipMode: "continuous" | "discrete" = "discrete"
) {
  const [tooltipGroup, setTooltipGroup] = useState<TGroup | null>(null)
  const [tooltipBaseCoords, setTooltipBaseCoords] = useState<[number, number] | null>(null)
  const onMove = useCallback(
    (localX: number | null, absoluteX = 0) => {
      if (localX == null) {
        setTooltipBaseCoords(null)
        return
      }

      const paddingOuter = getOuterPadding(scale)
      const eachBand = scale.step()

      const isInBar = (localX - paddingOuter) % eachBand <= bandwidth
      if (!isInBar && tooltipMode === "discrete") {
        setTooltipBaseCoords(null)
        return
      }

      const index = Math.floor((localX - paddingOuter) / eachBand)
      const value = data[index]

      if (!value) {
        setTooltipBaseCoords(null)
        return
      }

      const yPosition = computeYPosition(value)
      setTooltipBaseCoords([paddingOuter + bandwidth / 2 + index * eachBand + absoluteX, yPosition])
      setTooltipGroup(value)
    },
    [scale, bandwidth, tooltipMode, data, computeYPosition]
  )

  return [onMove, tooltipGroup, tooltipBaseCoords] as const
}

type ChartProps = {
  id: string
  width: number
  height: number
}

export function Chart(props: PropsWithChildren<ChartProps>) {
  const { height, width, id, children } = props
  return (
    <svg id={id} width={width} height={height} style={{ touchAction: "pan-y" }}>
      {children}
    </svg>
  )
}
