import React from "react"
import { Flex } from "../../components/flex/Flex"
import { styled, theme } from "../../styles/stitches.config"

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",

  alignItems: "center",

  "@lg": {
    alignItems: "flex-start",
  },
})

export const HeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: theme.space.s6,
  "@lg": {
    flexDirection: "row",
  },
})

export const PaymentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.space.s4,
  marginBottom: theme.space.s4,
  "@lg": {
    alignItems: "end",
    marginRight: theme.space.s6,
    marginTop: 0,
    marginBottom: 0,
  },
})

export const PaymentButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  "@lg": {
    flexDirection: "row",
  },
})

export const FilterContainer = styled("div", {
  marginLeft: theme.space.s2,
  alignSelf: "center",
})

export const SeparatorContainer = styled(Flex, {
  height: theme.space.s14,
  marginLeft: theme.space.s8,
  marginRight: theme.space.s8,
})

export const StickyFilterContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  width: "100vw",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
})

export const anchorContainerStyle: React.CSSProperties = {
  position: "absolute",
  bottom: theme.space.s14.value,
  left: 0,
  right: 0,
}
