import { ComponentProps } from "react"
import { useIsMaxSm, useIsMaxXl, useIsMobileVersion } from "../../hooks/mediaHooks"
import { theme } from "../../styles/stitches.config"
import { Spacer } from "../spacer/Spacer"
import {
  FilterDownloadContainer,
  ToggleContainer,
  TopContainer,
  anchorContainerStyle,
  DatePickerMobileContainer,
} from "../../sections/overview/OverviewChart.styled"
import { OverviewChartItem, OverviewChartPlainResponsive } from "./OverviewChartPlain"
import { ChartPlaceholder } from "../placeholder/Placeholder.stories"
import { Export } from "./components/Export"
import { DateRange } from "../../utils/types"
import DatePicker from "../datepicker/DatePicker"
import { Select, SelectItem } from "../select/Select"
import { useOverviewChartConfig } from "./useOverviewChartConfig"
import { DayPicker } from "../datepicker/DayPicker"
import { YearRangePicker } from "../datepicker/yearPicker/YearRangePicker"
import { getOverviewPlaceholderData } from "./getUnknownChartPlaceholder"
import { OverviewType } from "../../sections/overview/overview.utils"
import {
  OverviewChartType,
  OverviewGroup,
  killowattHourToMegawattHour,
  enumFromStringValueWithDefault,
  OverviewState,
} from "@nano-portal/shared"
import { minMaxAvg } from "../../utils/array"
import { addDays, addMonths, differenceInDays, differenceInMonths, endOfDay, endOfMonth } from "date-fns"
import { MonthYearPicker } from "../datepicker/monthYearPicker/MonthYearPicker"

type Props = Omit<ComponentProps<typeof OverviewChartPlainResponsive>, "id" | "valueLabel" | "unitLabel"> & {
  chartType: OverviewChartType
  setChartType: (chartType: OverviewChartType) => void
  setGroup: (group: OverviewGroup) => void
  loading?: boolean
  timeRange: DateRange
  setTimeRange: (selected: DateRange) => void
  minDate?: Date
  maxDate?: Date
  errorState?: boolean
  type: OverviewType
  csvDataSupplier: ComponentProps<typeof Export>["csvDataSupplier"]
}

function fillMissingDays(incompleteData: OverviewChartItem[]): OverviewChartItem[] {
  return fillMissingData(incompleteData, {
    getPeriodDifference: differenceInDays,
    addPeriodAmount: addDays,
    endOfPeriod: endOfDay,
  })
}

function fillMissingMonths(incompleteData: OverviewChartItem[]): OverviewChartItem[] {
  return fillMissingData(incompleteData, {
    getPeriodDifference: differenceInMonths,
    addPeriodAmount: addMonths,
    endOfPeriod: endOfMonth,
  })
}

function fillMissingData(
  incompleteData: OverviewChartItem[],
  config: {
    getPeriodDifference: (date: Date, date2: Date) => number
    addPeriodAmount: (date: Date, amount: number) => Date
    endOfPeriod: (date: Date) => Date
  }
): OverviewChartItem[] {
  const result: OverviewChartItem[] = []
  const avg = minMaxAvg(incompleteData.map((data) => data.value))

  let previousData = incompleteData[0]
  if (previousData === undefined) {
    return result
  }

  result.push(previousData)
  for (const data of incompleteData.slice(1)) {
    const difference = config.getPeriodDifference(data.from, previousData.from)

    if (difference > 1) {
      for (let j = 1; j < difference; j++) {
        const newDate = config.addPeriodAmount(previousData.from, j)
        result.push({
          value: avg,
          from: newDate,
          to: config.endOfPeriod(newDate),
          dataState: OverviewState.Unknown,
        })
      }
    }
    result.push(data)
    previousData = data
  }

  return result
}

function fillMissingGroupData(data: OverviewChartItem[], group: OverviewGroup): OverviewChartItem[] {
  if (group === OverviewGroup.Months) {
    return fillMissingMonths(data)
  }

  if (group === OverviewGroup.Days) {
    return fillMissingDays(data)
  }

  return data
}

const OVERVIEW_CHART_ID = "overview-chart-cons"

const OverviewChart = ({
  chartType,
  setChartType,
  group,
  setGroup,
  data,
  loading,
  timeRange,
  setTimeRange,
  maxDate,
  minDate,
  isUnknownState,
  isHiddenFixedPricelist,
  errorState,
  type,
  csvDataSupplier,
}: Props) => {
  const config = useOverviewChartConfig({ data, chartType, type })

  const isMaxSm = useIsMaxSm()
  const isMobile = useIsMobileVersion()
  const isSmallDesktop = useIsMaxXl()
  const renderDesktopVersion = !isMobile && !isSmallDesktop
  const renderMobileVersion = isMobile || isSmallDesktop

  const filledData = fillMissingGroupData(data, group)
  const convertedData = filledData.map((d) => ({
    ...d,
    value:
      chartType === OverviewChartType.ConsumptionChart && config.formatAsMwh
        ? killowattHourToMegawattHour(d.value)
        : d.value,
  }))

  const exportDisabled = loading || isUnknownState || errorState

  return (
    <>
      <TopContainer>
        <ToggleContainer>
          <Select
            defaultValue={OverviewChartType.TotalPaymentChart}
            position="popper"
            width={isMaxSm ? "max" : theme.sizes.s52}
            size="small"
            value={chartType}
            onValueChange={(value) =>
              setChartType(
                enumFromStringValueWithDefault(OverviewChartType, value, OverviewChartType.TotalPaymentChart)
              )
            }
            textColor="primary"
          >
            {config.chartTypes.map((conf) => (
              <SelectItem key={conf.value} text={conf.title} value={conf.value} size="small" color="primary" />
            ))}
          </Select>

          <Select
            defaultValue={OverviewGroup.Months}
            position="popper"
            width={isMaxSm ? "max" : theme.sizes.s36}
            size="small"
            value={group}
            onValueChange={(value) =>
              setGroup(enumFromStringValueWithDefault(OverviewGroup, value, OverviewGroup.Months))
            }
            textColor="primary"
          >
            {config.groups.map((conf) => (
              <SelectItem key={conf.value} text={conf.title} value={conf.value} size="small" color="primary" />
            ))}
          </Select>
        </ToggleContainer>

        {renderDesktopVersion && (
          <FilterDownloadContainer>
            <DatePicker selected={timeRange} setSelected={setTimeRange} minDate={minDate} maxDate={maxDate}>
              {group === OverviewGroup.Hours ? (
                <DayPicker
                  selected={timeRange}
                  setSelected={setTimeRange}
                  minDate={minDate}
                  maxDate={maxDate}
                  singleDay={group === OverviewGroup.Hours}
                />
              ) : group === OverviewGroup.Days ? (
                <MonthYearPicker minDate={minDate} maxDate={maxDate} selected={timeRange} onSelect={setTimeRange} />
              ) : (
                <YearRangePicker
                  singleYear={group === OverviewGroup.Months}
                  selected={timeRange}
                  onSelect={setTimeRange}
                  maxDate={maxDate}
                  minDate={minDate}
                />
              )}
            </DatePicker>
            <Export
              svgId={OVERVIEW_CHART_ID}
              timeRange={timeRange}
              csvDataSupplier={csvDataSupplier}
              disabled={exportDisabled}
            />
          </FilterDownloadContainer>
        )}
      </TopContainer>

      <Spacer size={theme.space.s6} />

      {loading ? (
        <ChartPlaceholder />
      ) : (
        <OverviewChartPlainResponsive
          id={OVERVIEW_CHART_ID}
          data={errorState || isUnknownState ? getOverviewPlaceholderData(group, timeRange) : convertedData}
          group={group}
          valueLabel={config.typeTitle}
          isUnknownState={isUnknownState}
          isHiddenFixedPricelist={isHiddenFixedPricelist}
          unitLabel={config.unitLabel}
          type={type}
          valueFormatter={config.valueFormatter}
          isErrorState={errorState}
        />
      )}

      {renderMobileVersion && (
        <DatePickerMobileContainer>
          <DatePicker
            anchorContainerStyle={anchorContainerStyle}
            selected={timeRange}
            setSelected={setTimeRange}
            minDate={minDate}
            maxDate={maxDate}
          >
            {group === OverviewGroup.Hours ? (
              <DayPicker
                selected={timeRange}
                setSelected={setTimeRange}
                minDate={minDate}
                maxDate={maxDate}
                singleDay={group === OverviewGroup.Hours}
              />
            ) : group === OverviewGroup.Days ? (
              <MonthYearPicker minDate={minDate} maxDate={maxDate} selected={timeRange} onSelect={setTimeRange} />
            ) : (
              <YearRangePicker
                singleYear={group === OverviewGroup.Months}
                selected={timeRange}
                onSelect={setTimeRange}
                maxDate={maxDate}
                minDate={minDate}
              />
            )}
          </DatePicker>
          <Export
            svgId={OVERVIEW_CHART_ID}
            timeRange={timeRange}
            csvDataSupplier={csvDataSupplier}
            disabled={exportDisabled}
          />
        </DatePickerMobileContainer>
      )}
    </>
  )
}

export default OverviewChart
