import { Fragment, useRef } from "react"
import ContentLoader from "react-content-loader"
import useMeasureDirty from "react-use/lib/useMeasureDirty"

const defaultHeight = 64
const defaultCols = 3

const HeaderPlaceholder = ({ cols: _cols, height: _height }: { cols?: number; height?: number }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useMeasureDirty(ref)

  const cols = _cols || defaultCols

  const height = _height || defaultHeight
  const size = width / (2 * cols - 1)

  return (
    <div ref={ref} style={{ width: "100%", height }}>
      <ContentLoader viewBox={`0 0 ${width} ${height}`}>
        {Array.from({ length: cols })
          .fill(" ")
          .map((_, index) => {
            return (
              <Fragment key={index}>
                <rect x={index * 2 * size} y="0" width={size} height={height} />
              </Fragment>
            )
          })}
      </ContentLoader>
    </div>
  )
}

export default HeaderPlaceholder
