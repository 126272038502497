import { styled, theme } from "../../../../../styles/stitches.config"

export const SReadingsCellContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  paddingTop: theme.space.s1,
  paddingBottom: theme.space.s1,
  gap: theme.space.s1,
  maxWidth: theme.sizes.s40,
})

export const SAttachmentsCellContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  hover: {
    cursor: "pointer",
  },
  gap: theme.space.s1,
})
