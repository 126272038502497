import * as React from "react"

function SvgNotifications(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 124" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 125"
        d="M12 22a2.006 2.006 0 002-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4a1.5 1.5 0 00-3 0v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29a1 1 0 00.7 1.71h13.17a1 1 0 00.71-1.71z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotifications
