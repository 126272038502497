import * as Popover from "@radix-ui/react-popover"
import { styled, theme } from "../../styles/stitches.config"

export const FilterContent = styled(Popover.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  paddingTop: theme.space.s6,
  paddingLeft: theme.space.s6,
  paddingRight: theme.space.s6,
  marginTop: theme.space.s2,
})

export const CheckboxContainer = styled("div", {
  marginLeft: theme.space.s4,
})
