import { styled, theme } from "../../../styles/stitches.config"

export const SWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.shadows.default,
  height: theme.space.s48,

  variants: {
    isActive: {
      true: {
        backgroundColor: theme.colors.white,
      },
      false: {
        backgroundColor: theme.colors.lines,
      },
    },
  },

  defaultVariants: {
    isActive: "true",
  },
})

export const SHeader = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  paddingTop: theme.space.s3,
  paddingRight: theme.space.s3,
  paddingLeft: theme.space.s6,
  paddingBottom: 0,
})

export const SNameAddressContainer = styled("div", {
  paddingRight: theme.space.s3,
  paddingLeft: theme.space.s6,
})

export const SContent = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  paddingLeft: theme.space.s6,
  paddingRight: theme.space.s6,
  paddingBottom: theme.space.s6,
  paddingTop: 0,
})
