import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import DatePicker from "../../components/datepicker/DatePicker"
import Filter, { FilterItem } from "../../components/filter/Filter"
import { Flex } from "../../components/flex/Flex"
import { AppLayout } from "../../components/layouts/appLayout/AppLayout"
import PaymentsPlaceholder from "../../components/placeholder/PaymentsPlaceholder"
import Text from "../../components/Text"
import { usePaymentsData } from "./hooks/usePaymentsData"
import {
  Container,
  HeaderContainer,
  FilterContainer,
  StickyFilterContainer,
  anchorContainerStyle,
} from "./Payments.styled"
import PaymentsTable from "./PaymentsTable"
import { useIsMaxXl } from "../../hooks/mediaHooks"
import { trpc } from "../../api/trpc/trpc"
import { TablePlaceholder } from "../../components/placeholder/Placeholder.stories"

import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { DateRange } from "../../utils/types"
import { DayPicker } from "../../components/datepicker/DayPicker"
import {
  DeliveryPointType,
  DueDateFilter,
  StateFilter,
  TypeFilter,
  defaultPaymentsFilter,
  enumFromStringValue,
  getDefaultFilterDateRange,
} from "@nano-portal/shared"
import { ProductionDataTariffNotification } from "../../components/notifications/ProductionDataTariffNotification"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { resolveQueriesState } from "../../utils/queries"
import { useNotifyAboutDataTariff } from "../../hooks/query/useNotifyAboutDataTariff"
import { useDeliveryPointDetail } from "../../hooks/query/useDeliveryPointDetail"
import { PaymentInfo } from "./PaymentInfo"

type PaymentsFilter = TypeFilter | StateFilter | DueDateFilter

const PaymentsContent = () => {
  const mobileVersion = useIsMaxXl()
  const { t } = useTranslation(["payments", "common"])

  const filterToName: Record<PaymentsFilter, string> = useMemo(
    () => ({
      [TypeFilter.Advances]: t("payments:advance"),
      [TypeFilter.Invoices]: t("payments:invoice"),
      [TypeFilter.Billing]: t("payments:billing"),
      [TypeFilter.CreditNote]: t("payments:credit_note"),
      [TypeFilter.DebitNote]: t("payments:debit_note"),
      [TypeFilter.Fine]: t("payments:fine"),
      [TypeFilter.Inclusion]: t("payments:inclusion"),
      [TypeFilter.Claim]: t("payments:claim"),
      [TypeFilter.SelfBillingInvoice]: t("payments:self_billing_invoice"),
      [TypeFilter.ProductionBilling]: t("payments:production_billing"),
      [TypeFilter.ProductionInvoiceBasis]: t("payments:production_invoice_basis"),
      [StateFilter.Paid]: t("payments:state_paid"),
      [StateFilter.Unpaid]: t("payments:state_unpaid"),
      [StateFilter.Cancelled]: t("payments:state_cancelled"),
      [StateFilter.Included]: t("payments:state_included"),
      [StateFilter.Invoiced]: t("payments:state_invoiced"),
      [StateFilter.Uninvoiced]: t("payments:state_uninvoiced"),
      [StateFilter.Unknown]: t("payments:state_unknown"),
      [DueDateFilter.AfterDueDate]: t("payments:after_due_date"),
      [DueDateFilter.ApproachingDueDate]: t("payments:approaching_due_date"),
      [DueDateFilter.FarFromDueDate]: t("payments:far_from_due_date"),
      [DueDateFilter.Unknown]: t("payments:due_date_unknown"),
    }),
    [t]
  )

  const filtersToFilterItems = useCallback(
    (filter: { type: TypeFilter[]; state: StateFilter[]; dueDate: DueDateFilter[] }) => {
      const typeItems: FilterItem<PaymentsFilter>[] = filter.type.map((type) => ({
        id: type,
        name: filterToName[type],
        group: t("common:type"),
        enabled: true,
      }))
      const stateItems: FilterItem<PaymentsFilter>[] = filter.state.map((state) => ({
        id: state,
        name: filterToName[state],
        group: t("common:state"),
        enabled: true,
      }))
      const dueDateItems: FilterItem<PaymentsFilter>[] = filter.dueDate.map((dueDate) => ({
        id: dueDate,
        name: filterToName[dueDate],
        group: t("common:state"),
        enabled: true,
      }))

      return [...typeItems, ...stateItems, ...dueDateItems]
    },
    [t, filterToName]
  )

  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const [selected, setSelected] = useState<DateRange>(getDefaultFilterDateRange())
  const [filterItems, setFilterItems] = useState<FilterItem<PaymentsFilter>[]>(
    filtersToFilterItems(defaultPaymentsFilter)
  )

  const filter = useMemo(() => {
    const filters = filterItems.filter((item) => item.enabled).map((item) => item.id)

    if (filters.length === 0) {
      return
    }

    const result: {
      type: TypeFilter[]
      state: StateFilter[]
      dueDate: DueDateFilter[]
    } = {
      type: [],
      state: [],
      dueDate: [],
    }

    for (const f of filters) {
      const type = enumFromStringValue(TypeFilter, f)
      if (type) {
        result.type.push(type)
        continue
      }

      const state = enumFromStringValue(StateFilter, f)
      if (state) {
        result.state.push(state)
        continue
      }

      const dueDate = enumFromStringValue(DueDateFilter, f)
      if (dueDate) {
        result.dueDate.push(dueDate)
      }
    }

    return result
  }, [filterItems])

  const payments = trpc.useQuery([
    "payments.list",
    { since: selected.from, until: selected.to, deliveryPointId, filter },
  ])

  const deliveryPointQuery = useDeliveryPointDetail(deliveryPointId)
  const notificationQuery = useNotifyAboutDataTariff(deliveryPointId)
  const { isLoading } = resolveQueriesState([deliveryPointQuery, notificationQuery])
  const paymentsTableData = usePaymentsData(deliveryPointId, deliveryPointQuery.data?.ean, payments.data)

  const paymentsLoading = payments.isLoading || !payments.data
  const deliveryPointLoading = deliveryPointQuery.isLoading || !deliveryPointQuery.data

  if (deliveryPointLoading || isLoading || !deliveryPointQuery.data) {
    return <PaymentsPlaceholder />
  }

  //TODO zde nějaký error screen, až bude návrh
  if (deliveryPointQuery.data === null) {
    return <PaymentsPlaceholder />
  }

  const { type } = deliveryPointQuery.data

  const displayProductionDataTariffNotification =
    type.includes(DeliveryPointType.Production) && notificationQuery.notifyAboutTariff

  return (
    <>
      <Spacer size={theme.space.s8} />

      {type.includes(DeliveryPointType.Consumption) && <PaymentInfo deliveryPointId={deliveryPointId} />}

      <Spacer size={theme.space.s8} />

      <HeaderContainer>
        <Text type="headerH1">{t("payments:your_payments")}</Text>
        <Flex direction="row" align="center">
          {!mobileVersion && (
            <>
              <DatePicker selected={selected} setSelected={setSelected}>
                <DayPicker selected={selected} setSelected={setSelected} />
              </DatePicker>

              <FilterContainer>
                <Filter items={filterItems} onChange={setFilterItems} />
              </FilterContainer>
            </>
          )}
        </Flex>
      </HeaderContainer>
      {paymentsLoading ? <TablePlaceholder /> : <PaymentsTable data={paymentsTableData} />}

      {displayProductionDataTariffNotification && (
        <>
          <Spacer size={theme.space.s7_5} />
          <ProductionDataTariffNotification />
          <Spacer size={theme.space.s7_5} />
        </>
      )}

      <Spacer size={theme.space.s12} />

      {mobileVersion && (
        <StickyFilterContainer>
          <DatePicker selected={selected} setSelected={setSelected} anchorContainerStyle={anchorContainerStyle}>
            <DayPicker selected={selected} setSelected={setSelected} />
          </DatePicker>
          <Filter items={filterItems} onChange={setFilterItems} />
        </StickyFilterContainer>
      )}
    </>
  )
}

const Payments = () => {
  return (
    <AppLayout>
      <Container>
        <PaymentsContent />
      </Container>
    </AppLayout>
  )
}

export default Payments
