import * as React from "react"

function SvgDirectDeposit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_DirectDeposit_svg__a">
          <path data-name="Rectangle 417" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 810" clipPath="url(#_DirectDeposit_svg__a)" fill="currentColor">
        <path data-name="Path 308" d="M4.084 10.308v3.526a1.322 1.322 0 002.644 0v-3.526a1.322 1.322 0 00-2.644 0" />
        <path data-name="Path 309" d="M9.373 10.308v3.526a1.322 1.322 0 102.644 0v-3.526a1.322 1.322 0 10-2.644 0" />
        <path
          data-name="Path 310"
          d="M3.423 7.225h14.552a1.1 1.1 0 00.5-2.071l-6.961-3.669a1.742 1.742 0 00-1.639 0L2.912 5.152a1.1 1.1 0 00.511 2.073"
        />
        <path data-name="Path 311" d="M11.352 16.918H3.644a1.322 1.322 0 000 2.644h7.708a1.322 1.322 0 000-2.644" />
        <path
          data-name="Path 312"
          d="M15.983 15.155a1.32 1.32 0 001.322-1.322v-3.526a1.322 1.322 0 00-2.644 0v3.526a1.32 1.32 0 001.322 1.322"
        />
        <path
          data-name="Path 313"
          d="M22.319 17.931l-2.45-2.459a.438.438 0 00-.749.308v1.578h-3.554a.882.882 0 100 1.763h3.545v1.578a.436.436 0 00.749.308l2.45-2.459a.434.434 0 00.009-.617"
        />
      </g>
    </svg>
  )
}

export default SvgDirectDeposit
