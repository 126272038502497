import { fontVariants, theme } from "../../../styles/stitches.config"
import { useIsMobileChart } from "../hooks"

type Type = "normal" | "small"

interface Props {
  x: number
  chartHeight: number
  chartYPadding: number
  date?: Date
  text: string
  type?: Type
}

const ChartLabel = ({ x, chartHeight, chartYPadding, text, type }: Props) => {
  const isMobile = useIsMobileChart()
  const fontSize = type === "small" ? (isMobile ? 9 : 12) : isMobile ? 10 : 15
  return (
    <text
      x={x}
      y={chartHeight - (chartYPadding - 25)}
      style={{ ...fontVariants.textsSmall, fontSize }}
      fill={theme.colors.textsAlt.value}
      textAnchor="middle"
    >
      {text}
    </text>
  )
}

export default ChartLabel
