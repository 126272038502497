import React, { useMemo, useState } from "react"
import * as Popover from "@radix-ui/react-popover"
// import "react-day-picker/dist/style.css"
import "./style.css"
import { FormControl } from "../form/FormControl"
import { Date as DateIcon } from "../../images/xd/icons"
import { theme } from "../../styles/stitches.config"
import { DropdownContent } from "./DatePicker.styled"
import { formatIsoDate } from "../../utils/date"
import { Spacer } from "../spacer/Spacer"
import { DayPicker, Matcher } from "react-day-picker"
import { ButtonTrigger } from "./DateInput.styled"
import { isValid } from "date-fns"

interface Props {
  value: string
  setValue: (value: string) => void
  min?: string
  max?: string
}

const DateInput = ({ value, setValue, min, max }: Props) => {
  const [open, setOpen] = useState(false)

  const date = useMemo(() => new Date(value), [value])

  const { disabled, from, to } = useMemo(() => {
    const disabled: Matcher[] = []

    const from = min ? new Date(min) : undefined
    const to = max ? new Date(max) : undefined

    if (to) {
      disabled.push({
        after: to,
      })
    }

    if (from) {
      disabled.push({
        before: from,
      })
    }

    return { disabled, from, to }
  }, [min, max])

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal>
      <FormControl
        rightContent={
          <Popover.Trigger asChild>
            <ButtonTrigger>
              <DateIcon
                color={theme.colors.secondary.value}
                width={theme.space.s6.value}
                height={theme.space.s6.value}
              />
            </ButtonTrigger>
          </Popover.Trigger>
        }
      >
        <Popover.Anchor>
          <input
            type="date"
            min={min}
            max={max}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onClick={(event) => event.preventDefault()}
            onBlur={() => {
              if (!isValid(date)) {
                return setValue(formatIsoDate(new Date()))
              }
            }}
            required
          />
        </Popover.Anchor>
      </FormControl>
      <DropdownContent avoidCollisions>
        <Spacer size={theme.space.s1} />
        <DayPicker
          mode="single"
          selected={date}
          defaultMonth={date}
          fromDate={from}
          toDate={to}
          hidden={disabled}
          onSelect={(d) => {
            if (d) {
              setValue(formatIsoDate(d))
            }
            setOpen(false)
          }}
        />
        <Spacer size={theme.space.s1} />
      </DropdownContent>
    </Popover.Root>
  )
}

export default DateInput
