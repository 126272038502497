import * as React from "react"

function SvgVisibilityOn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 306" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 307"
        d="M12 4a11.827 11.827 0 00-11 7.5 11.817 11.817 0 0022 0A11.827 11.827 0 0012 4zm0 12.5a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisibilityOn
