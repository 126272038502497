import { styled, theme } from "../../../../styles/stitches.config"
import InfoCard from "../infoCard/InfoCard"

export const SContainer = styled("div", {
  gap: theme.space.s4.value,
  display: "flex",
  flexDirection: "column",

  "@md": {
    flexDirection: "row",
  },

  "@lg": {
    maxWidth: theme.sizes.s220,
  },
})

export const contractInfoCardHeight = theme.sizes.s29
export const SInfoCard = styled(InfoCard, {
  "@md": {
    height: contractInfoCardHeight,
  },
})
