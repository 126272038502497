import * as React from "react"

function SvgQuestionmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 227" fill="none">
        <path data-name="Rectangle 310" d="M0 0h24v24H0z" />
        <path data-name="Rectangle 311" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 228">
        <path
          data-name="Path 102"
          d="M7.92 7.54a1.408 1.408 0 01-.66-2.05A5.564 5.564 0 0111.99 3a5.37 5.37 0 014.78 2.41 4.806 4.806 0 01.03 4.9c-1.2 1.77-2.35 2.31-2.97 3.45a2.31 2.31 0 00-.3.94A1.457 1.457 0 0112.1 16a1.476 1.476 0 01-1.48-1.62 4.068 4.068 0 01.46-1.54c.77-1.39 2.25-2.21 3.11-3.44a2.356 2.356 0 00-2.18-3.7 2.746 2.746 0 00-2.4 1.34 1.365 1.365 0 01-1.69.5zM14 20a2 2 0 11-2-2 2.006 2.006 0 012 2z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgQuestionmark
