// import { useEffect } from "react"
// import LogRocket from "logrocket"
import { lazy } from "react"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"
import { AuthRoute } from "./components/AuthRoute"
import { SentryTest } from "./components/hoc/withSentry"
import { LINKS } from "./constants/links"
import Login from "./pages/auth/Login"
import ForgotPassword from "./pages/auth/ForgotPassword"
import Home from "./pages/Home"
import NotFound from "./pages/NotFound"
import Overview from "./pages/overview/Overview"
import Payments from "./pages/payments/Payments"
import DeliveryPoint from "./pages/DeliveryPoint"
import ResetPassword from "./pages/auth/ResetPassword"
import CreateAccount from "./pages/auth/CreateAccount"
import ForgotPasswordSuccess from "./pages/auth/ForgotPasswordSuccess"
import InvoiceDetail from "./pages/payments/InvoiceDetail"
import { ConsentRoute } from "./components/ConsentRoute"
import { MeterReading } from "./pages/meterReading/MeterReading"
import { Flexi2022 } from "./pages/flexi2022/Flexi2022"
import OverviewGeneralRoute from "./pages/overview/OverviewGeneralRoute"
import { MicrositeRouter } from "./pages/microsite/MicrositeRouter"
// import { trpc } from "./api/trpc/trpc"
import Maintenance from "./pages/Maintenance"
import { Flexi2022Variant } from "@nano-portal/shared"
import { Flexibility } from "./pages/flexibility/Flexibility"
import { InvoiceSpotData } from "./pages/invoiceSpotData/InvoiceSpotData"
import { FlexiItSelfBilling } from "./pages/flexiItSelfBilling/FlexiItSelfBilling"
import { trpc } from "./api/trpc/trpc"
import LoadingPage from "./pages/Loading"

const DailyPrices = lazy(() =>
  import("./pages/microsite/dailyPrices/DailyPrices").then((module) => ({ default: module.DailyPrices }))
)

// const LogRocketWrapper = () => {
//   // useEffect(() => {
//   //   if (process.env.NODE_ENV !== "development") {
//   //     LogRocket.init("vckjgc/nano-portal")
//   //   }
//   // }, [])

//   return <Outlet />
// }

const MaintenanceWrapper = () => {
  if (window.location.hostname === "moje.nanogreen.cz") {
    window.location.replace("https://moje.deltagreen.cz")
  }

  const { data, isLoading } = trpc.useQuery(["service.status"])

  if (isLoading) {
    return <LoadingPage />
  }

  if (!isLoading && (data?.status === "MAINTENANCE" || data?.status === "MIGRATION")) {
    return <Maintenance screenType={data?.status} />
  }

  return <Outlet />
}

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="__test/sentry" element={<SentryTest />} />

        <Route path="" element={<MaintenanceWrapper />}>
          <Route
            path=""
            element={
              <AuthRoute>
                <ConsentRoute>
                  <Outlet />
                </ConsentRoute>
              </AuthRoute>
            }
          >
            <Route path={LINKS.home} element={<Home />} />
            <Route path={LINKS.pdt(":deliveryPointId")} element={<OverviewGeneralRoute />} />
            <Route path={LINKS.pdtConsumption(":deliveryPointId")} element={<Overview type="consumption" />} />
            <Route path={LINKS.pdtProduction(":deliveryPointId")} element={<Overview type="production" />} />
            <Route path={LINKS.pdtPayments(":deliveryPointId")} element={<Payments />} />
            <Route path={LINKS.pdtDetail(":deliveryPointId")} element={<DeliveryPoint />} />
            <Route path={LINKS.pdtInvoiceDetail(":deliveryPointId", ":paymentId")} element={<InvoiceDetail />} />
            <Route path={LINKS.pdtFlexibility(":deliveryPointId")} element={<Flexibility />} />

            {/*
            TODO profile is hidden
            <Route path={LINKS.profile} element={<Profile />} />
          */}
          </Route>

          <Route path={LINKS.authLogin} element={<Login />} />
          <Route path={LINKS.authForgotPassword} element={<ForgotPassword />} />
          <Route path={LINKS.authForgotPasswordSuccess} element={<ForgotPasswordSuccess />} />
          <Route path={LINKS.authResetPassword} element={<ResetPassword />} />
          <Route path={LINKS.authCreateAccount} element={<CreateAccount />} />
        </Route>

        <Route path={LINKS.pdtPaymentsInvoiceSpotData(":ean", ":paymentId")} element={<InvoiceSpotData />} />
        <Route path={LINKS.flexi2022(":hash")} element={<Flexi2022 variant={Flexi2022Variant.Flexi2022} />} />
        <Route path={LINKS.flexi1222(":hash")} element={<Flexi2022 variant={Flexi2022Variant.Flexi1222} />} />
        <Route path={LINKS.flexiGreen24(":hash")} element={<Flexi2022 variant={Flexi2022Variant.FlexiGreen24} />} />
        <Route path={LINKS.flexiItSelfBilling(":hash")} element={<FlexiItSelfBilling />} />

        <Route path={LINKS.meterReading(":ean", ":signature")} element={<MeterReading />} />

        <Route path={LINKS.microSite} element={<MicrositeRouter />} />
        <Route path={LINKS.dailyPrices} element={<DailyPrices />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
