import i18n from "i18next"
import commonEn from "./en/common.json"
import commonCs from "./cs/common.json"
import micrositeEn from "./en/microsite.json"
import micrositeCs from "./cs/microsite.json"
import authEn from "./en/auth.json"
import authCs from "./cs/auth.json"
import validationEn from "./en/validation.json"
import validationCs from "./cs/validation.json"
import overviewEn from "./en/overview.json"
import overviewCs from "./cs/overview.json"
import deliveryPointEn from "./en/deliveryPoint.json"
import deliveryPointCs from "./cs/deliveryPoint.json"
import paymentsEn from "./en/payments.json"
import paymentsCs from "./cs/payments.json"
import datePickerEn from "./en/datePicker.json"
import datePickerCs from "./cs/datePicker.json"
import menuEn from "./en/menu.json"
import menuCs from "./cs/menu.json"
import flexi2022En from "./en/flexi2022.json"
import flexi2022Cs from "./cs/flexi2022.json"
import flexibilityEn from "./en/flexibility.json"
import flexibilityCs from "./cs/flexibility.json"
import flexiItSelfBillingEn from "./en/flexiItSelfBilling.json"
import flexiItSelfBillingCs from "./cs/flexiItSelfBilling.json"
import notificationsEn from "./en/notifications.json"
import notificationsCs from "./cs/notifications.json"
import acquisitionFormEn from "./en/acquisitionForm.json"
import acquisitionFormCs from "./cs/acquisitionForm.json"
import bankAccountChangeEn from "./en/bankAccountChange.json"
import bankAccountChangeCs from "./cs/bankAccountChange.json"

import { initReactI18next } from "react-i18next"

export const resources = {
  en: {
    common: commonEn,
    microsite: micrositeEn,
    auth: authEn,
    validation: validationEn,
    overview: overviewEn,
    delivery_point: deliveryPointEn,
    payments: paymentsEn,
    date_picker: datePickerEn,
    menu: menuEn,
    flexi2022: flexi2022En,
    flexibility: flexibilityEn,
    flexiItSelfBilling: flexiItSelfBillingEn,
    notifications: notificationsEn,
    acquisitionForm: acquisitionFormEn,
    bank_account_change: bankAccountChangeEn,
  },
  cs: {
    common: commonCs,
    microsite: micrositeCs,
    auth: authCs,
    validation: validationCs,
    overview: overviewCs,
    delivery_point: deliveryPointCs,
    payments: paymentsCs,
    date_picker: datePickerCs,
    menu: menuCs,
    flexi2022: flexi2022Cs,
    flexibility: flexibilityCs,
    flexiItSelfBilling: flexiItSelfBillingCs,
    notifications: notificationsCs,
    acquisitionForm: acquisitionFormCs,
    bank_account_change: bankAccountChangeCs,
  },
} as const

export const i18nInstance = i18n.use(initReactI18next)

export const getLocale = () => {
  // use this line to return browser language
  // return Intl.NumberFormat().resolvedOptions().locale

  // temporarily hardcoded to cs
  return "cs"
}

export const getCurrency = () => {
  return {
    code: "CZK",
    symbol: "Kč",
  }
}

void i18nInstance.init({
  lng: getLocale(),
  resources,

  ns: [
    "common",
    "microsite",
    "auth",
    "validation",
    "overview",
    "delivery_point",
    "payments",
    "menu",
    "date_picker",
    "flexi_2022",
    "flexibility",
    "notifications",
    "acquisition_form",
    "bank_account_change",
  ],
})
