import { styled, theme } from "../../styles/stitches.config"

export const SSquareButton = styled("button", {
  border: 0,
  height: theme.space.s11_5,
  width: theme.space.s11_5,
  boxShadow: theme.shadows.medium,
  cursor: "pointer",
  backgroundColor: theme.colors.secondary,
  padding: theme.space.s3,
  "&:disabled": {
    border: `3px solid ${theme.colors.lines}`,
    backgroundColor: theme.colors.lines,
    cursor: "not-allowed",
    opacity: 1,
  },
})
