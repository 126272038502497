import { max, min, startOfDay } from "date-fns"
import React, { useCallback, useMemo, useState } from "react"
import { DayPicker as ReactDayPicker, DateRange as PickerDateRange, Matcher } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useIsMobileVersion } from "../../hooks/mediaHooks"
import { theme } from "../../styles/stitches.config"
import {
  getDateFnsLocale,
  getLastMonth,
  getLastYear,
  getThisMonth,
  getThisYear,
  isLastMonthRange,
  isLastYearRange,
  isThisMonthRange,
  isThisYearRange,
} from "../../utils/date"
import { DateRange } from "../../utils/types"
import { Flex } from "../flex/Flex"
import { Spacer } from "../spacer/Spacer"
import { PresetItem, ConfirmButtonWrapper } from "./DatePicker.styled"
import { Button } from "../button/Button"

const Presets = ({ selected, setSelected }: Pick<Props, "selected" | "setSelected">) => {
  const { t } = useTranslation("date_picker")

  const ranges = useMemo(
    () => [
      {
        text: t("date_picker_this_month"),
        onClick: () => setSelected(getThisMonth()),
        active: isThisMonthRange(selected),
      },
      {
        text: t("date_picker_last_month"),
        onClick: () => setSelected(getLastMonth()),
        active: isLastMonthRange(selected),
      },
      {
        text: t("date_picker_this_year"),
        onClick: () => setSelected(getThisYear()),
        active: isThisYearRange(selected),
      },
      {
        text: t("date_picker_last_year"),
        onClick: () => setSelected(getLastYear()),
        active: isLastYearRange(selected),
      },
    ],
    [selected, setSelected, t]
  )

  return (
    <Flex
      direction="column"
      style={{ marginLeft: theme.space.s6.value, marginRight: theme.space.s6.value, marginTop: theme.space.s16.value }}
    >
      {ranges.map(({ text, ...props }, i) => (
        <React.Fragment key={i}>
          <PresetItem {...props}>{text}</PresetItem>
          <Spacer size={theme.space.s2} />
        </React.Fragment>
      ))}
    </Flex>
  )
}

interface ConfirmButtonProps {
  onClick: () => void
}

const ConfirmButton = ({ onClick: onClick }: ConfirmButtonProps) => {
  const { t } = useTranslation("date_picker")
  return (
    <ConfirmButtonWrapper onClick={onClick}>
      <Button
        style={{
          width: theme.space.s32.value,
          height: theme.space.s14.value,
        }}
      >
        {t("date_picker_confirm")}
      </Button>
    </ConfirmButtonWrapper>
  )
}

interface Props {
  selected: DateRange
  setSelected: (selected: DateRange) => void
  maxDate?: Date
  minDate?: Date
  singleDay?: boolean
  onConfirm?: (range: DateRange) => void
}

export const DayPicker = ({ singleDay, selected, setSelected, maxDate, minDate, onConfirm }: Props) => {
  const { from } = selected
  const [locale] = useState(getDateFnsLocale())
  const mobileVersion = useIsMobileVersion()
  const [month, setMonth] = useState(from)

  if (maxDate) {
    maxDate = startOfDay(maxDate)
  }
  if (minDate) {
    minDate = startOfDay(minDate)
  }

  const _setSelected = useCallback(
    (range: PickerDateRange | undefined, selectedDay: Date) => {
      const { from: previousFrom, to: previousTo } = selected

      if (previousFrom !== previousTo) {
        setSelected({ from: selectedDay, to: selectedDay })
      } else if (range && range.to) {
        setSelected(range as DateRange)
      } else {
        setSelected({
          from: selectedDay,
          to: selectedDay,
        })
      }
    },
    [selected, setSelected]
  )

  const _setSelectedFromPreset = useCallback(
    (range: DateRange) => {
      setMonth(range.from)
      setSelected(range)
    },
    [setSelected]
  )

  const disabled = useMemo(() => {
    const matchers: Matcher[] = []

    if (maxDate) {
      matchers.push({
        after: maxDate,
      })
    }

    if (minDate) {
      matchers.push({
        before: minDate,
      })
    }

    return matchers
  }, [maxDate, minDate])

  const fromDate = minDate ? min([selected.from, minDate]) : undefined
  const toDate = maxDate ? max([selected.to, maxDate]) : undefined

  return (
    <>
      {singleDay ? (
        <Flex direction="column" justify="center" align="center">
          <ReactDayPicker
            mode="single"
            selected={selected.from}
            onSelect={(day, selected) => _setSelected({ from: day, to: day }, selected)}
            locale={locale}
            defaultMonth={from}
            month={month}
            onMonthChange={setMonth}
            disabled={disabled}
            hidden={disabled}
            fromDate={fromDate}
            toDate={toDate}
          />
          <ConfirmButton onClick={() => (onConfirm ? onConfirm(selected) : setSelected(selected))} />
          <Spacer size={theme.space.s3} />
        </Flex>
      ) : mobileVersion ? (
        <Flex direction="column" justify="center" align="center">
          <ReactDayPicker
            mode="range"
            selected={selected}
            onSelect={_setSelected}
            numberOfMonths={1}
            locale={locale}
            defaultMonth={from}
            month={month}
            onMonthChange={setMonth}
            disabled={disabled}
            hidden={disabled}
            fromDate={fromDate}
            toDate={toDate}
          />
          <ConfirmButton onClick={() => (onConfirm ? onConfirm(selected) : setSelected(selected))} />
          <Spacer size={theme.space.s3} />
        </Flex>
      ) : (
        <>
          <ReactDayPicker
            mode="range"
            selected={selected}
            onSelect={_setSelected}
            numberOfMonths={2}
            locale={locale}
            defaultMonth={from}
            month={month}
            onMonthChange={setMonth}
            disabled={disabled}
            hidden={disabled}
            fromDate={fromDate}
            toDate={toDate}
          />
          <Flex direction="column" justify="center" align="center">
            <Presets selected={selected} setSelected={_setSelectedFromPreset} />
            <Spacer size={theme.space.s6} />
            <ConfirmButton onClick={() => (onConfirm ? onConfirm(selected) : setSelected(selected))} />
          </Flex>
        </>
      )}
    </>
  )
}
