import { PropsWithChildren } from "react"
import { Flex } from "../../flex/Flex"

export function AuthLayout(props: PropsWithChildren<unknown>) {
  return (
    <>
      <Flex justify="center" align="center" css={{ minHeight: "100vh", width: "100%" }}>
        {props.children}
      </Flex>
    </>
  )
}
