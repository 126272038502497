export function safeIndex<Item>(arr: Item[], index: number): Item {
  const item = arr[index]
  if (item === undefined) {
    throw new Error(`Property in Array length of ${arr.length} at index ${index} is undefined.`)
  }

  return item
}

export function minMaxAvg(numbers: number[], fallback = 0): number {
  if (numbers.length === 0) {
    return fallback
  }
  const min = Math.min(...numbers)
  const max = Math.max(...numbers)
  return (min + max) / 2
}
