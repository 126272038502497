import { useTranslation } from "react-i18next"
import * as icons from "../../images/xd/icons"
import { Flex } from "../../components/flex/Flex"
import { Spacer } from "../../components/spacer/Spacer"
import Text, { TextType } from "../../components/Text"
import { styled, theme } from "../../styles/stitches.config"
import { formatDate } from "../../utils/date"
import { formatCurrency } from "../../utils/format"
import { DueDateStateStyle, usePaymentMethodToTexts } from "../../utils/payments"
import { SSectionInfo } from "./InvoiceDetailContent.styled"
import { PaymentMethod } from "@nano-portal/shared"
import { Div } from "../../components/div/Div"
import { SModalDescription } from "../../components/modal/Modal.styled"

export const SDescription = styled(SModalDescription, {
  lineHeight: "21px",
})

interface Props {
  amount: number
  amountToPair: number
  dueDate: Date | null
  paymentMethod: PaymentMethod
  dueDateStateStyle: DueDateStateStyle
  partialyPaid: boolean
  paymentDate: Date | null | undefined
}

export function InvoiceDetailInfoSection({
  amount,
  dueDate,
  paymentMethod,
  dueDateStateStyle,
  amountToPair,
  partialyPaid,
  paymentDate,
}: Props) {
  const { t } = useTranslation(["common", "payments"])

  const { paymentMethodName, paymentMethodDescription } = usePaymentMethodToTexts(paymentMethod)
  const remainsToBePaid = amount - amountToPair

  const Icon = dueDateStateStyle.icon && icons[dueDateStateStyle.icon]
  return (
    <Div>
      <Flex justify={"between"} wrap={"wrap"} css={{ gap: theme.space.s4 }}>
        <Flex direction={"column"}>
          <Text type="headerH5">{t("common:due_date")}</Text>
          <Spacer size={theme.space.s2} />
          <Flex>
            <Text
              type={dueDateStateStyle.textType ? (`headerH3${dueDateStateStyle.textType}` as TextType) : "textsLarge"}
            >
              {dueDate ? formatDate(dueDate) : "-"}
            </Text>
            <Spacer size={theme.space.s2} />
            {Icon && (
              <Icon
                color={theme.colors[dueDateStateStyle.iconColor].value}
                height={theme.sizes.s4.value}
                width={theme.sizes.s4.value}
              />
            )}
          </Flex>
          <Spacer size={theme.space.s4} />
          {partialyPaid && (
            <>
              <Text type="headerH5">{t("payments:invoice_detail_date_of_partial_payment")}</Text>
              <Spacer size={theme.space.s2} />
              <Text type={"textsLarge"}>{paymentDate && formatDate(paymentDate)}</Text>
              <Spacer size={theme.space.s4} />
            </>
          )}
        </Flex>

        <Flex direction={"column"}>
          <Text type="headerH5">{t("payments:invoice_detail_payment_amount")}</Text>
          <Spacer size={theme.space.s2} />
          <Text type="textsLarge">{formatCurrency(amount)}</Text>
          <Spacer size={theme.space.s4} />
          {partialyPaid && (
            <>
              <Text type="headerH5">{t("payments:state_paid")}</Text>
              <Spacer size={theme.space.s2} />
              <Text type="textsLarge">{formatCurrency(remainsToBePaid)}</Text>
              <Spacer size={theme.space.s4} />
            </>
          )}
        </Flex>

        <Flex direction={"column"}>
          <Text type="headerH5">{t("payments:invoice_detail_payment_method")}</Text>
          <Spacer size={theme.space.s2} />
          <Text type="textsLarge">{paymentMethodName}</Text>
          <Spacer size={theme.space.s4} />
          {partialyPaid && (
            <>
              <Text type="headerH5">{t("payments:invoice_detail_remains_to_be_paid")}</Text>
              <Spacer size={theme.space.s2} />
              <Text type="headerH3Negative">{formatCurrency(amountToPair)}</Text>
              <Spacer size={theme.space.s4} />
            </>
          )}
        </Flex>
      </Flex>

      {paymentMethodDescription && (
        <Flex direction={"column"}>
          <Spacer size={theme.space.s4} />

          <SDescription>{paymentMethodDescription}</SDescription>
        </Flex>
      )}
      {/* <Spacer size={theme.space.s6} /> */}
      {/* <SFlexMobile>
        <Button variant="secondary" onClick={paymentsSettingOnclick} isFullwidth={isMobile}>
          {t("invoice_detail_payments_settings")}
        </Button>
        <Spacer size={theme.space.s4} />
        <Button onClick={changeAdvancesOnClick} isFullwidth={isMobile}>
          {t("payments:change_advances")}
        </Button>
      </SFlexMobile> */}
    </Div>
  )
}

export function InvoiceDetailInfoSectionCard(props: Props) {
  return (
    <SSectionInfo>
      <InvoiceDetailInfoSection {...props} />
    </SSectionInfo>
  )
}
