import * as React from "react"

function SvgNanoEnergiesLogoHorizontal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 30" {...props}>
      <defs>
        <clipPath id="_NanoEnergiesLogoHorizontal_svg__a">
          <path data-name="Rectangle 17653" transform="translate(0 .425)" fill="none" d="M0 0h180v30H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17724">
        <path
          data-name="Path 59389"
          d="M85.927 26.904h-23.06l11.53-23.972zm-16.346-4.221h9.632l-4.816-10.014z"
          fill="#ffc200"
        />
        <g data-name="Group 17722">
          <g data-name="Group 17721" clipPath="url(#_NanoEnergiesLogoHorizontal_svg__a)" transform="translate(0 -.425)">
            <path
              data-name="Path 59390"
              d="M118.116 16.185a6.475 6.475 0 001.341-4.132 7.182 7.182 0 00-.915-3.643 6.314 6.314 0 00-2.744-2.508 9.943 9.943 0 00-4.479-.915h-9.21v22.3h5.394v-8.421h1.23l4.826 8.422h5.962l-5.3-8.769a6.851 6.851 0 003.9-2.334m-4.9-1.719a3.366 3.366 0 01-2.366.71h-3.344V9.529h3.344a3.239 3.239 0 012.35.757 2.781 2.781 0 01.773 2.082 2.739 2.739 0 01-.757 2.1"
              fill="#00515f"
            />
            <path
              data-name="Path 59391"
              d="M122.169 27.289h13.879v-4.321h-8.485v-4.952h7.539v-4.07h-7.539V9.278h8.485v-4.29h-13.879z"
              fill="#00515f"
            />
            <path
              data-name="Path 59392"
              d="M139.107 27.289h13.879v-4.321h-8.485v-4.952h7.54v-4.07h-7.54V9.278h8.485v-4.29h-13.879z"
              fill="#00515f"
            />
            <path
              data-name="Path 59393"
              d="M170.461 4.987v13.816l-9.022-13.816h-5.394v22.3h5.394V13.598l9.021 13.69h5.394V4.987z"
              fill="#00515f"
            />
            <path
              data-name="Path 59394"
              d="M91.229 10.493l2.223-4.548a11.317 11.317 0 00-13.019 2.17l2.461 5.118a6.264 6.264 0 018.334-2.74"
              fill="#00515f"
            />
            <path
              data-name="Path 59395"
              d="M89.877 18.66h4.352a6.274 6.274 0 01-6.969 3.65l1.71 3.555.746 1.552a11.357 11.357 0 009.305-7.1V14.78H88.009z"
              fill="#00515f"
            />
            <path
              data-name="Path 59396"
              d="M19.237 6.406a13.1 13.1 0 00-6.217-1.388H4.595v22.311h8.426a12.883 12.883 0 006.2-1.42 9.839 9.839 0 004.039-3.96 11.83 11.83 0 001.4-5.823 11.745 11.745 0 00-1.4-5.807 9.692 9.692 0 00-4.023-3.913m-1.782 14.422a6.819 6.819 0 01-4.907 1.673H9.991V9.751h2.556a6.819 6.819 0 014.908 1.673 6.192 6.192 0 011.752 4.7 6.19 6.19 0 01-1.752 4.7"
              fill="#ffc200"
            />
            <path
              data-name="Path 59397"
              d="M27.731 27.329h13.881v-4.323h-8.489v-4.955h7.543V13.98h-7.543V9.31h8.489V5.018H27.731z"
              fill="#ffc200"
            />
            <path data-name="Path 59398" d="M50.069 5.018h-5.4v22.311h11.392v-4.134h-5.992z" fill="#ffc200" />
            <path
              data-name="Path 59399"
              d="M64.548 9.262h3.251l2.064-4.292H53.124v4.292h6v18.02l.026-.029.667-1.387 4.734-9.843z"
              fill="#ffc200"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgNanoEnergiesLogoHorizontal
