import SideMenuContent from "../sideMenu/SideMenuContent"
import { Flex } from "../../../flex/Flex"
import Separator from "../../../Separator"
import LogoutButton from "../topMenu/userMenu/LogoutButton"
import ChangePasswordButton from "../topMenu/userMenu/ChangePasswordButton"
import { SContainer } from "./SideMenuStateContent.styled"
import { Spacer } from "../../../spacer/Spacer"
import { theme } from "../../../../styles/stitches.config"

interface Props {
  hideContent?: boolean
}

export default function SideMenuStateContent({ hideContent }: Props) {
  return (
    <SContainer>
      {hideContent ? <Spacer size={theme.space.spx} /> : <SideMenuContent asDropdownContent />}

      <Flex align="center" justify="between" css={{ gap: theme.space.s3 }}>
        <ChangePasswordButton />

        <Flex direction="column" css={{ height: "100%" }}>
          <Separator orientation="vertical" decorative />
        </Flex>

        <LogoutButton />
      </Flex>
    </SContainer>
  )
}
