import { ComponentProps, ReactNode } from "react"
import { SContainer, STitleContainer } from "./SubSection.styled"

export interface SubSectionProps {
  title: ReactNode
  content: ReactNode
  width?: ComponentProps<typeof SContainer>["width"]
}

const SubSection = ({ title, content, width }: SubSectionProps) => {
  return (
    <SContainer width={width}>
      <STitleContainer>{title}</STitleContainer>
      {content}
    </SContainer>
  )
}

export default SubSection
