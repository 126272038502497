import { CSSTransition } from "react-transition-group"
import SideMenuStateContent from "./SideMenuStateContent"
import { RefObject, useEffect, useState } from "react"
import DeliveryPointsStateContent from "./DeliveryPointsStateContent"
import { useNumberParam } from "../../../../hooks/useNumberParam"

type State = "points" | "menu"

interface Props {
  state: State
  topDetailsRef?: RefObject<HTMLElement>
  selectedEan?: string
}

const duration = 200

export default function MenuContent({ state, topDetailsRef, selectedEan }: Props) {
  const [showMenu, setShowMenu] = useState(state === "menu")
  const [showSupplyPoints, setShowSupplyPoints] = useState(state === "points")
  const activeOpmId = useNumberParam("deliveryPointId")

  useEffect(() => {
    state === "menu" ? setShowSupplyPoints(false) : setShowMenu(false)
  }, [state])

  return (
    <>
      <CSSTransition
        in={showMenu}
        timeout={duration}
        classNames="menuState"
        unmountOnExit
        onEnter={() => setShowSupplyPoints(false)}
        onExited={() => setShowSupplyPoints(true)}
      >
        <SideMenuStateContent hideContent={!activeOpmId} />
      </CSSTransition>

      <CSSTransition
        in={showSupplyPoints}
        timeout={duration}
        classNames="supplyPointsState"
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <DeliveryPointsStateContent topDetailsRef={topDetailsRef} selectedEan={selectedEan} />
      </CSSTransition>
    </>
  )
}
