import * as React from "react"

function SvgNoDocs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoDocs_svg__a">
          <path data-name="Rectangle 17154" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 16299" clipPath="url(#_NoDocs_svg__a)">
        <path
          data-name="Path 51358"
          d="M8.016 12.007h3.63a.589.589 0 000-1.178h-3.63a.589.589 0 000 1.178"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51359"
          d="M14.553 13.376H8.016a.589.589 0 000 1.178h6.537a.589.589 0 000-1.178"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51360"
          d="M14.071 15.741H8.016a.589.589 0 000 1.178h6.055a.589.589 0 000-1.178"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51361"
          d="M14.843 19.179l.024-.026.229-.229H5.55V3.912h6.252v4.626a.589.589 0 00.589.589h4.626v4.658a2.263 2.263 0 011.052.567l.025.023.1.1V8.538v-.012a.581.581 0 00-.01-.1.589.589 0 00-.029-.1c0-.01-.008-.019-.012-.028a.584.584 0 00-.036-.068l-.016-.025a.588.588 0 00-.067-.081l-5.215-5.215a.607.607 0 00-.081-.066l-.026-.016a.609.609 0 00-.067-.036l-.028-.012a.59.59 0 00-.1-.029.6.6 0 00-.1-.01H4.961a.589.589 0 00-.589.589v16.19a.589.589 0 00.589.589h9.347a2.267 2.267 0 01.534-.924M12.981 4.745l3.2 3.2h-3.2z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51362"
          d="M19.811 18.373l1.894-1.893a.665.665 0 00-.943-.937l-1.892 1.889-1.893-1.892-.014-.013a.667.667 0 00-.942.945l1.906 1.906-1.893 1.891-.013.014a.667.667 0 00.942.945l1.907-1.906 1.891 1.893.013.013a.668.668 0 10.949-.939l-.005-.005z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgNoDocs
