import { Trans, useTranslation } from "react-i18next"
import Text from "../../components/Text"
import { Flex } from "../../components/flex/Flex"
import { styled, theme } from "../../styles/stitches.config"
import InfoBox from "../../components/info/InfoBox"
import { InfoText } from "../../components/info/InfoText"
import { WEB_LINKS } from "../../constants/links"

const DescriptionLink = styled("a", {
  textDecoration: "underline",
})

export function VatPricesDisclaimer() {
  const { t } = useTranslation(["overview"])
  return (
    <Flex align="center" justify="end" css={{ marginRight: theme.space.s4.value, gap: theme.space.s1 }}>
      <Text type="labelsAlt">{t("overview:vat_prices_disclaimer")}</Text>
      <InfoBox size="small">
        <InfoText type="textsSmall">
          <Trans t={t} i18nKey="overview:vat_prices_disclaimer_description">
            <DescriptionLink target="_blank" rel="noreferrer" href={WEB_LINKS.blogEnergyVat} />
          </Trans>
        </InfoText>
      </InfoBox>
    </Flex>
  )
}

export function VatExcludedDisclaimer() {
  const { t } = useTranslation(["overview"])
  return (
    <Flex align="center" justify="end" style={{ marginRight: theme.space.s4.value }}>
      <Text type="labelsAlt">{t("overview:vat_excluded_disclaimer")}</Text>
    </Flex>
  )
}
