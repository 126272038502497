import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import type * as Stitches from "@stitches/react"
import { createStitches } from "@stitches/react"
import { colors } from "../images/xd/xdTheme"
import * as icons from "../images/xd/icons"
import { ScaleValue } from "@stitches/react/types/theme"

export { variants as fontVariants } from "../images/xd/xdTheme"

const spacings = {
  s0: "0",
  spx: "1px",
  s0_5: "2px",
  s1: "4px",
  s1_5: "6px",
  s2: "8px",
  s2_5: "10px",
  s3: "12px",
  s3_5: "14px",
  s4: "16px",
  s4_5: "18px",
  s5: "20px",
  s5_5: "22px",
  s6: "24px",
  s6_5: "26px",
  s7: "28px",
  s7_5: "30px",
  s8: "32px",
  s8_5: "34px",
  s9: "36px",
  s9_5: "38px",
  s10: "40px",
  s10_5: "42px",
  s11: "44px",
  s11_5: "46px",
  s12: "48px",
  s12_5: "50px",
  s13: "52px",
  s13_5: "54px",
  s14: "56px",
  s15: "60px",
  s15_5: "62px",
  s16: "64px",
  s17: "68px",
  s18: "72px",
  s20: "80px",
  s21: "84px",
  s23: "92px",
  s24: "96px",
  s25: "100px",
  s27: "108px",
  s27_5: "110px",
  s28: "112px",
  s29: "116px",
  s30: "120px",
  s32: "128px",
  s35: "140px",
  s36: "144px",
  s38: "152px",
  s40: "160px",
  s41: "164px",
  s42: "168px",
  s44: "176px",
  s48: "192px",
  s50: "200px",
  s52: "208px",
  s53: "212px",
  s55: "220px",
  s56: "224px",
  s59: "236px",
  s60: "240px",
  s61: "244px",
  s64: "256px",
  s71: "284px",
  s72: "288px",
  s75: "300px",
  s80: "320px",
  s84: "336px",
  s86: "344px",
  s87: "348px",
  s90: "360px",
  s92_5: "370px",
  s96: "384px",
  s98: "392px",
  s100: "400px",
  s105: "420px",
  s106: "424px",
  s110: "440px",
  s122: "488px",
  s132: "528px",
  s133_5: "534px",
  s139: "556px",
  s160: "640px",
  s169: "676px",
  s192: "768px",
  s216_5: "866px",
  s220: "880px",
  s320: "1280px",
}

export const media = {
  xs: 360,
  sm: 576,
  md: 825,
  lg: 1024,
  xl: 1201,
  xxl: 1695,
}

export const stitches = createStitches({
  theme: {
    space: spacings,
    sizes: spacings,
    colors: {
      ...colors,

      white: "#FFF",
      green: "#8EE57E",
      gray: "#EEE",
      transparent: "transparent",
      background: "#F6F8F9",
      lightGreen: "#7CA7AD",
      lightGreenTransparent: "#7CA7AD3D",
      darkGreen: "#00515F",
      good: "#affc5d",
      bad: "#ffa913",
    },
    fonts: {
      default: "Poppins",
    },
    fontWeights: {
      thin: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    fontSizes: {
      xSmall: "12px",
      small: "14px",
      regular: "16px",
      medium: "18px",
    },
    shadows: {
      default: "6px 6px 16px #00000029",
      medium: "3px 6px 16px #00000029",
      mediumLight: "3px 6px 12px #00000029",
      light: "3px 3px 16px #00000029",
    },
    radii: {
      default: "16px",
      medium: "13px",
    },
    borderStyles: {
      thin: `solid ${spacings.spx} ${colors.lines}`,
    },
  },
  media: {
    xs: `(min-width: ${media.xs}px)`,
    sm: `(min-width: ${media.sm}px)`,
    md: `(min-width: ${media.md}px)`,
    lg: `(min-width: ${media.lg}px)`,
    xl: `(min-width: ${media.xl}px)`,
  },
  utils: {
    hover: (css: Stitches.CSS) => ({
      "@media (hover: hover)": {
        "&:hover": css,
      },
    }),
    noReducedMotion: (css: Stitches.CSS) => ({
      "@media (prefers-reduced-motion: no-preference)": {
        ...css,
      },
    }),
    ellipsis: (css: Stitches.CSS) => {
      return {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...css,
      }
    },
    fullheight: (css: Stitches.CSS) => ({ height: "calc(100 * var(--vh))", ...css }),
  },
})

export const { styled, getCssText, css, globalCss, config, createTheme, theme, keyframes, prefix, reset } = stitches

export const important = (style: ScaleValue | string) => {
  return `${style} !important`
}

const globalCssObject = {
  a: {
    textDecoration: "none",
    "&:visited": { color: "inherit" },
  },
  p: {
    margin: 0,
  },
  body: {
    backgroundColor: theme.colors.background,
  },
  "*, *::before, *::after": {
    boxSizing: "border-box",
  },
  ":root": {
    "--toastify-color-success": theme.colors.successesLight,
    "--toastify-color-error": theme.colors.errors,
    "--toastify-text-color-success": theme.colors.primary,
    "--toastify-text-color-error": theme.colors.white,
    "--toastify-color-warning": theme.colors.warning,
    "--toastify-text-color-warning": theme.colors.white,
  },
  ".Toastify__close-button": {
    color: "inherit",
  },
  "#daktela-web *": {
    fontFamily: `${theme.fonts.default} !important`,
  },
  "#daktela-web-button-state": {
    backgroundColor: "#0CAB9B !important",
  },
  "#daktela-web-header-state": {
    backgroundColor: "#0CAB9B !important",
  },
  [`@media(max-width: ${media.xl}px)`]: {
    "#daktela-web .dw-button": {
      bottom: "55px !important",
    },
  },
  'input[type="date"]::-webkit-inner-spin-button': {
    display: "none",
    "-webkit-appearance": "none",
  },
  'input[type="date"]::-webkit-calendar-picker-indicator': {
    display: "none",
    "-webkit-appearance": "none",
  },
  'input[type="date"]::-webkit-clear-button': {
    display: "none",
    "-webkit-appearance": "none",
  },
}

export const defaultGlobalStyles = globalCss(globalCssObject)

export const micrositeGlobalStyles = globalCss({
  ...globalCssObject,
  body: {
    backgroundColor: theme.colors.white,
  },

  "#daktela-web": {
    display: "none !important",
  },
})

export type CSSProp = Stitches.CSS<typeof config>

export type ColorType = keyof typeof theme.colors
export type IconType = keyof typeof icons
export type MediaSizeType = keyof typeof media

export function getStyledIcon(icon: IconType, css?: CSSProp) {
  if (!css) {
    return styled(icons[icon])
  }

  return styled(icons[icon], css)
}
