import { ComponentType, useRef } from "react"
import { useWindowSize } from "react-use"
import useMeasureDirty from "react-use/lib/useMeasureDirty"

const minHeight = 320

export interface DimensionProps {
  width: number
  height: number
}

export const withResponsiveContainer = <P extends DimensionProps>(WrappedComponent: ComponentType<P>) => {
  const ResponsiveContainer = (props: Omit<P, "width" | "height">) => {
    const ref = useRef<HTMLDivElement>(null)
    const { width } = useMeasureDirty(ref)

    const { height: windowHeight } = useWindowSize()

    const responsiveHeight = Math.min(width * 0.6, windowHeight * 0.4)
    const height = Math.max(minHeight, responsiveHeight)

    return (
      <div ref={ref} style={{ width: "100%" }}>
        <WrappedComponent {...({ ...props, width, height } as P)} />
      </div>
    )
  }

  return ResponsiveContainer
}
