import { ComponentPropsWithRef } from "react"
import Text from "../../components/Text"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import {
  SPaymentInfo,
  SPaymentAccountInfo,
  SAdvanceInfo,
  PaymentInfoCell,
  SIconCopy,
  SInfoIcon,
  SAdvanceInfoCard,
} from "./PaymentInfo.styled"
import { Flex } from "../../components/flex/Flex"
import { useCopyToClipboard } from "../../utils/clipboard"
import { trpc } from "../../api/trpc/trpc"
import { resolveQueriesState } from "../../utils/queries"
import RectPlaceholder from "../../components/placeholder/components/RectPlaceholder"
import { formatCurrency } from "../../utils/format"
import { usePaymentMethodToTexts } from "../../utils/payments"
import { PaymentMethod } from "@nano-portal/shared"
import Separator from "../../components/Separator"
import { useTranslation } from "react-i18next"

function PaymentInfoCellLabel({ children, ...props }: Omit<ComponentPropsWithRef<typeof Text>, "type">) {
  return (
    <Text type="headerH5" {...props}>
      {children}
    </Text>
  )
}

type PaymentInfoCellValueProps = Omit<ComponentPropsWithRef<typeof Text>, "type"> & {
  onCopy?: () => void
}

function PaymentInfoCellValue({ children, onCopy, ...props }: PaymentInfoCellValueProps) {
  function renderContent() {
    return (
      <Text type="textsLarge" {...props}>
        {children}
      </Text>
    )
  }

  if (onCopy) {
    return (
      <Flex align="center" css={{ gap: theme.space.s1, cursor: "pointer" }} onClick={onCopy}>
        {renderContent()}
        <SIconCopy />
      </Flex>
    )
  }
  return (
    <Text type="textsLarge" {...props}>
      {children}
    </Text>
  )
}

type Props = {
  deliveryPointId: number
}

export function PaymentInfo(props: Props) {
  const { deliveryPointId } = props
  const copyToClipboard = useCopyToClipboard()

  const paymentInfoQuery = trpc.useQuery(["payments.paymentInfo", { deliveryPointId }])
  const { t } = useTranslation(["payments"])
  const { isLoading, isError } = resolveQueriesState([paymentInfoQuery])

  const { paymentMethodName } = usePaymentMethodToTexts(
    paymentInfoQuery.data?.advanceInfo.paymentMethod ?? PaymentMethod.BankOrder
  )

  if (isLoading) {
    return <RectPlaceholder />
  }

  if (isError || !paymentInfoQuery.data) {
    // TODO návrh na zobrazení chyby?
    return null
  }

  const { paymentInfo, advanceInfo } = paymentInfoQuery.data

  return (
    <>
      <Text type="headerH1">{t("payments:payments_info_header")}</Text>

      <SPaymentInfo>
        <SPaymentAccountInfo>
          <PaymentInfoCell position="relative">
            <SInfoIcon />
            <PaymentInfoCellLabel>{t("payments:payments_info_delta_green_account_number")}</PaymentInfoCellLabel>
            <Spacer size={theme.space.s2} />
            <PaymentInfoCellValue onCopy={() => void copyToClipboard(paymentInfo.accountNumber)}>
              {paymentInfo.accountNumber}
            </PaymentInfoCellValue>
          </PaymentInfoCell>

          <PaymentInfoCell>
            <PaymentInfoCellLabel>{t("payments:payments_info_variable_symbol")}</PaymentInfoCellLabel>
            <Spacer size={theme.space.s2} />
            <PaymentInfoCellValue onCopy={() => void copyToClipboard(paymentInfo.variableSymbol)}>
              {paymentInfo.variableSymbol}
            </PaymentInfoCellValue>
          </PaymentInfoCell>
        </SPaymentAccountInfo>

        <Spacer size={theme.space.s6} />

        <SAdvanceInfoCard>
          <SAdvanceInfo>
            <PaymentInfoCell>
              <PaymentInfoCellLabel>{t("payments:payments_info_advance_amount")}</PaymentInfoCellLabel>
              <Spacer size={theme.space.s2} />
              <PaymentInfoCellValue>{formatCurrency(advanceInfo.advanceAmount)}</PaymentInfoCellValue>
            </PaymentInfoCell>

            <PaymentInfoCell>
              <PaymentInfoCellLabel>{t("payments:payments_info_payment_method")}</PaymentInfoCellLabel>
              <Spacer size={theme.space.s2} />
              <PaymentInfoCellValue>{paymentMethodName}</PaymentInfoCellValue>
            </PaymentInfoCell>
          </SAdvanceInfo>

          <Spacer size={theme.space.s1_5} />
          <Separator orientation="horizontal" />
        </SAdvanceInfoCard>
      </SPaymentInfo>
    </>
  )
}
