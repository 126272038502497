import { merge } from "lodash"
import qs from "query-string"

type SearchParams = Record<string, unknown>

export function addSearchParams(url: string, args: SearchParams = {}): string {
  const { query: initialQuery, url: baseUrl } = qs.parseUrl(url)
  const queryObj = merge(initialQuery, args)
  const querystring = qs.stringify(queryObj, { skipNull: true })
  return querystring.length > 0 ? `${baseUrl}?${querystring}` : baseUrl
}

export function hasUppercase(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) == str.charAt(i).toUpperCase() && /[a-z]/i.test(str.charAt(i))) {
      return true
    }
  }

  return false
}

export function hasLowercase(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) == str.charAt(i).toLowerCase() && /[a-z]/i.test(str.charAt(i))) {
      return true
    }
  }

  return false
}

export function hasNumber(str: string) {
  return /\d/.test(str)
}

export function splitAddressToStreetAndTown(address: string) {
  const splitAddress = address.split(",")
  const street = splitAddress[0]
  const town = splitAddress[1]?.split("-")[0]
  return [street, town] as const
}
