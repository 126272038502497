import { theme } from "../../../../styles/stitches.config"
import { Div } from "../../../div/Div"
import Separator from "../../../Separator"
import UserMenuItem from "../topMenu/userMenu/UserMenuItem"
import { useTranslation } from "react-i18next"
import { Flex } from "../../../flex/Flex"
import UserMenuItemContainer from "../topMenu/userMenu/UserMenuItemContainer"
import { memo, RefObject, useLayoutEffect, useRef, useState } from "react"
import DeliveryPointDetails from "../topMenu/DeliveryPointDetails"
import { SContainer } from "./DeliveryPointsStateContent.styled"
import { useNavigate } from "react-router-dom"
import { LINKS } from "../../../../constants/links"
import useSupplyPointNavigation from "../../../../hooks/useSupplyPointNavigation"
import { trpc } from "../../../../api/trpc/trpc"
import TablePlaceholder from "../../../placeholder/components/TablePlaceholder"
import ScrollArea from "../../../scrollarea/ScrollArea"

/**
 * Scroll area works correctly only when it's specific height is set. However, we don't know it upfront, since
 * details on top and link to home screen are always sticky on top and bottom, respectively.
 *
 * Therefore, we need to compute the scoll area height before the component is rendered.
 *
 * @param topLevelDetailsRef A ref to the top level details container
 * @returns object with ref to be hooked to the bottom home screen link and possibly computed scroll area height
 * depending on whether the input parameter is provided or not
 */
const useItemListHeight = ({ topDetailsRef }: { topDetailsRef?: RefObject<HTMLElement> }) => {
  const [surroundingsHeight, setSurroundingsHeight] = useState(0)
  const bottomItemRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const bottomItemRect = bottomItemRef?.current?.getBoundingClientRect()
    const bottomItemHeight = bottomItemRect?.height || 0

    const topDetailsRect = topDetailsRef?.current?.getBoundingClientRect()
    const topDetailsHeight = topDetailsRect?.height || 0

    setSurroundingsHeight(bottomItemHeight + topDetailsHeight)
  }, [topDetailsRef, bottomItemRef])

  return { bottomItemRef, itemListHeight: topDetailsRef ? `calc(100vh - ${surroundingsHeight}px)` : undefined }
}

type Props = {
  itemListHeight?: number
  topDetailsRef?: RefObject<HTMLElement>
  selectedEan?: string
}

function DeliveryPointsStateContent({ topDetailsRef, selectedEan }: Props) {
  const { t } = useTranslation("menu")
  const spNavigate = useSupplyPointNavigation()
  const navigate = useNavigate()
  const points = trpc.useQuery(["deliveryPoints.list"])

  const { bottomItemRef, itemListHeight } = useItemListHeight({ topDetailsRef })

  if (points.isLoading || !points.data) {
    return <TablePlaceholder />
  }

  return (
    <SContainer>
      <ScrollArea css={{ height: itemListHeight }}>
        <Flex
          direction="column"
          css={{
            paddingTop: theme.space.s3,
            paddingBottom: theme.space.s3,
            paddingLeft: theme.space.s6,
            paddingRight: theme.space.s6,
            gap: theme.space.s3,
          }}
        >
          {points.data.map((item) => (
            <UserMenuItemContainer key={item.ean} onClick={() => spNavigate(item.deliveryPointId)}>
              <DeliveryPointDetails {...item} isSelected={item.ean === selectedEan} />
            </UserMenuItemContainer>
          ))}
        </Flex>
      </ScrollArea>
      <Div css={{ width: "100%" }} ref={bottomItemRef}>
        <Separator orientation="horizontal" decorative />
        <UserMenuItemContainer
          onClick={() => navigate(LINKS.home)}
          css={{ padding: `${theme.space.s4} ${theme.space.s6}` }}
        >
          <UserMenuItem text={t("user_menu_supply_point_overview")} textType="menuItem" icon="Dashboard" />
        </UserMenuItemContainer>
      </Div>
    </SContainer>
  )
}

export default memo(DeliveryPointsStateContent)
