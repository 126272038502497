import Compressor from "compressorjs"

const DEBUG_DELAY = 0
const MAX_SIZE = 600
const QUALITY = 0.8

type ProgressCallback = (progress: number) => void

export function blobToFile(theBlob: Blob, fileName: string): File {
  return new File([theBlob], fileName, { lastModified: Date.now(), type: theBlob.type })
}

function compressFile(file: File | Blob) {
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      quality: QUALITY,
      maxHeight: MAX_SIZE,
      maxWidth: MAX_SIZE,
      success: (result) => {
        // NOTE: Blob je tu v typu pravděpodobně jenom kvůli tomu, že Compressor dokáže optimalizovat i Blob
        const file = result instanceof File ? result : blobToFile(result, "name.jpeg")
        resolve(file)
      },
      error: reject,
    })
  })
}

function putFile(url: string, file: File | Blob, progressCallback?: ProgressCallback): Promise<XMLHttpRequest> {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          setTimeout(() => {
            resolve(xhr)
          }, DEBUG_DELAY)
        } else {
          reject(xhr)
        }
      }
    })

    if (progressCallback) {
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const percentComplete = Math.round((e.loaded / file.size) * 100)
          if (DEBUG_DELAY && percentComplete === 100) {
            for (let i = 0; i <= DEBUG_DELAY; i += DEBUG_DELAY / 10) {
              // const progress = i
              setTimeout(progressCallback.bind(progressCallback, (i / DEBUG_DELAY) * 100), i)
            }

            return
          }
          progressCallback(percentComplete)
        }
      })
    }

    xhr.open("PUT", url)
    xhr.send(file)
  })
}

export async function uploadImage(url: string, file: File, compress = true, progressCallback?: ProgressCallback) {
  const finalFile = compress ? await compressFile(file) : file

  return putFile(url, finalFile, progressCallback)
}
