import { RValueCellProps, withNullableValue } from "../../../../../components/table/renderers"
import { Flex } from "../../../../../components/flex/Flex"
import { formatEnergy } from "../../../../../utils/format"
import { useTranslation } from "react-i18next"
import { fontVariants, theme } from "../../../../../styles/stitches.config"
import { SReadingsCellContainer } from "./renderers.styled"
import { Span } from "../../../../../components/span/Span"

type Tariff = "low" | "high"
interface TariffReadingProps {
  tariff: Tariff
  amount: number
}

const TariffReading = ({ tariff, amount }: TariffReadingProps) => {
  const { t } = useTranslation("delivery_point")

  const labelStyles =
    tariff === "low" ? fontVariants.headerH3 : { ...fontVariants.headerH3Positive, color: theme.colors.secondary }
  const label = tariff === "low" ? t("supply_point_low_tariff_label") : t("supply_point_high_tariff_label")

  return (
    <Flex justify="between">
      <Span css={{ ...labelStyles }}>{label}</Span>
      {formatEnergy(Math.round(amount))}
    </Flex>
  )
}

export interface ReadingsCellValue {
  lowTariffValue: number | null
  highTariffValue: number
}

export interface AttachmentsCellValue {
  lowTariffReadingsUrl: string
  highTariffReadingsUrl: string
}

export const ReadingsCell = withNullableValue(({ value }: RValueCellProps<ReadingsCellValue>) => {
  return (
    <SReadingsCellContainer>
      {value.lowTariffValue !== null && <TariffReading tariff="low" amount={value.lowTariffValue} />}
      <TariffReading tariff="high" amount={value.highTariffValue} />
    </SReadingsCellContainer>
  )
})
