import React from "react"
import { theme } from "../../styles/stitches.config"
import { Spacer } from "../spacer/Spacer"
import HeaderPlaceholder from "./components/HeaderPlaceholder"
import ChartPlaceholder from "./components/ChartPlaceholder"
import TablePlaceholder from "./components/TablePlaceholder"

const OverviewPlaceholder = () => {
  return (
    <>
      <HeaderPlaceholder cols={1} />
      <Spacer size={theme.space.s7} />
      <HeaderPlaceholder cols={2} height={32} />
      <Spacer size={theme.space.s7} />
      <ChartPlaceholder />
      <Spacer size={theme.space.s7} />
      <TablePlaceholder />
    </>
  )
}

export default OverviewPlaceholder
