import { FundamentalInfo } from "./components/fundamentalInfo/FundamentalInfo"
import ContractInfo from "./components/contractInfo/ContractInfo"
import TechnicalParams from "./components/technicalParams/TechnicalParams"
import Measurements from "./components/measurements/Measurements"
import { SContainer, SContractInfo } from "./DeliveryPointContent.styled"
import { InferQueryOutput } from "../../api/trpc/trpcHelper"
import { useMemo, useState } from "react"
import DeliveryPointTypeToggle from "./components/deliveryPointTypeToggle/DeliveryPointTypeToggle"
import Text from "../../components/Text"
import { Flex } from "../../components/flex/Flex"
import { useTranslation } from "react-i18next"
import { splitAddressToStreetAndTown } from "../../utils/string"
import Documents from "./components/documents/Documents"
import { DeliveryPointType, megawattHourToKilowattHour, VoltageType, MeasurementType } from "@nano-portal/shared"

// Utility error fallback, kdyby nebyla data - nenastane, ale pro jistotu a abychom se zbavili undefined
const NoDataErrorFallback = () => {
  const { t } = useTranslation("delivery_point")
  return (
    <Flex direction="column">
      <Text type="headerH2">{t("delivery_point_data_error_header")}</Text>
      <Text type="textsLarge">{t("delivery_point_data_error_description")}</Text>
    </Flex>
  )
}

interface Props {
  data: NonNullable<InferQueryOutput<"deliveryPoints.fullDetail">>
}

export default function DeliveryPointContent({ data }: Props) {
  const isProduction = data.type.includes(DeliveryPointType.Production)
  const isConsumption = data.type.includes(DeliveryPointType.Consumption)
  const isProductionAndConsumption = isProduction && isConsumption

  const [selectedType, setSelectedType] = useState(
    isConsumption || isProductionAndConsumption ? DeliveryPointType.Consumption : DeliveryPointType.Production
  )
  const selectedData = data.typeData.get(selectedType)

  const address = data.address

  const [street, town] = useMemo(() => splitAddressToStreetAndTown(address), [address])

  if (!selectedData) {
    return <NoDataErrorFallback />
  }

  return (
    <SContainer>
      <FundamentalInfo
        deliveryPointType={data.type}
        addressStreet={street}
        addressTown={town}
        eanEic={data.ean}
        commodity={selectedData.commodity}
        supplyPointName={data.name}
        consumption={megawattHourToKilowattHour(data.annualConsumption)}
        production={megawattHourToKilowattHour(data.annualProduction)}
        paymentsFrequency={selectedData.paymentsFrequency}
        advanceAmount={selectedData.advanceAmount}
        selectedType={selectedType}
      />

      {isProductionAndConsumption && (
        <DeliveryPointTypeToggle selected={selectedType} onSelectedChange={setSelectedType} />
      )}

      <SContractInfo>
        <ContractInfo
          contractNumber={selectedData.contractCode}
          status={selectedData.status}
          product={selectedData.product}
        />
        <Documents data={selectedData.documents} />
      </SContractInfo>
      <TechnicalParams
        distributor={selectedData.distributor}
        distributionRate={selectedData.distributionTariff}
        circuitBreaker={selectedData.circuitBreaker}
        measurementType={data.measurementType}
      />

      {selectedType === DeliveryPointType.Consumption && data.voltageType === VoltageType.Low && (
        <Measurements
          distributorCode={selectedData.distributorCode}
          deliveryPointId={data.deliveryPointId}
          singleTariff={data.singleTariff ?? false}
          ean={data.ean}
          address={{ town: town || "", street: street || "" }}
          disableCreateMeasurement={!data.isActive}
          hideSelfMeasurement={data.measurementType !== MeasurementType.C}
        />
      )}
    </SContainer>
  )
}
