import { DateRange } from "../../../utils/types"
import { SContainer } from "../yearPicker/YearRangePicker.styled"
import MonthSelect from "./MonthSelect"
import YearSelect from "../yearPicker/YearSelect"
import { Spacer } from "../../spacer/Spacer"
import { theme } from "../../../styles/stitches.config"
import { getMonthRange, updateMonth, updateYear } from "../../../utils/date"

const yearSpan = 20

interface Props {
  selected: DateRange
  maxDate?: Date
  minDate?: Date
  onSelect: (range: DateRange) => void
}

export function MonthYearPicker({ selected, minDate, maxDate, onSelect }: Props) {
  const handleSelect = (newSelected: DateRange) => {
    onSelect(newSelected)
  }

  return (
    <SContainer>
      <MonthSelect
        value={selected.from.getMonth()}
        onSelect={(month) => handleSelect(getMonthRange(updateMonth(month, selected.from)))}
      />
      <Spacer size={theme.space.s4} />
      <YearSelect
        minYear={minDate ? minDate.getFullYear() : selected.from.getFullYear() - yearSpan}
        maxYear={maxDate ? maxDate.getFullYear() : selected.to.getFullYear() + yearSpan}
        value={selected.from.getFullYear()}
        onSelect={(year) => handleSelect(getMonthRange(updateYear(year, selected.from)))}
      />
    </SContainer>
  )
}
