import * as React from "react"

function SvgLeaves1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.163 238.539" {...props}>
      <defs>
        <clipPath id="_Leaves1_svg__a">
          <path data-name="Rectangle 510" fill="none" d="M0 0h236.163v238.539H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1148">
        <g data-name="Group 1147" clipPath="url(#_Leaves1_svg__a)">
          <path
            data-name="Path 368"
            d="M231.037 233.679c-.138 0-.277-.006-.416-.017-62.657-5.227-120.808-31.505-163.743-73.993C23.829 117.066.077 62.149 0 5.033A5 5 0 012.281.829 4.906 4.906 0 015.229.006C8.5.132 11.682.3 14.694.5 75.9 5.076 132.571 31.293 174.315 74.319c41.83 43.113 63.748 98 61.719 154.539a5 5 0 01-5 4.821M10.077 10.238C13.131 118.667 106.579 210.8 226.156 223.2c.838-108.961-93.176-203.822-212.163-212.73a314.717 314.717 0 00-3.916-.232"
            fill="#ffc200"
          />
          <path
            data-name="Path 369"
            d="M86.56 238.539a4.982 4.982 0 01-2.28-.553l-.033-.017c-1.8-.928-3.528-1.873-5.29-2.889C33.126 208.619 13.6 153.9 34.5 110.5a86.977 86.977 0 019.415-15.217 5 5 0 017.026-.893c44.695 34.737 61.245 92.642 40.3 140.9a5.112 5.112 0 01-.237.532 5 5 0 01-4.448 2.71M48.837 105.56a76.953 76.953 0 00-5.33 9.293c-18.641 38.7-.875 87.707 40.45 111.566l.07.04c15.46-41.56 1.415-89.92-35.19-120.9"
            fill="#00515f"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLeaves1
