import { useState } from "react"
import MeasurementsTable, { MeasurementsTableData } from "./table/MeasurementsTable"
import { trpc } from "../../../../api/trpc/trpc"
import TablePlaceholder from "../../../../components/placeholder/components/TablePlaceholder"
import { Spacer } from "../../../../components/spacer/Spacer"
import { theme } from "../../../../styles/stitches.config"
import { Flex } from "../../../../components/flex/Flex"
import { Pagination } from "../../../../components/pagination/Pagination"
import { SButtonsContainer } from "./GaugeContent.styled"
import { Button } from "../../../../components/button/Button"
import { useTranslation } from "react-i18next"
import { useModal } from "../../../../hooks/useModal"
import { useIsMobileVersion } from "../../../../hooks/mediaHooks"
import { CreateMeasurement } from "../../../createMeasurement/CreateMeasurement"
import Text from "../../../../components/Text"
import InfoBox from "../../../../components/info/InfoBox"
import { defaultMeasurementsOffset, defaultMeasurementsPerPage, isCezDistributor } from "@nano-portal/shared"
import { Trans } from "react-i18next"

interface Props {
  deliveryPointId: number
  singleTariff: boolean
  ean: string
  disableCreateMeasurement: boolean
  distributorCode: string
  address: {
    street: string
    town: string
  }
  hideSelfMeasurement?: boolean
}

export default function MeasurementsContent({
  deliveryPointId,
  singleTariff,
  address,
  ean,
  disableCreateMeasurement,
  hideSelfMeasurement,
  distributorCode,
}: Props) {
  const [offset, setOffset] = useState(defaultMeasurementsOffset)

  const { t } = useTranslation(["delivery_point"])
  const { setModal, hideModal } = useModal()
  const isMobile = useIsMobileVersion()

  const measurements = trpc.useQuery([
    "deliveryPoints.measurements",
    {
      deliveryPointId: deliveryPointId,
      offset,
      limit: defaultMeasurementsPerPage,
    },
  ])

  const getUploadUrl = trpc.useMutation(["meterReadings.getReadingImagePresignedUrl"], { onError: undefined })
  const saveMeterReading = trpc.useMutation(["meterReadings.saveReading"], { onError: undefined })

  const data: MeasurementsTableData[] =
    measurements.data?.items.map((measurement) => ({
      colDateReading: {
        date: measurement.endAt,
        justify: "start",
      },
      colReadingValue: {
        lowTariffValue: measurement.lowTariffEnd,
        highTariffValue: measurement.highTariffEnd,
      },
      colAttachment: {
        lowTariffReadingsUrl: "", //TODO kde seženeme?
        highTariffReadingsUrl: "",
      },
      rowType: "success",
    })) ?? []

  return (
    <>
      {measurements.isLoading || !measurements.data ? (
        <TablePlaceholder />
      ) : (
        <MeasurementsTable
          data={data}
          notSupported={measurements.data.state === "NOT_SUPPORTED"}
          selfMeasurementsEnabled={!hideSelfMeasurement && !disableCreateMeasurement}
        />
      )}
      <Spacer size={theme.space.s4} />

      <Flex justify="end">
        <Text type="textsLarge">
          <Trans t={t} i18nKey="delivery_point:meter_readings_low_tariff">
            <Text type="headerH3" />
          </Trans>
        </Text>

        <Spacer size={theme.space.s7} />

        <Text type="textsLarge">
          <Trans t={t} i18nKey="delivery_point:meter_readings_high_tariff">
            <Text type="headerH3Positive" color="secondary" />
          </Trans>
        </Text>
      </Flex>

      <Spacer size={theme.space.s4} />
      <Flex justify="between">
        <Spacer size={theme.space.s2} />
        {!hideSelfMeasurement && (
          <>
            <Flex>
              <InfoBox variant="warning" />
              <Spacer size={theme.space.s2} />
              <Text type="labelsAlt">{t("delivery_point:measurements_info")}</Text>
            </Flex>

            <Spacer size={theme.space.s2} />
          </>
        )}

        <Pagination
          itemsPerPage={defaultMeasurementsPerPage}
          totalItems={measurements.data?.totalCount ?? 0}
          onPageChange={(newPage) => setOffset((newPage - 1) * defaultMeasurementsPerPage)}
        />
      </Flex>

      {!hideSelfMeasurement && (
        <>
          <Spacer size={theme.space.s4} />
          <SButtonsContainer>
            {!disableCreateMeasurement && (
              <Button
                disabled={disableCreateMeasurement}
                isFullwidth={isMobile}
                onClick={() =>
                  setModal({
                    modal: (
                      <CreateMeasurement
                        ean={ean}
                        address={address}
                        onMeasurementCreated={hideModal}
                        onlyHighTariff={singleTariff}
                        getUploadUrl={getUploadUrl.mutateAsync}
                        enableExtraInvoiceOption={isCezDistributor(distributorCode)}
                        saveReading={(input) =>
                          saveMeterReading.mutateAsync({
                            deliveryPointId,
                            ...input,
                          })
                        }
                      />
                    ),
                    isFullscreenOnMobile: true,
                  })
                }
              >
                {t("delivery_point:create_measurement_new_measurement")}
              </Button>
            )}

            {/*<Button isFullwidth={isMobile} variant="secondary">
          {t("translations:measurements_notifications_settings")}
        </Button>*/}
          </SButtonsContainer>
        </>
      )}
    </>
  )
}
