import { theme } from "../../../../styles/stitches.config"
import DeliveryPointDetails from "../topMenu/DeliveryPointDetails"
import { Flex } from "../../../flex/Flex"
import { useState, ReactElement, useCallback } from "react"
import AlternateRotatingIcon from "../../../rotatingIcon/AlternateRotatingIcon"
import { ArrowForward } from "../../../../images/xd/icons"
import { defaultAnimationDuration } from "../../../../styles/utils"

interface Props {
  supplyPointDetails?: ReactElement<typeof DeliveryPointDetails>
  onSwitch?: (toggled: boolean) => void
}

export default function MenuStateSwitch({ onSwitch, supplyPointDetails }: Props) {
  const [toggled, setToggled] = useState(false)

  const handleSwitch = useCallback(() => {
    setToggled((prev) => !prev)
    onSwitch?.(!toggled)
  }, [toggled, onSwitch])

  return (
    <Flex
      onClick={handleSwitch}
      justify="between"
      align="center"
      css={{
        hover: {
          cursor: "pointer",
        },
        padding: `${theme.space.s2} ${theme.space.s6}`,
      }}
    >
      {supplyPointDetails}
      <AlternateRotatingIcon
        icon={ArrowForward}
        toggled={toggled}
        direction="right"
        duration={defaultAnimationDuration}
      />
    </Flex>
  )
}
