import { CSSProperties } from "react"
import { styled, theme } from "../../styles/stitches.config"

export const FilterDownloadContainer = styled("div", {
  display: "flex",
  flexDirection: "row",

  alignItems: "center",
  gap: theme.space.s4,
})

export const ToggleContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s2,
  marginRight: 0,
  justifyContent: "space-between",
  width: "100%",

  "@sm": {
    flexDirection: "row",
    height: theme.space.s7_5,
    gap: theme.space.s0,
  },

  "@lg": {
    width: "auto",
    gap: theme.space.s4,
  },
})

export const TopContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  marginLeft: theme.space.s6,
  marginRight: theme.space.s6,
  "@lg": {
    marginLeft: theme.space.s9,
    marginRight: 0,

    "&:last-child": {
      marginLeft: "auto",
    },
  },
})

export const DatePickerMobileContainer = styled("div", {
  position: "fixed",
  display: "flex",
  bottom: 0,
  left: 0,
  right: 0,
  alignItems: "stretch",
  width: "100vw",
})

export const anchorContainerStyle: CSSProperties = {
  position: "absolute",
  bottom: theme.space.s14.value,
  left: 0,
  right: 0,
}
