import { Flex } from "../../components/flex/Flex"
import { Text, TextType } from "../../components/Text"
import { Button } from "../../components/button/Button"
import { PropsWithChildren, useState } from "react"
import Checkbox from "../../components/checkbox/Checkbox"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { SCardWrapper, SRrappweLeft, SText } from "./PolicyConsent.styled"
import { TermsAndConditions } from "../../images/xd/icons"
import { useIsMaxSm } from "../../hooks/mediaHooks"
import { Trans, useTranslation } from "react-i18next"
import { WEB_LINKS } from "../../constants/links"

interface Props {
  onAgree: () => void
  gdprChecked: boolean
  termsAndConditionChecked: boolean
}

const PolicyConsent = ({ onAgree, gdprChecked, termsAndConditionChecked }: Props) => {
  const [termsChecked, setTermsChecked] = useState(gdprChecked)
  const [persDataProcChecked, setPersDataProcChecked] = useState(termsAndConditionChecked)
  // TODO const [marketingCommunicationChecked, setMarketingCommunicationChecked] = useState(false)

  const isMaxSmall = useIsMaxSm()
  const { t } = useTranslation(["auth", "common"])

  return (
    <Flex justify="center" align="center" direction={"column"} style={{ width: "100vw", height: "100vh" }}>
      <SCardWrapper isFullWidth={isMaxSmall}>
        <TermsAndConditions width={theme.sizes.s41.value} />
        <Spacer size={theme.space.s8} />
        <SRrappweLeft>
          <Text type="headerH2Neutral">{t("auth:policy_consent")}</Text>
          <Spacer size={theme.space.s6} />
          <ConsentRow isAgreed={termsChecked} onAgreedChanged={() => setTermsChecked((prevState) => !prevState)}>
            <Trans t={t} i18nKey="auth:policy_consent_agree_to_the_terms_and_conditions">
              <TextLink href={WEB_LINKS.termsAndConditions} textType={"menuItemActive"} />
            </Trans>
          </ConsentRow>
          <ConsentRow
            isAgreed={persDataProcChecked}
            onAgreedChanged={() => setPersDataProcChecked((prevState) => !prevState)}
          >
            <Trans t={t} i18nKey="auth:policy_consent_agree_to_the_presonal_data_processing_policy">
              <TextLink href={WEB_LINKS.termsAndConditions} textType={"menuItemActive"} />
            </Trans>
          </ConsentRow>
          {
            // TODO
            /* <ConsentRow isAgreed={marketingCommunicationChecked} onAgreedChanged={() => setMarketingCommunicationChecked((prevState) => !prevState)}>
            <Text type={"textsLarge"}>{t("policy_consent_agree_to_receive_marketing_communications")}</Text>
          </ConsentRow> */
          }
        </SRrappweLeft>
        <Spacer size={theme.space.s8} />

        <Button onClick={onAgree} disabled={!termsChecked || !persDataProcChecked}>
          {t("common:continue")}
        </Button>
      </SCardWrapper>
    </Flex>
  )
}

interface ConsentRowProps extends PropsWithChildren {
  isAgreed: boolean
  onAgreedChanged: () => void
}

export const ConsentRow = ({ isAgreed, onAgreedChanged, children }: ConsentRowProps) => (
  <>
    <Flex>
      <Checkbox checked={isAgreed} onChange={onAgreedChanged} />
      <Spacer size={theme.space.s2} />
      <SText>
        <Text type="textsLarge">{children}</Text>
      </SText>
    </Flex>
    <Spacer size={theme.space.s4} />
  </>
)

interface TextLinkProps extends PropsWithChildren {
  href: string
  textType: TextType
}

const TextLink = ({ children, href, textType }: TextLinkProps) => (
  <a href={href} target="_blank" rel="noreferrer">
    <Text type={textType}>{children}</Text>
  </a>
)

export default PolicyConsent
