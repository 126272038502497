import * as HoverCard from "@radix-ui/react-hover-card"
import Text from "../../../../components/Text"
import { SHoverContent, SIcon, SIconHelp, SMissingDocuments, SStrong } from "./MissingDocuments.styled"
import { useTouchTooltip } from "../../../../hooks/useTouchTooltip"
import { useIsMaxSm } from "../../../../hooks/mediaHooks"
import { Trans, useTranslation } from "react-i18next"

export function MissingDocuments() {
  const { open, handleToggle } = useTouchTooltip()
  const isMobile = useIsMaxSm()
  const { t } = useTranslation(["notifications", "delivery_point"])

  return (
    <HoverCard.Root openDelay={150} open={open} onOpenChange={handleToggle}>
      <HoverCard.Trigger asChild onTouchEnd={handleToggle}>
        <SMissingDocuments>
          <SIcon />
          <Text type="headerH3Neutral">{t("delivery_point:documents_missing_documents")}</Text>
          <SIconHelp />
        </SMissingDocuments>
      </HoverCard.Trigger>
      <SHoverContent side="top" align="start" alignOffset={isMobile ? 0 : 80} sideOffset={-40}>
        <Text type="textsSmall">
          <Trans t={t} i18nKey="notifications:working_on_historical_documents_import_notification_short">
            <SStrong />
          </Trans>{" "}
          {t("notifications:working_on_historical_documents_import_notification_description")}
        </Text>
      </SHoverContent>
    </HoverCard.Root>
  )
}
