import { Spacer } from "../../../../components/spacer/Spacer"
import { theme } from "../../../../styles/stitches.config"
import { Button } from "../../../../components/button/Button"
import PasswordInput from "../../../../components/passwordInput/PasswordInput"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { PasswordEditFormValues, usePasswordEditFormSchema } from "./PasswordEdit.utils"
import { trpc } from "../../../../api/trpc/trpc"
import { useTranslation } from "react-i18next"
import { TRPCClientError } from "@trpc/client"
import { SContainer } from "./PasswordEdit.styled"
import { SModalDescription, SModalTitle } from "../../../../components/modal/Modal.styled"

interface Props {
  onSuccess?: () => void
}

export default function PasswordEdit({ onSuccess }: Props) {
  const { t } = useTranslation("auth")

  const changePasswordMutation = trpc.useMutation(["users.changePassword"], { onError: undefined })

  const { schema } = usePasswordEditFormSchema()
  const form = useForm<PasswordEditFormValues>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  async function changePassword(currentPassword: string, newPassword: string) {
    await changePasswordMutation.mutateAsync({ currentPassword, newPassword })
    onSuccess?.()
  }

  function handleError(error: unknown) {
    if (!(error instanceof TRPCClientError)) {
      throw error
    }

    switch (error.data.code) {
      case "UNAUTHORIZED":
        form.setError("password", { message: t("profile_password_edit_incorrect_password") })
        break
      case "BAD_REQUEST":
        form.setError("newPassword", { message: t("profile_password_edit_must_not_match") })
        break
      default:
        throw error
    }
  }

  async function onSubmit({ newPassword, password }: PasswordEditFormValues) {
    try {
      await changePassword(password, newPassword)
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SContainer>
      <SModalTitle>{t("profile_password_edit_header")}</SModalTitle>

      <Spacer size={theme.space.s6} />

      <SModalDescription>{t("profile_password_edit_description")}</SModalDescription>

      <Spacer size={theme.space.s6} />

      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <PasswordInput
          label={t("profile_password_edit_current_password")}
          error={form.formState.errors.password?.message}
          formRegisterProps={form.register("password")}
          placeholder={t("profile_password_edit_placeholder")}
        />

        <Spacer size={theme.space.s12} />

        <PasswordInput
          label={t("profile_password_edit_new_password")}
          error={form.formState.errors.newPassword?.message}
          formRegisterProps={form.register("newPassword")}
          placeholder={t("profile_password_edit_placeholder")}
        />

        <Spacer size={theme.space.s12} />

        <PasswordInput
          label={t("profile_password_edit_new_password_again")}
          error={form.formState.errors.newPasswordCheck?.message}
          formRegisterProps={form.register("newPasswordCheck")}
          placeholder={t("profile_password_edit_placeholder")}
        />

        <Spacer size={theme.space.s12} />

        <Button variant="primary" type="submit" isFullwidth disabled={form.formState.isSubmitting}>
          {t("profile_password_edit_change_password")}
        </Button>
      </form>
    </SContainer>
  )
}
