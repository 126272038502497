import React from "react"
import { IconType, theme } from "../../styles/stitches.config"
import * as icons from "../../images/xd/icons"
import { SSquareButton } from "./SquareButton.styled"

interface Props {
  icon: IconType
  onClick?: () => void
  style?: React.ComponentProps<typeof SSquareButton>["style"]
  disabled?: boolean
}

const SquareButton = ({ icon, onClick, style, disabled }: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const Icon = icons[icon]

  return (
    <SSquareButton ref={ref} onClick={onClick} style={style} disabled={disabled}>
      <div>
        <Icon color={theme.colors.primary.value} />
      </div>
    </SSquareButton>
  )
}

export default React.forwardRef(SquareButton)
