import { useCallback } from "react"
import { trpc } from "../../../api/trpc/trpc"
import { captureException } from "@sentry/react"
import JSZip from "jszip"
import { downloadXlsxSheets, getSpotDataXlsxSettings, transformSpotDataToSheets } from "../../../utils/xlsx"
import axios from "axios"
import { toasts } from "../../../utils/toasts"
import { formatIsoDate } from "../../../utils/date"
import { useTranslation } from "react-i18next"

export function usePaymentAttachments() {
  const { t } = useTranslation(["payments"])
  const getInvoiceSpotDataMutation = trpc.useMutation("spotData.invoiceSpotData")

  const getInvoiceSpotData = useCallback(
    (paymentId: string, ean: string) => {
      try {
        return getInvoiceSpotDataMutation.mutateAsync({ paymentId, ean })
      } catch (error) {
        captureException(
          new Error(`Error while fetching invoice spot data (payment ID: ${paymentId})`, { cause: error })
        )
        return null
      }
    },
    [getInvoiceSpotDataMutation]
  )

  const downloadBillAttachments = useCallback(
    async (params: {
      id: string
      ean: string | undefined
      paymentCode: string | undefined
      attachmentUrl: string | undefined | null
      spotDataAvailable: boolean | undefined
    }) => {
      const { id, paymentCode, attachmentUrl, spotDataAvailable, ean } = params
      const invoiceName = paymentCode ?? "zuctovani"
      document.body.style.cursor = "progress"
      const zip = new JSZip()
      const fetchResults = await Promise.allSettled([
        (async () => {
          if (!spotDataAvailable || !ean) {
            return
          }

          const spotData = await getInvoiceSpotData(id, ean)
          if (!spotData) {
            throw new Error(t("payments:invoice_attachment_spot_data_download_error"))
          }

          const sheets = await transformSpotDataToSheets(spotData)
          if (!sheets) {
            toasts.error("Nepodařilo se načíst šablonu pro výpočet vyúčtování")
            return
          }

          const xlsxSettings = getSpotDataXlsxSettings(spotData)
          const buffer = await downloadXlsxSheets(sheets, "buffer", xlsxSettings)

          if (!buffer) {
            throw new Error(t("payments:invoice_attachment_spot_data_transform_error"))
          }

          zip.file(`${xlsxSettings.fileName}.xlsx`, buffer)
        })(),
        (async () => {
          if (!attachmentUrl) {
            return
          }

          try {
            const pdfBlob = await axios.get(attachmentUrl, { responseType: "blob" })
            zip.file(`${invoiceName}.pdf`, pdfBlob.data)
          } catch (error) {
            captureException(new Error(`Error while fetching bill attachment (payment ID: ${id})`, { cause: error }))
            throw new Error(t("payments:invoice_attachment_document_download_error"))
          }
        })(),
      ])

      const rejected = fetchResults.filter((result): result is PromiseRejectedResult => result.status === "rejected")
      for (const rejectedPromise of rejected) {
        toasts.error(rejectedPromise.reason.message)
      }

      const fulfilled = fetchResults.filter(
        (result): result is PromiseFulfilledResult<void> => result.status === "fulfilled"
      )
      if (fulfilled.length === 0) {
        toasts.error(t("payments:invoice_attachment_download_error"))
        return
      }

      try {
        const zipBlob = await zip.generateAsync({ type: "blob" })
        const href = URL.createObjectURL(zipBlob)
        const link = document.createElement("a")
        link.href = href
        link.download = `${invoiceName}-${formatIsoDate(new Date())}`
        link.click()

        URL.revokeObjectURL(href)
      } catch (error) {
        toasts.error(t("payments:invoice_attachment_download_error"))
        captureException(new Error(`Error while zip archive (payment ID: ${id})`, { cause: error }))
      } finally {
        document.body.style.cursor = ""
      }
    },
    [getInvoiceSpotData, t]
  )

  return {
    downloadBillAttachments,
  }
}
