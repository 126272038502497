import { styled, theme } from "../../../../styles/stitches.config"
import * as Dropdown from "@radix-ui/react-dropdown-menu"
import { Hamburger } from "../../../../images/xd/icons"
import { defaultRadixContentFadeInOutAnimation } from "../../../../styles/utils"

export const STrigger = styled(Dropdown.Trigger, {
  outline: 0,
  border: 0,
  backgroundColor: "transparent",
  hover: {
    cursor: "pointer",
  },
})

export const SContent = styled(Dropdown.Content, {
  position: "absolute",
  top: `-${theme.space.s14.value}`,
  backgroundColor: theme.colors.white,
  height: "100dvh",
  boxShadow: theme.shadows.default,

  display: "flex",
  minWidth: theme.space.s72,
  flexDirection: "column",
  overflow: "hidden",

  ...defaultRadixContentFadeInOutAnimation,
})

export const SHamburger = styled(Hamburger, {
  width: theme.sizes.s6,
  height: theme.sizes.s6,

  color: theme.colors.primary,
  margin: theme.space.s3_5,
})

export const SBackgroundShade = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: theme.colors.texts,
  height: "100vh",
  width: "100vw",
  zIndex: 10,

  variants: {
    hidden: {
      true: {
        visibility: "hidden",
        opacity: 0,
        noReducedMotion: {
          transition: "300ms",
        },
      },
      false: {
        visibility: "visible",
        opacity: 0.4,
        noReducedMotion: {
          transition: "300ms",
        },
      },
    },
  },

  defaultVariants: {
    hidden: "true",
  },
})
