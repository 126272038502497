import { ComponentProps } from "react"
import Text from "../../../../components/Text"
import { DocumentCard, SIcon } from "./Documents.styled"

interface DocumentProps {
  id: string
  name: string
  downloadLink?: string
  variant?: ComponentProps<typeof DocumentCard>["variant"]
}

const Document = ({ name, downloadLink, variant }: DocumentProps) => {
  return (
    <a href={downloadLink} target="_blank" rel="noreferrer">
      <DocumentCard variant={variant}>
        <Text type="textsLarge" css={{ ellipsis: {} }}>
          {name}
        </Text>
        <SIcon />
      </DocumentCard>
    </a>
  )
}

export default Document
