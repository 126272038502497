import UserMenuItem from "./UserMenuItem"
import UserMenuItemContainer from "./UserMenuItemContainer"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useCallback } from "react"
import { LINKS } from "../../../../../constants/links"
import { CSSProp } from "../../../../../styles/stitches.config"
import { setUser } from "@sentry/react"
import { trpc } from "../../../../../api/trpc/trpc"
import { clearUserInfoFromDaktela } from "../../../../../utils/daktela"

interface Props {
  css?: CSSProp
}

export default function LogoutButton({ css }: Props) {
  const { t } = useTranslation("menu")
  const logoutMutation = trpc.useMutation(["users.logout"])
  const navigate = useNavigate()

  const handleLogout = useCallback(
    async function () {
      await logoutMutation.mutateAsync()

      setUser(null)
      clearUserInfoFromDaktela()
      navigate(LINKS.authLogin)
    },
    [navigate, logoutMutation]
  )

  return (
    <UserMenuItemContainer onClick={handleLogout} css={{ ...css }}>
      <UserMenuItem text={t("user_menu_logout")} textType="headerH3Negative" icon="TurnOff" />
    </UserMenuItemContainer>
  )
}
