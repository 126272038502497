import { assertUnreachable, ObjectValues } from "../../utils/types"
import { createRotationAnimation, defaultAnimationDuration } from "../../styles/utils"
import { ReactElement, SVGProps, useEffect, useMemo, useRef, useState } from "react"
import { createAnimatedIcon } from "./AlternateRotatingIcon.styled"
import { CSSProp, theme } from "../../styles/stitches.config"

type RotationDirection = "left" | "right"

type AnimationFactoryReturnType = ReturnType<typeof createRotationAnimation>
const createRotationsTuple = (from: number, to: number): [AnimationFactoryReturnType, AnimationFactoryReturnType] => {
  return [createRotationAnimation(from, to), createRotationAnimation(to, from)]
}

const createAnimations = (direction: RotationDirection): ReturnType<typeof createRotationsTuple> => {
  switch (direction) {
    case "left":
      return createRotationsTuple(180, 0)
    case "right":
      return createRotationsTuple(-180, 0)
    default:
      return assertUnreachable(direction)
  }
}

interface Props {
  icon(props: SVGProps<SVGSVGElement>): ReactElement
  size?: ObjectValues<typeof theme.sizes>
  color?: ObjectValues<typeof theme.colors>
  direction?: RotationDirection
  duration?: number
  toggled: boolean
}

export default function AlternateRotatingIcon({
  icon,
  size = theme.sizes.s6,
  color = theme.colors.secondary,
  toggled,
  direction = "left",
  duration = defaultAnimationDuration,
}: Props) {
  const [forward, backward] = useMemo(() => createAnimations(direction), [direction])
  const style: CSSProp = useMemo(
    () => ({
      width: size,
      color,
    }),
    [size, color]
  )

  const toggledPrev = useRef(false)

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (toggled !== toggledPrev.current) {
      toggledPrev.current = toggled
      setAnimate(true)
      setTimeout(() => setAnimate(false), duration)
    }
  }, [duration, toggled])

  const css: CSSProp = useMemo(
    () => ({
      ...style,
      noReducedMotion: animate
        ? { animation: `${toggled ? backward : forward} ${duration}ms ease-out forwards` }
        : undefined,

      transform: toggled ? "rotate(180deg)" : undefined,
    }),
    [animate, backward, duration, forward, style, toggled]
  )

  const AnimatedIcon = createAnimatedIcon(icon, css)
  return <AnimatedIcon />
}
