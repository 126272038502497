import { styled, theme } from "../../styles/stitches.config"

export const SOverviewHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.space.s9,
  marginRight: theme.space.s9,
})

export const SDisclaimerWrapper = styled("div", {
  display: "flex",
  paddingTop: "16px",
  paddingBottom: "16px",
  paddingLeft: "24px",
  paddingRight: "24px",
  borderStyle: "solid",
  borderColor: "$textsAlt",
  borderWidth: "thin",
  marginLeft: theme.space.s1,
  marginRight: theme.space.s1,

  "@lg": {
    marginLeft: theme.space.s0,
    marginRight: theme.space.s0,
  },
})
