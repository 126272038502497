import * as React from "react"

function SvgFaceQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_Face_question_svg__a">
          <path
            data-name="Rectangle 17087"
            transform="translate(-2907 1110)"
            fill="#ec5d5c"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
        <clipPath id="_Face_question_svg__b">
          <path data-name="Rectangle 17086" fill="none" d="M0 0h46v46H0z" />
        </clipPath>
      </defs>
      <g data-name="Mask Group 27" transform="translate(2907 -1110)" clipPath="url(#_Face_question_svg__a)">
        <g data-name="Group 16135">
          <g
            data-name="Group 16134"
            clipPath="url(#_Face_question_svg__b)"
            fill="#7ca7ad"
            transform="translate(-2906 1111)"
          >
            <path
              data-name="Path 48444"
              d="M23 46a23 23 0 1123-23 23.026 23.026 0 01-23 23m0-43.824A20.824 20.824 0 1043.824 23 20.847 20.847 0 0023 2.176"
            />
            <path
              data-name="Path 48445"
              d="M18.295 17.612a1.53 1.53 0 01-.719-2.228 6.046 6.046 0 015.139-2.705 5.834 5.834 0 015.193 2.618 5.222 5.222 0 01.033 5.324c-1.3 1.923-2.553 2.51-3.227 3.748a2.51 2.51 0 00-.326 1.021 1.583 1.583 0 01-1.554 1.412 1.6 1.6 0 01-1.608-1.76 4.424 4.424 0 01.5-1.673c.837-1.51 2.445-2.4 3.379-3.737a2.56 2.56 0 00-2.368-4.02 2.984 2.984 0 00-2.608 1.456 1.483 1.483 0 01-1.836.543m6.606 13.537a2.173 2.173 0 11-2.173-2.173 2.179 2.179 0 012.173 2.173"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgFaceQuestion
