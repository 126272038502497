import * as React from "react"

function SvgFaceBad(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <clipPath id="_FaceBad_svg__a">
          <path
            data-name="Rectangle 17076"
            transform="translate(-2907 1052)"
            fill="#ff984a"
            stroke="#707070"
            d="M0 0h48v48H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 23" transform="translate(2907 -1052)" clipPath="url(#_FaceBad_svg__a)">
        <path
          data-name="Union 31"
          d="M-2906 1076a23 23 0 1123 23 23.026 23.026 0 01-23-23zm2.176 0a20.824 20.824 0 1020.824-20.824 20.847 20.847 0 00-20.824 20.824zm15.855 6.4a1.088 1.088 0 010-1.539 7.035 7.035 0 019.938 0 1.088 1.088 0 01-1.539 1.539 4.856 4.856 0 00-6.86 0 1.088 1.088 0 01-1.539 0zm17.8-2.953a2.184 2.184 0 112.183 2.184 2.184 2.184 0 01-2.179-2.186zm-30.037 0a2.184 2.184 0 112.184 2.184 2.184 2.184 0 01-2.178-2.186z"
          fill="#ff984a"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default SvgFaceBad
