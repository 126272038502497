import * as React from "react"

function SvgLoxoneAlphaessSolaxLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 24" {...props}>
      <defs>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__a">
          <path
            data-name="Rectangle 17113"
            transform="translate(239 634)"
            fill="#fff"
            stroke="#707070"
            d="M0 0h220v24H0z"
          />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__b">
          <path data-name="Rectangle 17103" fill="#c5d3d6" d="M0 0h218.998v21.587H0z" />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__c">
          <path
            data-name="Path 51214"
            d="M228.988 7.151l1.486 2.049a1.684 1.684 0 00.659.356 1.984 1.984 0 001.764-.2 5.082 5.082 0 001.229-1.477c.187-.236 4.466-6.4 4.466-6.4z"
            transform="translate(-228.988 -1.482)"
            fill="#c5d3d6"
          />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__d">
          <path
            data-name="Path 51216"
            d="M221.926 16.628a5.1 5.1 0 00-1.229 1.478c-.187.236-4.466 6.4-4.466 6.4l9.6-5.669-1.486-2.054a1.7 1.7 0 00-.66-.355 2.914 2.914 0 00-.755-.12 1.722 1.722 0 00-1.008.318"
            transform="translate(-216.231 -16.31)"
            fill="#c5d3d6"
          />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__e">
          <path
            data-name="Path 51217"
            d="M216.155 1.482l13.137 18.743a1.44 1.44 0 00.978.531h5.146l-19.24-19.274z"
            transform="translate(-216.155 -1.482)"
            fill="#c5d3d6"
          />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__f">
          <path
            data-name="Path 51220"
            d="M129.189 10.428a.706.706 0 00-.194.121l-2.583 1.974-8.08 6.167-6.432 4.91-.724.554 8.4-2.9 12.572-4.335a.764.764 0 00.525-.946.794.794 0 00-.069-.166h-.014l-2.467-5a.713.713 0 00-.934-.382"
            transform="translate(-111.173 -10.372)"
            fill="#c5d3d6"
          />
        </clipPath>
        <clipPath id="_Loxone_Alphaess_Solax_logo_svg__g">
          <path
            data-name="Path 51221"
            d="M117.631.169a.882.882 0 00-.175.168 2 2 0 00-.166.272l-5.8 11.794a3.261 3.261 0 00-.319 1.406v7.779l6.116-12.274c.038-.071.071-.132.105-.19a.888.888 0 011.5 0 1.881 1.881 0 01.115.19l5.27 10.551a3.289 3.289 0 002.789 1.723h1.458c.962 0 .534-.863.534-.863L119 .6a2.572 2.572 0 00-.132-.235.887.887 0 00-.718-.365.878.878 0 00-.519.169"
            transform="translate(-111.173)"
            fill="#c5d3d6"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 29" transform="translate(-239 -634)" clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__a)">
        <g data-name="Group 16200">
          <g data-name="Group 16180">
            <g
              data-name="Group 16179"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__b)"
              fill="#c5d3d6"
              transform="translate(239 635)"
            >
              <path
                data-name="Path 51203"
                d="M203.19 15.942v-.545h.972a.405.405 0 100-.808h-1.3v2.06h-.556v-2.624h1.852a.891.891 0 01.979.95.908.908 0 01-.979.967z"
              />
              <path
                data-name="Path 51204"
                d="M206.641 16.649a1.281 1.281 0 01-1.288-1.339 1.23 1.23 0 011.288-1.285h.646a1.231 1.231 0 011.3 1.285 1.284 1.284 0 01-1.3 1.339zm.646-.563a.75.75 0 000-1.5h-.646a.75.75 0 000 1.5z"
              />
              <path
                data-name="Path 51205"
                d="M210.651 15.819l-.362.689a.31.31 0 01-.284.177.318.318 0 01-.29-.177l-1.327-2.483h.667l.968 1.87.344-.621-.642-1.249h.671l.91 1.87.972-1.87h.628l-1.33 2.483a.316.316 0 01-.287.177.307.307 0 01-.287-.177z"
              />
              <path
                data-name="Path 51206"
                d="M213.151 16.649v-2.624h2.367v.561h-1.811v1.5h1.815v.563zm.882-1.555h1.385v.444h-1.385z"
              />
              <path
                data-name="Path 51207"
                d="M218.205 16.649l-.635-.715h-.81v-.479h1.011a.393.393 0 00.424-.441.385.385 0 00-.424-.425h-1.338v2.06h-.556v-2.624h1.894a.9.9 0 01.972.974.858.858 0 01-.527.859l.782.79z"
              />
              <path
                data-name="Path 51208"
                d="M182.907 12.919v-1.46h4.251c.331 0 .531-.157.531-.442s-.2-.465-.531-.465h-2.488a1.693 1.693 0 11.016-3.369h4.066v1.454h-4.066a.411.411 0 000 .82h2.475c1.223 0 1.876.529 1.876 1.7a1.68 1.68 0 01-1.876 1.768z"
              />
              <path
                data-name="Path 51209"
                d="M192.37 12.918a2.8 2.8 0 01-2.814-2.927 2.689 2.689 0 012.814-2.809h1.406a2.684 2.684 0 012.821 2.809 2.8 2.8 0 01-2.821 2.927zm1.406-1.46a1.345 1.345 0 001.406-1.4 1.37 1.37 0 00-1.406-1.421h-1.406a1.371 1.371 0 00-1.391 1.427 1.337 1.337 0 001.391 1.4z"
              />
              <path data-name="Path 51210" d="M197.244 12.918V7.183h1.422v4.276h3.474v1.46z" />
              <path
                data-name="Path 51211"
                d="M208.236 12.919l-.73-1.27h-2.076l.631-1.1h.815l-.8-1.389-2.152 3.756h-1.614l3.136-5.349a.73.73 0 011.361 0l3.136 5.349z"
              />
              <path
                data-name="Path 51212"
                d="M216.634 12.918l-2.144-2.142-2.151 2.142h-1.837l2.9-3.03-2.64-2.784h1.953l1.872 1.9 1.97-1.9h1.829l-2.779 2.741 3.01 3.073z"
              />
              <path
                data-name="Path 51213"
                d="M176.768 1.115a1.426 1.426 0 00-.944.628c-.385.558-3.538 5-3.538 5l.026.036 9.6-5.669z"
              />
            </g>
          </g>
          <g data-name="Group 16182">
            <g
              data-name="Group 16181"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__c)"
              transform="translate(411.312 636.115)"
              opacity={0.8}
            >
              <path data-name="Rectangle 17104" fill="#c5d3d6" d="M0 0h9.604v8.364H0z" />
            </g>
          </g>
          <g data-name="Group 16184">
            <g
              data-name="Group 16183"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__b)"
              transform="translate(239 635)"
            >
              <path
                data-name="Path 51215"
                d="M167.861 20.472a1.426 1.426 0 00.944-.628c.385-.558 3.538-5 3.538-5l-.026-.036-9.6 5.669z"
                fill="#c5d3d6"
              />
            </g>
          </g>
          <g data-name="Group 16186">
            <g
              data-name="Group 16185"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__d)"
              transform="translate(401.712 647.273)"
              opacity={0.8}
            >
              <path data-name="Rectangle 17106" fill="#c5d3d6" d="M0 0h9.604v8.198H0z" />
            </g>
          </g>
          <g data-name="Group 16188">
            <g
              data-name="Group 16187"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__e)"
              transform="translate(401.655 636.115)"
              opacity={0.8}
            >
              <path
                data-name="Rectangle 17107"
                fill="#c5d3d6"
                d="M-9.584 8.625L10.642-9.576 28.844 10.65 8.618 28.852z"
              />
            </g>
          </g>
          <g data-name="Group 16190">
            <g
              data-name="Group 16189"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__b)"
              fill="#c5d3d6"
              transform="translate(239 635)"
            >
              <path data-name="Path 51218" d="M168.804 1.733a1.457 1.457 0 00-1.241-.617h-4.887l19.24 19.274z" />
              <path data-name="Path 51219" d="M83.657 21.586l.724-.551 6.435-4.913 2.946-2.249h-6.265l-3.836 7.713z" />
            </g>
          </g>
          <g data-name="Group 16192">
            <g
              data-name="Group 16191"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__f)"
              transform="translate(322.657 642.805)"
              opacity={0.6}
            >
              <path data-name="Rectangle 17109" fill="#c5d3d6" d="M-4.43 1.613l23.517-8.56 6.96 19.117-23.518 8.56z" />
            </g>
          </g>
          <g data-name="Group 16194">
            <g
              data-name="Group 16193"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__g)"
              transform="translate(322.657 635)"
              opacity={0.8}
            >
              <path data-name="Rectangle 17110" fill="#c5d3d6" d="M0 0h18.319v21.587H0z" />
            </g>
          </g>
          <g data-name="Group 16196">
            <g
              data-name="Group 16195"
              clipPath="url(#_Loxone_Alphaess_Solax_logo_svg__b)"
              fill="#c5d3d6"
              transform="translate(239 635)"
            >
              <path
                data-name="Path 51222"
                d="M109.589 14.814l.71-2.1h3.035l.713 2.1h1.128l-2.437-6.935a.9.9 0 00-1.767 0l-2.473 6.935zm.986-2.922l1.233-3.656 1.24 3.656zm5.185 1.42a2.455 2.455 0 00.584 1.761l.856-.421a2.108 2.108 0 01-.445-1.39V6.781h-1zm3.119 1.39a6.028 6.028 0 001.448.19c1.747 0 2.477-.751 2.477-2.973s-.785-2.963-2.654-2.963a4.842 4.842 0 00-2.226.47q-.041.509-.041 1.019v6.5h.993v-2.243zm0-.8v-3.091a7.757 7.757 0 01.031-.85 3.165 3.165 0 011.24-.231c1.413 0 1.652.856 1.652 2.188 0 1.41-.167 2.178-1.744 2.178a4.158 4.158 0 01-1.179-.2m9.515.914v-3.757c0-1.217-.238-2.118-2.25-2.111a5.332 5.332 0 00-1.566.231V6.795h-1v8.019h1v-4.812a3.622 3.622 0 011.285-.245c1.359 0 1.536.432 1.536 1.448v3.608zm1.09-4.913a14.954 14.954 0 011.975-.157c1.081 0 1.417.4 1.417 1.274v.143a21.781 21.781 0 00-2.28.157 1.61 1.61 0 00-1.506 1.807c0 1.227.68 1.767 2.226 1.767a5.371 5.371 0 002.511-.5c.024-.339.038-.68.038-1.019v-2.65c0-1.458-1.02-1.788-2.264-1.788a13.217 13.217 0 00-2.191.2l.085.755zm.6 3.2c0-.74.251-.968.883-1.108a12.021 12.021 0 011.91-.15v1.186c0 .265 0 .591-.027.85a3.78 3.78 0 01-1.4.231c-.931 0-1.359-.2-1.359-1.02z"
              />
              <path
                data-name="Path 51223"
                d="M138.517 8.858a43.223 43.223 0 00-1.539-.041c-1.05 0-2.039.058-2.039 1.387v3.327c0 1.325.979 1.379 2.039 1.379.445 0 1.077 0 1.539-.034v-.656h-1.563c-.989 0-1.155-.184-1.155-1.053v-1.02h2.6v-.68h-2.6v-.9c0-.87.166-1.057 1.155-1.057h1.563v-.652zm2.99.622a8.872 8.872 0 011.247.109l.078-.663a8.717 8.717 0 00-1.6-.153c-1.359 0-2.1.4-2.1 1.651 0 1.108.631 1.4 1.716 1.726.7.214 1.233.381 1.233 1.1 0 .819-.547.992-1.332.992a11.978 11.978 0 01-1.5-.119l-.082.68a7.6 7.6 0 001.614.157c1.4 0 2.181-.466 2.181-1.74 0-1.087-.632-1.5-1.668-1.784-.9-.251-1.309-.363-1.309-1.124s.816-.829 1.526-.829m4.159 0a8.83 8.83 0 011.244.109l.078-.663a8.691 8.691 0 00-1.6-.153c-1.359 0-2.1.4-2.1 1.651 0 1.108.632 1.4 1.7 1.726.707.214 1.237.381 1.237 1.1 0 .819-.55.992-1.332.992a11.821 11.821 0 01-1.485-.119l-.085.68a7.627 7.627 0 001.617.157c1.4 0 2.181-.466 2.181-1.74 0-1.087-.632-1.5-1.671-1.784-.9-.251-1.309-.363-1.309-1.124s.819-.829 1.529-.829z"
              />
              <path
                data-name="Path 51224"
                d="M.774 17.289H7.56v-2.177H2.309V4.317H0v11.792zm15.25-4.161a2.134 2.134 0 01-2.34 2.33h-.713a2.123 2.123 0 01-2.33-2.33v-4.67a2.123 2.123 0 012.33-2.33h.722a2.123 2.123 0 012.33 2.33zm1.476 2.411a5.053 5.053 0 00.62-2.411v-4.68a4.123 4.123 0 00-2.02-3.794 5.045 5.045 0 00-2.411-.621h-.732a4.125 4.125 0 00-3.79 2.014 5.021 5.021 0 00-.621 2.411v4.68a4.125 4.125 0 002.014 3.795 5.047 5.047 0 002.411.621h.729a4.125 4.125 0 003.8-2.015m7.772-4.72L21.06 4.328h-2.584l4.212 6.491-4.212 6.49h2.584zm.58-.9l3.632-5.6H26.9L24.56 7.93zm1.048 7.39h2.584l-3.632-5.6-1.292 1.994zm10.407-4.181a2.134 2.134 0 01-2.34 2.33h-.713a2.123 2.123 0 01-2.33-2.33v-4.67a2.123 2.123 0 012.33-2.33h.723a2.123 2.123 0 012.329 2.33zm1.476 2.411a5.044 5.044 0 00.62-2.411v-4.68a4.122 4.122 0 00-2.014-3.794 5.049 5.049 0 00-2.411-.621h-.734a4.125 4.125 0 00-3.794 2.014 5.027 5.027 0 00-.62 2.411v4.68a4.123 4.123 0 002.014 3.795 5.045 5.045 0 002.41.621h.732a4.126 4.126 0 003.8-2.014m12.157 1.75h.257V4.317h-2.113v9.34l-4.76-9.34h-2.8v12.962h2.116V7.492l4.985 9.787h2.309zm4.857-2.178v-3.581h3.37V9.354H55.8V7.665l-.764-1.169h6V4.317h-7.543v11.781l.772 1.2h6.776v-2.176H55.8z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgLoxoneAlphaessSolaxLogo
