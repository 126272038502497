import { PropsWithChildren } from "react"
import { theme } from "../../styles/stitches.config"
import Text, { TextType } from "../Text"

interface Props {
  type: TextType
}

export const InfoText = ({ children, type }: PropsWithChildren<Props>) => {
  return (
    <Text
      type={type}
      css={{ display: "block", maxWidth: theme.sizes.s100, textAlign: "start", whiteSpace: "break-spaces" }}
    >
      {children}
    </Text>
  )
}
