import React, { ComponentProps, Fragment, ReactNode } from "react"
import { Row } from "react-table"
import { useMedia } from "react-use"
import { useIsMaxMd } from "../../hooks/mediaHooks"
import * as icons from "../../images/xd/icons"
import { ArrowForward } from "../../images/xd/icons"
import {
  ColorType,
  fontVariants,
  getStyledIcon,
  IconType,
  media,
  MediaSizeType,
  styled,
  theme,
} from "../../styles/stitches.config"
import { formatDate, formatMonthSlashYear } from "../../utils/date"
import { formatCurrency } from "../../utils/format"
import { Flex } from "../flex/Flex"
import { FormattedUnit, TextStyle } from "../FormattedUnit"
import { TextType } from "../Text"
import { TableRow } from "./Table"
import {
  ActionCellButton,
  AmountCellContainer,
  CellSubText,
  IconContainer,
  SHoverCardArrow,
  SHoverCardContent,
  SPopoverActionTrigger,
  SPopoverArrow,
  SPopoverContent,
} from "./Table.styled"
import * as Popover from "@radix-ui/react-popover"
import * as HoverCard from "@radix-ui/react-hover-card"
import { Spacer } from "../spacer/Spacer"
import Separator from "../Separator"
import { Span } from "../span/Span"

interface CellProps<T> {
  value?: T | null
  row: Row<TableRow<any>>
}

export interface RValueCellProps<T> extends CellProps<T> {
  value: T
}

export interface IconCellValue {
  text: string
  subText?: string
  icon: IconType
  callback?: () => void
  iconSize?: ComponentProps<typeof IconContainer>["size"]
  hoverConfig?: {
    content: ReactNode
    underline?: boolean
  }
}

export interface IconCellContentProps extends IconCellValue {
  isSuccess?: boolean
  iconSize?: ComponentProps<typeof IconContainer>["size"]
}

export interface DateCellValue {
  date: Date
  subText?: string
  justify?: ComponentProps<typeof Flex>["justify"]
}

export interface DateRangeCellValue {
  from: Date
  to: Date
}

export interface DateIconCellValue extends DateCellValue {
  icon?: IconType
  maxMediaIconVisibility?: MediaSizeType
  subText?: string
}

export interface AmountCellValue {
  amount: number
  subText?: string
  maxMediaSubTextVisibility?: MediaSizeType
}

export interface TextCellValue {
  text: string
  subText?: string
}

export interface CustomUnitCellValue {
  amount: number
}

export interface ActionCellValue {
  callback: () => void
  icon?: IconType
  iconColor?: ColorType
  expandToRow?: boolean // action applies for whole row if true
  disabled?: boolean
}

export interface MultiActionCellValue {
  justify?: ComponentProps<typeof Flex>["justify"]
  actions: {
    icon: IconType
    iconColor?: ColorType
    callback?: () => void
    hoverIconColor?: ColorType
  }[]
}

export type PopoverActionCellValue = ActionCellValue & {
  popoverMenuItems: {
    icon: IconType
    iconColor?: ColorType
    label: ReactNode
    callback: () => void
  }[]
}

function useIsComponentMediaVisible(maxMediaVisibility?: MediaSizeType) {
  const mediaQuery = maxMediaVisibility ? `(max-width: ${media[maxMediaVisibility]}px)` : "(min-width: 0px)"

  const isComponentMediaVisible = useMedia(mediaQuery)

  return isComponentMediaVisible
}

export function withNullableValue<T, P extends CellProps<T>>(
  WrappedComponent: React.ComponentType<RValueCellProps<T>>,
  emptyValue = "-",
  textType?: TextType
) {
  const NullableValueComponent = ({ value, ...other }: P) => {
    if (!value) {
      return (
        <Flex direction="row" justify="center" css={textType && { ...fontVariants[textType] }}>
          {emptyValue}
        </Flex>
      )
    }

    return <WrappedComponent value={value} {...(other as Omit<P, "value">)} />
  }

  return NullableValueComponent
}

const SStateWrapper = styled(Span, {
  ...fontVariants.headerH3Positive,

  variants: {
    underlined: {
      true: {
        borderBottomColor: theme.colors.successes,
        borderBottomStyle: "dashed",
        borderBottomWidth: theme.sizes.spx,
      },
    },
  },
})

export const IconCell = withNullableValue(({ value, row }: RValueCellProps<IconCellValue>) => {
  const Icon = icons[value.icon]
  const isSuccess = row.original.rowType === "success"

  const cellContent = (
    <Flex direction="row" align="center" onClick={value.callback}>
      {value.icon && (
        <IconContainer side="left" size={value.iconSize}>
          <Icon color={isSuccess ? theme.colors.successes.value : undefined} />
        </IconContainer>
      )}
      <Flex direction="column">
        {isSuccess ? <SStateWrapper underlined={value.hoverConfig?.underline}>{value.text}</SStateWrapper> : value.text}
        {value.subText && <CellSubText>{value.subText}</CellSubText>}
      </Flex>
    </Flex>
  )

  if (!value.hoverConfig) {
    return cellContent
  }

  return (
    <HoverCard.Root openDelay={100} closeDelay={100}>
      <HoverCard.Trigger asChild>{cellContent}</HoverCard.Trigger>
      <SHoverCardContent side="top" align="start" alignOffset={-50}>
        <SHoverCardArrow offset={100} />
        {value.hoverConfig.content}
      </SHoverCardContent>
    </HoverCard.Root>
  )
})

export const DateCell = withNullableValue(({ value }: RValueCellProps<DateCellValue>) => {
  return (
    <Flex direction="row" justify={value.justify ? value.justify : "end"} css={{ textAlign: "center" }}>
      <Flex direction="column">
        {formatDate(value.date)}
        {value.subText && <CellSubText>{value.subText}</CellSubText>}
      </Flex>
    </Flex>
  )
})

export const DateMonthCell = withNullableValue(({ value }: RValueCellProps<DateCellValue>) => {
  return (
    <Flex direction="row" justify={value.justify ? value.justify : "end"}>
      {formatMonthSlashYear(value.date)}
    </Flex>
  )
})

export const DateIconCell = withNullableValue(({ value, ...other }: RValueCellProps<DateIconCellValue>) => {
  const iconMediaVisibile = useIsComponentMediaVisible(value.maxMediaIconVisibility)

  return value.icon && iconMediaVisibile ? (
    <IconCell value={{ icon: value.icon, text: formatDate(value.date), subText: value.subText }} {...other} />
  ) : (
    <DateCell value={value} {...other} />
  )
})

export const DateRangeCell = withNullableValue(({ value }: RValueCellProps<DateRangeCellValue>) => {
  return (
    <Flex direction="row" justify="center">
      {formatDate(value.from)} - {formatDate(value.to)}
    </Flex>
  )
})

export const AmountCell = withNullableValue(
  ({ value: { amount, subText, maxMediaSubTextVisibility } }: RValueCellProps<AmountCellValue>) => {
    const subTextMediaVisibile = useIsComponentMediaVisible(maxMediaSubTextVisibility)

    return (
      <AmountCellContainer>
        {formatCurrency(amount, 2, 2)}
        {subText && subTextMediaVisibile && (
          <>
            <br />
            {<CellSubText>{subText}</CellSubText>}
          </>
        )}
      </AmountCellContainer>
    )
  }
)

export const TextCell = withNullableValue(({ value }: RValueCellProps<TextCellValue>) => {
  const { text, subText } = value

  return (
    <Flex direction="column">
      {text}
      {subText && (
        <>
          <br />
          {<CellSubText>{subText}</CellSubText>}
        </>
      )}
    </Flex>
  )
})

const SActionIconWrapper = styled("div", {
  hover: {
    cursor: "pointer",
  },
})

export const MultiActionCell = withNullableValue(({ value }: RValueCellProps<MultiActionCellValue>) => {
  const { actions, justify } = value

  if (actions.length === 0) {
    return null
  }

  return (
    <Flex
      justify={justify}
      css={{
        minWidth: theme.space.s12,
        gap: theme.space.s3,

        "@md": {
          gap: theme.space.s3_5,
        },
      }}
    >
      {actions.map((action) => {
        const Icon = getStyledIcon(action.icon, {
          width: theme.sizes.s5,
          height: theme.sizes.s5,
          color: action.iconColor ? theme.colors[action.iconColor].value : undefined,
          hover: action.hoverIconColor
            ? {
                color: theme.colors[action.hoverIconColor].value,
              }
            : undefined,
        })

        return (
          <SActionIconWrapper
            key={`${action.icon}-${action.iconColor}`}
            onClick={(e) => {
              e.stopPropagation()
              action.callback?.()
            }}
          >
            <Icon />
          </SActionIconWrapper>
        )
      })}
    </Flex>
  )
})

export const PopoverActionCell = withNullableValue(({ value }: RValueCellProps<PopoverActionCellValue>) => {
  const { icon, iconColor, expandToRow, disabled, popoverMenuItems } = value
  const Icon = icon ? icons[icon] : ArrowForward

  if (popoverMenuItems.length === 0 && !expandToRow) {
    return null
  }

  const iconNode = (
    <IconContainer side="right">
      {!disabled && <Icon color={iconColor ? theme.colors[iconColor].value : undefined} />}
    </IconContainer>
  )

  if (expandToRow) {
    return iconNode
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild disabled={disabled}>
        <SPopoverActionTrigger disabled={disabled}>{iconNode}</SPopoverActionTrigger>
      </Popover.Trigger>
      <SPopoverContent side="top" align="end" alignOffset={-24} sideOffset={12}>
        {popoverMenuItems.map(({ icon, label, callback }, index) => {
          const ItemIcon = icons[icon]
          return (
            <Fragment key={icon}>
              <Flex onClick={callback} align="center" css={{ cursor: "pointer" }}>
                <ItemIcon
                  color={theme.colors.textsAlt.value}
                  height={theme.sizes.s6.value}
                  width={theme.sizes.s6.value}
                />
                <Spacer size={theme.space.s4} />
                {label}
              </Flex>
              {index != popoverMenuItems.length - 1 && (
                <Separator orientation="horizontal" css={{ marginTop: theme.space.s4, marginBottom: theme.space.s4 }} />
              )}
            </Fragment>
          )
        })}
        <SPopoverArrow offset={24} />
      </SPopoverContent>
    </Popover.Root>
  )
})

export const ActionCell = withNullableValue(({ value }: RValueCellProps<ActionCellValue>) => {
  const { icon, callback, iconColor, expandToRow: rowAction, disabled } = value
  const Icon = icon ? icons[icon] : ArrowForward

  return (
    <ActionCellButton
      onClick={() => {
        if (rowAction || disabled) {
          return
        }

        callback()
      }}
      css={disabled ? { cursor: "default" } : {}}
    >
      <IconContainer side="right">
        {!disabled && <Icon color={iconColor ? theme.colors[iconColor].value : undefined} />}
      </IconContainer>
    </ActionCellButton>
  )
}, "")

export interface CustomUnitCellProps {
  unit: string
  valueStyle?: TextStyle
  unitStyle?: TextStyle
}

export const getCustomUnitCell = ({ unit, unitStyle, valueStyle }: CustomUnitCellProps) => {
  const customUnitCell = withNullableValue(
    ({ value }: RValueCellProps<CustomUnitCellValue>) => {
      return (
        <Flex direction="row" justify="end" css={{ textAlign: "end" }}>
          <FormattedUnit
            value={value.amount}
            unit={unit}
            unitTextType={unitStyle?.textType || "textsLarge"}
            unitFontSize={unitStyle?.fontSize}
            valueTextType={valueStyle?.textType || "textsLarge"}
            valueFontSize={valueStyle?.fontSize}
          />
        </Flex>
      )
    },
    "-",
    valueStyle?.textType
  )

  return customUnitCell
}
