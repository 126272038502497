import { styled, theme } from "../../../../styles/stitches.config"

export const SMenuItemContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: theme.space.s4.value,
  alignItems: "center",
  justifyContent: "space-between",

  hover: {
    cursor: "pointer",
  },

  variants: {
    active: {
      true: {
        color: theme.colors.secondary,
      },
      false: {
        color: theme.colors.textsAlt,
      },
    },
  },
})

export const SMenuItem = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: theme.space.s4,
})

export const iconStyles = {
  width: theme.sizes.s4.value,
  height: theme.sizes.s4.value,
}
