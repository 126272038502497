import { setUser, setExtra } from "@sentry/react"
import { PropsWithChildren, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
// import LogRocket from "logrocket"
import { trpc } from "../api/trpc/trpc"
import { LINKS } from "../constants/links"
import LoadingPage from "../pages/Loading"
import { clearUserInfoFromDaktela, fillUserInfoToDaktela } from "../utils/daktela"
import { addSearchParams } from "../utils/string"
import { isAuthError, useGlobalErrorHandler } from "../utils/error"

export function AuthRoute(props: PropsWithChildren<unknown>) {
  const { children } = props
  const navigate = useNavigate()
  const location = useLocation()
  const handleError = useGlobalErrorHandler()

  const { isLoading, isError, data } = trpc.useQuery(["users.myself"], {
    retry: 1,
    onError: (error) => {
      if (isAuthError(error)) {
        clearUserInfoFromDaktela()
        const to = addSearchParams(LINKS.authLogin, { next: `${location.pathname}${location.search}` })
        navigate(to)
        return
      }

      handleError(error)
    },
  })

  useEffect(() => {
    if (data) {
      setUser({ id: data.id, email: data.email })

      if (data.externalUserId) {
        setExtra("externalUserId", data.externalUserId)
      }
    }
  }, [data])

  const email = data?.email
  useEffect(() => {
    if (!email) {
      clearUserInfoFromDaktela()
      return
    }

    fillUserInfoToDaktela(email)
    // NOTE: nevím jak moc si Daktela persistuje data, tak proto to raději nastavuji při každém rerenderu
  })

  if (isLoading || isError) {
    return <LoadingPage />
  }

  return <>{children}</>
}
