import * as React from "react"

function SvgCheckboxChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 301" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 302"
        d="M19 3H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm-8.29 13.29a1 1 0 01-1.41 0L5.71 12.7a1 1 0 011.41-1.41L10 14.17l6.88-6.88a1 1 0 011.41 1.41l-7.58 7.59z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheckboxChecked
