import { DeliveryPointType } from "@nano-portal/shared"
import Text from "../../../../components/Text"
import { SButton, SContainer } from "./DeliveryPointTypeToggle.styled"
import { useTranslation } from "react-i18next"

interface ButtonProps {
  type: DeliveryPointType
  isSelected: boolean
  onSelect: (selectedType: DeliveryPointType) => void
  header: string
}

const ToggleButton = ({ onSelect, isSelected, type, header }: ButtonProps) => {
  return (
    <SButton selected={isSelected} onClick={() => onSelect(type)}>
      <Text type={isSelected ? "headerH3" : "textsLarge"}>{header}</Text>
    </SButton>
  )
}

interface Props {
  selected: DeliveryPointType
  onSelectedChange: (newValue: DeliveryPointType) => void
}

export default function DeliveryPointTypeToggle({ selected, onSelectedChange }: Props) {
  const { t } = useTranslation("delivery_point")
  return (
    <SContainer>
      <ToggleButton
        type={DeliveryPointType.Consumption}
        isSelected={selected === DeliveryPointType.Consumption}
        onSelect={onSelectedChange}
        header={t("delivery_point_type_toggle_consumption")}
      />
      <ToggleButton
        type={DeliveryPointType.Production}
        isSelected={selected === DeliveryPointType.Production}
        onSelect={onSelectedChange}
        header={t("delivery_point_type_toggle_production")}
      />
    </SContainer>
  )
}
