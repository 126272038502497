import * as React from "react"

function SvgSupportedBrands(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 462 48" {...props}>
      <defs>
        <linearGradient id="_Supported_brands_svg__a" y1={0.5} x2={1} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.303} stopColor="#f2be19" />
          <stop offset={0.698} stopColor="#ed1c24" />
          <stop offset={0.808} stopColor="#b81015" />
          <stop offset={0.937} stopColor="#7f0406" />
          <stop offset={1} stopColor="#690000" />
        </linearGradient>
        <linearGradient
          id="_Supported_brands_svg__c"
          x1={1}
          y1={0.169}
          x2={0.158}
          y2={0.775}
          xlinkHref="#_Supported_brands_svg__a"
        />
        <linearGradient
          id="_Supported_brands_svg__d"
          x1={0.781}
          y1={0.92}
          x2={0.112}
          y2={0.177}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0.182} stopColor="#f2be19" />
          <stop offset={0.578} stopColor="#ed1c24" />
          <stop offset={0.732} stopColor="#b81015" />
          <stop offset={0.913} stopColor="#7f0406" />
          <stop offset={1} stopColor="#690000" />
        </linearGradient>
        <clipPath id="_Supported_brands_svg__b">
          <path data-name="Rectangle 17090" fill="none" d="M0 0h98.365v18.877H0z" />
        </clipPath>
        <clipPath id="_Supported_brands_svg__e">
          <path data-name="Rectangle 17261" fill="#c5d3d6" d="M0 0h63.942v9.508H0z" />
        </clipPath>
        <clipPath id="_Supported_brands_svg__f">
          <path data-name="Rectangle 17262" fill="#c5d3d6" d="M0 0h62.747v15.265H0z" />
        </clipPath>
      </defs>
      <g
        data-name="Group 17671"
        style={{
          isolation: "isolate",
        }}
        fill="#7ca7ad"
      >
        <path data-name="Path 52657" d="M7.476 13H6.383L1.992 6.316V13H.9V4.54h1.092l4.392 6.7v-6.7h1.093V13z" />
        <path
          data-name="Path 52658"
          d="M9.683 6.412l2.141 5.188 1.98-5.184h1.212l-3.917 9.704H9.864l1.367-3.229L8.46 6.412h1.224z"
        />
        <path
          data-name="Path 52659"
          d="M21.336 7.042a2.958 2.958 0 01.708 2.13V13h-1.093V9.268a2.108 2.108 0 00-.5-1.512 1.8 1.8 0 00-1.367-.528 1.869 1.869 0 00-1.44.582 2.412 2.412 0 00-.54 1.7V13h-1.096V6.412H17.1v1.56a2.245 2.245 0 01.894-1.235 2.6 2.6 0 011.482-.437 2.46 2.46 0 011.86.738z"
        />
        <path data-name="Path 52660" d="M26.351 4.492L23.795 5.9v-.78l2.556-1.52zm-1.427 1.92V13h-1.1V6.412z" />
        <path
          data-name="Path 52661"
          d="M34.943 6.736a2.815 2.815 0 011.074 1.176 3.927 3.927 0 01.39 1.8 3.889 3.889 0 01-.39 1.794 2.828 2.828 0 01-1.074 1.17 2.989 2.989 0 01-1.56.408 2.679 2.679 0 01-1.626-.486 2.474 2.474 0 01-.919-1.277v4.8h-1.092V6.412h1.092v1.68a2.474 2.474 0 01.919-1.277 2.679 2.679 0 011.626-.486 2.989 2.989 0 011.56.408zm-3.024.857a2.037 2.037 0 00-.8.847 2.706 2.706 0 00-.295 1.271 2.677 2.677 0 00.288 1.266 2.085 2.085 0 00.8.847 2.255 2.255 0 001.152.3 2.13 2.13 0 001.626-.642 2.479 2.479 0 00.606-1.771 2.475 2.475 0 00-.606-1.77 2.127 2.127 0 00-1.619-.641 2.3 2.3 0 00-1.152.294z"
        />
        <path
          data-name="Path 52662"
          d="M39.876 6.682a2.817 2.817 0 011.487-.382v1.192h-.339a2.333 2.333 0 00-1.512.474 1.9 1.9 0 00-.588 1.566V13h-1.089V6.412h1.089v1.332a2.418 2.418 0 01.948-1.062z"
        />
        <path
          data-name="Path 52663"
          d="M47.124 6.736A2.907 2.907 0 0148.3 7.912a3.652 3.652 0 01.427 1.8 3.618 3.618 0 01-.427 1.794 2.923 2.923 0 01-1.176 1.17 3.735 3.735 0 01-3.4 0 2.965 2.965 0 01-1.183-1.17 3.575 3.575 0 01-.417-1.794 3.608 3.608 0 01.432-1.8 2.961 2.961 0 011.178-1.176 3.735 3.735 0 013.395 0zm-2.784.815a1.987 1.987 0 00-.8.811 2.728 2.728 0 00-.306 1.35 2.7 2.7 0 00.306 1.338 1.987 1.987 0 00.8.811 2.368 2.368 0 002.173 0 1.987 1.987 0 00.8-.811 2.7 2.7 0 00.311-1.338 2.731 2.731 0 00-.306-1.35 1.993 1.993 0 00-.8-.811 2.368 2.368 0 00-2.173 0z"
        />
        <path
          data-name="Path 52664"
          d="M56.981 6.91a2.451 2.451 0 01.822 1.565h-1.044a1.521 1.521 0 00-.486-.966 1.583 1.583 0 00-1.11-.378 1.375 1.375 0 00-.918.282.951.951 0 00-.33.762.782.782 0 00.223.582 1.41 1.41 0 00.552.336 9.131 9.131 0 00.906.246 9.1 9.1 0 011.182.342 1.854 1.854 0 01.768.559 1.568 1.568 0 01.318 1.032 1.623 1.623 0 01-.606 1.308 2.439 2.439 0 01-1.613.5 3.092 3.092 0 01-1.92-.564 2.245 2.245 0 01-.876-1.571h1.056a1.377 1.377 0 00.522.972 1.883 1.883 0 001.218.372 1.266 1.266 0 00.879-.289.972.972 0 00.318-.756.824.824 0 00-.229-.612 1.512 1.512 0 00-.563-.348 8.276 8.276 0 00-.925-.252 9.065 9.065 0 01-1.17-.336 1.793 1.793 0 01-.744-.534 1.5 1.5 0 01-.306-.99 1.657 1.657 0 01.619-1.333 2.526 2.526 0 011.668-.51 2.659 2.659 0 011.794.582z"
        />
        <path
          data-name="Path 52665"
          d="M62.471 12.04V13h-.815a2.094 2.094 0 01-1.506-.48 2.159 2.159 0 01-.5-1.607V7.361h-.984v-.948h.984V4.768h1.1v1.645h1.7v.948h-1.7v3.575a1.228 1.228 0 00.234.87 1.125 1.125 0 00.81.234h.672z"
        />
        <path
          data-name="Path 52666"
          d="M65.843 6.682a2.818 2.818 0 011.488-.382v1.192h-.336a2.333 2.333 0 00-1.512.474 1.9 1.9 0 00-.589 1.566V13h-1.092V6.412h1.092v1.332a2.424 2.424 0 01.948-1.062zm1.381-1.974l-1.655 1-1.668-1V3.94l1.668 1.008 1.655-1.008z"
        />
        <path data-name="Path 52667" d="M71.039 4.492L68.483 5.9v-.78l2.556-1.52zm-1.428 1.92V13h-1.1V6.412z" />
        <path
          data-name="Path 52668"
          d="M75.797 6.808a2.507 2.507 0 01.93 1.284V4.12h1.097V13h-1.1v-1.68a2.5 2.5 0 01-.93 1.283 2.707 2.707 0 01-1.626.48 2.99 2.99 0 01-1.561-.408 2.816 2.816 0 01-1.074-1.17 3.887 3.887 0 01-.39-1.794 3.924 3.924 0 01.39-1.8 2.814 2.814 0 011.074-1.176 2.987 2.987 0 011.561-.408 2.713 2.713 0 011.626.479zm-2.929 1.135a2.479 2.479 0 00-.605 1.77 2.483 2.483 0 00.605 1.771 2.115 2.115 0 001.614.642 2.241 2.241 0 001.158-.3 2.091 2.091 0 00.8-.847 2.669 2.669 0 00.284-1.266 2.7 2.7 0 00-.288-1.271 2.048 2.048 0 00-.8-.847 2.289 2.289 0 00-1.154-.295 2.112 2.112 0 00-1.614.643z"
        />
        <path
          data-name="Path 52669"
          d="M83.885 6.808a2.507 2.507 0 01.93 1.284v-1.68h1.092V13h-1.092v-1.68a2.5 2.5 0 01-.93 1.283 2.707 2.707 0 01-1.626.48 2.99 2.99 0 01-1.561-.408 2.816 2.816 0 01-1.074-1.175 3.887 3.887 0 01-.39-1.794 3.924 3.924 0 01.39-1.8 2.814 2.814 0 011.074-1.176 2.987 2.987 0 011.561-.408 2.713 2.713 0 011.626.479zm-2.929 1.135a2.479 2.479 0 00-.605 1.77 2.483 2.483 0 00.605 1.771 2.115 2.115 0 001.614.642 2.241 2.241 0 001.158-.3 2.091 2.091 0 00.8-.847 2.669 2.669 0 00.288-1.266 2.7 2.7 0 00-.292-1.271 2.048 2.048 0 00-.8-.847A2.289 2.289 0 0082.57 7.3a2.112 2.112 0 00-1.614.643z"
        />
        <path
          data-name="Path 52670"
          d="M92.65 7a2.843 2.843 0 011.021 1.8h-1.147a1.682 1.682 0 00-.653-1.109 2.314 2.314 0 00-2.377-.138 1.935 1.935 0 00-.762.811 2.878 2.878 0 00-.288 1.35 2.878 2.878 0 00.288 1.35 1.923 1.923 0 00.762.811 2.3 2.3 0 002.377-.144 1.708 1.708 0 00.653-1.115h1.152a2.83 2.83 0 01-1.021 1.806 3.225 3.225 0 01-2.088.666 3.4 3.4 0 01-1.68-.408 2.877 2.877 0 01-1.146-1.17 3.711 3.711 0 01-.417-1.794 3.746 3.746 0 01.414-1.8 2.874 2.874 0 011.144-1.176 3.392 3.392 0 011.68-.408A3.206 3.206 0 0192.65 7zm-.491-2.292l-1.656 1-1.668-1V3.94l1.668 1.008 1.656-1.008z"
        />
        <path
          data-name="Path 52671"
          d="M101.11 9.94h-5.268a2.3 2.3 0 00.642 1.691 2.1 2.1 0 001.482.564 2.139 2.139 0 001.325-.4 1.662 1.662 0 00.654-1.074h1.164a2.75 2.75 0 01-1.608 2.065 3.552 3.552 0 01-1.487.3 3.4 3.4 0 01-1.681-.408 2.89 2.89 0 01-1.146-1.17 3.688 3.688 0 01-.414-1.794 3.735 3.735 0 01.414-1.8 2.874 2.874 0 011.146-1.176 3.4 3.4 0 011.681-.408 3.319 3.319 0 011.674.408 2.8 2.8 0 011.092 1.092 3.073 3.073 0 01.378 1.512 3.264 3.264 0 01-.048.6zm-1.3-1.752a1.765 1.765 0 00-.756-.732 2.3 2.3 0 00-1.044-.24 2.164 2.164 0 00-1.513.559 2.248 2.248 0 00-.659 1.638h4.212a2.259 2.259 0 00-.24-1.224z"
        />
        <path data-name="Path 52672" d="M103.534 6.532v1.284h-1.32V6.532zm0 5.184V13h-1.32v-1.284z" />
      </g>
      <g
        data-name="Group 17672"
        style={{
          isolation: "isolate",
        }}
        fill="#7ca7ad"
      >
        <path
          data-name="Path 52673"
          d="M244.361 9.364a2.035 2.035 0 01.485 1.355 2.106 2.106 0 01-.7 1.656 2.97 2.97 0 01-2.034.624h-3.144V4.54h3.1a2.905 2.905 0 011.932.563 1.935 1.935 0 01.66 1.549 1.938 1.938 0 01-.438 1.319 1.982 1.982 0 01-1.123.648 2.218 2.218 0 011.267.744zm-4.3-1.116h1.824a1.815 1.815 0 001.229-.372 1.313 1.313 0 00.427-1.044 1.3 1.3 0 00-.421-1.021 1.9 1.9 0 00-1.284-.371h-1.775v2.808zm3.186 3.444a1.377 1.377 0 00.475-1.116 1.362 1.362 0 00-.486-1.11 2.057 2.057 0 00-1.35-.4h-1.824v3.023h1.848a2.032 2.032 0 001.338-.4z"
        />
        <path
          data-name="Path 52674"
          d="M248.255 6.682a2.818 2.818 0 011.488-.378v1.188h-.336a2.333 2.333 0 00-1.512.474 1.9 1.9 0 00-.588 1.566V13h-1.092V6.412h1.092v1.332a2.416 2.416 0 01.947-1.062z"
        />
        <path
          data-name="Path 52675"
          d="M251.459 12.076h3.528V13h-4.729v-.876l3.359-4.788h-3.264v-.924h4.512V7.3l-3.407 4.776z"
        />
        <path
          data-name="Path 52676"
          d="M256.631 6.412l2.136 5.184 1.98-5.184h1.212l-3.912 9.708h-1.235l1.367-3.229-2.771-6.479h1.224z"
        />
        <path
          data-name="Path 52677"
          d="M269.063 12.04V13h-.816a2.1 2.1 0 01-1.506-.48 2.164 2.164 0 01-.5-1.607V7.361h-.984v-.948h.984V4.768h1.1v1.645h1.7v.948h-1.7v3.575a1.228 1.228 0 00.234.87 1.128 1.128 0 00.811.234h.672z"
        />
        <path
          data-name="Path 52678"
          d="M274.724 6.808a2.5 2.5 0 01.93 1.284v-1.68h1.093V13h-1.093v-1.68a2.489 2.489 0 01-.93 1.283 2.707 2.707 0 01-1.626.48 2.992 2.992 0 01-1.561-.408 2.823 2.823 0 01-1.074-1.17 3.886 3.886 0 01-.39-1.794 3.924 3.924 0 01.39-1.8 2.821 2.821 0 011.074-1.176 2.992 2.992 0 011.561-.408 2.713 2.713 0 011.626.479zm-2.929 1.135a2.479 2.479 0 00-.605 1.77 2.483 2.483 0 00.605 1.771 2.116 2.116 0 001.615.642 2.237 2.237 0 001.157-.3 2.085 2.085 0 00.8-.847 2.669 2.669 0 00.288-1.266 2.7 2.7 0 00-.288-1.271 2.042 2.042 0 00-.8-.847 2.285 2.285 0 00-1.157-.294 2.113 2.113 0 00-1.615.643z"
        />
        <path
          data-name="Path 52679"
          d="M282.095 13l-2.521-2.964V13h-1.092V4.12h1.092v5.16l2.484-2.868h1.332l-2.952 3.276L283.461 13h-1.367z"
        />
        <path
          data-name="Path 52680"
          d="M290.434 9.94h-5.268a2.3 2.3 0 00.642 1.691 2.091 2.091 0 001.481.564 2.14 2.14 0 001.326-.4 1.666 1.666 0 00.654-1.074h1.164a2.75 2.75 0 01-1.608 2.065 3.555 3.555 0 01-1.487.3 3.4 3.4 0 01-1.681-.408 2.883 2.883 0 01-1.146-1.17 3.7 3.7 0 01-.414-1.794 3.735 3.735 0 01.414-1.8 2.868 2.868 0 011.146-1.176 3.394 3.394 0 011.681-.408 3.319 3.319 0 011.674.408 2.8 2.8 0 011.092 1.092 3.073 3.073 0 01.378 1.512 3.353 3.353 0 01-.048.6zm-1.3-1.752a1.754 1.754 0 00-.756-.732 2.3 2.3 0 00-1.043-.24 2.164 2.164 0 00-1.513.559 2.248 2.248 0 00-.659 1.638h4.211a2.241 2.241 0 00-.24-1.224zm.133-3.7l-2.557 1.4v-.78l2.56-1.508v.889z"
        />
        <path
          data-name="Path 52681"
          d="M300.07 6.736a2.815 2.815 0 011.074 1.176 3.927 3.927 0 01.39 1.8 3.889 3.889 0 01-.39 1.794 2.828 2.828 0 01-1.074 1.17 2.986 2.986 0 01-1.56.408 2.681 2.681 0 01-1.626-.486 2.474 2.474 0 01-.919-1.277v4.8h-1.092V6.412h1.092v1.68a2.474 2.474 0 01.919-1.277 2.681 2.681 0 011.626-.486 2.986 2.986 0 011.56.408zm-3.024.857a2.029 2.029 0 00-.8.847 2.7 2.7 0 00-.289 1.271 2.666 2.666 0 00.289 1.266 2.076 2.076 0 00.8.847 2.255 2.255 0 001.152.3 2.132 2.132 0 001.627-.642 2.483 2.483 0 00.605-1.771 2.479 2.479 0 00-.605-1.77 2.129 2.129 0 00-1.627-.643 2.3 2.3 0 00-1.152.294z"
        />
        <path
          data-name="Path 52682"
          d="M305.003 6.682a2.816 2.816 0 011.487-.378v1.188h-.336a2.333 2.333 0 00-1.512.474 1.9 1.9 0 00-.589 1.566V13h-1.092V6.412h1.092v1.332a2.426 2.426 0 01.949-1.062z"
        />
        <path
          data-name="Path 52683"
          d="M312.257 6.736a2.914 2.914 0 011.176 1.176 3.663 3.663 0 01.426 1.8 3.629 3.629 0 01-.426 1.794 2.929 2.929 0 01-1.176 1.17 3.737 3.737 0 01-3.4 0 2.957 2.957 0 01-1.182-1.17 3.565 3.565 0 01-.433-1.794 3.6 3.6 0 01.433-1.8 2.952 2.952 0 011.182-1.176 3.737 3.737 0 013.4 0zm-2.785.815a1.979 1.979 0 00-.8.811 2.715 2.715 0 00-.307 1.35 2.688 2.688 0 00.307 1.338 1.979 1.979 0 00.8.811 2.368 2.368 0 002.173 0 1.987 1.987 0 00.8-.811 2.7 2.7 0 00.307-1.338 2.731 2.731 0 00-.307-1.35 1.994 1.994 0 00-.8-.811 2.368 2.368 0 00-2.173 0z"
        />
        <path
          data-name="Path 52684"
          d="M322.108 6.91a2.456 2.456 0 01.822 1.565h-1.045a1.512 1.512 0 00-.486-.966 1.58 1.58 0 00-1.109-.378 1.373 1.373 0 00-.918.282.948.948 0 00-.33.762.782.782 0 00.223.582 1.413 1.413 0 00.551.336 9.289 9.289 0 00.906.246 9.009 9.009 0 011.182.342 1.851 1.851 0 01.769.559 1.568 1.568 0 01.318 1.032 1.623 1.623 0 01-.606 1.308 2.441 2.441 0 01-1.613.5 3.09 3.09 0 01-1.92-.564 2.246 2.246 0 01-.877-1.571h1.057a1.376 1.376 0 00.521.972 1.888 1.888 0 001.219.372 1.264 1.264 0 00.881-.288.968.968 0 00.318-.756.827.827 0 00-.228-.612 1.52 1.52 0 00-.563-.348 8.338 8.338 0 00-.925-.252 9.066 9.066 0 01-1.17-.336 1.793 1.793 0 01-.744-.534 1.5 1.5 0 01-.306-.99 1.656 1.656 0 01.611-1.338 2.528 2.528 0 011.668-.51 2.663 2.663 0 011.8.582z"
        />
        <path
          data-name="Path 52685"
          d="M327.598 12.04V13h-.816a2.1 2.1 0 01-1.506-.48 2.164 2.164 0 01-.5-1.607V7.361h-.984v-.948h.984V4.768h1.1v1.645h1.7v.948h-1.7v3.575a1.228 1.228 0 00.234.87 1.128 1.128 0 00.811.234h.672z"
        />
        <path
          data-name="Path 52686"
          d="M330.97 6.682a2.818 2.818 0 011.488-.378v1.188h-.334a2.329 2.329 0 00-1.512.474 1.9 1.9 0 00-.588 1.566V13h-1.092V6.412h1.092v1.332a2.418 2.418 0 01.948-1.062zm1.38-1.974l-1.655 1-1.668-1V3.94l1.668 1.008 1.655-1.008z"
        />
        <path data-name="Path 52687" d="M336.165 4.492l-2.555 1.4v-.78l2.555-1.512zm-1.428 1.92V13h-1.1V6.412z" />
        <path
          data-name="Path 52688"
          d="M340.924 6.808a2.5 2.5 0 01.93 1.284V4.12h1.093V13h-1.093v-1.68a2.489 2.489 0 01-.93 1.283 2.707 2.707 0 01-1.626.48 2.992 2.992 0 01-1.561-.408 2.823 2.823 0 01-1.074-1.17 3.886 3.886 0 01-.39-1.794 3.924 3.924 0 01.39-1.8 2.821 2.821 0 011.074-1.176 2.992 2.992 0 011.561-.408 2.713 2.713 0 011.626.479zm-2.929 1.135a2.479 2.479 0 00-.605 1.77 2.483 2.483 0 00.605 1.771 2.116 2.116 0 001.615.642 2.237 2.237 0 001.157-.3 2.085 2.085 0 00.8-.847 2.669 2.669 0 00.288-1.266 2.7 2.7 0 00-.288-1.271 2.042 2.042 0 00-.8-.847 2.285 2.285 0 00-1.157-.294 2.113 2.113 0 00-1.615.643z"
        />
        <path
          data-name="Path 52689"
          d="M349.012 6.808a2.5 2.5 0 01.93 1.284v-1.68h1.093V13h-1.093v-1.68a2.489 2.489 0 01-.93 1.283 2.707 2.707 0 01-1.626.48 2.992 2.992 0 01-1.561-.408 2.823 2.823 0 01-1.074-1.17 3.886 3.886 0 01-.39-1.794 3.924 3.924 0 01.39-1.8 2.821 2.821 0 011.073-1.176 2.992 2.992 0 011.561-.408 2.713 2.713 0 011.626.479zm-2.929 1.135a2.479 2.479 0 00-.605 1.77 2.483 2.483 0 00.605 1.771 2.116 2.116 0 001.615.642 2.237 2.237 0 001.157-.3 2.085 2.085 0 00.8-.847 2.669 2.669 0 00.288-1.266 2.7 2.7 0 00-.288-1.271 2.042 2.042 0 00-.8-.847 2.285 2.285 0 00-1.157-.294 2.113 2.113 0 00-1.615.643z"
        />
        <path
          data-name="Path 52690"
          d="M357.777 7a2.852 2.852 0 011.021 1.8h-1.152a1.684 1.684 0 00-.654-1.109 2.312 2.312 0 00-2.376-.138 1.935 1.935 0 00-.762.811 2.856 2.856 0 00-.288 1.35 2.866 2.866 0 00.288 1.35 1.923 1.923 0 00.762.811 2.3 2.3 0 002.376-.144 1.709 1.709 0 00.654-1.115h1.152a2.839 2.839 0 01-1.021 1.806 3.22 3.22 0 01-2.088.666 3.4 3.4 0 01-1.681-.408 2.89 2.89 0 01-1.146-1.17 3.688 3.688 0 01-.414-1.794 3.735 3.735 0 01.414-1.8 2.874 2.874 0 011.146-1.176 3.4 3.4 0 011.681-.408 3.206 3.206 0 012.088.668zm-.491-2.292l-1.656 1-1.668-1V3.94l1.668 1.008 1.656-1.008z"
        />
        <path
          data-name="Path 52691"
          d="M366.237 9.94h-5.268a2.3 2.3 0 00.642 1.691 2.091 2.091 0 001.481.564 2.14 2.14 0 001.326-.4 1.666 1.666 0 00.654-1.074h1.164a2.75 2.75 0 01-1.608 2.065 3.555 3.555 0 01-1.487.3 3.4 3.4 0 01-1.681-.408 2.883 2.883 0 01-1.146-1.17 3.7 3.7 0 01-.414-1.794 3.735 3.735 0 01.414-1.8 2.868 2.868 0 011.146-1.176 3.394 3.394 0 011.681-.408 3.319 3.319 0 011.674.408 2.8 2.8 0 011.092 1.092 3.073 3.073 0 01.378 1.512 3.353 3.353 0 01-.048.6zm-1.3-1.752a1.754 1.754 0 00-.756-.732 2.3 2.3 0 00-1.043-.24 2.164 2.164 0 00-1.513.559 2.248 2.248 0 00-.659 1.638h4.211a2.241 2.241 0 00-.24-1.224z"
        />
        <path data-name="Path 52692" d="M368.661 6.532v1.284h-1.32V6.532zm0 5.184V13h-1.32v-1.284z" />
      </g>
      <g
        data-name="Group 17673"
        style={{
          isolation: "isolate",
        }}
        fill="#c5d3d6"
      >
        <path
          data-name="Path 52693"
          d="M422.856 40.044h-3.721L418.424 42h-1.176l3.131-8.3h1.245l3.12 8.3h-1.176l-.708-1.956zm-.312-.852l-1.547-4.272-1.549 4.272z"
        />
        <path
          data-name="Path 52694"
          d="M433.134 35.808a2.5 2.5 0 01.93 1.284V33.12h1.093V42h-1.093v-1.68a2.489 2.489 0 01-.93 1.283 2.7 2.7 0 01-1.625.48 2.99 2.99 0 01-1.561-.408 2.816 2.816 0 01-1.074-1.17 4.325 4.325 0 010-3.594 2.814 2.814 0 011.074-1.176 2.987 2.987 0 011.561-.408 2.71 2.71 0 011.625.479zm-2.928 1.135a2.893 2.893 0 000 3.541 2.113 2.113 0 001.614.642 2.237 2.237 0 001.157-.3 2.094 2.094 0 00.8-.847 2.68 2.68 0 00.287-1.266 2.713 2.713 0 00-.287-1.271 2.05 2.05 0 00-.8-.847 2.285 2.285 0 00-1.153-.295 2.111 2.111 0 00-1.614.643z"
        />
        <path
          data-name="Path 52695"
          d="M441.224 35.808a2.5 2.5 0 01.93 1.284v-1.68h1.093V42h-1.093v-1.68a2.489 2.489 0 01-.93 1.283 2.7 2.7 0 01-1.625.48 2.99 2.99 0 01-1.561-.408 2.816 2.816 0 01-1.074-1.17 4.325 4.325 0 010-3.594 2.814 2.814 0 011.074-1.176 2.987 2.987 0 011.561-.408 2.71 2.71 0 011.625.479zm-2.928 1.135a2.893 2.893 0 000 3.541 2.113 2.113 0 001.614.642 2.237 2.237 0 001.157-.3 2.094 2.094 0 00.8-.847 2.68 2.68 0 00.287-1.266 2.713 2.713 0 00-.287-1.271 2.05 2.05 0 00-.8-.847 2.285 2.285 0 00-1.157-.294 2.111 2.111 0 00-1.614.643z"
        />
        <path data-name="Path 52696" d="M446.075 33.12V42h-1.092v-8.88z" />
        <path
          data-name="Path 52697"
          d="M451.686 35.91a2.456 2.456 0 01.822 1.565h-1.045a1.512 1.512 0 00-.486-.966 1.58 1.58 0 00-1.109-.378 1.373 1.373 0 00-.918.282.948.948 0 00-.33.762.782.782 0 00.223.582 1.413 1.413 0 00.551.336 9.288 9.288 0 00.906.246 9.009 9.009 0 011.182.342 1.851 1.851 0 01.769.559 1.568 1.568 0 01.318 1.032 1.623 1.623 0 01-.606 1.308 2.441 2.441 0 01-1.613.5 3.09 3.09 0 01-1.92-.564 2.246 2.246 0 01-.877-1.571h1.057a1.376 1.376 0 00.521.972 1.888 1.888 0 001.219.372 1.264 1.264 0 00.881-.288.968.968 0 00.318-.756.827.827 0 00-.228-.612 1.52 1.52 0 00-.563-.348 8.337 8.337 0 00-.925-.252 9.066 9.066 0 01-1.17-.336 1.793 1.793 0 01-.744-.534 1.5 1.5 0 01-.306-.99 1.656 1.656 0 01.611-1.338 2.528 2.528 0 011.668-.51 2.663 2.663 0 011.8.582zm.029-2.2l-1.656 1-1.668-1v-.77l1.668 1.008 1.656-1.008z"
        />
        <path data-name="Path 52698" d="M456.66 33.492l-2.556 1.4v-.78l2.556-1.513zm-1.428 1.92V42h-1.1v-6.588z" />
        <path data-name="Path 52699" d="M457.895 40.716V42h-1.32v-1.284z" />
        <path data-name="Path 52700" d="M459.948 40.716V42h-1.324v-1.284z" />
        <path data-name="Path 52701" d="M462 40.716V42h-1.32v-1.284z" />
      </g>
      <path data-name="Rectangle 17268" fill="#eef4f6" d="M207.024 0h1v48h-1z" />
      <g data-name="Group 16144" transform="translate(0 29)" clipPath="url(#_Supported_brands_svg__b)">
        <path
          data-name="Path 51124"
          d="M4.333 2.872a4.228 4.228 0 00.951-1 3.083 3.083 0 011.049-.9L6.35.954a3.9 3.9 0 00-3.705.787S.414 3.249 0 9.512c0 0-.115 1.049.787 1.213 0 0 .951.2 1.049-.607.115-.787.115-4.755 1.853-6.788a1.368 1.368 0 01.639-.459"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51125"
          d="M7.334 3.856A6.344 6.344 0 019.679.806a3.866 3.866 0 00-3.394.837S4.202 2.922 3.678 8.021a10.4 10.4 0 00-.082 1.886.881.881 0 00.837.787s.951.082.984-.787l.082-1.213s.148-3.394 1.508-5.1z"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51126"
          d="M10.825 3.823A6.514 6.514 0 0113.057.841V.805h-.017a4.149 4.149 0 00-3.771 1.213s-1.59 1.394-2.033 4.9c0 0-.2 1.885-.23 2.787a.988.988 0 00.837.984.9.9 0 00.951-.82v-.05s.082-4.263 1.656-6.231z"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51127"
          d="M12.024 2.921s-1.508 2.2-1.508 6.64c0 0-.082 1.049.754 1.165a.929.929 0 00.984-.837v-.212s.033-4.395 1.738-6.199c0 0 1.508 1.771 1.623 6a17.911 17.911 0 00.607 4.493 20.7 20.7 0 00.574-4.263.943.943 0 01.344-.754s.262-.115.2-.573a18.072 18.072 0 00-1.59-5.772s2.607-.2 3.1 5.018a2.138 2.138 0 00.41 1.246.943.943 0 01.344.754 16.32 16.32 0 01-.148 3.1 2.7 2.7 0 00.2 1.246 10.21 10.21 0 00.491-3.509s-.082-1.394.607-1.886c0 0-.033-3.968-1.623-6.116A4.395 4.395 0 0015.54.773a4.16 4.16 0 00-3.509 2.148"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51128"
          d="M28.864 8.841s-.951-.2-1.049.607c-.115.787-.115 4.755-1.853 6.788a1.365 1.365 0 01-.64.459 4.034 4.034 0 00-.951 1.017 3.289 3.289 0 01-1.066.9 3.826 3.826 0 003.706-.787s2.23-1.508 2.64-7.772c0 0 .115-1.049-.787-1.213"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51129"
          d="M25.945 11.561a10.4 10.4 0 00.082-1.886.842.842 0 00-.837-.787s-.951-.082-.984.787l-.082 1.213s-.148 3.394-1.508 5.1l-.344-.262a5.959 5.959 0 01-2.329 3.05 3.866 3.866 0 003.394-.837s2.084-1.278 2.608-6.378"
          fill="#5aa5e1"
        />
        <path data-name="Path 51130" d="M16.598 18.793l.019-.016z" fill="#5aa5e1" />
        <path
          data-name="Path 51131"
          d="M20.386 17.561s1.59-1.394 2.033-4.9c0 0 .2-1.886.23-2.787a.988.988 0 00-.837-.984.9.9 0 00-.951.82v.1s-.033 4.263-1.656 6.23l-.377-.229a6.694 6.694 0 01-2.21 2.968 4.147 4.147 0 003.768-1.218"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51132"
          d="M17.6 16.662s1.508-2.2 1.508-6.64c0 0 .082-1.049-.754-1.165a.929.929 0 00-.984.837v.213s-.033 4.411-1.738 6.2c0 0-1.508-1.771-1.623-6a17.911 17.911 0 00-.607-4.493 20.7 20.7 0 00-.574 4.263.943.943 0 01-.344.754s-.262.115-.2.573a18.072 18.072 0 001.59 5.772s-2.607.2-3.1-5.018a2.142 2.142 0 00-.41-1.247.94.94 0 01-.344-.754 16.32 16.32 0 01.148-3.1 3.2 3.2 0 00-.2-1.246 9.458 9.458 0 00-.459 3.509s.082 1.394-.607 1.886c0 0 .082 3.968 1.624 6.116a4.357 4.357 0 003.558 1.689 4.16 4.16 0 003.509-2.148"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51133"
          d="M34.734 2.511c-.033 0-.115-.033-.148-.033-.016 0-.049-.017-.065-.017a.735.735 0 00-.919.542l-.934 2.852h-.033l-.984-2.9c-.017-.033-.033-.049-.033-.082a.829.829 0 10-1.508.689l1.59 4a1.046 1.046 0 00.574.607c.573.23.984 0 1.213-.607l1.623-3.968a.805.805 0 00-.377-1.082"
        />
        <path
          data-name="Path 51134"
          d="M37.095.248a.926.926 0 00-1.3.033.87.87 0 00-.295.689.77.77 0 00.295.64l.033.033a.926.926 0 001.3-.033l.033-.033a.925.925 0 00-.033-1.3z"
        />
        <path
          data-name="Path 51135"
          d="M36.996 2.626a.782.782 0 00-1.115.016.848.848 0 00-.229.574h-.033v4.23a.85.85 0 00.837.837.819.819 0 00.787-.837V3.217a.819.819 0 00-.23-.574z"
        />
        <path
          data-name="Path 51136"
          d="M41.358 4.053l.148.033.115.033.148.033.082.033h.147a.686.686 0 00.672-.574c.148-.541-.262-1.066-1.476-1.213a5.378 5.378 0 01-.541-.033 2.951 2.951 0 10.443 5.886 1.535 1.535 0 00.61-.116c.755-.23 1.066-.607.984-1.017a.284.284 0 00-.033-.2.685.685 0 00-.9-.344l-.082.033-.2.082a1.3 1.3 0 01-1.885-1.278 1.3 1.3 0 011.771-1.361"
        />
        <path
          data-name="Path 51137"
          d="M45.982 2.462h-.492v-.951a.787.787 0 00-1.574 0v.951l-.311.033a.732.732 0 00-.525.23l-.033.033a.7.7 0 00.033.987.773.773 0 00.525.2h.295v3.5a.819.819 0 00.23.574.834.834 0 001.131 0 .851.851 0 00.229-.574V3.938h.492a.735.735 0 00.525-.23l.05-.049a.7.7 0 00-.05-.967.706.706 0 00-.525-.23"
        />
        <path
          data-name="Path 51138"
          d="M49.902 3.921l.23-.082.082-.033c.033-.033.115-.082.147-.115a.717.717 0 00.115-.984c-.328-.475-1.049-.475-1.656.2-.066.033-.115.115-.148.148V2.97c.049-.312-.3-.607-.787-.574a.831.831 0 00-.837.788l.066 4.263v.033a.76.76 0 00.787.754.852.852 0 00.837-.837v-2.41c0-.262.147-.541.672-.836a3.343 3.343 0 01.492-.23"
        />
        <path
          data-name="Path 51139"
          d="M53.312 2.38a2.791 2.791 0 00-2.378 1.394 3.281 3.281 0 00-.409 1.587v.147a2.965 2.965 0 005.919-.344 3.013 3.013 0 00-3.132-2.787m1.164 3.2a1.25 1.25 0 01-.9 1.246c-.672.148-1.247-.262-1.394-1.246v-.524c.115-.951.721-1.361 1.394-1.213a1.265 1.265 0 01.9 1.213 1.422 1.422 0 010 .525"
        />
        <path
          data-name="Path 51140"
          d="M62.003 4.446v-.017a1.893 1.893 0 00-1.738-2.034 3.187 3.187 0 00-.787 0 1.806 1.806 0 00-1.1.607v-.163a.612.612 0 00-.721-.46.9.9 0 00-.837.837h.05v4.248a.821.821 0 00.738.738.833.833 0 00.886-.738V4.708a.875.875 0 01.262-.672l.033-.033a.926.926 0 011.3.033.916.916 0 01.295.672v2.87a.8.8 0 00.8.7.822.822 0 00.82-.82z"
        />
        <path
          data-name="Path 51141"
          d="M70.956 6.742a.641.641 0 00-.344-.344c-.279-.1-.426-.1-.951.2a1.657 1.657 0 01-2.115-.2 1.53 1.53 0 01-.262-.574l3.116.033c.525 0 .755-.262.672-.9a2.833 2.833 0 00-.23-.9 2.747 2.747 0 00-3.017-1.624 2.8 2.8 0 00-2.23 2.2 3.313 3.313 0 00-.115.9 2.9 2.9 0 003.017 2.755c1.968 0 2.689-.967 2.459-1.542m-3.673-2.088a1.227 1.227 0 01.869-.951 1.1 1.1 0 011.328.984.275.275 0 01.027.082h-2.224z"
        />
        <path
          data-name="Path 51142"
          d="M75.087 2.397a3.187 3.187 0 00-.787 0 1.8 1.8 0 00-1.1.607v-.165a.612.612 0 00-.721-.459.908.908 0 00-.787.836v4.248a.822.822 0 00.738.738.834.834 0 00.886-.738V4.709a.875.875 0 01.262-.672l.033-.033a.927.927 0 011.3.033.916.916 0 01.295.672v2.87a.8.8 0 00.8.7.822.822 0 00.82-.82V4.43a1.892 1.892 0 00-1.738-2.033"
        />
        <path
          data-name="Path 51143"
          d="M83.106 4.958a2.794 2.794 0 00-.23-.9 2.746 2.746 0 00-3.017-1.624 2.8 2.8 0 00-2.23 2.2 3.321 3.321 0 00-.115.9 2.9 2.9 0 003.017 2.754c1.968 0 2.689-.967 2.46-1.541a.644.644 0 00-.344-.344c-.279-.1-.426-.1-.951.2a1.658 1.658 0 01-2.116-.2 1.53 1.53 0 01-.262-.574l3.116.033c.525 0 .755-.262.672-.9m-3.821-.18a.238.238 0 00.037-.124 1.225 1.225 0 01.869-.951c.607-.148 1.131.229 1.329.984a.324.324 0 01.027.082z"
        />
        <path
          data-name="Path 51144"
          d="M87.156 2.708c-.328-.475-1.017-.475-1.656.2-.066.033-.115.115-.148.148V2.97c.05-.312-.295-.607-.787-.574a.83.83 0 00-.836.787l-.033 4.214a.836.836 0 101.672 0v-2.41c0-.262.148-.541.672-.836a3.268 3.268 0 01.492-.23l.229-.082.082-.033a.787.787 0 00.2-.115.717.717 0 00.115-.984"
        />
        <path
          data-name="Path 51145"
          d="M91.96 2.381h-.082a.652.652 0 00-.642.436 2.244 2.244 0 00-3.474.646 3.311 3.311 0 00-.41 1.1 3.192 3.192 0 00.755 2.9 2.074 2.074 0 003.132 0v.23c0 1.049-.8 1.624-2.2 1.131a.546.546 0 01-.23-.082l-.344-.148a.79.79 0 00-.836.2c-.378.426-.262.951.525 1.328a3.127 3.127 0 001.213.344c2.181.23 3.427-.9 3.427-3.017V3.217a.9.9 0 00-.837-.837m-1.082 4c-.05.082-.115.115-.2.2a1.069 1.069 0 01-1.509-.147 1.648 1.648 0 01-.327-1.066c0-.1.033-.18.033-.295.115-.984.9-1.443 1.624-1.1a1.46 1.46 0 01.672 1.394 1.884 1.884 0 01-.295 1.017"
        />
        <path
          data-name="Path 51146"
          d="M97.748 2.397a.8.8 0 00-.967.59l-1.017 2.722-1.017-2.722c0-.033-.033-.082-.033-.115 0-.016-.016-.033-.016-.049a.781.781 0 00-1.033-.361 1.029 1.029 0 00-.574 1.016l1.706 4.264-.755 1.738v.033a.832.832 0 00.46 1.049c.033 0 .082.033.115.033h.016a.827.827 0 001-.607l2.64-6.46a.391.391 0 00.082-.23.8.8 0 00-.607-.9"
        />
        <path
          data-name="Path 51147"
          d="M41.737 11.448a.471.471 0 00.23-.147.329.329 0 00.115-.262.463.463 0 00-.148-.378.766.766 0 00-.459-.147h-.525v2.082h.607a.8.8 0 00.475-.164.691.691 0 00.2-.41.636.636 0 00-.115-.377.569.569 0 00-.377-.2m-.541-.639a.483.483 0 01.262-.033c.229 0 .377.082.377.295s-.148.344-.41.344h-.229zm.638 1.481a.637.637 0 01-.377.115h-.23v-.787h.23a.956.956 0 01.377.066.471.471 0 010 .607"
        />
        <path data-name="Path 51148" d="M45.425 10.546h-.262v2.066h1.2v-.262h-.935z" />
        <path
          data-name="Path 51149"
          d="M50.325 11.907a.514.514 0 01-.115.344.663.663 0 01-.344.115h-.082a.371.371 0 01-.328-.409v-1.394h-.262v1.41a.56.56 0 00.148.459.885.885 0 001.049 0 .7.7 0 00.2-.492v-1.427h-.266z"
        />
        <path data-name="Path 51150" d="M54.148 12.611h1.2v-.262h-.9v-.754h.689v-.229h-.689v-.558h.951v-.263h-1.251z" />
        <path
          data-name="Path 51151"
          d="M62.151 10.493h-.378v2.082h.262v-.782l.23.033c.524 0 .786-.23.786-.721 0-.41-.294-.607-.9-.607m.082 1.066h-.2v-.754h.149v-.033c.377 0 .574.115.574.377s-.2.41-.525.41"
        />
        <path
          data-name="Path 51152"
          d="M66.676 10.546a.8.8 0 00-.607.295 1.4 1.4 0 000 1.508.78.78 0 00.607.3.749.749 0 00.64-.3 1.228 1.228 0 00.229-.787 1.4 1.4 0 00-.229-.754.683.683 0 00-.64-.262m.492 1.607a.552.552 0 01-.459.23.4.4 0 01-.378-.23.984.984 0 01-.147-.607.771.771 0 01.115-.557.436.436 0 01.41-.23c.377 0 .607.262.607.787a1.115 1.115 0 01-.148.607"
        />
        <path
          data-name="Path 51153"
          d="M72.005 11.975l-.426-1.426h-.115l-.443 1.426-.426-1.426h-.3l.64 2.115h.115l.459-1.443.492 1.443h.069l.639-2.115h-.262z"
        />
        <path
          data-name="Path 51154"
          d="M75.547 12.611h1.213v-.262h-.919v-.754h.689v-.229h-.689v-.558h.951v-.263h-1.245z"
        />
        <path
          data-name="Path 51155"
          d="M80.86 11.481a.617.617 0 00.115-.344c0-.377-.262-.607-.754-.607h-.525v2.081h.295v-.9h.3l.573.869h.344l-.588-.921a.6.6 0 00.243-.177m-.705.016h-.2v-.672a.869.869 0 01.2-.049.963.963 0 01.377.082.284.284 0 01.115.262.4.4 0 01-.115.295.743.743 0 01-.377.082"
        />
      </g>
      <path
        data-name="Path 52610"
        d="M161.185 38.771v-.543h.968a.403.403 0 100-.806h-1.294v2.053h-.554v-2.613h1.848a.887.887 0 01.976.945.9.9 0 01-.976.964h-.968z"
        fill="#231f20"
      />
      <path
        data-name="Path 52611"
        d="M164.623 39.476a1.276 1.276 0 01-1.283-1.334 1.226 1.226 0 011.283-1.28h.643a1.227 1.227 0 011.294 1.28 1.279 1.279 0 01-1.294 1.334zm.643-.561a.746.746 0 000-1.492h-.643a.746.746 0 000 1.492z"
        fill="#231f20"
      />
      <path
        data-name="Path 52612"
        d="M168.617 38.649l-.361.687a.307.307 0 01-.282.176.316.316 0 01-.289-.176l-1.322-2.474h.665l.965 1.862.343-.618-.64-1.244h.668l.908 1.862.968-1.862h.625l-1.326 2.474a.315.315 0 01-.286.176.305.305 0 01-.286-.176l-.35-.687z"
        fill="#231f20"
      />
      <path
        data-name="Path 52613"
        d="M171.108 39.476v-2.614h2.359v.561h-1.8v1.494h1.808v.561h-2.362zm.879-1.107v-.442h1.379v.442z"
        fill="#231f20"
      />
      <path
        data-name="Path 52614"
        d="M176.143 39.475l-.633-.712h-.804v-.477h1a.391.391 0 00.422-.439.383.383 0 00-.422-.424h-1.333v2.053h-.554v-2.614h1.887a.9.9 0 01.968.971.854.854 0 01-.525.856l.779.787h-.79z"
        fill="#231f20"
      />
      <path
        data-name="Path 52615"
        d="M140.979 35.759V34.3h4.235c.329 0 .528-.157.528-.44s-.2-.464-.528-.464h-2.479a1.686 1.686 0 11.015-3.356h4.051v1.446h-4.053a.365.365 0 00-.39.409.369.369 0 00.39.409h2.466c1.218 0 1.869.527 1.869 1.69a1.674 1.674 0 01-1.869 1.761h-4.235z"
        fill="#231f20"
      />
      <path
        data-name="Path 52616"
        d="M150.401 35.759a2.79 2.79 0 01-2.8-2.916 2.679 2.679 0 012.8-2.8h1.4a2.673 2.673 0 012.811 2.8 2.787 2.787 0 01-2.811 2.916zm1.4-1.454a1.34 1.34 0 001.4-1.391 1.366 1.366 0 00-1.4-1.423h-1.4a1.367 1.367 0 00-1.386 1.423 1.332 1.332 0 001.386 1.386h1.4z"
        fill="#231f20"
      />
      <path data-name="Path 52617" d="M155.261 35.759v-5.714h1.417V34.3h3.461v1.454h-4.878z" fill="#231f20" />
      <path
        data-name="Path 52618"
        d="M166.212 35.759l-.728-1.265h-2.068l.628-1.093h.812l-.8-1.383-2.144 3.741h-1.608l3.124-5.329a.727.727 0 011.355 0l3.124 5.329h-1.7z"
        fill="#231f20"
      />
      <path
        data-name="Path 52619"
        d="M174.579 35.759l-2.136-2.134-2.143 2.134h-1.83l2.889-3.018-2.63-2.774h1.945l1.866 1.9 1.962-1.9h1.822l-2.768 2.731 3 3.062h-1.978z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52620"
        d="M134.863 24a1.421 1.421 0 00-.94.626c-.384.557-3.524 4.985-3.524 4.985l.026.036L139.992 24z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52621"
        d="M43.04 5.646l1.481 2.047a1.691 1.691 0 00.657.354 1.976 1.976 0 001.757-.2 5.075 5.075 0 001.225-1.472C48.344 6.142 52.608 0 52.608 0L43.04 5.646z"
        transform="translate(87.384 24.001)"
        fill="url(#_Supported_brands_svg__a)"
      />
      <path
        data-name="Path 52622"
        d="M125.99 43.283a1.421 1.421 0 00.94-.626c.384-.557 3.524-4.985 3.524-4.985l-.026-.036-9.567 5.647z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52623"
        d="M9.823 52.255l-1.48-2.047a1.691 1.691 0 00-.657-.354 1.976 1.976 0 00-1.757.2 5.075 5.075 0 00-1.229 1.47C4.519 51.759.256 57.9.256 57.9l9.568-5.647z"
        transform="translate(120.605 -14.619)"
        fill="url(#_Supported_brands_svg__c)"
      />
      <path
        data-name="Path 52624"
        d="M.021 0H0l13.087 18.672a1.435 1.435 0 00.974.529h5.126z"
        transform="translate(120.804 24)"
        fill="url(#_Supported_brands_svg__d)"
      />
      <path data-name="Path 52625" d="M126.931 24.615a1.451 1.451 0 00-1.237-.615h-4.869l19.167 19.2z" fill="#f2be19" />
      <g>
        <g
          data-name="Group 17668"
          transform="translate(327.058 32.746)"
          clipPath="url(#_Supported_brands_svg__e)"
          fill="#c5d3d6"
        >
          <path
            data-name="Path 52626"
            d="M17.683.054h-3.815a3.132 3.132 0 00-3.134 3.134V6.3a3.132 3.132 0 003.134 3.134h3.815A3.139 3.139 0 0020.817 6.3V3.188A3.132 3.132 0 0017.683.054M19.004 6.3a1.321 1.321 0 01-1.321 1.321h-3.815A1.321 1.321 0 0112.547 6.3V3.188a1.321 1.321 0 011.321-1.322h3.815a1.322 1.322 0 011.321 1.322z"
          />
          <path
            data-name="Path 52627"
            d="M8.687 3.784H5.351a.978.978 0 00-.981.981v.021a.976.976 0 00.981.97h2.238V7.45H3.922a1.951 1.951 0 01-1.95-1.95V3.986a1.951 1.951 0 011.95-1.95h4.616a.981.981 0 00.981-.991.978.978 0 00-.981-.981H3.922A3.926 3.926 0 000 3.986V5.5a3.926 3.926 0 003.922 3.922h5.639V4.658a.878.878 0 00-.874-.874"
          />
          <path
            data-name="Path 52628"
            d="M62.95 7.536h-3.5a1.973 1.973 0 01-1.965-1.844h5.461a.992.992 0 000-1.983h-5.433a1.973 1.973 0 011.941-1.639h3.5a1.011 1.011 0 00.97-.97 1 1 0 00-.97-1.023h-3.5A3.96 3.96 0 0055.5 4.031v1.522a3.96 3.96 0 003.954 3.954h3.5a.986.986 0 000-1.972"
          />
          <path
            data-name="Path 52629"
            d="M28.843.054h-3.816a3.132 3.132 0 00-3.133 3.134V6.3a3.132 3.132 0 003.134 3.134h3.816A3.132 3.132 0 0031.978 6.3V3.188A3.132 3.132 0 0028.843.054M30.164 6.3a1.321 1.321 0 01-1.321 1.321h-3.816A1.321 1.321 0 0123.706 6.3V3.188a1.321 1.321 0 011.321-1.322h3.816a1.322 1.322 0 011.321 1.322z"
          />
          <path
            data-name="Path 52630"
            d="M38.563.043l-5.212.011v9.39h5.2A3.955 3.955 0 0042.495 5.5V3.983a3.932 3.932 0 00-3.932-3.94M40.525 5.5a1.956 1.956 0 01-1.961 1.961h-3.22V2.026h3.219a1.956 1.956 0 011.962 1.957z"
          />
          <path
            data-name="Path 52631"
            d="M52.985 0h-.032a.924.924 0 00-.916.916v5.311l-1.439-1.495a.935.935 0 00-1.364 1.279l3.038 3.155a.881.881 0 00.554.287.359.359 0 00.106.011.946.946 0 00.97-.927V.916A.91.91 0 0052.985 0"
          />
          <path
            data-name="Path 52632"
            d="M47.261 4.732l-1.438 1.495V.916A.917.917 0 0044.905 0h-.043a.91.91 0 00-.916.916v7.622a.907.907 0 00.663.881.872.872 0 00.317.046.359.359 0 00.106-.011.881.881 0 00.554-.287l3.037-3.155a.918.918 0 00-.041-1.312.93.93 0 00-1.321.032"
          />
        </g>
      </g>
      <g>
        <g
          data-name="Group 17670"
          transform="translate(238.067 26.99)"
          clipPath="url(#_Supported_brands_svg__f)"
          fill="#c5d3d6"
        >
          <path
            data-name="Path 52633"
            d="M8.057 0c.288.04.577.068.863.121a7.4 7.4 0 013.5 1.653c.13.111.252.233.381.346.069.061.072.113 0 .18q-.919.913-1.832 1.83c-.081.081-.127.045-.191-.015a4.51 4.51 0 00-2.542-1.23 4.586 4.586 0 00-4.03 1.351 4.556 4.556 0 00-1.353 3.669 4.692 4.692 0 004.037 4.283 4.638 4.638 0 004.248-1.6c.042-.046.078-.1.115-.147a.107.107 0 00.005-.032c-.054-.043-.119-.022-.178-.023H7.771c-.216 0-.219 0-.219-.216V7.739c0-.15.047-.2.2-.2h7.182c.14 0 .192.041.192.186v7.17c0 .15-.052.2-.2.2q-1.233-.007-2.466 0c-.143 0-.19-.049-.187-.191.008-.426 0-.852 0-1.278 0-.049.026-.116-.028-.142s-.081.036-.117.063A7.409 7.409 0 018.5 15.025a7.377 7.377 0 01-4.606-.892A7.462 7.462 0 01.355 9.841 6.721 6.721 0 01.024 8.15.657.657 0 000 8.044v-1a.727.727 0 00.024-.12 6.135 6.135 0 01.222-1.316A7.574 7.574 0 016.042.146C6.378.076 6.72.048 7.058 0z"
          />
          <path
            data-name="Path 52634"
            d="M35.374 12.736c.029-.088.06-.174.086-.261q.71-2.372 1.416-4.746a.225.225 0 01.26-.194q.679.015 1.358 0a.228.228 0 01.266.2q.649 2.446 1.311 4.888l.051.178c.076-.245.141-.449.2-.655q.664-2.2 1.323-4.4a.25.25 0 01.292-.217c.332.014.666 0 1 0 .2 0 .207.008.15.2l-.858 2.881c-.426 1.427-.855 2.855-1.275 4.285a.221.221 0 01-.253.186q-.787-.008-1.576 0c-.14 0-.2-.048-.233-.185q-.629-2.386-1.269-4.77l-.041-.147a.235.235 0 00-.066.14q-.706 2.364-1.406 4.733c-.049.166-.117.236-.3.231a39.839 39.839 0 00-1.546 0 .208.208 0 01-.237-.183q-.995-3.587-2-7.172c-.055-.2-.054-.2.153-.2h1.529a.188.188 0 01.215.166q.692 2.475 1.394 4.947c.008.029 0 .064.051.088"
          />
          <path
            data-name="Path 52635"
            d="M23.1 11.334a4.314 4.314 0 01.464-2.109 3.179 3.179 0 012.1-1.684 4.985 4.985 0 012.726.02 3.2 3.2 0 012.219 2.1 4.722 4.722 0 01-.037 3.4 3.206 3.206 0 01-2.326 2.044 5.025 5.025 0 01-2.56-.014 3.267 3.267 0 01-2.373-2.3 4.161 4.161 0 01-.213-1.454m2.17.036a3.392 3.392 0 00.034.563 3.152 3.152 0 00.474 1.4 1.491 1.491 0 002.069.375 1.969 1.969 0 00.688-1.085 4.589 4.589 0 00.159-1.8 3.5 3.5 0 00-.4-1.4 1.454 1.454 0 00-1.756-.708 1.481 1.481 0 00-.951.921 4.056 4.056 0 00-.315 1.728"
          />
          <path
            data-name="Path 52636"
            d="M50.535 11.365v1.73a2.276 2.276 0 00.039.4.5.5 0 00.559.431c.28 0 .28 0 .28.277v.717a.149.149 0 01-.118.173 3.311 3.311 0 01-1.682.085 1.239 1.239 0 01-.893-.752c-.074-.17-.106-.181-.242-.057a3.11 3.11 0 01-1.088.712 2.531 2.531 0 01-2.239-.161 2.029 2.029 0 01-.976-1.668 2.515 2.515 0 012.131-2.6 9.02 9.02 0 012.045-.183c.2 0 .208 0 .208-.21v-.577a.927.927 0 00-.883-1 3.99 3.99 0 00-2.049.262 5.053 5.053 0 00-.654.295c-.1.054-.14.038-.139-.081V8.035a.175.175 0 01.141-.189 7.545 7.545 0 012.69-.465 4.38 4.38 0 011.528.262 1.931 1.931 0 011.342 1.936c.005.6 0 1.195 0 1.793zm-1.973 1.074v-.624c0-.114-.046-.158-.159-.155-.239.006-.48-.008-.718.012a2.022 2.022 0 00-1.2.434 1.039 1.039 0 00-.277 1.178 1.015 1.015 0 001.018.574 2.3 2.3 0 001.2-.563.348.348 0 00.136-.311c-.008-.182 0-.364 0-.546"
          />
          <path
            data-name="Path 52637"
            d="M54.648 7.538h2.061c.147 0 .2.041.2.193-.009.3-.008.6 0 .9 0 .147-.042.2-.194.2-.421-.008-.843 0-1.265-.005-.131 0-.169.046-.168.173 0 1.195 0 2.39.005 3.585a2.889 2.889 0 00.064.556.869.869 0 00.855.728 2.266 2.266 0 00.683-.041c.117-.029.165 0 .164.124v.966a.138.138 0 01-.1.152 3.727 3.727 0 01-2.377 0 1.852 1.852 0 01-1.3-1.75c-.076-1.11-.017-2.222-.032-3.334q-.007-.5 0-1c0-.115-.039-.158-.154-.154-.176.007-.354 0-.531 0-.123 0-.163-.047-.162-.166.005-.317.008-.634 0-.951 0-.138.047-.18.182-.179.691.005 1.384 0 2.076 0"
          />
          <path
            data-name="Path 52638"
            d="M58.949 11.09V9.049c0-.219 0-.219-.226-.219-.156 0-.312-.005-.468 0-.123 0-.167-.05-.167-.167 0-.312.005-.623 0-.935 0-.15.055-.195.2-.195h3.574c.187 0 .375.005.562 0 .132 0 .172.05.17.175-.006.317-.007.634 0 .951 0 .13-.048.171-.174.17h-1.249c-.137 0-.18.042-.18.179.005 1.242 0 2.483.005 3.724a1.759 1.759 0 00.058.415.87.87 0 00.873.723 2.4 2.4 0 00.667-.042c.11-.025.154 0 .153.117v.966a.15.15 0 01-.125.166 3.864 3.864 0 01-2.214.036 1.88 1.88 0 01-1.425-1.7c-.075-.735-.017-1.474-.034-2.211v-.109"
          />
          <path
            data-name="Path 52639"
            d="M19.454 8.851a4.013 4.013 0 01.8-.917 2.49 2.49 0 011.8-.553c.127.01.183.062.181.2v1.5c0 .12-.034.157-.157.118a2.167 2.167 0 00-2.564 1 .48.48 0 00-.061.283v4.347c0 .267 0 .267-.268.268h-1.592c-.187 0-.2-.016-.2-.206V7.738c0-.15.051-.2.2-.195q.843.01 1.686 0c.135 0 .184.045.183.18-.006.378 0 .757 0 1.136"
          />
          <path
            data-name="Path 52640"
            d="M47.133 4.048a.563.563 0 01.335-.218.315.315 0 01.319.118c.062.092.1.07.162 0a.366.366 0 01.427-.1c.165.061.19.206.191.358v.669c0 .121-.055.149-.163.149s-.167-.026-.164-.147q.008-.265 0-.53c0-.1.038-.236-.126-.256-.12-.015-.259.13-.264.276q-.007.241 0 .483c0 .107-.015.176-.15.176s-.177-.046-.173-.173a7.977 7.977 0 000-.513c0-.1.032-.224-.12-.247-.105-.016-.265.133-.271.256a6.413 6.413 0 000 .5c0 .106-.011.179-.147.179s-.157-.061-.154-.173V4.03c0-.081-.015-.173.115-.176.107 0 .206 0 .183.19"
          />
          <path
            data-name="Path 52641"
            d="M37.331 4.849a.485.485 0 01-.336.177.414.414 0 01-.374-.186.809.809 0 01.045-.84.45.45 0 01.563-.117.146.146 0 00.177 0c.146-.094.265-.008.266.17 0 .255-.006.509 0 .763.018.579-.32.692-.768.645a1.552 1.552 0 01-.181-.044c-.1-.024-.1-.088-.079-.17.022-.1.08-.068.142-.051a.97.97 0 00.214.037c.27.009.361-.1.328-.379m.014-.47v-.093a.2.2 0 00-.189-.239.219.219 0 00-.246.17.588.588 0 00-.015.365.2.2 0 00.358.109c.108-.086.093-.2.092-.312"
          />
          <path
            data-name="Path 52642"
            d="M17.752 3.982c.041-.032.072-.06.106-.082a.407.407 0 01.6.147.783.783 0 01-.021.775.424.424 0 01-.576.184c-.117-.056-.134-.02-.127.084v.156c0 .18-.031.2-.216.19-.084 0-.126-.038-.125-.122v-1.34c0-.064.024-.114.1-.114.1 0 .226-.043.26.121m.446.458v-.092c-.023-.173-.109-.265-.236-.252a.257.257 0 00-.222.285v.248a.183.183 0 00.152.189.194.194 0 00.239-.094.586.586 0 00.071-.283"
          />
          <path
            data-name="Path 52643"
            d="M61.862 4.32c-.057.186-.12.37-.168.559-.03.118-.094.152-.21.154s-.163-.053-.191-.156c-.077-.285-.161-.569-.236-.855-.013-.05-.07-.137.027-.153s.216-.062.259.087a6.263 6.263 0 00.207.633c.058-.186.12-.373.173-.561.029-.1.061-.175.193-.174s.165.071.191.176c.045.181.1.36.15.553.068-.047.068-.114.085-.167.051-.157.1-.318.145-.476.029-.092.108-.091.18-.076.094.019.048.09.035.137-.087.3-.182.6-.265.9-.032.117-.106.13-.209.134a.16.16 0 01-.183-.144c-.046-.19-.1-.378-.15-.567h-.033"
          />
          <path
            data-name="Path 52644"
            d="M23.849 4.325c-.056.188-.117.375-.167.566-.03.113-.1.14-.2.141a.171.171 0 01-.195-.151c-.074-.286-.16-.569-.236-.856-.014-.052-.08-.143.037-.159.09-.012.207-.05.249.088.054.184.105.369.158.554a.118.118 0 00.051.087c.061-.2.124-.4.178-.6a.167.167 0 01.186-.144.165.165 0 01.184.146 3.249 3.249 0 00.192.578l.13-.425c.023-.074.043-.15.071-.223.035-.091.117-.084.186-.064s.038.086.025.132c-.089.3-.183.606-.269.911-.031.113-.108.118-.2.12s-.157-.024-.181-.125c-.048-.2-.1-.389-.156-.584h-.037"
          />
          <path
            data-name="Path 52645"
            d="M45.039 4.44a.6.6 0 11-1.2 0 .6.6 0 111.2 0m-.852 0a.7.7 0 00.033.229.223.223 0 00.22.163.214.214 0 00.216-.168.713.713 0 000-.457.225.225 0 00-.218-.17.236.236 0 00-.216.174.707.707 0 00-.032.229"
          />
          <path
            data-name="Path 52646"
            d="M58.83 3.827a.574.574 0 01.6.614.612.612 0 11-1.224-.009.573.573 0 01.624-.6m-.268.6a.555.555 0 00.031.217.23.23 0 00.449 0 .628.628 0 00-.018-.47.214.214 0 00-.2-.143.219.219 0 00-.207.14.517.517 0 00-.049.254"
          />
          <path
            data-name="Path 52647"
            d="M20.803 3.827a.564.564 0 01.609.614.608.608 0 11-1.215 0 .571.571 0 01.606-.619m-.249.606v.062c.009.213.087.326.229.335s.238-.081.276-.294a.544.544 0 00.009-.093c0-.224-.112-.4-.258-.408s-.257.149-.257.4"
          />
          <path
            data-name="Path 52648"
            d="M51.54 4.446a.563.563 0 01-.6.61.577.577 0 01-.612-.616.572.572 0 01.612-.613.555.555 0 01.6.619m-.851-.006a.564.564 0 00.045.259.2.2 0 00.192.134.209.209 0 00.216-.124.591.591 0 000-.54.222.222 0 00-.2-.131.226.226 0 00-.2.144.514.514 0 00-.045.258"
          />
          <path
            data-name="Path 52649"
            d="M34.082 4.04a.79.79 0 01.266-.188.332.332 0 01.46.259 4.834 4.834 0 01.012.823c0 .119-.1.091-.177.1s-.146-.01-.145-.114v-.545c0-.107.032-.246-.132-.272-.119-.018-.276.14-.282.281a5.955 5.955 0 000 .5c0 .115-.045.154-.157.154s-.174-.028-.172-.157c.005-.269 0-.539 0-.81 0-.079-.04-.194.1-.2.109-.006.25-.05.226.176"
          />
          <path
            data-name="Path 52650"
            d="M26.99 5.061a.621.621 0 01-.576-.277.674.674 0 010-.7.54.54 0 01.635-.234.514.514 0 01.322.562c-.009.084-.06.085-.119.085h-.437c-.146 0-.164.052-.1.173a.3.3 0 00.243.148.689.689 0 00.262-.024c.057-.016.137-.07.158.045a.13.13 0 01-.114.17l-.276.048m-.136-.734c.072-.017.184.037.182-.1 0-.107-.058-.187-.167-.183a.189.189 0 00-.185.194c-.008.131.106.073.17.092"
          />
          <path
            data-name="Path 52651"
            d="M41.988 5.055c-.276 0-.415-.127-.427-.378-.006-.13 0-.26 0-.39 0-.094.033-.2-.1-.249-.013 0-.019-.035-.023-.055-.027-.152.249-.393.4-.345a.061.061 0 01.051.067c-.013.129.056.162.169.157.058 0 .08.038.081.093s-.02.108-.086.1c-.153-.013-.169.075-.161.194.006.1 0 .2 0 .3 0 .143.015.278.208.282.052 0 .068.041.068.087s0 .087-.05.1a1.26 1.26 0 01-.124.033"
          />
          <path
            data-name="Path 52652"
            d="M29.465 4.02a.528.528 0 01.268-.18c.079-.018.154-.014.153.093 0 .078.048.179-.114.177-.216 0-.315.114-.315.334 0 .15-.005.3 0 .451.005.131-.074.137-.172.137s-.16-.017-.158-.135v-.856c0-.079-.023-.178.11-.179.106 0 .229-.033.226.16"
          />
          <path
            data-name="Path 52653"
            d="M56.157 4.021a.515.515 0 01.279-.186c.087-.017.148.008.145.107 0 .074.036.167-.112.167-.225 0-.314.105-.315.329 0 .14-.006.28 0 .42s-.038.177-.17.175-.163-.049-.16-.167c.005-.28 0-.56 0-.841 0-.078-.01-.166.11-.163.1 0 .225-.039.22.161"
          />
          <path
            data-name="Path 52654"
            d="M53.666 4.016a.514.514 0 01.285-.183c.091-.017.143.02.137.115 0 .069.035.156-.1.159-.247.005-.327.1-.328.351v.435c0 .145-.077.135-.173.137s-.159-.019-.157-.136v-.856c0-.079-.022-.177.111-.178.106 0 .229-.033.225.157"
          />
          <path
            data-name="Path 52655"
            d="M31.952 4.459v.45c0 .127-.087.117-.172.119s-.156-.007-.155-.12v-.932c0-.114.071-.12.156-.119s.173-.006.171.12v.481"
          />
          <path
            data-name="Path 52656"
            d="M31.807 3.673c-.109.006-.182-.008-.183-.137s.051-.153.16-.15.172.009.172.137-.049.161-.149.149"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgSupportedBrands
