import * as React from "react"

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 48" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 49"
        d="M10.198 19v-5h4v5a1 1 0 001 1h3a1 1 0 001-1v-7h1.7a.5.5 0 00.33-.87l-8.36-7.53a1.008 1.008 0 00-1.34 0l-8.36 7.53a.5.5 0 00.33.87h1.7v7a1 1 0 001 1h3a1 1 0 001-1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHome
