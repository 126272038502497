// ! MANUAL ENTRY, DO NOT OVERWRITE !
function SvgInfoOutlined(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.02768 3.05499C6.20349 2.26934 7.58587 1.85 9 1.85C9.93896 1.85 10.8687 2.03494 11.7362 2.39426C12.6037 2.75358 13.3919 3.28025 14.0558 3.94419C14.7198 4.60813 15.2464 5.39634 15.6057 6.26381C15.9651 7.13129 16.15 8.06105 16.15 9C16.15 10.4141 15.7307 11.7965 14.945 12.9723C14.1594 14.1481 13.0427 15.0646 11.7362 15.6057C10.4297 16.1469 8.99207 16.2885 7.60511 16.0126C6.21814 15.7367 4.94414 15.0558 3.94419 14.0558C2.94424 13.0559 2.26327 11.7819 1.98739 10.3949C1.7115 9.00793 1.8531 7.5703 2.39426 6.26381C2.93543 4.95732 3.85186 3.84064 5.02768 3.05499Z"
        stroke="currentColor"
        strokeWidth="0.7"
        fill="none"
      />
      <rect x="8" y="8" width="2" height="5" rx="1" fill="currentColor" />
      <rect x="8" y="5" width="2" height="2" fill="currentColor" />
    </svg>
  )
}

export default SvgInfoOutlined
