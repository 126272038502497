import { Flex } from "../../components/flex/Flex"
import InfoBox from "../../components/info/InfoBox"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import Text from "../../components/Text"
import { useTranslation } from "react-i18next"

export function NonContinuousMeasurementDisclaimer() {
  const { t } = useTranslation(["overview"])

  return (
    <Flex align="center" justify="end" style={{ marginRight: theme.space.s4.value }}>
      <Text type="labelsAlt">{t("overview:c_measurement_type_disclaimer")}</Text>
      <Spacer size={theme.space.s2} />
      <InfoBox>
        <Flex style={{ maxWidth: theme.space.s90.value }}>
          <Text type="textsSmall">{t("overview:c_measurement_type_disclaimer_content")}</Text>
        </Flex>
      </InfoBox>
    </Flex>
  )
}
