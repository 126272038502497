import { ComponentProps, ForwardedRef, forwardRef } from "react"
import { styled, theme } from "../../styles/stitches.config"
import { ObjectValues } from "../../utils/types"

const SSpacer = styled("span", {
  display: "block",
})

type Props = ComponentProps<typeof SSpacer> & {
  size: ObjectValues<typeof theme.space>
}

function SpacerInner(props: Props, ref: ForwardedRef<HTMLSpanElement>) {
  const { size, css, ...rest } = props
  return <SSpacer ref={ref} css={{ width: size, minWidth: size, height: size, minHeight: size, ...css }} {...rest} />
}

export const Spacer = forwardRef(SpacerInner)
