import { ColorType, CSSProp, styled, theme } from "../../../styles/stitches.config"
import { ObjectValues } from "../../../utils/types"

const SCircle = styled("span", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  minWidth: theme.sizes.s2,
  minHeight: theme.sizes.s2,
  flexShrink: 0,
})

interface Props {
  size?: ObjectValues<typeof theme.sizes>
  color?: ColorType
  opacity?: number
  borderColor?: ColorType
}

export default function Circle({ size = theme.sizes.s3, color = "primary", opacity, borderColor }: Props) {
  const borderStyles: CSSProp = borderColor ? { border: `2px solid ${theme.colors[borderColor].value}` } : {}
  return <SCircle css={{ width: size, height: size, backgroundColor: theme.colors[color], opacity, ...borderStyles }} />
}
