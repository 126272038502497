import { CSSProp, theme } from "../../../../styles/stitches.config"
import Separator from "../../../../components/Separator"

const styles: CSSProp = {
  marginInline: theme.space.s7,
  display: "none",
  "@md": {
    display: "initial",
  },
}

const InfoCardSeparator = () => {
  return <Separator decorative orientation="vertical" css={styles} />
}

export default InfoCardSeparator
