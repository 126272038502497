import * as React from "react"

function SvgRequests(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_Requests_svg__a">
          <path data-name="Rectangle 392" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 707" clipPath="url(#_Requests_svg__a)" fill="currentColor">
        <path
          data-name="Path 275"
          d="M17.439 3.849h-1.11a1.949 1.949 0 00-1.948-1.939H9.619a1.949 1.949 0 00-1.947 1.939H6.567a1.95 1.95 0 00-1.95 1.95v14.34a1.95 1.95 0 001.95 1.95h10.872a1.95 1.95 0 001.95-1.95V5.801a1.95 1.95 0 00-1.95-1.952m-7.82 1.952v-1.94h4.761v1.946H9.619zm7.82 14.338H6.567V5.801h1.1v.006a1.95 1.95 0 001.95 1.95h4.761a1.95 1.95 0 001.95-1.95v-.006h1.1z"
        />
        <path
          data-name="Path 276"
          d="M14.505 11.198l-3.275 3.276-1.735-1.738a.975.975 0 00-1.378 1.38l2.425 2.427a.976.976 0 001.378 0l3.963-3.965a.975.975 0 10-1.378-1.38"
        />
      </g>
    </svg>
  )
}

export default SvgRequests
