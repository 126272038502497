import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getCurrency } from "../../i18n/config"
import { getValueFormatter } from "../../sections/overview/overview.utils"
import { formatDay, formatHour, formatYear, getLocalizedMonth } from "../../utils/date"
import { formatEnergy } from "../../utils/format"
import { OverviewChartItem } from "./OverviewChartPlain"
import { OverviewChartType, EnergyUnit, OverviewGroup } from "@nano-portal/shared"

interface UseChartConfigParams {
  chartType: OverviewChartType
  data: OverviewChartItem[]
  type: "production" | "consumption"
}

export const useOverviewChartConfig = ({ chartType, data, type }: UseChartConfigParams) => {
  const { t } = useTranslation(["date_picker", "overview"])
  const formatAsMwh = chartType === OverviewChartType.ConsumptionChart && data.some((d) => d.value > 1000)

  const energyUnit = formatAsMwh ? EnergyUnit.MWh : EnergyUnit.kWh
  const valueFormatter = formatAsMwh ? (value: number) => formatEnergy(value, energyUnit) : getValueFormatter(chartType)

  const groups = useMemo(
    () => [
      {
        value: OverviewGroup.Years,
        title: t("date_picker:date_years"),
        labelFormatter: (date: Date) => formatYear(date, "yyyy"),
      },
      {
        value: OverviewGroup.Months,
        title: t("date_picker:date_months"),
        labelFormatter: getLocalizedMonth,
      },
      {
        value: OverviewGroup.Days,
        title: t("date_picker:date_days"),
        labelFormatter: formatDay,
      },
      {
        value: OverviewGroup.Hours,
        title: t("date_picker:date_hours"),
        labelFormatter: formatHour,
      },
    ],
    [t]
  )

  const chartTypes = useMemo(
    () => [
      {
        value: OverviewChartType.TotalPaymentChart,
        title: t("overview:chart_type_total_payment"),
        unitLabel: getCurrency().symbol,
      },
      {
        value: OverviewChartType.ConsumptionChart,
        title: type === "production" ? t("overview:chart_type_production") : t("overview:chart_type_consumption"),
        unitLabel: energyUnit,
      },
      {
        value: OverviewChartType.PricePerUnitChart,
        title: t("overview:chart_type_price_per_unit"),
        unitLabel: `${getCurrency().symbol}/${energyUnit}`,
      },
    ],
    [t, energyUnit, type]
  )

  const [unitLabel, typeTitle] = useMemo(() => {
    const type = chartTypes.find((conf) => conf.value === chartType)
    return [type?.unitLabel || "", type?.title || ""] as const
  }, [chartTypes, chartType])

  return { unitLabel, typeTitle, groups, chartTypes, formatAsMwh, valueFormatter }
}
