import * as React from "react"

function SvgSolaxActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 56.129 19.283"
      {...props}
    >
      <defs>
        <linearGradient id="_Solax_active_svg__a" y1={0.5} x2={1} y2={0.5} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.303} stopColor="#f2be19" />
          <stop offset={0.698} stopColor="#ed1c24" />
          <stop offset={0.808} stopColor="#b81015" />
          <stop offset={0.937} stopColor="#7f0406" />
          <stop offset={1} stopColor="#690000" />
        </linearGradient>
        <linearGradient
          id="_Solax_active_svg__b"
          x1={1}
          y1={0.169}
          x2={0.158}
          y2={0.775}
          xlinkHref="#_Solax_active_svg__a"
        />
        <linearGradient
          id="_Solax_active_svg__c"
          x1={0.781}
          y1={0.92}
          x2={0.112}
          y2={0.177}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0.182} stopColor="#f2be19" />
          <stop offset={0.578} stopColor="#ed1c24" />
          <stop offset={0.732} stopColor="#b81015" />
          <stop offset={0.913} stopColor="#7f0406" />
          <stop offset={1} stopColor="#690000" />
        </linearGradient>
      </defs>
      <path
        data-name="Path 52610"
        d="M40.381 14.771v-.543h.968a.403.403 0 100-.806h-1.294v2.053h-.554v-2.613h1.848a.887.887 0 01.976.945.9.9 0 01-.976.964h-.968z"
        fill="#231f20"
      />
      <path
        data-name="Path 52611"
        d="M43.819 15.476a1.276 1.276 0 01-1.283-1.334 1.226 1.226 0 011.283-1.28h.643a1.227 1.227 0 011.294 1.28 1.279 1.279 0 01-1.294 1.334zm.643-.561a.746.746 0 000-1.492h-.643a.746.746 0 000 1.492z"
        fill="#231f20"
      />
      <path
        data-name="Path 52612"
        d="M47.814 14.649l-.361.687a.307.307 0 01-.282.176.316.316 0 01-.289-.176l-1.322-2.474h.665l.965 1.862.343-.618-.64-1.244h.668l.908 1.862.968-1.862h.625l-1.326 2.474a.315.315 0 01-.286.176.305.305 0 01-.286-.176l-.35-.687z"
        fill="#231f20"
      />
      <path
        data-name="Path 52613"
        d="M50.304 15.476v-2.614h2.359v.561h-1.8v1.494h1.808v.561h-2.362zm.879-1.107v-.442h1.379v.442z"
        fill="#231f20"
      />
      <path
        data-name="Path 52614"
        d="M55.339 15.475l-.633-.712h-.804v-.477h1a.391.391 0 00.422-.439.383.383 0 00-.422-.424h-1.333v2.053h-.554v-2.614h1.887a.9.9 0 01.968.971.854.854 0 01-.525.856l.779.787h-.79z"
        fill="#231f20"
      />
      <path
        data-name="Path 52615"
        d="M20.174 11.759V10.3h4.235c.329 0 .528-.157.528-.44s-.2-.464-.528-.464H21.93a1.686 1.686 0 11.015-3.356h4.051v1.446h-4.053a.365.365 0 00-.39.409.369.369 0 00.39.409h2.466c1.218 0 1.869.527 1.869 1.69a1.674 1.674 0 01-1.869 1.761h-4.235z"
        fill="#231f20"
      />
      <path
        data-name="Path 52616"
        d="M29.597 11.759a2.79 2.79 0 01-2.8-2.916 2.679 2.679 0 012.8-2.8h1.4a2.673 2.673 0 012.811 2.8 2.787 2.787 0 01-2.811 2.916zm1.4-1.454a1.34 1.34 0 001.4-1.391 1.366 1.366 0 00-1.4-1.423h-1.4a1.367 1.367 0 00-1.386 1.423 1.332 1.332 0 001.386 1.386h1.4z"
        fill="#231f20"
      />
      <path data-name="Path 52617" d="M34.457 11.759V6.045h1.417V10.3h3.461v1.454h-4.878z" fill="#231f20" />
      <path
        data-name="Path 52618"
        d="M45.408 11.759l-.728-1.265h-2.068l.628-1.093h.812l-.8-1.383-2.144 3.741H39.5l3.124-5.329a.727.727 0 011.355 0l3.124 5.329h-1.7z"
        fill="#231f20"
      />
      <path
        data-name="Path 52619"
        d="M53.774 11.758l-2.136-2.134-2.143 2.134h-1.83l2.889-3.018-2.63-2.774h1.945l1.866 1.9 1.962-1.9h1.822l-2.768 2.731 3 3.062h-1.978z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52620"
        d="M14.059 0a1.421 1.421 0 00-.94.626c-.384.557-3.524 4.985-3.524 4.985l.026.036L19.188 0z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52621"
        d="M43.04 5.646l1.481 2.047a1.691 1.691 0 00.657.354 1.976 1.976 0 001.757-.2 5.075 5.075 0 001.225-1.472C48.344 6.142 52.608 0 52.608 0L43.04 5.646z"
        transform="translate(-33.42 .001)"
        fill="url(#_Solax_active_svg__a)"
      />
      <path
        data-name="Path 52622"
        d="M5.186 19.283a1.421 1.421 0 00.94-.626c.384-.557 3.524-4.985 3.524-4.985l-.026-.036-9.567 5.647z"
        fill="#f2be19"
      />
      <path
        data-name="Path 52623"
        d="M9.823 52.255l-1.48-2.047a1.691 1.691 0 00-.657-.354 1.976 1.976 0 00-1.757.2 5.075 5.075 0 00-1.229 1.47C4.519 51.759.256 57.9.256 57.9l9.568-5.647z"
        transform="translate(-.199 -38.619)"
        fill="url(#_Solax_active_svg__b)"
      />
      <path
        data-name="Path 52624"
        d="M.021 0H0l13.087 18.672a1.435 1.435 0 00.974.529h5.126z"
        fill="url(#_Solax_active_svg__c)"
      />
      <path data-name="Path 52625" d="M6.127.615A1.451 1.451 0 004.89 0H.021l19.167 19.2z" fill="#f2be19" />
    </svg>
  )
}

export default SvgSolaxActive
