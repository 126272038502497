import { ComponentProps, useMemo } from "react"
import { useTranslation } from "react-i18next"
import InfoCard from "../components/infoCard/InfoCard"

export interface TechnicalParamsData {
  distributor: string
  distributionRate?: string | null
  circuitBreaker?: string | null
  measurementType?: string
}

const useTechnicalParamsCellData = ({
  distributor,
  distributionRate,
  circuitBreaker,
  measurementType,
}: TechnicalParamsData) => {
  const { t } = useTranslation("delivery_point")

  return useMemo(() => {
    return [
      {
        label: t("technical_params_distributor"),
        value: distributor,
      },
      {
        label: t("technical_params_dist_rate"),
        value: distributionRate,
      },
      {
        label: t("technical_params_circuit_breaker"),
        value: circuitBreaker,
      },
      {
        label: t("technical_params_measurement_type"),
        value: measurementType,
      },
    ] as ComponentProps<typeof InfoCard>["data"]
  }, [t, distributionRate, distributor, circuitBreaker, measurementType])
}

export default useTechnicalParamsCellData
