import { styled } from "../../../../styles/stitches.config"
import * as Dropdown from "@radix-ui/react-dropdown-menu"

export default styled(Dropdown.Group, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "stretch",
  flex: 1,
})
