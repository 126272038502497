import * as React from "react"

function SvgProductionAndConsumption(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <defs>
        <clipPath id="_ProductionAndConsumption_svg__a">
          <path data-name="Rectangle 17668" fill="none" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17792">
        <path data-name="Path 60505" d="M57.966 42.847l-.011 1.86-25.876 15.035.01-1.86z" fill="#d0dadd" />
        <g data-name="Group 17773">
          <g data-name="Group 17772" clipPath="url(#_ProductionAndConsumption_svg__a)">
            <path
              data-name="Path 60506"
              d="M32.08 59.849a.107.107 0 01-.107-.107l.011-1.86a.107.107 0 01.053-.092l25.871-15.035a.107.107 0 01.16.093l-.011 1.86a.107.107 0 01-.053.092L32.133 59.835a.106.106 0 01-.054.015m.117-1.905l-.009 1.613 25.657-14.91.009-1.613z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60507" d="M32.09 57.882l-.011 1.86-26.04-15.034.011-1.86z" fill="#d0dadd" />
            <path
              data-name="Path 60508"
              d="M32.079 59.853a.107.107 0 01-.053-.014L5.986 44.805a.107.107 0 01-.053-.093l.01-1.86a.107.107 0 01.16-.092l26.041 15.034a.107.107 0 01.053.093l-.011 1.86a.107.107 0 01-.107.106m-25.933-15.2l25.828 14.9.009-1.615L6.155 43.032z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60509" d="M57.961 42.847L32.089 57.882 6.049 42.848 31.92 27.813z" fill="#d0dadd" />
            <path
              data-name="Path 60510"
              d="M32.089 57.989a.107.107 0 01-.053-.014L5.995 42.941a.107.107 0 010-.185l25.871-15.035a.106.106 0 01.107 0L58.014 42.76a.107.107 0 010 .185l-25.871 15.03a.106.106 0 01-.054.015M6.262 42.848L32.089 57.76l25.658-14.911L31.92 27.937z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 60511" d="M31.829 10.518l-.009 21.876-19.855 11.6.009-21.8z" fill="#fff" />
        <g data-name="Group 17775">
          <g data-name="Group 17774" clipPath="url(#_ProductionAndConsumption_svg__a)">
            <path
              data-name="Path 60512"
              d="M11.965 44.103a.107.107 0 01-.107-.107l.009-21.8a.107.107 0 01.053-.092l19.855-11.677a.107.107 0 01.161.092l-.009 21.876a.107.107 0 01-.053.092l-19.855 11.6a.106.106 0 01-.054.015m.116-21.841l-.009 21.548 19.641-11.477.009-21.628z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60513" d="M31.82 10.488l.009 21.876 19.965 11.663-.009-21.876z" fill="#fff" />
            <path
              data-name="Path 60514"
              d="M51.795 44.135a.107.107 0 01-.054-.015L31.776 32.457a.107.107 0 01-.053-.092l-.009-21.877a.107.107 0 01.161-.092L51.84 22.059a.107.107 0 01.053.092l.009 21.876a.107.107 0 01-.107.107M31.937 32.303l19.751 11.538-.009-21.629-19.75-11.538z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 60515" d="M19.647 31.017l5.457-3.151v-9.437l-5.465 3.135z" fill="#d0dadd" />
        <g data-name="Group 17777">
          <g data-name="Group 17776" clipPath="url(#_ProductionAndConsumption_svg__a)">
            <path
              data-name="Path 60516"
              d="M19.647 31.123a.107.107 0 01-.107-.107l-.009-9.452a.107.107 0 01.053-.093l5.462-3.135a.107.107 0 01.16.093v9.437a.107.107 0 01-.053.092l-5.457 3.151a.106.106 0 01-.053.014m.1-9.5l.005 9.206 5.243-3.027v-9.191z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60517" d="M25.104 27.866v-9.445l-.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60518"
              d="M24.195 28.497a.107.107 0 01-.107-.107v-9.45a.107.107 0 01.054-.093l.909-.519a.107.107 0 01.16.093v9.445a.107.107 0 01-.053.092l-.91.524a.107.107 0 01-.053.014m.107-9.494v9.2l.7-.4v-9.2zm.8 8.863z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60519" d="M20.561 32.066l5.453-3.151v-.525l-5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 60520"
              d="M20.56 32.173a.107.107 0 01-.107-.107v-.527a.106.106 0 01.053-.092l5.453-3.149a.107.107 0 01.16.092v.525a.106.106 0 01-.053.092l-5.453 3.151a.107.107 0 01-.053.014m.107-.572v.281l5.24-3.028v-.278z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60521" d="M20.559 32.066v-.527l-.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60522"
              d="M20.56 32.173a.106.106 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-.526a.107.107 0 01.16-.093l.912.523a.107.107 0 01.054.093v.527a.107.107 0 01-.107.107m-.8-.693l.7.4V31.6l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60523" d="M20.56 31.539l5.453-3.149-1.818-1.05-4.556 2.631.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60524"
              d="M20.559 31.646a.106.106 0 01-.053-.014l-.912-.523a.107.107 0 01-.054-.092l-.008-1.046a.107.107 0 01.053-.093l4.553-2.63a.107.107 0 01.107 0l1.818 1.05a.107.107 0 010 .185l-5.453 3.149a.107.107 0 01-.053.014m-.806-.692l.806.462 5.24-3.026-1.6-.926-4.446 2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1073" fill="#fff" d="M22.006 20.235v8.399" />
            <path
              data-name="Path 60525"
              d="M22.006 28.741a.107.107 0 01-.107-.107v-8.4a.107.107 0 11.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1074" fill="#ffc200" d="M24.194 23.155l-4.552 2.613" />
            <path
              data-name="Path 60526"
              d="M19.642 25.875a.107.107 0 01-.053-.2l4.552-2.613a.107.107 0 01.106.185l-4.552 2.613a.105.105 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 60527" d="M32.201 23.882l-5.23-3.02 4.838-2.793 5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60528"
              d="M32.203 23.988a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 010-.185l4.838-2.793a.107.107 0 01.107 0l5.23 3.02a.107.107 0 010 .185l-4.838 2.793a.106.106 0 01-.053.014m-5.017-3.126l5.017 2.9 4.624-2.67-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60529" d="M32.201 38.537l-5.23-3.02V20.862l5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60530"
              d="M32.203 38.645a.106.106 0 01-.053-.014l-5.23-3.02a.106.106 0 01-.053-.092V20.863a.107.107 0 01.16-.092l5.23 3.02a.107.107 0 01.053.092v14.656a.107.107 0 01-.107.107m-5.123-3.188l5.017 2.9V23.944l-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60531" d="M37.037 35.744l-4.835 2.793V23.882l4.838-2.793z" fill="#ffc200" />
            <path
              data-name="Path 60532"
              d="M32.203 38.644a.107.107 0 01-.107-.107V23.882a.107.107 0 01.053-.092l4.838-2.793a.107.107 0 01.16.092v14.656a.107.107 0 01-.053.092l-4.838 2.793a.106.106 0 01-.053.014m.107-14.7v14.409l4.624-2.67v-14.41zm4.731 11.8z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1075" fill="#ffc200" d="M26.972 30.542l5.23 3.02" />
            <path
              data-name="Path 60533"
              d="M32.202 33.668a.107.107 0 01-.053-.014l-5.23-3.02a.107.107 0 11.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1076" fill="#ffc200" d="M27.467 20.574l5.23 3.02" />
            <path
              data-name="Path 60534"
              d="M32.696 23.7a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 01.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Path 60535" d="M29.907 33.419l.35-.2v-.372l-.35.2z" fill="#fff" />
            <path
              data-name="Path 60536"
              d="M29.907 33.472a.053.053 0 01-.053-.054v-.372a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.372a.054.054 0 01-.027.046l-.35.2a.054.054 0 01-.027.007m.054-.395v.249l.243-.141v-.248z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60537"
              d="M30.057 32.727l-.16.093-.811-.472.157-.091-.194-.112-.35.2 1.208.7.35-.2z"
              fill="#fff"
            />
            <path
              data-name="Path 60538"
              d="M29.908 33.1a.054.054 0 01-.027-.007l-1.209-.7a.053.053 0 010-.092l.35-.2a.053.053 0 01.053 0l.194.112a.053.053 0 010 .092l-.078.045.7.41.133-.077a.053.053 0 01.053 0l.2.116a.053.053 0 010 .092l-.35.2a.054.054 0 01-.027.007m-1.1-.751l1.1.636.243-.141-.094-.054-.133.078a.054.054 0 01-.054 0l-.811-.472a.053.053 0 010-.092l.077-.045-.087-.05z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60539" d="M28.7 32.349v.372l1.208.7v-.372z" fill="#fff" />
            <path
              data-name="Path 60540"
              d="M29.903 33.472a.053.053 0 01-.027-.007l-1.208-.7a.054.054 0 01-.027-.046v-.372a.053.053 0 01.08-.046l1.209.7a.054.054 0 01.027.046v.372a.053.053 0 01-.027.046.054.054 0 01-.027.007m-1.155-.782l1.1.636v-.249l-1.1-.636z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1077" fill="#fff" d="M29.243 32.258v.183" />
            <path
              data-name="Path 60541"
              d="M29.245 32.493a.053.053 0 01-.053-.053v-.183a.054.054 0 01.107 0v.183a.053.053 0 01-.053.053"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60542"
              d="M31.617 29.738l.008-2.164.127-.074v-.334l-.35.2-.008 2.779.35-.2v-.279z"
              fill="#fff"
            />
            <path
              data-name="Path 60543"
              d="M31.395 30.203a.053.053 0 01-.027-.007.053.053 0 01-.027-.046l.008-2.779a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.333a.053.053 0 01-.027.046l-.1.059-.007 2.04.048-.028a.053.053 0 01.08.046v.283a.053.053 0 01-.027.046l-.35.2a.053.053 0 01-.027.007m.061-2.8l-.007 2.655.243-.141v-.159l-.048.028a.053.053 0 01-.08-.046l.008-2.164a.053.053 0 01.027-.046l.1-.059v-.209z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60544" d="M31.403 27.371l.35-.2-.36-.208-.35.2z" fill="#fff" />
            <path
              data-name="Path 60545"
              d="M31.403 27.425a.053.053 0 01-.027-.007l-.36-.208a.053.053 0 010-.092l.35-.2a.053.053 0 01.053 0l.36.208a.053.053 0 010 .092l-.35.2a.053.053 0 01-.027.007m-.254-.261l.253.146.243-.142-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60546" d="M31.043 27.163l-.008 2.779.36.208.008-2.779z" fill="#fff" />
            <path
              data-name="Path 60547"
              d="M31.395 30.203a.054.054 0 01-.027-.007l-.36-.208a.054.054 0 01-.027-.046l.008-2.779a.053.053 0 01.08-.046l.36.208a.054.054 0 01.027.046l-.006 2.779a.053.053 0 01-.053.053m-.306-.292l.253.146.007-2.656-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1078" fill="#fff" d="M31.617 29.589l.129.074" />
            <path
              data-name="Path 60548"
              d="M31.746 29.717a.053.053 0 01-.027-.007l-.129-.074a.053.053 0 01.053-.092l.129.074a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path data-name="Line 1079" fill="#ffc200" d="M32.697 38.249V23.593" />
            <path
              data-name="Path 60549"
              d="M32.697 38.356a.107.107 0 01-.107-.107V23.594a.107.107 0 11.214 0V38.25a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1080" fill="#ffc200" d="M32.202 33.562l.495-.286" />
            <path
              data-name="Path 60550"
              d="M32.203 33.669a.107.107 0 01-.053-.2l.495-.286a.107.107 0 01.107.185l-.495.286a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60551"
              d="M40.003 42.518h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60552"
              d="M40.003 42.624h-.005a.339.339 0 01-.339-.338v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.338m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60553"
              d="M43.963 40.281h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60554"
              d="M43.963 40.388h-.005a.339.339 0 01-.339-.339v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60555"
              d="M32.966 38.507h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60556"
              d="M32.966 38.613h-.005a.339.339 0 01-.339-.339v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path data-name="Path 60557" d="M44.318 39.518l-4.356 2.515v-6.621l4.356-2.515z" fill="#fff" />
            <path
              data-name="Path 60558"
              d="M39.963 42.14a.107.107 0 01-.107-.107v-6.621a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v6.621a.107.107 0 01-.053.092l-4.356 2.515a.107.107 0 01-.053.014m.107-6.667v6.375l4.142-2.392V33.08zm4.249 4.045z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60559" d="M39.96 42.033l-3.616-2.1v-6.621l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60560"
              d="M39.963 42.14a.107.107 0 01-.054-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.621a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.62a.107.107 0 01-.107.107m-3.509-2.266l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60561"
              d="M37.555 35.236a.546.546 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.554.554 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path data-name="Path 60562" d="M36.338 39.928l-3.616-2.1v-6.621l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60563"
              d="M36.337 40.035a.106.106 0 01-.053-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.621a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.621a.107.107 0 01-.107.107m-3.509-2.266l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1081" fill="#fff" d="M32.729 33.313l3.617 2.089" />
            <path
              data-name="Path 60564"
              d="M36.346 35.509a.106.106 0 01-.053-.014l-3.617-2.089a.107.107 0 11.107-.185L36.4 35.31a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1082" fill="#fff" d="M32.729 35.652l3.617 2.089" />
            <path
              data-name="Path 60565"
              d="M36.346 37.848a.106.106 0 01-.053-.014l-3.617-2.089a.107.107 0 01.107-.185l3.617 2.089a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60566"
              d="M34.694 33.611a.546.546 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.309.178a.336.336 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.554.554 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60567"
              d="M34.694 35.781a.547.547 0 01-.273-.073l-.31-.178a.263.263 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.335.335 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.555.555 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60568"
              d="M34.694 37.951a.547.547 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.049.049 0 00.025.043l.309.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.554.554 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path data-name="Path 60569" d="M44.983 38.379l-4.709-2.719 4.356-2.515 4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60570"
              d="M44.983 38.485a.106.106 0 01-.053-.014l-4.709-2.719a.107.107 0 010-.185l4.356-2.515a.107.107 0 01.107 0l4.709 2.719a.107.107 0 010 .185l-4.356 2.515a.105.105 0 01-.053.014m-4.5-2.825l4.5 2.6 4.142-2.392-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60571" d="M44.983 45.379l-4.709-2.719v-7.216l4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60572"
              d="M44.983 45.486a.107.107 0 01-.053-.014l-4.709-2.719a.106.106 0 01-.053-.092v-7.224a.107.107 0 01.16-.092l4.709 2.719a.107.107 0 01.053.093v7.22a.106.106 0 01-.107.107m-4.6-2.887l4.5 2.6v-6.974l-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60573" d="M49.339 42.864l-4.356 2.515v-7l4.356-2.515z" fill="#ffc200" />
            <path
              data-name="Path 60574"
              d="M44.98 45.486a.107.107 0 01-.107-.107v-7a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v7a.107.107 0 01-.053.092l-4.356 2.515a.106.106 0 01-.053.014m.107-7.045v6.754l4.142-2.392v-6.754zm4.249 4.424z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60575"
              d="M42.707 38.72a1.352 1.352 0 00-1.309-.16l-.337.19a1.311 1.311 0 011.271.145 4.035 4.035 0 011.831 3.159 1.328 1.328 0 01-.5 1.157l.464-.254a1.239 1.239 0 00.4-1.023 4.117 4.117 0 00-1.814-3.214"
              fill="#ffc200"
            />
            <path
              data-name="Path 60576"
              d="M43.659 43.319a.107.107 0 01-.059-.2 1.233 1.233 0 00.456-1.068 3.923 3.923 0 00-1.778-3.067 1.213 1.213 0 00-1.167-.143.107.107 0 01-.1-.188l.336-.19a1.454 1.454 0 011.415.16 4.2 4.2 0 011.867 3.306 1.318 1.318 0 01-.44 1.108l-.013.009-.464.254a.107.107 0 01-.051.013m-1.892-4.756a2 2 0 01.618.241 4.16 4.16 0 011.885 3.251 1.831 1.831 0 01-.154.768 1.19 1.19 0 00.3-.888 4.028 4.028 0 00-1.761-3.121 1.6 1.6 0 00-.887-.25"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60577"
              d="M44.163 42.056c0 1.161-.82 1.628-1.831 1.044a4.036 4.036 0 01-1.831-3.159c0-1.161.82-1.628 1.831-1.044a4.036 4.036 0 011.831 3.159"
              fill="#ffc200"
            />
            <path
              data-name="Path 60578"
              d="M43.153 43.459a1.779 1.779 0 01-.876-.267 4.16 4.16 0 01-1.885-3.251 1.375 1.375 0 01.586-1.266 1.392 1.392 0 011.406.13 4.16 4.16 0 011.885 3.251 1.375 1.375 0 01-.586 1.266 1.047 1.047 0 01-.53.137m-1.644-4.708a.837.837 0 00-.424.108 1.175 1.175 0 00-.479 1.082 3.923 3.923 0 001.778 3.067 1.193 1.193 0 001.192.13 1.175 1.175 0 00.479-1.082 3.923 3.923 0 00-1.778-3.067 1.569 1.569 0 00-.768-.238"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60579"
              d="M43.68 41.777c0 .855-.6 1.2-1.349.769a2.973 2.973 0 01-1.349-2.327c0-.855.6-1.2 1.349-.769a2.972 2.972 0 011.349 2.327"
              fill="#fff"
            />
            <path
              data-name="Path 60580"
              d="M42.938 42.836a1.338 1.338 0 01-.659-.2 3.1 3.1 0 01-1.4-2.419 1.038 1.038 0 01.446-.957 1.051 1.051 0 011.064.1 3.1 3.1 0 011.4 2.419 1.039 1.039 0 01-.446.957.8.8 0 01-.4.1m-1.211-3.468a.59.59 0 00-.3.076.839.839 0 00-.339.772 2.859 2.859 0 001.3 2.234.852.852 0 00.85.1.839.839 0 00.339-.772 2.859 2.859 0 00-1.3-2.234 1.128 1.128 0 00-.552-.171"
              fill="#1d1d1b"
            />
            <path data-name="Path 60581" d="M42.111 37.963l-1.613-.931v-.933l1.613.931z" fill="#ffc200" />
            <path
              data-name="Path 60582"
              d="M42.11 38.017a.053.053 0 01-.027-.007l-1.613-.931a.053.053 0 01-.027-.046V36.1a.053.053 0 01.08-.046l1.613.931a.053.053 0 01.027.046v.933a.053.053 0 01-.027.046.052.052 0 01-.027.007m-1.559-1.015l1.506.869v-.809l-1.506-.869z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60583"
              d="M42.733 37.502a.189.189 0 00-.191-.019l-.117.068a.189.189 0 01.191.019.588.588 0 01.267.463.187.187 0 01-.078.172l.117-.068a.187.187 0 00.078-.172.588.588 0 00-.267-.463"
              fill="#fff"
            />
            <path
              data-name="Path 60584"
              d="M42.832 38.25l-.054-.092a.14.14 0 00.051-.126.538.538 0 00-.24-.416.141.141 0 00-.137-.019l-.054-.092.117-.068a.239.239 0 01.244.018.639.639 0 01.294.509.238.238 0 01-.1.218zm-.192-.729a.638.638 0 01.294.509.221.221 0 00.01-.069.538.538 0 00-.24-.416.228.228 0 00-.067-.027"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60585"
              d="M42.616 37.57a.589.589 0 01.267.463c0 .17-.121.238-.269.153a.589.589 0 01-.267-.462c0-.17.121-.239.269-.153"
              fill="#fff"
            />
            <path
              data-name="Path 60586"
              d="M42.735 38.276a.3.3 0 01-.147-.044.639.639 0 01-.294-.509.216.216 0 01.349-.2.638.638 0 01.294.509.237.237 0 01-.105.218.191.191 0 01-.1.025m-.239-.689a.086.086 0 00-.043.011.14.14 0 00-.052.126.538.538 0 00.24.416.142.142 0 00.137.019.14.14 0 00.052-.126.538.538 0 00-.24-.416.193.193 0 00-.094-.029"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60587"
              d="M43.389 37.857a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60588"
              d="M43.433 38.154a.145.145 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.041.041 0 00.018.007.088.088 0 000-.018.169.169 0 00-.073-.126.038.038 0 00-.018-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60589"
              d="M43.767 38.064a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60590"
              d="M43.812 38.361a.144.144 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.042.042 0 00.018.007.107.107 0 000-.018.169.169 0 00-.073-.126.037.037 0 00-.019-.007"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60591"
              d="M44.145 38.293a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60592"
              d="M44.19 38.59a.144.144 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.042.042 0 00.018.007.11.11 0 000-.018.168.168 0 00-.073-.126.036.036 0 00-.019-.007"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60593"
              d="M43.389 38.273a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60594"
              d="M43.433 38.57a.144.144 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.042.042 0 00.018.007.089.089 0 000-.018.169.169 0 00-.073-.126.035.035 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60595"
              d="M43.767 38.48a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60596"
              d="M43.812 38.777a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.042.042 0 00.018.007.1.1 0 000-.018.168.168 0 00-.073-.126.036.036 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60597"
              d="M44.145 38.709a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60598"
              d="M44.19 39.006a.144.144 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.042.042 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.038.038 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60599"
              d="M44.53 38.531a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60600"
              d="M44.574 38.828a.144.144 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.041.041 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.035.035 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60601"
              d="M44.53 38.946a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60602"
              d="M44.574 39.244a.145.145 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.092.092 0 000-.018.169.169 0 00-.073-.126.041.041 0 00-.019-.007"
              fill="#1d1d1b"
            />
            <path data-name="Path 60603" d="M44.984 37.79l-12.487-7.239 4.548-2.626 12.487 7.239z" fill="#fff" />
            <path
              data-name="Path 60604"
              d="M44.984 37.897a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 010-.185l4.548-2.626a.107.107 0 01.107 0l12.487 7.239a.107.107 0 010 .185l-4.548 2.626a.107.107 0 01-.053.014m-12.274-7.346l12.274 7.115 4.335-2.5-12.274-7.115z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60605" d="M32.497 31.089v-.536l12.487 7.239v.538z" fill="#fff" />
            <path
              data-name="Path 60606"
              d="M44.984 38.435a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 01-.053-.092v-.538a.107.107 0 01.16-.092l12.487 7.239a.107.107 0 01.053.092v.538a.107.107 0 01-.107.107m-12.38-7.407l12.274 7.115v-.291l-12.274-7.115z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60607" d="M49.533 35.164v.538l-4.549 2.626v-.538z" fill="#fff" />
            <path
              data-name="Path 60608"
              d="M44.984 38.434a.107.107 0 01-.107-.107v-.538a.106.106 0 01.053-.092l4.546-2.626a.107.107 0 01.16.092v.538a.107.107 0 01-.053.093l-4.548 2.626a.106.106 0 01-.053.014m.107-.583v.291l4.335-2.5v-.293z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60609" d="M44.319 31.017l-5.457-3.151v-9.437l5.462 3.135z" fill="#d0dadd" />
            <path
              data-name="Path 60610"
              d="M44.319 31.123a.107.107 0 01-.053-.014l-5.457-3.151a.106.106 0 01-.053-.092v-9.437a.107.107 0 01.16-.093l5.462 3.135a.107.107 0 01.054.093l-.005 9.452a.107.107 0 01-.107.107m-5.35-3.319l5.243 3.027.005-9.206-5.248-3.013z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60611" d="M38.862 27.866v-9.445l.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60612"
              d="M39.772 28.497a.106.106 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-9.445a.107.107 0 01.16-.093l.909.519a.107.107 0 01.054.093v9.45a.107.107 0 01-.107.107m-.8-.693l.7.4v-9.2l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60613" d="M43.41 32.066l-5.453-3.151v-.525l5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 60614"
              d="M43.403 32.173a.108.108 0 01-.053-.014l-5.453-3.151a.107.107 0 01-.053-.092v-.525a.107.107 0 01.16-.092l5.453 3.149a.107.107 0 01.053.092v.527a.107.107 0 01-.107.107m-5.346-3.319l5.24 3.028v-.281l-5.24-3.025z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60615" d="M43.407 32.066v-.527l.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60616"
              d="M43.407 32.174a.107.107 0 01-.107-.107v-.527a.107.107 0 01.054-.093l.912-.523a.107.107 0 01.16.093v.526a.107.107 0 01-.053.092l-.91.524a.106.106 0 01-.053.014m.107-.572v.281l.7-.4v-.279z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60617" d="M43.41 31.539l-5.453-3.149 1.818-1.05 4.553 2.63-.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60618"
              d="M43.403 31.646a.106.106 0 01-.053-.014l-5.453-3.149a.107.107 0 010-.185l1.818-1.05a.107.107 0 01.107 0l4.553 2.63a.107.107 0 01.053.093l-.005 1.045a.107.107 0 01-.054.092l-.912.523a.107.107 0 01-.053.014m-5.24-3.255l5.24 3.025.806-.462v-.922l-4.446-2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1083" fill="#fff" d="M41.961 20.235v8.399" />
            <path
              data-name="Path 60619"
              d="M41.961 28.741a.107.107 0 01-.107-.107v-8.4a.107.107 0 01.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1084" fill="#ffc200" d="M39.772 23.155l4.552 2.613" />
            <path
              data-name="Path 60620"
              d="M44.325 25.875a.105.105 0 01-.053-.014l-4.552-2.613a.107.107 0 01.106-.185l4.552 2.613a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60621"
              d="M15.611 43.045h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 60622"
              d="M15.612 43.152h-.033a.306.306 0 01-.306-.306v-1.349a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.349a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60623"
              d="M25.853 40.065h-.035a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 60624"
              d="M25.853 40.172h-.033a.307.307 0 01-.306-.306v-1.349a.307.307 0 01.306-.306h.033a.306.306 0 01.306.306v1.349a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60625"
              d="M18.198 44.511h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 60626"
              d="M18.191 44.618h-.033a.306.306 0 01-.306-.306v-1.349a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.349a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60627" d="M26.166 36.698v2.321L18.18 43.66v-2.321z" fill="#fff" />
            <path
              data-name="Path 60628"
              d="M18.178 43.766a.107.107 0 01-.107-.107v-2.321a.107.107 0 01.053-.092l7.986-4.641a.107.107 0 01.16.092v2.321a.107.107 0 01-.053.092l-7.986 4.641a.106.106 0 01-.054.014m.108-2.366v2.074l7.773-4.517v-2.074z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60629" d="M18.18 41.339v2.321l-2.926-1.689V39.65z" fill="#fff" />
            <path
              data-name="Path 60630"
              d="M18.178 43.767a.107.107 0 01-.053-.014l-2.926-1.689a.106.106 0 01-.053-.093V39.65a.107.107 0 01.16-.092l2.926 1.689a.107.107 0 01.053.093v2.321a.107.107 0 01-.107.107m-2.819-1.858l2.712 1.566v-2.074l-2.712-1.566z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60631" d="M26.166 36.698l-7.986 4.641-2.923-1.686 7.986-4.641z" fill="#fff" />
            <path
              data-name="Path 60632"
              d="M18.18 41.446a.107.107 0 01-.053-.014L15.2 39.746a.107.107 0 010-.185l7.986-4.641a.106.106 0 01.107 0l2.926 1.689a.107.107 0 010 .185l-7.986 4.641a.106.106 0 01-.054.015m-2.713-1.8l2.713 1.566 7.774-4.517-2.713-1.566z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60633"
              d="M20.71 42.3a.107.107 0 01-.107-.107v-2.32a.107.107 0 01.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60634"
              d="M23.524 40.664a.107.107 0 01-.107-.107v-2.32a.107.107 0 11.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Path 60635" d="M18.988 39.965l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 60636"
              d="M18.987 40.188a.053.053 0 01-.053-.053v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.052.052 0 01-.027.007m.054-.188v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60637" d="M16.383 38.529l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 60638"
              d="M18.988 40.018a.054.054 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.054.054 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60639" d="M18.988 39.965v.166l-2.606-1.436v-.166z" fill="#ffc200" />
            <path
              data-name="Path 60640"
              d="M18.987 40.184a.053.053 0 01-.026-.007l-2.605-1.432a.054.054 0 01-.028-.047v-.166a.053.053 0 01.079-.047l2.606 1.436a.053.053 0 01.028.047v.166a.053.053 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60641" d="M24.749 36.56l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 60642"
              d="M24.748 36.781a.053.053 0 01-.053-.053v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.053.053 0 01-.027.007m.054-.188v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60643" d="M22.143 35.123l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 60644"
              d="M24.749 36.613a.052.052 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.053.053 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60645" d="M24.748 36.559v.166l-2.606-1.436v-.166z" fill="#ffc200" />
            <path
              data-name="Path 60646"
              d="M24.748 36.779a.054.054 0 01-.026-.007l-2.606-1.436a.053.053 0 01-.028-.047v-.166a.054.054 0 01.026-.046.053.053 0 01.053 0l2.606 1.436a.054.054 0 01.028.047v.166a.054.054 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60647"
              d="M17.248 40.232l7.8-4.545a.421.421 0 00.211-.365v-6.154a.421.421 0 00-.632-.365l-7.8 4.545a.421.421 0 00-.211.365v6.154a.421.421 0 00.632.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 60648"
              d="M17.037 40.395a.53.53 0 01-.528-.529v-6.154a.53.53 0 01.264-.457l7.8-4.545a.528.528 0 01.793.457v6.154a.53.53 0 01-.264.457l-7.8 4.545a.527.527 0 01-.264.072m.211-.164zm7.593-11.379a.314.314 0 00-.157.043l-7.8 4.545a.316.316 0 00-.158.273v6.154a.315.315 0 00.472.273l7.8-4.545a.315.315 0 00.158-.273v-6.154a.316.316 0 00-.315-.315"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60649"
              d="M16.411 33.12l7.8-4.545a.418.418 0 01.455.022l.376.205a.417.417 0 00-.419 0l-7.8 4.545a.422.422 0 00-.211.365v6.155a.416.416 0 00.187.35l-.331-.187a.43.43 0 01-.116-.069.415.415 0 01-.149-.321v-6.154a.421.421 0 01.211-.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 60650"
              d="M16.804 40.323a.106.106 0 01-.052-.014l-.325-.183a.535.535 0 01-.136-.081h-.006a.518.518 0 01-.186-.4v-6.154a.53.53 0 01.264-.457l7.8-4.545a.523.523 0 01.562.021l.36.2.016.009a.107.107 0 01-.1.186l-.013-.007a.311.311 0 00-.3.007l-7.8 4.545a.316.316 0 00-.158.273v6.139a.309.309 0 00.14.261.107.107 0 010 .18.108.108 0 01-.056.015m-.379-.444a.323.323 0 00.087.052.543.543 0 010-.065v-6.154a.53.53 0 01.264-.457l7.8-4.545.042-.022h-.007a.312.312 0 00-.34-.017l-7.8 4.545a.316.316 0 00-.157.272v6.154a.308.308 0 00.111.239m-.009-6.759z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60651"
              d="M17.476 39.796l7.31-4.252a.386.386 0 00.193-.334v-5.632a.386.386 0 00-.579-.334l-7.31 4.252a.386.386 0 00-.193.334v5.632a.386.386 0 00.578.334"
              fill="#ffc200"
            />
            <path
              data-name="Path 60652"
              d="M17.284 39.901a.441.441 0 01-.439-.44v-5.632a.44.44 0 01.22-.38l7.31-4.252a.439.439 0 01.659.38v5.632a.44.44 0 01-.22.38l-7.31 4.252a.438.438 0 01-.219.06m.193-.106zm7.117-10.551a.333.333 0 00-.166.045l-7.31 4.252a.333.333 0 00-.166.288v5.632a.332.332 0 00.5.288l7.31-4.252a.334.334 0 00.166-.288V29.58a.334.334 0 00-.332-.333"
              fill="#1d1d1b"
            />
            <path data-name="Path 60653" d="M35.299 42.851l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 60654"
              d="M35.289 46.555a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.107.107 0 01-.054.014m.117-3.642l-.01 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60655" d="M34.701 42.508l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 60656"
              d="M35.299 42.958a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60657" d="M35.298 42.851l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 60658"
              d="M35.288 46.554a.107.107 0 01-.053-.014l-.594-.343a.107.107 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.01-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60659" d="M24.417 46.115l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 60660"
              d="M24.407 49.818a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.106.106 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60661" d="M23.823 45.772l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 60662"
              d="M24.417 46.222a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60663" d="M24.417 46.115l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 60664"
              d="M24.407 49.818a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60665" d="M35.295 44.243v.549l-8.235 4.786v-.549z" fill="#fff" />
            <path
              data-name="Path 60666"
              d="M27.058 49.681a.107.107 0 01-.107-.107v-.549a.106.106 0 01.053-.092l8.235-4.786a.107.107 0 01.16.093v.549a.107.107 0 01-.053.092l-8.235 4.786a.107.107 0 01-.054.014m.109-.594v.3l8.021-4.662v-.3z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60667" d="M27.06 49.023v.549l-2.646-1.528v-.549z" fill="#fff" />
            <path
              data-name="Path 60668"
              d="M27.058 49.685a.107.107 0 01-.053-.014l-2.646-1.528a.107.107 0 01-.053-.093v-.549a.107.107 0 01.16-.092l2.646 1.528a.107.107 0 01.053.093v.549a.107.107 0 01-.107.106m-2.539-1.7l2.433 1.4v-.3l-2.433-1.4z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60669"
              d="M34.7 43.896l.595.346-8.235 4.786-2.646-1.528.591-.34v-.688l7.648-4.444 2.049 1.183z"
              fill="#fff"
            />
            <path
              data-name="Path 60670"
              d="M27.06 49.135a.107.107 0 01-.053-.014l-2.646-1.528a.107.107 0 010-.185l.537-.309v-.626a.107.107 0 01.053-.092l7.654-4.445a.106.106 0 01.107 0l2.049 1.183a.107.107 0 01.053.093v.623l.541.315a.107.107 0 010 .185l-8.235 4.786a.107.107 0 01-.054.014M24.633 47.5l2.432 1.4 8.023-4.662-.436-.254a.107.107 0 01-.053-.093v-.623l-1.941-1.121-7.541 4.383v.627a.107.107 0 01-.053.092z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60671" d="M27.059 47.636l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 60672"
              d="M27.048 51.343a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.107.107 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60673" d="M26.463 47.297l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 60674"
              d="M27.058 47.746a.107.107 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60675" d="M27.058 47.635l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 60676"
              d="M27.048 51.343a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.01-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60677" d="M35.891 41.82v.683l-8.831 5.132v-.683z" fill="#fff" />
            <path
              data-name="Path 60678"
              d="M27.058 47.747a.107.107 0 01-.107-.107v-.683a.107.107 0 01.053-.092l8.831-5.132a.107.107 0 01.16.093v.683a.107.107 0 01-.053.092l-8.831 5.132a.107.107 0 01-.054.014m.109-.728v.436l8.617-5.008v-.433z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60679" d="M27.06 46.957v.683l-3.235-1.868v-.683z" fill="#fff" />
            <path
              data-name="Path 60680"
              d="M27.058 47.746a.106.106 0 01-.053-.014l-3.235-1.868a.106.106 0 01-.053-.093v-.683a.107.107 0 01.16-.092l3.235 1.868a.107.107 0 01.053.093v.683a.107.107 0 01-.107.106M23.93 45.71l3.022 1.745v-.437l-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60681" d="M35.891 41.825l-8.831 5.132-3.235-1.868 8.831-5.132z" fill="#fff" />
            <path
              data-name="Path 60682"
              d="M27.06 47.063a.107.107 0 01-.053-.014l-3.235-1.868a.107.107 0 010-.185l8.831-5.132a.106.106 0 01.107 0l3.235 1.868a.107.107 0 010 .185l-8.831 5.132a.106.106 0 01-.054.015m-3.022-1.975l3.022 1.745 8.618-5.008-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60683"
              d="M31.865 54.965h-.018a.232.232 0 01-.232-.232v-1.48a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60684"
              d="M31.865 55.072h-.02a.339.339 0 01-.338-.338v-1.488a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.338m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.482a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60685"
              d="M41.38 49.292h-.018a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60686"
              d="M41.381 49.398h-.018a.339.339 0 01-.338-.338v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.338m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60687"
              d="M27.83 53.172h-.015a.232.232 0 01-.232-.232v-1.48a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60688"
              d="M27.83 53.278h-.022a.339.339 0 01-.338-.338v-1.487a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.338m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.482a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60689" d="M40.938 45.175l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 60690"
              d="M40.928 48.838a.107.107 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.877-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.877.509a.107.107 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60691" d="M36.953 42.876l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 60692"
              d="M40.938 45.282a.106.106 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.877-.509a.106.106 0 01.107 0l3.985 2.3a.107.107 0 010 .185l-.876.509a.107.107 0 01-.054.014m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60693" d="M40.938 45.175l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 60694"
              d="M40.928 48.84a.107.107 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60695" d="M39.989 45.55v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 60696"
              d="M35.695 49.165a.107.107 0 01-.107-.107v-1.014a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60697" d="M35.698 48.045v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 60698"
              d="M35.695 49.165a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106m-2.989-1.956l2.883 1.665v-.768l-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60699" d="M39.989 45.55l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 60700"
              d="M35.698 48.152a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.015m-2.883-1.895l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60701" d="M35.697 48.044v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 60702"
              d="M31.403 51.659a.107.107 0 01-.107-.107v-1.014a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60703" d="M31.406 50.539v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 60704"
              d="M31.403 51.656a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106M28.414 49.7l2.883 1.665v-.768l-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60705" d="M35.697 48.044l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 60706"
              d="M31.403 50.645a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.014M28.52 48.75l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60707"
              d="M37.598 44.274s.115.153.144.208a12.452 12.452 0 01.485 1.3.648.648 0 01-.007.3 2.762 2.762 0 00.131-.261 8.275 8.275 0 01.49-1.006 2.205 2.205 0 01.57-.58.6.6 0 01.218-.107.815.815 0 01-.157-.2l-.554-1.056a4.428 4.428 0 01-.106-.275.665.665 0 01-.19.2 1.952 1.952 0 00-.46.353 4.945 4.945 0 00-.438.967.369.369 0 01-.125.159"
              fill="#fff"
            />
            <path
              data-name="Path 60708"
              d="M38.22 46.18a.107.107 0 01-.1-.133.523.523 0 00.009-.235 12.691 12.691 0 00-.478-1.281 2.578 2.578 0 00-.134-.193.107.107 0 01.025-.152.262.262 0 00.085-.107 4.973 4.973 0 01.453-1 1.883 1.883 0 01.49-.38.558.558 0 00.154-.165.107.107 0 01.193.018c.039.109.087.236.1.261l.554 1.056a.7.7 0 00.133.166.107.107 0 01-.047.184.488.488 0 00-.173.084l-.12.1a1.685 1.685 0 00-.43.459 8.287 8.287 0 00-.48.986 2.834 2.834 0 01-.138.275.106.106 0 01-.093.054m-.479-1.894a1.547 1.547 0 01.1.145 11.418 11.418 0 01.463 1.226 8.046 8.046 0 01.452-.909 1.911 1.911 0 01.476-.51l.115-.093a.562.562 0 01.1-.069.715.715 0 01-.068-.1l-.554-1.056a1.513 1.513 0 01-.046-.11.572.572 0 01-.1.079 1.809 1.809 0 00-.431.326 4.5 4.5 0 00-.423.939.429.429 0 01-.083.136"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60709"
              d="M38.22 46.074a2.762 2.762 0 00.131-.261 8.261 8.261 0 01.49-1.006 2.2 2.2 0 01.57-.58.6.6 0 01.217-.107 1.545 1.545 0 01-.44 1.417 3.738 3.738 0 01-.968.537"
              fill="#fff"
            />
            <path
              data-name="Path 60710"
              d="M38.22 46.181a.107.107 0 01-.093-.159 2.694 2.694 0 00.125-.247 8.24 8.24 0 01.5-1.026 1.914 1.914 0 01.476-.51l.115-.093a.694.694 0 01.262-.13.107.107 0 01.131.093 1.644 1.644 0 01-.475 1.507 3.792 3.792 0 01-1 .557.106.106 0 01-.037.007m1.313-1.909a.339.339 0 00-.053.036l-.12.1a1.687 1.687 0 00-.43.459 8.277 8.277 0 00-.48.986v.01a2.861 2.861 0 00.67-.4 1.362 1.362 0 00.417-1.186"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60711"
              d="M36.959 44.798s.066.155.079.208a10.986 10.986 0 01.141 1.21.568.568 0 01-.068.25 2.407 2.407 0 00.167-.2 7.285 7.285 0 01.63-.756 1.938 1.938 0 01.609-.375.523.523 0 01.208-.045.713.713 0 01-.092-.2l-.25-1.018a4.245 4.245 0 01-.033-.257.583.583 0 01-.205.132 1.716 1.716 0 00-.467.2 4.359 4.359 0 00-.578.734.325.325 0 01-.14.109"
              fill="#fff"
            />
            <path
              data-name="Path 60712"
              d="M37.111 46.573a.107.107 0 01-.094-.157.449.449 0 00.056-.192 11.186 11.186 0 00-.138-1.192 1.95 1.95 0 00-.073-.191.107.107 0 01.06-.141.216.216 0 00.089-.069 4.375 4.375 0 01.6-.757 1.664 1.664 0 01.5-.223.469.469 0 00.161-.105.107.107 0 01.182.064c.01.1.025.219.03.242l.25 1.018a.612.612 0 00.077.166.107.107 0 01-.089.167.416.416 0 00-.159.034l-.124.059a1.474 1.474 0 00-.46.3 7.272 7.272 0 00-.615.739 2.478 2.478 0 01-.177.207.106.106 0 01-.078.033m-.017-1.729a1.29 1.29 0 01.048.136 9.743 9.743 0 01.134 1.112 6.955 6.955 0 01.559-.656 1.694 1.694 0 01.517-.337c.038-.017.077-.035.117-.055a.5.5 0 01.09-.034.642.642 0 01-.031-.091l-.25-1.018c0-.017-.009-.05-.014-.086a.5.5 0 01-.088.038 1.582 1.582 0 00-.432.185 3.936 3.936 0 00-.556.71.383.383 0 01-.094.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60713"
              d="M37.11 46.466a2.439 2.439 0 00.167-.2 7.244 7.244 0 01.63-.756 1.935 1.935 0 01.609-.375.523.523 0 01.208-.045 1.333 1.333 0 01-.8 1.025 6.641 6.641 0 01-.817.346"
              fill="#fff"
            />
            <path
              data-name="Path 60714"
              d="M37.111 46.574a.107.107 0 01-.077-.18 2.25 2.25 0 00.157-.183 7.29 7.29 0 01.644-.772 1.693 1.693 0 01.517-.337c.038-.018.077-.035.117-.055a.637.637 0 01.257-.057.107.107 0 01.1.122 1.448 1.448 0 01-.852 1.1 6.651 6.651 0 01-.833.353.108.108 0 01-.035.006m1.48-1.348l-.026.011a3.34 3.34 0 01-.124.058 1.475 1.475 0 00-.46.3 6.983 6.983 0 00-.536.632c.139-.057.3-.127.434-.2a1.253 1.253 0 00.713-.8"
              fill="#1d1d1b"
            />
            <path data-name="Path 60715" d="M36.608 51.226l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 60716"
              d="M36.608 51.332a.107.107 0 01-.054-.014l-.877-.509a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.877.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.053.093.105.105 0 01-.053.014m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60717" d="M36.598 46.727l-.877-.509 4.32-2.494.877.509z" fill="#fff" />
            <path
              data-name="Path 60718"
              d="M36.598 46.83a.105.105 0 01-.054-.015l-.877-.509a.107.107 0 010-.185l4.32-2.494a.106.106 0 01.107 0l.877.509a.107.107 0 010 .185l-4.32 2.494a.107.107 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60719" d="M40.918 44.232l.01 4.5-4.32 2.494-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 60720"
              d="M36.608 51.333a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.009-4.254z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60721"
              d="M29.18 49.582a2.281 2.281 0 01.26-.045c.365 0 .915.086 1.462.086.529 0 1.054-.086 1.406-.086a2.33 2.33 0 01.314.073s-.056-.283-.056-.353v-1.616a2.2 2.2 0 01.078-.328s-.27.039-.333.039c-.347 0-.871-.1-1.391-.1-.495 0-.985.1-1.315.1a3.226 3.226 0 01-.445-.1s.045.322.045.4v1.681c0 .05-.026.246-.026.246"
              fill="#fff"
            />
            <path
              data-name="Path 60722"
              d="M30.903 49.729c-.306 0-.613-.026-.884-.05-.215-.018-.419-.036-.578-.036a2.1 2.1 0 00-.237.042.107.107 0 01-.129-.118c.01-.079.025-.2.025-.233v-1.678c0-.05-.027-.261-.044-.383a.107.107 0 01.134-.118 2.935 2.935 0 00.417.091c.143 0 .324-.019.517-.04a7.533 7.533 0 01.8-.055c.292 0 .585.029.843.055.2.021.4.04.548.04.039 0 .209-.022.318-.038a.108.108 0 01.1.036.107.107 0 01.021.1 2.033 2.033 0 00-.074.3v1.612c0 .037.028.2.054.332a.107.107 0 01-.135.123 2.2 2.2 0 00-.284-.069c-.153 0-.348.017-.555.036-.26.023-.555.05-.851.05m-1.462-.3c.169 0 .376.018.6.037.267.023.569.049.866.049s.576-.026.832-.049c.211-.019.411-.037.574-.037a.939.939 0 01.178.032 1.64 1.64 0 01-.027-.205v-1.616a1.011 1.011 0 01.038-.2c-.074.01-.151.018-.186.018-.161 0-.359-.02-.569-.041a8.163 8.163 0 00-.822-.054 7.388 7.388 0 00-.776.054c-.2.021-.385.041-.539.041a1.818 1.818 0 01-.318-.059c.012.1.025.209.025.254v1.681c0 .023 0 .067-.009.113a.936.936 0 01.137-.018"
              fill="#1d1d1b"
            />
            <path data-name="Path 60723" d="M32.29 53.719l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 60724"
              d="M32.289 53.825a.106.106 0 01-.054-.014l-.876-.506a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.876.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.107.107m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60725" d="M32.279 49.218l-.876-.509 4.32-2.494.876.509z" fill="#fff" />
            <path
              data-name="Path 60726"
              d="M32.279 49.325a.106.106 0 01-.054-.015l-.876-.509a.107.107 0 010-.185l4.32-2.494a.107.107 0 01.107 0l.876.509a.107.107 0 010 .185l-4.32 2.494a.107.107 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60727" d="M36.599 46.724l.01 4.5-4.317 2.495-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 60728"
              d="M32.285 53.826a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.008-4.249z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60729" d="M31.423 50.674l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 60730"
              d="M31.413 54.338a.107.107 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.876-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.877.509a.107.107 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60731" d="M27.438 48.371l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 60732"
              d="M31.422 50.781a.106.106 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.876-.509a.106.106 0 01.107 0l3.985 2.3a.107.107 0 010 .185l-.877.509a.107.107 0 01-.054.014m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60733" d="M31.422 50.674l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 60734"
              d="M31.412 54.339a.107.107 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60735"
              d="M13.835 45.187a2.863 2.863 0 002.6-.026c.707-.423.691-1.1-.037-1.505a2.864 2.864 0 00-2.6.026c-.707.423-.691 1.1.037 1.505"
              fill="#fff"
            />
            <path
              data-name="Path 60736"
              d="M15.109 45.592a2.769 2.769 0 01-1.326-.31 1.029 1.029 0 01-.611-.846 1 1 0 01.572-.844 2.986 2.986 0 012.706-.028 1.029 1.029 0 01.611.845 1 1 0 01-.572.844 2.751 2.751 0 01-1.379.338m.013-2.128a2.531 2.531 0 00-1.269.311.8.8 0 00-.468.658.829.829 0 00.5.662 2.749 2.749 0 002.492-.025.8.8 0 00.468-.658.829.829 0 00-.5-.662 2.552 2.552 0 00-1.223-.286"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60737"
              d="M15.105 44.422h.021a.232.232 0 00.232-.232v-8.8a.232.232 0 00-.232-.232h-.021a.232.232 0 00-.232.232v8.8a.232.232 0 00.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60738"
              d="M15.126 44.53h-.021a.339.339 0 01-.338-.338v-8.8a.339.339 0 01.338-.338h.021a.339.339 0 01.338.338v8.8a.339.339 0 01-.338.338m-.021-9.258a.125.125 0 00-.125.125v8.8a.125.125 0 00.125.125h.021a.125.125 0 00.125-.125v-8.8a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60739"
              d="M13.469 33.879a3.681 3.681 0 003.341-.034c.909-.544.888-1.41-.047-1.935a3.681 3.681 0 00-3.341.034c-.909.544-.888 1.41.047 1.935"
              fill="#ffc200"
            />
            <path
              data-name="Path 60740"
              d="M15.107 34.366a3.528 3.528 0 01-1.69-.4 1.294 1.294 0 01-.77-1.061 1.262 1.262 0 01.72-1.059 3.805 3.805 0 013.448-.035 1.294 1.294 0 01.77 1.061 1.262 1.262 0 01-.72 1.059 3.505 3.505 0 01-1.757.431m.016-2.736a3.285 3.285 0 00-1.647.4 1.062 1.062 0 00-.616.873 1.094 1.094 0 00.661.877 3.568 3.568 0 003.234-.032 1.062 1.062 0 00.616-.873 1.094 1.094 0 00-.661-.877 3.312 3.312 0 00-1.587-.371"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60741"
              d="M13.469 33.879a3.681 3.681 0 003.341-.034 1.17 1.17 0 00.668-.951v2.163a1.157 1.157 0 01-.668.975 3.681 3.681 0 01-3.341.034 1.186 1.186 0 01-.716-.984v-2.187a1.186 1.186 0 00.716.984"
              fill="#ffc200"
            />
            <path
              data-name="Path 60742"
              d="M15.107 36.555a3.527 3.527 0 01-1.69-.4 1.283 1.283 0 01-.77-1.078V32.89h.213v.009a1.09 1.09 0 00.661.883 3.569 3.569 0 003.234-.032 1.071 1.071 0 00.616-.86h.213v2.163a1.256 1.256 0 01-.72 1.066 3.506 3.506 0 01-1.757.431m-2.247-3.049v1.576a1.085 1.085 0 00.661.891 3.568 3.568 0 003.234-.032 1.057 1.057 0 00.616-.88v-1.564a1.733 1.733 0 01-.507.44 3.8 3.8 0 01-3.448.035 1.762 1.762 0 01-.557-.466"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60743"
              d="M13.936 36.378a.116.116 0 01-.042-.009.093.093 0 01-.034-.023.047.047 0 01-.013-.016.1.1 0 01-.011-.018.113.113 0 01-.005-.02.066.066 0 010-.02.073.073 0 010-.021.142.142 0 01.005-.02l.011-.018a.084.084 0 01.013-.016.1.1 0 01.151 0l.014.016a.116.116 0 01.016.038.145.145 0 010 .021.131.131 0 010 .02.065.065 0 01-.006.02.059.059 0 01-.01.018.067.067 0 01-.014.016.1.1 0 01-.075.032"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60744"
              d="M13.829 36.865a.107.107 0 01.214 0 .107.107 0 11-.214 0m0-.3a.107.107 0 01.214 0 .107.107 0 01-.214 0"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60745"
              d="M13.936 37.268a.106.106 0 01-.041-.009.089.089 0 01-.035-.023.1.1 0 01-.031-.075.114.114 0 01.007-.042.108.108 0 01.023-.034.11.11 0 01.151 0 .093.093 0 01.023.034.111.111 0 01.009.042.109.109 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60746"
              d="M13.677 37.688a.259.259 0 10.259-.259.259.259 0 00-.259.259"
              fill="#ffc200"
            />
            <path
              data-name="Path 60747"
              d="M13.935 38.002a.312.312 0 11.312-.312.313.313 0 01-.312.312m0-.518a.206.206 0 10.206.206.206.206 0 00-.206-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60748"
              d="M36.297 32.07l-2.041-1.189a.362.362 0 010-.627l1.926-1.112a1.1 1.1 0 011.1 0l2.041 1.189a.362.362 0 010 .627l-1.926 1.112a1.1 1.1 0 01-1.1 0"
              fill="#fff"
            />
            <path
              data-name="Path 60749"
              d="M36.851 32.325a1.207 1.207 0 01-.607-.164l-2.041-1.189a.469.469 0 010-.811l1.926-1.112a1.21 1.21 0 011.211 0l2.041 1.189a.469.469 0 010 .811l-1.926 1.117a1.207 1.207 0 01-.6.161m-.117-3.224a.993.993 0 00-.5.133l-1.926 1.112a.255.255 0 000 .442l2.041 1.189a1 1 0 001 0l1.926-1.112a.255.255 0 000-.442l-2.041-1.189a.993.993 0 00-.5-.135"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60750"
              d="M36.524 31.711l-1.667-.971a.207.207 0 010-.358l1.583-.914a.611.611 0 01.613 0l1.667.971a.207.207 0 010 .358l-1.583.914a.612.612 0 01-.613 0"
              fill="#fff"
            />
            <path
              data-name="Path 60751"
              d="M36.831 31.848a.663.663 0 01-.335-.09l-1.667-.97a.26.26 0 010-.45l1.583-.914a.667.667 0 01.667 0l1.667.971a.26.26 0 010 .45l-1.583.914a.665.665 0 01-.332.089m-.085-2.407a.558.558 0 00-.279.075l-1.583.914a.154.154 0 000 .266l1.667.971a.559.559 0 00.56 0l1.583-.914a.154.154 0 000-.266l-1.667-.971a.558.558 0 00-.281-.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60752"
              d="M38.258 31.119a.053.053 0 01-.027-.007l-1.2-.7a.559.559 0 00-.56 0l-1.121.647a.053.053 0 01-.053-.092l1.121-.647a.666.666 0 01.667 0l1.2.7a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60753"
              d="M37.321 31.353c0 .1-.171.184-.382.184s-.382-.082-.382-.184.171-.185.382-.185.382.083.382.185"
              fill="#fff"
            />
            <path
              data-name="Path 60754"
              d="M36.939 31.599c-.248 0-.435-.1-.435-.238s.187-.238.435-.238.435.1.435.238-.187.238-.435.238m0-.369c-.2 0-.328.078-.328.131s.128.131.328.131.328-.078.328-.131-.128-.131-.328-.131"
              fill="#1d1d1b"
            />
            <path data-name="Path 60755" d="M37.892 30.309a.146.146 0 11-.041-.17.127.127 0 01.041.17" fill="#fff" />
            <path
              data-name="Path 60756"
              d="M37.818 30.393a.216.216 0 01-.136-.052.177.177 0 01-.049-.245.147.147 0 01.123-.051.228.228 0 01.2.167.148.148 0 01-.026.13.145.145 0 01-.115.051m-.07-.241a.042.042 0 00-.032.012c-.015.019 0 .064.034.1s.084.034.1.015a.044.044 0 000-.037.122.122 0 00-.1-.085"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60757"
              d="M37.986 29.341c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.185h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 60758"
              d="M37.717 29.575c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60759"
              d="M37.986 29.157c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 60760"
              d="M37.717 29.392c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60761"
              d="M38.049 29.712a.214.214 0 01-.214-.214V28.26c0-.385-.2-.654-.375-.654s-.375.268-.375.654a.214.214 0 01-.428 0c0-.607.353-1.082.8-1.082s.8.475.8 1.082v1.236a.214.214 0 01-.214.214"
              fill="#fff"
            />
            <path
              data-name="Path 60762"
              d="M38.05 29.766a.268.268 0 01-.267-.267v-1.236c0-.372-.188-.6-.322-.6s-.322.228-.322.6a.268.268 0 11-.535 0c0-.637.376-1.135.857-1.135s.857.5.857 1.135v1.236a.268.268 0 01-.268.267m-.589-2.211c.207 0 .429.284.429.707v1.236a.161.161 0 00.321 0v-1.236c0-.577-.33-1.029-.75-1.029s-.75.452-.75 1.029a.161.161 0 00.322 0c0-.423.222-.707.429-.707"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60763"
              d="M38.675 29.695c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.184h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 60764"
              d="M38.405 29.932c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60765"
              d="M38.675 29.512c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 60766"
              d="M38.405 29.747c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60767"
              d="M35.452 24.552a3.654 3.654 0 11-3.654-3.654 3.654 3.654 0 013.654 3.654"
              fill="#ffc200"
            />
            <path
              data-name="Path 60768"
              d="M31.798 28.313a3.761 3.761 0 113.761-3.761 3.765 3.765 0 01-3.761 3.761m0-7.308a3.547 3.547 0 103.547 3.547 3.551 3.551 0 00-3.547-3.547"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60769"
              d="M32.964 21.779c0 .3-.522.544-1.166.544s-1.166-.244-1.166-.544.522-.544 1.166-.544 1.166.244 1.166.544"
              fill="#fff"
            />
            <path
              data-name="Path 60770"
              d="M31.798 22.43c-.726 0-1.273-.28-1.273-.651s.547-.651 1.273-.651 1.273.28 1.273.651-.547.651-1.273.651m0-1.088c-.624 0-1.06.23-1.06.437s.435.437 1.06.437 1.06-.23 1.06-.437-.435-.437-1.06-.437"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60771"
              d="M35.427 25.067a8.394 8.394 0 01-6.357-2.972.055.055 0 11.1-.045 8.416 8.416 0 006.261 2.91.054.054 0 010 .107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60772"
              d="M28.37 25.874a.054.054 0 010-.107 16.648 16.648 0 006.869-2.36.054.054 0 11.085.065 16.215 16.215 0 01-6.949 2.4"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60773"
              d="M32.916 28.054H32.9a11.643 11.643 0 01-4.8-3.533.054.054 0 11.09-.058 11.5 11.5 0 004.743 3.489.053.053 0 01-.016.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60774"
              d="M29.906 27.576h-.239a.053.053 0 01-.053-.054.051.051 0 01.054-.053 12.91 12.91 0 005.074-.867l.037-.014a.053.053 0 11.037.1l-.037.014a12.9 12.9 0 01-4.874.876"
              fill="#1d1d1b"
            />
            <path data-name="Line 1085" fill="#ffc200" d="M31.798 21.779v-5.06" />
            <path
              data-name="Path 60775"
              d="M31.798 21.992a.214.214 0 01-.214-.213v-5.06a.213.213 0 11.427 0v5.06a.214.214 0 01-.213.213"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60776"
              d="M32.858 17.218c0 .3-.474.547-1.06.547s-1.06-.245-1.06-.547v-.5h2.12z"
              fill="#ffc200"
            />
            <path
              data-name="Path 60777"
              d="M31.798 17.925c-.7 0-1.22-.3-1.22-.707v-.5a.16.16 0 01.16-.16h2.12a.16.16 0 01.16.16v.5c0 .4-.524.707-1.22.707m-.9-1.046v.339c0 .162.342.387.9.387s.9-.225.9-.387v-.339z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60778"
              d="M32.858 16.719c0 .3-.474.547-1.06.547s-1.06-.245-1.06-.547.474-.547 1.06-.547 1.06.245 1.06.547"
              fill="#ffc200"
            />
            <path
              data-name="Path 60779"
              d="M31.798 17.426c-.7 0-1.22-.3-1.22-.707s.524-.707 1.22-.707 1.22.3 1.22.707-.524.707-1.22.707m0-1.093c-.557 0-.9.225-.9.387s.342.387.9.387.9-.225.9-.387-.342-.387-.9-.387"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60780"
              d="M31.841 33.625L21.125 16.792l-10.745 4.5.079 21.712 1.505 1 .009-21.8 18.352 10.549v21.834l1.505 1 .009-21.963z"
              fill="#fff"
            />
            <path
              data-name="Path 60781"
              d="M31.834 55.689a.107.107 0 01-.059-.018l-1.505-1a.107.107 0 01-.048-.089V32.811L12.08 22.385l-.009 21.611a.107.107 0 01-.166.089l-1.505-1a.107.107 0 01-.048-.088l-.079-21.712a.107.107 0 01.066-.1l10.745-4.5a.107.107 0 01.131.041l10.719 16.837a.107.107 0 01.015.078l-.009 21.942a.107.107 0 01-.107.107m-1.4-1.164l1.292.858.009-21.735-10.651-16.731-10.6 4.435.079 21.583 1.292.858.009-21.6a.107.107 0 01.16-.092l18.352 10.549a.107.107 0 01.053.093z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60782" d="M31.842 33.621l21.428-12.46-10.721-16.9-21.428 12.46z" fill="#00515f" />
            <path
              data-name="Path 60783"
              d="M31.842 33.727a.107.107 0 01-.09-.05l-10.721-16.9a.106.106 0 01.037-.149L42.495 4.166a.107.107 0 01.144.035l10.721 16.9a.107.107 0 01-.036.149L31.896 33.71a.106.106 0 01-.054.014M21.27 16.753l10.607 16.723L53.12 21.124 42.513 4.401z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60784" d="M10.38 21.284l10.745-4.49 21.428-12.46-10.745 4.493z" fill="#00515f" />
            <path
              data-name="Path 60785"
              d="M10.38 21.392a.107.107 0 01-.054-.2l21.428-12.46.012-.006 10.745-4.49a.107.107 0 01.095.191l-21.428 12.46-.012.006-10.745 4.49a.108.108 0 01-.041.008m21.476-12.47L11.843 20.558l9.235-3.859L41.091 5.062z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60786"
              d="M22.374 23.871c0 1.136-.805 1.6-1.789 1.033a3.97 3.97 0 01-1.789-3.1c0-1.136.805-1.6 1.789-1.033a3.97 3.97 0 011.789 3.1"
              fill="#00515f"
            />
            <path
              data-name="Path 60787"
              d="M21.38 25.254a1.723 1.723 0 01-.849-.258 4.079 4.079 0 01-1.842-3.192 1.2 1.2 0 011.949-1.126 4.079 4.079 0 011.843 3.192 1.354 1.354 0 01-.579 1.248 1.029 1.029 0 01-.522.135m-1.59-4.619a.82.82 0 00-.416.106 1.155 1.155 0 00-.472 1.063 3.843 3.843 0 001.736 3.007.988.988 0 001.629-.941 3.842 3.842 0 00-1.736-3.007 1.512 1.512 0 00-.741-.229"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60788"
              d="M21.281 24.756a3.97 3.97 0 01-1.789-3.1 1.3 1.3 0 01.462-1.115c-.675-.094-1.158.383-1.158 1.263a3.969 3.969 0 001.789 3.1 1.215 1.215 0 001.327.082 1.756 1.756 0 01-.631-.23"
              fill="#fff"
            />
            <path
              data-name="Path 60789"
              d="M21.381 25.253a1.724 1.724 0 01-.85-.256 4.079 4.079 0 01-1.843-3.192 1.456 1.456 0 01.427-1.139 1.042 1.042 0 01.853-.23.107.107 0 01.047.193 1.2 1.2 0 00-.417 1.028 3.842 3.842 0 001.736 3.007 1.652 1.652 0 00.592.217.107.107 0 01.047.193 1.014 1.014 0 01-.592.179m-1.69-4.611a.775.775 0 00-.436.185 1.271 1.271 0 00-.353.978 3.843 3.843 0 001.736 3.007 1.33 1.33 0 00.95.2 2.14 2.14 0 01-.36-.167 4.079 4.079 0 01-1.843-3.192 1.584 1.584 0 01.306-1.015"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60790"
              d="M26.301 18.248l6.677 10.626 7.25-4.275v-.406l-13.827-6.495z"
              fill="#ffc200"
            />
            <path
              data-name="Path 60791"
              d="M32.98 28.981a.107.107 0 01-.091-.05l-6.677-10.626a.107.107 0 01-.015-.075l.093-.55a.107.107 0 01.151-.079l13.833 6.5a.107.107 0 01.061.1v.406a.107.107 0 01-.053.092l-7.25 4.275a.106.106 0 01-.054.015m-6.565-10.756l6.6 10.5 7.108-4.191v-.278l-13.645-6.406z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60792" d="M26.397 17.698l6.677 10.626 7.157-4.131-6.71-10.59z" fill="#ffc200" />
            <path
              data-name="Path 60793"
              d="M33.073 28.431a.107.107 0 01-.09-.05l-6.677-10.626a.107.107 0 01.037-.149l7.127-4.1a.107.107 0 01.143.035l6.707 10.591a.107.107 0 01-.037.15l-7.157 4.131a.106.106 0 01-.053.014m-6.527-10.7l6.563 10.445 6.971-4.024-6.592-10.41z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60794"
              d="M36.632 26.367a.107.107 0 01-.09-.05l-6.717-10.594a.107.107 0 01.18-.114l6.717 10.594a.107.107 0 01-.09.164"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60795"
              d="M32.119 26.887a.107.107 0 01-.053-.2l7.106-4.1a.107.107 0 11.107.185l-7.106 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60796"
              d="M30.195 23.85a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 11.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60797"
              d="M27.353 19.283a.107.107 0 01-.053-.2l7.088-4.093a.107.107 0 01.107.185l-7.088 4.093a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60798"
              d="M28.351 20.793a.107.107 0 01-.053-.2l7.059-4.076a.107.107 0 11.107.185l-7.059 4.076a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60799"
              d="M29.279 22.313a.107.107 0 01-.053-.2l7.072-4.083a.107.107 0 01.107.185l-7.072 4.083a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60800"
              d="M31.173 25.357a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 01.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60801"
              d="M35.31 13.385l6.677 10.627 7.25-4.275v-.406l-13.833-6.495z"
              fill="#ffc200"
            />
            <path
              data-name="Path 60802"
              d="M41.987 24.118a.107.107 0 01-.091-.05l-6.677-10.626a.107.107 0 01-.015-.075l.093-.549a.107.107 0 01.151-.079l13.833 6.5a.107.107 0 01.061.1v.406a.107.107 0 01-.053.092l-7.25 4.275a.106.106 0 01-.054.015m-6.56-10.765l6.6 10.5 7.108-4.191v-.278l-13.648-6.401z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60803" d="M35.403 12.835l6.677 10.631 7.157-4.131L42.53 8.744z" fill="#ffc200" />
            <path
              data-name="Path 60804"
              d="M42.08 23.568a.107.107 0 01-.091-.05l-6.677-10.626a.107.107 0 01.037-.149l7.127-4.1a.107.107 0 01.143.035l6.707 10.591a.107.107 0 01-.037.15l-7.157 4.131a.107.107 0 01-.053.014m-6.527-10.7l6.563 10.445 6.972-4.024-6.592-10.41z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60805"
              d="M45.639 21.504a.107.107 0 01-.09-.05L38.832 10.86a.107.107 0 11.18-.114l6.717 10.594a.107.107 0 01-.09.164"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60806"
              d="M41.126 22.023a.107.107 0 01-.053-.2l7.106-4.1a.107.107 0 11.107.185l-7.106 4.1a.107.107 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60807"
              d="M39.202 18.987a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 11.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60808"
              d="M36.36 14.42a.107.107 0 01-.053-.2l7.088-4.093a.107.107 0 11.107.185l-7.088 4.093a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60809"
              d="M37.358 15.93a.107.107 0 01-.053-.2l7.059-4.076a.107.107 0 11.107.185l-7.059 4.076a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60810"
              d="M38.286 17.449a.107.107 0 01-.053-.2l7.072-4.083a.107.107 0 01.107.185l-7.072 4.083a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60811"
              d="M40.175 20.488a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 11.107.185l-7.1 4.1a.107.107 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60812"
              d="M51.787 22.119L33.335 32.755l-1.5.863.009 21.9 1.505-1v-21.1l18.433-10.7.008 21.273 1.505-1v-21.75z"
              fill="#fff"
            />
            <path
              data-name="Path 60813"
              d="M31.849 55.627a.107.107 0 01-.107-.107l-.009-21.9a.107.107 0 01.053-.093l21.461-12.372a.107.107 0 01.16.093v21.75a.107.107 0 01-.048.089l-1.505 1a.107.107 0 01-.166-.089l-.008-21.087-18.22 10.572v21.04a.107.107 0 01-.048.089l-1.505 1a.107.107 0 01-.059.018m.1-21.947l.009 21.641 1.292-.858V33.419a.107.107 0 01.053-.092l18.433-10.7a.107.107 0 01.16.092l.008 21.074 1.292-.858V21.431l-1.353.78zM51.79 22.122z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60814"
              d="M50.008 43.507a2.591 2.591 0 01-2.351-.024.91.91 0 01-.454-.536l.421 1.721a.675.675 0 00.337.4 1.921 1.921 0 001.743.018.689.689 0 00.361-.408l.43-1.718a.929.929 0 01-.486.551"
              fill="#fff"
            />
            <path
              data-name="Path 60815"
              d="M48.848 45.392a1.871 1.871 0 01-.943-.235.777.777 0 01-.385-.464l-.421-1.72.207-.051a.809.809 0 00.4.47 2.476 2.476 0 002.244.022.838.838 0 00.435-.484l.207.052-.43 1.717a.79.79 0 01-.412.475 1.887 1.887 0 01-.908.216m-1.409-1.929l.288 1.18a.573.573 0 00.288.331 1.805 1.805 0 001.636.016.594.594 0 00.309-.341l.295-1.178a1.53 1.53 0 01-.2.129 2.713 2.713 0 01-2.458-.025 1.5 1.5 0 01-.163-.112"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60816"
              d="M50.072 42.922a2.735 2.735 0 01-2.482-.025c-.676-.4-.66-1.048.035-1.438a2.736 2.736 0 012.482.025c.676.4.66 1.048-.035 1.438"
              fill="#fff"
            />
            <path
              data-name="Path 60817"
              d="M48.855 43.313a2.632 2.632 0 01-1.32-.324.964.964 0 01-.549-.811.987.987 0 01.586-.812 2.858 2.858 0 012.589.026.964.964 0 01.549.811.987.987 0 01-.586.812 2.648 2.648 0 01-1.269.3m-.012-2.033a2.433 2.433 0 00-1.166.273.788.788 0 00-.477.628.764.764 0 00.445.625 2.622 2.622 0 002.375.024.788.788 0 00.477-.629.764.764 0 00-.445-.625 2.412 2.412 0 00-1.21-.3"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60818"
              d="M49.831 42.656a2.5 2.5 0 01-2-.018c-.544-.283-.531-.733.028-1.006a2.5 2.5 0 012 .017c.544.283.531.733-.028 1.006"
              fill="#fff"
            />
            <path
              data-name="Path 60819"
              d="M48.853 42.961a2.386 2.386 0 01-1.067-.227.707.707 0 01-.457-.6.729.729 0 01.488-.6 2.619 2.619 0 012.093.019.707.707 0 01.457.6.729.729 0 01-.488.6 2.408 2.408 0 01-1.026.209m-.01-1.422a2.188 2.188 0 00-.933.19c-.232.113-.366.263-.368.41s.123.291.342.4a2.379 2.379 0 001.9.016c.232-.113.366-.263.368-.41s-.123-.291-.342-.4a2.163 2.163 0 00-.968-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60820"
              d="M50.12 42.371a.693.693 0 00-.31-.274 2.379 2.379 0 00-1.9-.016.713.713 0 00-.336.286l-.191-.1a.917.917 0 01.433-.381 2.621 2.621 0 012.093.019.9.9 0 01.4.367z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60821"
              d="M50.073 42.922a2.735 2.735 0 01-2.482-.025.869.869 0 01-.5-.706v.591a.859.859 0 00.5.724 2.735 2.735 0 002.482.025.881.881 0 00.532-.731v-.608a.881.881 0 01-.532.731"
              fill="#fff"
            />
            <path
              data-name="Path 60822"
              d="M48.855 43.921a2.632 2.632 0 01-1.32-.324.96.96 0 01-.548-.818v-.588h.214a.771.771 0 00.445.615 2.621 2.621 0 002.375.024l.052.093-.052-.093a.785.785 0 00.477-.632v-.006h.214v.609a.979.979 0 01-.586.825 2.65 2.65 0 01-1.269.3m-1.655-1.2v.065a.76.76 0 00.445.633 2.621 2.621 0 002.375.024.781.781 0 00.477-.638v-.077a1.409 1.409 0 01-.373.293 2.857 2.857 0 01-2.59-.027 1.38 1.38 0 01-.335-.273"
              fill="#1d1d1b"
            />
            <path data-name="Line 1086" fill="#fff" d="M48.846 39.103v-2.31" />
            <path
              data-name="Path 60823"
              d="M48.846 39.236a.133.133 0 01-.133-.133v-2.31a.134.134 0 01.267 0v2.31a.133.133 0 01-.133.133"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60824"
              d="M48.846 42.565a.16.16 0 01-.16-.16v-3.207a.16.16 0 11.32 0v3.207a.16.16 0 01-.16.16"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60825"
              d="M51.175 41.246c-.744.507-1.585.567-1.879.136s.071-1.192.815-1.7a1.777 1.777 0 011.66-.327 2.849 2.849 0 00.385.118s-.116.16-.1.207c.153.442-.21 1.107-.882 1.564"
              fill="#fff"
            />
            <path
              data-name="Path 60826"
              d="M49.963 41.779a.871.871 0 01-.755-.337.914.914 0 01.01-.927 2.441 2.441 0 01.833-.919 1.874 1.874 0 011.769-.333 2.584 2.584 0 00.361.109.107.107 0 01.061.166.778.778 0 00-.078.131c.153.5-.234 1.192-.929 1.666a2.349 2.349 0 01-1.271.444m1.362-2.412a2.165 2.165 0 00-1.154.405 2.226 2.226 0 00-.76.835.714.714 0 00-.026.715c.256.376 1.048.3 1.73-.164.618-.421.979-1.04.841-1.441-.008-.022-.023-.067.032-.175a1.669 1.669 0 01-.266-.09.856.856 0 00-.4-.085m-.151 1.879zm.983-1.6"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60827"
              d="M48.824 41.919a.107.107 0 01-.092-.161 8.941 8.941 0 012.045-1.631.107.107 0 11.091.193 9.144 9.144 0 00-1.953 1.547.107.107 0 01-.092.052"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60828"
              d="M46.353 40.719c.779.53 1.66.594 1.967.142s-.074-1.248-.853-1.778a1.861 1.861 0 00-1.738-.342 2.975 2.975 0 01-.4.123s.121.167.1.217c-.16.463.22 1.159.923 1.638"
              fill="#fff"
            />
            <path
              data-name="Path 60829"
              d="M47.622 41.268h-.119a2.551 2.551 0 01-1.213-.458c-.728-.5-1.132-1.223-.971-1.741a.807.807 0 00-.083-.14.107.107 0 01.061-.166 2.7 2.7 0 00.379-.114 1.958 1.958 0 011.848.349c.84.572 1.227 1.419.881 1.927a.9.9 0 01-.784.348m-2.131-2.339c.061.115.044.164.037.185-.146.422.234 1.073.882 1.515a2.337 2.337 0 001.108.421.752.752 0 00.711-.251c.269-.4-.108-1.142-.825-1.63a1.779 1.779 0 00-1.629-.336 1.8 1.8 0 01-.284.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60830"
              d="M48.812 41.417a.107.107 0 01-.092-.052 9.576 9.576 0 00-2.047-1.621.107.107 0 01.091-.193 9.355 9.355 0 012.14 1.706.107.107 0 01-.092.161"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60831"
              d="M51.029 39.602c-.789.278-1.528.108-1.651-.379s.417-1.109 1.206-1.387a1.478 1.478 0 011.52.139 2.383 2.383 0 00.3.216s-.147.12-.147.169c0 .46-.511.991-1.223 1.242"
              fill="#fff"
            />
            <path
              data-name="Path 60832"
              d="M50.291 39.843a1.37 1.37 0 01-.646-.144.707.707 0 01-.371-.451c-.138-.548.421-1.213 1.273-1.514a1.574 1.574 0 011.634.168 2.146 2.146 0 00.274.2.107.107 0 01.01.173.762.762 0 00-.108.106c-.014.509-.555 1.063-1.294 1.323a2.346 2.346 0 01-.772.14m1.029-2.034a2.142 2.142 0 00-.7.127c-.724.256-1.245.833-1.137 1.26a.493.493 0 00.265.315 1.641 1.641 0 001.247-.01c.655-.231 1.151-.722 1.152-1.142 0-.021 0-.067.081-.155a1.389 1.389 0 01-.2-.158.95.95 0 00-.7-.238m-.293 1.793z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60833"
              d="M48.845 39.607a.107.107 0 01-.075-.183 8.257 8.257 0 012.244-.991.107.107 0 01.037.21 8.4 8.4 0 00-2.132.934.106.106 0 01-.074.03"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60834"
              d="M46.888 39.125c.707.249 1.369.1 1.479-.34s-.373-.993-1.08-1.243a1.324 1.324 0 00-1.362.125 2.131 2.131 0 01-.264.193s.132.107.132.152c0 .412.457.888 1.1 1.113"
              fill="#fff"
            />
            <path
              data-name="Path 60835"
              d="M47.547 39.349a2.113 2.113 0 01-.7-.126c-.665-.235-1.151-.733-1.167-1.192a.705.705 0 00-.093-.09.107.107 0 01.01-.173 1.919 1.919 0 00.243-.175 1.421 1.421 0 011.476-.154c.769.271 1.273.873 1.148 1.369a.644.644 0 01-.338.411 1.241 1.241 0 01-.585.13m-.624-.328a1.459 1.459 0 001.108.01.431.431 0 00.232-.275c.1-.378-.368-.889-1.012-1.116a1.238 1.238 0 00-1.248.1 1.194 1.194 0 01-.171.135c.067.075.067.116.067.137 0 .371.442.807 1.024 1.012"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60836"
              d="M48.845 39.141a.106.106 0 01-.075-.03 7.506 7.506 0 00-1.9-.834.107.107 0 11.037-.21 7.417 7.417 0 012.016.891.107.107 0 01-.074.183"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60837"
              d="M50.712 37.844c-.579.474-1.253.556-1.505.183s.013-1.061.593-1.535a1.323 1.323 0 011.323-.346 2.122 2.122 0 00.314.092s-.087.146-.072.187a1.472 1.472 0 01-.652 1.418"
              fill="#fff"
            />
            <path
              data-name="Path 60838"
              d="M49.719 38.369a.957.957 0 01-.139-.01.644.644 0 01-.457-.271c-.286-.424-.017-1.161.614-1.677a1.42 1.42 0 011.44-.358 1.923 1.923 0 00.288.082.107.107 0 01.068.159.714.714 0 00-.057.116 1.586 1.586 0 01-.691 1.517 1.768 1.768 0 01-1.065.442m1.085-2.187a1.577 1.577 0 00-.932.394c-.528.432-.79 1.07-.572 1.393a.431.431 0 00.311.18 1.457 1.457 0 001.038-.386 1.383 1.383 0 00.619-1.3.192.192 0 01.017-.151 1.2 1.2 0 01-.207-.069.588.588 0 00-.274-.06m-.087 1.663z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60839"
              d="M48.846 38.531a.107.107 0 01-.1-.153 7.41 7.41 0 011.592-1.523.107.107 0 11.106.185 7.507 7.507 0 00-1.506 1.431.107.107 0 01-.1.06"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60840"
              d="M47.091 36.934c.533.437 1.154.512 1.386.169s-.012-.977-.546-1.413a1.218 1.218 0 00-1.218-.318 1.96 1.96 0 01-.289.085s.081.134.067.172a1.355 1.355 0 00.6 1.306"
              fill="#fff"
            />
            <path
              data-name="Path 60841"
              d="M48.009 37.426a1.637 1.637 0 01-.986-.408 1.472 1.472 0 01-.64-1.4.682.682 0 00-.051-.1.107.107 0 01.069-.159 1.734 1.734 0 00.263-.075 1.316 1.316 0 011.335.33c.584.478.833 1.162.567 1.556a.6.6 0 01-.427.254.877.877 0 01-.129.009m-1.43-1.9a.178.178 0 01.012.137 1.265 1.265 0 00.567 1.187 1.333 1.333 0 00.949.353.389.389 0 00.281-.162c.2-.294-.042-.876-.525-1.271a1.132 1.132 0 00-1.1-.306 1.05 1.05 0 01-.183.062m-.189.064"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60842"
              d="M48.812 37.579a.107.107 0 01-.1-.06 6.886 6.886 0 00-1.383-1.314.107.107 0 11.106-.185 6.837 6.837 0 011.469 1.406.107.107 0 01-.1.153"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60843"
              d="M50.434 35.468c-.43.539-1.022.743-1.321.456s-.193-.958.237-1.5a1.219 1.219 0 011.125-.567 1.957 1.957 0 00.3.022s-.051.148-.029.183a1.356 1.356 0 01-.313 1.4"
              fill="#fff"
            />
            <path
              data-name="Path 60844"
              d="M49.459 36.161a.589.589 0 01-.419-.16c-.343-.33-.243-1.05.228-1.641a1.317 1.317 0 011.237-.6 1.75 1.75 0 00.273.018.107.107 0 01.1.141.672.672 0 00-.028.111 1.473 1.473 0 01-.332 1.507 1.525 1.525 0 01-1.007.625h-.051m.865-2.214a1.291 1.291 0 00-.89.546c-.389.488-.5 1.108-.247 1.354a.39.39 0 00.309.1 1.335 1.335 0 00.855-.545 1.266 1.266 0 00.306-1.28.177.177 0 01-.017-.136 1.06 1.06 0 01-.192-.022.458.458 0 00-.123-.016m.514.061"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60845"
              d="M48.847 37.049a.107.107 0 01-.107-.106 3.9 3.9 0 011.161-2.306.107.107 0 11.143.159 3.713 3.713 0 00-1.09 2.146.107.107 0 01-.106.107z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60846"
              d="M49.645 39.566c-.1.318-.6.452-1.191.344a.924.924 0 00-.341.112s-.138-.249-.227-.289c-.54-.243-.876-.637-.778-.953.213-.686 1.056-.04 1.372.058.363.113 1.377.045 1.165.728"
              fill="#fff"
            />
            <path
              data-name="Path 60847"
              d="M48.114 40.128a.107.107 0 01-.094-.055.927.927 0 00-.178-.244c-.6-.272-.948-.717-.835-1.082a.517.517 0 01.284-.348c.289-.123.666.067.968.22a2.078 2.078 0 00.254.116 2.3 2.3 0 00.309.056c.325.046.729.1.89.357a.521.521 0 01.035.449c-.114.367-.653.539-1.312.417a.918.918 0 00-.272.1.107.107 0 01-.049.012m-.617-1.555a.276.276 0 00-.286.237c-.078.25.232.6.72.824a.691.691 0 01.225.248.658.658 0 01.319-.078c.531.1.991-.018 1.069-.27a.316.316 0 00-.011-.271c-.109-.171-.459-.221-.74-.26a2.394 2.394 0 01-.343-.064 2.116 2.116 0 01-.287-.13 1.766 1.766 0 00-.666-.236"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60848"
              d="M48.289 39.384h-.008a.107.107 0 01-.1-.114.935.935 0 01.631-.719.107.107 0 11.063.2.737.737 0 00-.481.531.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60849"
              d="M49.597 36.993c-.078.253-.48.36-.947.273a.735.735 0 00-.271.089s-.11-.2-.181-.23c-.43-.193-.7-.507-.619-.758.169-.546.84-.032 1.092.047.289.09 1.1.036.927.58"
              fill="#fff"
            />
            <path
              data-name="Path 60850"
              d="M48.381 37.463a.107.107 0 01-.094-.055.764.764 0 00-.134-.186c-.489-.22-.768-.585-.674-.886a.432.432 0 01.238-.29c.239-.1.544.052.789.175a1.642 1.642 0 00.2.091 1.805 1.805 0 00.243.044c.263.037.59.083.724.294a.435.435 0 01.03.375c-.094.3-.533.446-1.069.347a.8.8 0 00-.2.079.108.108 0 01-.049.012m-.491-1.237a.2.2 0 00-.207.173c-.059.189.183.459.561.629a.526.526 0 01.179.189.5.5 0 01.249-.056c.412.076.767-.01.826-.2a.232.232 0 00-.007-.2c-.081-.128-.366-.168-.573-.2a1.915 1.915 0 01-.276-.051 1.689 1.689 0 01-.231-.1 1.4 1.4 0 00-.519-.186"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60851"
              d="M48.517 36.87h-.007a.107.107 0 01-.1-.114.768.768 0 01.517-.591.107.107 0 11.063.2.57.57 0 00-.367.4.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgProductionAndConsumption
