import * as React from "react"

function SvgRadioButtonChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 402" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 403"
        d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z"
        fill="currentColor"
      />
      <circle data-name="Ellipse 493" cx={5} cy={5} r={5} transform="translate(7 7)" fill="currentColor" />
    </svg>
  )
}

export default SvgRadioButtonChecked
