import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { InferQueryOutput } from "../../../api/trpc/trpcHelper"
import { TableRow } from "../../../components/table/Table"
import { ColorType, IconType, theme } from "../../../styles/stitches.config"
import { formatCurrency } from "../../../utils/format"
import { DueDateStateEnum, mapDueDateState, usePaymentTypeToText } from "../../../utils/payments"
import { assertUnreachable } from "../../../utils/types"
import { PaymentMethod, PaymentState, PaymentType, productionPaymentTypes } from "@nano-portal/shared"
import { MultiActionCellValue } from "../../../components/table/renderers"
import { PaymentsTableData } from "../PaymentsTable"
import { usePaymentAttachments } from "./usePaymentAttachments"
import { formatDate } from "../../../utils/date"
import Text from "../../../components/Text"
import { useModal } from "../../../hooks/useModal"
import { PaymentDetail } from "../InvoiceDetail"
import { useIsMaxSm } from "../../../hooks/mediaHooks"

function mapRowTypeToActionIconColors(rowType: TableRow["rowType"]): { color: ColorType; hoverColor: ColorType } {
  switch (rowType) {
    case "negative":
      return {
        color: "errors",
        hoverColor: "errorsHover",
      }
    case "warning":
      return {
        color: "warning",
        hoverColor: "warningHover",
      }
    case "success":
    case "successLight":
    case "default":
      return {
        color: "secondary",
        hoverColor: "warning",
      }
    case "disabled":
    case undefined:
      return {
        color: "textsAlt",
        hoverColor: "secondary",
      }
    default:
      return assertUnreachable(rowType)
  }
}

export const usePaymentsData = (deliveryPointId: number, ean?: string, data?: InferQueryOutput<"payments.list">) => {
  const { t } = useTranslation("payments")
  const { setModal } = useModal()
  const { downloadBillAttachments } = usePaymentAttachments()
  const isMaxSm = useIsMaxSm()
  const paymentTypeToText = usePaymentTypeToText()

  const getSubtextFromPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      switch (paymentMethod) {
        case PaymentMethod.BankEncashment:
        case PaymentMethod.BankOrder:
        case PaymentMethod.PostOrder:
        case PaymentMethod.PostSipo:
          return ""
        case PaymentMethod.BankRefund:
          return t("method_subtext_bank_refund")
        case PaymentMethod.NextAdvances:
          return t("method_subtext_next_advances")
        case PaymentMethod.PostRefund:
          return t("method_subtext_post_refund")
        default:
          return assertUnreachable(paymentMethod)
      }
    },
    [t]
  )

  const mapState = useCallback(
    (
      paymentState: PaymentState,
      paymentMethod: PaymentMethod,
      amount: number,
      amountToPair: number,
      paymentType: PaymentType
    ) => {
      const isProductionInvoice = productionPaymentTypes.includes(paymentType)
      switch (paymentState) {
        case PaymentState.Invoiced:
          return { text: t("state_invoiced"), subText: getSubtextFromPaymentMethod(paymentMethod) }
        case PaymentState.Uninvoiced:
          return { text: t("state_uninvoiced"), subText: getSubtextFromPaymentMethod(paymentMethod) }
        case PaymentState.Paid:
          return {
            text: isProductionInvoice || amount < 0 ? t("state_invoice_paid") : t("state_paid"),
            subText: getSubtextFromPaymentMethod(paymentMethod),
          }
        case PaymentState.Unpaid:
          if (amountToPair > 0 && amountToPair !== amount) {
            return {
              text: t("partial_payment"),
              subText: t("remains_to_pay", { amount: formatCurrency(amountToPair) }),
            }
          }

          if (isProductionInvoice || amount < 0) {
            return { text: t("state_invoice_unpaid"), subText: "" }
          }

          return { text: t("state_unpaid"), subText: getSubtextFromPaymentMethod(paymentMethod) }
        case PaymentState.Cancelled:
          return { text: t("state_cancelled"), subText: getSubtextFromPaymentMethod(paymentMethod) }
        case PaymentState.Included:
          return { text: t("state_included"), subText: "" } // nanos FIX: avoid inclusions with substring "Send via bank transfer" - inclusions are not sent
        case PaymentState.Unknown:
          return null
        default:
          return assertUnreachable(paymentState)
      }
    },
    [getSubtextFromPaymentMethod, t]
  )

  const mapType = useCallback(
    (paymentType: PaymentType) => {
      const type = {
        text: "",
        subText: "",
      }

      type.text = paymentTypeToText(paymentType)

      return type
    },
    [paymentTypeToText]
  )

  const mapRowStyle = useCallback(
    (
      paymentState: PaymentState,
      paymentType: PaymentType,
      dueDate: Date | null,
      amount: number,
      amountToPair: number,
      paymentMethod: PaymentMethod
    ) => {
      let icon: IconType = "Check"
      let rowType: TableRow["rowType"] = "default"

      // TODO zobrazovat stav u výrobních faktur, až je budeme znát
      const state = mapState(paymentState, paymentMethod, amount, amountToPair, paymentType)
      const type = mapType(paymentType)
      const dueDateInfo = {
        text: dueDate ? formatDate(dueDate) : undefined,
        subText: "",
      }

      const dueDateState = mapDueDateState(dueDate)

      if (dueDateState.days > 0) {
        rowType = "disabled"
      }

      if (paymentState === "paid" || paymentState === "invoiced" || paymentState === "included") {
        icon = "Check"
        rowType = "success"
      } else if (paymentState === "unpaid") {
        icon = "Cross"

        if (productionPaymentTypes.includes(paymentType)) {
          if (dueDateState.state !== DueDateStateEnum.PAST) {
            icon = "Waiting"
          }
        } else {
          switch (dueDateState.state) {
            case DueDateStateEnum.PAST:
              rowType = "negative"
              dueDateInfo.subText = t("days_after_payment", { count: Math.abs(dueDateState.days) })
              break
            case DueDateStateEnum.FUTURE:
              rowType = "warning"
              icon = "Info"
              dueDateInfo.subText = t("days_before_payment", { count: dueDateState.days })
              break
            case DueDateStateEnum.OK:
              icon = "Waiting"
              break
            default:
              assertUnreachable(dueDateState.state)
          }
        }
      }

      return {
        state,
        type,
        icon,
        rowType,
        dueDateInfo,
      }
    },
    [t, mapState, mapType]
  )

  const rows = useMemo<TableRow<PaymentsTableData>[]>(() => {
    if (!data) {
      return []
    }

    return data.map((item) => {
      const {
        dueDate,
        paymentDate,
        amount,
        state: paymentState,
        type: paymentType,
        attachmentUrl,
        amountToPair,
        id,
        paymentMethod,
        spotDataAvailable,
        paymentCode,
        forPeriod,
        bankAccount,
        variableCode,
        issuedBy,
      } = item

      const { state, type, icon, rowType, dueDateInfo } = mapRowStyle(
        paymentState,
        paymentType,
        dueDate,
        amount,
        amountToPair,
        paymentMethod
      )

      const hasDownloadableAttachments = !!attachmentUrl || spotDataAvailable
      const paymentQrCodeString = issuedBy === "supplier" ? item.paymentQrCodeString : undefined

      const paymentPending = rowType !== "success"
      const isReturnPayment = paymentType === PaymentType.Billing && amount <= 0
      const detailAvailable =
        !isReturnPayment && paymentPending && !productionPaymentTypes.includes(paymentType) && !!paymentQrCodeString
      const colAction: MultiActionCellValue | null = {
        justify: detailAvailable && !hasDownloadableAttachments ? "end" : "between",
        actions: [],
      }

      const iconColors = mapRowTypeToActionIconColors(rowType)
      if (hasDownloadableAttachments) {
        colAction.actions.push({
          icon: "Download",
          iconColor: iconColors.color,
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          callback: () => downloadBillAttachments({ id, paymentCode, attachmentUrl, spotDataAvailable, ean }),
          hoverIconColor: iconColors.hoverColor,
        })
      }

      if (detailAvailable) {
        colAction.actions.push({
          icon: "QrCode",
          iconColor: iconColors.color,
          hoverIconColor: iconColors.hoverColor,
          callback: !!paymentQrCodeString
            ? () =>
                setModal({
                  isFullscreenOnMobile: false,
                  padding: isMaxSm ? theme.space.s9 : undefined,
                  modal: (
                    <PaymentDetail
                      amount={amount}
                      dueDate={dueDate}
                      accountNumber={bankAccount}
                      qrPaymentCode={paymentQrCodeString}
                      variableCode={variableCode}
                      paymentMethod={paymentMethod}
                      amountToPair={amountToPair}
                      paymentState={paymentState}
                      paymentType={paymentType}
                      paymentDate={paymentDate}
                    />
                  ),
                })
            : undefined,
        })
      }

      return {
        colDueDate: dueDate
          ? {
              date: dueDate,
              icon,
              maxMediaIconVisibility: "md",
              justify: "center",
              subText: dueDateInfo.subText,
            }
          : null,
        colPeriod: forPeriod,
        colAmount:
          amount !== undefined && amount !== null
            ? {
                amount: amount,
                subText: type.text,
                maxMediaSubTextVisibility: "md",
              }
            : null,
        colState: state
          ? {
              text: state.text,
              subText: state.subText,
              icon,
              hoverConfig: !paymentDate
                ? undefined
                : {
                    content: (
                      <>
                        <Text type="labelsAlt" css={{ display: "block" }}>
                          {t("payment_date")}
                        </Text>
                        <Text type="buttons">{formatDate(paymentDate)}</Text>
                      </>
                    ),
                    underline: true,
                  },
            }
          : null,
        colAction,
        colType: {
          text: type.text,
        },
        rowType,
      }
    })
  }, [data, mapRowStyle, t, downloadBillAttachments, ean, setModal, isMaxSm])

  return rows
}
