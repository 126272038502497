import * as React from "react"

function SvgHamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 374" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 375"
        d="M4 18h16a1 1 0 000-2H4a1 1 0 000 2zm0-5h16a1 1 0 000-2H4a1 1 0 000 2zM3 7a1 1 0 001 1h16a1 1 0 000-2H4a1 1 0 00-1 1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHamburger
