import { captureException } from "@sentry/react"

const DAKTELA_ROOT_ELEMENT_SELECTOR = "#daktela-web"
const EMAIL_INPUT_NAME = "email"

function getInputByName(name: string) {
  const element = document.querySelector(DAKTELA_ROOT_ELEMENT_SELECTOR)?.querySelector(`[name="${name}"]`)
  if (element instanceof HTMLInputElement) {
    return element
  }

  return element instanceof HTMLInputElement ? element : undefined
}

export function fillUserInfoToDaktela(email: string) {
  try {
    const emailInput = getInputByName(EMAIL_INPUT_NAME)
    if (emailInput && emailInput.value !== email) {
      emailInput.value = email
    }
  } catch (error) {
    captureException(error)
  }
}

export function clearUserInfoFromDaktela() {
  try {
    const emailInput = getInputByName(EMAIL_INPUT_NAME)
    if (emailInput) {
      emailInput.value = ""
    }
  } catch (error) {
    captureException(error)
  }
}
