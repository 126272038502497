import { useParams } from "react-router"
import { trpc } from "../../api/trpc/trpc"
import { Flexi2022ApiResponse } from "./Flexi2022ApiResponse"
import { Flex } from "../../components/flex/Flex"
import Loader from "../../components/loader/Loader"
import { useTranslation } from "react-i18next"
import { Flexi2022Variant } from "@nano-portal/shared"

function Flexi2022SendContract() {
  const { t } = useTranslation(["flexi2022"])

  return (
    <Flexi2022ApiResponse
      isError
      heading={t("flexi2022:errors_invalid_signature_heading")}
      description={t("flexi2022:errors_invalid_signature_text")}
    />
  )
}

function Flexi2022Content(props: { hash: string; variant: Flexi2022Variant }) {
  const { hash, variant } = props

  const { t } = useTranslation(["flexi2022"])

  const statusQuery = trpc.useQuery(["flexi2022.status", { hash: hash ?? "", variant }], {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 1,
  })

  if (statusQuery.isLoading) {
    return (
      <Flex align="center" justify="center" css={{ fullheight: {} }}>
        <Loader />
      </Flex>
    )
  }

  if (statusQuery.isError) {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexi2022:errors_common_heading")}
        description={t("flexi2022:errors_common_text")}
      />
    )
  }

  if (statusQuery.data?.alreadyUsed) {
    return (
      <Flexi2022ApiResponse
        heading={t("flexi2022:errors_contract_sent_heading")}
        description={t("flexi2022:errors_contract_sent_text")}
      />
    )
  }

  if (!statusQuery.data?.signatureValid) {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexi2022:errors_invalid_link_heading")}
        description={t("flexi2022:errors_invalid_link_text")}
      />
    )
  }

  return <Flexi2022SendContract />
}

export function Flexi2022({ variant }: { variant: Flexi2022Variant }) {
  const { hash } = useParams()
  const { t } = useTranslation(["flexi2022"])

  if (!hash) {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexi2022:errors_invalid_link_heading")}
        description={t("flexi2022:errors_invalid_link_text")}
      />
    )
  }

  return <Flexi2022Content hash={hash} variant={variant} />
}
