import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ColorType, IconType } from "../../styles/stitches.config"
import { ConsumptionPeriodType, OverviewState, ProductionPeriodType } from "@nano-portal/shared"

export interface PeriodOverviewDescriptionItem<V> {
  icon?: IconType
  color: ColorType
  label: string
  value?: V
  state: OverviewState
  baseDescription?: string
  detailedDescription?: string
}

interface PeriodOverviewDescription {
  consumption: PeriodOverviewDescriptionItem<ConsumptionPeriodType>[]
  production: PeriodOverviewDescriptionItem<ProductionPeriodType>[]
  all: PeriodOverviewDescriptionItem<ConsumptionPeriodType | ProductionPeriodType>[]
}

export const usePeriodOverviewDescriptions = () => {
  const { t } = useTranslation(["overview"])
  const overviewPeriodDescriptions: PeriodOverviewDescription = useMemo(() => {
    // TODO udělat z polí mapy s "value" jako klíči - nerozbije nám to někde něco?
    const consumption: PeriodOverviewDescriptionItem<ConsumptionPeriodType>[] = [
      {
        icon: "Questionmark",
        color: "primary",
        label: t("overview:missing_data"),
        state: OverviewState.Unknown,
        baseDescription: t("overview:missing_data_tooltip"),
      },
      {
        color: "primary",
        label: t("overview:confirmed"),
        value: ConsumptionPeriodType.ConfirmedHistory,
        state: OverviewState.Known,
        baseDescription: t("overview:confirmed_tooltip"),
      },
      {
        color: "textsAlt",
        label: t("overview:unconfirmed"),
        value: ConsumptionPeriodType.UnconfirmedHistory,
        state: OverviewState.Known,
        baseDescription: t("overview:unconfirmed_tooltip"),
      },
      // TODO prozatím schováno, dokud nebude zelený řádek
      // { color: "secondary", label: t("overview:billing"), value: ConsumptionPeriodType.Billing },
      {
        color: "textsDisabeled",
        label: t("overview:prediction"),
        value: ConsumptionPeriodType.Prediction,
        state: OverviewState.Known,
        baseDescription: t("overview:prediction_consumption_tooltip"),
      },
    ]

    const production: PeriodOverviewDescriptionItem<ProductionPeriodType>[] = [
      {
        color: "primary",
        label: t("overview:confirmed"),
        value: ProductionPeriodType.ConfirmedHistory,
        state: OverviewState.Known,
        baseDescription: t("overview:confirmed_tooltip"),
      },
      {
        color: "textsDisabeled",
        label: t("overview:prediction"),
        value: ProductionPeriodType.Prediction,
        state: OverviewState.Known,
        baseDescription: t("overview:prediction_production_base_tooltip"),
        detailedDescription: t("overview:prediction_production_detailed_tooltip"),
      },
    ]

    return {
      consumption,
      production,
      all: [...consumption, ...production],
    }
  }, [t])

  return overviewPeriodDescriptions
}
