import * as React from "react"

function SvgSupplyPoints(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 55">
        <path data-name="Path 55" d="M0 0h24v24H0z" fill="none" />
      </g>
      <g data-name="Group 57">
        <g data-name="Group 56" fill="currentColor">
          <path
            data-name="Path 56"
            d="M14.16 10.4l-5-3.57a1.982 1.982 0 00-2.32 0l-5 3.57A2 2 0 001 12.03V20a1 1 0 001 1h4v-6h4v6h4a1 1 0 001-1v-7.97a2 2 0 00-.84-1.63z"
          />
          <path
            data-name="Path 57"
            d="M21.03 3h-9.06A1.967 1.967 0 0010 4.97l.09.09c.08.05.16.09.24.14l5 3.57A4.023 4.023 0 0116.87 11H19v2h-2v2h2v2h-2v4h4.03A1.967 1.967 0 0023 19.03V4.97A1.967 1.967 0 0021.03 3zM19 9h-2V7h2z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgSupplyPoints
