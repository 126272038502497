import { useTranslation } from "react-i18next"
import ChartLegend, { LegendItem } from "../chart/ChartLegend"
import { theme } from "../../styles/stitches.config"
import { Flex } from "../flex/Flex"
import { Spacer } from "../spacer/Spacer"
import TableComponent, { TableData } from "../table/Table"
import Text from "../Text"
import { OverviewTableData, OverviewTableSummary, usePeriodOverviewColumns } from "./usePeriodOverviewColumns"
import { SHeaderContainer } from "../../sections/overview/periodOverviewRenderers.styled"
import { useMemo } from "react"
import EmptyTablePlaceholder from "../table/EmptyTablePlaceholder"
import { InfoText } from "../info/InfoText"
import { OverviewGroup, ConsumptionPeriodType } from "@nano-portal/shared"

interface PeriodOverviewProps {
  type: "production" | "consumption"
  data: TableData<OverviewTableData>
  summary: OverviewTableSummary
  group: OverviewGroup
  errorState?: boolean
  isUnknownState?: boolean
  isHiddenFixedPricelist?: boolean
}

const ErrorStatePlaceholder = () => {
  const { t } = useTranslation(["overview"])
  return (
    <EmptyTablePlaceholder
      css={{ height: theme.sizes.s90.value }}
      icon="MissingInvoices"
      mainText={t("overview:error_period_payments_header")}
      explanationText={t("overview:error_overview_label")}
    />
  )
}

const UnknownStatePlaceholder = () => {
  const { t } = useTranslation(["overview"])
  return (
    <EmptyTablePlaceholder
      css={{ height: theme.sizes.s90.value }}
      icon="NoInvoices"
      mainText={t("overview:unknown_overview_state_header")}
    />
  )
}

const HiddenFixedPricelistPlaceholder = () => {
  const { t } = useTranslation(["overview"])
  return (
    <EmptyTablePlaceholder
      css={{ height: theme.sizes.s90.value }}
      icon="NoInvoices"
      mainText={t("overview:unknown_fix_pricelist_prices_header")}
      explanationText={t("overview:unknown_fix_pricelist_prices_label")}
    />
  )
}

export const PeriodOverview = ({
  data,
  summary,
  group,
  errorState,
  isUnknownState,
  isHiddenFixedPricelist,
  type,
}: PeriodOverviewProps) => {
  const { t } = useTranslation(["overview"])
  const [columns, consumptionPeriodDescriptions, productionPeriodDescriptions] = usePeriodOverviewColumns(
    summary,
    group,
    type
  )

  const legendItems: LegendItem[] = useMemo(
    () =>
      (type === "consumption" ? consumptionPeriodDescriptions : productionPeriodDescriptions).map((desc) => ({
        ...desc,
        tooltipType: "inline",
        tooltipContent: <InfoText type="textsSmall">{desc.detailedDescription || desc.baseDescription}</InfoText>,
      })),
    [consumptionPeriodDescriptions, productionPeriodDescriptions, type]
  )

  const tableData: TableData<OverviewTableData> = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        rowType: row.type === ConsumptionPeriodType.Billing ? "successLight" : "default",
      })),
    [data]
  )

  return (
    <>
      <SHeaderContainer>
        <Text type="headerH1">{t(`overview:${type}_overview_header`)}</Text>

        <Flex css={{ gap: theme.space.s6, flexWrap: "wrap" }}>
          <ChartLegend items={legendItems} />
        </Flex>
      </SHeaderContainer>

      <Spacer size={theme.space.s6} />

      <TableComponent
        fixedLayout
        data={isHiddenFixedPricelist ? [] : tableData}
        columns={columns}
        size="small"
        horizontallyScrollable
        emptyPlaceholder={
          errorState ? (
            <ErrorStatePlaceholder />
          ) : isUnknownState ? (
            <UnknownStatePlaceholder />
          ) : isHiddenFixedPricelist ? (
            <HiddenFixedPricelistPlaceholder />
          ) : undefined
        }
        paddingRight="large"
      />
    </>
  )
}
