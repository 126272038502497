import { useCallback, useMemo } from "react"
import { fontVariants, theme } from "../../styles/stitches.config"
import { useIsMaxMd, useIsMaxSm, useIsMinXl, useIsMobileVersion } from "../../hooks/mediaHooks"
import { getLocale } from "../../i18n/config"
import { OverviewGroup } from "@nano-portal/shared"
const MaxBandwidth = 48
const FullSizeFont = 16
const ReducedSizeFont = 10

export const CHART_X_PADDING = 70
export const CHART_X_PADDING_MOBILE = 40
export const CHART_Y_PADDING = 48

const alignToCenterDynamicPadding = 2
const alignToCenterStaticPadding = 0.92

const defaultChartDomain = [0, 10]

export const TickDensitiesGroup: {
  [K in OverviewGroup]: {
    sm: number
    md: number
    lg: number
  }
} = {
  [OverviewGroup.Years]: { sm: 0.5, md: 1, lg: 1 },
  [OverviewGroup.Months]: { sm: 0.5, md: 0.5, lg: 1 },
  [OverviewGroup.Days]: { sm: 0.5, md: 1, lg: 1 },
  [OverviewGroup.Hours]: { sm: 0.25, md: 0.5, lg: 0.5 },
}

export type TickDensities = typeof TickDensitiesGroup[OverviewGroup]

export const useBandwidth = <T extends { toString(): string }>(scaleX: d3.ScaleBand<T>) => {
  const bw = useMemo(() => {
    const bandwidth = Math.min(scaleX.bandwidth(), MaxBandwidth)
    const labelBandwidth = bandwidth * 0.6

    return [bandwidth, labelBandwidth]
  }, [scaleX])

  return bw as [number, number]
}

export const useIsMobileChart = () => {
  return useIsMobileVersion()
}

export const useChartPadding = () => {
  const isMaxSm = useIsMaxSm()
  const xPadding = isMaxSm ? CHART_X_PADDING_MOBILE : CHART_X_PADDING
  return [xPadding, CHART_Y_PADDING] as const
}

export const useXAxisTicks = <T,>(domain: T[], tickDensities: TickDensities, centered?: boolean) => {
  const isSmall = useIsMaxSm()
  const isMedium = useIsMaxMd()
  const isExtraLarge = useIsMinXl()

  if (isExtraLarge || (centered && domain.length <= 4)) {
    return domain
  }

  const tickDensity = isSmall ? tickDensities.sm : isMedium ? tickDensities.md : tickDensities.lg
  const tickCount = Math.floor(domain.length * tickDensity)
  const interval = Math.max(1, Math.floor(domain.length / tickCount))

  return domain.filter((_, index) => index % interval === 0)
}

export const useChartRange = (width: number, height: number, barsCount?: number, centered?: boolean) => {
  const [xPadding, yPadding] = useChartPadding()

  const res = useMemo(() => {
    let xStart = 1.6 * xPadding
    let xEnd = width - xPadding

    if (barsCount && centered) {
      // range is aligned to center to avoid sparse bars
      const rangeWidth = xEnd - xStart
      const rangePadding =
        (2 - 1 / alignToCenterDynamicPadding + barsCount / alignToCenterDynamicPadding) / alignToCenterStaticPadding
      xStart += rangeWidth / rangePadding
      xEnd -= rangeWidth / rangePadding
    }

    const rangeX = [xStart, xEnd]
    const rangeY = [height - yPadding, yPadding]

    return [rangeX, rangeY] as const
  }, [xPadding, width, barsCount, centered, height, yPadding])

  return res
}

export function createDomain(minValue: number, maxValue: number) {
  if (minValue === 0 && maxValue === 0) {
    return defaultChartDomain
  }

  if (minValue === maxValue) {
    return [minValue - 1, maxValue + 1]
  }

  return [minValue, maxValue]
}

export const useAxisRenderers = (
  width: number,
  scaleY: [d3.ScaleLinear<number, number>, d3.ScaleLinear<number, number>] | [d3.ScaleLinear<number, number>],
  unitLabel?: string | [string, string]
) => {
  const isMobile = useIsMobileChart()
  const [xPadding] = useChartPadding()
  const fontSize = isMobile ? ReducedSizeFont : FullSizeFont
  const [scaleY1, scaleY2] = scaleY
  const [ticksY1, ticksY2] = useMemo(() => [scaleY[0].ticks(8), scaleY[1] ? scaleY[1].ticks(8) : undefined], [scaleY])

  const formatNumberTick = useCallback((tick: number) => {
    return new Intl.NumberFormat(getLocale(), { useGrouping: true }).format(tick)
  }, [])

  const renderHorizontalLines = useCallback(
    () =>
      ticksY1.map((tick) => (
        <line
          key={tick}
          x1={1.3 * xPadding}
          x2={width - xPadding}
          y1={scaleY1(tick)}
          y2={scaleY1(tick)}
          stroke={theme.colors.textsDisabeled.value}
          opacity={0.3}
        />
      )),
    [ticksY1, width, scaleY1, xPadding]
  )

  const renderZeroHorizontalLine = useCallback(
    (color?: string) => (
      <>
        {ticksY1.length > 1 && (
          <line
            x1={1.3 * xPadding}
            x2={width - xPadding}
            y1={scaleY1(0)}
            y2={scaleY1(0)}
            stroke={color ? color : theme.colors.white.value}
            strokeWidth={3}
          />
        )}
      </>
    ),
    [width, scaleY1, ticksY1, xPadding]
  )

  const renderHorizontalLinesTicks = useCallback(() => {
    return (
      <>
        {ticksY1.length > 1 &&
          ticksY1.map((tick) => (
            <text
              key={tick}
              x={xPadding + fontSize * 0.5}
              y={scaleY1(tick) + fontSize * 0.25}
              textAnchor="end"
              style={{ ...fontVariants.textsSmall, fontSize }}
              fill={theme.colors.textsAlt.value}
            >
              {formatNumberTick(tick)}
            </text>
          ))}
        {scaleY2 &&
          ticksY2 &&
          ticksY2.length > 1 &&
          ticksY2.map((tick) => (
            <text
              key={tick}
              x={width - xPadding + fontSize * 0.5}
              y={scaleY2(tick) + fontSize * 0.25}
              textAnchor="start"
              style={{ ...fontVariants.textsSmall, fontSize }}
              fill={theme.colors.textsAlt.value}
            >
              {formatNumberTick(tick)}
            </text>
          ))}
      </>
    )
  }, [ticksY1, ticksY2, fontSize, scaleY1, scaleY2, width, formatNumberTick, xPadding])

  const renderHorizontalLineUnitLabel = useCallback(() => {
    const y1Label = typeof unitLabel === "string" ? unitLabel : unitLabel?.[0]
    const y2Label = typeof unitLabel === "string" ? undefined : unitLabel?.[1]

    return (
      <>
        {y1Label && (
          <text
            x={xPadding}
            y={fontSize}
            textAnchor="middle"
            style={{ ...fontVariants.textsSmall, fontWeight: 600, fontSize }}
            fill={theme.colors.textsAlt.value}
          >
            {y1Label}
          </text>
        )}
        {y2Label && (
          <text
            x={width - xPadding}
            y={fontSize}
            textAnchor="start"
            style={{ ...fontVariants.textsSmall, fontWeight: 600, fontSize }}
            fill={theme.colors.textsAlt.value}
          >
            {y2Label}
          </text>
        )}
      </>
    )
  }, [fontSize, unitLabel, width, xPadding])

  return { renderHorizontalLines, renderZeroHorizontalLine, renderHorizontalLinesTicks, renderHorizontalLineUnitLabel }
}

export const useWorstToBestPriceLevels = (min: number, max: number, current?: number | null) => {
  const result = useMemo(() => {
    if (current === undefined || current === null) {
      return
    }

    const diff = max - min
    const step = diff / 5

    if (current < min + step) {
      return "BEST"
    } else if (current < min + 2 * step) {
      return "GOOD"
    } else if (current < min + 3 * step) {
      return "NEUTRAL"
    } else if (current < min + 4 * step) {
      return "BAD"
    }
    return "WORST"
  }, [min, max, current])

  return result
}
