import * as React from "react"

function SvgHousehold(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" {...props}>
      <defs>
        <clipPath id="_Household_svg__a">
          <path data-name="Rectangle 17667" fill="none" d="M0 0h250v250H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17795">
        <path data-name="Path 60088" d="M73.744 46.56l.8-.464 6.557 11.32-.8.464z" fill="#00515f" />
        <g data-name="Group 17770">
          <g data-name="Group 17769" clipPath="url(#_Household_svg__a)">
            <path
              data-name="Path 60089"
              d="M80.302 58.13a.254.254 0 01-.064-.009.25.25 0 01-.152-.116l-6.558-11.32a.25.25 0 01.091-.342l.8-.464a.25.25 0 01.342.091l6.557 11.32a.25.25 0 01-.091.341l-.8.465a.249.249 0 01-.125.034m-6.216-11.479l6.307 10.887.367-.214-6.307-10.887z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60090" d="M73.516 46.69l.8-.464.228-.13-.8.465z" fill="#fff" />
            <path
              data-name="Path 60091"
              d="M73.516 46.94a.25.25 0 01-.126-.466l1.029-.6a.25.25 0 01.25.433l-1.03.6a.248.248 0 01-.123.033"
              fill="#1d1d1b"
            />
            <path data-name="Path 60092" d="M73.744 46.56l6.557 11.32-.228.132-6.557-11.321z" fill="#00515f" />
            <path
              data-name="Path 60093"
              d="M73.744 46.56l6.557 11.32-.228.132-6.557-11.321zm0-.5a.5.5 0 00-.247.066l-.228.13a.5.5 0 00-.185.685l6.557 11.321a.5.5 0 00.683.183l.228-.132a.5.5 0 00.183-.684L74.177 46.31a.5.5 0 00-.433-.25"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60094"
              d="M83.902 56.687l-.246 86.917a1.1 1.1 0 01-.644.9 3.453 3.453 0 01-3.133 0 1.108 1.108 0 01-.654-.914l.246-86.916a1.109 1.109 0 00.654.913 3.453 3.453 0 003.133 0 1.1 1.1 0 00.644-.9"
              fill="#fff"
            />
            <path
              data-name="Path 60095"
              d="M81.448 145.128a3.449 3.449 0 01-1.694-.412 1.344 1.344 0 01-.779-1.131l.247-86.917a.25.25 0 01.25-.249.25.25 0 01.249.251.878.878 0 00.529.7 3.229 3.229 0 002.882 0 .868.868 0 00.519-.685.25.25 0 01.25-.249.25.25 0 01.249.251l-.246 86.916a1.336 1.336 0 01-.768 1.117 3.426 3.426 0 01-1.69.412M79.717 57.61l-.244 85.98a.878.878 0 00.529.7 3.229 3.229 0 002.882 0 .869.869 0 00.519-.685l.244-85.98a2.106 2.106 0 01-.265.181 3.679 3.679 0 01-3.383 0 2.144 2.144 0 01-.282-.193"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60096"
              d="M83.248 55.773a.959.959 0 01.01 1.815 3.455 3.455 0 01-3.133 0 .959.959 0 01-.01-1.815 3.451 3.451 0 013.133 0"
              fill="#fff"
            />
            <path
              data-name="Path 60097"
              d="M81.694 58.216A3.45 3.45 0 0180 57.804a1.2 1.2 0 01-.011-2.247 3.68 3.68 0 013.384 0 1.2 1.2 0 01.011 2.247 3.426 3.426 0 01-1.69.412m-.014-2.572a2.924 2.924 0 00-1.439.345.868.868 0 00-.52.688.879.879 0 00.529.694 3.23 3.23 0 002.882 0 .867.867 0 00.519-.688.879.879 0 00-.529-.694 2.952 2.952 0 00-1.444-.345"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60098"
              d="M84.039 57.976v1.2a1.168 1.168 0 01-.683.957 3.668 3.668 0 01-3.327 0 1.177 1.177 0 01-.695-.97v-1.2a1.177 1.177 0 00.695.97 3.668 3.668 0 003.327 0 1.168 1.168 0 00.683-.957"
              fill="#00515f"
            />
            <path
              data-name="Path 60099"
              d="M81.694 60.782a3.647 3.647 0 01-1.79-.436 1.411 1.411 0 01-.82-1.187v-1.2a.25.25 0 01.25-.249.25.25 0 01.25.25.949.949 0 00.57.749 3.447 3.447 0 003.076 0 .936.936 0 00.559-.741.25.25 0 01.25-.249.25.25 0 01.25.25v1.2a1.4 1.4 0 01-.808 1.173 3.621 3.621 0 01-1.787.436m-2.11-1.855v.232a.945.945 0 00.57.754 3.447 3.447 0 003.076 0 .936.936 0 00.559-.742v-.238a2.184 2.184 0 01-.308.215 3.89 3.89 0 01-3.577 0 2.2 2.2 0 01-.32-.222"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60100"
              d="M84.039 61.249v1.2a1.168 1.168 0 01-.683.957 3.668 3.668 0 01-3.327 0 1.177 1.177 0 01-.695-.97v-1.2a1.177 1.177 0 00.695.97 3.668 3.668 0 003.327 0 1.168 1.168 0 00.683-.957"
              fill="#00515f"
            />
            <path
              data-name="Path 60101"
              d="M81.694 64.055a3.647 3.647 0 01-1.79-.436 1.411 1.411 0 01-.82-1.187v-1.2a.25.25 0 01.25-.249.25.25 0 01.25.25.949.949 0 00.57.749 3.447 3.447 0 003.076 0 .936.936 0 00.559-.741.25.25 0 01.25-.249.25.25 0 01.25.25v1.2a1.4 1.4 0 01-.808 1.173 3.619 3.619 0 01-1.787.436m-2.11-1.855v.232a.945.945 0 00.57.754 3.447 3.447 0 003.076 0 .936.936 0 00.559-.742v-.238a2.184 2.184 0 01-.308.215 3.89 3.89 0 01-3.577 0 2.2 2.2 0 01-.32-.222"
              fill="#1d1d1b"
            />
            <path data-name="Path 60102" d="M89.371 54.988l.8-.464.225.392-.8.464z" fill="#fff" />
            <path
              data-name="Path 60103"
              d="M89.595 55.63a.247.247 0 01-.064-.008.251.251 0 01-.152-.117l-.225-.392a.25.25 0 01.091-.341l.8-.464a.25.25 0 01.342.091l.225.392a.25.25 0 01-.091.341l-.8.465a.25.25 0 01-.126.034"
              fill="#1d1d1b"
            />
            <path data-name="Path 60104" d="M83.027 58.567l.543-.32 6.6-3.723-.8.465z" fill="#00515f" />
            <path
              data-name="Path 60105"
              d="M83.027 58.816a.25.25 0 01-.127-.465l.543-.32 6.6-3.725a.25.25 0 01.248.434l-.8.465-6.346 3.579a.249.249 0 01-.122.032"
              fill="#1d1d1b"
            />
            <path data-name="Path 60106" d="M89.371 54.988l.225.392-6.6 3.725.032-.538z" fill="#00515f" />
            <path
              data-name="Path 60107"
              d="M89.37 54.988l.225.392-6.6 3.725.032-.538zm0-.5a.5.5 0 00-.245.064l-6.343 3.578a.5.5 0 00-.254.406l-.031.538a.5.5 0 00.745.465l6.6-3.725a.5.5 0 00.188-.684l-.225-.392a.5.5 0 00-.434-.251"
              fill="#1d1d1b"
            />
            <path data-name="Path 60108" d="M98.747 60.31l1.6-.929-.005 1.7-1.6.929z" fill="#00515f" />
            <path
              data-name="Path 60109"
              d="M98.742 62.258a.249.249 0 01-.25-.251v-1.7a.251.251 0 01.124-.215l1.6-.929a.25.25 0 01.376.217v1.7a.25.25 0 01-.125.215l-1.6.929a.25.25 0 01-.125.034m.255-1.805v1.12l1.1-.638v-1.12z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60110" d="M65.403 41.058l1.6-.929 33.344 19.251-1.6.929z" fill="#00515f" />
            <path
              data-name="Path 60111"
              d="M98.747 60.564a.25.25 0 01-.125-.033L65.278 41.274a.25.25 0 010-.433l1.6-.929a.249.249 0 01.25 0l33.343 19.252a.25.25 0 010 .433l-1.6.929a.251.251 0 01-.126.034m-32.846-19.5l32.845 18.963 1.1-.64-32.842-18.965z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60112" d="M98.747 60.31v1.7L65.403 42.759v-1.7z" fill="#00515f" />
            <path
              data-name="Path 60113"
              d="M98.742 62.258a.251.251 0 01-.125-.033L65.273 42.976a.249.249 0 01-.125-.217v-1.7a.25.25 0 01.375-.216l33.344 19.251a.25.25 0 01.125.217l-.005 1.7a.25.25 0 01-.25.249M65.643 42.615l32.85 18.961v-1.123L65.656 41.49z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60114"
              d="M69.429 36.303l-.013 4.623a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60115"
              d="M67.365 42.234a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.131.131 0 01.125-.124.125.125 0 01.125.125.913.913 0 00.545.74 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.124.125l-.013 4.623a1.138 1.138 0 01-.66.944 3.082 3.082 0 01-1.515.364m-1.926-5.324l-.011 4a.913.913 0 00.545.74 3.071 3.071 0 002.782 0 .9.9 0 00.535-.729l.011-4a1.626 1.626 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.642 1.642 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60116"
              d="M68.822 35.455a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.205 3.205 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60117"
              d="M67.379 37.611a3.1 3.1 0 01-1.518-.363 1.011 1.011 0 01-.009-1.9 3.346 3.346 0 013.033 0 1.012 1.012 0 01.01 1.9 3.08 3.08 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.071 3.071 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.848 2.848 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60118"
              d="M70.626 39.051v.555a1.619 1.619 0 01-.947 1.326 5.079 5.079 0 01-4.608 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60119"
              d="M67.376 41.606a4.844 4.844 0 01-2.37-.567 1.747 1.747 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.134.134 0 01.125.126 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.131.131 0 01.125-.124.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.811 4.811 0 01-2.364.567m-3.109-1.756a1.708 1.708 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.957 2.372 2.372 0 01-.722.618 5.224 5.224 0 01-4.733 0 2.39 2.39 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60120"
              d="M69.663 37.707c1.277.738 1.283 1.933.015 2.669a5.076 5.076 0 01-4.608 0c-1.276-.736-1.283-1.932-.015-2.669a5.082 5.082 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60121"
              d="M67.378 41.051a4.847 4.847 0 01-2.37-.567 1.532 1.532 0 01-.015-2.886 5.224 5.224 0 014.733 0 1.532 1.532 0 01.016 2.886 4.812 4.812 0 01-2.364.567m-.021-3.773a4.556 4.556 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.515 1.515 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60122"
              d="M69.429 34.381l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60123"
              d="M67.365 40.315a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.956l.013-4.624a.123.123 0 01.125-.124.125.125 0 01.125.125.913.913 0 00.545.739 3.069 3.069 0 002.782 0 .9.9 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.124.126l-.013 4.623a1.138 1.138 0 01-.66.944 3.078 3.078 0 01-1.515.363m-1.927-5.324l-.011 4a.913.913 0 00.545.739 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729l.011-4a1.632 1.632 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.648 1.648 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60124"
              d="M68.822 33.536a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.205 3.205 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60125"
              d="M67.379 35.693a3.1 3.1 0 01-1.518-.363 1.011 1.011 0 01-.009-1.9 3.346 3.346 0 013.033 0 1.012 1.012 0 01.01 1.9 3.08 3.08 0 01-1.515.364m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.07 3.07 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60126"
              d="M70.631 37.22v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.961-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60127"
              d="M67.383 39.776a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.129.129 0 01.125.126 1.513 1.513 0 00.9 1.235 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.126v.55a1.736 1.736 0 01-1.009 1.433 4.807 4.807 0 01-2.364.567m-3.109-1.756a1.708 1.708 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.367 2.367 0 01-.722.617 5.222 5.222 0 01-4.734 0 2.384 2.384 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60128"
              d="M69.669 35.876c1.278.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60129"
              d="M67.384 39.221a4.844 4.844 0 01-2.37-.567 1.532 1.532 0 01-.016-2.885 5.223 5.223 0 014.734 0 1.75 1.75 0 011.025 1.447 1.732 1.732 0 01-1.009 1.438 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60130"
              d="M69.434 34.291l-.013 2.811a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60131"
              d="M67.372 38.41a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-2.81a.125.125 0 01.125-.124.171.171 0 00.022 0 .486.486 0 01.647-.21 3.07 3.07 0 002.782 0 .48.48 0 01.642.22h.018a.125.125 0 01.125.126v.007l-.013 2.8a1.138 1.138 0 01-.659.944 3.079 3.079 0 01-1.515.364m-1.924-3.977l-.012 2.661a.913.913 0 00.545.739 3.071 3.071 0 002.782 0 .9.9 0 00.535-.729l.012-2.664a.638.638 0 01-.064-.081c-.149-.21-.233-.257-.344-.193a3.348 3.348 0 01-3.033 0c-.116-.068-.2-.022-.355.186a.657.657 0 01-.065.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60132"
              d="M70.625 35.32v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.961-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60133"
              d="M67.376 37.871a4.843 4.843 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.133.133 0 01.125.126 1.513 1.513 0 00.9 1.235 4.949 4.949 0 004.483 0 1.5 1.5 0 00.885-1.218.137.137 0 01.125-.124.125.125 0 01.125.126v.555a1.736 1.736 0 01-1.009 1.433 4.807 4.807 0 01-2.364.567m-3.109-1.756a1.71 1.71 0 00.865.973 4.949 4.949 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.224 5.224 0 01-4.733 0 2.388 2.388 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60134"
              d="M69.663 33.976c1.278.737 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.933-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60135"
              d="M67.377 37.321a4.844 4.844 0 01-2.37-.567 1.75 1.75 0 01-1.024-1.447 1.733 1.733 0 011.008-1.438 5.223 5.223 0 014.734 0 1.751 1.751 0 011.025 1.447 1.733 1.733 0 01-1.009 1.438 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60136"
              d="M69.428 34.401l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60137"
              d="M67.367 36.504a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.956l.013-.8a.122.122 0 01.126-.123.125.125 0 01.124.126.913.913 0 00.545.739 3.069 3.069 0 002.782 0 .916.916 0 00.535-.712v-.018a.125.125 0 01.125-.123.125.125 0 01.124.125v.022l-.013.776a1.138 1.138 0 01-.659.942 3.078 3.078 0 01-1.515.364m-1.935-1.506v.188a.913.913 0 00.545.738 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729v-.168a1.64 1.64 0 01-.4.316 3.346 3.346 0 01-3.033 0 1.631 1.631 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60138"
              d="M68.821 33.553a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60139"
              d="M67.379 35.708a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.148 1.148 0 01.669.953 1.136 1.136 0 01-.659.947 3.079 3.079 0 01-1.515.363m-.013-2.381a2.826 2.826 0 00-1.389.333.9.9 0 00-.535.731.915.915 0 00.545.737 3.07 3.07 0 002.782 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60140"
              d="M84.131 44.599l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60141"
              d="M82.069 50.53a3.106 3.106 0 01-1.518-.363 1.147 1.147 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.125.125.125 0 01.125.126.912.912 0 00.545.739 3.069 3.069 0 002.782 0 .9.9 0 00.535-.729.125.125 0 01.125-.124.125.125 0 01.125.125l-.013 4.623a1.139 1.139 0 01-.659.944 3.083 3.083 0 01-1.515.363m-1.926-5.324l-.012 4a.913.913 0 00.545.74 3.071 3.071 0 002.783 0 .905.905 0 00.535-.729l.012-4a1.628 1.628 0 01-.408.326 3.346 3.346 0 01-3.033 0 1.642 1.642 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60142"
              d="M83.525 43.751a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60143"
              d="M82.083 45.906a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.348 3.348 0 013.033 0 1.012 1.012 0 01.009 1.9 3.081 3.081 0 01-1.514.363m-.014-2.381a2.827 2.827 0 00-1.389.333.9.9 0 00-.535.731.914.914 0 00.545.737 3.068 3.068 0 002.782 0 .9.9 0 00.535-.731.915.915 0 00-.544-.737 2.852 2.852 0 00-1.394-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60144"
              d="M85.329 47.346v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.608 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60145"
              d="M82.079 49.902a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.122.122 0 01.125.126 1.513 1.513 0 00.9 1.235 4.949 4.949 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.124.125.125 0 01.125.126v.555a1.736 1.736 0 01-1.009 1.433 4.807 4.807 0 01-2.364.567m-3.109-1.756a1.71 1.71 0 00.865.973 4.949 4.949 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.224 5.224 0 01-4.733 0 2.387 2.387 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60146"
              d="M84.364 46.002c1.277.737 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.933-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60147"
              d="M82.08 49.346a4.845 4.845 0 01-2.37-.567 1.749 1.749 0 01-1.024-1.447 1.731 1.731 0 011.008-1.438 5.224 5.224 0 014.734 0 1.532 1.532 0 01.015 2.885 4.81 4.81 0 01-2.364.567m-.021-3.773a4.555 4.555 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.515 1.515 0 00.9 1.231 4.948 4.948 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60148"
              d="M84.131 42.681l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60149"
              d="M82.069 48.611a3.106 3.106 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.125.121.121 0 01.125.126.912.912 0 00.545.739 3.069 3.069 0 002.782 0 .905.905 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.125.125l-.013 4.623a1.138 1.138 0 01-.659.944 3.081 3.081 0 01-1.515.363m-1.927-5.324l-.012 4a.913.913 0 00.545.74 3.07 3.07 0 002.783 0 .905.905 0 00.535-.729l.012-4a1.623 1.623 0 01-.408.326 3.346 3.346 0 01-3.033 0 1.642 1.642 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60150"
              d="M83.525 41.833a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60151"
              d="M82.083 43.988a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.148 1.148 0 01.669.953 1.136 1.136 0 01-.66.947 3.078 3.078 0 01-1.514.363m-.014-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.535.731.914.914 0 00.545.737 3.069 3.069 0 002.782 0 .9.9 0 00.535-.731.915.915 0 00-.544-.737 2.849 2.849 0 00-1.394-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60152"
              d="M85.335 45.515v.555a1.619 1.619 0 01-.947 1.326 5.079 5.079 0 01-4.609 0 1.63 1.63 0 01-.961-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.618 1.618 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60153"
              d="M82.086 48.071a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.513 1.513 0 00.9 1.235 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.807 4.807 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.864.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.373 2.373 0 01-.722.618 5.223 5.223 0 01-4.733 0 2.39 2.39 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60154"
              d="M84.372 44.171c1.277.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.933-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60155"
              d="M82.087 47.516a4.848 4.848 0 01-2.37-.567 1.533 1.533 0 01-.015-2.886 5.223 5.223 0 014.734 0 1.532 1.532 0 01.015 2.885 4.807 4.807 0 01-2.364.567m-.021-3.773a4.552 4.552 0 00-2.239.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.948 4.948 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.589 4.589 0 00-2.244-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60156"
              d="M84.137 42.583l-.013 2.811a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60157"
              d="M82.075 46.705a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.956l.013-2.81a.125.125 0 01.125-.124.047.047 0 01.022 0 .486.486 0 01.647-.21 3.07 3.07 0 002.783 0 .48.48 0 01.642.22h.018a.125.125 0 01.124.126v.008l-.013 2.8a1.138 1.138 0 01-.659.943 3.078 3.078 0 01-1.515.363m-1.926-3.98l-.013 2.661a.912.912 0 00.545.739 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729l.013-2.664a.638.638 0 01-.064-.081c-.149-.21-.233-.257-.344-.193a3.347 3.347 0 01-3.033 0c-.116-.067-.2-.021-.355.186a.656.656 0 01-.065.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60158"
              d="M85.329 43.616v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.961-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60159"
              d="M82.079 46.171a4.843 4.843 0 01-2.37-.567 1.747 1.747 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.121.121 0 01.125.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.116.116 0 01.125-.125.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.811 4.811 0 01-2.364.567m-3.109-1.756a1.708 1.708 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.225 5.225 0 01-4.733 0 2.39 2.39 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60160"
              d="M84.366 42.272c1.277.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.736-1.283-1.933-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60161"
              d="M82.08 45.617a4.844 4.844 0 01-2.37-.567 1.75 1.75 0 01-1.024-1.447 1.732 1.732 0 011.009-1.438 5.222 5.222 0 014.734 0 1.532 1.532 0 01.015 2.885 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60162"
              d="M84.131 42.696l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60163"
              d="M82.069 44.799a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-.8a.125.125 0 01.125-.123.125.125 0 01.124.125.913.913 0 00.545.74 3.07 3.07 0 002.783 0 .916.916 0 00.535-.712v-.018a.125.125 0 01.125-.123.125.125 0 01.124.126v.022l-.013.775a1.138 1.138 0 01-.66.942 3.081 3.081 0 01-1.515.363m-1.935-1.506v.189a.913.913 0 00.545.738 3.071 3.071 0 002.782 0 .9.9 0 00.535-.729v-.168a1.634 1.634 0 01-.4.316 3.347 3.347 0 01-3.033 0 1.629 1.629 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60164"
              d="M83.524 41.848a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60165"
              d="M82.082 44.004a3.1 3.1 0 01-1.519-.363 1.011 1.011 0 01-.009-1.9 3.346 3.346 0 013.033 0 1.012 1.012 0 01.01 1.9 3.08 3.08 0 01-1.515.364m-.014-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.07 3.07 0 002.783 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60166"
              d="M100.802 54.349l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60167"
              d="M98.739 60.28a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.125.125.125 0 01.125.125.913.913 0 00.545.74 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.125.126l-.013 4.623a1.139 1.139 0 01-.66.944 3.083 3.083 0 01-1.515.363m-1.927-5.324l-.011 4a.913.913 0 00.545.74 3.072 3.072 0 002.783 0 .905.905 0 00.535-.729l.011-4a1.625 1.625 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.642 1.642 0 01-.422-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60168"
              d="M100.194 53.501a.89.89 0 01.01 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60169"
              d="M98.752 55.657a3.1 3.1 0 01-1.518-.363 1.011 1.011 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.082 3.082 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.071 3.071 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.848 2.848 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60170"
              d="M101.998 57.097v.555a1.619 1.619 0 01-.947 1.326 5.079 5.079 0 01-4.609 0 1.63 1.63 0 01-.961-1.344v-.555a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.618 1.618 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60171"
              d="M98.749 59.652a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.554a.125.125 0 01.125-.124.128.128 0 01.125.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.124.125.125 0 01.125.125v.555a1.737 1.737 0 01-1.009 1.433 4.809 4.809 0 01-2.364.567m-3.109-1.756a1.708 1.708 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.957 2.37 2.37 0 01-.722.618 5.225 5.225 0 01-4.734 0 2.391 2.391 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60172"
              d="M101.037 55.752c1.277.738 1.283 1.932.015 2.669a5.078 5.078 0 01-4.609 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60173"
              d="M98.751 59.097a4.846 4.846 0 01-2.37-.567 1.532 1.532 0 01-.015-2.886 5.224 5.224 0 014.733 0 1.532 1.532 0 01.016 2.886 4.811 4.811 0 01-2.364.567m-.021-3.773a4.556 4.556 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.884-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60174"
              d="M100.802 52.431l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60175"
              d="M98.739 58.362a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.124.125.125 0 01.125.125.913.913 0 00.545.74 3.071 3.071 0 002.783 0 .9.9 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.125.125l-.013 4.623a1.138 1.138 0 01-.66.944 3.079 3.079 0 01-1.515.364m-1.927-5.324l-.011 4a.913.913 0 00.545.739 3.07 3.07 0 002.783 0 .9.9 0 00.535-.729l.011-4a1.631 1.631 0 01-.408.326 3.348 3.348 0 01-3.033 0 1.648 1.648 0 01-.422-.338"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60176"
              d="M100.194 51.583a.89.89 0 01.01 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60177"
              d="M98.752 53.739a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.082 3.082 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.914.914 0 00.545.737 3.071 3.071 0 002.783 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.849 2.849 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60178"
              d="M102.005 55.267v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.608 0 1.631 1.631 0 01-.961-1.344v-.555a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60179"
              d="M98.755 57.822a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.124.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.128.128 0 01.125-.124.125.125 0 01.125.125v.555a1.737 1.737 0 01-1.009 1.433 4.809 4.809 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.224 5.224 0 01-4.733 0 2.393 2.393 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60180"
              d="M101.043 53.922c1.277.738 1.283 1.933.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.081 5.081 0 014.609 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60181"
              d="M98.757 57.267a4.844 4.844 0 01-2.37-.567 1.532 1.532 0 01-.016-2.885 5.223 5.223 0 014.734 0 1.75 1.75 0 011.025 1.444 1.732 1.732 0 01-1.009 1.438 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60182"
              d="M100.807 52.337l-.013 2.811a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60183"
              d="M98.745 56.456a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.956l.013-2.81a.125.125 0 01.125-.124h.022a.486.486 0 01.647-.21 3.07 3.07 0 002.782 0 .48.48 0 01.642.22.056.056 0 01.018 0 .125.125 0 01.125.126v.007l-.013 2.8a1.138 1.138 0 01-.659.944 3.079 3.079 0 01-1.515.363m-1.926-3.98l-.012 2.661a.913.913 0 00.545.739 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729l.012-2.664a.636.636 0 01-.064-.081c-.149-.21-.233-.258-.344-.193a3.348 3.348 0 01-3.033 0c-.116-.067-.2-.021-.355.186a.657.657 0 01-.065.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60184"
              d="M101.998 53.367v.555a1.619 1.619 0 01-.947 1.326 5.079 5.079 0 01-4.608 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60185"
              d="M98.749 55.921a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.127.127 0 01.125.125 1.513 1.513 0 00.9 1.236 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.126v.555a1.736 1.736 0 01-1.009 1.433 4.806 4.806 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.222 5.222 0 01-4.733 0 2.387 2.387 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60186"
              d="M101.036 52.022c1.277.738 1.283 1.933.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.736-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60187"
              d="M98.75 55.367a4.845 4.845 0 01-2.37-.567 1.75 1.75 0 01-1.024-1.447 1.732 1.732 0 011.009-1.438 5.222 5.222 0 014.734 0 1.751 1.751 0 011.025 1.447 1.733 1.733 0 01-1.009 1.438 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60188"
              d="M100.801 52.447l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60189"
              d="M98.739 54.55a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-.8a.123.123 0 01.126-.123.125.125 0 01.124.126.913.913 0 00.545.739 3.069 3.069 0 002.782 0 .917.917 0 00.535-.712v-.018a.125.125 0 01.125-.123.125.125 0 01.124.125v.023l-.013.775a1.138 1.138 0 01-.659.942 3.078 3.078 0 01-1.515.364m-1.935-1.506v.189a.913.913 0 00.545.738 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729v-.167a1.634 1.634 0 01-.4.316 3.347 3.347 0 01-3.033 0 1.631 1.631 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60190"
              d="M100.194 51.598a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60191"
              d="M98.752 53.754a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.148 1.148 0 01.669.953 1.136 1.136 0 01-.659.947 3.079 3.079 0 01-1.515.363m-.013-2.381a2.826 2.826 0 00-1.389.333.9.9 0 00-.535.731.915.915 0 00.545.737 3.07 3.07 0 002.782 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60192"
              d="M44.011 82.917a.375.375 0 01-.02-.749c9.895-.542 42.131-19.245 52.214-26.716a.375.375 0 01.446.6c-10.135 7.509-42.6 26.313-52.62 26.862h-.021"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60193"
              d="M27.468 72.875a.375.375 0 01-.02-.749c9.895-.542 42.131-19.245 52.214-26.716a.375.375 0 01.447.6c-10.135 7.509-42.6 26.313-52.62 26.862h-.021"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60194"
              d="M12.614 64.652a.375.375 0 01-.02-.75c9.894-.542 42.131-19.245 52.214-26.715a.375.375 0 01.447.6c-10.136 7.509-42.6 26.313-52.62 26.862h-.021"
              fill="#1d1d1b"
            />
            <path data-name="Path 60195" d="M15.878 74.358l.8-.464 6.558 11.32-.8.464z" fill="#00515f" />
            <path
              data-name="Path 60196"
              d="M22.435 85.928a.243.243 0 01-.064-.009.249.249 0 01-.152-.116l-6.557-11.32a.25.25 0 01.091-.342l.8-.465a.25.25 0 01.342.091l6.558 11.319a.25.25 0 01-.091.342l-.8.465a.251.251 0 01-.125.034m-6.22-11.478l6.307 10.887.367-.213-6.304-10.887z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60197" d="M15.651 74.489l.8-.464.228-.13-.8.465z" fill="#fff" />
            <path
              data-name="Path 60198"
              d="M15.65 74.738a.25.25 0 01-.126-.466l1.029-.6a.25.25 0 01.25.433l-1.029.6a.248.248 0 01-.124.033"
              fill="#1d1d1b"
            />
            <path data-name="Path 60199" d="M15.878 74.358l6.557 11.32-.228.132-6.555-11.322z" fill="#00515f" />
            <path
              data-name="Path 60200"
              d="M15.878 74.358l6.557 11.319-.228.132-6.556-11.321zm0-.5a.5.5 0 00-.247.066l-.228.13a.5.5 0 00-.185.685l6.557 11.321a.5.5 0 00.683.182l.228-.132a.5.5 0 00.183-.684l-6.557-11.319a.5.5 0 00-.433-.25"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60201"
              d="M26.036 84.484l-.246 86.917a1.1 1.1 0 01-.644.9 3.453 3.453 0 01-3.133 0 1.108 1.108 0 01-.654-.914l.246-86.917a1.108 1.108 0 00.654.914 3.451 3.451 0 003.133 0 1.1 1.1 0 00.644-.9"
              fill="#fff"
            />
            <path
              data-name="Path 60202"
              d="M23.582 172.934a3.45 3.45 0 01-1.693-.412 1.344 1.344 0 01-.779-1.131l.247-86.917a.25.25 0 01.25-.249.25.25 0 01.249.251.877.877 0 00.529.7 3.229 3.229 0 002.882 0 .869.869 0 00.519-.686.25.25 0 01.25-.249.25.25 0 01.249.251l-.246 86.916a1.336 1.336 0 01-.768 1.117 3.425 3.425 0 01-1.69.412m-1.729-87.521l-.244 85.98a.877.877 0 00.529.7 3.23 3.23 0 002.882 0 .869.869 0 00.519-.686l.244-85.98a2.109 2.109 0 01-.265.182 3.678 3.678 0 01-3.383 0 2.122 2.122 0 01-.282-.193"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60203"
              d="M25.386 83.571a.959.959 0 01.01 1.815 3.455 3.455 0 01-3.133 0 .959.959 0 01-.01-1.815 3.451 3.451 0 013.133 0"
              fill="#fff"
            />
            <path
              data-name="Path 60204"
              d="M23.828 86.014a3.447 3.447 0 01-1.694-.412 1.2 1.2 0 01-.011-2.247 3.68 3.68 0 013.383 0 1.2 1.2 0 01.011 2.247 3.426 3.426 0 01-1.69.412m-.015-2.572a2.924 2.924 0 00-1.438.345.868.868 0 00-.52.688.879.879 0 00.529.694 3.229 3.229 0 002.882 0 .867.867 0 00.519-.687.879.879 0 00-.529-.694 2.952 2.952 0 00-1.444-.345"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60205"
              d="M26.173 85.775v1.2a1.168 1.168 0 01-.683.957 3.668 3.668 0 01-3.327 0 1.178 1.178 0 01-.695-.97v-1.2a1.178 1.178 0 00.695.97 3.668 3.668 0 003.327 0 1.168 1.168 0 00.683-.957"
              fill="#00515f"
            />
            <path
              data-name="Path 60206"
              d="M23.828 88.58a3.647 3.647 0 01-1.791-.436 1.412 1.412 0 01-.82-1.187v-1.2a.25.25 0 01.25-.249.25.25 0 01.25.25.949.949 0 00.57.749 3.448 3.448 0 003.076 0 .936.936 0 00.559-.742.25.25 0 01.25-.249.25.25 0 01.25.25v1.2a1.4 1.4 0 01-.808 1.173 3.621 3.621 0 01-1.787.436m-2.111-1.855v.232a.945.945 0 00.57.753 3.446 3.446 0 003.076 0 .936.936 0 00.559-.741v-.238a2.184 2.184 0 01-.308.215 3.891 3.891 0 01-3.577 0 2.206 2.206 0 01-.32-.222"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60207"
              d="M26.173 89.047v1.2a1.168 1.168 0 01-.683.957 3.668 3.668 0 01-3.327 0 1.178 1.178 0 01-.695-.97v-1.2a1.177 1.177 0 00.695.97 3.668 3.668 0 003.327 0 1.168 1.168 0 00.683-.957"
              fill="#00515f"
            />
            <path
              data-name="Path 60208"
              d="M23.828 91.853a3.647 3.647 0 01-1.791-.435 1.412 1.412 0 01-.82-1.187v-1.2a.25.25 0 01.25-.249.25.25 0 01.25.25v.005a.949.949 0 00.57.749 3.447 3.447 0 003.076 0 .936.936 0 00.559-.741.25.25 0 01.25-.249.25.25 0 01.25.25v1.2a1.4 1.4 0 01-.808 1.173 3.621 3.621 0 01-1.787.436M21.716 90v.233a.946.946 0 00.57.753 3.446 3.446 0 003.076 0 .936.936 0 00.559-.741v-.238a2.184 2.184 0 01-.308.215 3.891 3.891 0 01-3.577 0 2.206 2.206 0 01-.32-.222"
              fill="#1d1d1b"
            />
            <path data-name="Path 60209" d="M31.504 82.787l.8-.464.225.392-.8.465z" fill="#fff" />
            <path
              data-name="Path 60210"
              d="M31.729 83.428a.248.248 0 01-.064-.009.251.251 0 01-.152-.117l-.225-.392a.25.25 0 01.091-.341l.8-.465a.251.251 0 01.342.091l.225.392a.25.25 0 01-.091.341l-.8.465a.25.25 0 01-.126.034"
              fill="#1d1d1b"
            />
            <path data-name="Path 60211" d="M25.161 86.364l.542-.32 6.6-3.723-.8.465z" fill="#00515f" />
            <path
              data-name="Path 60212"
              d="M25.161 86.615a.25.25 0 01-.127-.465l.543-.32 6.6-3.725a.25.25 0 01.248.434l-.8.465-6.346 3.58a.249.249 0 01-.123.032"
              fill="#1d1d1b"
            />
            <path data-name="Path 60213" d="M31.504 82.786l.225.392-6.6 3.725.032-.539z" fill="#00515f" />
            <path
              data-name="Path 60214"
              d="M31.504 82.786l.225.392-6.6 3.725.032-.538zm0-.5a.5.5 0 00-.245.064l-6.347 3.579a.5.5 0 00-.254.406l-.031.539a.5.5 0 00.745.465l6.6-3.725a.5.5 0 00.188-.685l-.225-.392a.5.5 0 00-.434-.251"
              fill="#1d1d1b"
            />
            <path data-name="Path 60215" d="M40.88 88.107l1.6-.929-.005 1.7-1.6.929z" fill="#00515f" />
            <path
              data-name="Path 60216"
              d="M40.876 90.056a.25.25 0 01-.25-.251v-1.7a.251.251 0 01.125-.216l1.6-.929a.25.25 0 01.375.217v1.7a.251.251 0 01-.124.216l-1.6.929a.248.248 0 01-.126.034m.255-1.805v1.12l1.1-.638v-1.12z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60217" d="M7.536 68.856l1.6-.929L42.48 87.178l-1.6.929z" fill="#00515f" />
            <path
              data-name="Path 60218"
              d="M40.88 88.358a.251.251 0 01-.125-.034L7.411 69.071a.25.25 0 010-.433l1.6-.929a.249.249 0 01.25 0L42.606 86.96a.25.25 0 010 .433l-1.6.929a.251.251 0 01-.126.034m-32.846-19.5l32.845 18.963 1.1-.639L9.135 68.216z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60219" d="M40.881 88.107v1.7L7.532 70.555l.005-1.7z" fill="#00515f" />
            <path
              data-name="Path 60220"
              d="M40.876 90.057a.249.249 0 01-.125-.034L7.407 70.772a.249.249 0 01-.125-.217v-1.7a.25.25 0 01.375-.216L41.001 87.89a.25.25 0 01.125.217v1.7a.251.251 0 01-.25.249M7.782 70.411l32.845 18.963v-1.123L7.785 69.288z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60221"
              d="M11.562 64.101l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60222"
              d="M9.5 70.032a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.13.13 0 01.125-.125.125.125 0 01.125.125.913.913 0 00.545.74 3.07 3.07 0 002.783 0 .9.9 0 00.535-.728.125.125 0 01.125-.125.125.125 0 01.125.126l-.013 4.623a1.138 1.138 0 01-.66.944 3.078 3.078 0 01-1.514.363m-1.928-5.325l-.011 4a.913.913 0 00.545.739 3.069 3.069 0 002.782 0 .9.9 0 00.535-.729l.011-4a1.631 1.631 0 01-.408.326 3.348 3.348 0 01-3.033 0 1.648 1.648 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60223"
              d="M10.955 63.253a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60224"
              d="M9.513 65.409a3.106 3.106 0 01-1.518-.363 1.012 1.012 0 01-.009-1.9 3.346 3.346 0 013.033 0 1.012 1.012 0 01.01 1.9 3.083 3.083 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.072 3.072 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60225"
              d="M12.759 66.849v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60226"
              d="M9.51 69.404a4.843 4.843 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.125.125 0 01.125.126 1.513 1.513 0 00.9 1.235 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.13.13 0 01.125-.125.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.808 4.808 0 01-2.364.567m-3.11-1.756a1.709 1.709 0 00.865.973 4.947 4.947 0 004.483 0 1.7 1.7 0 00.849-.956 2.372 2.372 0 01-.722.618 5.223 5.223 0 01-4.733 0 2.391 2.391 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60227"
              d="M11.797 65.504c1.278.737 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.933-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60228"
              d="M9.511 68.849a4.846 4.846 0 01-2.369-.567 1.75 1.75 0 01-1.024-1.448 1.733 1.733 0 011.008-1.438 5.224 5.224 0 014.734 0 1.532 1.532 0 01.015 2.886 4.808 4.808 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60229"
              d="M11.562 62.183l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60230"
              d="M9.5 68.114a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.124.125.125 0 01.125.125.913.913 0 00.545.74 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.125.126l-.013 4.623a1.138 1.138 0 01-.66.944 3.082 3.082 0 01-1.515.364m-1.927-5.324l-.011 4a.913.913 0 00.545.74 3.071 3.071 0 002.782 0 .9.9 0 00.535-.729l.011-4a1.625 1.625 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.642 1.642 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60231"
              d="M10.955 61.335a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60232"
              d="M9.513 63.491a3.1 3.1 0 01-1.519-.363 1.011 1.011 0 01-.009-1.9 3.346 3.346 0 013.033 0 1.012 1.012 0 01.01 1.9 3.08 3.08 0 01-1.515.363m-.014-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.071 3.071 0 002.783 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737A2.847 2.847 0 009.5 61.11"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60233"
              d="M12.765 65.018v.555a1.62 1.62 0 01-.947 1.326 5.078 5.078 0 01-4.608 0 1.631 1.631 0 01-.961-1.344V65a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60234"
              d="M9.516 67.577a4.844 4.844 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.512 1.512 0 00.9 1.236 4.948 4.948 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.124.124 0 01.125.126v.554a1.736 1.736 0 01-1.009 1.433 4.808 4.808 0 01-2.364.567m-3.11-1.756a1.709 1.709 0 00.865.973 4.947 4.947 0 004.483 0 1.7 1.7 0 00.849-.957 2.374 2.374 0 01-.722.618 5.224 5.224 0 01-4.734 0 2.387 2.387 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60235"
              d="M11.803 63.674c1.277.738 1.283 1.933.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.736-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60236"
              d="M9.518 67.019a4.848 4.848 0 01-2.37-.567 1.532 1.532 0 01-.015-2.886 5.223 5.223 0 014.734 0 1.532 1.532 0 01.016 2.886 4.812 4.812 0 01-2.364.567m-.021-3.773a4.555 4.555 0 00-2.238.537 1.5 1.5 0 00-.886 1.221 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60237"
              d="M11.572 62.089l-.013 2.811a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.608-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60238"
              d="M9.506 66.208a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.956l.013-2.81a.125.125 0 01.125-.125h.023a.486.486 0 01.648-.21 3.069 3.069 0 002.782 0 .479.479 0 01.642.22h.019a.125.125 0 01.125.126v.007l-.013 2.8a1.138 1.138 0 01-.66.944 3.079 3.079 0 01-1.515.363m-1.926-3.98l-.012 2.661a.913.913 0 00.545.739 3.07 3.07 0 002.782 0 .9.9 0 00.535-.729l.012-2.665a.635.635 0 01-.064-.081c-.149-.21-.233-.257-.344-.193a3.346 3.346 0 01-3.033 0c-.116-.067-.2-.021-.355.186a.654.654 0 01-.066.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60239"
              d="M12.758 63.118v.555a1.619 1.619 0 01-.947 1.326 5.079 5.079 0 01-4.609 0 1.631 1.631 0 01-.962-1.344V63.1a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60240"
              d="M9.509 65.677a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.513 1.513 0 00.9 1.236 4.948 4.948 0 004.483 0 1.5 1.5 0 00.885-1.218.135.135 0 01.125-.124.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.81 4.81 0 01-2.364.567M6.401 63.92a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.957 2.371 2.371 0 01-.722.618 5.223 5.223 0 01-4.734 0 2.387 2.387 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60241"
              d="M11.796 61.774c1.278.738 1.283 1.933.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60242"
              d="M9.511 65.119a4.847 4.847 0 01-2.37-.567 1.751 1.751 0 01-1.024-1.448 1.733 1.733 0 011.008-1.438 5.223 5.223 0 014.734 0 1.532 1.532 0 01.016 2.886 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60243"
              d="M11.561 62.198l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60244"
              d="M9.5 64.301a3.1 3.1 0 01-1.518-.363 1.147 1.147 0 01-.67-.957l.013-.8a.125.125 0 01.25 0 .913.913 0 00.545.739 3.071 3.071 0 002.782 0 .917.917 0 00.535-.712v-.018a.125.125 0 01.125-.123.125.125 0 01.124.125v.022l-.013.775a1.139 1.139 0 01-.66.942 3.082 3.082 0 01-1.515.363m-1.932-1.499v.188a.913.913 0 00.545.738 3.072 3.072 0 002.783 0 .905.905 0 00.535-.729v-.167a1.637 1.637 0 01-.4.316 3.347 3.347 0 01-3.033 0 1.631 1.631 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60245"
              d="M10.952 61.35a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60246"
              d="M9.513 63.506a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.009 1.9 3.078 3.078 0 01-1.514.364M9.5 61.126a2.825 2.825 0 00-1.389.333.9.9 0 00-.535.731.914.914 0 00.545.737 3.071 3.071 0 002.782 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.849 2.849 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60247"
              d="M26.265 72.397l-.013 4.624a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.029 1.029 0 01-.607-.848l.013-4.624a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.837"
              fill="#ffc200"
            />
            <path
              data-name="Path 60248"
              d="M24.203 78.328a3.106 3.106 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.125.125.125 0 01.125.125.912.912 0 00.545.74 3.071 3.071 0 002.782 0 .905.905 0 00.535-.729.125.125 0 01.125-.125.125.125 0 01.125.125l-.013 4.623a1.139 1.139 0 01-.66.944 3.081 3.081 0 01-1.515.363m-1.927-5.323l-.012 4a.913.913 0 00.545.74 3.071 3.071 0 002.783 0 .905.905 0 00.535-.729l.011-4a1.625 1.625 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.641 1.641 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60249"
              d="M25.658 71.549a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60250"
              d="M24.215 73.705a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.079 3.079 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.072 3.072 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.848 2.848 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60251"
              d="M27.462 75.144v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.618 1.618 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60252"
              d="M24.213 77.698a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.123-.125.125.125 0 01.124.125 1.513 1.513 0 00.9 1.236 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.809 4.809 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.957 2.369 2.369 0 01-.722.618 5.223 5.223 0 01-4.733 0 2.387 2.387 0 01-.741-.634"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60253"
              d="M26.5 73.8c1.277.737 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60254"
              d="M24.214 77.145a4.846 4.846 0 01-2.369-.567 1.75 1.75 0 01-1.024-1.448 1.732 1.732 0 011.008-1.438 5.223 5.223 0 014.734 0 1.75 1.75 0 011.025 1.447 1.732 1.732 0 01-1.009 1.438 4.811 4.811 0 01-2.364.567m-.021-3.773a4.554 4.554 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60255"
              d="M26.265 70.478l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60256"
              d="M24.203 76.409a3.106 3.106 0 01-1.518-.363 1.147 1.147 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.125.125.125 0 01.125.125.912.912 0 00.545.739 3.068 3.068 0 002.782 0 .9.9 0 00.535-.728.125.125 0 01.125-.125.125.125 0 01.125.125l-.013 4.623a1.139 1.139 0 01-.659.944 3.083 3.083 0 01-1.515.363m-1.926-5.323l-.012 4a.913.913 0 00.545.74 3.072 3.072 0 002.783 0 .905.905 0 00.535-.729l.011-4a1.628 1.628 0 01-.408.326 3.346 3.346 0 01-3.033 0 1.642 1.642 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60257"
              d="M25.658 69.63a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60258"
              d="M24.215 71.786a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.148 1.148 0 01.669.953 1.136 1.136 0 01-.66.947 3.078 3.078 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.535.731.914.914 0 00.545.737 3.07 3.07 0 002.782 0 .9.9 0 00.535-.731.915.915 0 00-.544-.737 2.849 2.849 0 00-1.394-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60259"
              d="M27.468 73.315v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.618 1.618 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60260"
              d="M24.219 75.869a4.844 4.844 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.559a.125.125 0 01.125-.125.125.125 0 01.124.125 1.513 1.513 0 00.9 1.236 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.126v.554a1.736 1.736 0 01-1.009 1.433 4.808 4.808 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.947 4.947 0 004.483 0 1.7 1.7 0 00.849-.956 2.375 2.375 0 01-.722.617 5.222 5.222 0 01-4.733 0 2.387 2.387 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60261"
              d="M26.506 71.969c1.277.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60262"
              d="M24.22 75.316a4.847 4.847 0 01-2.37-.567 1.533 1.533 0 01-.015-2.886 5.224 5.224 0 014.734 0 1.532 1.532 0 01.016 2.886 4.812 4.812 0 01-2.364.567m-.021-3.773a4.556 4.556 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.515 1.515 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60263"
              d="M26.271 70.389l-.013 2.811a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60264"
              d="M24.209 74.503a3.105 3.105 0 01-1.518-.363 1.147 1.147 0 01-.67-.957l.013-2.81a.125.125 0 01.125-.124h.022a.486.486 0 01.647-.21 3.069 3.069 0 002.782 0 .479.479 0 01.642.22h.019a.125.125 0 01.124.125v.007l-.013 2.8a1.138 1.138 0 01-.66.943 3.079 3.079 0 01-1.515.363m-1.925-3.98l-.013 2.661a.913.913 0 00.545.739 3.072 3.072 0 002.782 0 .9.9 0 00.535-.729l.013-2.664a.635.635 0 01-.064-.081c-.149-.211-.233-.257-.344-.193a3.347 3.347 0 01-3.033 0c-.116-.067-.2-.022-.355.186a.652.652 0 01-.065.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60265"
              d="M27.462 71.414v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.618 1.618 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60266"
              d="M24.213 73.969a4.844 4.844 0 01-2.37-.567 1.747 1.747 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.124.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.126v.555a1.736 1.736 0 01-1.009 1.433 4.808 4.808 0 01-2.364.567m-3.109-1.756a1.708 1.708 0 00.865.973 4.947 4.947 0 004.483 0 1.7 1.7 0 00.849-.956 2.375 2.375 0 01-.722.618 5.224 5.224 0 01-4.734 0 2.391 2.391 0 01-.741-.635"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60267"
              d="M26.5 70.07c1.277.737 1.283 1.933.015 2.669a5.076 5.076 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.082 5.082 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60268"
              d="M24.214 73.416a4.848 4.848 0 01-2.37-.567 1.751 1.751 0 01-1.024-1.448 1.733 1.733 0 011.009-1.438 5.223 5.223 0 014.734 0 1.532 1.532 0 01.015 2.886 4.81 4.81 0 01-2.364.567m-.021-3.773a4.555 4.555 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60269"
              d="M26.264 70.494l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60270"
              d="M24.203 72.598a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-.8a.125.125 0 01.125-.123.125.125 0 01.124.125.913.913 0 00.545.74 3.069 3.069 0 002.782 0 .916.916 0 00.535-.712v-.018a.125.125 0 01.125-.123.125.125 0 01.124.126v.022l-.013.776a1.138 1.138 0 01-.66.942 3.08 3.08 0 01-1.515.363m-1.935-1.506v.189a.913.913 0 00.545.738 3.072 3.072 0 002.782 0 .905.905 0 00.535-.729v-.168a1.638 1.638 0 01-.4.316 3.347 3.347 0 01-3.033 0 1.633 1.633 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60271"
              d="M25.658 69.646a.89.89 0 01.009 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.009-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60272"
              d="M24.217 71.802a3.1 3.1 0 01-1.519-.363 1.011 1.011 0 01-.009-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.08 3.08 0 01-1.515.364m-.014-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.07 3.07 0 002.783 0 .9.9 0 00.535-.731.913.913 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60273"
              d="M42.935 82.147l-.013 4.623a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.907 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60274"
              d="M40.873 88.078a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.624a.125.125 0 01.125-.124.125.125 0 01.125.125.913.913 0 00.545.74 3.069 3.069 0 002.782 0 .9.9 0 00.535-.728.125.125 0 01.125-.125.125.125 0 01.124.125l-.014 4.622a1.139 1.139 0 01-.659.944 3.079 3.079 0 01-1.515.363m-1.926-5.322l-.011 4a.913.913 0 00.545.739 3.071 3.071 0 002.783 0 .9.9 0 00.535-.729l.011-4a1.626 1.626 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.647 1.647 0 01-.421-.338"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60275"
              d="M42.331 81.299a.89.89 0 01.01 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60276"
              d="M40.886 83.455a3.105 3.105 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.081 3.081 0 01-1.515.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.071 3.071 0 002.783 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.848 2.848 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60277"
              d="M44.132 84.894v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.609 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.962 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60278"
              d="M40.883 87.447a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.124.125.125 0 01.125.125v.555a1.737 1.737 0 01-1.009 1.433 4.809 4.809 0 01-2.364.567m-3.11-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.37 2.37 0 01-.722.618 5.224 5.224 0 01-4.734 0 2.39 2.39 0 01-.741-.634"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60279"
              d="M43.17 83.55c1.277.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60280"
              d="M40.884 86.895a4.844 4.844 0 01-2.37-.567 1.75 1.75 0 01-1.024-1.447 1.732 1.732 0 011.008-1.438 5.224 5.224 0 014.734 0 1.75 1.75 0 011.025 1.447 1.732 1.732 0 01-1.009 1.438 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.222 1.516 1.516 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60281"
              d="M42.935 80.229l-.013 4.623a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.907 0 1.03 1.03 0 01-.608-.848l.013-4.623a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60282"
              d="M40.873 86.159a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-4.623a.125.125 0 01.125-.124.131.131 0 01.125.125.913.913 0 00.545.74 3.07 3.07 0 002.782 0 .9.9 0 00.535-.728.125.125 0 01.125-.125.125.125 0 01.124.125l-.013 4.623a1.139 1.139 0 01-.659.944 3.083 3.083 0 01-1.515.363m-1.927-5.323l-.011 4a.913.913 0 00.545.74 3.072 3.072 0 002.783 0 .905.905 0 00.535-.729l.011-4a1.626 1.626 0 01-.408.326 3.347 3.347 0 01-3.033 0 1.641 1.641 0 01-.421-.337"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60283"
              d="M42.331 79.381a.89.89 0 01.01 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60284"
              d="M40.886 81.537a3.1 3.1 0 01-1.518-.363 1.012 1.012 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.01 1.9 3.079 3.079 0 01-1.515.363m-.014-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.915.915 0 00.545.737 3.07 3.07 0 002.783 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.847 2.847 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60285"
              d="M44.141 83.063v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.608 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.948-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60286"
              d="M40.888 85.62a4.848 4.848 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.453v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.513 1.513 0 00.9 1.235 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 11.25 0v.555a1.736 1.736 0 01-1.009 1.433 4.808 4.808 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.374 2.374 0 01-.722.618 5.224 5.224 0 01-4.734 0 2.393 2.393 0 01-.741-.634"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60287"
              d="M43.176 81.72c1.277.738 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60288"
              d="M40.89 85.064a4.848 4.848 0 01-2.37-.567 1.75 1.75 0 01-1.024-1.447 1.732 1.732 0 011.008-1.438 5.225 5.225 0 014.734 0 1.532 1.532 0 01.016 2.886 4.812 4.812 0 01-2.364.567m-.021-3.773a4.555 4.555 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.515 1.515 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.59 4.59 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60289"
              d="M42.941 80.137l-.013 2.811a1.021 1.021 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-2.811c0 .307.2-.458.607-.224a3.2 3.2 0 002.908 0c.4-.231.6.539.6.236"
              fill="#ffc200"
            />
            <path
              data-name="Path 60290"
              d="M40.879 84.254a3.1 3.1 0 01-1.518-.363 1.146 1.146 0 01-.67-.957l.013-2.81a.125.125 0 01.125-.124h.023a.486.486 0 01.647-.21 3.069 3.069 0 002.782 0 .479.479 0 01.642.22.042.042 0 01.018 0 .125.125 0 01.125.125v.007l-.013 2.8a1.138 1.138 0 01-.66.944 3.079 3.079 0 01-1.515.363m-1.926-3.98l-.012 2.661a.913.913 0 00.545.739 3.069 3.069 0 002.782 0 .9.9 0 00.535-.729l.012-2.664a.635.635 0 01-.064-.081c-.15-.211-.233-.257-.344-.193a3.346 3.346 0 01-3.033 0c-.116-.066-.2-.022-.355.186a.654.654 0 01-.066.08"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60291"
              d="M44.131 81.163v.555a1.619 1.619 0 01-.947 1.326 5.078 5.078 0 01-4.608 0 1.631 1.631 0 01-.962-1.344v-.555a1.63 1.63 0 00.961 1.344 5.078 5.078 0 004.608 0 1.619 1.619 0 00.947-1.326"
              fill="#ffc200"
            />
            <path
              data-name="Path 60292"
              d="M40.883 83.72a4.845 4.845 0 01-2.37-.567 1.746 1.746 0 01-1.024-1.452v-.555a.125.125 0 01.125-.125.125.125 0 01.125.125 1.513 1.513 0 00.9 1.236 4.947 4.947 0 004.483 0 1.5 1.5 0 00.885-1.218.125.125 0 01.125-.125.125.125 0 01.125.125v.555a1.736 1.736 0 01-1.009 1.433 4.809 4.809 0 01-2.364.567m-3.109-1.756a1.709 1.709 0 00.865.973 4.948 4.948 0 004.483 0 1.7 1.7 0 00.849-.956 2.374 2.374 0 01-.722.618 5.223 5.223 0 01-4.734 0 2.387 2.387 0 01-.741-.634"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60293"
              d="M43.169 79.82c1.277.737 1.283 1.932.015 2.669a5.077 5.077 0 01-4.608 0c-1.276-.737-1.283-1.932-.015-2.669a5.08 5.08 0 014.608 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60294"
              d="M40.884 83.165a4.847 4.847 0 01-2.37-.567 1.751 1.751 0 01-1.024-1.448 1.733 1.733 0 011.008-1.438 5.223 5.223 0 014.734 0 1.532 1.532 0 01.016 2.886 4.81 4.81 0 01-2.364.567m-.021-3.773a4.553 4.553 0 00-2.238.537 1.5 1.5 0 00-.884 1.222 1.516 1.516 0 00.9 1.231 4.946 4.946 0 004.483 0 1.5 1.5 0 00.885-1.222 1.517 1.517 0 00-.9-1.231 4.589 4.589 0 00-2.245-.537"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60295"
              d="M42.934 80.245l-.013.8a1.022 1.022 0 01-.6.836 3.2 3.2 0 01-2.908 0 1.03 1.03 0 01-.607-.848l.013-.8a1.029 1.029 0 00.607.848 3.2 3.2 0 002.908 0 1.021 1.021 0 00.6-.836"
              fill="#ffc200"
            />
            <path
              data-name="Path 60296"
              d="M40.872 82.347a3.105 3.105 0 01-1.518-.363 1.147 1.147 0 01-.67-.957l.013-.8a.125.125 0 01.25 0 .913.913 0 00.545.74 3.072 3.072 0 002.782 0 .917.917 0 00.535-.712v-.018a.131.131 0 01.126-.123.125.125 0 01.124.125v.022l-.013.775a1.138 1.138 0 01-.66.942 3.079 3.079 0 01-1.515.363m-1.935-1.506v.188a.913.913 0 00.545.738 3.07 3.07 0 002.782 0 .9.9 0 00.535-.728v-.168a1.637 1.637 0 01-.4.316 3.346 3.346 0 01-3.033 0 1.63 1.63 0 01-.43-.347"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60297"
              d="M42.333 79.396a.89.89 0 01.01 1.684 3.2 3.2 0 01-2.908 0 .89.89 0 01-.01-1.684 3.2 3.2 0 012.908 0"
              fill="#ffc200"
            />
            <path
              data-name="Path 60298"
              d="M40.886 81.552a3.1 3.1 0 01-1.518-.363 1.011 1.011 0 01-.01-1.9 3.347 3.347 0 013.033 0 1.012 1.012 0 01.009 1.9 3.078 3.078 0 01-1.514.363m-.013-2.381a2.825 2.825 0 00-1.389.333.9.9 0 00-.536.731.914.914 0 00.545.737 3.072 3.072 0 002.782 0 .9.9 0 00.535-.731.914.914 0 00-.544-.737 2.849 2.849 0 00-1.393-.333"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60299"
              d="M207.879 158.389c-.737 4.434 2.859 8.726 8.034 9.586s9.967-2.036 10.7-6.471-2.554-10.562-7.729-11.422-10.272 3.872-11.009 8.306"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60300"
              d="M217.724 168.376a11.279 11.279 0 01-1.853-.154c-5.3-.881-9-5.311-8.24-9.874.762-4.58 5.971-9.4 11.3-8.512s8.7 7.13 7.934 11.71c-.67 4.025-4.586 6.83-9.139 6.83m-9.6-9.946c-.714 4.291 2.8 8.462 7.828 9.3s9.7-1.974 10.416-6.265c.724-4.356-2.47-10.294-7.523-11.135s-10 3.745-10.721 8.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60301"
              d="M201.568 158.564c0 5.424 4.06 9.821 9.069 9.821s9.069-4.4 9.069-9.821-4.27-16.174-9.279-16.174-8.859 10.75-8.859 16.174"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60302"
              d="M210.637 168.635c-5.138 0-9.319-4.518-9.319-10.071 0-5.478 3.938-16.424 9.109-16.424 5.215 0 9.529 10.875 9.529 16.424s-4.18 10.071-9.319 10.071m-.21-26c-4.711 0-8.609 10.543-8.609 15.924 0 5.278 3.956 9.571 8.819 9.571s8.819-4.293 8.819-9.571c0-5.243-4.179-15.924-9.029-15.924"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60303"
              d="M227.581 165.563c-.066 1.723-1.808 3.055-3.891 2.976s-3.719-1.541-3.653-3.264a3.775 3.775 0 117.544.288"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60304"
              d="M223.865 168.792h-.185c-2.218-.085-3.964-1.665-3.893-3.523a4.224 4.224 0 011.275-2.687 3.687 3.687 0 015.7.218 4.225 4.225 0 011.067 2.776c-.069 1.806-1.831 3.219-3.966 3.219m-.029-6.958a3.436 3.436 0 00-2.419 1.1 3.713 3.713 0 00-1.13 2.354c-.06 1.583 1.471 2.93 3.413 3s3.572-1.153 3.632-2.736l.25.01-.25-.01a3.714 3.714 0 00-.948-2.433 3.4 3.4 0 00-2.438-1.284h-.109"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60305"
              d="M209.843 163.683a6.269 6.269 0 1012.519 0c0-3.658-2.8-8.137-6.26-8.137s-6.259 4.479-6.259 8.137"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60306"
              d="M216.102 170.556a6.706 6.706 0 01-6.509-6.873c0-3.8 2.9-8.387 6.509-8.387s6.51 4.588 6.51 8.387a6.706 6.706 0 01-6.51 6.873m0-14.76c-3.237 0-6.009 4.337-6.009 7.887a6.02 6.02 0 1012.019 0c0-3.55-2.773-7.887-6.01-7.887"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60307"
              d="M204.773 161.781a5.4 5.4 0 11-10.765-.411c.12-3.156 2.678-6.928 5.65-6.814s5.235 4.07 5.115 7.225"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60308"
              d="M199.371 167.543h-.208a5.447 5.447 0 01-3.921-1.9 6.112 6.112 0 01-1.484-4.274 8.671 8.671 0 011.808-4.776 5.1 5.1 0 014.1-2.278c3.12.119 5.48 4.192 5.355 7.484a6.11 6.11 0 01-1.805 4.148 5.453 5.453 0 01-3.846 1.6m.155-12.74a4.68 4.68 0 00-3.565 2.087 8.153 8.153 0 00-1.7 4.489 5.613 5.613 0 001.361 3.927 4.919 4.919 0 007.248.276 5.612 5.612 0 001.656-3.811c.116-3.048-2.11-6.861-4.874-6.966h-.122"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60309"
              d="M208.701 165.406a1.875 1.875 0 01-1.875-1.875V115.36a1.875 1.875 0 013.75 0v48.171a1.875 1.875 0 01-1.875 1.875"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60310"
              d="M208.701 139.142a.625.625 0 01-.221-1.21l9.062-3.413 7.29-9.014a.625.625 0 01.972.786l-7.4 9.146a.629.629 0 01-.265.192l-9.221 3.473a.627.627 0 01-.22.04"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60311"
              d="M208.279 135.42a.626.626 0 01-.273-.063l-8.644-4.216a.625.625 0 01-.338-.434l-1.484-7.122a.625.625 0 011.224-.255l1.422 6.822 8.369 4.081a.625.625 0 01-.275 1.187"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60312"
              d="M226.25 134.081h-.059l-6.546-.621a.625.625 0 01.118-1.245l6.264.594 3.511-3.315a.625.625 0 01.859.909l-3.717 3.509a.624.624 0 01-.429.17"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60313"
              d="M202.456 132.581a.625.625 0 01-.6-.812l1.211-3.859-.661-4.358a.625.625 0 111.236-.187l.683 4.5a.624.624 0 01-.022.281l-1.255 4a.626.626 0 01-.6.438"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60314"
              d="M208.701 129.33a.625.625 0 01-.256-1.2l4.822-2.164.42-6.821a.625.625 0 111.247.077l-.443 7.2a.624.624 0 01-.368.532l-5.166 2.319a.619.619 0 01-.256.055"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60315"
              d="M207.261 142.593a.62.62 0 01-.192-.03l-10.026-3.239a.625.625 0 01-.407-.418l-1.194-4.06a.625.625 0 011.2-.353l1.1 3.743 9.713 3.135a.625.625 0 01-.192 1.22"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60316"
              d="M209.376 113.465c1.254 7.542-4.864 14.842-13.665 16.306s-16.952-3.464-18.206-11.006 4.344-17.965 13.146-19.428 17.471 6.586 18.725 14.128"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60317"
              d="M192.6 130.28a17.273 17.273 0 01-8.952-2.421 13.086 13.086 0 01-6.391-9.053 18.181 18.181 0 012.837-12.167c2.623-4.177 6.357-6.857 10.514-7.548s8.558.637 12.391 3.739a18.181 18.181 0 016.622 10.595c1.275 7.666-4.948 15.109-13.87 16.593a19.22 19.22 0 01-3.152.263m.183-30.868a12.8 12.8 0 00-2.092.172c-4.015.668-7.628 3.268-10.173 7.322a17.669 17.669 0 00-2.767 11.819 12.594 12.594 0 006.156 8.707 17.248 17.248 0 0011.763 2.094c8.651-1.439 14.688-8.624 13.459-16.018a17.67 17.67 0 00-6.443-10.288 15.849 15.849 0 00-9.9-3.807"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60318"
              d="M201.39 131.271a5.355 5.355 0 11-10.7 0c0-3.1 2.395-6.888 5.349-6.888s5.349 3.792 5.349 6.888"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60319"
              d="M196.041 137.128a5.738 5.738 0 01-5.6-5.856c0-3.233 2.5-7.138 5.6-7.138s5.6 3.905 5.6 7.138a5.738 5.738 0 01-5.6 5.856m0-12.495c-2.746 0-5.1 3.65-5.1 6.638a5.105 5.105 0 1010.2 0c0-2.988-2.353-6.638-5.1-6.638"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60320"
              d="M231.931 102.499c0 10.6-10.025 19.188-22.392 19.188s-22.392-8.591-22.392-19.188 10.543-31.6 22.91-31.6 21.874 21.005 21.874 31.6"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60321"
              d="M209.539 121.938c-12.485 0-22.642-8.72-22.642-19.439 0-5.459 2.755-13.46 6.856-19.91 2.836-4.46 8.726-11.943 16.3-11.943 12.559 0 22.124 21.229 22.124 31.853 0 10.719-10.157 19.439-22.642 19.439m.518-50.792c-7.339 0-13.1 7.338-15.881 11.711-4.054 6.376-6.778 14.269-6.778 19.642 0 10.443 9.933 18.939 22.142 18.939s22.142-8.5 22.142-18.939-9.348-31.353-21.624-31.353"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60322"
              d="M243.759 118.677c0 7.027-6.647 12.723-14.847 12.723s-14.847-5.7-14.847-12.723 6.647-17.717 14.847-17.717 14.847 10.69 14.847 17.717"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60323"
              d="M228.912 131.65c-8.324 0-15.1-5.82-15.1-12.973 0-7.095 6.763-17.967 15.1-17.967s15.1 10.872 15.1 17.967c0 7.154-6.773 12.973-15.1 12.973m0-30.44c-8.057 0-14.6 10.569-14.6 17.467 0 6.878 6.548 12.473 14.6 12.473s14.6-5.6 14.6-12.473c0-6.9-6.54-17.467-14.6-17.467"
              fill="#1d1d1b"
            />
            <path data-name="Path 60324" d="M120.862 214.36l-84.485-48.448 82.5-47.565 83.582 48.969z" fill="#fff" />
            <path
              data-name="Path 60325"
              d="M120.862 214.61a.249.249 0 01-.124-.033L36.253 166.13a.25.25 0 010-.433l82.5-47.566a.249.249 0 01.251 0l83.582 48.969a.25.25 0 010 .432l-81.595 47.045a.25.25 0 01-.125.033m-83.984-48.7l83.984 48.161 81.1-46.759-83.086-48.678z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60326" d="M73.973 143.227l63.028 36.392 28.632-16.532-63.028-36.392z" fill="#fff" />
            <path data-name="Path 60327" d="M145.298 196.654v-76.068L66.793 75.244v76.068z" fill="#fff" />
            <path
              data-name="Path 60328"
              d="M145.298 196.905a.25.25 0 01-.125-.034l-78.501-45.342a.249.249 0 01-.125-.216V75.245a.25.25 0 01.375-.216l78.505 45.342a.25.25 0 01.125.217v76.068a.249.249 0 01-.25.25M67.047 151.17l78.001 45.048v-75.487L67.043 75.678z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60329" d="M170.749 80.469l25.49 10.669v76.068l-50.922 29.449v-76.069z" fill="#fff" />
            <path
              data-name="Path 60330"
              d="M145.317 196.904a.25.25 0 01-.25-.25v-76.069a.251.251 0 01.039-.134l25.434-40.117a.25.25 0 01.308-.1l25.49 10.669a.25.25 0 01.154.231v76.071a.25.25 0 01-.125.216l-50.925 29.449a.25.25 0 01-.125.034m.25-76.246v75.563l50.425-29.16V91.303L170.849 80.78z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60331"
              d="M75.252 185.526l.025-36.7 25.445-24.521.013 9.826-.02.011v36.764z"
              fill="#fff"
            />
            <path
              data-name="Path 60332"
              d="M75.252 185.776a.25.25 0 01-.25-.25l.025-36.7a.25.25 0 01.077-.18l25.444-24.522a.25.25 0 01.423.18l.013 9.826a.249.249 0 01-.021.1v36.676a.25.25 0 01-.125.217l-25.463 14.616a.251.251 0 01-.125.033m.275-36.84L75.5 185.09l24.962-14.329v-36.615a.252.252 0 01.02-.1l-.012-9.152z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60333" d="M145.329 120.402L66.795 75.061l25.434-40.1 78.534 45.342z" fill="#00515f" />
            <path
              data-name="Path 60334"
              d="M145.329 120.653a.248.248 0 01-.125-.034L66.666 75.278a.25.25 0 01-.086-.35l25.435-40.1a.25.25 0 01.336-.082l78.534 45.341a.25.25 0 01.086.35l-25.434 40.1a.25.25 0 01-.211.116m-78.18-45.678l78.1 45.092 25.167-39.678-78.1-45.091z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60335" d="M196.247 91.137l-25.5-10.652-78.534-45.342 25.491 10.652z" fill="#00515f" />
            <path
              data-name="Path 60336"
              d="M196.242 91.387a.251.251 0 01-.1-.019L170.655 80.72l-.029-.014-78.529-45.347a.25.25 0 01.221-.447l25.491 10.652.029.014 78.529 45.342a.25.25 0 01-.125.467M170.863 80.26l21.87 9.139-75.136-43.379-21.87-9.139z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60337" d="M100.721 124.308l-33.965-19.58-25.438 24.5 33.979 19.658z" fill="#00515f" />
            <path
              data-name="Path 60338"
              d="M75.295 149.133a.248.248 0 01-.125-.034l-33.975-19.657a.25.25 0 01-.048-.4l25.438-24.5a.25.25 0 01.3-.036l33.965 19.58a.25.25 0 01.049.4l-25.428 24.579a.25.25 0 01-.174.07m-33.567-19.96l33.529 19.4 25.05-24.212-33.514-19.324z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60339" d="M75.272 148.824l-33.954-19.6v36.758l33.954 19.542z" fill="#fff" />
            <path
              data-name="Path 60340"
              d="M75.272 185.774a.25.25 0 01-.125-.033l-33.952-19.542a.25.25 0 01-.125-.217v-36.757a.25.25 0 01.375-.216l33.954 19.6a.25.25 0 01.125.217v36.7a.25.25 0 01-.25.25m-33.7-19.938l33.454 19.255v-36.128l-33.454-19.307z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60341" d="M128.327 142.7l-12.742-7.357v-22.035l12.754 7.32z" fill="#ffc200" />
            <path
              data-name="Path 60342"
              d="M128.327 142.95a.25.25 0 01-.125-.033l-12.742-7.357a.249.249 0 01-.125-.216v-22.037a.25.25 0 01.374-.217l12.754 7.321a.25.25 0 01.125.217l-.012 22.072a.25.25 0 01-.25.25m-12.492-7.751l12.242 7.069.012-21.495-12.254-7.033z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60343" d="M115.586 135.343V113.29l2.122 1.213v22.065z" fill="#fff" />
            <path
              data-name="Path 60344"
              d="M117.709 136.818a.248.248 0 01-.125-.034l-2.124-1.224a.25.25 0 01-.125-.217V113.29a.25.25 0 01.374-.217l2.122 1.212a.25.25 0 01.126.217v22.066a.25.25 0 01-.25.25m-1.874-1.619l1.624.936v-21.487l-1.622-.927z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60345" d="M126.197 145.152l-12.734-7.357v-1.225l12.734 7.353z" fill="#fff" />
            <path
              data-name="Path 60346"
              d="M126.197 145.402a.252.252 0 01-.125-.033l-12.733-7.358a.25.25 0 01-.125-.216v-1.226a.25.25 0 01.375-.216l12.734 7.352a.25.25 0 01.125.217v1.231a.25.25 0 01-.25.25m-12.484-7.752l12.234 7.069v-.654l-12.234-7.064z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60347" d="M126.197 145.152v-1.231l2.13-1.221-.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60348"
              d="M126.197 145.402a.25.25 0 01-.25-.25v-1.231a.25.25 0 01.126-.217l2.13-1.221a.25.25 0 01.374.218l-.005 1.228a.249.249 0 01-.125.216l-2.125 1.224a.25.25 0 01-.125.033m.25-1.336v.654l1.625-.936v-.651z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60349"
              d="M126.197 143.921l-12.734-7.352 4.245-2.451 10.632 6.141-.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60350"
              d="M126.197 144.171a.25.25 0 01-.125-.034l-12.734-7.353a.25.25 0 010-.433l4.244-2.451a.25.25 0 01.25 0l10.632 6.141a.25.25 0 01.125.218l-.012 2.441a.251.251 0 01-.126.216l-2.13 1.221a.249.249 0 01-.124.033m-12.234-7.6l12.234 7.064 1.88-1.078.011-2.152-10.381-6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60351" d="M128.327 177.093l-12.742-7.357v-22.034l12.754 7.32z" fill="#ffc200" />
            <path
              data-name="Path 60352"
              d="M128.327 177.343a.251.251 0 01-.125-.033l-12.742-7.357a.249.249 0 01-.125-.217v-22.033a.25.25 0 01.374-.217l12.754 7.321a.25.25 0 01.125.217l-.012 22.072a.25.25 0 01-.25.25m-12.492-7.751l12.242 7.068.012-21.494-12.254-7.034z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60353" d="M115.586 169.738v-22.054l2.122 1.213v22.065z" fill="#fff" />
            <path
              data-name="Path 60354"
              d="M117.709 171.212a.249.249 0 01-.125-.034l-2.124-1.224a.25.25 0 01-.125-.216v-22.054a.25.25 0 01.374-.217l2.122 1.213a.249.249 0 01.126.217v22.065a.25.25 0 01-.25.25m-1.874-1.619l1.624.936v-21.488l-1.622-.927z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60355" d="M126.197 179.547l-12.734-7.358v-1.226l12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60356"
              d="M126.197 179.797a.251.251 0 01-.125-.034l-12.733-7.358a.25.25 0 01-.125-.216v-1.229a.25.25 0 01.375-.216l12.734 7.352a.25.25 0 01.125.216v1.231a.25.25 0 01-.25.25m-12.484-7.752l12.234 7.069v-.65l-12.234-7.064z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60357" d="M126.197 179.547v-1.232l2.13-1.221-.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60358"
              d="M126.197 179.797a.25.25 0 01-.25-.25v-1.231a.25.25 0 01.126-.217l2.13-1.221a.25.25 0 01.374.218l-.005 1.228a.249.249 0 01-.125.216l-2.125 1.224a.249.249 0 01-.125.033m.25-1.336v.654l1.625-.936v-.651z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60359"
              d="M126.197 178.315l-12.734-7.353 4.245-2.451 10.632 6.141-.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60360"
              d="M126.197 178.566a.247.247 0 01-.125-.034l-12.734-7.352a.25.25 0 010-.433l4.244-2.451a.248.248 0 01.25 0l10.632 6.141a.25.25 0 01.125.218l-.012 2.441a.25.25 0 01-.126.216l-2.13 1.221a.246.246 0 01-.124.033m-12.234-7.6l12.234 7.064 1.88-1.078.011-2.152-10.381-6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60361" d="M175.022 170.883l12.742-7.357v-7.179l-12.754 7.321z" fill="#ffc200" />
            <path
              data-name="Path 60362"
              d="M175.022 171.133a.25.25 0 01-.25-.25l-.012-7.215a.25.25 0 01.125-.217l12.754-7.321a.25.25 0 01.375.217v7.179a.25.25 0 01-.125.216l-12.742 7.357a.249.249 0 01-.125.034m.238-7.32l.011 6.638 12.242-7.069v-6.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60363" d="M187.763 163.526v-7.2l-2.122 1.212v7.208z" fill="#fff" />
            <path
              data-name="Path 60364"
              d="M185.639 165.001a.246.246 0 01-.125-.034.25.25 0 01-.125-.216v-7.208a.25.25 0 01.126-.217l2.122-1.213a.25.25 0 01.374.217v7.2a.25.25 0 01-.125.217l-2.124 1.224a.25.25 0 01-.125.033m.252-7.313v6.63l1.624-.936v-6.623zm1.872 5.839z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60365" d="M177.151 173.335l12.734-7.358v-1.225l-12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60366"
              d="M177.152 173.585a.25.25 0 01-.25-.25v-1.231a.249.249 0 01.125-.216l12.734-7.353a.25.25 0 01.375.216v1.226a.25.25 0 01-.125.216l-12.734 7.358a.25.25 0 01-.125.034m.25-1.337v.654l12.234-7.069v-.648z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60367" d="M177.152 173.336v-1.231l-2.13-1.221.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60368"
              d="M177.151 173.585a.25.25 0 01-.125-.034l-2.125-1.224a.25.25 0 01-.125-.216l-.005-1.228a.25.25 0 01.375-.218l2.13 1.221a.25.25 0 01.125.217v1.231a.25.25 0 01-.25.25m-1.875-1.619l1.625.936v-.654l-1.628-.933z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60369"
              d="M177.152 172.104l12.734-7.352-4.245-2.451-10.632 6.141.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60370"
              d="M177.151 172.354a.25.25 0 01-.124-.033l-2.13-1.221a.25.25 0 01-.126-.216l-.012-2.441a.249.249 0 01.125-.217l10.631-6.142a.25.25 0 01.25 0l4.245 2.451a.25.25 0 010 .433l-12.734 7.352a.25.25 0 01-.125.034m-1.881-1.616l1.88 1.077 12.234-7.064-3.745-2.162-10.381 6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60371" d="M92.235 107.087L79.493 99.73v-7.179l12.754 7.321z" fill="#ffc200" />
            <path
              data-name="Path 60372"
              d="M92.235 107.337a.251.251 0 01-.125-.033l-12.74-7.357a.249.249 0 01-.125-.216v-7.179a.25.25 0 01.374-.217l12.751 7.32a.25.25 0 01.125.217l-.012 7.215a.25.25 0 01-.25.249m-12.492-7.751l12.242 7.069.011-6.638-12.254-7.034z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60373" d="M79.494 99.73v-7.2l2.122 1.212v7.208z" fill="#fff" />
            <path
              data-name="Path 60374"
              d="M81.617 101.204a.247.247 0 01-.125-.034l-2.122-1.222a.25.25 0 01-.125-.216v-7.2a.25.25 0 01.374-.217l2.122 1.213a.25.25 0 01.126.217v7.208a.25.25 0 01-.25.25m-1.874-1.619l1.624.936v-6.63l-1.622-.927z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60375" d="M90.105 109.539l-12.734-7.358v-1.225l12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60376"
              d="M90.105 109.789a.25.25 0 01-.125-.034l-12.734-7.358a.25.25 0 01-.125-.217v-1.225a.25.25 0 01.375-.216l12.734 7.352a.25.25 0 01.125.217v1.231a.25.25 0 01-.25.25m-12.484-7.752l12.234 7.069v-.654l-12.234-7.064z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60377" d="M90.105 109.538v-1.23l2.13-1.221-.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60378"
              d="M90.105 109.788a.251.251 0 01-.25-.25v-1.23a.25.25 0 01.126-.217l2.13-1.221a.25.25 0 01.374.218l-.005 1.228a.249.249 0 01-.125.215l-2.125 1.224a.249.249 0 01-.125.034m.25-1.336v.654l1.626-.936v-.651z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60379"
              d="M90.105 108.308l-12.734-7.352 4.245-2.451 10.632 6.141-.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60380"
              d="M90.105 108.558a.251.251 0 01-.125-.034l-12.734-7.353a.25.25 0 010-.433l4.245-2.451a.25.25 0 01.25 0l10.632 6.141a.25.25 0 01.125.218l-.012 2.441a.25.25 0 01-.126.216l-2.13 1.22a.248.248 0 01-.124.033m-12.234-7.6l12.234 7.064 1.88-1.077.011-2.152-10.381-6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60381" d="M166.532 140.266l12.742-7.357v-22.036l-12.754 7.32z" fill="#ffc200" />
            <path
              data-name="Path 60382"
              d="M166.532 140.516a.25.25 0 01-.25-.25l-.012-22.072a.25.25 0 01.125-.217l12.754-7.321a.25.25 0 01.375.217v22.036a.25.25 0 01-.125.216l-12.742 7.357a.248.248 0 01-.125.034m.238-22.178l.012 21.495 12.242-7.069v-21.46z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60383" d="M179.274 132.909v-22.054l-2.122 1.213v22.065z" fill="#fff" />
            <path
              data-name="Path 60384"
              d="M177.15 134.384a.25.25 0 01-.25-.25v-22.065a.249.249 0 01.126-.217l2.122-1.213a.25.25 0 01.374.217v22.054a.25.25 0 01-.125.216l-2.124 1.224a.25.25 0 01-.125.033m.252-22.17v21.488l1.624-.936v-21.481zm1.872 20.7z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60385" d="M168.663 142.718l12.734-7.358v-1.226l-12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60386"
              d="M168.662 142.968a.25.25 0 01-.25-.25v-1.231a.249.249 0 01.125-.216l12.734-7.352a.25.25 0 01.375.216v1.226a.25.25 0 01-.125.216l-12.734 7.358a.25.25 0 01-.125.034m.25-1.337v.654l12.234-7.069v-.648z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60387" d="M168.663 142.718v-1.231l-2.13-1.221.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60388"
              d="M168.663 142.968a.25.25 0 01-.125-.034l-2.125-1.224a.25.25 0 01-.125-.216l-.005-1.228a.25.25 0 01.375-.218l2.13 1.221a.25.25 0 01.126.217v1.231a.251.251 0 01-.25.25m-1.876-1.619l1.626.936v-.654l-1.628-.933z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60389"
              d="M168.663 141.487l12.734-7.353-4.245-2.451-10.632 6.141.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60390"
              d="M168.663 141.737a.25.25 0 01-.124-.033l-2.13-1.221a.25.25 0 01-.126-.216l-.012-2.441a.249.249 0 01.125-.217l10.631-6.142a.25.25 0 01.25 0l4.245 2.451a.25.25 0 010 .433l-12.733 7.348a.25.25 0 01-.125.034m-1.881-1.616l1.88 1.077 12.234-7.064-3.745-2.162-10.381 6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60391" d="M83.763 179.463l12.742-7.357-.145-30.528-12.754 7.321z" fill="#d0dadd" />
            <path
              data-name="Path 60392"
              d="M83.763 179.713a.25.25 0 01-.25-.249l-.157-30.564a.25.25 0 01.125-.218l12.754-7.321a.25.25 0 01.374.216l.145 30.528a.25.25 0 01-.125.218l-12.742 7.357a.251.251 0 01-.125.034m.093-30.67l.155 29.988 12.243-7.069-.143-29.953z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60393" d="M96.505 172.105V141.47l-2.122 1.213v30.647z" fill="#fff" />
            <path
              data-name="Path 60394"
              d="M94.381 173.579a.25.25 0 01-.25-.25v-30.647a.25.25 0 01.126-.217l2.122-1.213a.25.25 0 01.374.217v30.635a.25.25 0 01-.125.217l-2.122 1.22a.25.25 0 01-.125.033m.252-30.752v30.069l1.624-.936v-30.06zm1.872 29.278z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60395" d="M85.894 181.914l12.734-7.358v-1.226l-12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60396"
              d="M85.894 182.164a.25.25 0 01-.25-.25v-1.231a.249.249 0 01.125-.216l12.734-7.352a.25.25 0 01.375.216v1.228a.25.25 0 01-.125.217l-12.734 7.358a.25.25 0 01-.125.034m.25-1.337v.654l12.234-7.069v-.657z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60397" d="M85.894 181.913v-1.23l-2.13-1.221.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60398"
              d="M85.895 182.163a.249.249 0 01-.125-.034l-2.125-1.224a.25.25 0 01-.125-.215l-.005-1.228a.25.25 0 01.375-.218l2.13 1.221a.25.25 0 01.126.217v1.231a.251.251 0 01-.25.25m-1.876-1.619l1.626.936v-.654l-1.629-.933z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60399"
              d="M85.893 180.683l12.734-7.353-4.245-2.451-10.636 6.142.013 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60400"
              d="M85.896 180.933a.252.252 0 01-.125-.033l-2.13-1.221a.249.249 0 01-.125-.216l-.013-2.441a.25.25 0 01.125-.218l10.632-6.141a.25.25 0 01.25 0l4.242 2.448a.25.25 0 010 .433l-12.733 7.352a.25.25 0 01-.125.034m-1.881-1.616l1.88 1.077 12.234-7.064-3.745-2.162-10.381 6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60401" d="M92.25 160.826a.97.97 0 10-.97-.97.97.97 0 00.97.97" fill="#fff" />
            <path
              data-name="Path 60402"
              d="M92.25 161.076a1.22 1.22 0 111.22-1.22 1.222 1.222 0 01-1.22 1.22m0-1.94a.72.72 0 10.72.72.721.721 0 00-.72-.72"
              fill="#1d1d1b"
            />
            <path data-name="Path 60403" d="M63.597 169.046l-12.742-7.357v-22.036l12.754 7.32z" fill="#ffc200" />
            <path
              data-name="Path 60404"
              d="M63.597 169.296a.251.251 0 01-.125-.034l-12.742-7.357a.249.249 0 01-.125-.216v-22.036a.25.25 0 01.374-.217l12.754 7.32a.25.25 0 01.126.217l-.012 22.072a.25.25 0 01-.25.25m-12.492-7.751l12.242 7.068.012-21.495-12.253-7.028z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60405" d="M50.856 161.693v-22.058l2.122 1.213v22.065z" fill="#fff" />
            <path
              data-name="Path 60406"
              d="M52.982 163.164a.247.247 0 01-.125-.034l-2.124-1.224a.25.25 0 01-.125-.216v-22.054a.25.25 0 01.374-.217l2.122 1.212a.25.25 0 01.126.217v22.065a.25.25 0 01-.25.25m-1.874-1.619l1.624.936v-21.487l-1.622-.927z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60407" d="M61.467 171.498l-12.734-7.358v-1.226l12.734 7.352z" fill="#fff" />
            <path
              data-name="Path 60408"
              d="M61.468 171.748a.251.251 0 01-.125-.034l-12.734-7.357a.25.25 0 01-.125-.216v-1.226a.25.25 0 01.375-.216l12.734 7.353a.25.25 0 01.125.216v1.231a.25.25 0 01-.25.25m-12.484-7.752l12.234 7.069v-.659l-12.234-7.064z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60409" d="M61.468 171.498v-1.231l2.13-1.221-.005 1.228z" fill="#fff" />
            <path
              data-name="Path 60410"
              d="M61.467 171.748a.251.251 0 01-.25-.25v-1.231a.25.25 0 01.126-.217l2.13-1.221a.25.25 0 01.374.218l-.005 1.228a.25.25 0 01-.125.216l-2.125 1.224a.25.25 0 01-.125.033m.25-1.336v.654l1.626-.936v-.651z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60411"
              d="M61.467 170.267l-12.734-7.352 4.245-2.451 10.632 6.144-.012 2.441z"
              fill="#fff"
            />
            <path
              data-name="Path 60412"
              d="M61.468 170.517a.25.25 0 01-.125-.034l-12.734-7.352a.25.25 0 010-.433l4.245-2.451a.25.25 0 01.25 0l10.632 6.141a.25.25 0 01.125.218l-.012 2.441a.25.25 0 01-.126.216l-2.13 1.221a.248.248 0 01-.124.033m-12.234-7.6l12.234 7.064 1.88-1.078.011-2.152-10.381-6z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60413"
              d="M170.182 101.649c0 2.7 1.91 3.8 4.245 2.451a9.418 9.418 0 004.245-7.352c0-2.7-1.91-3.8-4.245-2.451a9.418 9.418 0 00-4.245 7.352"
              fill="#ffc200"
            />
            <path
              data-name="Path 60414"
              d="M172.54 104.927a2.441 2.441 0 01-1.236-.32 3.21 3.21 0 01-1.372-2.958 9.674 9.674 0 014.37-7.569 2.834 2.834 0 014.62 2.667 9.673 9.673 0 01-4.37 7.569 4.082 4.082 0 01-2.011.611m3.771-10.958a3.589 3.589 0 00-1.76.543 9.12 9.12 0 00-4.12 7.136 2.348 2.348 0 003.87 2.234 9.119 9.119 0 004.12-7.136 2.743 2.743 0 00-1.122-2.526 1.951 1.951 0 00-.988-.252"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60415"
              d="M172.775 103.748a9.417 9.417 0 004.245-7.352 3.083 3.083 0 00-1.1-2.645c1.6-.222 2.748.91 2.748 3a9.418 9.418 0 01-4.245 7.353 2.881 2.881 0 01-3.148.194 4.164 4.164 0 001.5-.547"
              fill="#fff"
            />
            <path
              data-name="Path 60416"
              d="M172.537 104.923a2.4 2.4 0 01-1.4-.425.25.25 0 01.109-.452 3.919 3.919 0 001.406-.516 9.12 9.12 0 004.12-7.136 2.861 2.861 0 00-.99-2.44.25.25 0 01.109-.452 2.467 2.467 0 012.021.546 3.45 3.45 0 011.01 2.7 9.673 9.673 0 01-4.37 7.569 4.084 4.084 0 01-2.014.607m-.5-.559a3.149 3.149 0 002.266-.481 9.12 9.12 0 004.12-7.136 3.018 3.018 0 00-.839-2.322 1.844 1.844 0 00-1.044-.441 3.751 3.751 0 01.731 2.411 9.673 9.673 0 01-4.37 7.569 5.057 5.057 0 01-.865.4m.74-.617z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60417"
              d="M68.916 80.497c-.676-.394-1.229-.165-1.229.508a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60418"
              d="M73.443 85.67a1.5 1.5 0 01-.745-.227l-3.911-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.185 1.185 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.424.841.869.869 0 01-.437.111m-5.145-5.116a.377.377 0 00-.189.045.452.452 0 00-.172.407 2.329 2.329 0 001.105 1.726l3.908 2.279a.735.735 0 00.681.115.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.726l-3.909-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60419"
              d="M68.466 99.734c-.676-.394-1.229-.165-1.229.509a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60420"
              d="M72.994 104.906a1.505 1.505 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.184 1.184 0 011.182.116l3.908 2.279a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.11m-5.145-5.115a.378.378 0 00-.189.045.452.452 0 00-.173.407 2.329 2.329 0 001.105 1.726l3.908 2.279a.734.734 0 00.681.114.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-3.908-2.279a1.022 1.022 0 00-.492-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60421"
              d="M43.329 162.392c-.676-.394-1.229-.166-1.229.508a2.574 2.574 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60422"
              d="M47.857 167.565a1.5 1.5 0 01-.745-.226l-3.908-2.279a2.8 2.8 0 01-1.354-2.158.914.914 0 01.423-.841 1.186 1.186 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.424.841.87.87 0 01-.437.11m-5.145-5.115a.378.378 0 00-.189.045.452.452 0 00-.173.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.732.732 0 00.681.115.451.451 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-3.908-2.279a1.024 1.024 0 00-.492-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60423"
              d="M68.06 83.412l-1.277-.745v2.445l1.277.745c.676.394 1.229.165 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60424"
              d="M68.679 86.301a1.5 1.5 0 01-.745-.227l-1.277-.745a.25.25 0 01-.124-.216v-2.451a.25.25 0 01.376-.216l1.277.745a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.111m-1.646-1.331l1.153.672a.732.732 0 00.681.115.451.451 0 00.173-.407 2.329 2.329 0 00-1.105-1.726l-.9-.526z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60425"
              d="M42.595 141.138l-1.277-.745v2.451l1.277.745c.676.394 1.229.165 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60426"
              d="M43.214 144.031a1.5 1.5 0 01-.745-.227l-1.277-.745a.25.25 0 01-.124-.216v-2.451a.25.25 0 01.376-.216l1.277.745a2.8 2.8 0 011.353 2.158.914.914 0 01-.424.841.867.867 0 01-.437.11m-1.646-1.331l1.153.672a.733.733 0 00.681.115.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-.9-.526z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60427"
              d="M70.183 96.871l-3.39-1.967v2.451l3.39 1.967c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60428"
              d="M70.802 99.77a1.5 1.5 0 01-.745-.227l-3.39-1.966a.25.25 0 01-.124-.216V94.91a.25.25 0 01.375-.216l3.39 1.967a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.111m-3.759-2.553l3.266 1.895a.734.734 0 00.681.115.452.452 0 00.172-.407 2.329 2.329 0 00-1.1-1.726l-3.014-1.749z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60429"
              d="M42.992 137.749c-.676-.394-1.229-.166-1.229.508a2.574 2.574 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60430"
              d="M47.519 142.922a1.5 1.5 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.186 1.186 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.423.841.869.869 0 01-.437.11m-5.145-5.116a.377.377 0 00-.189.045.452.452 0 00-.173.407 2.329 2.329 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.115.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-3.908-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60431"
              d="M44.708 134.892l-3.39-1.967v2.451l3.39 1.967c.676.394 1.229.166 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60432"
              d="M45.327 137.785a1.5 1.5 0 01-.745-.227l-3.389-1.966a.25.25 0 01-.124-.216v-2.451a.25.25 0 01.376-.216l3.39 1.967a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.111m-3.759-2.554l3.266 1.895a.734.734 0 00.682.115.452.452 0 00.172-.407 2.329 2.329 0 00-1.105-1.726l-3.014-1.749z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60433"
              d="M44.708 159.67l-3.39-1.967v2.451l3.39 1.967c.676.394 1.229.166 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60434"
              d="M45.327 162.563a1.5 1.5 0 01-.745-.227l-3.389-1.966a.25.25 0 01-.124-.216v-2.451a.25.25 0 01.376-.216l3.39 1.967a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.11m-3.759-2.553l3.266 1.895a.734.734 0 00.682.115.452.452 0 00.172-.407 2.329 2.329 0 00-1.105-1.726l-3.014-1.749z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60435"
              d="M138.442 161.165c-.676-.394-1.229-.166-1.229.508a2.574 2.574 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60436"
              d="M142.969 166.338a1.506 1.506 0 01-.745-.227l-3.911-2.281a2.8 2.8 0 01-1.353-2.158.914.914 0 01.424-.841 1.186 1.186 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.424.841.869.869 0 01-.437.11m-5.144-5.116a.376.376 0 00-.189.045.452.452 0 00-.173.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.115.452.452 0 00.172-.407 2.329 2.329 0 00-1.106-1.727l-3.908-2.279a1.023 1.023 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60437"
              d="M139.109 127.468c-.676-.394-1.229-.165-1.229.508a2.574 2.574 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60438"
              d="M143.636 132.641a1.5 1.5 0 01-.745-.226l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.424-.841 1.185 1.185 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.424.841.869.869 0 01-.437.11m-5.144-5.115a.377.377 0 00-.189.045.452.452 0 00-.172.407 2.329 2.329 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.115.452.452 0 00.172-.407 2.33 2.33 0 00-1.105-1.726l-3.908-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60439"
              d="M94.541 107.304c-.676-.394-1.229-.165-1.229.509a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.576 2.576 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60440"
              d="M99.068 112.477a1.5 1.5 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.187 1.187 0 011.182.116l3.908 2.279a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.11m-5.144-5.116a.378.378 0 00-.19.045.452.452 0 00-.172.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.114.452.452 0 00.172-.407 2.329 2.329 0 00-1.105-1.726l-3.908-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60441"
              d="M93.527 102.844l-1.279-.742v2.451l1.277.745c.676.394 1.229.165 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60442"
              d="M94.146 105.738a1.5 1.5 0 01-.745-.227l-1.277-.745a.25.25 0 01-.126-.214v-2.451a.25.25 0 01.376-.216l1.277.745a2.8 2.8 0 011.353 2.158.914.914 0 01-.424.84.867.867 0 01-.437.111m-1.646-1.331l1.153.673a.732.732 0 00.681.115.451.451 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-.9-.525z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60443"
              d="M145.317 127.317l-2.246-1.282c-.676-.394-1.229-.165-1.229.509a2.574 2.574 0 001.229 1.942l2.246 1.282z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60444"
              d="M145.317 130.017a.25.25 0 01-.124-.033l-2.246-1.282a2.8 2.8 0 01-1.355-2.159.914.914 0 01.424-.841 1.183 1.183 0 011.182.116l2.245 1.281a.25.25 0 01.126.217v2.451a.251.251 0 01-.25.25m-2.864-3.926a.377.377 0 00-.189.045.452.452 0 00-.172.407 2.329 2.329 0 001.105 1.726l1.871 1.068v-1.875l-2.12-1.21a1.028 1.028 0 00-.494-.16"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60445"
              d="M145.317 168.199l-2.246-1.282c-.676-.394-1.229-.166-1.229.508a2.574 2.574 0 001.229 1.942l2.246 1.282z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60446"
              d="M145.317 170.899a.252.252 0 01-.124-.033l-2.246-1.282a2.8 2.8 0 01-1.355-2.159.914.914 0 01.424-.841 1.186 1.186 0 011.182.116l2.244 1.281a.25.25 0 01.126.217v2.451a.25.25 0 01-.25.25m-2.864-3.926a.378.378 0 00-.189.045.452.452 0 00-.172.407 2.33 2.33 0 001.105 1.726l1.87 1.068v-1.875l-2.12-1.21a1.028 1.028 0 00-.494-.16"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60447"
              d="M171.308 166.917c.676-.394 1.229-.166 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.909 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60448"
              d="M166.78 172.089a.866.866 0 01-.437-.111.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.186 1.186 0 011.182-.116.914.914 0 01.423.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m5.145-5.116a1.021 1.021 0 00-.492.159l-3.908 2.279a2.33 2.33 0 00-1.105 1.726.452.452 0 00.172.407.733.733 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.105-1.726.451.451 0 00-.172-.407.379.379 0 00-.19-.045m-.617-.057z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60449"
              d="M151.854 160.754c.676-.394 1.229-.166 1.229.508a2.574 2.574 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60450"
              d="M147.326 165.926a.869.869 0 01-.437-.11.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.184 1.184 0 011.182-.116.915.915 0 01.424.841 2.8 2.8 0 01-1.354 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.956l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.451.451 0 00.172.407.733.733 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.106-1.726.452.452 0 00-.172-.407.732.732 0 00-.681.115"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60451"
              d="M169.546 171.543c.676-.394 1.229-.165 1.229.509a2.574 2.574 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60452"
              d="M165.018 176.716a.87.87 0 01-.437-.111.914.914 0 01-.423-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.184 1.184 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.354 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.957l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.452.452 0 00.172.407.732.732 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.107-1.725.452.452 0 00-.173-.407.733.733 0 00-.681.114z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60453"
              d="M151.854 119.877c.676-.394 1.229-.165 1.229.509a2.574 2.574 0 01-1.229 1.941l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60454"
              d="M147.326 125.049a.869.869 0 01-.437-.11.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.184 1.184 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.354 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.956l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.451.451 0 00.172.407.732.732 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.106-1.726.452.452 0 00-.172-.407.732.732 0 00-.681.115"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60455"
              d="M189.53 118.392c.676-.394 1.229-.165 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60456"
              d="M185.003 123.565a.867.867 0 01-.437-.111.914.914 0 01-.424-.841 2.8 2.8 0 011.354-2.158l3.908-2.279a1.186 1.186 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.505 1.505 0 01-.745.227m5.144-5.116a1.021 1.021 0 00-.491.159l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.452.452 0 00.173.407.734.734 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.105-1.726.451.451 0 00-.173-.407.377.377 0 00-.189-.045m-.617-.057z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60457"
              d="M186.523 123.774c.676-.394 1.229-.165 1.229.509a2.574 2.574 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60458"
              d="M181.997 128.946a.869.869 0 01-.437-.111.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.185 1.185 0 011.182-.116.914.914 0 01.423.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.957l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.451.451 0 00.173.407.732.732 0 00.681-.115l3.908-2.282a2.328 2.328 0 001.105-1.726.452.452 0 00-.172-.407.733.733 0 00-.682.119z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60459"
              d="M152.568 167.709c.676-.394 1.229-.166 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60460"
              d="M148.04 172.88a.869.869 0 01-.437-.11.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.185 1.185 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m5.144-5.115a1.022 1.022 0 00-.491.159l-3.908 2.279a2.33 2.33 0 00-1.105 1.726.452.452 0 00.172.407.733.733 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.105-1.726.452.452 0 00-.173-.407.378.378 0 00-.189-.045m-.617-.057z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60461"
              d="M173.79 172.848c.676-.394 1.229-.166 1.229.508a2.574 2.574 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60462"
              d="M169.263 178.021a.865.865 0 01-.437-.11.913.913 0 01-.424-.841 2.8 2.8 0 011.354-2.158l3.908-2.279a1.185 1.185 0 011.182-.116.915.915 0 01.424.841 2.8 2.8 0 01-1.354 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m5.145-5.115a1.022 1.022 0 00-.492.159l-3.908 2.279a2.33 2.33 0 00-1.105 1.726.452.452 0 00.172.407.732.732 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.106-1.726.451.451 0 00-.172-.407.378.378 0 00-.19-.045m-.617-.057z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60463"
              d="M194.109 136.298c.676-.394 1.229-.166 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60464"
              d="M189.581 141.467a.869.869 0 01-.437-.111.914.914 0 01-.423-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.184 1.184 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.956l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.451.451 0 00.172.407.733.733 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.105-1.726.452.452 0 00-.173-.407.732.732 0 00-.681.115"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60465"
              d="M191.376 141.643c.676-.394 1.229-.166 1.229.508a2.574 2.574 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60466"
              d="M186.849 146.818a.869.869 0 01-.437-.111.914.914 0 01-.424-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.185 1.185 0 011.182-.116.914.914 0 01.423.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m5.144-5.115a1.022 1.022 0 00-.492.159l-3.908 2.279a2.33 2.33 0 00-1.105 1.726.452.452 0 00.173.407.733.733 0 00.681-.115l3.908-2.279a2.328 2.328 0 001.105-1.726.451.451 0 00-.172-.407.379.379 0 00-.19-.045m-.617-.057z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60467"
              d="M193.952 144.011c.676-.394 1.229-.166 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.908 2.279c-.676.394-1.229.165-1.229-.509a2.575 2.575 0 011.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60468"
              d="M189.424 149.184a.869.869 0 01-.437-.111.914.914 0 01-.423-.841 2.8 2.8 0 011.353-2.158l3.908-2.279a1.186 1.186 0 011.182-.116.914.914 0 01.424.841 2.8 2.8 0 01-1.353 2.158l-3.908 2.279a1.5 1.5 0 01-.745.227m4.653-4.957l-3.908 2.279a2.329 2.329 0 00-1.105 1.726.451.451 0 00.172.407.733.733 0 00.681-.115l3.908-2.279a2.329 2.329 0 001.105-1.726.452.452 0 00-.173-.407.733.733 0 00-.681.115"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60469"
              d="M194.965 139.551l1.277-.745v2.451l-1.277.745c-.676.394-1.229.166-1.229-.508a2.575 2.575 0 011.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60470"
              d="M194.343 142.444a.871.871 0 01-.437-.11.914.914 0 01-.423-.841 2.8 2.8 0 011.36-2.161l1.277-.745a.25.25 0 01.376.216v2.451a.25.25 0 01-.124.216l-1.277.745a1.5 1.5 0 01-.745.227m1.646-3.2l-.9.526a2.329 2.329 0 00-1.105 1.726.451.451 0 00.172.407.733.733 0 00.681-.115l1.153-.672zm-1.027.31z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60471"
              d="M102.844 138.718c-.676-.394-1.229-.166-1.229.508a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60472"
              d="M107.376 143.89a1.505 1.505 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.354-2.158.914.914 0 01.423-.841 1.186 1.186 0 011.182.116l3.908 2.279a2.8 2.8 0 011.353 2.158.915.915 0 01-.423.841.87.87 0 01-.437.11m-5.145-5.115a.378.378 0 00-.189.045.452.452 0 00-.173.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.115.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.726l-3.908-2.279a1.024 1.024 0 00-.492-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60473"
              d="M105.581 144.105c-.676-.394-1.229-.165-1.229.508a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60474"
              d="M110.108 149.278a1.5 1.5 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.184 1.184 0 011.182.116l3.908 2.279a2.8 2.8 0 011.353 2.158.914.914 0 01-.423.841.87.87 0 01-.437.111m-5.145-5.116a.378.378 0 00-.189.045.452.452 0 00-.173.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.734.734 0 00.681.115.452.452 0 00.172-.407 2.329 2.329 0 00-1.105-1.726l-3.908-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60475"
              d="M103.005 146.472c-.676-.394-1.229-.166-1.229.508a2.575 2.575 0 001.229 1.942l3.908 2.279c.676.394 1.229.165 1.229-.509a2.575 2.575 0 00-1.229-1.942z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60476"
              d="M107.532 151.643a1.5 1.5 0 01-.745-.227l-3.908-2.279a2.8 2.8 0 01-1.353-2.158.914.914 0 01.423-.841 1.186 1.186 0 011.182.116l3.908 2.279a2.8 2.8 0 011.354 2.158.914.914 0 01-.423.841.87.87 0 01-.437.11m-5.145-5.115a.379.379 0 00-.19.045.452.452 0 00-.172.407 2.33 2.33 0 001.105 1.726l3.908 2.279a.733.733 0 00.681.115.452.452 0 00.173-.407 2.33 2.33 0 00-1.105-1.727l-3.908-2.279a1.024 1.024 0 00-.491-.159"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60477"
              d="M101.992 142.011l-1.277-.745v2.451l1.277.745c.676.394 1.229.166 1.229-.508a2.575 2.575 0 00-1.229-1.942"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60478"
              d="M102.607 144.904a1.5 1.5 0 01-.745-.227l-1.277-.745a.249.249 0 01-.124-.216v-2.451a.25.25 0 01.376-.216l1.277.745a2.8 2.8 0 011.358 2.158.914.914 0 01-.423.841.871.871 0 01-.437.11m-1.646-1.331l1.153.672a.733.733 0 00.681.115.451.451 0 00.173-.407 2.33 2.33 0 00-1.105-1.726l-.9-.526z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60479"
              d="M145.317 127.316l3.53-2.058c.676-.394 1.229-.165 1.229.509a2.574 2.574 0 01-1.227 1.939l-3.53 2.058z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60480"
              d="M145.317 130.017a.25.25 0 01-.25-.25v-2.451a.25.25 0 01.124-.216l3.53-2.058a1.185 1.185 0 011.182-.116.914.914 0 01.423.841 2.8 2.8 0 01-1.353 2.158l-3.53 2.058a.25.25 0 01-.126.034m.25-2.557v1.872l3.154-1.839a2.33 2.33 0 001.105-1.726.452.452 0 00-.172-.407.732.732 0 00-.681.115z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60481"
              d="M145.308 168.198l3.539-2.064c.676-.394 1.229-.166 1.229.508a2.575 2.575 0 01-1.229 1.942l-3.539 2.064z"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60482"
              d="M145.308 170.9a.25.25 0 01-.25-.25v-2.451a.25.25 0 01.124-.216l3.539-2.06a1.185 1.185 0 011.182-.116.914.914 0 01.423.841 2.8 2.8 0 01-1.353 2.158l-3.54 2.064a.25.25 0 01-.126.034m.25-2.557v1.872l3.164-1.845a2.328 2.328 0 001.107-1.73.451.451 0 00-.172-.407.733.733 0 00-.681.114z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60483" d="M36.373 165.924l84.461 48.448.036 2.438-84.489-48.431z" fill="#fff" />
            <path
              data-name="Path 60484"
              d="M120.87 217.062a.251.251 0 01-.124-.033l-84.489-48.433a.25.25 0 01-.125-.216l-.008-2.453a.25.25 0 01.374-.218l84.461 48.448a.25.25 0 01.126.213l.036 2.438a.251.251 0 01-.25.254M36.63 168.234l83.983 48.14-.027-1.857-83.961-48.162z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60485" d="M202.458 167.314l-81.588 47.053.069 2.445 81.511-47.044z" fill="#fff" />
            <path
              data-name="Path 60486"
              d="M120.94 217.062a.25.25 0 01-.25-.243l-.069-2.445a.25.25 0 01.125-.224l81.588-47.052a.25.25 0 01.375.217l-.008 2.453a.25.25 0 01-.125.216l-81.512 47.044a.25.25 0 01-.125.034m.185-2.553l.053 1.877 81.024-46.763.006-1.875z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60487"
              d="M206.839 167.911c-.364 2.188 1.411 4.306 3.965 4.731s4.919-1.005 5.282-3.193-1.26-5.212-3.814-5.637-5.069 1.911-5.433 4.1"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60488"
              d="M211.707 172.969a5.76 5.76 0 01-.944-.078 5.328 5.328 0 01-3.227-1.79 3.971 3.971 0 01-.944-3.228l.247.041-.247-.041a5.457 5.457 0 011.985-3.183 4.424 4.424 0 016.908 1.149 5.456 5.456 0 01.848 3.654 3.972 3.972 0 01-1.937 2.749 5.189 5.189 0 01-2.689.728m-.053-8.956a4.424 4.424 0 00-2.761 1.065 4.945 4.945 0 00-1.806 2.876 3.48 3.48 0 00.833 2.825 5.008 5.008 0 006.218 1.034 3.481 3.481 0 001.7-2.4 4.946 4.946 0 00-.778-3.306 4.226 4.226 0 00-2.83-2.044 3.52 3.52 0 00-.578-.047"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60489"
              d="M200.251 168.814c0 3.075 2.909 5.567 6.5 5.567s6.5-2.493 6.5-5.567-3.059-9.169-6.647-9.169-6.353 6.094-6.353 9.169"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60490"
              d="M206.747 174.632c-3.72 0-6.747-2.609-6.747-5.817 0-3.141 2.852-9.419 6.6-9.419 3.774 0 6.9 6.236 6.9 9.419 0 3.208-3.027 5.817-6.747 5.817m-.15-14.737c-3.336 0-6.1 5.905-6.1 8.919 0 2.932 2.8 5.317 6.247 5.317s6.247-2.385 6.247-5.317-2.961-8.919-6.4-8.919"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60491"
              d="M207.78 171.775a3.675 3.675 0 007.349 0c0-1.98-1.645-4.405-3.674-4.405s-3.674 2.425-3.674 4.405"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60492"
              d="M211.454 175.61a3.885 3.885 0 01-3.924-3.835c0-2.109 1.751-4.655 3.924-4.655s3.925 2.547 3.925 4.655a3.885 3.885 0 01-3.925 3.835m0-7.99c-1.844 0-3.424 2.285-3.424 4.155a3.426 3.426 0 006.849 0c0-1.871-1.58-4.155-3.425-4.155"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60493"
              d="M197.202 171.721c0 2.039 1.929 3.692 4.308 3.692s4.308-1.653 4.308-3.692-1.929-5.14-4.308-5.14-4.308 3.1-4.308 5.14"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60494"
              d="M201.51 175.663c-2.513 0-4.558-1.768-4.558-3.942 0-2.12 1.957-5.39 4.558-5.39s4.557 3.27 4.557 5.39c0 2.173-2.044 3.942-4.557 3.942m0-8.832c-2.24 0-4.058 2.959-4.058 4.89 0 1.9 1.82 3.442 4.058 3.442s4.057-1.544 4.057-3.442c0-1.931-1.818-4.89-4.057-4.89"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60495"
              d="M31.844 168.38c.737 4.434-2.859 8.726-8.034 9.587s-9.966-2.036-10.7-6.471 2.554-10.562 7.729-11.422 10.272 3.872 11.009 8.306"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60496"
              d="M21.981 178.369a10.26 10.26 0 01-5.316-1.438 7.8 7.8 0 01-3.806-5.394 10.793 10.793 0 011.682-7.225 8.71 8.71 0 0113.618-2.265 10.8 10.8 0 013.93 6.292 7.793 7.793 0 01-1.855 6.335 10.538 10.538 0 01-6.384 3.539 11.413 11.413 0 01-1.87.156m.107-18.148a7.409 7.409 0 00-1.213.1 8.816 8.816 0 00-5.91 4.258 10.283 10.283 0 00-1.612 6.877 7.3 7.3 0 003.571 5.048 10.4 10.4 0 0012.929-2.15 7.3 7.3 0 001.745-5.932 10.284 10.284 0 00-3.752-5.986 9.219 9.219 0 00-5.757-2.215"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60497"
              d="M45.199 170.208c0 6.23-5.894 11.281-13.165 11.281s-13.165-5.051-13.165-11.281 6.2-18.58 13.469-18.58 12.86 12.349 12.86 18.58"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60498"
              d="M32.032 181.739c-7.4 0-13.415-5.173-13.415-11.531s6.211-18.83 13.719-18.83c7.443 0 13.11 12.55 13.11 18.83 0 6.358-6.018 11.531-13.415 11.531m.3-29.862c-7.1 0-13.219 12.294-13.219 18.33 0 6.083 5.794 11.031 12.915 11.031s12.915-4.949 12.915-11.031c0-6.114-5.452-18.33-12.61-18.33"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60499"
              d="M10.604 177.748c.066 1.723 1.808 3.055 3.891 2.976s3.719-1.541 3.653-3.264a4.084 4.084 0 00-3.918-3.689 4.084 4.084 0 00-3.626 3.977"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60500"
              d="M14.317 180.978a4.4 4.4 0 01-2.676-.867 3.1 3.1 0 01-1.287-2.353 4.337 4.337 0 013.866-4.237 4.339 4.339 0 014.178 3.93 3.1 3.1 0 01-1.1 2.444 4.431 4.431 0 01-2.79 1.079h-.188m-3.467-3.232a2.613 2.613 0 001.092 1.972 4.1 4.1 0 005.017-.191 2.614 2.614 0 00.938-2.052 3.855 3.855 0 00-3.66-3.449 3.855 3.855 0 00-3.386 3.718"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60501"
              d="M28.789 175.868a7.448 7.448 0 01-14.891 0c0-4.013 3.333-8.926 7.445-8.926s7.446 4.914 7.446 8.926"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60502"
              d="M21.343 183.383a7.616 7.616 0 01-7.7-7.515c0-4.156 3.433-9.176 7.7-9.176s7.7 5.02 7.7 9.176a7.616 7.616 0 01-7.7 7.515m0-16.191c-3.986 0-7.2 4.746-7.2 8.676a7.2 7.2 0 0014.391 0c0-3.93-3.21-8.676-7.2-8.676"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60503"
              d="M25.779 181.806a2.06 2.06 0 004.043-.154 2.023 2.023 0 10-4.043.154"
              fill="#d0dadd"
            />
            <path
              data-name="Path 60504"
              d="M27.768 183.652a2.476 2.476 0 01-1.5-.488 1.776 1.776 0 01-.735-1.349 2.383 2.383 0 01.6-1.568 2.091 2.091 0 013.227-.123 2.382 2.382 0 01.717 1.517 1.777 1.777 0 01-.63 1.4 2.49 2.49 0 01-1.568.608h-.1m.016-3.729h-.054a1.71 1.71 0 00-1.222.648 1.872 1.872 0 00-.48 1.224 1.285 1.285 0 00.539.97 2.084 2.084 0 002.54-.1 1.286 1.286 0 00.464-1.009 1.874 1.874 0 00-.572-1.184 1.728 1.728 0 00-1.215-.554"
              fill="#1d1d1b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgHousehold
