import * as React from "react"

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 21" d="M24 24H0V0h24z" fill="none" opacity={0.87} />
      <path
        data-name="Path 22"
        d="M8.13 15.29l3.88-3.88 3.88 3.88a1 1 0 001.41-1.41l-4.59-4.59a1 1 0 00-1.41 0l-4.59 4.59a1 1 0 000 1.41 1.017 1.017 0 001.42 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowUp
