import { useParams } from "react-router"

export const useNumberParam = (key: string) => {
  const params = useParams()
  const param = params[key]

  if (!param) {
    return
  }

  return Number.parseInt(param, 10)
}
