import Text from "../../../../components/Text"
import { Flex } from "../../../../components/flex/Flex"
import { styled, theme } from "../../../../styles/stitches.config"
import { Edit, NoEdit } from "../../../../images/xd/icons"
import { ReactNode, useCallback } from "react"
import InfoCellContainer from "./InfoCellContainer"
import { Spacer } from "../../../../components/spacer/Spacer"
import InfoBox from "../../../../components/info/InfoBox"

const defaultCellFormatter = <T extends ReactNode>(value: T) => (value && `${value}`.length > 0 ? value : `-`)

const SEditIcon = styled(Edit, {
  width: theme.sizes.s6,
  height: theme.sizes.s6,
  color: theme.colors.secondary,

  flexShrink: 0,

  hover: {
    cursor: "pointer",
  },
})

export interface InfoCellProps<T extends ReactNode> {
  label: string
  value: T
  onEdit?: (value: T) => void
  formatter?: (value: T) => ReactNode
  editing?: boolean
  cannotEditInfo?: ReactNode
}

export default function InfoCell<T extends ReactNode>({
  label,
  value,
  onEdit,
  formatter = defaultCellFormatter,
  editing,
  cannotEditInfo,
}: InfoCellProps<T>) {
  const handleEdit = useCallback(() => {
    onEdit?.(value)
  }, [value, onEdit])

  const renderContent = useCallback(() => {
    return (
      <InfoCellContainer>
        <Text type="headerH5" css={{ lineHeight: 1.5 }}>
          {label}
        </Text>
        {!editing && <Spacer size={theme.space.s1} />}
        <Text type="textsLarge" css={{ lineHeight: 1.5, ellipsis: {} }}>
          {formatter ? formatter(value) : value}
        </Text>
      </InfoCellContainer>
    )
  }, [label, value, formatter, editing])

  return (onEdit && !editing) || cannotEditInfo ? (
    <Flex justify="between" align="end" css={{ gap: theme.space.s1 }}>
      {renderContent()}
      {cannotEditInfo ? (
        <InfoBox renderIcon={() => <NoEdit />}>{cannotEditInfo}</InfoBox>
      ) : (
        <SEditIcon onClick={handleEdit} />
      )}
    </Flex>
  ) : (
    renderContent()
  )
}
