import { useParams } from "react-router"
import { useEffectOnce } from "react-use"
import { trpc } from "../../api/trpc/trpc"
import { Flex } from "../../components/flex/Flex"
import Loader from "../../components/loader/Loader"
import { useTranslation } from "react-i18next"
import { Flexi2022ApiResponse } from "../flexi2022/Flexi2022ApiResponse"

function FlexiItSelfBillingContent(props: { hash: string }) {
  const { hash } = props

  const { t } = useTranslation(["flexiItSelfBilling"])

  const sendMutation = trpc.useMutation("flexiItSelfBilling.send", {})
  useEffectOnce(() => sendMutation.mutate({ hash }))

  if (sendMutation.error?.shape?.data.portalCode === "FLEXI_IT_SELF_BILLING_FAILED_TO_CREATE") {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexiItSelfBilling:errors_failed_to_create_heading")}
        description={t("flexiItSelfBilling:errors_failed_to_create_text")}
      />
    )
  }

  if (sendMutation.error?.shape?.data.portalCode === "FLEXI_IT_SELF_BILLING_INVALID_SIGNATURE") {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexiItSelfBilling:errors_invalid_signature_heading")}
        description={t("flexiItSelfBilling:errors_invalid_signature_text")}
      />
    )
  }

  if (sendMutation.data?.result) {
    if (sendMutation.data?.result === "CURRENT_MONTH") {
      return (
        <Flexi2022ApiResponse
          heading={t("flexiItSelfBilling:success_current_month_contract_sent_heading")}
          description={t("flexiItSelfBilling:success_current_month_contract_sent_text")}
        />
      )
    } else if (sendMutation.data?.result === "NEXT_MONTH") {
      return (
        <Flexi2022ApiResponse
          heading={t("flexiItSelfBilling:success_next_month_contract_sent_heading")}
          description={t("flexiItSelfBilling:success_next_month_contract_sent_text")}
        />
      )
    }

    return (
      <Flexi2022ApiResponse
        heading={t("flexiItSelfBilling:success_support_contract_sent_heading")}
        description={t("flexiItSelfBilling:success_support_contract_sent_text")}
      />
    )
  }

  return (
    <Flex align="center" justify="center" css={{ fullheight: {} }}>
      <Loader />
    </Flex>
  )
}

export function FlexiItSelfBilling() {
  const { hash } = useParams()
  const { t } = useTranslation(["flexiItSelfBilling"])

  if (!hash) {
    return (
      <Flexi2022ApiResponse
        isError
        heading={t("flexiItSelfBilling:errors_invalid_link_heading")}
        description={t("flexiItSelfBilling:errors_invalid_link_text")}
      />
    )
  }

  return <FlexiItSelfBillingContent hash={hash} />
}
