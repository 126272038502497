import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Flex } from "../../../components/flex/Flex"
import { Spacer } from "../../../components/spacer/Spacer"
import Text from "../../../components/Text"
import { ArrowForward } from "../../../images/xd/icons"
import { theme } from "../../../styles/stitches.config"

export function HomeSupplyPointCardBillingState(props: { hasBillingDebt?: boolean; unpaidLink: string }) {
  const { hasBillingDebt, unpaidLink } = props
  const { t } = useTranslation(["common"])
  if (hasBillingDebt) {
    return (
      <Link to={unpaidLink}>
        <Flex align="center" justify="center" css={{ gap: theme.space.s1 }}>
          <Text type="headerH3Negative">{t("common:underpayment")}</Text>
          <ArrowForward width={24} color="red" />
        </Flex>
      </Link>
    )
  }

  return (
    <Flex align="center">
      <Text type="headerH3Positive">{t("common:in_order")}</Text>
      <Spacer size={theme.space.s6_5} />
    </Flex>
  )
}
