import HeaderPlaceholder from "./components/HeaderPlaceholder"
import { Spacer } from "../spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { Div } from "../div/Div"
import TablePlaceholder from "./components/TablePlaceholder"
import { Flex } from "../flex/Flex"

interface SectionProps {
  cols: number
  contentHeight: number
}

const SectionPlaceholder = ({ cols, contentHeight }: SectionProps) => {
  return (
    <>
      <HeaderPlaceholder cols={cols} />
      <Spacer size={theme.space.s4} />
      <HeaderPlaceholder cols={cols} height={contentHeight} />
    </>
  )
}

const DeliveryPointPlaceholder = () => {
  return (
    <>
      <Div css={{ maxWidth: theme.sizes.s220 }}>
        <SectionPlaceholder cols={1} contentHeight={204} />

        <Spacer size={theme.space.s10_5} />

        <SectionPlaceholder cols={2} contentHeight={142} />

        <Spacer size={theme.space.s10_5} />

        <SectionPlaceholder cols={1} contentHeight={116} />

        <Spacer size={theme.space.s10_5} />

        <Flex>
          <HeaderPlaceholder cols={1} height={142} />
          <TablePlaceholder />
        </Flex>
      </Div>
    </>
  )
}

export default DeliveryPointPlaceholder
