import { styled, theme } from "../../styles/stitches.config"

export const SWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: `repeat(auto-fill, minmax(${theme.space.s80}, 1fr))`,
  columnGap: theme.sizes.s6,
  rowGap: theme.sizes.s6,
})

export const EmptyPlaceholderWrapper = styled("div", {
  marginLeft: theme.sizes.s8,
  marginRight: theme.sizes.s8,
  paddingTop: "10%",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  textAlign: "center",
})
