import React from "react"
import { theme } from "../../styles/stitches.config"
import { Spacer } from "../spacer/Spacer"
import HeaderPlaceholder from "./components/HeaderPlaceholder"
import TablePlaceholder from "./components/TablePlaceholder"

const PaymentsPlaceholder = () => {
  return (
    <>
      <HeaderPlaceholder />
      <Spacer size={theme.space.s7} />
      <TablePlaceholder />
    </>
  )
}

export default PaymentsPlaceholder
