import * as React from "react"

function SvgNoEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoEdit_svg__a">
          <path data-name="Rectangle 17155" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 16300" clipPath="url(#_NoEdit_svg__a)">
        <path
          data-name="Path 51363"
          d="M20.71 7.04a1 1 0 000-1.41l-2.34-2.34a1 1 0 00-1.41 0l-1.83 1.83 3.75 3.75z"
          fill="#c5d3d6"
        />
        <path
          data-name="Path 51364"
          d="M10.513 14.14a2.583 2.583 0 01-.014-3.6 2.569 2.569 0 013.631-.04l1.561 1.561 2.119-2.12-3.75-3.75L3.15 17.1a.487.487 0 00-.15.36v3.04a.5.5 0 00.5.5h3.04a.475.475 0 00.35-.15l5.169-5.165z"
          fill="#c5d3d6"
        />
        <path
          data-name="Path 51365"
          d="M17.634 16.121l3.028-3.028a1.063 1.063 0 00-1.509-1.5l-3.024 3.02L13.1 11.59l-.023-.021a1.067 1.067 0 00-1.507 1.51l3.047 3.047-3.023 3.024-.021.023a1.067 1.067 0 001.507 1.511l3.049-3.048 3.024 3.026.021.022a1.068 1.068 0 101.518-1.5l-.009-.009z"
          fill="#ec5d5c"
        />
      </g>
    </svg>
  )
}

export default SvgNoEdit
