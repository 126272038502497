import * as React from "react"

function SvgDocument(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_Document_svg__a">
          <path data-name="Rectangle 16836" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9145" clipPath="url(#_Document_svg__a)">
        <path
          data-name="Path 1992"
          d="M9 16h6a1 1 0 010 2H9a1 1 0 010-2m0-4h6a1 1 0 010 2H9a1 1 0 010-2m5.57-9.43A1.946 1.946 0 0013.194 2H6a2.006 2.006 0 00-2 2v16a2 2 0 001.99 2H18a2.006 2.006 0 002-2V8.806a1.946 1.946 0 00-.57-1.376zM18 20H6V4h7v3.569A1.431 1.431 0 0014.431 9H18z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgDocument
