import * as React from "react"

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 118" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 119"
        d="M3 17.46v3.04a.5.5 0 00.5.5h3.04a.469.469 0 00.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1a.491.491 0 00-.15.36zM20.71 7.04a1 1 0 000-1.41l-2.34-2.34a1 1 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEdit
