type Environment = "production" | "development" | "not-set"

export const getEnv = (): Environment => {
  return window.location.hostname === "moje.deltagreen.cz"
    ? "production"
    : window.location.hostname.startsWith("localhost")
    ? "not-set"
    : "development"
}

export const isProd = (): boolean => {
  return getEnv() === "production"
}

export const isDev = () => !isProd()

export const isLocal = () => getEnv() === "not-set"
