import Card from "../../../../components/Card"
import { CSSProp, media, styled, theme } from "../../../../styles/stitches.config"

type RowSize = 2 | 4

const createGridStyles = (size: RowSize): CSSProp => ({
  "& > *": {
    borderTop: `${theme.space.spx} solid ${theme.colors.lines}`,
    borderLeft: `${theme.space.spx} solid ${theme.colors.lines}`,
    padding: theme.space.s6,
    [`&:nth-child(-n + ${size})`]: {
      borderTop: "none",
      paddingTop: theme.space.s0,
      paddingRight: theme.space.s6,
      paddingBottom: theme.space.s6,
      paddingLeft: theme.space.s6,
    },
    [`&:nth-child(${size}n + 1)`]: {
      borderLeft: "none",
      paddingLeft: `${theme.space.s0} !important`,
    },
    [`&:nth-child(${size}n)`]: {
      paddingRight: `${theme.space.s0} !important`,
    },
    [`&:nth-last-child(-n + ${size})`]: {
      paddingRight: theme.space.s6,
      paddingBottom: theme.space.s0,
      paddingLeft: theme.space.s6,
    },
  },
})

export const SCard = styled(Card, {
  width: "100%",
  "@md": { width: "initial" },
})

export const SContainer = styled("div", {
  display: "flex",
  width: "100%",
})

const largeGridStyle = {
  gridTemplateColumns: `${theme.sizes.s64} ${theme.sizes.s56} ${theme.sizes.s48} ${theme.sizes.s36}`,
  ...createGridStyles(4),
}

const mediumGridStyle = {
  gridTemplateColumns: `${theme.sizes.s90} ${theme.sizes.s72}`,
  ...createGridStyles(2),
}

export const GridInfoCard = styled("div", {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  paddingBlock: theme.space.s7,
  paddingInline: theme.space.s10,

  display: "flex",
  flexDirection: "column",
  gap: theme.space.s4,

  "@md": {
    display: "grid",
    gap: theme.space.s0,
  },

  [`@media (min-width: ${media.md}px) and (max-width: 865px)`]: {
    ...mediumGridStyle,
  },

  [`@media (min-width: 865px) and (max-width: ${media.lg}px)`]: {
    ...largeGridStyle,
  },

  [`@media (min-width: ${media.lg}px) and (max-width: 1160px)`]: {
    ...mediumGridStyle,
  },

  "@media(min-width: 1160px)": {
    ...largeGridStyle,
  },
})
