import { Flex } from "../../components/flex/Flex"
import { fontVariants, styled, theme } from "../../styles/stitches.config"

export const AdvertisementCard = styled("div", {
  maxWidth: 816,

  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  paddingTop: theme.space.s6,
  paddingBottom: theme.space.s2_5,
  paddingLeft: theme.space.s5,
  paddingRight: theme.space.s2,

  "@md": {
    width: 816,
  },
})

export const AdvertisementHeader = styled("span", {
  ...fontVariants.headerH3,
  fontSize: 20,
})

export const ComingSoonTag = styled("span", {
  ...fontVariants.menuItem,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontSize: 10,
  lineHeight: "21px",
  backgroundColor: theme.colors.gray,
  paddingInline: theme.space.s0_5,
  borderRadius: "6px 6px 6px 0px",

  position: "relative",
  right: "-30%",

  // hack to get around the element being rendered above the close tooltip content
  zIndex: 0,

  "@md": {
    fontSize: 14,
    right: "-50%",
    paddingInline: theme.space.s4,
    paddingBlock: theme.space.s1,
  },
})

export const PartnerLogos = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  gap: theme.space.s2,
  height: 32,
  "@sm": { gap: theme.space.s6, justifyContent: "start" },
  "@md": { height: 80 },
})

export const Logo = styled(Flex, {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    size: {
      small: {
        "& > svg": {
          width: 48,
          "@sm": {
            width: 64,
          },

          "@md": {
            width: 120,
          },
        },
      },
      large: {
        "& > svg": {
          width: 78,
          "@md": {
            width: 120,
          },
        },
      },
    },
  },

  defaultVariants: {
    size: "small",
  },
})

export const AutomaticsDescription = styled("p", {
  ...fontVariants.textsLarge,
  color: theme.colors.texts,
  fontSize: 12,
  "@sm": { fontSize: 16 },
  flex: 2,
  "@md": { maxWidth: 338 },
})

export const AutomaticsKeyPoints = styled("div", {
  display: "flex",
  flex: 2,
  height: 93,
  gap: theme.space.s4,
})

export const AutomaticsKeyPointHeader = styled("span", {
  ...fontVariants.headerH2,
  color: theme.colors.secondary,
  margin: 0,
  fontSize: 24,
  "@sm": { fontSize: 32 },
})

export const AutomaticsKeyPointDescription = styled("span", {
  ...fontVariants.textsLarge,
  color: theme.colors.texts,
  fontSize: 12,
  "@sm": { fontSize: 16 },
})
