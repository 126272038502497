import { round } from "lodash"
import { Trans } from "react-i18next"
import { ColorType } from "../styles/stitches.config"
import { formatNumber } from "../utils/format"
import { fixNegativeZero } from "../utils/number"
import Text, { TextType } from "./Text"

interface Props {
  value: number
  valueTextType: TextType
  maxFractionDigits?: number
  unit: string
  unitTextType: TextType
  valueFontSize?: number
  unitFontSize?: number
  unitColor?: ColorType
  valueColor?: ColorType
}

export interface TextStyle {
  fontSize?: number
  textType?: TextType
}

export const FormattedUnit = ({
  unit,
  unitTextType,
  unitFontSize,
  value,
  valueTextType,
  valueFontSize,
  maxFractionDigits = 2,
  unitColor,
  valueColor,
}: Props) => {
  let roundedValue = round(value, maxFractionDigits)
  roundedValue = fixNegativeZero(roundedValue)

  return (
    <Text type={valueTextType} css={{ fontSize: valueFontSize }} color={valueColor}>
      <Trans
        defaults="{{value}} <0>{{unit}}</0>"
        shouldUnescape={true}
        values={{ unit, value: formatNumber(roundedValue, maxFractionDigits, maxFractionDigits) }}
      >
        <Text type={unitTextType} css={{ fontSize: unitFontSize }} color={unitColor} />
      </Trans>
    </Text>
  )
}
