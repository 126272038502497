import { styled, theme } from "../../../styles/stitches.config"

export default styled("div", {
  maxWidth: theme.space.s320,
  margin: "0 auto",

  variants: {
    variant: {
      top: {
        padding: `${theme.space.s4} ${theme.space.s4} ${theme.space.s0} ${theme.space.s4}`,
      },
      bottom: {
        padding: `${theme.space.s0} ${theme.space.s4} ${theme.space.s4} ${theme.space.s4}`,
      },
      mobile: {
        padding: theme.space.s0,
      },
    },
  },
})
