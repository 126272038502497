import { trpc } from "../../api/trpc/trpc"
import { resolveQueriesState } from "../../utils/queries"

export function useNotifyAboutDataTariff(deliveryPointId: number) {
  const myselfQuery = trpc.useQuery(["users.myself"])
  const productionDataTariffStatusQuery = trpc.useQuery([
    "flexibility.getProductionDataTariffApplicationStatus",
    { deliveryPointId },
  ])
  const onceLoggedInQuery = trpc.useQuery(["flexibility.hasDeliveryPointLoggedInToProteus", { deliveryPointId }])

  const { isError, isLoading } = resolveQueriesState([myselfQuery, productionDataTariffStatusQuery, onceLoggedInQuery])

  return {
    isError,
    isLoading: isLoading || !myselfQuery.data || !productionDataTariffStatusQuery.data,
    notifyAboutTariff: !myselfQuery.data?.proteusEnabled && productionDataTariffStatusQuery.data?.status === undefined,
    status:
      myselfQuery.data?.proteusEnabled || onceLoggedInQuery.data?.hasLoggedInToProteus
        ? ("LOGGED_IN" as const)
        : productionDataTariffStatusQuery.data?.status,
  }
}
