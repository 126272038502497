import * as React from "react"

function SvgVisibilityOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 326" d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 327"
        d="M12 6.5a4.938 4.938 0 014.76 6.46l3.06 3.06A11.8 11.8 0 0023 11.49 11.838 11.838 0 008.36 4.57l2.17 2.17A5.14 5.14 0 0112 6.5zM2.71 3.16a1 1 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5a11.8 11.8 0 0015.31 6.68l2.72 2.72a1 1 0 101.41-1.41L4.13 3.16a1.008 1.008 0 00-1.42 0zM12 16.5a5 5 0 01-5-5 4.911 4.911 0 01.49-2.14l1.57 1.57a3.434 3.434 0 00-.06.57 3 3 0 003 3 2.694 2.694 0 00.57-.07L14.14 16a4.813 4.813 0 01-2.14.5zm2.97-5.33a2.969 2.969 0 00-2.64-2.64z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgVisibilityOff
