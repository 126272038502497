import * as zod from "zod"
import { useTranslation } from "react-i18next"
import { usePasswordSchema } from "../../../../utils/zod"

export const usePasswordEditFormSchema = () => {
  const { t } = useTranslation("auth")
  const passwordSchema = usePasswordSchema()

  const schema = zod
    .object({
      password: zod.string().min(1),
      newPassword: passwordSchema,
      newPasswordCheck: zod.string(),
    })
    .refine(
      (value) => value && value.newPassword && value.newPasswordCheck && value.newPassword === value.newPasswordCheck,
      {
        message: t("profile_password_edit_passwords_have_to_match"),
        path: ["newPasswordCheck"],
      }
    )

  return { schema }
}

export type PasswordEditFormValues = zod.infer<ReturnType<typeof usePasswordEditFormSchema>["schema"]>
