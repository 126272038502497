import { styled } from "@stitches/react"
import { theme } from "../../styles/stitches.config"

export const SCardWrapper = styled("div", {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  variants: {
    isFullWidth: {
      true: {
        width: "100%",
        height: "100%",
      },
      false: {
        width: theme.sizes.s139,
        height: theme.sizes.s132,
      },
    },
  },
})

export const SRrappweLeft = styled("div", {
  width: "100%",
  alignItems: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
})

export const SText = styled("span", {
  marginTop: theme.sizes.s0_5,
})
