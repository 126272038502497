import { MINIMAL_VIEWPORTS } from "@storybook/addon-viewport"
import { ComponentStory } from "@storybook/react"
import TablePlaceholderComponent from "./components/TablePlaceholder"
import HeaderPlaceholderComponent from "./components/HeaderPlaceholder"
import ChartPlaceholderComponent from "./components/ChartPlaceholder"
import RectPlaceholderComponent from "./components/RectPlaceholder"
import PaymentsPlaceholderComponent from "./PaymentsPlaceholder"
import OverviewPlaceholderComponent from "./OverviewPlaceholder"
import HomePlaceholderComponent from "./HomePlaceholder"
import InvoiceDetailPlaceholderComponent from "./InvoiceDetailPlaceholder"

export default {
  title: "Components/Placeholder",
  parameters: {
    layout: "fullscreen",
    viewport: {
      viewports: MINIMAL_VIEWPORTS,
      defaultViewport: "responsive",
    },
  },
}

export const TablePlaceholder: ComponentStory<typeof TablePlaceholderComponent> = () => <TablePlaceholderComponent />

export const HeaderPlaceholder: ComponentStory<typeof HeaderPlaceholderComponent> = () => <HeaderPlaceholderComponent />

export const ChartPlaceholder: ComponentStory<typeof ChartPlaceholderComponent> = () => <ChartPlaceholderComponent />

export const RectPlaceholder: ComponentStory<typeof RectPlaceholderComponent> = () => <RectPlaceholderComponent />

export const PaymentsPlaceholder: ComponentStory<typeof PaymentsPlaceholderComponent> = () => (
  <PaymentsPlaceholderComponent />
)

export const OverviewPlaceholder: ComponentStory<typeof OverviewPlaceholderComponent> = () => (
  <OverviewPlaceholderComponent />
)

export const HomePlaceholder: ComponentStory<typeof HomePlaceholderComponent> = () => <HomePlaceholderComponent />

export const InvoiceDetailPlaceholder: ComponentStory<typeof InvoiceDetailPlaceholderComponent> = () => (
  <InvoiceDetailPlaceholderComponent />
)
