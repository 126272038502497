import { useTranslation } from "react-i18next"
import * as zod from "zod"

export const useLoginFormSchema = () => {
  const { t } = useTranslation("validation")

  const schema = zod.object({
    email: zod.string().email({ message: t("invalid_email") }),
    password: zod.string().min(1),
  })

  return { schema: schema }
}

export type LoginFormValues = zod.infer<ReturnType<typeof useLoginFormSchema>["schema"]>
