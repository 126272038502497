import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { trpc } from "../../api/trpc/trpc"
import { Button } from "../../components/button/Button"
import { Flex } from "../../components/flex/Flex"
import { FormControl } from "../../components/form/FormControl"
import { AuthLayout } from "../../components/layouts/authLayout/AuthLayout"
import PasswordInput from "../../components/passwordInput/PasswordInput"
import { Spacer } from "../../components/spacer/Spacer"
import Text from "../../components/Text"
import { LINKS } from "../../constants/links"
import { theme } from "../../styles/stitches.config"
import { toasts } from "../../utils/toasts"
import { isTRPCError } from "../../utils/types"
import { SForm, SLogo } from "./LoginContent.styled"
import { LoginFormValues, useLoginFormSchema } from "./LoginContent.utils"
import { pushAnalyticsEvent } from "../../utils/analytics"

export function LoginContent() {
  const { t } = useTranslation(["common", "auth"])
  const navigate = useNavigate()

  const loginMutation = trpc.useMutation(["auth.login"], { onError: undefined })
  const [search] = useSearchParams()

  const { schema } = useLoginFormSchema()
  const form = useForm<LoginFormValues>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  async function onSubmit(formValues: LoginFormValues) {
    try {
      await loginMutation.mutateAsync(formValues)

      const next = search.get("next")
      navigate(next ? next : LINKS.home)
      pushAnalyticsEvent("userData", { isClient: true })
    } catch (error) {
      if (isTRPCError(error)) {
        if (error.data?.code === "UNAUTHORIZED") {
          toasts.error(t("auth:login_incorrect_password"))
          return
        }

        if (error.data?.code === "NOT_FOUND") {
          toasts.error(t("auth:login_user_does_not_exist"))
          return
        }
      }

      captureException(error)
      toasts.error(t("common:generic_error"))
    }
  }

  return (
    <AuthLayout>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <SForm onSubmit={form.handleSubmit(onSubmit)}>
        <Flex justify="center">
          <SLogo />
        </Flex>

        <Spacer size={theme.space.s14} />

        <FormControl label={t("common:email")} error={form.formState.errors.email?.message}>
          <input placeholder={t("auth:login_email_placeholder")} autoCapitalize="none" {...form.register("email")} />
        </FormControl>

        <Spacer size={theme.space.s12} />

        <PasswordInput
          label={t("auth:login_password_label")}
          error={form.formState.errors.password?.message}
          formRegisterProps={form.register("password")}
          placeholder={t("auth:login_password_placeholder")}
        />

        <Spacer size={theme.space.s16} />

        <Button variant="primary" type="submit" isFullwidth isSubmitting={form.formState.isSubmitting}>
          {t("auth:login_submit_button")}
        </Button>

        <Spacer size={theme.space.s8} />

        <Link to={LINKS.authForgotPassword}>
          <Text type="headerH4">{t("auth:login_forgot_password")}</Text>
        </Link>
      </SForm>
    </AuthLayout>
  )
}
