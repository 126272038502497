import * as HoverCard from "@radix-ui/react-hover-card"
import { styled, theme } from "../../styles/stitches.config"

export const HeaderTooltipTrigger = styled(HoverCard.Trigger, {
  cursor: "default",
})

export const HeaderTooltipContent = styled(HoverCard.Content, {
  backgroundColor: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.default})`,
  padding: theme.space.s6,
  maxWidth: `80vw`,
})

export const HeaderTooltipArrow = styled(HoverCard.Arrow, {
  fill: theme.colors.white,
  width: theme.space.s5,
  height: theme.space.s4,
})

export const SHeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  gap: theme.space.s6,
  flexWrap: "wrap",

  paddingLeft: theme.space.s6,
  paddingRight: theme.space.s6,

  "@md": {
    justifyContent: "space-between",
  },

  "@lg": {
    paddingLeft: 0,
    paddingRight: 0,
  },
})
