import React, { ComponentProps, CSSProperties } from "react"
import * as HoverCardPrimitive from "@radix-ui/react-hover-card"
import { Info } from "../../images/xd/icons"
import { InfoBoxArrow, InfoBoxContent, InfoBoxTrigger } from "./InfoBox.styled"
import { CSSProp, theme } from "../../styles/stitches.config"
import { assertUnreachable } from "../../utils/types"
import { useTouchTooltip } from "../../hooks/useTouchTooltip"

interface Props {
  variant?: "default" | "error" | "warning" | "success" | "neutral"
  inline?: boolean
  align?: ComponentProps<typeof InfoBoxContent>["align"]
  css?: CSSProp
  contentCSS?: CSSProp
  iconCSS?: CSSProperties
  side?: ComponentProps<typeof InfoBoxContent>["side"]
  renderIcon?: (color: string) => JSX.Element
  size?: ComponentProps<typeof InfoBoxTrigger>["size"]
}

const openDelay = 150
const offset = 8

function getIconColor(variant: Props["variant"]) {
  switch (variant) {
    case "error":
      return theme.colors.errors
    case "warning":
      return theme.colors.warning
    case "success":
      return theme.colors.successes
    case "neutral":
      return theme.colors.primary
    case "default":
    case undefined:
      return theme.colors.textsAlt
    default:
      return assertUnreachable(variant)
  }
}

export default function InfoBox({
  children,
  variant,
  css,
  inline,
  align,
  side,
  renderIcon,
  contentCSS,
  iconCSS,
  size,
}: React.PropsWithChildren<Props>) {
  const { open, handleToggle } = useTouchTooltip()
  return (
    <HoverCardPrimitive.Root openDelay={openDelay} open={open} onOpenChange={handleToggle}>
      <InfoBoxTrigger asChild css={css} inline={inline} size={size} onTouchEnd={handleToggle}>
        <span>
          {renderIcon ? (
            renderIcon(getIconColor(variant).value)
          ) : (
            <Info color={getIconColor(variant).value} style={iconCSS} />
          )}
        </span>
      </InfoBoxTrigger>

      {children && (
        <InfoBoxContent align={align} sideOffset={offset} avoidCollisions side={side} css={contentCSS}>
          <InfoBoxArrow offset={offset} />

          {children}
        </InfoBoxContent>
      )}
    </HoverCardPrimitive.Root>
  )
}
