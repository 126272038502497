import * as React from "react"

function SvgNewReading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NewReading_svg__a">
          <path data-name="Rectangle 16803" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9063">
        <g data-name="Group 9062" clipPath="url(#_NewReading_svg__a)">
          <g data-name="Group 9060" fill="#7ca7ad">
            <path
              data-name="Path 1966"
              d="M18.73 6.223H10.234a.659.659 0 00-.659.659v3.707a.659.659 0 00.659.659h2.754a.375.375 0 00.149 0h2.7a.375.375 0 00.149 0h2.749a.659.659 0 00.659-.659V6.881a.659.659 0 00-.664-.658zM15.248 7.54v2.39h-1.527V7.54zm-4.355 0h1.511v2.39h-1.511zm7.178 2.39h-1.506V7.54h1.506z"
            />
            <path
              data-name="Path 1967"
              d="M15.599 13.906h-.515l.179-1.267a.519.519 0 00-.653-.583.516.516 0 00-.312.244q-.461.812-.818 1.437l-.683 1.2a.5.5 0 00-.054.524.477.477 0 00.448.242h.512l-.18 1.269a.505.505 0 00-.006.079.521.521 0 00.516.52h.005a.516.516 0 00.45-.263q.217-.383 1.533-2.68l.014-.028a.484.484 0 00-.013-.482.478.478 0 00-.423-.212zm-1.561 3.3z"
            />
            <path
              data-name="Path 1968"
              d="M21.34 3.12H7.77L2.938.862a.66.66 0 00-.633.041.659.659 0 00-.3.556V22.54a.659.659 0 00.938.6l4.832-2.258h7.079v-1.32H8.287V4.439h12.4V15.19a1.178 1.178 0 01.637.359l.363.4h.317V3.78a.658.658 0 00-.664-.66zM3.317 2.493l3.648 1.7v15.6l-3.648 1.7z"
            />
          </g>
          <g data-name="Group 9061" fill="#ffc200">
            <path
              data-name="Path 1969"
              d="M19.731 17.862a1.949 1.949 0 101.679 1.679 1.95 1.95 0 00-1.679-1.679zm.892 2.168a1.169 1.169 0 11-1.146-1.4 1.17 1.17 0 011.147 1.4z"
            />
            <path
              data-name="Path 1970"
              d="M22.596 16.678h-1.04a.445.445 0 01-.328-.145l-.449-.49a.445.445 0 00-.328-.145h-1.947a.445.445 0 00-.328.145l-.449.49a.445.445 0 01-.328.145h-1.04a.782.782 0 00-.779.779v4.677a.782.782 0 00.779.78h6.236a.782.782 0 00.779-.78v-4.676a.782.782 0 00-.778-.78zm0 5.456H16.36v-4.676h1.382a.444.444 0 00.328-.145l.449-.49a.444.444 0 01.319-.144h1.274a.444.444 0 01.324.141l.451.493a.444.444 0 00.325.145h1.383z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgNewReading
