import * as React from "react"

function SvgVictronActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.365 18.877" {...props}>
      <defs>
        <clipPath id="_Victron_active_svg__a">
          <path data-name="Rectangle 17090" fill="none" d="M0 0h98.365v18.877H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 16144" clipPath="url(#_Victron_active_svg__a)">
        <path
          data-name="Path 51124"
          d="M4.333 2.872a4.228 4.228 0 00.951-1 3.083 3.083 0 011.049-.9L6.35.954a3.9 3.9 0 00-3.705.787S.414 3.249 0 9.512c0 0-.115 1.049.787 1.213 0 0 .951.2 1.049-.607.115-.787.115-4.755 1.853-6.788a1.368 1.368 0 01.639-.459"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51125"
          d="M7.334 3.856A6.344 6.344 0 019.679.806a3.866 3.866 0 00-3.394.837S4.202 2.922 3.678 8.021a10.4 10.4 0 00-.082 1.886.881.881 0 00.837.787s.951.082.984-.787l.082-1.213s.148-3.394 1.508-5.1z"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51126"
          d="M10.825 3.823A6.514 6.514 0 0113.057.841V.805h-.017a4.149 4.149 0 00-3.771 1.213s-1.59 1.394-2.033 4.9c0 0-.2 1.885-.23 2.787a.988.988 0 00.837.984.9.9 0 00.951-.82v-.05s.082-4.263 1.656-6.231z"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51127"
          d="M12.024 2.921s-1.508 2.2-1.508 6.64c0 0-.082 1.049.754 1.165a.929.929 0 00.984-.837v-.212s.033-4.395 1.738-6.199c0 0 1.508 1.771 1.623 6a17.911 17.911 0 00.607 4.493 20.7 20.7 0 00.574-4.263.943.943 0 01.344-.754s.262-.115.2-.573a18.072 18.072 0 00-1.59-5.772s2.607-.2 3.1 5.018a2.138 2.138 0 00.41 1.246.943.943 0 01.344.754 16.32 16.32 0 01-.148 3.1 2.7 2.7 0 00.2 1.246 10.21 10.21 0 00.491-3.509s-.082-1.394.607-1.886c0 0-.033-3.968-1.623-6.116A4.395 4.395 0 0015.54.773a4.16 4.16 0 00-3.509 2.148"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51128"
          d="M28.864 8.841s-.951-.2-1.049.607c-.115.787-.115 4.755-1.853 6.788a1.365 1.365 0 01-.64.459 4.034 4.034 0 00-.951 1.017 3.289 3.289 0 01-1.066.9 3.826 3.826 0 003.706-.787s2.23-1.508 2.64-7.772c0 0 .115-1.049-.787-1.213"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51129"
          d="M25.945 11.561a10.4 10.4 0 00.082-1.886.842.842 0 00-.837-.787s-.951-.082-.984.787l-.082 1.213s-.148 3.394-1.508 5.1l-.344-.262a5.959 5.959 0 01-2.329 3.05 3.866 3.866 0 003.394-.837s2.084-1.278 2.608-6.378"
          fill="#5aa5e1"
        />
        <path data-name="Path 51130" d="M16.598 18.793l.019-.016z" fill="#5aa5e1" />
        <path
          data-name="Path 51131"
          d="M20.386 17.561s1.59-1.394 2.033-4.9c0 0 .2-1.886.23-2.787a.988.988 0 00-.837-.984.9.9 0 00-.951.82v.1s-.033 4.263-1.656 6.23l-.377-.229a6.694 6.694 0 01-2.21 2.968 4.147 4.147 0 003.768-1.218"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51132"
          d="M17.6 16.662s1.508-2.2 1.508-6.64c0 0 .082-1.049-.754-1.165a.929.929 0 00-.984.837v.213s-.033 4.411-1.738 6.2c0 0-1.508-1.771-1.623-6a17.911 17.911 0 00-.607-4.493 20.7 20.7 0 00-.574 4.263.943.943 0 01-.344.754s-.262.115-.2.573a18.072 18.072 0 001.59 5.772s-2.607.2-3.1-5.018a2.142 2.142 0 00-.41-1.247.94.94 0 01-.344-.754 16.32 16.32 0 01.148-3.1 3.2 3.2 0 00-.2-1.246 9.458 9.458 0 00-.459 3.509s.082 1.394-.607 1.886c0 0 .082 3.968 1.624 6.116a4.357 4.357 0 003.558 1.689 4.16 4.16 0 003.509-2.148"
          fill="#5aa5e1"
        />
        <path
          data-name="Path 51133"
          d="M34.734 2.511c-.033 0-.115-.033-.148-.033-.016 0-.049-.017-.065-.017a.735.735 0 00-.919.542l-.934 2.852h-.033l-.984-2.9c-.017-.033-.033-.049-.033-.082a.829.829 0 10-1.508.689l1.59 4a1.046 1.046 0 00.574.607c.573.23.984 0 1.213-.607l1.623-3.968a.805.805 0 00-.377-1.082"
        />
        <path
          data-name="Path 51134"
          d="M37.095.248a.926.926 0 00-1.3.033.87.87 0 00-.295.689.77.77 0 00.295.64l.033.033a.926.926 0 001.3-.033l.033-.033a.925.925 0 00-.033-1.3z"
        />
        <path
          data-name="Path 51135"
          d="M36.996 2.626a.782.782 0 00-1.115.016.848.848 0 00-.229.574h-.033v4.23a.85.85 0 00.837.837.819.819 0 00.787-.837V3.217a.819.819 0 00-.23-.574z"
        />
        <path
          data-name="Path 51136"
          d="M41.358 4.053l.148.033.115.033.148.033.082.033h.147a.686.686 0 00.672-.574c.148-.541-.262-1.066-1.476-1.213a5.378 5.378 0 01-.541-.033 2.951 2.951 0 10.443 5.886 1.535 1.535 0 00.61-.116c.755-.23 1.066-.607.984-1.017a.284.284 0 00-.033-.2.685.685 0 00-.9-.344l-.082.033-.2.082a1.3 1.3 0 01-1.885-1.278 1.3 1.3 0 011.771-1.361"
        />
        <path
          data-name="Path 51137"
          d="M45.982 2.462h-.492v-.951a.787.787 0 00-1.574 0v.951l-.311.033a.732.732 0 00-.525.23l-.033.033a.7.7 0 00.033.987.773.773 0 00.525.2h.295v3.5a.819.819 0 00.23.574.834.834 0 001.131 0 .851.851 0 00.229-.574V3.938h.492a.735.735 0 00.525-.23l.05-.049a.7.7 0 00-.05-.967.706.706 0 00-.525-.23"
        />
        <path
          data-name="Path 51138"
          d="M49.902 3.921l.23-.082.082-.033c.033-.033.115-.082.147-.115a.717.717 0 00.115-.984c-.328-.475-1.049-.475-1.656.2-.066.033-.115.115-.148.148V2.97c.049-.312-.3-.607-.787-.574a.831.831 0 00-.837.788l.066 4.263v.033a.76.76 0 00.787.754.852.852 0 00.837-.837v-2.41c0-.262.147-.541.672-.836a3.343 3.343 0 01.492-.23"
        />
        <path
          data-name="Path 51139"
          d="M53.312 2.38a2.791 2.791 0 00-2.378 1.394 3.281 3.281 0 00-.409 1.587v.147a2.965 2.965 0 005.919-.344 3.013 3.013 0 00-3.132-2.787m1.164 3.2a1.25 1.25 0 01-.9 1.246c-.672.148-1.247-.262-1.394-1.246v-.524c.115-.951.721-1.361 1.394-1.213a1.265 1.265 0 01.9 1.213 1.422 1.422 0 010 .525"
        />
        <path
          data-name="Path 51140"
          d="M62.003 4.446v-.017a1.893 1.893 0 00-1.738-2.034 3.187 3.187 0 00-.787 0 1.806 1.806 0 00-1.1.607v-.163a.612.612 0 00-.721-.46.9.9 0 00-.837.837h.05v4.248a.821.821 0 00.738.738.833.833 0 00.886-.738V4.708a.875.875 0 01.262-.672l.033-.033a.926.926 0 011.3.033.916.916 0 01.295.672v2.87a.8.8 0 00.8.7.822.822 0 00.82-.82z"
        />
        <path
          data-name="Path 51141"
          d="M70.956 6.742a.641.641 0 00-.344-.344c-.279-.1-.426-.1-.951.2a1.657 1.657 0 01-2.115-.2 1.53 1.53 0 01-.262-.574l3.116.033c.525 0 .755-.262.672-.9a2.833 2.833 0 00-.23-.9 2.747 2.747 0 00-3.017-1.624 2.8 2.8 0 00-2.23 2.2 3.313 3.313 0 00-.115.9 2.9 2.9 0 003.017 2.755c1.968 0 2.689-.967 2.459-1.542m-3.673-2.088a1.227 1.227 0 01.869-.951 1.1 1.1 0 011.328.984.275.275 0 01.027.082h-2.224z"
        />
        <path
          data-name="Path 51142"
          d="M75.087 2.397a3.187 3.187 0 00-.787 0 1.8 1.8 0 00-1.1.607v-.165a.612.612 0 00-.721-.459.908.908 0 00-.787.836v4.248a.822.822 0 00.738.738.834.834 0 00.886-.738V4.709a.875.875 0 01.262-.672l.033-.033a.927.927 0 011.3.033.916.916 0 01.295.672v2.87a.8.8 0 00.8.7.822.822 0 00.82-.82V4.43a1.892 1.892 0 00-1.738-2.033"
        />
        <path
          data-name="Path 51143"
          d="M83.106 4.958a2.794 2.794 0 00-.23-.9 2.746 2.746 0 00-3.017-1.624 2.8 2.8 0 00-2.23 2.2 3.321 3.321 0 00-.115.9 2.9 2.9 0 003.017 2.754c1.968 0 2.689-.967 2.46-1.541a.644.644 0 00-.344-.344c-.279-.1-.426-.1-.951.2a1.658 1.658 0 01-2.116-.2 1.53 1.53 0 01-.262-.574l3.116.033c.525 0 .755-.262.672-.9m-3.821-.18a.238.238 0 00.037-.124 1.225 1.225 0 01.869-.951c.607-.148 1.131.229 1.329.984a.324.324 0 01.027.082z"
        />
        <path
          data-name="Path 51144"
          d="M87.156 2.708c-.328-.475-1.017-.475-1.656.2-.066.033-.115.115-.148.148V2.97c.05-.312-.295-.607-.787-.574a.83.83 0 00-.836.787l-.033 4.214a.836.836 0 101.672 0v-2.41c0-.262.148-.541.672-.836a3.268 3.268 0 01.492-.23l.229-.082.082-.033a.787.787 0 00.2-.115.717.717 0 00.115-.984"
        />
        <path
          data-name="Path 51145"
          d="M91.96 2.381h-.082a.652.652 0 00-.642.436 2.244 2.244 0 00-3.474.646 3.311 3.311 0 00-.41 1.1 3.192 3.192 0 00.755 2.9 2.074 2.074 0 003.132 0v.23c0 1.049-.8 1.624-2.2 1.131a.546.546 0 01-.23-.082l-.344-.148a.79.79 0 00-.836.2c-.378.426-.262.951.525 1.328a3.127 3.127 0 001.213.344c2.181.23 3.427-.9 3.427-3.017V3.217a.9.9 0 00-.837-.837m-1.082 4c-.05.082-.115.115-.2.2a1.069 1.069 0 01-1.509-.147 1.648 1.648 0 01-.327-1.066c0-.1.033-.18.033-.295.115-.984.9-1.443 1.624-1.1a1.46 1.46 0 01.672 1.394 1.884 1.884 0 01-.295 1.017"
        />
        <path
          data-name="Path 51146"
          d="M97.748 2.397a.8.8 0 00-.967.59l-1.017 2.722-1.017-2.722c0-.033-.033-.082-.033-.115 0-.016-.016-.033-.016-.049a.781.781 0 00-1.033-.361 1.029 1.029 0 00-.574 1.016l1.706 4.264-.755 1.738v.033a.832.832 0 00.46 1.049c.033 0 .082.033.115.033h.016a.827.827 0 001-.607l2.64-6.46a.391.391 0 00.082-.23.8.8 0 00-.607-.9"
        />
        <path
          data-name="Path 51147"
          d="M41.737 11.448a.471.471 0 00.23-.147.329.329 0 00.115-.262.463.463 0 00-.148-.378.766.766 0 00-.459-.147h-.525v2.082h.607a.8.8 0 00.475-.164.691.691 0 00.2-.41.636.636 0 00-.115-.377.569.569 0 00-.377-.2m-.541-.639a.483.483 0 01.262-.033c.229 0 .377.082.377.295s-.148.344-.41.344h-.229zm.638 1.481a.637.637 0 01-.377.115h-.23v-.787h.23a.956.956 0 01.377.066.471.471 0 010 .607"
        />
        <path data-name="Path 51148" d="M45.425 10.546h-.262v2.066h1.2v-.262h-.935z" />
        <path
          data-name="Path 51149"
          d="M50.325 11.907a.514.514 0 01-.115.344.663.663 0 01-.344.115h-.082a.371.371 0 01-.328-.409v-1.394h-.262v1.41a.56.56 0 00.148.459.885.885 0 001.049 0 .7.7 0 00.2-.492v-1.427h-.266z"
        />
        <path data-name="Path 51150" d="M54.148 12.611h1.2v-.262h-.9v-.754h.689v-.229h-.689v-.558h.951v-.263h-1.251z" />
        <path
          data-name="Path 51151"
          d="M62.151 10.493h-.378v2.082h.262v-.782l.23.033c.524 0 .786-.23.786-.721 0-.41-.294-.607-.9-.607m.082 1.066h-.2v-.754h.149v-.033c.377 0 .574.115.574.377s-.2.41-.525.41"
        />
        <path
          data-name="Path 51152"
          d="M66.676 10.546a.8.8 0 00-.607.295 1.4 1.4 0 000 1.508.78.78 0 00.607.3.749.749 0 00.64-.3 1.228 1.228 0 00.229-.787 1.4 1.4 0 00-.229-.754.683.683 0 00-.64-.262m.492 1.607a.552.552 0 01-.459.23.4.4 0 01-.378-.23.984.984 0 01-.147-.607.771.771 0 01.115-.557.436.436 0 01.41-.23c.377 0 .607.262.607.787a1.115 1.115 0 01-.148.607"
        />
        <path
          data-name="Path 51153"
          d="M72.005 11.975l-.426-1.426h-.115l-.443 1.426-.426-1.426h-.3l.64 2.115h.115l.459-1.443.492 1.443h.069l.639-2.115h-.262z"
        />
        <path
          data-name="Path 51154"
          d="M75.547 12.611h1.213v-.262h-.919v-.754h.689v-.229h-.689v-.558h.951v-.263h-1.245z"
        />
        <path
          data-name="Path 51155"
          d="M80.86 11.481a.617.617 0 00.115-.344c0-.377-.262-.607-.754-.607h-.525v2.081h.295v-.9h.3l.573.869h.344l-.588-.921a.6.6 0 00.243-.177m-.705.016h-.2v-.672a.869.869 0 01.2-.049.963.963 0 01.377.082.284.284 0 01.115.262.4.4 0 01-.115.295.743.743 0 01-.377.082"
        />
      </g>
    </svg>
  )
}

export default SvgVictronActive
