import { animationDuration, UserMenuDropdownContent, UserMenuTrigger } from "./UserMenu.styled"
import { useState } from "react"
import * as Dropdown from "@radix-ui/react-dropdown-menu"
import UserDetails from "./UserDetails"
import { theme } from "../../../../../styles/stitches.config"
import ChangePasswordButton from "./ChangePasswordButton"
import LogoutButton from "./LogoutButton"
import AlternateRotatingIcon from "../../../../rotatingIcon/AlternateRotatingIcon"
import { ArrowDown } from "../../../../../images/xd/icons"

interface UserMenuProps {
  email: string
  onLogout?: () => void
}

function UserMenu({ email }: UserMenuProps) {
  const [toggled, setToggled] = useState(false)

  return (
    <Dropdown.Root open={toggled} onOpenChange={() => setToggled((prev) => !prev)}>
      <Dropdown.Trigger asChild>
        <UserMenuTrigger>
          <UserDetails email={email} />
          <AlternateRotatingIcon direction="left" duration={animationDuration} toggled={toggled} icon={ArrowDown} />
        </UserMenuTrigger>
      </Dropdown.Trigger>
      <UserMenuDropdownContent sideOffset={12} align="end">
        <ChangePasswordButton css={{ padding: theme.space.s2_5 }} />
        <LogoutButton css={{ padding: theme.space.s2_5 }} />
      </UserMenuDropdownContent>
    </Dropdown.Root>
  )
}

export default UserMenu
