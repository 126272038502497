// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import d3ToPng from "react-svg-to-image"
import * as Popover from "@radix-ui/react-popover"
import { theme } from "../../../styles/stitches.config"
import SquareButton from "../../button/SquareButton"
import { ExportContent, Item } from "./Export.styled"
import { Spacer } from "../../spacer/Spacer"
import { DateRange } from "../../../utils/types"
import { formatIsoDate } from "../../../utils/date"
import { useTranslation } from "react-i18next"
import { toasts } from "../../../utils/toasts"
import { captureException } from "@sentry/react"

export interface CsvData {
  headers: string[]
  rows: string[][]
}

interface Props {
  svgId: string
  timeRange: DateRange
  csvDataSupplier: () => CsvData
  disabled?: boolean
}

const imageName = "overview"
const size = 2048

export const Export = ({ csvDataSupplier, svgId, timeRange, disabled }: Props) => {
  const { t } = useTranslation(["common", "overview", "payments"])
  const fileName = `${imageName}-${formatIsoDate(timeRange.from)}_${formatIsoDate(timeRange.to)}`

  const downloadPng = () => {
    const idSelector = `#${svgId}`

    const svg = document.querySelector(idSelector)
    const svgSize = svg?.getBoundingClientRect() || { width: 0, height: 0 }

    const scale = size / svgSize.width

    d3ToPng(idSelector, fileName, {
      scale,
      format: "png",
    }).catch((error: Error) => {
      toasts.error(t("common:generic_error"))
      captureException(error)
    })
  }

  const downloadCsv = () => {
    const { headers, rows } = csvDataSupplier()
    const csvData = rows.map((row) => row.join(";")).join("\n")

    const csv = `${headers.join(";")}\n${csvData}`

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: "type: 'text/csv;charset=utf-8'" })
    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = `${fileName}.csv`
    link.click()

    URL.revokeObjectURL(url)
    return
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild disabled={disabled}>
        <SquareButton
          icon="Download"
          style={{
            width: theme.space.s11_5.value,
            height: theme.space.s11_5.value,
            // marginLeft: theme.space.s4.value,
          }}
          disabled={disabled}
        />
      </Popover.Trigger>
      <ExportContent>
        <Item onClick={downloadCsv}>CSV (.csv)</Item>
        <Spacer size={theme.space.s6} />
        <Item onClick={downloadPng}>PNG (.png)</Item>
      </ExportContent>
    </Popover.Root>
  )
}
