import * as React from "react"

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 450">
        <path data-name="Rectangle 331" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 451">
        <path
          data-name="Path 120"
          d="M16.59 9H15V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v5H7.41a1 1 0 00-.71 1.71l4.59 4.59a1 1 0 001.41 0l4.59-4.59a1 1 0 00-.7-1.71zM5 19a1 1 0 001 1h12a1 1 0 000-2H6a1 1 0 00-1 1z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgDownload
