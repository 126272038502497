import { SBackgroundShade, SContent, SHamburger, STrigger } from "./UserSideMenu.styled"
import { Root as DropdownRoot } from "@radix-ui/react-dropdown-menu"
import { useState } from "react"
import UserSideMenuContent from "./UserSideMenuContent"

const UserSideMenu = () => {
  const [toggled, setToggled] = useState(false)

  return (
    <>
      <DropdownRoot open={toggled} onOpenChange={() => setToggled((prev) => !prev)}>
        <STrigger>
          <SHamburger />
        </STrigger>
        <SContent align="start">
          <UserSideMenuContent />
        </SContent>
      </DropdownRoot>

      <SBackgroundShade hidden={toggled ? "false" : "true"} />
    </>
  )
}

export default UserSideMenu
