import { useTranslation } from "react-i18next"
import InfoCard from "../infoCard/InfoCard"
import useTechnicalParamsCellData, { TechnicalParamsData } from "../../hooks/useTechnicalParamsCellData"
import { SContainer } from "./TechnicalParams.styled"
import SubSection from "../../../../components/subSection/SubSection"
import { SubSectionTitle } from "../../../../components/subSection/SubSection.styled"

const TechnicalParams = (props: TechnicalParamsData) => {
  const { t } = useTranslation("delivery_point")
  const data = useTechnicalParamsCellData(props)

  return (
    <SubSection
      width="max"
      title={<SubSectionTitle>{t("technical_params_header")}</SubSectionTitle>}
      content={
        <SContainer>
          <InfoCard data={data} />
        </SContainer>
      }
    />
  )
}

export default TechnicalParams
