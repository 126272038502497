import * as React from "react"

function SvgMissingGraphData(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_MissingGraphData_svg__a">
          <path data-name="Rectangle 16832" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9139" clipPath="url(#_MissingGraphData_svg__a)">
        <path
          data-name="Path 1986"
          d="M17.178 14.99a.573.573 0 01-.313-.748.554.554 0 01.045-.087 2.265 2.265 0 011.926-1.014 2.186 2.186 0 011.946.981 1.957 1.957 0 01.012 1.995c-.488.721-.957.94-1.209 1.4a.941.941 0 00-.122.383.593.593 0 01-.582.529.6.6 0 01-.605-.6.571.571 0 010-.063 1.653 1.653 0 01.187-.627c.313-.566.916-.9 1.266-1.4a.959.959 0 00-.888-1.506 1.118 1.118 0 00-.977.546.556.556 0 01-.688.2m2.475 5.073a.814.814 0 11-.814-.814.814.814 0 01.814.814"
          fill="#ffc200"
        />
        <path
          data-name="Path 1987"
          d="M15.898 17.709H3.582V5.534a.659.659 0 00-1.317 0v12.83a.658.658 0 00.659.659h12.974a.659.659 0 100-1.317"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1988"
          d="M17.623 7.3a.658.658 0 00-.931 0l-4.733 4.733-1.793-1.792a.658.658 0 00-.931 0l-4.348 4.348a.658.658 0 10.931.931l3.883-3.883 1.795 1.795a.659.659 0 00.931 0l5.2-5.2a.658.658 0 000-.931"
          fill="#7ca7ad"
        />
      </g>
    </svg>
  )
}

export default SvgMissingGraphData
