import { CSSProp, keyframes } from "./stitches.config"

export const createRotation = (degrees: number) => {
  return {
    transform: `rotate(${degrees}deg)`,
  }
}

export const createRotationAnimation = (from: number, to: number) => {
  return keyframes({
    from: createRotation(from),
    to: createRotation(to),
  })
}

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

export const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

export const createFade = (from: number, to: number) =>
  keyframes({
    from: {
      opacity: from,
    },
    to: {
      opacity: to,
    },
  })

export const createFadeInAndOut = (
  from: number,
  to: number
): [ReturnType<typeof keyframes>, ReturnType<typeof keyframes>] => {
  return [createFade(from, to), createFade(to, from)]
}

export const defaultAnimationDuration = 300

export const createFadeAnimation = (direction: "in" | "out", duration: number = defaultAnimationDuration) =>
  ({
    animation: `${direction === "in" ? fadeIn : fadeOut} ${duration}ms ease-out forwards`,
  } as CSSProp)

export const defaultRadixContentFadeInOutAnimation: CSSProp = {
  noReducedMotion: {
    '&[data-state="open"]': {
      ...createFadeAnimation("in"),
    },
    '&[data-state="closed"]': {
      ...createFadeAnimation("out"),
    },
  },
}
