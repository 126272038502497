import * as React from "react"

function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 44" d="M0 0h24v24H0z" fill="none" opacity={0.87} />
      <path
        data-name="Path 45"
        d="M12 2a10 10 0 1010 10A9.991 9.991 0 0012 2zm4.3 14.3a1 1 0 01-1.41 0L12 13.41 9.11 16.3a1 1 0 11-1.41-1.41L10.59 12 7.7 9.11A1 1 0 019.11 7.7L12 10.59l2.89-2.89a1 1 0 011.41 1.41L13.41 12l2.89 2.89a1.014 1.014 0 010 1.41z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCross
