import * as React from "react"

function SvgAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 51375" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 51376"
        d="M18 13h-5v5a1 1 0 01-2 0v-5H6a1 1 0 010-2h5V6a1 1 0 012 0v5h5a1 1 0 010 2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAdd
