import { ComponentProps } from "react"
import Document from "./Document"
import { Spacer } from "../../../../components/spacer/Spacer"
import { theme } from "../../../../styles/stitches.config"
import { Flex } from "../../../../components/flex/Flex"
import { useTranslation } from "react-i18next"
import { WorkingOnDocumentsNotification } from "../../../../components/notifications/WorkingOnDocumentsNotification"
import { SModalTitle } from "../../../../components/modal/Modal.styled"

interface Props {
  data: ComponentProps<typeof Document>[]
}

export const DocumentsModalContent = ({ data }: Props) => {
  const { t } = useTranslation("delivery_point")

  return (
    <>
      <SModalTitle>{t("documents_header")}</SModalTitle>
      <Spacer size={theme.space.s6} />

      <WorkingOnDocumentsNotification />

      <Spacer size={theme.space.s6} />

      <Flex direction="column" css={{ gap: theme.space.s2 }}>
        {data.map((document) => (
          <Document key={document.id} variant="card" {...document} />
        ))}
      </Flex>
    </>
  )
}
