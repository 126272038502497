import { useState } from "react"
import OverviewChart from "../../components/chart/OverviewChart"
import { AppLayout } from "../../components/layouts/appLayout/AppLayout"
import { Spacer } from "../../components/spacer/Spacer"
import { useTranslation } from "react-i18next"
import { addYears, endOfDay, endOfHour, endOfMonth, endOfYear, startOfDay, startOfYear, subYears } from "date-fns"
import { trpc } from "../../api/trpc/trpc"
import { TablePlaceholder } from "../../components/placeholder/Placeholder.stories"
import { InferQueryOutput } from "../../api/trpc/trpcHelper"
import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { Text } from "../../components/Text"
import { PeriodOverview } from "../../components/overview/PeriodOverview"
import { theme } from "../../styles/stitches.config"
import { DateRange, assertUnreachable } from "../../utils/types"
import { getMonthRange, getYearRange } from "../../utils/date"
import { useDeliveryPointFullDetail } from "../../hooks/query/useDeliveryPointDetail"
import { fixNegativeZero } from "../../utils/number"
import { round } from "lodash"
import {
  OverviewChartType,
  OverviewState,
  OverviewGroup,
  getDefaultFilterDateRange,
  defaultOverviewGroup,
  defaultConsumptionChartType,
  VoltageType,
  MeasurementType,
  ConsumptionPeriodType,
  EnergyUnit,
  ProductPriceType,
  isContinuousMeasurement,
  DeliveryPointType,
} from "@nano-portal/shared"
import { VatExcludedDisclaimer, VatPricesDisclaimer } from "../../sections/overview/VatPricesDisclaimer"
import { NonContinuousMeasurementDisclaimer } from "../../sections/overview/NonContinuousMeasurementDisclaimer"
import { OverviewType, getCsvExportPeriodFormatter } from "../../sections/overview/overview.utils"
import { formatCurrency, formatCurrencyPerEnergy, formatEnergy } from "../../utils/format"
import OverviewPlaceholder from "../../components/placeholder/OverviewPlaceholder"
import { SDisclaimerWrapper, SOverviewHeader } from "./Overview.styled"

interface Props {
  type: OverviewType
}

type QueryData = InferQueryOutput<"overview.consumption"> | InferQueryOutput<"overview.production">

const MAX_DEFAULT_YEARS_RANGE = 10

/**
 * Temporary copy of BE function to ensure backwards compatibility with cached API data
 */
export const getEndOfPeriodByGroup = (date: Date, group: OverviewGroup): Date => {
  switch (group) {
    case OverviewGroup.Hours:
      return endOfHour(date)
    case OverviewGroup.Days:
      return endOfDay(date)
    case OverviewGroup.Months:
      return endOfMonth(date)
    case OverviewGroup.Years:
      return endOfYear(date)
    default:
      assertUnreachable(group)
  }

  return date
}

const getChartData = (type: OverviewChartType, overviewGroup: OverviewGroup, queryData?: QueryData) => {
  if (!queryData) {
    return []
  }

  const { chartVolume, chartPrice, chartUnitPrice } = queryData
  let chart = chartVolume

  if (type === OverviewChartType.PricePerUnitChart) {
    chart = chartUnitPrice
  } else if (type === OverviewChartType.TotalPaymentChart) {
    chart = chartPrice
  }

  return chart.map((group) => ({
    from: group.from ?? group.date,
    to: group.to ?? getEndOfPeriodByGroup(group.date, overviewGroup),
    value: fixNegativeZero(round(group.value, 2)),
    dataState: OverviewState.Known,
    advanceAmount: group.advanceAmount,
    // falling back to consumption prediction to avoid breaking the chart when cached data is used
    periodType: group.type === "prediction" ? ConsumptionPeriodType.Prediction : group.type,
  }))
}

const getPeriodOverviewData = (group: OverviewGroup, queryData?: QueryData["table"], state?: QueryData["state"]) => {
  if (!queryData) {
    return []
  }

  return queryData.rows.map((data) => ({
    colAdvance: data.advance === null ? null : { amount: data.advance || 0 },
    colCharges: data.otherFees === null ? null : { amount: data.otherFees || 0 },
    colElectricityVolume: state === OverviewState.UnknownVolumes ? null : { amount: data.electricityVolume },
    colPeriod: { from: data.from, to: data.to, justify: "center" as const, group },
    colPowerElectricityEnergy: { amount: data.electricityPrice || 0 },
    colPowerElectricityPrice: { amount: data.pricePerPowerUnit || 0 },
    colTotal: data.total === null ? null : { amount: data.total || 0 },
    colSettlement: data.settlement === null ? null : { amount: data.settlement || 0 },
    // falling back to consumption prediction to avoid breaking the chart when cached data is used
    type: data.type === "prediction" ? ConsumptionPeriodType.Prediction : data.type,
  }))
}

const getPeriodOverviewSummary = (queryData?: QueryData["table"], state?: QueryData["state"]) => {
  if (!queryData) {
    return {
      electricityVolume: 0,
      pricePerPowerUnit: 0,
      electricityPrice: 0,
      otherFees: null,
      advance: null,
      total: null,
      settlement: null,
    }
  }

  return state === OverviewState.UnknownVolumes ? { ...queryData.summary, electricityVolume: null } : queryData.summary
}

const getDefaultOverviewValues = (deliveryPointTypes: DeliveryPointType[], measurementType?: MeasurementType) => {
  if (isContinuousMeasurement(measurementType)) {
    return {
      defaultGroup: OverviewGroup.Days,
      defaultTimeRange: getMonthRange(new Date()),
    }
  }

  const isProduction = deliveryPointTypes.includes(DeliveryPointType.Production)
  if (isProduction) {
    return {
      defaultGroup: OverviewGroup.Days,
      defaultTimeRange: getMonthRange(new Date()),
    }
  }

  return {
    defaultGroup: defaultOverviewGroup,
    defaultTimeRange: getDefaultFilterDateRange(),
  }
}

type PropsWithData = Props & {
  deliveryPointId: number
  defaultTimeRange: DateRange
  defaultGroup: OverviewGroup
  isDataError: boolean
}

const OverviewContent = ({ type, deliveryPointId, defaultGroup, defaultTimeRange, isDataError }: PropsWithData) => {
  const { t } = useTranslation(["overview", "common"])
  const [selectedTimeRange, setSelectedTimeRange] = useState(defaultTimeRange)
  const [group, setGroup] = useState(defaultGroup)
  const [chartType, setChartType] = useState(defaultConsumptionChartType)

  const now = new Date()
  const nextYear = addYears(now, 1)

  const maxDate = group === OverviewGroup.Years ? endOfYear(now) : endOfYear(nextYear)

  const overviewQuery = trpc.useQuery([
    type === "consumption" ? "overview.consumption" : "overview.production",
    { deliveryPointId, since: selectedTimeRange.from, until: selectedTimeRange.to, group },
  ])

  const isLoading = overviewQuery.isLoading
  const isError = overviewQuery.error || isDataError
  const isUnknownState = overviewQuery.data?.state === OverviewState.Unknown
  const isChartUnknownState =
    chartType !== OverviewChartType.PricePerUnitChart && overviewQuery.data?.state === OverviewState.UnknownVolumes
  const isNotContinuousMeasurementType = overviewQuery.data?.measurementTypes?.includes(MeasurementType.C)
  const unknownPriceAndPaymentsFixPricelist =
    overviewQuery.data?.productPriceTypes?.includes(ProductPriceType.Fix) &&
    group === OverviewGroup.Hours &&
    (chartType === OverviewChartType.PricePerUnitChart || chartType === OverviewChartType.TotalPaymentChart)

  const handleGroupChange = (newGroup: OverviewGroup) => {
    switch (newGroup) {
      case OverviewGroup.Hours: {
        const from = startOfDay(now)
        const to = endOfDay(from)
        setSelectedTimeRange({ from, to })
        setGroup(newGroup)
        return
      }
      case OverviewGroup.Days: {
        setSelectedTimeRange(getMonthRange(now))
        setGroup(newGroup)
        return
      }
      case OverviewGroup.Months:
        setSelectedTimeRange(getYearRange(selectedTimeRange.to))
        setGroup(newGroup)
        return
      case OverviewGroup.Years: {
        const to = endOfYear(now)
        const from = startOfYear(subYears(to, MAX_DEFAULT_YEARS_RANGE))
        setSelectedTimeRange({ from, to })
        setGroup(newGroup)
        return
      }
      default:
        assertUnreachable(newGroup)
    }
  }

  const csvDataSupplier = () => {
    const dateFormatter = getCsvExportPeriodFormatter(group)

    const headers = [
      t("overview:export_csv_period_start"),
      t("overview:export_csv_period_end"),
      t(`overview:volume_${type}`),
      t("overview:chart_type_price_per_unit"),
      t("overview:export_csv_electrical_power_total"),
    ]

    if (type === "consumption") {
      headers.push(t("overview:export_csv_distribution_and_other_fees"), t("overview:total_payment"))
    }

    const rows = overviewQuery.data?.table.rows.map((row) => {
      const result = [
        dateFormatter(row.from),
        // TODO - vymazat fallback na konec obdobi, az bude typ povinny
        dateFormatter(row.to ?? getEndOfPeriodByGroup(row.from, group)),
        formatEnergy(row.electricityVolume, EnergyUnit.kWh, 2),
        formatCurrencyPerEnergy(row.pricePerPowerUnit, 2, 2),
        formatCurrency(row.electricityPrice, 2, 2),
      ]

      if (type === "consumption") {
        result.push(formatCurrency(row.otherFees ?? 0, 2, 2), formatCurrency(row.total ?? 0, 2, 2))
      }
      return result
    })

    return { headers, rows: rows ?? [] }
  }

  return (
    <>
      <Spacer size={theme.space.s8} />

      <SOverviewHeader>
        <Text type="headerH1">
          {type === "production" ? t("overview:production_chart_header") : t("overview:consumption_chart_header")}
        </Text>

        <Text type={"textsLarge"} css={{ fontSize: "14px" }}>
          {type === "production"
            ? t("overview:ote_messages_volume_inconsistency_disclaimer_production")
            : t("overview:ote_messages_volume_inconsistency_disclaimer_consumption")}
        </Text>
      </SOverviewHeader>

      <Spacer size={theme.space.s6} />

      <OverviewChart
        data={getChartData(chartType, group, overviewQuery.data)}
        chartType={chartType}
        setChartType={setChartType}
        group={group}
        setGroup={handleGroupChange}
        loading={isLoading}
        timeRange={selectedTimeRange}
        setTimeRange={setSelectedTimeRange}
        maxDate={maxDate}
        isUnknownState={isUnknownState || isChartUnknownState}
        isHiddenFixedPricelist={unknownPriceAndPaymentsFixPricelist}
        errorState={!!isError}
        type={type}
        csvDataSupplier={csvDataSupplier}
      />

      {isNotContinuousMeasurementType && group === OverviewGroup.Hours && (
        <>
          <NonContinuousMeasurementDisclaimer />
          <Spacer size={theme.space.s4} />
        </>
      )}

      {!isLoading && chartType === OverviewChartType.PricePerUnitChart && (
        <>
          <SDisclaimerWrapper>
            <Text type={"textsLarge"} css={{ fontSize: "14px" }}>
              {type === "production"
                ? t("overview:price_per_unit_disclaimer_production")
                : t("overview:price_per_unit_disclaimer_consumption")}
            </Text>
          </SDisclaimerWrapper>

          <Spacer size={theme.space.s6} />
        </>
      )}

      {isLoading ? (
        <TablePlaceholder />
      ) : (
        <>
          <PeriodOverview
            data={getPeriodOverviewData(group, overviewQuery.data?.table, overviewQuery.data?.state)}
            summary={getPeriodOverviewSummary(overviewQuery.data?.table, overviewQuery.data?.state)}
            group={group}
            errorState={!!isError}
            isUnknownState={isUnknownState}
            isHiddenFixedPricelist={unknownPriceAndPaymentsFixPricelist}
            type={type}
          />

          <Spacer size={theme.space.s6} />
          {type === "consumption" ? <VatPricesDisclaimer /> : <VatExcludedDisclaimer />}

          <Spacer size={theme.space.s28} />
        </>
      )}
    </>
  )
}

const Overview = (props: Props) => {
  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const deliveryPointQuery = useDeliveryPointFullDetail(deliveryPointId)

  const isLoading = deliveryPointQuery.isLoading
  const isError = deliveryPointQuery.error || deliveryPointQuery.data?.voltageType === VoltageType.High

  const { defaultGroup, defaultTimeRange } = getDefaultOverviewValues(
    deliveryPointQuery.data?.type ?? [],
    deliveryPointQuery.data?.measurementType
  )

  if (isLoading) {
    return (
      <AppLayout hideLeaves>
        <OverviewPlaceholder />
      </AppLayout>
    )
  }

  return (
    <AppLayout hideLeaves>
      <OverviewContent
        {...props}
        deliveryPointId={deliveryPointId}
        defaultGroup={defaultGroup}
        defaultTimeRange={defaultTimeRange}
        isDataError={!!isError}
      />
    </AppLayout>
  )
}

export default Overview
