import * as React from "react"

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Rectangle 456" fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="Path 334"
        d="M19.5 12c0-.23-.01-.45-.03-.68l1.86-1.41a1.006 1.006 0 00.26-1.3l-1.87-3.23a.987.987 0 00-1.25-.42l-2.15.91a7.587 7.587 0 00-1.17-.68l-.29-2.31a1 1 0 00-.99-.88h-3.73a1 1 0 00-1 .88l-.29 2.31a7.587 7.587 0 00-1.17.68l-2.15-.91a.987.987 0 00-1.25.42L2.41 8.62a1.008 1.008 0 00.26 1.3l1.86 1.41a7.343 7.343 0 000 1.35l-1.86 1.41a1.006 1.006 0 00-.26 1.3l1.87 3.23a.987.987 0 001.25.42l2.15-.91a7.587 7.587 0 001.17.68l.29 2.31a1 1 0 00.99.88h3.73a1 1 0 00.99-.88l.29-2.31a7.587 7.587 0 001.17-.68l2.15.91a.987.987 0 001.25-.42l1.87-3.23a1.008 1.008 0 00-.26-1.3l-1.86-1.41a5.17 5.17 0 00.04-.68zm-7.46 3.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSettings
