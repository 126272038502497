import React from "react"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"
import { styled, theme } from "../../styles/stitches.config"

const SCROLLBAR_SIZE = 6

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  overflow: "hidden",
})

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
})

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 2,
  transition: "background 160ms ease-out",
  // "&:hover": { background: theme.colors.secondary },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: SCROLLBAR_SIZE,
  },
})

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: theme.colors.secondary,
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
})

export default function ScrollArea({
  children,
  ...otherProps
}: React.PropsWithChildren<React.ComponentProps<typeof StyledScrollArea>>) {
  return (
    <StyledScrollArea {...otherProps}>
      <StyledViewport css={{ backgroundColor: theme.colors.white }}>{children}</StyledViewport>
      <StyledScrollbar orientation="vertical">
        <StyledThumb />
      </StyledScrollbar>
    </StyledScrollArea>
  )
}
