import { Maybe } from "../utils/types"

type LinkId = Maybe<string | number>

export const LINKS = {
  home: "/",

  authLogin: "/auth/login",
  authForgotPassword: "/auth/forgot-password",
  authForgotPasswordSuccess: "/auth/forgot-password/success",
  authResetPassword: "/auth/reset-password",
  authCreateAccount: "/auth/create-account",

  pdt: (id: LinkId) => `/pdt/${id}`,
  pdtConsumption: (id: LinkId) => `/pdt/${id}/consumption`,
  pdtProduction: (id: LinkId) => `/pdt/${id}/production`,
  pdtDetail: (id: LinkId) => `/pdt/${id}/detail`,
  pdtPayments: (id: LinkId) => `/pdt/${id}/payments`,
  pdtPaymentsInvoiceSpotData: (ean: LinkId, invoiceId: LinkId) => `/prehled-spot/${invoiceId}/${ean}`,
  pdtInvoiceDetail: (id: LinkId, invoiceId: LinkId) => `/pdt/${id}/payments/invoice-detail/${invoiceId}`,

  pdtFlexibility: (id: LinkId) => `/pdt/${id}/flexibility`,

  /*
  TODO profile is hidden
  profile: "/profile",
  */

  microSite: "/micro-site/*",

  dailyPrices: "spot",

  meterReading: (ean: string, signature: string) => `/meter-reading/${ean}/${signature}`,
  flexi2022: (hash: string) => `/flexi-2022/${hash}`,
  flexi1222: (hash: string) => `/flexi-12-2022/${hash}`,
  flexiGreen24: (hash: string) => `/flexi-green-24/${hash}`,
  flexiItSelfBilling: (hash: string) => `/enable-self-billing/${hash}`,
}

export const WEB_LINKS = {
  home: "https://www.deltagreen.cz/",
  priceLists: "https://www.deltagreen.cz/ceniky",
  buyoutLink:
    "https://www.deltagreen.cz/vyrabim-a-spotrebovavam?utm_source=dailyprices&utm_medium=button&utm_campaign=dailyprices&utm_content=vykup",
  consumptionLink:
    "https://www.deltagreen.cz/vyrabim-a-spotrebovavam?utm_source=dailyprices&utm_medium=button&utm_campaign=dailyprices&utm_content=odber",
  termsAndConditions: "https://www.deltagreen.cz/obchodni-podminky",
  contact: "https://www.deltagreen.cz/kontakt",
  selfMeasurement: "https://www.deltagreen.cz/jak-na-samoodecet",

  blogProductionInvoicingGuide: "https://www.deltagreen.cz/blog/jak-funguje-fakturace-za-vykup-elektriny",
  blogEnergyVat: "https://www.deltagreen.cz/blog/jak-je-to-s-dph-u-energii",
  dailyPrices: "https://moje.deltagreen.cz/spot",
  eshopDeltaLink: "https://www.deltagreen.cz/chytra-domacnost-od-delta-green",
}
