import { useTranslation } from "react-i18next"
import { Link, useMatch } from "react-router-dom"
import { LINKS } from "../../../../constants/links"
import { Lightbulb, OpenInNew } from "../../../../images/xd/icons"
import { theme } from "../../../../styles/stitches.config"
import { Flex } from "../../../flex/Flex"
import MenuItem from "./MenuItem"
import { useSafeNumberParam } from "../../../../hooks/useSafeNumberParam"
import { resolveQueriesState } from "../../../../utils/queries"
import Loader from "../../../loader/Loader"
import { Div } from "../../../div/Div"
import { useDeliveryPointDetail } from "../../../../hooks/query/useDeliveryPointDetail"
import { DeliveryPointType, VoltageType } from "@nano-portal/shared"
import { useNotifyAboutDataTariff } from "../../../../hooks/query/useNotifyAboutDataTariff"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { PropsWithChildren, ReactNode } from "react"
import { trpc } from "../../../../api/trpc/trpc"

function ItemLink(props: PropsWithChildren<{ external?: boolean; href: string }>) {
  if (props.external) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    )
  }

  return <Link to={props.href}>{props.children}</Link>
}

function SideMenuItemWrapper(props: { asDropdownItem?: boolean; children?: ReactNode }) {
  const { asDropdownItem, children } = props
  return asDropdownItem ? <DropdownMenu.Item asChild>{children}</DropdownMenu.Item> : <>{children}</>
}

export default function SideMenuContent({ asDropdownContent }: { asDropdownContent?: boolean }) {
  const { t } = useTranslation("menu")
  const deliveryPointId = useSafeNumberParam("deliveryPointId")

  const deliveryPointsQuery = useDeliveryPointDetail(deliveryPointId)
  const notificationQuery = useNotifyAboutDataTariff(deliveryPointId)

  const isActive = deliveryPointsQuery.data?.isActive
  const isProduction = deliveryPointsQuery.data?.type.includes(DeliveryPointType.Production)

  const displayFlexibility = isProduction && isActive
  const displayProteusExternalLink = displayFlexibility && notificationQuery.status === "LOGGED_IN"

  const proteusLoginUrlQuery = trpc.useQuery(["flexibility.createProtheusLoginLink", { deliveryPointId }], {
    enabled: displayProteusExternalLink ?? false,
  })

  const { isLoading, isError } = resolveQueriesState([deliveryPointsQuery, notificationQuery, proteusLoginUrlQuery])

  const consumptionLink = LINKS.pdtConsumption(deliveryPointId)
  const productionLink = LINKS.pdtProduction(deliveryPointId)
  const detailLink = LINKS.pdtDetail(deliveryPointId)
  const paymentsLink = LINKS.pdtPayments(deliveryPointId)
  const flexibilityLink = LINKS.pdtFlexibility(deliveryPointId)

  const matchesConsumption = useMatch(consumptionLink)
  const matchesProduction = useMatch(productionLink)
  const matchesDetail = useMatch(detailLink)
  const matchesPayments = useMatch(`${paymentsLink}/*`)
  const matchesFlexibility = useMatch(flexibilityLink)

  const highlightFlexibility = notificationQuery.notifyAboutTariff

  function renderFlexibilityRightContent() {
    if (highlightFlexibility) {
      return (
        <Flex
          align="center"
          justify="center"
          css={{ backgroundColor: theme.colors.primary, width: 12, height: 12, borderRadius: "50%" }}
        >
          <Lightbulb width={10} height={10} color={theme.colors.white.value} />
        </Flex>
      )
    }

    if (displayProteusExternalLink) {
      return <OpenInNew width={16} height={16} />
    }

    return null
  }

  function renderContent() {
    // TODO: skeleton loader? + extra error state
    if (
      isLoading ||
      isError ||
      !deliveryPointsQuery.data ||
      (displayProteusExternalLink && !proteusLoginUrlQuery.data)
    ) {
      return (
        <Div css={{ minWidth: theme.sizes.s38, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </Div>
      )
    }

    const isConsumption = deliveryPointsQuery.data.type.includes(DeliveryPointType.Consumption)

    return (
      <>
        {deliveryPointsQuery.data.voltageType !== VoltageType.High && isConsumption && (
          <SideMenuItemWrapper asDropdownItem={asDropdownContent}>
            <Link to={consumptionLink}>
              <MenuItem title={t("side_menu_home")} active={!!matchesConsumption} icon="Home" />
            </Link>
          </SideMenuItemWrapper>
        )}
        {isProduction && (
          <SideMenuItemWrapper asDropdownItem={asDropdownContent}>
            <Link to={productionLink}>
              <MenuItem title={t("side_menu_production")} active={!!matchesProduction} icon="Sun" />
            </Link>
          </SideMenuItemWrapper>
        )}

        <SideMenuItemWrapper asDropdownItem={asDropdownContent}>
          <Link to={detailLink}>
            <MenuItem title={t("side_menu_supply_points")} active={!!matchesDetail} icon="SupplyPoints" />
          </Link>
        </SideMenuItemWrapper>

        <SideMenuItemWrapper asDropdownItem={asDropdownContent}>
          <Link to={paymentsLink}>
            <MenuItem title={t("side_menu_payments")} active={!!matchesPayments} icon="Payments" />
          </Link>
        </SideMenuItemWrapper>

        {displayFlexibility && (
          <SideMenuItemWrapper asDropdownItem={asDropdownContent}>
            <ItemLink
              external={displayProteusExternalLink}
              href={displayProteusExternalLink ? proteusLoginUrlQuery.data ?? "" : flexibilityLink}
            >
              <MenuItem
                title={t("side_menu_nano_grid")}
                active={!!matchesFlexibility}
                icon="Lightbulb"
                right={renderFlexibilityRightContent()}
              />
            </ItemLink>
          </SideMenuItemWrapper>
        )}
      </>
    )
  }

  return (
    <Flex direction="column" css={{ gap: theme.space.s6 }}>
      {renderContent()}
    </Flex>
  )
}
