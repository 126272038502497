import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
  isSameYear,
  startOfYear,
  endOfYear,
  addWeeks,
  addMonths,
  addYears,
  addDays,
  startOfMonth,
  endOfMonth,
  isSameDay,
  format,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  subMonths,
  subYears,
} from "date-fns"
import { cs, sk, enUS } from "date-fns/locale"
import { getLocale } from "../i18n/config"
import { DateRange } from "./types"
import { range } from "lodash"

export const getDateFnsLocale = () => {
  const locale = getLocale()

  if (locale === "cs") {
    return cs
  } else if (locale === "sk") {
    return sk
  }

  return enUS
}

export const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString(getLocale())
}

export const formatIsoDate = (date: Date | string) => {
  return format(new Date(date), "yyyy-MM-dd")
}

export const formatIsoDateTime = (date: Date | string) => {
  return format(new Date(date), "yyyy-MM-dd HH:mm:ss")
}

export const formatMonth = (date: Date | string, formatPattern = "MM") => {
  return format(new Date(date), formatPattern, { locale: getDateFnsLocale() })
}

export const formatDay = (date: Date | string, formatPattern: "d" | "dd" = "d") => {
  return format(new Date(date), formatPattern, { locale: getDateFnsLocale() })
}

export const formatHour = (date: Date | string, formatPattern: "H:mm" | "HH:mm" = "H:mm") => {
  return format(new Date(date), formatPattern, { locale: getDateFnsLocale() })
}

export const formatYear = (date: Date | string, formatPattern: "yy" | "yyyy" = "yy") => {
  return format(new Date(date), formatPattern, { locale: getDateFnsLocale() })
}

export const formatMonthSlashYear = (date: Date, stringFormat: "M/yyyy" | "M/yy" | "yyyy-MM" = "M/yyyy") => {
  return format(date, stringFormat, { locale: getDateFnsLocale() })
}

export const getTodayHour = (hour: number) => {
  const date = new Date()
  date.setHours(hour, 0)
  return date
}

export const getDateWithMonth = (month: number) => {
  const date = new Date()
  date.setMonth(month)
  return date
}

export const getLocalizedMonthsSequence = () => {
  const numberOfMonths = 12
  const firstMonth = 0
  const locale = getDateFnsLocale()

  const months: string[] = []
  for (const monthIndex of range(firstMonth, numberOfMonths)) {
    months.push(locale.localize?.month(monthIndex))
  }

  return months
}

export const getLocalizedMonth = (date: Date, monthFormat: "long" | "short" = "long", firstLetterUpperCase = true) => {
  const month = format(date, monthFormat === "short" ? "LLL" : "LLLL", { locale: getDateFnsLocale() })
  return firstLetterUpperCase ? month.charAt(0).toUpperCase() + month.slice(1) : month
}

export const startOfWeekLocalized = (date: Date) => {
  return startOfWeek(date, { locale: getDateFnsLocale() })
}

export const endOfWeekLocalized = (date: Date) => {
  return endOfWeek(date, { locale: getDateFnsLocale() })
}

export const isWeekRange = ({ from, to }: DateRange) => {
  if (!isSameWeek(from, to, { locale: getDateFnsLocale() })) {
    return false
  }

  return isSameDay(from, startOfWeekLocalized(from)) && isSameDay(to, endOfWeekLocalized(to))
}

export const isMonthRange = ({ from, to }: DateRange) => {
  if (!isSameMonth(from, to)) {
    return false
  }

  return isFirstDayOfMonth(from) && isLastDayOfMonth(to)
}

export const isYearRange = ({ from, to }: DateRange) => {
  if (!isSameYear(from, to)) {
    return false
  }

  return isSameDay(from, startOfYear(from)) && isSameDay(to, endOfYear(to))
}

interface TrimOptions {
  minDate?: Date
  maxDate?: Date
}

export enum DateIntervals {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

export const shiftInterval = (
  range: DateRange,
  step: number,
  defaultShiftPrecision: DateIntervals = DateIntervals.Days
) => {
  const getDayRangeShifted = () => {
    const diffDays = differenceInCalendarDays(range.to, range.from) + 1 // difference + 1 to have step = 1 if the dates are in the same day
    return {
      from: addDays(range.from, step * diffDays),
      to: addDays(range.to, step * diffDays),
    }
  }

  const getWeekRangeShifted = () => {
    const diffWeeks = differenceInCalendarWeeks(range.to, range.from) + 1 // difference + 1 to have step = 1 if the dates are in the same week
    return {
      from: startOfWeekLocalized(addWeeks(range.from, step * diffWeeks)),
      to: endOfWeekLocalized(addWeeks(range.to, step * diffWeeks)),
    }
  }

  const getMonthRangeShifted = () => {
    const diffMonths = differenceInCalendarMonths(range.to, range.from) + 1 // difference + 1 to have step = 1 if the dates are in the same month
    return {
      from: startOfMonth(addMonths(range.from, step * diffMonths)),
      to: endOfMonth(addMonths(range.to, step * diffMonths)),
    }
  }

  const getYearRangeShifted = () => {
    const diffYear = differenceInCalendarYears(range.to, range.from) + 1 // difference + 1 to have step = 1 if the dates are in the same year
    return {
      from: startOfYear(addYears(range.from, step * diffYear)),
      to: endOfYear(addYears(range.to, step * diffYear)),
    }
  }

  if (isWeekRange(range)) return getWeekRangeShifted()
  else if (isMonthRange(range)) return getMonthRangeShifted()
  else if (isYearRange(range)) return getYearRangeShifted()

  switch (defaultShiftPrecision) {
    case DateIntervals.Weeks:
      return getWeekRangeShifted()
    case DateIntervals.Months:
      return getMonthRangeShifted()
    case DateIntervals.Years:
      return getYearRangeShifted()
    default:
      return getDayRangeShifted()
  }
}

export const getMonthRange = (date: Date) => {
  return {
    from: startOfMonth(date),
    to: endOfMonth(date),
  }
}

export const getThisMonth = () => {
  return getMonthRange(new Date())
}

export const getLastMonth = () => {
  const now = new Date()
  return getMonthRange(subMonths(now, 1))
}

export const getYearRange = (date: Date) => {
  return {
    from: startOfYear(date),
    to: endOfYear(date),
  }
}

export const getThisYear = () => {
  return getYearRange(new Date())
}

export const getLastYear = () => {
  const now = new Date()
  return getYearRange(subYears(now, 1))
}

export const isSameDateRange = (range1: DateRange, range2: DateRange) => {
  return isSameDay(range1.from, range2.from) && isSameDay(range1.to, range2.to)
}

export const isFullMonthRange = (range: DateRange) => {
  if (range.from.getMonth() !== range.to.getMonth()) {
    return false
  }

  const fullMonth = getMonthRange(range.from)
  return isSameDateRange(range, fullMonth)
}

export const isFullYearRange = (range: DateRange) => {
  if (range.from.getFullYear() !== range.to.getFullYear()) {
    return false
  }

  const fullYear = getYearRange(range.from)
  return isSameDateRange(range, fullYear)
}

export const isThisMonthRange = (range: DateRange) => isSameDateRange(range, getThisMonth())

export const isLastMonthRange = (range: DateRange) => isSameDateRange(range, getLastMonth())

export const isThisYearRange = (range: DateRange) => isSameDateRange(range, getThisYear())

export const isLastYearRange = (range: DateRange) => isSameDateRange(range, getLastYear())

export const trimRange = (range: DateRange, options: TrimOptions) => {
  const { minDate, maxDate } = options
  let { from, to } = range
  if (minDate && from < minDate) {
    from = minDate
  }
  if (maxDate && to > maxDate) {
    to = maxDate
  }

  return { from, to }
}

export const getMonthName = (date: Date) => {
  const locale = getDateFnsLocale()
  if (!locale.localize) {
    return ""
  }

  const monthName = locale.localize.month(date.getMonth()) as string
  return monthName.charAt(0).toUpperCase() + monthName.slice(1)
}

export const getDayArrayOfMonth = (month: number, year: number) => {
  const lastDay = endOfMonth(new Date(year, month, 1)).getDate()
  return Array.from({ length: lastDay }, (_, i) => new Date(year, month, i + 1))
}

export const getMonthArrayOfYear = (year: number) => {
  return Array.from({ length: 12 }, (_, i) => new Date(year, i, 1))
}

export const updateYear = (value: number, date: Date) => {
  return new Date(value, date.getMonth(), 1)
}

export const updateMonth = (value: number, date: Date) => {
  return new Date(date.getFullYear(), value, 1)
}
