import { Button } from "../../components/button/Button"
import { fontVariants, styled, theme } from "../../styles/stitches.config"

export const SCard = styled("div", {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s12,
  maxWidth: theme.sizes.s122,

  paddingLeft: theme.space.s12,
  paddingBottom: theme.space.s9,

  variants: {
    borderColor: {
      warning: {
        borderColor: theme.colors.warning,
        borderWidth: theme.sizes.spx,
        borderStyle: "solid",
      },
    },

    withSteps: {
      true: {
        paddingTop: theme.space.s4_5,
        paddingRight: theme.space.s6,
      },
      false: {
        paddingTop: theme.space.s12,
        paddingRight: theme.space.s12,
      },
    },
  },

  defaultVariants: {
    withSteps: false,
  },
})

export const SStepWrapper = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
})

export const SCardContentWrapper = styled("div", {
  paddingRight: theme.space.s6,
})

export const SPlaceholderWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  width: theme.sizes.s122,
  height: theme.sizes.s122,
})

export const SContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",

  alignItems: "center",

  paddingInline: theme.space.s2_5,

  "@lg": {
    alignItems: "flex-start",
    paddingInline: 0,
  },
})

export const SButton = styled(Button, {
  backgroundColor: `${theme.colors.white}!important`,
  width: "100%",
})

export const SComboBoxInput = styled("input", {
  color: `${theme.colors.textsAlt}!important`,
})

export const SComboBoxListWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "absolute",
  zIndex: 1,
})

export const SComboBoxListItem = styled("div", {
  display: "flex",
  borderStyle: "solid",
  borderWidth: theme.sizes.spx,
  borderColor: theme.colors.transparent,

  "&:hover": {
    cursor: "pointer",
    borderColor: theme.colors.secondary,
  },

  variants: {
    focused: {
      true: {
        cursor: "pointer",
        borderColor: theme.colors.secondary,
      },
      false: {},
    },
  },

  defaultVariants: {
    focused: false,
  },
})

export const SIconWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  height: "100%",

  "&:hover": {
    cursor: "pointer",
  },
})

export const ThankYouLabel = styled("p", {
  ...fontVariants.headerH3,
  border: `2px solid ${theme.colors.secondary}`,
  paddingBlock: theme.space.s2_5,
  paddingInline: theme.space.s1,
  textAlign: "center",
})
