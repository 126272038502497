import { useState } from "react"

export function useTouchTooltip() {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }

  return {
    open,
    handleToggle,
  }
}
