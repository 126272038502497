import * as React from "react"

function SvgBankEncashment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24.001" {...props}>
      <defs>
        <clipPath id="_BankEncashment_svg__a">
          <path data-name="Rectangle 419" fill="currentColor" d="M0 0h24v24.001H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 814" clipPath="url(#_BankEncashment_svg__a)" fill="currentColor">
        <path data-name="Path 315" d="M9.428 10.405v3.478a1.304 1.304 0 102.608 0v-3.478a1.304 1.304 0 10-2.608 0" />
        <path
          data-name="Path 316"
          d="M3.56 7.362h14.355a1.083 1.083 0 00.5-2.043l-6.874-3.617a1.721 1.721 0 00-1.617 0L3.056 5.319a1.083 1.083 0 00.5 2.043"
        />
        <path data-name="Path 317" d="M14.645 10.405v1.087a1.304 1.304 0 002.608 0v-1.087a1.304 1.304 0 00-2.608 0" />
        <path data-name="Path 318" d="M4.212 10.405v3.478a1.304 1.304 0 102.608 0v-3.478a1.304 1.304 0 10-2.608 0" />
        <path data-name="Path 319" d="M11.386 16.926H3.777a1.304 1.304 0 000 2.608h7.609a1.304 1.304 0 000-2.608" />
        <path
          data-name="Path 320"
          d="M18.502 19.305a.531.531 0 00-.905.378v.383a2.137 2.137 0 01-1.81-2.108 2.083 2.083 0 01.156-.8.719.719 0 00-.164-.782.739.739 0 00-1.203.25 3.544 3.544 0 00-.257 1.332 3.609 3.609 0 003.278 3.589v.422a.53.53 0 00.331.493.539.539 0 00.2.039.525.525 0 00.374-.157l1.142-1.143a.528.528 0 000-.753z"
        />
        <path
          data-name="Path 321"
          d="M18.249 14.368v-.422a.528.528 0 00-.9-.374l-1.152 1.142a.53.53 0 000 .753l1.148 1.148a.536.536 0 00.581.107.531.531 0 00.323-.49v-.382a2.135 2.135 0 011.807 2.108 2.067 2.067 0 01-.155.8.717.717 0 00.164.784.738.738 0 001.205-.247 3.541 3.541 0 00.257-1.332 3.61 3.61 0 00-3.278-3.59"
        />
      </g>
    </svg>
  )
}

export default SvgBankEncashment
