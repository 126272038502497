import * as React from "react"

function SvgElectricity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_Electricity_svg__a">
          <path data-name="Rectangle 38" fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 37" clipPath="url(#_Electricity_svg__a)">
        <path
          data-name="Path 41"
          d="M10.574 21.614a.616.616 0 01-.609-.705l.961-6.772H7.187c-.94 0-.352-.8-.331-.834 1.346-2.382 3.365-5.907 6.035-10.607a.615.615 0 011.143.4l-.96 6.771h3.75c.428 0 .662.2.428.705q-5.272 9.2-6.143 10.736a.605.605 0 01-.534.31"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgElectricity
