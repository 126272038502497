import { styled, theme } from "../../../../styles/stitches.config"
import { Download } from "../../../../images/xd/icons"
import { contractInfoCardHeight } from "../contractInfo/ContractInfo.styled"

export const DocumentCard = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "nowrap",
  justifyContent: "space-between",

  hover: {
    cursor: "pointer",
  },

  variants: {
    variant: {
      text: {
        padding: 0,

        hover: {
          textDecoration: "underline",
          textDecorationColor: theme.colors.textsAlt,
        },
      },
      card: {
        padding: `${theme.space.s3} ${theme.space.s6}`,
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows.default,
      },
    },
  },

  defaultVariants: {
    variant: "text",
  },
})

export const SIcon = styled(Download, {
  flexShrink: 0,
  width: theme.space.s6,
  color: theme.colors.secondary,
})

export const SContent = styled("div", {
  display: "grid",
  gap: theme.space.s2,
  gridTemplateColumns: `repeat(auto-fill, minmax(${theme.space.s80}, 1fr))`,
})

export const SShowMoreWrapper = styled("div", {
  display: "flex",
  justifyContent: "end",
})

export const SShowMoreButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  marginRight: theme.space.s2,
})

export const SDocumentList = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: theme.space.s2_5,

  backgroundColor: theme.colors.white,
  paddingInline: theme.space.s6,
  paddingBlock: theme.space.s2_5,
  boxShadow: theme.shadows.default,
  width: "100%",

  maxHeight: contractInfoCardHeight,
})
