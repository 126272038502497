import * as React from "react"

function SvgTermsAndConditions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_TermsAndConditions_svg__a">
          <path data-name="Rectangle 16804" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9068">
        <g data-name="Group 9067" clipPath="url(#_TermsAndConditions_svg__a)">
          <g data-name="Group 9066" fill="#7ca7ad">
            <path
              data-name="Path 1971"
              d="M18.686 4.701H16.86V3.666a.652.652 0 00-.653-.653h-1.333V2.58a.653.653 0 00-.653-.653H9.556a.653.653 0 00-.653.653v.433H7.57a.652.652 0 00-.652.653v1.038H5.092a.653.653 0 00-.653.653v16.286a.653.653 0 00.653.653h13.592a.653.653 0 00.653-.653V5.353a.653.653 0 00-.651-.652zM8.225 4.315h1.333a.652.652 0 00.652-.649v-.433h3.36v.433a.653.653 0 00.652.653h1.333v1.472h-7.33zm9.808 16.676H5.747V6.006H6.92v.433a.653.653 0 00.652.653h8.638a.653.653 0 00.653-.653v-.433h1.173z"
            />
            <path data-name="Path 1972" d="M8.334 10.735h3.684a.653.653 0 000-1.305H8.334a.653.653 0 000 1.305z" />
            <path data-name="Path 1973" d="M15.446 11.707H8.333a.653.653 0 000 1.305h7.113a.653.653 0 100-1.305z" />
            <path data-name="Path 1974" d="M15.446 13.984H8.333a.653.653 0 000 1.305h7.113a.653.653 0 100-1.305z" />
            <path data-name="Path 1975" d="M15.446 16.261H8.333a.653.653 0 000 1.305h7.113a.653.653 0 100-1.305z" />
          </g>
          <path
            data-name="Path 1976"
            d="M21.251 17.665a.77.77 0 00-1.074 0l-3.774 3.773-1.166-1.166a.76.76 0 00-.539-.23.774.774 0 00-.543.219.765.765 0 00-.013 1.08l1.717 1.717a.771.771 0 001.086 0l4.317-4.313a.765.765 0 00-.011-1.082z"
            fill="#ffc200"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgTermsAndConditions
