import { Fragment, useRef } from "react"
import ContentLoader from "react-content-loader"
import { useWindowSize } from "react-use"
import useMeasureDirty from "react-use/lib/useMeasureDirty"

const bars = 12
const barHeights = [0.7, 0.8, 0.6, 0.4, 0.5, 0.6, 0.9, 0.75, 0.6, 1, 0.9, 0.85]

const ChartPlaceholder = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useMeasureDirty(ref)

  const { height: windowHeight } = useWindowSize()

  const size = width / (2 * bars - 1)

  const height = windowHeight * 0.4

  return (
    <div ref={ref} style={{ width: "100%", padding: 48 }}>
      <ContentLoader viewBox={`0 0 ${width} ${height}`}>
        {Array.from({ length: bars })
          .fill(" ")
          .map((_, index) => {
            const barHeight = (barHeights[index] || 0) * height

            return (
              <Fragment key={index}>
                <rect x={index * 2 * size} y={height - barHeight} width={size} height={barHeight} />
              </Fragment>
            )
          })}
      </ContentLoader>
    </div>
  )
}

export default ChartPlaceholder
