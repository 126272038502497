import { styled, theme } from "../../../../styles/stitches.config"
import { NanoEnergiesLogo } from "../../../../images/xd/icons"
import { Link } from "react-router-dom"

export const SLogo = styled(NanoEnergiesLogo, {
  width: theme.sizes.s16,
  height: theme.sizes.s6,
  marginLeft: theme.sizes.s4,

  "@lg": {
    width: theme.sizes.s21,
    height: theme.sizes.s9,
    margin: theme.sizes.s0,
  },
})

export const SLink = styled(Link, {
  display: "flex",
  alignItems: "center",
  justifyItems: "center",

  "@lg": {
    paddingTop: theme.space.s4,
    paddingBottom: theme.space.s4,
  },
})
