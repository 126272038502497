import { addHours, endOfHour, endOfMonth, endOfYear, startOfYear } from "date-fns"
import { addMonths, addYears, differenceInYears, startOfDay } from "date-fns/esm"
import { DateRange } from "../../utils/types"
import { OverviewChartItem } from "./OverviewChartPlain"
import { OverviewGroup, ConsumptionPeriodType, OverviewState } from "@nano-portal/shared"

export const getOverviewPlaceholderData = (group: OverviewGroup, selected: DateRange) => {
  const data: OverviewChartItem[] = []
  const placeholderYAxisValue = 10

  if (group === OverviewGroup.Hours) {
    const start = startOfDay(selected.from)
    for (let i = 0; i < 24; ++i) {
      const hour = addHours(start, i)
      data.push({
        from: hour,
        to: endOfHour(hour),
        periodType: ConsumptionPeriodType.ConfirmedHistory,
        value: placeholderYAxisValue,
        dataState: OverviewState.Unknown,
      })
    }
  } else if (group === OverviewGroup.Months) {
    const start = startOfYear(selected.from)
    for (let i = 0; i < 12; ++i) {
      const month = addMonths(start, i)
      data.push({
        from: month,
        to: endOfMonth(month),
        periodType: ConsumptionPeriodType.ConfirmedHistory,
        value: placeholderYAxisValue,
        dataState: OverviewState.Unknown,
      })
    }
  } else {
    const start = startOfYear(selected.from)
    const yearSpan = differenceInYears(selected.to, selected.from) + 1
    for (let i = 0; i < yearSpan; ++i) {
      const year = addYears(start, i)
      data.push({
        from: year,
        to: endOfYear(year),
        periodType: ConsumptionPeriodType.ConfirmedHistory,
        value: placeholderYAxisValue,
        dataState: OverviewState.Unknown,
      })
    }
  }

  return data
}
