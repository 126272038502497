import { styled } from "../../styles/stitches.config"

export const ButtonTrigger = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  border: 0,
})
