import { useLayoutEffect, useRef, useState } from "react"
import { useIsMobileVersion } from "../../../hooks/mediaHooks"
import { theme } from "../../../styles/stitches.config"

interface Props {
  baseX: number
  baseY: number
  maxWidth: number
  maxHeight: number
}

const SvgTooltip: React.FC<React.PropsWithChildren<Props>> = ({ baseX, baseY, maxWidth, maxHeight, children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobileVersion()

  const [tooltipDimensions, setTooltipDimensions] = useState<{ width: number; height: number }>({ height: 0, width: 0 })
  useLayoutEffect(() => {
    const boundingRect = ref.current?.getBoundingClientRect()
    setTooltipDimensions({ height: (boundingRect?.height ?? 0) * 1.5, width: boundingRect?.width ?? 0 })
    // we need t orecalculate even on coords change so the tooltip does not retain smaller dimensions when switching
    // to wider/heigher content
  }, [ref, baseX, baseY])

  const { width: tWidth, height: tHeight } = tooltipDimensions

  return (
    <foreignObject
      x={baseX + tWidth > maxWidth ? baseX - tWidth : baseX}
      y={baseY - tHeight < 0 ? 5 : baseY - tHeight}
      width={tWidth}
      height={tHeight}
      style={{
        pointerEvents: "none",
      }}
      // boxShadow in style attribute is not a valid SVG property
      // Chrome handles it correctly and displays the shadow, but Firefox does't
      // only drop-shadow works correctly
      // <filter><feDropShadow> referenced by url(#shadow) for some reason breaks the tooltip on Chrome
      // thereofre using inline property
      filter={`drop-shadow(${theme.shadows.default.value})`}
    >
      <div
        ref={ref}
        style={{
          backgroundColor: theme.colors.white.value,
          minWidth: theme.sizes.s48.value,
          maxWidth,
          maxHeight,
          pointerEvents: "none",
          transformOrigin: "0 0",
          transform: isMobile ? "scale(0.7)" : undefined,
        }}
      >
        {children}
      </div>
    </foreignObject>
  )
}

export default SvgTooltip
