import * as React from "react"

function SvgGoogle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 55">
        <path data-name="Path 55" d="M0 0h24v24H0z" fill="none" />
      </g>
      <path
        data-name="Path 130"
        d="M20.095 12.19a9.725 9.725 0 00-.143-1.678h-7.789v3.18h4.461a3.823 3.823 0 01-1.651 2.51v2.064h2.661a8.065 8.065 0 002.461-6.076z"
        fill="#4285f4"
      />
      <path
        data-name="Path 131"
        d="M12.163 20.259a7.906 7.906 0 005.474-1.994l-2.661-2.064a5.009 5.009 0 01-7.447-2.619H4.783v2.127a8.26 8.26 0 007.38 4.55z"
        fill="#34a853"
      />
      <path
        data-name="Path 132"
        d="M7.526 13.582a4.947 4.947 0 010-3.162V8.293H4.783a8.266 8.266 0 000 7.417z"
        fill="#fbbc04"
      />
      <path
        data-name="Path 133"
        d="M12.163 7.009a4.488 4.488 0 013.168 1.238l2.358-2.358a7.937 7.937 0 00-5.526-2.148 8.257 8.257 0 00-7.38 4.552l2.743 2.127a4.94 4.94 0 014.637-3.411z"
        fill="#ea4335"
      />
    </svg>
  )
}

export default SvgGoogle
