import * as React from "react"

function SvgFileUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 16281">
        <path data-name="Rectangle 17151" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 16282">
        <path
          data-name="Path 51353"
          d="M7.4 10h1.59v5a1 1 0 001 1h4a1 1 0 001-1v-5h1.59a1 1 0 00.71-1.71L12.7 3.7a1 1 0 00-1.41 0L6.7 8.29A1 1 0 007.4 10zM5 19a1 1 0 001 1h12a1 1 0 000-2H6a1 1 0 00-1 1z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgFileUpload
