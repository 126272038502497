import UserMenuItem from "./UserMenuItem"
import UserMenuItemContainer from "./UserMenuItemContainer"
import { useTranslation } from "react-i18next"
import { CSSProp } from "../../../../../styles/stitches.config"
import { useModal } from "../../../../../hooks/useModal"
import PasswordEditSuccess from "../../../../../sections/profile/loginCredentials/passwordEditSuccessModal/PasswordEditSuccess"
import PasswordEdit from "../../../../../sections/profile/loginCredentials/passwordEditModal/PasswordEdit"

interface Props {
  css?: CSSProp
}

export default function ChangePasswordButton({ css }: Props) {
  const { t } = useTranslation("auth")

  const { setModal } = useModal()

  const showSuccessModal = () => setModal({ modal: <PasswordEditSuccess />, isFullscreenOnMobile: true })

  const showPasswordEditModal = () =>
    setModal({ modal: <PasswordEdit onSuccess={showSuccessModal} />, isFullscreenOnMobile: true })

  return (
    <UserMenuItemContainer css={{ ...css }} onClick={showPasswordEditModal}>
      <UserMenuItem text={t("profile_password_edit_header")} textType="menuItem" icon="Password" />
    </UserMenuItemContainer>
  )
}
