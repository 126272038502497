import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { styled, theme } from "../../styles/stitches.config"
import { Flex } from "../flex/Flex"
import ScrollArea from "../scrollarea/ScrollArea"
import { Div } from "../div/Div"

export const NotificationsContent = styled(DropdownMenu.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  paddingTop: theme.space.s6,
  paddingBottom: theme.space.s6,
  marginTop: theme.space.s2,
  filter: `drop-shadow(${theme.shadows.default})`,
})

export const NotificationContentScrollableWrapper = styled(ScrollArea, {
  height: theme.space.s96,
  paddingRight: theme.space.s6,
  marginRight: theme.space.s2,
})

export const NotificationsArrow = styled(DropdownMenu.Arrow, {
  fill: theme.colors.white,
  width: theme.space.s5,
  height: theme.space.s3_5,
})

export const NotificationsTrigger = styled(DropdownMenu.Trigger, {
  border: 0,
  outline: 0,
  backgroundColor: "transparent",
  color: "transparent",
})

export const ItemContentContainer = styled(Flex, {
  marginLeft: theme.space.s6,
  marginRight: theme.space.s6,
})

export const TitleContentContainer = styled(Flex, {
  marginLeft: theme.space.s6,
})

export const WorkingOnFixNotification = styled(Div, {
  alignItems: "center",
  justify: "center",
  display: "flex",
  paddingTop: theme.space.s4,
  paddingBottom: theme.space.s4,
  paddingRight: theme.space.s4,
  paddingLeft: theme.space.s6,

  "@lg": {
    paddingTop: theme.space.s8,
    paddingBottom: theme.space.s8,
    paddingRight: theme.space.s8,
  },
})
