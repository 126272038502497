import * as d3 from "d3"

import {
  Chart,
  getBarOffset,
  getBarWidth,
  useBarRadius,
  TRANSPARENT_OPACITY,
  useBandAxisMouseMovedHandler,
  getOuterPadding,
} from "./Chart"
import {
  PeriodOverviewDescriptionItem,
  usePeriodOverviewDescriptions,
} from "../../sections/overview/usePeriodOverviewDescription"
import { InfoSectionBaseContainer, InfoSectionContainer } from "./components/groupSummary/InfoSection"
import Text from "../Text"
import Separator from "../Separator"
import InfoField from "./components/groupSummary/InfoField"
import { formatTooltipDate, getBarSize, getDateFormatter, OverviewType } from "../../sections/overview/overview.utils"
import { Flex } from "../flex/Flex"
import Circle from "./components/Circle"
import { theme } from "../../styles/stitches.config"
import { DimensionProps, withResponsiveContainer } from "./withResponsiveContainer"
import { maxBy, min, minBy } from "lodash"
import { CHART_Y_PADDING, createDomain, useAxisRenderers, useBandwidth, useChartRange, useXAxisTicks } from "./hooks"
import ChartLabel from "./components/ChartLabel"
import Bar from "./components/Bar"
import ChartTooltipCanvas from "./ChartTooltipCanvas"
import SvgTooltip from "./components/SvgTooltip"
import {
  ChartErrorStatePlaceholder,
  ChartFixedPricelistHidden,
  ChartUnknownStatePlaceholder,
} from "./ChartDataPlaceholder"
import { ConsumptionPeriodType, ProductionPeriodType, OverviewState, OverviewGroup } from "@nano-portal/shared"
import * as icons from "../../images/xd/icons"
import { getChartView } from "./components/chartViewFactory"

export interface OverviewChartItem {
  value: number
  advanceAmount?: number
  from: Date
  to: Date
  periodType?: ConsumptionPeriodType | ProductionPeriodType
  dataState?: OverviewState
}

type Props = {
  id: string
  data: OverviewChartItem[]
  unitLabel?: string
  valueLabel: string
  group: OverviewGroup
  isUnknownState?: boolean
  isHiddenFixedPricelist?: boolean
  isErrorState?: boolean
  valueFormatter?: (value: number) => string
  type: OverviewType
}

const PREDICTION_TYPES = new Set([ConsumptionPeriodType.Prediction, ProductionPeriodType.Prediction])

function isPredictionType(type: ConsumptionPeriodType | ProductionPeriodType | undefined): boolean {
  if (type === undefined) {
    return false
  }
  return PREDICTION_TYPES.has(type)
}

type TooltipProps = Omit<OverviewChartItem, "periodType" | "advanceAmount"> & {
  group: OverviewGroup
  valueLabel: string
  valueFormatter?: (value: number) => string
  periodDescription?: PeriodOverviewDescriptionItem<unknown>
  transparent?: boolean
}

function Tooltip({
  from,
  to,
  value,
  dataState,
  valueLabel,
  valueFormatter,
  periodDescription,
  group,
  transparent,
}: TooltipProps) {
  const Icon = periodDescription?.icon ? icons[periodDescription.icon] : null
  return (
    <>
      <InfoSectionBaseContainer>
        <Text type="headerH5">{formatTooltipDate(group, from, to)}</Text>
      </InfoSectionBaseContainer>

      {dataState !== OverviewState.Unknown && (
        <>
          <Separator orientation="horizontal" decorative />
          <InfoSectionContainer>
            <InfoField header={valueLabel} amount={value} formatter={valueFormatter} />
          </InfoSectionContainer>
        </>
      )}

      {periodDescription && (
        <>
          <Separator orientation="horizontal" decorative />
          <InfoSectionBaseContainer>
            <Flex align="center" css={{ gap: theme.space.s2 }}>
              {Icon ? (
                <Icon
                  color={theme.colors[periodDescription.color].value}
                  opacity={transparent ? 0.3 : 1}
                  height={"24px"}
                />
              ) : (
                <Circle color={periodDescription.color} opacity={transparent ? 0.3 : 1} />
              )}
              <Text type="textsSmall" color="textsAlt" css={{ fontSize: 12 }}>
                {periodDescription.baseDescription}
              </Text>
            </Flex>
          </InfoSectionBaseContainer>
        </>
      )}
    </>
  )
}

type ChartProps = DimensionProps & Props

export function OverviewChartPlain({
  data,
  group,
  id,
  valueLabel,
  unitLabel,
  valueFormatter,
  isUnknownState,
  isErrorState,
  isHiddenFixedPricelist,
  height,
  width,
  type,
}: ChartProps) {
  const chartView = getChartView(group)

  const dates = data.map((d) => d.from)
  const maxValue = maxBy(data, (d) => d.value)?.value ?? 0
  const minValue = minBy(data, (d) => d.value)?.value ?? 0
  const [rangeX, rangeY] = useChartRange(width, height, data.length, chartView.centered)

  const chartDomain = chartView.getChartDomain(dates)
  const scaleXBand = d3.scaleBand<Date>().domain(chartDomain).range(rangeX).paddingInner(0.1)
  const scaleY = d3
    .scaleLinear()
    .domain(createDomain(min([minValue, 0]) ?? 0, maxValue))
    .range(rangeY)

  const [bandwidth] = useBandwidth(scaleXBand)

  const { renderHorizontalLineUnitLabel, renderHorizontalLines, renderHorizontalLinesTicks, renderZeroHorizontalLine } =
    useAxisRenderers(width, [scaleY], unitLabel)

  const dateTicks = useXAxisTicks(dates, chartView.tickDensities, chartView.centered)
  const getBarRadius = useBarRadius()

  const [onMove, tooltipGroup, tooltipCoords] = useBandAxisMouseMovedHandler(
    scaleXBand,
    data,
    bandwidth,
    (value) => scaleY(value.value / 2),
    "continuous"
  )
  const { all: periodDescriptions } = usePeriodOverviewDescriptions()

  const tooltipDescription = periodDescriptions.find(
    (item) => item.value === tooltipGroup?.periodType && tooltipGroup?.dataState === item.state
  )

  const outerPadding = getOuterPadding(scaleXBand)
  const canvasWidth = chartView.calculateCanvasWidth(width, chartDomain.length, dates.length, outerPadding)

  const getCanvasOffset = () => {
    if (!data[0]?.from) {
      return 0
    }
    const canvasOffset = scaleXBand(data[0].from)
    return !canvasOffset ? 0 : canvasOffset - outerPadding
  }

  return (
    <Chart id={id} height={height} width={width}>
      {renderHorizontalLines()}
      {renderZeroHorizontalLine()}
      {renderHorizontalLineUnitLabel()}
      {renderHorizontalLinesTicks()}

      {dateTicks.map((value) => {
        const labelFormatter = getDateFormatter(group)

        return (
          <g key={value.toISOString()}>
            <ChartLabel
              date={value}
              x={(scaleXBand(value) ?? 0) + bandwidth * 0.5}
              chartHeight={height}
              chartYPadding={CHART_Y_PADDING}
              text={labelFormatter(value)}
              type="small"
            />
          </g>
        )
      })}

      {isErrorState && <ChartErrorStatePlaceholder height={height} width={width} />}
      {isUnknownState && <ChartUnknownStatePlaceholder type={type} height={height} width={width} />}
      {isHiddenFixedPricelist && <ChartFixedPricelistHidden type={type} height={height} width={width} />}

      {!isErrorState && !isUnknownState && !isHiddenFixedPricelist && (
        <>
          {data.map((d) => {
            const barHeight = scaleY(0) - scaleY(Math.abs(d.value))
            const barSize = getBarSize(group)
            const periodDescription = periodDescriptions.find((item) => item.value === d.periodType)
            const isUnknown = d.dataState === OverviewState.Unknown

            return (
              <g
                key={d.from.toISOString()}
                opacity={isPredictionType(d.periodType) && !isUnknown ? TRANSPARENT_OPACITY : 1}
              >
                <Bar
                  icon={isUnknown ? "Questionmark" : undefined}
                  iconColor={isUnknown ? "primary" : undefined}
                  borderColor={isUnknown ? "primary" : undefined}
                  startX={(scaleXBand(d.from) ?? 0) + getBarOffset(bandwidth, barSize)}
                  startY={scaleY(0)}
                  orientation={d.value < 0 ? "down" : "up"}
                  height={barHeight}
                  width={getBarWidth(bandwidth, barSize)}
                  fill={isUnknown ? "lightGreenTransparent" : periodDescription?.color ?? "primary"}
                  radius={getBarRadius(barSize)}
                />
              </g>
            )
          })}
          <ChartTooltipCanvas height={height} width={canvasWidth} xOffset={getCanvasOffset()} onChangeX={onMove} />
          {tooltipGroup && tooltipCoords !== null && (
            <SvgTooltip maxHeight={height} maxWidth={width} baseX={tooltipCoords[0]} baseY={tooltipCoords[1]}>
              <Tooltip
                {...tooltipGroup}
                periodDescription={tooltipDescription}
                valueFormatter={valueFormatter}
                valueLabel={valueLabel}
                group={group}
                transparent={isPredictionType(tooltipGroup.periodType)}
              />
            </SvgTooltip>
          )}
        </>
      )}
    </Chart>
  )
}

export const OverviewChartPlainResponsive = withResponsiveContainer(OverviewChartPlain)
