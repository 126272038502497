import { styled, theme } from "../../../styles/stitches.config"

export const SSideMenuContainer = styled("div", {
  $$top: theme.space.s28,
  display: "none",

  "@lg": {
    position: "absolute",
    display: "block",
  },
})

export const SBodyContainer = styled("div", {
  height: "100%",

  variants: {
    variant: {
      mobile: {
        marginLeft: theme.space.s0,
      },
      desktop: {
        marginLeft: theme.space.s64,
      },
    },
  },

  defaultVariant: {
    variant: "mobile",
  },
})
