import Text from "../../../../Text"
import { ColorType, IconType, fontVariants, theme } from "../../../../../styles/stitches.config"
import * as icons from "../../../../../images/xd/icons"

const iconProps = {
  width: theme.sizes.s4.value,
  height: theme.sizes.s4.value,
}

interface UserMenuItemProps {
  text: string
  textType: keyof typeof fontVariants
  color?: ColorType
  icon: IconType
}

function UserMenuItem({ text, color, textType, icon }: UserMenuItemProps) {
  const Icon = icons[icon]
  return (
    <>
      <Icon color={color ?? fontVariants[textType].color} {...iconProps} />
      <Text type={textType} color={color}>
        {text}
      </Text>
    </>
  )
}

export default UserMenuItem
