import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Flex } from "../../../components/flex/Flex"
import Separator from "../../../components/Separator"
import { Spacer } from "../../../components/spacer/Spacer"
import Tag from "../../../components/tag/Tag"
import Text from "../../../components/Text"
import { LINKS } from "../../../constants/links"
import { ArrowForward, Electricity, Lightbulb, Sun } from "../../../images/xd/icons"
import { theme } from "../../../styles/stitches.config"
import { assertUnreachable } from "../../../utils/types"
import { SContent, SHeader, SNameAddressContainer, SWrapper } from "./HomeSupplyPointCard.styled"
import { HomeSupplyPointCardDepositState } from "./HomeSupplyPointCardDepositState"
import { uniq } from "lodash"
import { trpc } from "../../../api/trpc/trpc"
import { PulseLoader } from "react-spinners"
import { HomeSupplyPointCardBillingState } from "./HomeSupplyPointCardBillingState"
import { DeliveryPointType } from "@nano-portal/shared"
import InfoBox from "../../../components/info/InfoBox"
import { splitAddressToStreetAndTown } from "../../../utils/string"

type Props = {
  id: number
  ean: string
  address: string
  isActive: boolean
  tags: DeliveryPointType[]
  disableDepositStateLoading?: boolean
  showNotification?: boolean
}

function BulbIcon({ color }: { color: string }) {
  return (
    <Flex align="center" justify="center" css={{ backgroundColor: color, width: 20, height: 20, borderRadius: "50%" }}>
      <Lightbulb width={10} height={10} color={theme.colors.white.value} />
    </Flex>
  )
}

function ActivateDataTariff({ id }: { id: number }) {
  return (
    <Link to={LINKS.pdtFlexibility(id)}>
      <Flex direction="row" align="center">
        <BulbIcon color={theme.colors.primary.value} />
        <Spacer size={theme.space.s2} />
        <Text type="textsLarge" color="primary">
          Aktivujte si nový tarif{" "}
          <Text type="headerH3" color="secondary" css={{ whiteSpace: "nowrap" }}>
            Výkup + DATA
          </Text>
        </Text>
        <Spacer size={theme.space.s2} />
        <ArrowForward width={20} color={theme.colors.secondary.value} />
      </Flex>
    </Link>
  )
}

export function HomeSupplyPointCard(props: Props) {
  const { id, ean, address, tags, isActive, disableDepositStateLoading, showNotification } = props
  const { t } = useTranslation(["common", "delivery_point"])
  const [street] = splitAddressToStreetAndTown(address)

  function renderTag(tag: DeliveryPointType) {
    switch (tag) {
      case DeliveryPointType.Consumption:
        return <Tag variant="primary" text={t("common:consumption")} icon={<Electricity />} />
      case DeliveryPointType.Production:
        return <Tag variant="tertiary" text={t("common:production")} icon={<Sun />} />
      default:
        return assertUnreachable(tag)
    }
  }

  const isOnlyProduction = tags.every((tag) => tag === DeliveryPointType.Production)

  const { data, isLoading } = trpc.useQuery(["deliveryPoints.deliveryPointPaymentState", { deliveryPointId: id }], {
    enabled: !disableDepositStateLoading && !isOnlyProduction,
  })

  const hasConsumptionAndDepositStateIsLoading = !disableDepositStateLoading && !isOnlyProduction
  const showDepositState =
    hasConsumptionAndDepositStateIsLoading &&
    !!(
      isActive ||
      data?.advancesState?.current?.amountToPair ||
      data?.advancesState?.next?.amountToPair ||
      data?.advancesState?.previous?.amountToPair
    )
  const isInactiveWithDebt = !isActive && data?.hasBillingDebtSinceYearAgo

  const showBillingState =
    (hasConsumptionAndDepositStateIsLoading && isInactiveWithDebt) ||
    (hasConsumptionAndDepositStateIsLoading && isActive)

  const showProductionDataTariffOffer = showNotification

  return (
    <Link to={LINKS.pdt(id)}>
      <SWrapper isActive={isActive}>
        <Flex direction="column">
          <SHeader>
            <Flex direction={"column"}>
              <Text type={"headerH5"} color={isActive ? "primary" : "textsAlt"} css={{ fontWeight: "normal" }}>
                {t("common:ean", { ean })}
              </Text>
            </Flex>
            {tags && (
              <Flex direction="row" align="center" justify="between" css={{ gap: theme.space.s2 }}>
                {uniq(tags).map((tag) => (
                  <Fragment key={tag}>{renderTag(tag)}</Fragment>
                ))}
              </Flex>
            )}
            {showProductionDataTariffOffer && showDepositState && (
              <InfoBox variant="neutral" renderIcon={(color: string) => <BulbIcon color={color} />}>
                <ActivateDataTariff id={id} />
              </InfoBox>
            )}
          </SHeader>
          <SNameAddressContainer>
            <Spacer size={theme.space.s3} />

            <Text type="headerH3" as="p">
              {street}
            </Text>

            <Text type="textsSmall" as="p">
              {t("delivery_point:supply_point")}
            </Text>

            <Spacer size={theme.space.s3} />
          </SNameAddressContainer>
        </Flex>

        <SContent>
          <Separator color={isActive ? "dark" : "light"} />
          {!isActive && (
            <Text type={"textsLarge"} css={{ margin: "auto" }}>
              {t("delivery_point:supply_point_inactive")}
            </Text>
          )}
          {showBillingState && (
            <Flex align="center" justify="between">
              <Text type="headerH4" css={{ maxWidth: theme.sizes.s32 }}>
                {t("delivery_point:supply_points_billing_state_title")}
              </Text>

              <Spacer size={theme.space.s4} />

              {isLoading ? (
                <PulseLoader color={theme.colors.textsDisabeled.value} />
              ) : (
                <HomeSupplyPointCardBillingState
                  hasBillingDebt={data?.hasBillingDebtSinceYearAgo}
                  unpaidLink={LINKS.pdtPayments(id)}
                />
              )}
            </Flex>
          )}
          {showDepositState && (
            <Flex align="center" justify="between">
              <Text type="headerH4" css={{ maxWidth: theme.sizes.s28 }}>
                {t("delivery_point:supply_points_deposit_state_title")}
              </Text>

              <Spacer size={theme.space.s4} />

              {isLoading ? (
                <PulseLoader color={theme.colors.textsDisabeled.value} />
              ) : (
                <HomeSupplyPointCardDepositState
                  advancesState={data?.advancesState}
                  paymentsLink={LINKS.pdtPayments(id)}
                />
              )}
            </Flex>
          )}
          {!showDepositState && showProductionDataTariffOffer && (
            <Flex align="center" justify="center">
              <ActivateDataTariff id={id} />
            </Flex>
          )}
        </SContent>
      </SWrapper>
    </Link>
  )
}
