import * as React from "react"

function SvgUnderConstruction(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_UnderConstruction_svg__a">
          <path data-name="Rectangle 17161" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 16306" clipPath="url(#_UnderConstruction_svg__a)">
        <path
          data-name="Path 51371"
          d="M13.194 5.897a2.165 2.165 0 10-2.165-2.165 2.165 2.165 0 002.165 2.165"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51372"
          d="M13.903 11.874l-.791-4.423a.941.941 0 00-.581-.866L9.226 5.193a.934.934 0 00-.1-.029.944.944 0 00-.413-.1H4.906a.954.954 0 00-.954.954v4.471a.954.954 0 001.908 0V6.974h1.7l-1.7 5.313a.952.952 0 00-.039.4c0 .023-.007.045-.007.068v4.321l-1.78 4.016a.954.954 0 101.744.773l1.862-4.2a.958.958 0 00.082-.386v-3.494l1.282.3 1.8 2.6v4.79a.954.954 0 001.908 0v-5.086a.954.954 0 00-.17-.544l-1.875-2.7 1.087-2.433.283 1.585a.963.963 0 00.045.164l1.322 3.558a.954.954 0 101.789-.664z"
          fill="#7ca7ad"
        />
        <path data-name="Path 51373" d="M3.135 7.361l-.919-.578a.681.681 0 10-.726 1.153l1.645 1.035z" fill="#ffc200" />
        <path
          data-name="Path 51374"
          d="M21.475 17.115l-1.8-1.145a.623.623 0 00-.861.192l-.485.762-2.265-1.428a1.777 1.777 0 01-.42 1.346l1.956 1.231-.494.777a.623.623 0 00.192.861l1.8 1.146a2.725 2.725 0 001.425.331 3.391 3.391 0 002.071-.576.623.623 0 00.134-.2 3.376 3.376 0 00-1.251-3.3"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgUnderConstruction
