import Card from "../../../../components/Card"
import InfoCell from "./InfoCell"
import { ComponentProps, ComponentPropsWithRef, Fragment } from "react"
import InfoCardSeparator from "./InfoCardSeparator"

type InfoCardProps = Omit<ComponentPropsWithRef<typeof Card>, "children"> & {
  data: ComponentProps<typeof InfoCell>[]
}

export default function InfoCard({ data, ref, ...props }: InfoCardProps) {
  return (
    <Card ref={ref} {...props}>
      {data.map((cell, cellIndex) => (
        <Fragment key={cell.label}>
          <InfoCell {...cell} />
          {cellIndex < data.length - 1 && <InfoCardSeparator />}
        </Fragment>
      ))}
    </Card>
  )
}
