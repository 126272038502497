import * as React from "react"

function SvgRequestsAll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.998" {...props}>
      <defs>
        <clipPath id="_RequestsAll_svg__a">
          <path data-name="Rectangle 391" fill="currentColor" d="M0 0h24v23.998H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 705" clipPath="url(#_RequestsAll_svg__a)" fill="currentColor">
        <path
          data-name="Path 272"
          d="M15.448 5.865h-1.094a1.929 1.929 0 00-1.928-1.92H7.714a1.929 1.929 0 00-1.928 1.92H4.694a1.929 1.929 0 00-1.929 1.929v14.187a1.929 1.929 0 001.929 1.929h10.754a1.929 1.929 0 001.929-1.929V7.794a1.929 1.929 0 00-1.929-1.929M7.714 7.794v-1.92h4.711v1.924H7.714zm7.733 14.187H4.694V7.794h1.092a1.93 1.93 0 001.928 1.935h4.711a1.93 1.93 0 001.929-1.931h1.092z"
        />
        <path
          data-name="Path 273"
          d="M12.549 13.134l-3.24 3.242-1.717-1.719a.965.965 0 00-1.363 1.365l2.4 2.4a.965.965 0 001.363 0l3.921-3.923a.965.965 0 00-1.364-1.365"
        />
        <path
          data-name="Path 274"
          d="M19.308 2.007h-1.1a1.929 1.929 0 00-1.927-1.92h-4.709a1.928 1.928 0 00-1.924 1.907h6.636v1.942h3.024v16.12a1.93 1.93 0 001.929-1.931V3.936a1.929 1.929 0 00-1.929-1.929"
        />
      </g>
    </svg>
  )
}

export default SvgRequestsAll
