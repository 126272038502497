import * as Popover from "@radix-ui/react-popover"
import { fontVariants, styled, theme } from "../../../styles/stitches.config"

export const ExportContent = styled(Popover.Content, {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s4,
  marginTop: theme.space.s4,
})

export const Item = styled("button", {
  ...fontVariants.menuItem,
  backgroundColor: "transparent",
  cursor: "pointer",
  border: 0,
  outline: 0,
})
