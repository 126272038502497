import React, { ReactElement, cloneElement, useCallback, useEffect, useState } from "react"
// import "react-day-picker/dist/style.css"
import "./style.css"
import * as Popover from "@radix-ui/react-popover"
import {
  ArrowButton,
  PickerControlContainer,
  DateRangeContainer,
  DropdownContent,
  PickerContainer,
  HeaderContainer,
} from "./DatePicker.styled"
import {
  getMonthName,
  isFullMonthRange,
  isFullYearRange,
  isLastMonthRange,
  isLastYearRange,
  isThisMonthRange,
  isThisYearRange,
} from "../../utils/date"
import { ArrowBack, ArrowForward, Date as DateIcon } from "../../images/xd/icons"
import { theme } from "../../styles/stitches.config"
import { Spacer } from "../spacer/Spacer"
import { formatDate, shiftInterval } from "../../utils/date"
import { DateRange } from "../../utils/types"
import { Flex } from "../flex/Flex"
import Text from "../Text"
import { endOfDay, isSameDay } from "date-fns"
import { VariantProps } from "@stitches/react"
import { useTranslation } from "react-i18next"

type Size = VariantProps<typeof PickerContainer>["size"]

interface Props {
  selected: DateRange
  setSelected: (selected: DateRange) => void
  header?: string
  maxDate?: Date
  minDate?: Date
  anchorContainerStyle?: React.CSSProperties
  children?: ReactElement
  size?: Size
  iconSide?: "left" | "right"
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

interface PickerControlProps {
  range: DateRange
  onClickPrevious?: () => void
  onClickNext?: () => void
  onClick?: () => void
  size?: Size
  iconSide?: "left" | "right"
}

const useGetDateDisplayValue = (range: DateRange) => {
  const { t } = useTranslation("date_picker")

  if (isSameDay(range.from, range.to)) {
    return formatDate(range.from)
  } else if (isThisMonthRange(range)) {
    return t("date_picker_this_month")
  } else if (isLastMonthRange(range)) {
    return t("date_picker_last_month")
  } else if (isFullMonthRange(range)) {
    return `${getMonthName(range.from)} ${range.from.getFullYear()}`
  } else if (isThisYearRange(range)) {
    return t("date_picker_this_year")
  } else if (isLastYearRange(range)) {
    return t("date_picker_last_year")
  } else if (isFullYearRange(range)) {
    return range.from.getFullYear().toString()
  } else {
    return `${formatDate(range.from)} - ${formatDate(range.to)}`
  }
}

const DatePickerControl = ({
  range,
  onClickPrevious,
  onClickNext,
  iconSide = "left",
  size = "regular",
}: PickerControlProps) => {
  const value = useGetDateDisplayValue(range)

  return (
    <PickerControlContainer direction="row" justify="between" align="center">
      <ArrowButton side="left" onClick={onClickPrevious} disabled={!onClickPrevious}>
        <ArrowBack
          color={onClickPrevious ? theme.colors.secondary.value : theme.colors.textsDisabeled.value}
          width={theme.space.s5.value}
          height={theme.space.s5.value}
        />
      </ArrowButton>
      <Popover.Trigger asChild>
        <DateRangeContainer size={size}>
          {iconSide === "left" ? (
            <>
              <DateIcon
                color={theme.colors.secondary.value}
                width={theme.space.s6.value}
                height={theme.space.s6.value}
              />
              <Spacer size={theme.space.s1} />
              {value}
            </>
          ) : (
            <>
              {value}
              <Spacer size={theme.space.s1} />
              <DateIcon
                color={theme.colors.secondary.value}
                width={theme.space.s6.value}
                height={theme.space.s6.value}
              />
            </>
          )}
        </DateRangeContainer>
      </Popover.Trigger>
      <ArrowButton side="right" onClick={onClickNext} disabled={!onClickNext}>
        <ArrowForward
          color={onClickNext ? theme.colors.secondary.value : theme.colors.textsDisabeled.value}
          width={theme.space.s4.value}
          height={theme.space.s5.value}
        />
      </ArrowButton>
    </PickerControlContainer>
  )
}

const DatePicker = ({
  selected,
  setSelected,
  minDate,
  maxDate,
  anchorContainerStyle,
  header,
  children,
  size = "regular",
  iconSide = "left",
  open,
  onOpenChange,
}: Props) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(selected)

  useEffect(() => {
    setSelectedDateRange(selected)
  }, [selected])

  const onClickPrevious = useCallback(() => {
    const newInterval = shiftInterval(selected, -1)
    setSelectedDateRange(newInterval)
    setSelected(newInterval)
  }, [selected, setSelected])

  const onClickNext = useCallback(() => {
    const newInterval = shiftInterval(selected, 1)
    setSelectedDateRange(newInterval)
    setSelected(newInterval)
  }, [selected, setSelected])

  const confirmSelection = () => {
    setSelected(selectedDateRange)
    onOpenChange?.(false)
  }

  const canClickPrevious = !minDate || endOfDay(selectedDateRange.from) > endOfDay(minDate)
  const canClickNext = !maxDate || endOfDay(selectedDateRange.to) < endOfDay(maxDate)

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <PickerContainer direction="column" size={size}>
        {header && (
          <HeaderContainer justify="center">
            <Text type="headerH2">{header}</Text>
          </HeaderContainer>
        )}
        <DatePickerControl
          range={selectedDateRange}
          onClickPrevious={canClickPrevious ? onClickPrevious : undefined}
          onClickNext={canClickNext ? onClickNext : undefined}
          iconSide={iconSide}
          size={size}
        />
      </PickerContainer>
      {anchorContainerStyle && (
        <div style={anchorContainerStyle}>
          <Popover.Anchor />
        </div>
      )}
      <DropdownContent align="center" onPointerDownOutside={confirmSelection}>
        <Flex direction="row" align="center">
          {children &&
            cloneElement(children, {
              selected: selectedDateRange,
              setSelected: setSelectedDateRange,
              onConfirm: confirmSelection,
            })}
        </Flex>
      </DropdownContent>
    </Popover.Root>
  )
}

export default DatePicker
