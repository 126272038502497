import ReactDOM from "react-dom"
import { App } from "./App"
import { withSentry } from "./components/hoc/withSentry"
import { initFullHeightHack } from "./utils/browser"
import { Workbox } from "workbox-window"
import "./i18n/config"
import { isLocal } from "./utils/env"

initFullHeightHack()

// TODO mirgate to React 18
const AppWithSentry = withSentry(App)
ReactDOM.render(<AppWithSentry />, document.querySelector("#app"))

// service worker registration
if (!isLocal() && "serviceWorker" in navigator) {
  const wb = new Workbox("/service-worker.js")

  wb.addEventListener("waiting", () => {
    wb.addEventListener("controlling", () => {
      window.location.reload()
    })
  })

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  wb.register()
}
