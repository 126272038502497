import { styled, theme } from "../../../../../styles/stitches.config"
import * as Dropdown from "@radix-ui/react-dropdown-menu"
import { ArrowDown } from "../../../../../images/xd/icons"
import {
  createRotationAnimation,
  defaultAnimationDuration,
  defaultRadixContentFadeInOutAnimation,
} from "../../../../../styles/utils"

export const UserMenuTrigger = styled("button", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  height: "inherit",
  hover: {
    cursor: "pointer",
  },
  paddingRight: theme.space.s6,
  border: 0,
  outline: 0,
  backgroundColor: "transparent",
})

export const UserDetailContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginRight: theme.space.s4,
  alignItems: "flex-start",
  gap: theme.space.s1,
})

export const UserMenuDropdownContent = styled(Dropdown.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s4,

  ...defaultRadixContentFadeInOutAnimation,
})

const fromDegrees = 0
const toDegrees = 180
export const animationDuration = defaultAnimationDuration

const toggled = createRotationAnimation(fromDegrees, toDegrees)
const collapsed = createRotationAnimation(toDegrees, fromDegrees)

const createAnimation = (isToggled: boolean) => {
  return {
    noReducedMotion: {
      animation: `${isToggled ? toggled : collapsed} ${animationDuration}ms ease-out forwards`,
    },
  }
}

export const RotatingArrow = styled(ArrowDown, {
  variants: {
    toggled: {
      true: createAnimation(true),
      false: createAnimation(false),
    },
  },
})
