import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { trpc } from "../../api/trpc/trpc"
import { LINKS } from "../../constants/links"
import { toasts } from "../../utils/toasts"

export const useDeliveryPointNotFoundHandler = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["delivery_point"])

  return () => {
    toasts.error(t("delivery_point:not_found_error"))
    navigate(LINKS.home)
  }
}

export const useDeliveryPointDetail = (deliveryPointId: number) => {
  const handle = useDeliveryPointNotFoundHandler()
  const deliveryPointsQuery = trpc.useQuery(["deliveryPoints.detail", { deliveryPointId }], {
    onError: (error) => {
      if (error.data?.portalCode === "DELIVERY_POINT_NOT_FOUND") {
        handle()
      }
    },
  })

  return deliveryPointsQuery
}

export const useDeliveryPointFullDetail = (deliveryPointId: number) => {
  const handle = useDeliveryPointNotFoundHandler()
  const deliveryPointsQuery = trpc.useQuery(["deliveryPoints.fullDetail", { deliveryPointId }], {
    onError: (error) => {
      if (error.data?.portalCode === "DELIVERY_POINT_NOT_FOUND") {
        handle()
      }
    },
  })

  return deliveryPointsQuery
}
