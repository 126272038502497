export async function refetchQueries(queries: { refetch: () => Promise<unknown> }[]) {
  return Promise.all(queries.map((query) => query.refetch()))
}

export function resolveQueriesState(queries: { isLoading: boolean; isError: boolean }[]) {
  return {
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
  }
}
