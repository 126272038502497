import * as React from "react"

function SvgDashboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g data-name="Group 546">
        <path data-name="Rectangle 377" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 548">
        <g data-name="Group 547" fill="currentColor">
          <path
            data-name="Path 130"
            d="M5 11h4a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2H5a2.006 2.006 0 00-2 2v4a2.006 2.006 0 002 2z"
          />
          <path
            data-name="Path 131"
            d="M5 21h4a2.006 2.006 0 002-2v-4a2.006 2.006 0 00-2-2H5a2.006 2.006 0 00-2 2v4a2.006 2.006 0 002 2z"
          />
          <path
            data-name="Path 132"
            d="M13 5v4a2.006 2.006 0 002 2h4a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2h-4a2.006 2.006 0 00-2 2z"
          />
          <path
            data-name="Path 133"
            d="M15 21h4a2.006 2.006 0 002-2v-4a2.006 2.006 0 00-2-2h-4a2.006 2.006 0 00-2 2v4a2.006 2.006 0 002 2z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgDashboard
