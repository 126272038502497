import { OverviewGroup } from "@nano-portal/shared"
import { getDayArrayOfMonth, getMonthArrayOfYear } from "../../../utils/date"
import { TickDensities, TickDensitiesGroup } from "../hooks"

type CalculateCanvasWidth = (
  containerWidth: number,
  domainSize: number,
  visibleBarCount: number,
  outerPadding?: number
) => number

export type ChartView = {
  centered: boolean
  tickDensities: TickDensities
  calculateCanvasWidth: CalculateCanvasWidth
  getChartDomain: (dates: Date[]) => Date[]
}

const calculateCanvasWidth: CalculateCanvasWidth = (
  containerWidth: number,
  domainSize: number,
  visibleBarCount: number,
  outerPadding = 0
) => {
  return containerWidth * (visibleBarCount / domainSize) + outerPadding
}

const calculateCenteredCanvasWidth: CalculateCanvasWidth = (
  containerWidth: number,
  domainSize: number,
  visibleBarCount: number,
  outerPadding = 0
) => {
  return containerWidth
}

const yearlyChartView: ChartView = {
  centered: true,
  tickDensities: TickDensitiesGroup.years,
  calculateCanvasWidth: calculateCenteredCanvasWidth,
  getChartDomain(dates: Date[]) {
    return dates
  },
}
const monthlyChartView: ChartView = {
  centered: false,
  tickDensities: TickDensitiesGroup.months,
  calculateCanvasWidth: calculateCanvasWidth,
  getChartDomain(dates: Date[]) {
    const date = dates[0] ?? new Date()
    return getMonthArrayOfYear(date.getFullYear())
  },
}
const dailyChartView: ChartView = {
  centered: false,
  tickDensities: TickDensitiesGroup.days,
  calculateCanvasWidth: calculateCanvasWidth,
  getChartDomain(dates: Date[]) {
    const date = dates[0] ?? new Date()
    return getDayArrayOfMonth(date.getMonth(), date.getFullYear())
  },
}
const hourlyChartView: ChartView = {
  centered: false,
  tickDensities: TickDensitiesGroup.hours,
  calculateCanvasWidth: calculateCenteredCanvasWidth,
  getChartDomain(dates: Date[]) {
    return dates
  },
}

const customChartViewMap = new Map<OverviewGroup, ChartView>([
  [OverviewGroup.Years, yearlyChartView],
  [OverviewGroup.Months, monthlyChartView],
  [OverviewGroup.Days, dailyChartView],
  [OverviewGroup.Hours, hourlyChartView],
])

export function getChartView(group: OverviewGroup): ChartView {
  const customChartView = customChartViewMap.get(group)
  return customChartView ?? yearlyChartView
}
