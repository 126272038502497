import * as React from "react"

function SvgConsumption(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <defs>
        <clipPath id="_Consumption_svg__a">
          <path data-name="Rectangle 17671" fill="none" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17794">
        <path data-name="Path 60852" d="M57.966 42.769l-.011 1.86-25.871 15.035.01-1.86z" fill="#d0dadd" />
        <g data-name="Group 17780">
          <g data-name="Group 17779" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60853"
              d="M32.08 59.771a.107.107 0 01-.107-.107l.01-1.86a.107.107 0 01.053-.092l25.871-15.035a.107.107 0 01.16.093l-.011 1.86a.107.107 0 01-.053.092L32.132 59.757a.106.106 0 01-.054.014m.117-1.905l-.009 1.613 25.657-14.91.009-1.613z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60854" d="M32.09 57.804l-.011 1.86L6.039 44.63l.011-1.86z" fill="#d0dadd" />
            <path
              data-name="Path 60855"
              d="M32.079 59.771a.107.107 0 01-.053-.014L5.986 44.723a.107.107 0 01-.053-.093l.011-1.86a.107.107 0 01.16-.092l26.04 15.034a.107.107 0 01.053.093l-.011 1.86a.107.107 0 01-.107.106m-25.933-15.2l25.828 14.912.009-1.615L6.155 42.954z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60856" d="M57.961 42.77L32.089 57.804 6.049 42.77 31.92 27.735z" fill="#d0dadd" />
            <path
              data-name="Path 60857"
              d="M32.089 57.91a.107.107 0 01-.053-.014L5.995 42.863a.107.107 0 010-.185l25.871-15.035a.107.107 0 01.107 0l26.04 15.034a.107.107 0 010 .185l-25.87 15.035a.106.106 0 01-.054.015M6.262 42.77l25.827 14.911L57.747 42.77 31.92 27.859z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 60858" d="M31.804 10.441l-.009 21.876-19.855 11.6.009-21.876z" fill="#fff" />
        <g data-name="Group 17782">
          <g data-name="Group 17781" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60859"
              d="M11.941 44.028a.107.107 0 01-.107-.107l.009-21.876a.107.107 0 01.053-.092l19.855-11.6a.107.107 0 01.161.092l-.009 21.876a.107.107 0 01-.053.092l-19.855 11.6a.107.107 0 01-.054.015m.116-21.922l-.009 21.629 19.641-11.477.009-21.629z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60860" d="M10.436 21.169l1.514.873-.009 21.876-1.505-1z" fill="#fff" />
            <path
              data-name="Path 60861"
              d="M11.941 44.025a.107.107 0 01-.059-.018l-1.505-1a.107.107 0 01-.048-.089v-21.75a.107.107 0 01.16-.092l1.514.873a.107.107 0 01.053.093l-.009 21.876a.107.107 0 01-.107.107m-1.4-1.164l1.292.858.009-21.616-1.3-.75z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60862" d="M53.277 21.169l-1.514.873.009 21.876 1.505-1z" fill="#fff" />
            <path
              data-name="Path 60863"
              d="M51.772 44.025a.107.107 0 01-.107-.107l-.009-21.876a.106.106 0 01.053-.092l1.514-.873a.107.107 0 01.16.092v21.75a.107.107 0 01-.048.089l-1.505 1a.107.107 0 01-.059.018m.1-21.921l.009 21.616 1.292-.858V21.355z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60864"
              d="M31.799 8.747l21.467 12.428-1.514.873-19.944-11.545h-.009L11.95 22.042l-1.514-.873L31.809 8.748z"
              fill="#fff"
            />
            <path
              data-name="Path 60865"
              d="M51.753 22.155a.106.106 0 01-.053-.014L31.8 10.628 12 22.135a.107.107 0 01-.107 0l-1.514-.873a.107.107 0 010-.185l21.34-12.4a.107.107 0 01.078-.034h.009a.106.106 0 01.077.033l21.434 12.409a.107.107 0 010 .185l-1.514.873a.107.107 0 01-.053.014M31.8 10.397a.142.142 0 01.062.014l19.891 11.514 1.3-.75-21.249-12.3-21.155 12.3 1.3.75 19.8-11.5a.107.107 0 01.054-.014"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path
          data-name="Path 60866"
          d="M51.771 23.958l-.008-.93v-.956h-.008v-.02L41.038 5.215l-9.156 5.249-.084-.049v2.782l.008 19.094 19.965 11.663-.008-20z"
          fill="#fff"
        />
        <g data-name="Group 17784">
          <g data-name="Group 17783" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60867"
              d="M51.771 44.058a.107.107 0 01-.054-.015L31.753 32.378a.107.107 0 01-.053-.092l-.008-19.079a.092.092 0 010-.015V10.41a.107.107 0 01.161-.092l.031.018 9.1-5.214a.107.107 0 01.143.035l10.717 16.834a.106.106 0 01.016.042.1.1 0 01.01.04v.956l.008.929a.107.107 0 01-.008.041l.008 19.951a.107.107 0 01-.107.107M31.914 32.225l19.75 11.538-.007-19.805a.109.109 0 01.007-.039l-.008-.885v-.924a.106.106 0 01-.007-.024L41.002 5.358l-9.067 5.194a.106.106 0 01-.031.012v2.614a.1.1 0 010 .015z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60868" d="M10.436 21.169l10.7-4.455L42.56 4.336 31.807 8.748z" fill="#00515f" />
            <path
              data-name="Path 60869"
              d="M10.436 21.275a.107.107 0 01-.054-.2l21.373-12.42.013-.006L42.52 4.237a.107.107 0 01.094.191L21.19 16.806l-.012.006-10.705 4.455a.107.107 0 01-.041.008m21.42-12.432L11.886 20.449l9.2-3.832L41.135 5.035z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60870" d="M21.197 18.198l-.061-1.484-10.7 4.455 1.514.879z" fill="#fff" />
            <path
              data-name="Path 60871"
              d="M11.95 22.155a.106.106 0 01-.054-.014l-1.514-.879a.107.107 0 01.013-.191l10.7-4.455a.107.107 0 01.148.094l.061 1.484a.107.107 0 01-.066.1l-9.248 3.85a.107.107 0 01-.041.008m-1.274-.97l1.282.745 9.131-3.8-.051-1.257z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60872"
              d="M53.277 21.169L42.561 4.336l-1.523.879-19.9 11.5.061 1.484 20.659-11.7 9.9 15.548z"
              fill="#fff"
            />
            <path
              data-name="Path 60873"
              d="M51.755 22.155a.107.107 0 01-.09-.049L41.824 6.643l-20.57 11.648a.107.107 0 01-.159-.088l-.061-1.484a.107.107 0 01.053-.1l19.88-11.482a.091.091 0 01.021-.017l1.523-.879a.107.107 0 01.143.035l10.713 16.839a.107.107 0 01-.037.15l-1.523.879a.106.106 0 01-.053.014m-9.9-15.761a.107.107 0 01.09.049l9.843 15.462 1.338-.773L42.524 4.479l-1.411.815a.093.093 0 01-.021.017L21.246 16.774l.051 1.245L41.804 6.407a.107.107 0 01.053-.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 60874" d="M19.647 30.942l5.457-3.151v-9.437l-5.465 3.133z" fill="#d0dadd" />
            <path
              data-name="Path 60875"
              d="M19.647 31.045a.107.107 0 01-.107-.107l-.009-9.452a.107.107 0 01.053-.093l5.462-3.135a.107.107 0 01.16.093v9.437a.107.107 0 01-.053.092l-5.457 3.151a.107.107 0 01-.053.014m.1-9.5l.005 9.206 5.243-3.027v-9.19z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60876" d="M25.104 27.788v-9.445l-.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60877"
              d="M24.195 28.419a.107.107 0 01-.107-.107v-9.45a.107.107 0 01.054-.093l.909-.519a.107.107 0 01.16.093v9.445a.107.107 0 01-.053.092l-.91.524a.106.106 0 01-.053.014m.107-9.494v9.2l.7-.4v-9.2zm.8 8.863z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60878" d="M20.561 31.984l5.453-3.151v-.525l-5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 60879"
              d="M20.56 32.095a.106.106 0 01-.107-.107v-.527a.106.106 0 01.053-.092l5.453-3.149a.107.107 0 01.16.092v.525a.107.107 0 01-.053.092l-5.453 3.151a.107.107 0 01-.053.014m.107-.572v.281l5.24-3.028v-.278z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60880" d="M20.559 31.989v-.527l-.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60881"
              d="M20.56 32.096a.107.107 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-.526a.107.107 0 01.16-.093l.912.523a.107.107 0 01.054.093v.527a.107.107 0 01-.107.107m-.8-.693l.7.4v-.281l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60882" d="M20.56 31.461l5.453-3.149-1.818-1.05-4.556 2.631.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60883"
              d="M20.559 31.569a.106.106 0 01-.053-.014l-.912-.523a.107.107 0 01-.054-.092l-.008-1.046a.107.107 0 01.053-.093l4.553-2.63a.107.107 0 01.107 0l1.818 1.05a.107.107 0 010 .185l-5.453 3.149a.107.107 0 01-.053.014m-.806-.692l.806.462 5.24-3.026-1.6-.926-4.446 2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1087" fill="#fff" d="M22.006 20.157v8.399" />
            <path
              data-name="Path 60884"
              d="M22.006 28.664a.107.107 0 01-.107-.107v-8.4a.107.107 0 11.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1088" fill="#ffc200" d="M24.194 23.077l-4.552 2.613" />
            <path
              data-name="Path 60885"
              d="M19.642 25.797a.107.107 0 01-.053-.2l4.552-2.613a.107.107 0 01.106.185l-4.552 2.618a.105.105 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 60886" d="M32.201 23.804l-5.23-3.02 4.838-2.793 5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60887"
              d="M32.203 23.911a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 010-.185l4.838-2.793a.107.107 0 01.107 0l5.23 3.02a.107.107 0 010 .185l-4.838 2.793a.106.106 0 01-.053.014m-5.017-3.126l5.017 2.9 4.624-2.67-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60888" d="M32.201 38.46l-5.23-3.02V20.781l5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60889"
              d="M32.203 38.566a.107.107 0 01-.053-.014l-5.23-3.02a.106.106 0 01-.053-.092V20.784a.107.107 0 01.16-.093l5.23 3.02a.107.107 0 01.053.092v14.656a.107.107 0 01-.107.107m-5.123-3.188l5.017 2.9V23.865l-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60890" d="M37.037 35.666l-4.838 2.793V23.804l4.838-2.793z" fill="#ffc200" />
            <path
              data-name="Path 60891"
              d="M32.203 38.566a.106.106 0 01-.107-.107V23.804a.107.107 0 01.053-.092l4.838-2.793a.107.107 0 01.16.093v14.656a.107.107 0 01-.053.092l-4.838 2.793a.107.107 0 01-.053.014m.107-14.7v14.409l4.624-2.67V21.195zm4.731 11.8z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1089" fill="#ffc200" d="M26.972 30.464l5.23 3.02" />
            <path
              data-name="Path 60892"
              d="M32.202 33.59a.107.107 0 01-.053-.014l-5.23-3.02a.107.107 0 11.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1090" fill="#ffc200" d="M27.467 20.496l5.23 3.02" />
            <path
              data-name="Path 60893"
              d="M32.696 23.623a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 11.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Path 60894" d="M29.907 33.341l.35-.2v-.372l-.35.2z" fill="#fff" />
            <path
              data-name="Path 60895"
              d="M29.907 33.394a.053.053 0 01-.053-.053v-.371a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.372a.054.054 0 01-.027.046l-.35.2a.052.052 0 01-.027.007m.054-.395v.248l.243-.141v-.248z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60896"
              d="M30.057 32.65l-.16.093-.811-.471.157-.091-.194-.112-.35.2 1.208.7.35-.2z"
              fill="#fff"
            />
            <path
              data-name="Path 60897"
              d="M29.908 33.022a.054.054 0 01-.027-.007l-1.209-.7a.053.053 0 010-.092l.35-.2a.053.053 0 01.054 0l.194.112a.053.053 0 010 .092l-.078.045.7.41.133-.077a.053.053 0 01.053 0l.2.116a.053.053 0 010 .092l-.35.2a.053.053 0 01-.027.007m-1.1-.751l1.1.636.243-.141-.094-.054-.133.077a.053.053 0 01-.054 0l-.811-.471a.053.053 0 010-.092l.077-.045-.087-.05z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60898" d="M28.7 32.271v.372l1.208.7v-.372z" fill="#fff" />
            <path
              data-name="Path 60899"
              d="M29.903 33.394a.054.054 0 01-.027-.007l-1.208-.7a.053.053 0 01-.027-.046v-.372a.053.053 0 01.08-.046l1.209.7a.054.054 0 01.027.046v.372a.053.053 0 01-.027.046.052.052 0 01-.027.007m-1.155-.782l1.1.636v-.249l-1.1-.636z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1091" fill="#fff" d="M29.243 32.18v.183" />
            <path
              data-name="Path 60900"
              d="M29.245 32.416a.053.053 0 01-.053-.053v-.183a.054.054 0 01.107 0v.183a.053.053 0 01-.053.053"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60901"
              d="M31.617 29.661l.008-2.164.127-.074v-.333l-.35.2-.008 2.779.35-.2v-.283z"
              fill="#fff"
            />
            <path
              data-name="Path 60902"
              d="M31.395 30.125a.053.053 0 01-.053-.053l.008-2.779a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.333a.053.053 0 01-.027.046l-.1.058-.007 2.04.048-.028a.053.053 0 01.08.046v.283a.053.053 0 01-.027.046l-.35.2a.052.052 0 01-.027.007m.061-2.8l-.007 2.655.243-.141v-.159l-.048.028a.053.053 0 01-.08-.046l.008-2.164a.053.053 0 01.027-.046l.1-.059v-.209z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60903" d="M31.403 27.294l.35-.2-.36-.208-.35.2z" fill="#fff" />
            <path
              data-name="Path 60904"
              d="M31.403 27.347a.054.054 0 01-.027-.007l-.36-.208a.053.053 0 010-.092l.35-.2a.053.053 0 01.054 0l.36.208a.053.053 0 010 .092l-.35.2a.053.053 0 01-.027.007m-.253-.261l.253.146.243-.141-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60905" d="M31.043 27.086l-.008 2.779.36.208.008-2.779z" fill="#fff" />
            <path
              data-name="Path 60906"
              d="M31.395 30.125a.054.054 0 01-.027-.007l-.36-.208a.053.053 0 01-.027-.046l.008-2.779a.053.053 0 01.08-.046l.36.208a.054.054 0 01.027.046l-.008 2.779a.053.053 0 01-.053.053m-.306-.292l.253.146.007-2.656-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1092" fill="#fff" d="M31.617 29.512l.129.074" />
            <path
              data-name="Path 60907"
              d="M31.746 29.639a.053.053 0 01-.027-.007l-.129-.074a.053.053 0 01.053-.092l.129.074a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path data-name="Line 1093" fill="#ffc200" d="M32.697 38.172V23.516" />
            <path
              data-name="Path 60908"
              d="M32.692 38.278a.107.107 0 01-.107-.107V23.519a.107.107 0 11.213 0v14.656a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1094" fill="#ffc200" d="M32.202 33.484l.495-.286" />
            <path
              data-name="Path 60909"
              d="M32.203 33.59a.107.107 0 01-.053-.2l.495-.286a.107.107 0 11.107.185l-.495.286a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60910"
              d="M40.003 42.44h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60911"
              d="M40.003 42.546h-.005a.339.339 0 01-.338-.339v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60912"
              d="M43.963 40.204h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60913"
              d="M43.963 40.31h-.005a.339.339 0 01-.338-.339V38.54a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125V38.54a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60914"
              d="M32.966 38.429h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60915"
              d="M32.966 38.536h-.005a.339.339 0 01-.338-.338v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.338m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path data-name="Path 60916" d="M44.318 39.44l-4.356 2.515v-6.621l4.356-2.515z" fill="#fff" />
            <path
              data-name="Path 60917"
              d="M39.963 42.062a.107.107 0 01-.107-.107v-6.621a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v6.622a.107.107 0 01-.053.092l-4.356 2.515a.106.106 0 01-.053.014m.107-6.667v6.375l4.142-2.392v-6.375zm4.249 4.045z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60918" d="M39.96 41.955l-3.616-2.1v-6.622l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60919"
              d="M39.963 42.062a.107.107 0 01-.054-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.621a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.621a.107.107 0 01-.107.107m-3.509-2.266l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60920"
              d="M37.555 35.153a.548.548 0 01-.273-.073l-.31-.178a.263.263 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.556.556 0 01-.279.075"
              fill="#1d1d1b"
            />
            <path data-name="Path 60921" d="M36.338 39.85l-3.616-2.1v-6.621l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60922"
              d="M36.337 39.957a.107.107 0 01-.054-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.622a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.622a.107.107 0 01-.107.107m-3.508-2.263l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1095" fill="#fff" d="M32.729 33.236l3.617 2.089" />
            <path
              data-name="Path 60923"
              d="M36.346 35.431a.106.106 0 01-.053-.014l-3.617-2.089a.107.107 0 01.107-.185l3.617 2.089a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1096" fill="#fff" d="M32.729 35.574l3.617 2.089" />
            <path
              data-name="Path 60924"
              d="M36.346 37.77a.107.107 0 01-.053-.014l-3.617-2.089a.107.107 0 01.107-.185l3.617 2.089a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60925"
              d="M34.694 33.533a.547.547 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.555.555 0 01-.279.075"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60926"
              d="M34.695 35.703a.546.546 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.309.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.555.555 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60927"
              d="M34.694 37.873a.547.547 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.555.555 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path data-name="Path 60928" d="M44.983 38.3l-4.709-2.719 4.356-2.515 4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60929"
              d="M44.983 38.408a.106.106 0 01-.053-.014l-4.709-2.719a.107.107 0 010-.185l4.356-2.515a.107.107 0 01.107 0l4.709 2.719a.107.107 0 010 .185l-4.356 2.515a.105.105 0 01-.053.014m-4.5-2.825l4.5 2.6 4.142-2.392-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60930" d="M44.983 45.301l-4.709-2.719v-7.22l4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60931"
              d="M44.983 45.408a.107.107 0 01-.053-.014l-4.709-2.719a.106.106 0 01-.053-.092v-7.22a.107.107 0 01.16-.092l4.709 2.718a.107.107 0 01.053.092v7.22a.107.107 0 01-.107.107m-4.6-2.887l4.5 2.6v-6.974l-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60932" d="M49.339 42.787l-4.352 2.515v-7l4.356-2.515z" fill="#ffc200" />
            <path
              data-name="Path 60933"
              d="M44.98 45.408a.107.107 0 01-.107-.107v-7a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v7a.107.107 0 01-.053.092l-4.356 2.515a.107.107 0 01-.053.014m.107-7.046v6.754l4.142-2.392v-6.754zm4.249 4.424z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60934"
              d="M42.707 38.644a1.352 1.352 0 00-1.309-.16l-.337.19a1.31 1.31 0 011.271.145 4.036 4.036 0 011.831 3.159 1.328 1.328 0 01-.5 1.157l.464-.254a1.239 1.239 0 00.4-1.023 4.117 4.117 0 00-1.814-3.214"
              fill="#ffc200"
            />
            <path
              data-name="Path 60935"
              d="M43.659 43.242a.107.107 0 01-.059-.2 1.233 1.233 0 00.456-1.068 3.923 3.923 0 00-1.778-3.066 1.213 1.213 0 00-1.167-.143.107.107 0 01-.1-.188l.337-.19a1.453 1.453 0 011.415.16 4.2 4.2 0 011.867 3.306 1.317 1.317 0 01-.44 1.108l-.013.009-.464.254a.106.106 0 01-.051.013m.464-.361zm-2.356-4.4a2 2 0 01.618.241 4.16 4.16 0 011.885 3.251 1.831 1.831 0 01-.154.768 1.19 1.19 0 00.3-.888 4.028 4.028 0 00-1.761-3.121 1.594 1.594 0 00-.887-.251"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60936"
              d="M44.163 41.976c0 1.161-.82 1.628-1.831 1.044a4.036 4.036 0 01-1.831-3.159c0-1.161.82-1.628 1.831-1.044a4.036 4.036 0 011.831 3.159"
              fill="#ffc200"
            />
            <path
              data-name="Path 60937"
              d="M43.153 43.381a1.779 1.779 0 01-.876-.267 4.16 4.16 0 01-1.885-3.251 1.374 1.374 0 01.586-1.266 1.392 1.392 0 011.406.13 4.16 4.16 0 011.885 3.251 1.374 1.374 0 01-.586 1.266 1.047 1.047 0 01-.53.137m-1.644-4.708a.837.837 0 00-.424.108 1.175 1.175 0 00-.479 1.082 3.923 3.923 0 001.778 3.067 1.193 1.193 0 001.192.13 1.175 1.175 0 00.479-1.082 3.923 3.923 0 00-1.778-3.067 1.569 1.569 0 00-.768-.238"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60938"
              d="M43.68 41.699c0 .855-.6 1.2-1.349.769a2.973 2.973 0 01-1.349-2.327c0-.855.6-1.2 1.349-.769a2.972 2.972 0 011.349 2.327"
              fill="#fff"
            />
            <path
              data-name="Path 60939"
              d="M42.937 42.761a1.337 1.337 0 01-.659-.2 3.1 3.1 0 01-1.4-2.419 1.039 1.039 0 01.445-.957 1.051 1.051 0 011.064.1 3.1 3.1 0 011.4 2.419 1.039 1.039 0 01-.446.957.8.8 0 01-.4.1m-1.211-3.468a.589.589 0 00-.3.076.839.839 0 00-.339.772 2.858 2.858 0 001.3 2.234.853.853 0 00.85.1.839.839 0 00.339-.772 2.859 2.859 0 00-1.3-2.234 1.129 1.129 0 00-.552-.171"
              fill="#1d1d1b"
            />
            <path data-name="Path 60940" d="M42.111 37.885l-1.613-.931v-.933l1.613.931z" fill="#ffc200" />
            <path
              data-name="Path 60941"
              d="M42.11 37.939a.054.054 0 01-.027-.007l-1.613-.931a.053.053 0 01-.027-.046v-.933a.053.053 0 01.08-.046l1.613.931a.053.053 0 01.027.046v.933a.053.053 0 01-.053.053m-1.559-1.015l1.506.87v-.809l-1.506-.869z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60942"
              d="M42.733 37.424a.189.189 0 00-.191-.019l-.117.068a.189.189 0 01.191.019.588.588 0 01.267.463.187.187 0 01-.078.172l.117-.068a.187.187 0 00.078-.172.588.588 0 00-.267-.463"
              fill="#fff"
            />
            <path
              data-name="Path 60943"
              d="M42.832 38.173l-.054-.092a.14.14 0 00.051-.126.539.539 0 00-.24-.416.142.142 0 00-.137-.019l-.054-.092.116-.068a.239.239 0 01.244.019.639.639 0 01.294.509.238.238 0 01-.1.218zm-.192-.729a.639.639 0 01.294.509.223.223 0 00.01-.069.538.538 0 00-.24-.416.224.224 0 00-.067-.027"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60944"
              d="M42.616 37.493a.589.589 0 01.267.463c0 .17-.121.238-.269.153a.589.589 0 01-.267-.462c0-.17.121-.239.269-.153"
              fill="#fff"
            />
            <path
              data-name="Path 60945"
              d="M42.735 38.198a.3.3 0 01-.147-.044.639.639 0 01-.294-.509.216.216 0 01.349-.2.639.639 0 01.294.509.238.238 0 01-.105.218.191.191 0 01-.1.025m-.239-.689a.086.086 0 00-.043.011.14.14 0 00-.052.126.539.539 0 00.241.416.142.142 0 00.137.019.139.139 0 00.052-.126.538.538 0 00-.24-.416.193.193 0 00-.094-.029"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60946"
              d="M43.389 37.779a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60947"
              d="M43.433 38.076a.144.144 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.041.041 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.038.038 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60948"
              d="M43.767 37.986a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60949"
              d="M43.812 38.284a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.036.036 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60950"
              d="M44.145 38.215a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60951"
              d="M44.19 38.512a.146.146 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.044.044 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.037.037 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60952"
              d="M43.389 38.199a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60953"
              d="M43.433 38.493a.145.145 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.092.092 0 000-.018.169.169 0 00-.073-.126.039.039 0 00-.019-.007"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60954"
              d="M43.767 38.402a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60955"
              d="M43.812 38.699a.146.146 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.168.168 0 00-.073-.126.037.037 0 00-.018-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60956"
              d="M44.145 38.631a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60957"
              d="M44.19 38.928a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.168.168 0 00-.073-.126.035.035 0 00-.018-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60958"
              d="M44.53 38.453a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60959"
              d="M44.574 38.751a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.094.094 0 000-.018.168.168 0 00-.073-.126.035.035 0 00-.018-.011z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60960"
              d="M44.53 38.867a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60961"
              d="M44.574 39.166a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.094.094 0 000-.018.169.169 0 00-.073-.126.037.037 0 00-.018-.006z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60962" d="M44.984 37.713l-12.487-7.239 4.548-2.626 12.487 7.239z" fill="#fff" />
            <path
              data-name="Path 60963"
              d="M44.984 37.819a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 010-.185l4.548-2.626a.106.106 0 01.107 0l12.487 7.239a.107.107 0 010 .185l-4.548 2.626a.107.107 0 01-.053.014M32.71 30.474l12.274 7.115 4.335-2.5-12.274-7.115z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60964" d="M32.497 31.011v-.538l12.487 7.239v.538z" fill="#fff" />
            <path
              data-name="Path 60965"
              d="M44.984 38.357a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 01-.053-.092v-.538a.107.107 0 01.16-.092l12.487 7.239a.107.107 0 01.053.092v.538a.107.107 0 01-.107.107m-12.38-7.407l12.274 7.115v-.291L32.604 30.66z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60966" d="M49.533 35.086v.538l-4.549 2.626v-.538z" fill="#fff" />
            <path
              data-name="Path 60967"
              d="M44.984 38.357a.107.107 0 01-.107-.107v-.538a.106.106 0 01.053-.092l4.546-2.626a.107.107 0 01.16.092v.538a.107.107 0 01-.053.092l-4.548 2.626a.106.106 0 01-.053.014m.107-.583v.291l4.335-2.5v-.291z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60968" d="M44.319 30.942l-5.457-3.151v-9.437l5.462 3.135z" fill="#d0dadd" />
            <path
              data-name="Path 60969"
              d="M44.319 31.046a.106.106 0 01-.053-.014l-5.457-3.151a.106.106 0 01-.053-.092v-9.437a.107.107 0 01.16-.093l5.462 3.135a.107.107 0 01.054.093l-.005 9.453a.107.107 0 01-.107.107m-5.35-3.319l5.243 3.027.005-9.206-5.249-3.013z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60970" d="M38.862 27.788v-9.445l.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60971"
              d="M39.772 28.419a.107.107 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-9.445a.107.107 0 01.16-.093l.909.519a.106.106 0 01.054.093v9.45a.107.107 0 01-.107.107m-.8-.693l.7.4v-9.2l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60972" d="M43.41 31.984l-5.453-3.151v-.525l5.453 3.153z" fill="#fff" />
            <path
              data-name="Path 60973"
              d="M43.403 32.095a.108.108 0 01-.053-.014l-5.453-3.151a.107.107 0 01-.053-.092v-.525a.107.107 0 01.16-.092l5.453 3.149a.106.106 0 01.053.092v.527a.107.107 0 01-.107.107m-5.346-3.319l5.24 3.028v-.281l-5.24-3.025z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60974" d="M43.407 31.989v-.527l.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60975"
              d="M43.407 32.096a.107.107 0 01-.107-.107v-.527a.107.107 0 01.054-.093l.912-.523a.107.107 0 01.16.093v.526a.107.107 0 01-.053.092l-.91.524a.107.107 0 01-.053.014m.107-.572v.281l.7-.4v-.279z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60976" d="M43.41 31.461l-5.453-3.149 1.818-1.05 4.553 2.63-.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60977"
              d="M43.403 31.568a.107.107 0 01-.053-.014l-5.453-3.149a.107.107 0 010-.185l1.818-1.05a.107.107 0 01.107 0l4.553 2.63a.107.107 0 01.053.093l-.005 1.045a.107.107 0 01-.054.092l-.912.523a.106.106 0 01-.053.014m-5.24-3.256l5.24 3.026.806-.462v-.922l-4.446-2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1097" fill="#fff" d="M41.961 20.157v8.399" />
            <path
              data-name="Path 60978"
              d="M41.961 28.664a.107.107 0 01-.107-.107v-8.4a.107.107 0 01.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1098" fill="#ffc200" d="M39.772 23.077l4.552 2.613" />
            <path
              data-name="Path 60979"
              d="M44.324 25.797a.105.105 0 01-.053-.014l-4.552-2.613a.107.107 0 01.106-.185l4.552 2.613a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60980"
              d="M50.008 43.43a2.591 2.591 0 01-2.351-.024.91.91 0 01-.454-.536l.421 1.721a.675.675 0 00.337.4 1.921 1.921 0 001.743.018.689.689 0 00.361-.408l.43-1.718a.929.929 0 01-.486.551"
              fill="#fff"
            />
            <path
              data-name="Path 60981"
              d="M48.848 45.314a1.873 1.873 0 01-.942-.235.777.777 0 01-.385-.464l-.421-1.721.207-.051a.809.809 0 00.4.469 2.477 2.477 0 002.244.022.837.837 0 00.435-.483l.207.052-.43 1.718a.789.789 0 01-.412.475 1.886 1.886 0 01-.908.216m-1.409-1.929l.288 1.179a.573.573 0 00.288.331 1.806 1.806 0 001.636.016.6.6 0 00.309-.341l.295-1.178a1.538 1.538 0 01-.2.129 2.713 2.713 0 01-2.458-.025 1.511 1.511 0 01-.163-.112"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60982"
              d="M50.072 42.845a2.735 2.735 0 01-2.482-.025c-.676-.4-.66-1.048.035-1.438a2.736 2.736 0 012.482.025c.676.4.66 1.048-.035 1.438"
              fill="#fff"
            />
            <path
              data-name="Path 60983"
              d="M48.855 43.235a2.632 2.632 0 01-1.32-.324.964.964 0 01-.549-.811.987.987 0 01.586-.812 2.857 2.857 0 012.589.027.964.964 0 01.549.811.988.988 0 01-.586.812 2.649 2.649 0 01-1.269.3m-.012-2.033a2.433 2.433 0 00-1.166.273.788.788 0 00-.477.629.764.764 0 00.445.625 2.621 2.621 0 002.375.024.788.788 0 00.477-.629.764.764 0 00-.445-.625 2.412 2.412 0 00-1.21-.3"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60984"
              d="M49.831 42.578a2.5 2.5 0 01-2-.018c-.544-.283-.531-.733.028-1.006a2.5 2.5 0 012 .017c.544.283.531.733-.028 1.006"
              fill="#fff"
            />
            <path
              data-name="Path 60985"
              d="M48.853 42.884a2.387 2.387 0 01-1.067-.228.707.707 0 01-.457-.6.729.729 0 01.488-.6 2.62 2.62 0 012.093.019.707.707 0 01.457.6.729.729 0 01-.488.6 2.406 2.406 0 01-1.026.209m-.01-1.422a2.188 2.188 0 00-.933.19c-.232.113-.366.263-.368.41s.123.291.342.4a2.379 2.379 0 001.9.016c.232-.113.366-.263.368-.41s-.123-.291-.342-.4a2.163 2.163 0 00-.968-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60986"
              d="M50.12 42.293a.693.693 0 00-.31-.274 2.38 2.38 0 00-1.9-.016.713.713 0 00-.336.286l-.191-.1a.917.917 0 01.433-.381 2.62 2.62 0 012.093.019.9.9 0 01.4.367z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60987"
              d="M50.073 42.844a2.735 2.735 0 01-2.482-.025.869.869 0 01-.5-.706v.591a.859.859 0 00.5.724 2.735 2.735 0 002.482.025.881.881 0 00.532-.731v-.608a.881.881 0 01-.532.731"
              fill="#fff"
            />
            <path
              data-name="Path 60988"
              d="M48.856 43.844a2.632 2.632 0 01-1.32-.324.96.96 0 01-.548-.818v-.593h.214a.771.771 0 00.445.615 2.621 2.621 0 002.375.024.785.785 0 00.477-.632v-.007h.214v.609a.979.979 0 01-.586.825 2.649 2.649 0 01-1.27.3m-1.655-1.2v.065a.76.76 0 00.444.633 2.621 2.621 0 002.375.024.78.78 0 00.477-.638v-.077a1.407 1.407 0 01-.373.293 2.857 2.857 0 01-2.59-.027 1.382 1.382 0 01-.335-.273"
              fill="#1d1d1b"
            />
            <path data-name="Line 1099" fill="#fff" d="M48.846 39.025v-2.31" />
            <path
              data-name="Path 60989"
              d="M48.846 39.158a.133.133 0 01-.133-.133v-2.31a.134.134 0 11.267 0v2.31a.133.133 0 01-.133.133"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60990"
              d="M48.846 42.492a.16.16 0 01-.16-.16v-3.207a.16.16 0 11.32 0v3.207a.16.16 0 01-.16.16"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60991"
              d="M51.175 41.168c-.744.507-1.585.567-1.879.136s.071-1.192.815-1.7a1.777 1.777 0 011.66-.327 2.849 2.849 0 00.385.118s-.116.16-.1.207c.153.442-.21 1.107-.882 1.564"
              fill="#fff"
            />
            <path
              data-name="Path 60992"
              d="M49.96 41.699a.864.864 0 01-.752-.334.914.914 0 01.01-.927 2.441 2.441 0 01.833-.919 1.873 1.873 0 011.769-.333 2.57 2.57 0 00.361.109.107.107 0 01.061.166.773.773 0 00-.078.131 1.721 1.721 0 01-.929 1.666 2.441 2.441 0 01-1.16.438h-.114m1.365-2.41a2.165 2.165 0 00-1.154.405 2.225 2.225 0 00-.76.835.6.6 0 00.648.952 2.225 2.225 0 001.056-.4c.618-.421.979-1.04.841-1.441-.008-.022-.023-.067.032-.175a1.659 1.659 0 01-.266-.09.858.858 0 00-.4-.085m.832.28"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60993"
              d="M48.824 41.84a.107.107 0 01-.092-.161 8.942 8.942 0 012.045-1.631.107.107 0 01.091.193 9.138 9.138 0 00-1.953 1.547.107.107 0 01-.092.052"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60994"
              d="M46.353 40.641c.779.53 1.66.594 1.967.142s-.074-1.248-.853-1.778a1.861 1.861 0 00-1.738-.342 2.972 2.972 0 01-.4.123s.121.167.1.217c-.16.463.22 1.159.923 1.638"
              fill="#fff"
            />
            <path
              data-name="Path 60995"
              d="M47.618 41.194a2.456 2.456 0 01-1.329-.465c-.728-.5-1.132-1.224-.971-1.741a.793.793 0 00-.083-.14.107.107 0 01.061-.167 2.691 2.691 0 00.379-.114 1.957 1.957 0 011.848.349 2.55 2.55 0 01.87.961.952.952 0 01.011.966.908.908 0 01-.787.35m-2.128-2.341c.061.116.044.164.037.185-.146.422.234 1.073.883 1.515.717.488 1.549.566 1.819.17a.752.752 0 00-.027-.753 2.336 2.336 0 00-.8-.877 1.778 1.778 0 00-1.629-.336 1.784 1.784 0 01-.284.1m-.165.115z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60996"
              d="M48.812 41.34a.107.107 0 01-.092-.052 9.577 9.577 0 00-2.047-1.622.107.107 0 11.091-.193 9.352 9.352 0 012.14 1.706.107.107 0 01-.092.161"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60997"
              d="M51.029 39.525c-.789.278-1.528.108-1.651-.38s.417-1.109 1.206-1.387a1.478 1.478 0 011.52.139 2.383 2.383 0 00.3.216s-.147.12-.147.169c0 .46-.511.991-1.223 1.242"
              fill="#fff"
            />
            <path
              data-name="Path 60998"
              d="M50.291 39.761a1.371 1.371 0 01-.646-.144.707.707 0 01-.371-.451c-.138-.548.421-1.213 1.273-1.514a1.574 1.574 0 011.634.168 2.149 2.149 0 00.274.2.107.107 0 01.01.173.757.757 0 00-.108.106c-.014.509-.555 1.063-1.294 1.323a2.348 2.348 0 01-.772.14m1.029-2.034a2.144 2.144 0 00-.7.127c-.724.256-1.245.833-1.137 1.26a.494.494 0 00.265.315 1.64 1.64 0 001.247-.01c.655-.231 1.151-.722 1.152-1.142 0-.021 0-.067.081-.155a1.389 1.389 0 01-.2-.158.95.95 0 00-.7-.238"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60999"
              d="M48.844 39.53a.107.107 0 01-.074-.183 8.256 8.256 0 012.244-.992.107.107 0 01.037.21 8.4 8.4 0 00-2.132.934.106.106 0 01-.075.03"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61000"
              d="M46.888 39.047c.707.249 1.369.1 1.479-.34s-.373-.993-1.08-1.243a1.324 1.324 0 00-1.362.125 2.128 2.128 0 01-.264.193s.132.107.132.152c0 .412.457.888 1.1 1.113"
              fill="#fff"
            />
            <path
              data-name="Path 61001"
              d="M47.547 39.274a2.112 2.112 0 01-.7-.126c-.665-.234-1.151-.733-1.167-1.192a.707.707 0 00-.093-.09.107.107 0 01.01-.173 1.909 1.909 0 00.243-.176 1.421 1.421 0 011.476-.153c.769.271 1.273.873 1.148 1.369a.644.644 0 01-.338.411 1.24 1.24 0 01-.585.13m-1.716-1.477c.067.075.067.116.067.137 0 .371.442.807 1.025 1.012a1.459 1.459 0 001.108.01.432.432 0 00.232-.275c.1-.378-.368-.889-1.012-1.116a1.238 1.238 0 00-1.248.1 1.2 1.2 0 01-.171.136"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61002"
              d="M48.845 39.063a.106.106 0 01-.074-.03 7.507 7.507 0 00-1.9-.834.107.107 0 11.037-.21 7.417 7.417 0 012.016.891.107.107 0 01-.075.183"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61003"
              d="M50.712 37.766c-.579.474-1.253.556-1.505.183s.013-1.061.593-1.535a1.323 1.323 0 011.323-.346 2.124 2.124 0 00.314.092s-.087.146-.072.187a1.472 1.472 0 01-.652 1.418"
              fill="#fff"
            />
            <path
              data-name="Path 61004"
              d="M49.719 38.291a.969.969 0 01-.139-.01.644.644 0 01-.457-.271c-.287-.424-.017-1.161.613-1.677a1.421 1.421 0 011.44-.357 1.924 1.924 0 00.288.082.107.107 0 01.068.159.714.714 0 00-.057.116 1.587 1.587 0 01-.691 1.517 1.769 1.769 0 01-1.065.441m1.084-2.187a1.577 1.577 0 00-.932.394c-.528.432-.79 1.07-.572 1.393a.431.431 0 00.311.18 1.455 1.455 0 001.038-.386 1.383 1.383 0 00.619-1.3.192.192 0 01.017-.151 1.187 1.187 0 01-.207-.069.59.59 0 00-.274-.06m.665.209"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61005"
              d="M48.846 38.454a.107.107 0 01-.1-.153 7.414 7.414 0 011.592-1.523.107.107 0 01.106.185 7.5 7.5 0 00-1.506 1.431.107.107 0 01-.1.06"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61006"
              d="M47.091 36.86c.533.437 1.154.512 1.386.169s-.012-.977-.546-1.413a1.218 1.218 0 00-1.218-.318 1.96 1.96 0 01-.289.085s.081.134.067.172a1.355 1.355 0 00.6 1.306"
              fill="#fff"
            />
            <path
              data-name="Path 61007"
              d="M48.009 37.352a1.637 1.637 0 01-.987-.408 1.472 1.472 0 01-.64-1.4.669.669 0 00-.051-.1.107.107 0 01.068-.159 1.756 1.756 0 00.263-.075 1.316 1.316 0 011.335.33c.584.478.833 1.161.566 1.556a.6.6 0 01-.427.254.889.889 0 01-.129.009m-.851-.574a1.333 1.333 0 00.949.353.389.389 0 00.281-.162c.2-.294-.042-.876-.525-1.271a1.132 1.132 0 00-1.1-.306 1.066 1.066 0 01-.183.062.176.176 0 01.012.136 1.265 1.265 0 00.567 1.187"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61008"
              d="M48.812 37.501a.107.107 0 01-.1-.06 6.891 6.891 0 00-1.383-1.314.107.107 0 11.106-.185 6.84 6.84 0 011.469 1.406.107.107 0 01-.1.153"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61009"
              d="M50.434 35.39c-.43.539-1.022.743-1.321.456s-.193-.958.237-1.5a1.219 1.219 0 011.125-.567 1.957 1.957 0 00.3.022s-.051.148-.029.183a1.356 1.356 0 01-.313 1.4"
              fill="#fff"
            />
            <path
              data-name="Path 61010"
              d="M49.459 36.083a.589.589 0 01-.419-.16c-.343-.33-.243-1.05.228-1.641a1.317 1.317 0 011.236-.6 1.754 1.754 0 00.273.018.107.107 0 01.1.141.657.657 0 00-.028.111 1.473 1.473 0 01-.332 1.507 1.525 1.525 0 01-1.007.625h-.051m.865-2.214a1.291 1.291 0 00-.889.546c-.389.488-.5 1.108-.247 1.354a.39.39 0 00.309.1 1.335 1.335 0 00.855-.545 1.266 1.266 0 00.306-1.28.176.176 0 01-.017-.136 1.068 1.068 0 01-.192-.022.459.459 0 00-.123-.016m.514.061"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61011"
              d="M48.847 36.976a.107.107 0 01-.107-.106 3.9 3.9 0 011.161-2.306.107.107 0 01.143.159 3.713 3.713 0 00-1.09 2.146.107.107 0 01-.106.107z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61012"
              d="M49.645 39.488c-.1.318-.6.452-1.191.344a.924.924 0 00-.341.112s-.138-.249-.227-.289c-.54-.243-.876-.637-.778-.953.213-.687 1.056-.04 1.372.058.363.113 1.377.045 1.165.728"
              fill="#fff"
            />
            <path
              data-name="Path 61013"
              d="M48.114 40.05a.107.107 0 01-.094-.055.922.922 0 00-.178-.244c-.6-.272-.948-.717-.835-1.081a.517.517 0 01.284-.348c.29-.123.666.067.968.22a2.065 2.065 0 00.254.116 2.3 2.3 0 00.309.056c.325.045.729.1.89.357a.521.521 0 01.035.449c-.114.368-.654.539-1.312.417a.92.92 0 00-.272.1.107.107 0 01-.049.012m-.617-1.555a.275.275 0 00-.286.237c-.078.25.232.6.72.824a.688.688 0 01.225.248.656.656 0 01.319-.078c.531.1.991-.018 1.069-.27a.317.317 0 00-.011-.271c-.109-.171-.459-.221-.74-.26a2.4 2.4 0 01-.343-.064 2.107 2.107 0 01-.287-.13 1.768 1.768 0 00-.666-.236m2.149.992z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61014"
              d="M48.289 39.306h-.008a.107.107 0 01-.1-.114.936.936 0 01.631-.719.107.107 0 01.063.2.736.736 0 00-.481.531.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61015"
              d="M49.597 36.916c-.078.253-.48.36-.947.273a.735.735 0 00-.271.089s-.11-.2-.181-.23c-.43-.193-.7-.507-.619-.758.169-.546.84-.032 1.092.047.289.09 1.1.036.927.58"
              fill="#fff"
            />
            <path
              data-name="Path 61016"
              d="M48.38 37.385a.107.107 0 01-.094-.055.763.763 0 00-.134-.186c-.489-.22-.768-.585-.674-.886a.432.432 0 01.238-.29c.24-.1.544.052.789.176a1.657 1.657 0 00.2.091 1.815 1.815 0 00.243.044c.263.037.59.083.724.294a.435.435 0 01.03.375c-.094.3-.534.446-1.069.347a.811.811 0 00-.2.079.106.106 0 01-.049.012m-.491-1.237a.2.2 0 00-.207.173c-.059.189.183.459.561.629a.526.526 0 01.179.189.5.5 0 01.249-.056c.412.076.767-.01.826-.2a.231.231 0 00-.007-.2c-.081-.128-.366-.168-.573-.2a1.9 1.9 0 01-.276-.051 1.7 1.7 0 01-.231-.1 1.4 1.4 0 00-.52-.186m1.709.768z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61017"
              d="M48.517 36.793h-.007a.107.107 0 01-.1-.114.768.768 0 01.517-.591.107.107 0 11.063.2.57.57 0 00-.367.4.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61018"
              d="M15.611 42.967h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61019"
              d="M15.611 43.072h-.033a.306.306 0 01-.306-.306v-1.349a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.355a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.348a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61020"
              d="M25.853 39.987h-.035a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61021"
              d="M25.852 40.095h-.033a.306.306 0 01-.306-.306V38.44a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.349a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61022"
              d="M18.198 44.433h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61023"
              d="M18.192 44.541h-.033a.306.306 0 01-.306-.306v-1.349a.307.307 0 01.306-.306h.033a.307.307 0 01.306.306v1.349a.307.307 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61024" d="M26.166 36.621v2.321l-7.986 4.641v-2.321z" fill="#fff" />
            <path
              data-name="Path 61025"
              d="M18.178 43.689a.107.107 0 01-.107-.107v-2.321a.107.107 0 01.053-.092l7.986-4.641a.107.107 0 01.16.092v2.321a.107.107 0 01-.053.092l-7.986 4.641a.107.107 0 01-.054.014m.108-2.366v2.074l7.773-4.517v-2.073z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61026" d="M18.18 41.262v2.321l-2.926-1.689v-2.321z" fill="#fff" />
            <path
              data-name="Path 61027"
              d="M18.178 43.688a.107.107 0 01-.053-.014l-2.926-1.689a.106.106 0 01-.053-.093v-2.321a.107.107 0 01.16-.092l2.926 1.689a.107.107 0 01.053.093v2.321a.107.107 0 01-.107.107m-2.819-1.858l2.712 1.566v-2.074l-2.706-1.564z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61028" d="M26.166 36.62l-7.986 4.641-2.926-1.689 7.986-4.641z" fill="#fff" />
            <path
              data-name="Path 61029"
              d="M18.179 41.368a.107.107 0 01-.053-.014L15.2 39.665a.107.107 0 010-.185l7.986-4.641a.106.106 0 01.107 0l2.926 1.689a.107.107 0 010 .185l-7.986 4.641a.107.107 0 01-.054.015m-2.713-1.8l2.713 1.566 7.774-4.517-2.712-1.566z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61030"
              d="M20.71 42.223a.107.107 0 01-.107-.107v-2.32a.107.107 0 11.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61031"
              d="M23.524 40.586a.107.107 0 01-.107-.107v-2.32a.107.107 0 11.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Path 61032" d="M18.988 39.887l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61033"
              d="M18.987 40.106a.053.053 0 01-.053-.053v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.053.053 0 01-.027.007m.054-.188v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61034" d="M16.383 38.451l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61035"
              d="M18.988 39.94a.053.053 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.053.053 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61036" d="M18.988 39.887v.166l-2.606-1.436v-.167z" fill="#ffc200" />
            <path
              data-name="Path 61037"
              d="M18.987 40.106a.054.054 0 01-.026-.007l-2.606-1.436a.054.054 0 01-.028-.047v-.166a.053.053 0 01.026-.046.052.052 0 01.053 0l2.606 1.436a.054.054 0 01.028.047v.166a.054.054 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61038" d="M24.749 36.485l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61039"
              d="M24.748 36.702a.054.054 0 01-.027-.007.053.053 0 01-.027-.046v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.055.055 0 01-.027.007m.054-.189v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61040" d="M22.143 35.046l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61041"
              d="M24.749 36.536a.053.053 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.054.054 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61042" d="M24.748 36.482v.166l-2.606-1.436v-.17z" fill="#ffc200" />
            <path
              data-name="Path 61043"
              d="M24.748 36.701a.053.053 0 01-.026-.007l-2.606-1.436a.054.054 0 01-.028-.047v-.166a.053.053 0 01.026-.046.053.053 0 01.053 0l2.606 1.436a.054.054 0 01.028.047v.166a.054.054 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61044"
              d="M17.248 40.154l7.8-4.545a.421.421 0 00.211-.365V29.09a.421.421 0 00-.632-.365l-7.8 4.545a.421.421 0 00-.211.365v6.154a.421.421 0 00.632.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 61045"
              d="M17.037 40.318a.53.53 0 01-.528-.529v-6.154a.53.53 0 01.264-.457l7.8-4.545a.528.528 0 01.793.457v6.154a.53.53 0 01-.264.457l-7.8 4.545a.528.528 0 01-.264.072m.211-.164zm7.593-11.378a.315.315 0 00-.157.043l-7.8 4.545a.316.316 0 00-.158.273v6.154a.315.315 0 00.472.273l7.8-4.545a.315.315 0 00.158-.273v-6.154a.312.312 0 00-.157-.273.315.315 0 00-.157-.043"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61046"
              d="M16.411 33.042l7.8-4.545a.418.418 0 01.455.022l.376.205a.417.417 0 00-.419 0l-7.8 4.545a.422.422 0 00-.211.365v6.154a.416.416 0 00.187.35l-.331-.187a.43.43 0 01-.116-.069.415.415 0 01-.149-.321v-6.154a.421.421 0 01.211-.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 61047"
              d="M16.803 40.245a.106.106 0 01-.052-.014l-.326-.183a.54.54 0 01-.136-.082h-.005a.518.518 0 01-.187-.4v-6.154a.53.53 0 01.264-.457l7.8-4.545a.523.523 0 01.562.022l.359.2.016.009a.107.107 0 01-.1.186l-.013-.007a.311.311 0 00-.3.007l-7.8 4.545a.316.316 0 00-.158.273v6.154a.309.309 0 00.14.261.107.107 0 01-.006.181.109.109 0 01-.054.014m-.379-.444a.32.32 0 00.087.052.546.546 0 010-.065v-6.154a.53.53 0 01.264-.457l7.8-4.545.042-.022h-.007a.312.312 0 00-.34-.017l-7.8 4.545a.315.315 0 00-.157.272v6.154a.308.308 0 00.111.239m-.009-6.759z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61048"
              d="M17.476 39.718l7.31-4.252a.386.386 0 00.193-.334V29.5a.386.386 0 00-.579-.334l-7.31 4.252a.386.386 0 00-.193.334v5.632a.386.386 0 00.578.334"
              fill="#ffc200"
            />
            <path
              data-name="Path 61049"
              d="M17.284 39.823a.441.441 0 01-.439-.44v-5.632a.44.44 0 01.219-.38l7.31-4.252a.439.439 0 01.659.38v5.632a.44.44 0 01-.22.38l-7.31 4.252a.438.438 0 01-.219.06m.193-.106zm7.117-10.551a.333.333 0 00-.166.045l-7.31 4.252a.334.334 0 00-.166.288v5.632a.332.332 0 00.5.288l7.31-4.252a.333.333 0 00.166-.288v-5.632a.334.334 0 00-.332-.333"
              fill="#1d1d1b"
            />
            <path data-name="Path 61050" d="M35.299 42.773l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61051"
              d="M35.289 46.477a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.106.106 0 01-.054.015m.117-3.642l-.01 3.349.377-.219.009-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61052" d="M34.701 42.426l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61053"
              d="M35.299 42.88a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61054" d="M35.298 42.773l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61055"
              d="M35.288 46.477a.107.107 0 01-.053-.014l-.594-.343a.107.107 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61056" d="M24.417 46.042l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61057"
              d="M24.407 49.736a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.107.107 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61058" d="M23.823 45.694l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61059"
              d="M24.417 46.144a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61060" d="M24.417 46.041l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61061"
              d="M24.407 49.741a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61062" d="M35.295 44.164v.549l-8.235 4.786v-.549z" fill="#fff" />
            <path
              data-name="Path 61063"
              d="M27.058 49.606a.107.107 0 01-.107-.107v-.549a.106.106 0 01.053-.092l8.235-4.786a.107.107 0 01.16.093v.549a.107.107 0 01-.053.092l-8.235 4.786a.107.107 0 01-.054.014m.108-.594v.3l8.021-4.662v-.3z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61064" d="M27.06 48.95v.549l-2.646-1.528v-.549z" fill="#fff" />
            <path
              data-name="Path 61065"
              d="M27.058 49.606a.107.107 0 01-.053-.014l-2.647-1.524a.107.107 0 01-.053-.093v-.549a.107.107 0 01.16-.092l2.646 1.528a.107.107 0 01.053.093v.549a.107.107 0 01-.107.106m-2.54-1.7l2.433 1.4v-.3l-2.433-1.4z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61066"
              d="M34.7 43.818l.595.346-8.235 4.786-2.646-1.528.591-.34v-.688l7.648-4.444 2.049 1.183z"
              fill="#fff"
            />
            <path
              data-name="Path 61067"
              d="M27.06 49.057a.107.107 0 01-.053-.014l-2.646-1.528a.107.107 0 010-.185l.537-.309v-.626a.107.107 0 01.053-.092l7.654-4.446a.106.106 0 01.107 0l2.049 1.183a.107.107 0 01.053.093v.623l.541.315a.107.107 0 010 .185l-8.235 4.786a.107.107 0 01-.054.014m-2.433-1.635l2.432 1.4 8.023-4.662-.436-.254a.107.107 0 01-.053-.093v-.623l-1.941-1.121-7.543 4.389v.627a.107.107 0 01-.054.092z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61068" d="M27.059 47.561l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61069"
              d="M27.048 51.265a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.106.106 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61070" d="M26.463 47.218l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61071"
              d="M27.058 47.668a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.381-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61072" d="M27.058 47.561l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61073"
              d="M27.048 51.265a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61074" d="M35.891 41.747v.683l-8.831 5.132v-.683z" fill="#fff" />
            <path
              data-name="Path 61075"
              d="M27.058 47.664a.107.107 0 01-.107-.107v-.683a.107.107 0 01.053-.092l8.831-5.132a.107.107 0 01.16.093v.683a.107.107 0 01-.053.092l-8.831 5.132a.106.106 0 01-.054.015m.109-.728v.436l8.617-5.008v-.436z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61076" d="M27.06 46.879v.683l-3.235-1.868v-.683z" fill="#fff" />
            <path
              data-name="Path 61077"
              d="M27.058 47.669a.106.106 0 01-.053-.014l-3.235-1.868a.107.107 0 01-.053-.093v-.683a.107.107 0 01.16-.092l3.235 1.868a.107.107 0 01.053.093v.683a.107.107 0 01-.107.106m-3.128-2.036l3.022 1.745v-.437l-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61078" d="M35.891 41.747l-8.831 5.132-3.235-1.868 8.831-5.132z" fill="#fff" />
            <path
              data-name="Path 61079"
              d="M27.06 46.986a.106.106 0 01-.053-.014l-3.235-1.868a.107.107 0 010-.185l8.831-5.132a.106.106 0 01.107 0l3.235 1.868a.107.107 0 010 .185l-8.831 5.132a.107.107 0 01-.054.014m-3.022-1.975l3.022 1.745 8.618-5.008-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61080"
              d="M31.865 54.888h-.018a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61081"
              d="M31.865 54.997h-.02a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61082"
              d="M41.38 49.214h-.018a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61083"
              d="M41.381 49.321h-.018a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61084"
              d="M27.83 53.094h-.015a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61085"
              d="M27.83 53.203h-.022a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61086" d="M40.938 45.095l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 61087"
              d="M40.928 48.762a.106.106 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.877-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.876.509a.106.106 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61088" d="M36.953 42.798l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 61089"
              d="M40.938 45.204a.106.106 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.877-.509a.107.107 0 01.107 0l3.984 2.3a.107.107 0 010 .185l-.876.509a.107.107 0 01-.054.014m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61090" d="M40.938 45.097l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 61091"
              d="M40.928 48.765a.106.106 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61092" d="M39.989 45.473v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 61093"
              d="M35.695 49.087a.106.106 0 01-.107-.107v-1.014a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61094" d="M35.698 47.966v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 61095"
              d="M35.695 49.087a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106m-2.989-1.956l2.883 1.665v-.768l-2.883-1.664z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61096" d="M39.989 45.473l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 61097"
              d="M35.698 48.073a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.015m-2.883-1.895l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61098" d="M35.697 47.967v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 61099"
              d="M31.403 51.581a.106.106 0 01-.107-.107V50.46a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61100" d="M31.406 50.46v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 61101"
              d="M31.403 51.581a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106m-2.989-1.956l2.883 1.665v-.768l-2.883-1.664z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61102" d="M35.697 47.966l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 61103"
              d="M31.404 50.568a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.015m-2.883-1.895l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61104"
              d="M37.598 44.196s.115.153.144.208a12.452 12.452 0 01.485 1.3.648.648 0 01-.007.3 2.762 2.762 0 00.131-.261 8.266 8.266 0 01.49-1.006 2.2 2.2 0 01.57-.58.6.6 0 01.218-.107.815.815 0 01-.157-.2l-.554-1.056a4.428 4.428 0 01-.106-.275.665.665 0 01-.19.2 1.953 1.953 0 00-.46.353 4.945 4.945 0 00-.438.967.369.369 0 01-.125.159"
              fill="#fff"
            />
            <path
              data-name="Path 61105"
              d="M38.22 46.103a.107.107 0 01-.1-.133.524.524 0 00.009-.235 12.709 12.709 0 00-.478-1.281 2.578 2.578 0 00-.134-.193.107.107 0 01.025-.152.263.263 0 00.085-.108 4.973 4.973 0 01.453-1 1.881 1.881 0 01.49-.38.559.559 0 00.154-.165.107.107 0 01.193.018c.039.109.087.236.1.261l.554 1.056a.705.705 0 00.133.166.107.107 0 01-.047.184.49.49 0 00-.173.084l-.12.1a1.687 1.687 0 00-.43.459 8.28 8.28 0 00-.48.986 2.816 2.816 0 01-.138.275.107.107 0 01-.093.054m-.479-1.894a1.543 1.543 0 01.1.145 11.413 11.413 0 01.463 1.226 8.047 8.047 0 01.452-.909 1.915 1.915 0 01.476-.509l.115-.093a.573.573 0 01.1-.069.7.7 0 01-.068-.1l-.554-1.056a1.513 1.513 0 01-.046-.11.574.574 0 01-.1.079 1.632 1.632 0 00-.431.326 4.493 4.493 0 00-.423.94.428.428 0 01-.082.136"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61106"
              d="M38.22 45.997a2.764 2.764 0 00.131-.261 8.259 8.259 0 01.49-1.006 2.2 2.2 0 01.57-.58.6.6 0 01.217-.107 1.545 1.545 0 01-.44 1.417 3.739 3.739 0 01-.968.537"
              fill="#fff"
            />
            <path
              data-name="Path 61107"
              d="M38.22 46.103a.107.107 0 01-.093-.159 2.679 2.679 0 00.125-.247 8.253 8.253 0 01.5-1.025 1.91 1.91 0 01.476-.509l.115-.093a.692.692 0 01.258-.129h.017a.1.1 0 01.03 0 .107.107 0 01.044.018.108.108 0 01.044.076 1.644 1.644 0 01-.475 1.507 3.794 3.794 0 01-1 .558.105.105 0 01-.037.007m1.313-1.909a.337.337 0 00-.053.036l-.12.1a1.683 1.683 0 00-.43.459 8.279 8.279 0 00-.48.986v.01a2.869 2.869 0 00.67-.4 1.362 1.362 0 00.417-1.186"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61108"
              d="M36.959 44.72s.066.155.079.208a10.986 10.986 0 01.141 1.21.568.568 0 01-.068.25 2.407 2.407 0 00.167-.2 7.285 7.285 0 01.63-.756 1.94 1.94 0 01.609-.375.523.523 0 01.208-.045.713.713 0 01-.092-.2l-.25-1.018a4.245 4.245 0 01-.033-.257.583.583 0 01-.205.132 1.716 1.716 0 00-.467.2 4.359 4.359 0 00-.578.734.325.325 0 01-.14.109"
              fill="#fff"
            />
            <path
              data-name="Path 61109"
              d="M37.111 46.495a.107.107 0 01-.094-.157.448.448 0 00.056-.192 11.187 11.187 0 00-.138-1.192 1.95 1.95 0 00-.073-.191.107.107 0 01.06-.141.216.216 0 00.089-.069 4.374 4.374 0 01.6-.757 1.671 1.671 0 01.5-.223.472.472 0 00.161-.1.107.107 0 01.183.064c.01.1.025.219.03.242l.25 1.019a.609.609 0 00.077.166.107.107 0 01-.088.167.414.414 0 00-.159.034l-.124.059a1.475 1.475 0 00-.46.3 7.283 7.283 0 00-.615.739 2.483 2.483 0 01-.177.207.107.107 0 01-.078.033m-.017-1.729a1.3 1.3 0 01.048.136 9.749 9.749 0 01.134 1.112 6.947 6.947 0 01.559-.656 1.692 1.692 0 01.517-.337c.038-.017.077-.035.117-.055a.5.5 0 01.09-.034.644.644 0 01-.031-.091l-.25-1.019c0-.017-.009-.05-.014-.087a.487.487 0 01-.088.038 1.584 1.584 0 00-.432.185 3.937 3.937 0 00-.556.71.389.389 0 01-.094.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61110"
              d="M37.11 46.388a2.436 2.436 0 00.167-.2 7.244 7.244 0 01.63-.756 1.935 1.935 0 01.609-.375.523.523 0 01.208-.045 1.333 1.333 0 01-.8 1.025 6.641 6.641 0 01-.817.346"
              fill="#fff"
            />
            <path
              data-name="Path 61111"
              d="M37.111 46.495a.107.107 0 01-.077-.18 2.288 2.288 0 00.157-.183 7.288 7.288 0 01.644-.772 1.694 1.694 0 01.517-.337c.038-.017.077-.035.117-.055a.637.637 0 01.257-.057.107.107 0 01.1.122 1.448 1.448 0 01-.852 1.1 6.65 6.65 0 01-.833.353.107.107 0 01-.034.006m1.48-1.348l-.026.011a3.34 3.34 0 01-.124.058 1.475 1.475 0 00-.46.3 6.974 6.974 0 00-.537.632c.139-.057.3-.127.434-.2a1.254 1.254 0 00.713-.8"
              fill="#1d1d1b"
            />
            <path data-name="Path 61112" d="M36.608 51.148l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 61113"
              d="M36.608 51.255a.106.106 0 01-.054-.015l-.877-.509a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.877.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.107.107m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61114" d="M36.598 46.648l-.877-.509 4.32-2.494.877.509z" fill="#fff" />
            <path
              data-name="Path 61115"
              d="M36.598 46.755a.107.107 0 01-.054-.014l-.876-.509a.107.107 0 010-.185l4.32-2.494a.106.106 0 01.107 0l.877.509a.107.107 0 010 .185l-4.32 2.494a.106.106 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61116" d="M40.918 44.154l.01 4.5-4.32 2.494-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 61117"
              d="M36.608 51.256a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.009-4.254z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61118"
              d="M29.18 49.504a2.281 2.281 0 01.26-.045c.365 0 .915.086 1.462.086.529 0 1.054-.086 1.406-.086a2.33 2.33 0 01.314.073s-.056-.283-.056-.353v-1.616a2.2 2.2 0 01.078-.328s-.27.039-.333.039c-.347 0-.871-.1-1.391-.1-.495 0-.985.1-1.315.1a3.226 3.226 0 01-.445-.1s.045.322.045.4v1.681c0 .05-.026.246-.026.246"
              fill="#fff"
            />
            <path
              data-name="Path 61119"
              d="M30.902 49.652c-.306 0-.613-.026-.884-.05-.215-.018-.419-.036-.578-.036a2.1 2.1 0 00-.237.042.107.107 0 01-.129-.118c.01-.079.025-.2.025-.233v-1.681c0-.05-.027-.261-.044-.383a.107.107 0 01.134-.118 2.95 2.95 0 00.417.091c.143 0 .324-.019.517-.04a7.554 7.554 0 01.8-.055c.292 0 .585.029.843.055.2.021.4.04.548.04.039 0 .209-.022.318-.038a.107.107 0 01.117.137 2.053 2.053 0 00-.074.3v1.616c0 .037.028.2.054.332a.107.107 0 01-.135.123 2.2 2.2 0 00-.284-.069c-.153 0-.348.017-.555.036-.26.023-.555.05-.851.05m-1.462-.3c.169 0 .376.018.6.037.267.023.569.049.866.049s.576-.026.832-.049c.211-.019.411-.037.574-.037a.937.937 0 01.178.032 1.658 1.658 0 01-.027-.205v-1.616a1.014 1.014 0 01.038-.2c-.074.01-.151.018-.186.018-.161 0-.36-.02-.57-.041-.254-.025-.541-.054-.822-.054a7.387 7.387 0 00-.776.054c-.2.021-.385.041-.539.041a1.819 1.819 0 01-.318-.059c.012.1.025.209.025.254v1.681c0 .023 0 .067-.009.113a.937.937 0 01.137-.018"
              fill="#1d1d1b"
            />
            <path data-name="Path 61120" d="M32.29 53.641l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 61121"
              d="M32.289 53.748a.108.108 0 01-.054-.014l-.876-.509a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.876.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.053.093.105.105 0 01-.053.014m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61122" d="M32.279 49.141l-.876-.509 4.32-2.494.876.509z" fill="#fff" />
            <path
              data-name="Path 61123"
              d="M32.279 49.247a.107.107 0 01-.054-.014l-.876-.509a.107.107 0 010-.185l4.32-2.494a.106.106 0 01.107 0l.876.509a.107.107 0 010 .185l-4.32 2.494a.106.106 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61124" d="M36.599 46.647l.01 4.5-4.32 2.494-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 61125"
              d="M32.285 53.748a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.009-4.254z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61126" d="M31.423 50.596l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 61127"
              d="M31.413 54.261a.107.107 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.876-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.877.509a.107.107 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61128" d="M27.438 48.297l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 61129"
              d="M31.422 50.703a.107.107 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.876-.509a.107.107 0 01.107 0l3.984 2.3a.107.107 0 010 .185l-.877.509a.106.106 0 01-.054.015m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61130" d="M31.422 50.597l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 61131"
              d="M31.412 54.26a.107.107 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61132"
              d="M13.835 45.111a2.863 2.863 0 002.6-.026c.707-.423.691-1.1-.037-1.505a2.864 2.864 0 00-2.6.026c-.707.423-.691 1.1.037 1.505"
              fill="#fff"
            />
            <path
              data-name="Path 61133"
              d="M15.109 45.514a2.768 2.768 0 01-1.326-.31 1.029 1.029 0 01-.611-.845 1 1 0 01.572-.844 2.985 2.985 0 012.706-.028 1.028 1.028 0 01.611.845 1 1 0 01-.572.844 2.751 2.751 0 01-1.379.338m.012-2.128a2.531 2.531 0 00-1.269.311.8.8 0 00-.468.658.829.829 0 00.5.662 2.749 2.749 0 002.492-.025.8.8 0 00.468-.658.829.829 0 00-.5-.662 2.551 2.551 0 00-1.223-.286"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61134"
              d="M15.105 44.345h.021a.232.232 0 00.232-.232v-8.8a.232.232 0 00-.232-.232h-.021a.232.232 0 00-.232.232v8.8a.232.232 0 00.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61135"
              d="M15.126 44.452h-.021a.339.339 0 01-.338-.338v-8.8a.339.339 0 01.338-.338h.021a.339.339 0 01.338.338v8.8a.339.339 0 01-.338.338m-.021-9.258a.125.125 0 00-.125.125v8.8a.125.125 0 00.125.125h.021a.125.125 0 00.125-.125v-8.8a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61136"
              d="M13.469 33.801a3.681 3.681 0 003.341-.034c.909-.544.888-1.41-.047-1.935a3.681 3.681 0 00-3.341.034c-.909.544-.888 1.41.047 1.935"
              fill="#ffc200"
            />
            <path
              data-name="Path 61137"
              d="M15.107 34.291a3.528 3.528 0 01-1.69-.4 1.294 1.294 0 01-.77-1.061 1.262 1.262 0 01.72-1.059 3.8 3.8 0 013.448-.035 1.294 1.294 0 01.77 1.061 1.262 1.262 0 01-.72 1.059 3.5 3.5 0 01-1.757.431m-1.586-.582a3.569 3.569 0 003.234-.032 1.062 1.062 0 00.616-.873 1.094 1.094 0 00-.661-.877 3.568 3.568 0 00-3.234.032 1.062 1.062 0 00-.616.873 1.094 1.094 0 00.661.877"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61138"
              d="M13.469 33.801a3.681 3.681 0 003.341-.034 1.17 1.17 0 00.668-.951v2.163a1.157 1.157 0 01-.668.975 3.681 3.681 0 01-3.341.034 1.186 1.186 0 01-.716-.984v-2.187a1.186 1.186 0 00.716.984"
              fill="#ffc200"
            />
            <path
              data-name="Path 61139"
              d="M15.107 36.478a3.527 3.527 0 01-1.69-.4 1.283 1.283 0 01-.77-1.078v-2.187h.213v.009a1.09 1.09 0 00.661.883 3.568 3.568 0 003.234-.032 1.071 1.071 0 00.616-.859h.213v2.163a1.256 1.256 0 01-.72 1.066 3.506 3.506 0 01-1.757.431m-2.247-3.049v1.576a1.085 1.085 0 00.661.891 3.569 3.569 0 003.234-.032 1.057 1.057 0 00.616-.88v-1.564a1.732 1.732 0 01-.507.44 3.8 3.8 0 01-3.448.035 1.762 1.762 0 01-.557-.466"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61140"
              d="M13.936 36.299a.116.116 0 01-.042-.009.093.093 0 01-.034-.023.047.047 0 01-.013-.016.1.1 0 01-.011-.018.113.113 0 01-.005-.02.065.065 0 010-.02.073.073 0 010-.021.129.129 0 01.005-.019.175.175 0 01.011-.019.084.084 0 01.013-.016.106.106 0 01.034-.022.1.1 0 01.082 0 .106.106 0 01.034.022l.014.016a.081.081 0 01.01.019.068.068 0 01.006.019.145.145 0 010 .021.13.13 0 010 .02.064.064 0 01-.006.02.059.059 0 01-.01.018.067.067 0 01-.014.016.1.1 0 01-.075.032"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61141"
              d="M13.829 36.786a.107.107 0 11.107.107.107.107 0 01-.107-.107m0-.3a.107.107 0 11.107.107.107.107 0 01-.107-.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61142"
              d="M13.936 37.191a.112.112 0 01-.076-.031.107.107 0 01-.031-.076.105.105 0 01.007-.041.1.1 0 01.023-.035.107.107 0 01.1-.029.064.064 0 01.02.006.059.059 0 01.018.01.064.064 0 01.016.013.089.089 0 01.023.035.106.106 0 01.009.041.109.109 0 01-.032.076l-.016.013a.059.059 0 01-.018.01.064.064 0 01-.02.006h-.02"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61143"
              d="M13.677 37.612a.259.259 0 10.259-.259.259.259 0 00-.259.259"
              fill="#ffc200"
            />
            <path
              data-name="Path 61144"
              d="M13.935 37.925a.312.312 0 11.312-.312.313.313 0 01-.312.312m0-.518a.206.206 0 10.206.206.206.206 0 00-.206-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61145"
              d="M36.297 31.992l-2.041-1.189a.362.362 0 010-.627l1.926-1.112a1.1 1.1 0 011.1 0l2.041 1.189a.362.362 0 010 .627l-1.926 1.112a1.1 1.1 0 01-1.1 0"
              fill="#fff"
            />
            <path
              data-name="Path 61146"
              d="M36.851 32.248a1.207 1.207 0 01-.607-.164l-2.041-1.189a.469.469 0 010-.811l1.926-1.112a1.209 1.209 0 011.211 0l2.041 1.189a.469.469 0 010 .811l-1.926 1.112a1.207 1.207 0 01-.6.161m-.117-3.224a.992.992 0 00-.5.133l-1.926 1.112a.255.255 0 000 .442l2.041 1.189a1 1 0 001 0l1.926-1.112a.255.255 0 000-.442l-2.041-1.189a.992.992 0 00-.5-.135"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61147"
              d="M36.524 31.633l-1.667-.971a.207.207 0 010-.358l1.583-.914a.611.611 0 01.613 0l1.667.971a.207.207 0 010 .358l-1.583.914a.611.611 0 01-.613 0"
              fill="#fff"
            />
            <path
              data-name="Path 61148"
              d="M36.831 31.77a.664.664 0 01-.335-.09l-1.667-.971a.26.26 0 010-.45l1.583-.914a.667.667 0 01.667 0l1.667.971a.26.26 0 010 .45l-1.583.914a.665.665 0 01-.332.089m-.085-2.407a.557.557 0 00-.279.075l-1.583.914a.154.154 0 000 .266l1.667.971a.56.56 0 00.56 0l1.583-.914a.154.154 0 000-.266l-1.667-.971a.558.558 0 00-.281-.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61149"
              d="M38.258 31.042a.053.053 0 01-.027-.007l-1.2-.7a.559.559 0 00-.56 0l-1.121.647a.053.053 0 01-.053-.092l1.121-.647a.666.666 0 01.667 0l1.2.7a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61150"
              d="M37.321 31.279c0 .1-.171.185-.382.185s-.382-.083-.382-.185.171-.184.382-.184.382.083.382.184"
              fill="#fff"
            />
            <path
              data-name="Path 61151"
              d="M36.939 31.518c-.248 0-.435-.1-.435-.238s.187-.238.435-.238.435.1.435.238-.187.238-.435.238m0-.369c-.2 0-.328.078-.328.131s.128.131.328.131.328-.078.328-.131-.128-.131-.328-.131"
              fill="#1d1d1b"
            />
            <path data-name="Path 61152" d="M37.892 30.231a.146.146 0 11-.041-.17.127.127 0 01.041.17" fill="#fff" />
            <path
              data-name="Path 61153"
              d="M37.818 30.316h-.008a.228.228 0 01-.2-.167.141.141 0 01.149-.181.22.22 0 01.128.051.177.177 0 01.049.245.146.146 0 01-.115.051m-.07-.241a.042.042 0 00-.032.012.044.044 0 000 .037.122.122 0 00.1.085.043.043 0 00.035-.012c.015-.019 0-.064-.034-.1a.114.114 0 00-.065-.027"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61154"
              d="M37.986 29.262c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.184h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 61155"
              d="M37.717 29.499c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61156"
              d="M37.986 29.079c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 61157"
              d="M37.717 29.314c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61158"
              d="M38.049 29.634a.214.214 0 01-.214-.214v-1.236c0-.385-.2-.654-.375-.654s-.375.268-.375.654a.214.214 0 01-.428 0c0-.607.353-1.082.8-1.082s.8.475.8 1.082v1.236a.214.214 0 01-.214.214"
              fill="#fff"
            />
            <path
              data-name="Path 61159"
              d="M38.049 29.688a.268.268 0 01-.267-.268v-1.236c0-.372-.188-.6-.322-.6s-.322.228-.322.6a.268.268 0 11-.535 0c0-.637.376-1.135.857-1.135s.857.5.857 1.135v1.236a.268.268 0 01-.268.268m-.589-2.211c.207 0 .429.284.429.707v1.236a.161.161 0 10.321 0v-1.236c0-.577-.33-1.029-.75-1.029s-.75.452-.75 1.029a.161.161 0 10.321 0c0-.423.222-.707.429-.707"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61160"
              d="M38.675 29.618c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.185h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 61161"
              d="M38.405 29.852c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61162"
              d="M38.675 29.435c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 61163"
              d="M38.405 29.669c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
          </g>
        </g>
      </g>
      <g data-name="Group 17793">
        <path data-name="Path 60852" d="M57.966 42.769l-.011 1.86-25.871 15.035.01-1.86z" fill="#d0dadd" />
        <g data-name="Group 17780">
          <g data-name="Group 17779" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60853"
              d="M32.08 59.771a.107.107 0 01-.107-.107l.01-1.86a.107.107 0 01.053-.092l25.871-15.035a.107.107 0 01.16.093l-.011 1.86a.107.107 0 01-.053.092L32.132 59.757a.106.106 0 01-.054.014m.117-1.905l-.009 1.613 25.657-14.91.009-1.613z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60854" d="M32.09 57.804l-.011 1.86L6.039 44.63l.011-1.86z" fill="#d0dadd" />
            <path
              data-name="Path 60855"
              d="M32.079 59.771a.107.107 0 01-.053-.014L5.986 44.723a.107.107 0 01-.053-.093l.011-1.86a.107.107 0 01.16-.092l26.04 15.034a.107.107 0 01.053.093l-.011 1.86a.107.107 0 01-.107.106m-25.933-15.2l25.828 14.912.009-1.615L6.155 42.954z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60856" d="M57.961 42.77L32.089 57.804 6.049 42.77 31.92 27.735z" fill="#d0dadd" />
            <path
              data-name="Path 60857"
              d="M32.089 57.91a.107.107 0 01-.053-.014L5.995 42.863a.107.107 0 010-.185l25.871-15.035a.107.107 0 01.107 0l26.04 15.034a.107.107 0 010 .185l-25.87 15.035a.106.106 0 01-.054.015M6.262 42.77l25.827 14.911L57.747 42.77 31.92 27.859z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 60858" d="M31.804 10.441l-.009 21.876-19.855 11.6.009-21.876z" fill="#fff" />
        <g data-name="Group 17782">
          <g data-name="Group 17781" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60859"
              d="M11.941 44.028a.107.107 0 01-.107-.107l.009-21.876a.107.107 0 01.053-.092l19.855-11.6a.107.107 0 01.161.092l-.009 21.876a.107.107 0 01-.053.092l-19.855 11.6a.107.107 0 01-.054.015m.116-21.922l-.009 21.629 19.641-11.477.009-21.629z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60860" d="M10.436 21.169l1.514.873-.009 21.876-1.505-1z" fill="#fff" />
            <path
              data-name="Path 60861"
              d="M11.941 44.025a.107.107 0 01-.059-.018l-1.505-1a.107.107 0 01-.048-.089v-21.75a.107.107 0 01.16-.092l1.514.873a.107.107 0 01.053.093l-.009 21.876a.107.107 0 01-.107.107m-1.4-1.164l1.292.858.009-21.616-1.3-.75z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60862" d="M53.277 21.169l-1.514.873.009 21.876 1.505-1z" fill="#fff" />
            <path
              data-name="Path 60863"
              d="M51.772 44.025a.107.107 0 01-.107-.107l-.009-21.876a.106.106 0 01.053-.092l1.514-.873a.107.107 0 01.16.092v21.75a.107.107 0 01-.048.089l-1.505 1a.107.107 0 01-.059.018m.1-21.921l.009 21.616 1.292-.858V21.355z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60864"
              d="M31.799 8.747l21.467 12.428-1.514.873-19.944-11.545h-.009L11.95 22.042l-1.514-.873L31.809 8.748z"
              fill="#fff"
            />
            <path
              data-name="Path 60865"
              d="M51.753 22.155a.106.106 0 01-.053-.014L31.8 10.628 12 22.135a.107.107 0 01-.107 0l-1.514-.873a.107.107 0 010-.185l21.34-12.4a.107.107 0 01.078-.034h.009a.106.106 0 01.077.033l21.434 12.409a.107.107 0 010 .185l-1.514.873a.107.107 0 01-.053.014M31.8 10.397a.142.142 0 01.062.014l19.891 11.514 1.3-.75-21.249-12.3-21.155 12.3 1.3.75 19.8-11.5a.107.107 0 01.054-.014"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path
          data-name="Path 60866"
          d="M51.771 23.958l-.008-.93v-.956h-.008v-.02L41.038 5.215l-9.156 5.249-.084-.049v2.782l.008 19.094 19.965 11.663-.008-20z"
          fill="#fff"
        />
        <g data-name="Group 17784">
          <g data-name="Group 17783" clipPath="url(#_Consumption_svg__a)">
            <path
              data-name="Path 60867"
              d="M51.771 44.058a.107.107 0 01-.054-.015L31.753 32.378a.107.107 0 01-.053-.092l-.008-19.079a.092.092 0 010-.015V10.41a.107.107 0 01.161-.092l.031.018 9.1-5.214a.107.107 0 01.143.035l10.717 16.834a.106.106 0 01.016.042.1.1 0 01.01.04v.956l.008.929a.107.107 0 01-.008.041l.008 19.951a.107.107 0 01-.107.107M31.914 32.225l19.75 11.538-.007-19.805a.109.109 0 01.007-.039l-.008-.885v-.924a.106.106 0 01-.007-.024L41.002 5.358l-9.067 5.194a.106.106 0 01-.031.012v2.614a.1.1 0 010 .015z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60868" d="M10.436 21.169l10.7-4.455L42.56 4.336 31.807 8.748z" fill="#00515f" />
            <path
              data-name="Path 60869"
              d="M10.436 21.275a.107.107 0 01-.054-.2l21.373-12.42.013-.006L42.52 4.237a.107.107 0 01.094.191L21.19 16.806l-.012.006-10.705 4.455a.107.107 0 01-.041.008m21.42-12.432L11.886 20.449l9.2-3.832L41.135 5.035z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60870" d="M21.197 18.198l-.061-1.484-10.7 4.455 1.514.879z" fill="#fff" />
            <path
              data-name="Path 60871"
              d="M11.95 22.155a.106.106 0 01-.054-.014l-1.514-.879a.107.107 0 01.013-.191l10.7-4.455a.107.107 0 01.148.094l.061 1.484a.107.107 0 01-.066.1l-9.248 3.85a.107.107 0 01-.041.008m-1.274-.97l1.282.745 9.131-3.8-.051-1.257z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60872"
              d="M53.277 21.169L42.561 4.336l-1.523.879-19.9 11.5.061 1.484 20.659-11.7 9.9 15.548z"
              fill="#fff"
            />
            <path
              data-name="Path 60873"
              d="M51.755 22.155a.107.107 0 01-.09-.049L41.824 6.643l-20.57 11.648a.107.107 0 01-.159-.088l-.061-1.484a.107.107 0 01.053-.1l19.88-11.482a.091.091 0 01.021-.017l1.523-.879a.107.107 0 01.143.035l10.713 16.839a.107.107 0 01-.037.15l-1.523.879a.106.106 0 01-.053.014m-9.9-15.761a.107.107 0 01.09.049l9.843 15.462 1.338-.773L42.524 4.479l-1.411.815a.093.093 0 01-.021.017L21.246 16.774l.051 1.245L41.804 6.407a.107.107 0 01.053-.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 60874" d="M19.647 30.942l5.457-3.151v-9.437l-5.465 3.133z" fill="#d0dadd" />
            <path
              data-name="Path 60875"
              d="M19.647 31.045a.107.107 0 01-.107-.107l-.009-9.452a.107.107 0 01.053-.093l5.462-3.135a.107.107 0 01.16.093v9.437a.107.107 0 01-.053.092l-5.457 3.151a.107.107 0 01-.053.014m.1-9.5l.005 9.206 5.243-3.027v-9.19z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60876" d="M25.104 27.788v-9.445l-.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60877"
              d="M24.195 28.419a.107.107 0 01-.107-.107v-9.45a.107.107 0 01.054-.093l.909-.519a.107.107 0 01.16.093v9.445a.107.107 0 01-.053.092l-.91.524a.106.106 0 01-.053.014m.107-9.494v9.2l.7-.4v-9.2zm.8 8.863z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60878" d="M20.561 31.984l5.453-3.151v-.525l-5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 60879"
              d="M20.56 32.095a.106.106 0 01-.107-.107v-.527a.106.106 0 01.053-.092l5.453-3.149a.107.107 0 01.16.092v.525a.107.107 0 01-.053.092l-5.453 3.151a.107.107 0 01-.053.014m.107-.572v.281l5.24-3.028v-.278z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60880" d="M20.559 31.989v-.527l-.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60881"
              d="M20.56 32.096a.107.107 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-.526a.107.107 0 01.16-.093l.912.523a.107.107 0 01.054.093v.527a.107.107 0 01-.107.107m-.8-.693l.7.4v-.281l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60882" d="M20.56 31.461l5.453-3.149-1.818-1.05-4.556 2.631.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60883"
              d="M20.559 31.569a.106.106 0 01-.053-.014l-.912-.523a.107.107 0 01-.054-.092l-.008-1.046a.107.107 0 01.053-.093l4.553-2.63a.107.107 0 01.107 0l1.818 1.05a.107.107 0 010 .185l-5.453 3.149a.107.107 0 01-.053.014m-.806-.692l.806.462 5.24-3.026-1.6-.926-4.446 2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1087" fill="#fff" d="M22.006 20.157v8.399" />
            <path
              data-name="Path 60884"
              d="M22.006 28.664a.107.107 0 01-.107-.107v-8.4a.107.107 0 11.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1088" fill="#ffc200" d="M24.194 23.077l-4.552 2.613" />
            <path
              data-name="Path 60885"
              d="M19.642 25.797a.107.107 0 01-.053-.2l4.552-2.613a.107.107 0 01.106.185l-4.552 2.618a.105.105 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 60886" d="M32.201 23.804l-5.23-3.02 4.838-2.793 5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60887"
              d="M32.203 23.911a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 010-.185l4.838-2.793a.107.107 0 01.107 0l5.23 3.02a.107.107 0 010 .185l-4.838 2.793a.106.106 0 01-.053.014m-5.017-3.126l5.017 2.9 4.624-2.67-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60888" d="M32.201 38.46l-5.23-3.02V20.781l5.23 3.02z" fill="#ffc200" />
            <path
              data-name="Path 60889"
              d="M32.203 38.566a.107.107 0 01-.053-.014l-5.23-3.02a.106.106 0 01-.053-.092V20.784a.107.107 0 01.16-.093l5.23 3.02a.107.107 0 01.053.092v14.656a.107.107 0 01-.107.107m-5.123-3.188l5.017 2.9V23.865l-5.017-2.9z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60890" d="M37.037 35.666l-4.838 2.793V23.804l4.838-2.793z" fill="#ffc200" />
            <path
              data-name="Path 60891"
              d="M32.203 38.566a.106.106 0 01-.107-.107V23.804a.107.107 0 01.053-.092l4.838-2.793a.107.107 0 01.16.093v14.656a.107.107 0 01-.053.092l-4.838 2.793a.107.107 0 01-.053.014m.107-14.7v14.409l4.624-2.67V21.195zm4.731 11.8z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1089" fill="#ffc200" d="M26.972 30.464l5.23 3.02" />
            <path
              data-name="Path 60892"
              d="M32.202 33.59a.107.107 0 01-.053-.014l-5.23-3.02a.107.107 0 11.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1090" fill="#ffc200" d="M27.467 20.496l5.23 3.02" />
            <path
              data-name="Path 60893"
              d="M32.696 23.623a.106.106 0 01-.053-.014l-5.23-3.02a.107.107 0 11.107-.185l5.23 3.02a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Path 60894" d="M29.907 33.341l.35-.2v-.372l-.35.2z" fill="#fff" />
            <path
              data-name="Path 60895"
              d="M29.907 33.394a.053.053 0 01-.053-.053v-.371a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.372a.054.054 0 01-.027.046l-.35.2a.052.052 0 01-.027.007m.054-.395v.248l.243-.141v-.248z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60896"
              d="M30.057 32.65l-.16.093-.811-.471.157-.091-.194-.112-.35.2 1.208.7.35-.2z"
              fill="#fff"
            />
            <path
              data-name="Path 60897"
              d="M29.908 33.022a.054.054 0 01-.027-.007l-1.209-.7a.053.053 0 010-.092l.35-.2a.053.053 0 01.054 0l.194.112a.053.053 0 010 .092l-.078.045.7.41.133-.077a.053.053 0 01.053 0l.2.116a.053.053 0 010 .092l-.35.2a.053.053 0 01-.027.007m-1.1-.751l1.1.636.243-.141-.094-.054-.133.077a.053.053 0 01-.054 0l-.811-.471a.053.053 0 010-.092l.077-.045-.087-.05z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60898" d="M28.7 32.271v.372l1.208.7v-.372z" fill="#fff" />
            <path
              data-name="Path 60899"
              d="M29.903 33.394a.054.054 0 01-.027-.007l-1.208-.7a.053.053 0 01-.027-.046v-.372a.053.053 0 01.08-.046l1.209.7a.054.054 0 01.027.046v.372a.053.053 0 01-.027.046.052.052 0 01-.027.007m-1.155-.782l1.1.636v-.249l-1.1-.636z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1091" fill="#fff" d="M29.243 32.18v.183" />
            <path
              data-name="Path 60900"
              d="M29.245 32.416a.053.053 0 01-.053-.053v-.183a.054.054 0 01.107 0v.183a.053.053 0 01-.053.053"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60901"
              d="M31.617 29.661l.008-2.164.127-.074v-.333l-.35.2-.008 2.779.35-.2v-.283z"
              fill="#fff"
            />
            <path
              data-name="Path 60902"
              d="M31.395 30.125a.053.053 0 01-.053-.053l.008-2.779a.053.053 0 01.027-.046l.35-.2a.053.053 0 01.08.046v.333a.053.053 0 01-.027.046l-.1.058-.007 2.04.048-.028a.053.053 0 01.08.046v.283a.053.053 0 01-.027.046l-.35.2a.052.052 0 01-.027.007m.061-2.8l-.007 2.655.243-.141v-.159l-.048.028a.053.053 0 01-.08-.046l.008-2.164a.053.053 0 01.027-.046l.1-.059v-.209z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60903" d="M31.403 27.294l.35-.2-.36-.208-.35.2z" fill="#fff" />
            <path
              data-name="Path 60904"
              d="M31.403 27.347a.054.054 0 01-.027-.007l-.36-.208a.053.053 0 010-.092l.35-.2a.053.053 0 01.054 0l.36.208a.053.053 0 010 .092l-.35.2a.053.053 0 01-.027.007m-.253-.261l.253.146.243-.141-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60905" d="M31.043 27.086l-.008 2.779.36.208.008-2.779z" fill="#fff" />
            <path
              data-name="Path 60906"
              d="M31.395 30.125a.054.054 0 01-.027-.007l-.36-.208a.053.053 0 01-.027-.046l.008-2.779a.053.053 0 01.08-.046l.36.208a.054.054 0 01.027.046l-.008 2.779a.053.053 0 01-.053.053m-.306-.292l.253.146.007-2.656-.253-.146z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1092" fill="#fff" d="M31.617 29.512l.129.074" />
            <path
              data-name="Path 60907"
              d="M31.746 29.639a.053.053 0 01-.027-.007l-.129-.074a.053.053 0 01.053-.092l.129.074a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path data-name="Line 1093" fill="#ffc200" d="M32.697 38.172V23.516" />
            <path
              data-name="Path 60908"
              d="M32.692 38.278a.107.107 0 01-.107-.107V23.519a.107.107 0 11.213 0v14.656a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1094" fill="#ffc200" d="M32.202 33.484l.495-.286" />
            <path
              data-name="Path 60909"
              d="M32.203 33.59a.107.107 0 01-.053-.2l.495-.286a.107.107 0 11.107.185l-.495.286a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60910"
              d="M40.003 42.44h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60911"
              d="M40.003 42.546h-.005a.339.339 0 01-.338-.339v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60912"
              d="M43.963 40.204h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60913"
              d="M43.963 40.31h-.005a.339.339 0 01-.338-.339V38.54a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.339m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125V38.54a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60914"
              d="M32.966 38.429h-.005a.232.232 0 01-.232-.232v-1.431a.232.232 0 01.232-.232h.005a.232.232 0 01.232.232v1.431a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 60915"
              d="M32.966 38.536h-.005a.339.339 0 01-.338-.338v-1.431a.341.341 0 01.682 0v1.431a.339.339 0 01-.338.338m0-1.895a.126.126 0 00-.13.125v1.431a.125.125 0 00.125.125h.005a.125.125 0 00.125-.125v-1.431a.125.125 0 00-.125-.125"
              fill="#1d1d1b"
            />
            <path data-name="Path 60916" d="M44.318 39.44l-4.356 2.515v-6.621l4.356-2.515z" fill="#fff" />
            <path
              data-name="Path 60917"
              d="M39.963 42.062a.107.107 0 01-.107-.107v-6.621a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v6.622a.107.107 0 01-.053.092l-4.356 2.515a.106.106 0 01-.053.014m.107-6.667v6.375l4.142-2.392v-6.375zm4.249 4.045z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60918" d="M39.96 41.955l-3.616-2.1v-6.622l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60919"
              d="M39.963 42.062a.107.107 0 01-.054-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.621a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.621a.107.107 0 01-.107.107m-3.509-2.266l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60920"
              d="M37.555 35.153a.548.548 0 01-.273-.073l-.31-.178a.263.263 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.556.556 0 01-.279.075"
              fill="#1d1d1b"
            />
            <path data-name="Path 60921" d="M36.338 39.85l-3.616-2.1v-6.621l3.616 2.1z" fill="#fff" />
            <path
              data-name="Path 60922"
              d="M36.337 39.957a.107.107 0 01-.054-.014l-3.616-2.1a.107.107 0 01-.053-.092v-6.622a.107.107 0 01.16-.092l3.616 2.1a.107.107 0 01.053.092v6.622a.107.107 0 01-.107.107m-3.508-2.263l3.4 1.974v-6.375l-3.4-1.974z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1095" fill="#fff" d="M32.729 33.236l3.617 2.089" />
            <path
              data-name="Path 60923"
              d="M36.346 35.431a.106.106 0 01-.053-.014l-3.617-2.089a.107.107 0 01.107-.185l3.617 2.089a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path data-name="Line 1096" fill="#fff" d="M32.729 35.574l3.617 2.089" />
            <path
              data-name="Path 60924"
              d="M36.346 37.77a.107.107 0 01-.053-.014l-3.617-2.089a.107.107 0 01.107-.185l3.617 2.089a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60925"
              d="M34.694 33.533a.547.547 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 01.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.555.555 0 01-.279.075"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60926"
              d="M34.695 35.703a.546.546 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.309.178a.336.336 0 00.336 0l.069-.043a.107.107 0 01.113.181l-.072.045a.555.555 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60927"
              d="M34.694 37.873a.547.547 0 01-.273-.073l-.31-.178a.264.264 0 010-.456l.055-.035a.107.107 0 11.114.181l-.059.037a.051.051 0 00-.028.045.048.048 0 00.025.043l.31.178a.336.336 0 00.336 0l.069-.043a.107.107 0 11.113.181l-.072.045a.555.555 0 01-.279.076"
              fill="#1d1d1b"
            />
            <path data-name="Path 60928" d="M44.983 38.3l-4.709-2.719 4.356-2.515 4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60929"
              d="M44.983 38.408a.106.106 0 01-.053-.014l-4.709-2.719a.107.107 0 010-.185l4.356-2.515a.107.107 0 01.107 0l4.709 2.719a.107.107 0 010 .185l-4.356 2.515a.105.105 0 01-.053.014m-4.5-2.825l4.5 2.6 4.142-2.392-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60930" d="M44.983 45.301l-4.709-2.719v-7.22l4.709 2.719z" fill="#ffc200" />
            <path
              data-name="Path 60931"
              d="M44.983 45.408a.107.107 0 01-.053-.014l-4.709-2.719a.106.106 0 01-.053-.092v-7.22a.107.107 0 01.16-.092l4.709 2.718a.107.107 0 01.053.092v7.22a.107.107 0 01-.107.107m-4.6-2.887l4.5 2.6v-6.974l-4.5-2.6z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60932" d="M49.339 42.787l-4.352 2.515v-7l4.356-2.515z" fill="#ffc200" />
            <path
              data-name="Path 60933"
              d="M44.98 45.408a.107.107 0 01-.107-.107v-7a.107.107 0 01.053-.092l4.356-2.515a.107.107 0 01.16.092v7a.107.107 0 01-.053.092l-4.356 2.515a.107.107 0 01-.053.014m.107-7.046v6.754l4.142-2.392v-6.754zm4.249 4.424z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60934"
              d="M42.707 38.644a1.352 1.352 0 00-1.309-.16l-.337.19a1.31 1.31 0 011.271.145 4.036 4.036 0 011.831 3.159 1.328 1.328 0 01-.5 1.157l.464-.254a1.239 1.239 0 00.4-1.023 4.117 4.117 0 00-1.814-3.214"
              fill="#ffc200"
            />
            <path
              data-name="Path 60935"
              d="M43.659 43.242a.107.107 0 01-.059-.2 1.233 1.233 0 00.456-1.068 3.923 3.923 0 00-1.778-3.066 1.213 1.213 0 00-1.167-.143.107.107 0 01-.1-.188l.337-.19a1.453 1.453 0 011.415.16 4.2 4.2 0 011.867 3.306 1.317 1.317 0 01-.44 1.108l-.013.009-.464.254a.106.106 0 01-.051.013m.464-.361zm-2.356-4.4a2 2 0 01.618.241 4.16 4.16 0 011.885 3.251 1.831 1.831 0 01-.154.768 1.19 1.19 0 00.3-.888 4.028 4.028 0 00-1.761-3.121 1.594 1.594 0 00-.887-.251"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60936"
              d="M44.163 41.976c0 1.161-.82 1.628-1.831 1.044a4.036 4.036 0 01-1.831-3.159c0-1.161.82-1.628 1.831-1.044a4.036 4.036 0 011.831 3.159"
              fill="#ffc200"
            />
            <path
              data-name="Path 60937"
              d="M43.153 43.381a1.779 1.779 0 01-.876-.267 4.16 4.16 0 01-1.885-3.251 1.374 1.374 0 01.586-1.266 1.392 1.392 0 011.406.13 4.16 4.16 0 011.885 3.251 1.374 1.374 0 01-.586 1.266 1.047 1.047 0 01-.53.137m-1.644-4.708a.837.837 0 00-.424.108 1.175 1.175 0 00-.479 1.082 3.923 3.923 0 001.778 3.067 1.193 1.193 0 001.192.13 1.175 1.175 0 00.479-1.082 3.923 3.923 0 00-1.778-3.067 1.569 1.569 0 00-.768-.238"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60938"
              d="M43.68 41.699c0 .855-.6 1.2-1.349.769a2.973 2.973 0 01-1.349-2.327c0-.855.6-1.2 1.349-.769a2.972 2.972 0 011.349 2.327"
              fill="#fff"
            />
            <path
              data-name="Path 60939"
              d="M42.937 42.761a1.337 1.337 0 01-.659-.2 3.1 3.1 0 01-1.4-2.419 1.039 1.039 0 01.445-.957 1.051 1.051 0 011.064.1 3.1 3.1 0 011.4 2.419 1.039 1.039 0 01-.446.957.8.8 0 01-.4.1m-1.211-3.468a.589.589 0 00-.3.076.839.839 0 00-.339.772 2.858 2.858 0 001.3 2.234.853.853 0 00.85.1.839.839 0 00.339-.772 2.859 2.859 0 00-1.3-2.234 1.129 1.129 0 00-.552-.171"
              fill="#1d1d1b"
            />
            <path data-name="Path 60940" d="M42.111 37.885l-1.613-.931v-.933l1.613.931z" fill="#ffc200" />
            <path
              data-name="Path 60941"
              d="M42.11 37.939a.054.054 0 01-.027-.007l-1.613-.931a.053.053 0 01-.027-.046v-.933a.053.053 0 01.08-.046l1.613.931a.053.053 0 01.027.046v.933a.053.053 0 01-.053.053m-1.559-1.015l1.506.87v-.809l-1.506-.869z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60942"
              d="M42.733 37.424a.189.189 0 00-.191-.019l-.117.068a.189.189 0 01.191.019.588.588 0 01.267.463.187.187 0 01-.078.172l.117-.068a.187.187 0 00.078-.172.588.588 0 00-.267-.463"
              fill="#fff"
            />
            <path
              data-name="Path 60943"
              d="M42.832 38.173l-.054-.092a.14.14 0 00.051-.126.539.539 0 00-.24-.416.142.142 0 00-.137-.019l-.054-.092.116-.068a.239.239 0 01.244.019.639.639 0 01.294.509.238.238 0 01-.1.218zm-.192-.729a.639.639 0 01.294.509.223.223 0 00.01-.069.538.538 0 00-.24-.416.224.224 0 00-.067-.027"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60944"
              d="M42.616 37.493a.589.589 0 01.267.463c0 .17-.121.238-.269.153a.589.589 0 01-.267-.462c0-.17.121-.239.269-.153"
              fill="#fff"
            />
            <path
              data-name="Path 60945"
              d="M42.735 38.198a.3.3 0 01-.147-.044.639.639 0 01-.294-.509.216.216 0 01.349-.2.639.639 0 01.294.509.238.238 0 01-.105.218.191.191 0 01-.1.025m-.239-.689a.086.086 0 00-.043.011.14.14 0 00-.052.126.539.539 0 00.241.416.142.142 0 00.137.019.139.139 0 00.052-.126.538.538 0 00-.24-.416.193.193 0 00-.094-.029"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60946"
              d="M43.389 37.779a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60947"
              d="M43.433 38.076a.144.144 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.041.041 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.038.038 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60948"
              d="M43.767 37.986a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60949"
              d="M43.812 38.284a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.036.036 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60950"
              d="M44.145 38.215a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60951"
              d="M44.19 38.512a.146.146 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.044.044 0 00.018.007.1.1 0 000-.018.169.169 0 00-.073-.126.037.037 0 00-.019-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60952"
              d="M43.389 38.199a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60953"
              d="M43.433 38.493a.145.145 0 01-.072-.021.271.271 0 01-.127-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.052.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.092.092 0 000-.018.169.169 0 00-.073-.126.039.039 0 00-.019-.007"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60954"
              d="M43.767 38.402a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60955"
              d="M43.812 38.699a.146.146 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.168.168 0 00-.073-.126.037.037 0 00-.018-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60956"
              d="M44.145 38.631a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60957"
              d="M44.19 38.928a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.1.1 0 000-.018.168.168 0 00-.073-.126.035.035 0 00-.018-.01z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60958"
              d="M44.53 38.453a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60959"
              d="M44.574 38.751a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.271.271 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.094.094 0 000-.018.168.168 0 00-.073-.126.035.035 0 00-.018-.011z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60960"
              d="M44.53 38.867a.22.22 0 01.1.173c0 .063-.045.089-.1.057a.22.22 0 01-.1-.173c0-.063.045-.089.1-.057"
              fill="#fff"
            />
            <path
              data-name="Path 60961"
              d="M44.574 39.166a.145.145 0 01-.072-.021.271.271 0 01-.126-.219.114.114 0 01.181-.1.27.27 0 01.126.219.122.122 0 01-.056.11.1.1 0 01-.053.014m-.09-.257v.018a.169.169 0 00.073.126.043.043 0 00.018.007.094.094 0 000-.018.169.169 0 00-.073-.126.037.037 0 00-.018-.006z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60962" d="M44.984 37.713l-12.487-7.239 4.548-2.626 12.487 7.239z" fill="#fff" />
            <path
              data-name="Path 60963"
              d="M44.984 37.819a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 010-.185l4.548-2.626a.106.106 0 01.107 0l12.487 7.239a.107.107 0 010 .185l-4.548 2.626a.107.107 0 01-.053.014M32.71 30.474l12.274 7.115 4.335-2.5-12.274-7.115z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60964" d="M32.497 31.011v-.538l12.487 7.239v.538z" fill="#fff" />
            <path
              data-name="Path 60965"
              d="M44.984 38.357a.106.106 0 01-.053-.014l-12.487-7.239a.107.107 0 01-.053-.092v-.538a.107.107 0 01.16-.092l12.487 7.239a.107.107 0 01.053.092v.538a.107.107 0 01-.107.107m-12.38-7.407l12.274 7.115v-.291L32.604 30.66z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60966" d="M49.533 35.086v.538l-4.549 2.626v-.538z" fill="#fff" />
            <path
              data-name="Path 60967"
              d="M44.984 38.357a.107.107 0 01-.107-.107v-.538a.106.106 0 01.053-.092l4.546-2.626a.107.107 0 01.16.092v.538a.107.107 0 01-.053.092l-4.548 2.626a.106.106 0 01-.053.014m.107-.583v.291l4.335-2.5v-.291z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60968" d="M44.319 30.942l-5.457-3.151v-9.437l5.462 3.135z" fill="#d0dadd" />
            <path
              data-name="Path 60969"
              d="M44.319 31.046a.106.106 0 01-.053-.014l-5.457-3.151a.106.106 0 01-.053-.092v-9.437a.107.107 0 01.16-.093l5.462 3.135a.107.107 0 01.054.093l-.005 9.453a.107.107 0 01-.107.107m-5.35-3.319l5.243 3.027.005-9.206-5.249-3.013z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60970" d="M38.862 27.788v-9.445l.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 60971"
              d="M39.772 28.419a.107.107 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-9.445a.107.107 0 01.16-.093l.909.519a.106.106 0 01.054.093v9.45a.107.107 0 01-.107.107m-.8-.693l.7.4v-9.2l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60972" d="M43.41 31.984l-5.453-3.151v-.525l5.453 3.153z" fill="#fff" />
            <path
              data-name="Path 60973"
              d="M43.403 32.095a.108.108 0 01-.053-.014l-5.453-3.151a.107.107 0 01-.053-.092v-.525a.107.107 0 01.16-.092l5.453 3.149a.106.106 0 01.053.092v.527a.107.107 0 01-.107.107m-5.346-3.319l5.24 3.028v-.281l-5.24-3.025z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60974" d="M43.407 31.989v-.527l.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 60975"
              d="M43.407 32.096a.107.107 0 01-.107-.107v-.527a.107.107 0 01.054-.093l.912-.523a.107.107 0 01.16.093v.526a.107.107 0 01-.053.092l-.91.524a.107.107 0 01-.053.014m.107-.572v.281l.7-.4v-.279z"
              fill="#1d1d1b"
            />
            <path data-name="Path 60976" d="M43.41 31.461l-5.453-3.149 1.818-1.05 4.553 2.63-.005 1.045z" fill="#fff" />
            <path
              data-name="Path 60977"
              d="M43.403 31.568a.107.107 0 01-.053-.014l-5.453-3.149a.107.107 0 010-.185l1.818-1.05a.107.107 0 01.107 0l4.553 2.63a.107.107 0 01.053.093l-.005 1.045a.107.107 0 01-.054.092l-.912.523a.106.106 0 01-.053.014m-5.24-3.256l5.24 3.026.806-.462v-.922l-4.446-2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1097" fill="#fff" d="M41.961 20.157v8.399" />
            <path
              data-name="Path 60978"
              d="M41.961 28.664a.107.107 0 01-.107-.107v-8.4a.107.107 0 01.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1098" fill="#ffc200" d="M39.772 23.077l4.552 2.613" />
            <path
              data-name="Path 60979"
              d="M44.324 25.797a.105.105 0 01-.053-.014l-4.552-2.613a.107.107 0 01.106-.185l4.552 2.613a.107.107 0 01-.053.2"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60980"
              d="M50.008 43.43a2.591 2.591 0 01-2.351-.024.91.91 0 01-.454-.536l.421 1.721a.675.675 0 00.337.4 1.921 1.921 0 001.743.018.689.689 0 00.361-.408l.43-1.718a.929.929 0 01-.486.551"
              fill="#fff"
            />
            <path
              data-name="Path 60981"
              d="M48.848 45.314a1.873 1.873 0 01-.942-.235.777.777 0 01-.385-.464l-.421-1.721.207-.051a.809.809 0 00.4.469 2.477 2.477 0 002.244.022.837.837 0 00.435-.483l.207.052-.43 1.718a.789.789 0 01-.412.475 1.886 1.886 0 01-.908.216m-1.409-1.929l.288 1.179a.573.573 0 00.288.331 1.806 1.806 0 001.636.016.6.6 0 00.309-.341l.295-1.178a1.538 1.538 0 01-.2.129 2.713 2.713 0 01-2.458-.025 1.511 1.511 0 01-.163-.112"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60982"
              d="M50.072 42.845a2.735 2.735 0 01-2.482-.025c-.676-.4-.66-1.048.035-1.438a2.736 2.736 0 012.482.025c.676.4.66 1.048-.035 1.438"
              fill="#fff"
            />
            <path
              data-name="Path 60983"
              d="M48.855 43.235a2.632 2.632 0 01-1.32-.324.964.964 0 01-.549-.811.987.987 0 01.586-.812 2.857 2.857 0 012.589.027.964.964 0 01.549.811.988.988 0 01-.586.812 2.649 2.649 0 01-1.269.3m-.012-2.033a2.433 2.433 0 00-1.166.273.788.788 0 00-.477.629.764.764 0 00.445.625 2.621 2.621 0 002.375.024.788.788 0 00.477-.629.764.764 0 00-.445-.625 2.412 2.412 0 00-1.21-.3"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60984"
              d="M49.831 42.578a2.5 2.5 0 01-2-.018c-.544-.283-.531-.733.028-1.006a2.5 2.5 0 012 .017c.544.283.531.733-.028 1.006"
              fill="#fff"
            />
            <path
              data-name="Path 60985"
              d="M48.853 42.884a2.387 2.387 0 01-1.067-.228.707.707 0 01-.457-.6.729.729 0 01.488-.6 2.62 2.62 0 012.093.019.707.707 0 01.457.6.729.729 0 01-.488.6 2.406 2.406 0 01-1.026.209m-.01-1.422a2.188 2.188 0 00-.933.19c-.232.113-.366.263-.368.41s.123.291.342.4a2.379 2.379 0 001.9.016c.232-.113.366-.263.368-.41s-.123-.291-.342-.4a2.163 2.163 0 00-.968-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60986"
              d="M50.12 42.293a.693.693 0 00-.31-.274 2.38 2.38 0 00-1.9-.016.713.713 0 00-.336.286l-.191-.1a.917.917 0 01.433-.381 2.62 2.62 0 012.093.019.9.9 0 01.4.367z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60987"
              d="M50.073 42.844a2.735 2.735 0 01-2.482-.025.869.869 0 01-.5-.706v.591a.859.859 0 00.5.724 2.735 2.735 0 002.482.025.881.881 0 00.532-.731v-.608a.881.881 0 01-.532.731"
              fill="#fff"
            />
            <path
              data-name="Path 60988"
              d="M48.856 43.844a2.632 2.632 0 01-1.32-.324.96.96 0 01-.548-.818v-.593h.214a.771.771 0 00.445.615 2.621 2.621 0 002.375.024.785.785 0 00.477-.632v-.007h.214v.609a.979.979 0 01-.586.825 2.649 2.649 0 01-1.27.3m-1.655-1.2v.065a.76.76 0 00.444.633 2.621 2.621 0 002.375.024.78.78 0 00.477-.638v-.077a1.407 1.407 0 01-.373.293 2.857 2.857 0 01-2.59-.027 1.382 1.382 0 01-.335-.273"
              fill="#1d1d1b"
            />
            <path data-name="Line 1099" fill="#fff" d="M48.846 39.025v-2.31" />
            <path
              data-name="Path 60989"
              d="M48.846 39.158a.133.133 0 01-.133-.133v-2.31a.134.134 0 11.267 0v2.31a.133.133 0 01-.133.133"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60990"
              d="M48.846 42.492a.16.16 0 01-.16-.16v-3.207a.16.16 0 11.32 0v3.207a.16.16 0 01-.16.16"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60991"
              d="M51.175 41.168c-.744.507-1.585.567-1.879.136s.071-1.192.815-1.7a1.777 1.777 0 011.66-.327 2.849 2.849 0 00.385.118s-.116.16-.1.207c.153.442-.21 1.107-.882 1.564"
              fill="#fff"
            />
            <path
              data-name="Path 60992"
              d="M49.96 41.699a.864.864 0 01-.752-.334.914.914 0 01.01-.927 2.441 2.441 0 01.833-.919 1.873 1.873 0 011.769-.333 2.57 2.57 0 00.361.109.107.107 0 01.061.166.773.773 0 00-.078.131 1.721 1.721 0 01-.929 1.666 2.441 2.441 0 01-1.16.438h-.114m1.365-2.41a2.165 2.165 0 00-1.154.405 2.225 2.225 0 00-.76.835.6.6 0 00.648.952 2.225 2.225 0 001.056-.4c.618-.421.979-1.04.841-1.441-.008-.022-.023-.067.032-.175a1.659 1.659 0 01-.266-.09.858.858 0 00-.4-.085m.832.28"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60993"
              d="M48.824 41.84a.107.107 0 01-.092-.161 8.942 8.942 0 012.045-1.631.107.107 0 01.091.193 9.138 9.138 0 00-1.953 1.547.107.107 0 01-.092.052"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60994"
              d="M46.353 40.641c.779.53 1.66.594 1.967.142s-.074-1.248-.853-1.778a1.861 1.861 0 00-1.738-.342 2.972 2.972 0 01-.4.123s.121.167.1.217c-.16.463.22 1.159.923 1.638"
              fill="#fff"
            />
            <path
              data-name="Path 60995"
              d="M47.618 41.194a2.456 2.456 0 01-1.329-.465c-.728-.5-1.132-1.224-.971-1.741a.793.793 0 00-.083-.14.107.107 0 01.061-.167 2.691 2.691 0 00.379-.114 1.957 1.957 0 011.848.349 2.55 2.55 0 01.87.961.952.952 0 01.011.966.908.908 0 01-.787.35m-2.128-2.341c.061.116.044.164.037.185-.146.422.234 1.073.883 1.515.717.488 1.549.566 1.819.17a.752.752 0 00-.027-.753 2.336 2.336 0 00-.8-.877 1.778 1.778 0 00-1.629-.336 1.784 1.784 0 01-.284.1m-.165.115z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60996"
              d="M48.812 41.34a.107.107 0 01-.092-.052 9.577 9.577 0 00-2.047-1.622.107.107 0 11.091-.193 9.352 9.352 0 012.14 1.706.107.107 0 01-.092.161"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60997"
              d="M51.029 39.525c-.789.278-1.528.108-1.651-.38s.417-1.109 1.206-1.387a1.478 1.478 0 011.52.139 2.383 2.383 0 00.3.216s-.147.12-.147.169c0 .46-.511.991-1.223 1.242"
              fill="#fff"
            />
            <path
              data-name="Path 60998"
              d="M50.291 39.761a1.371 1.371 0 01-.646-.144.707.707 0 01-.371-.451c-.138-.548.421-1.213 1.273-1.514a1.574 1.574 0 011.634.168 2.149 2.149 0 00.274.2.107.107 0 01.01.173.757.757 0 00-.108.106c-.014.509-.555 1.063-1.294 1.323a2.348 2.348 0 01-.772.14m1.029-2.034a2.144 2.144 0 00-.7.127c-.724.256-1.245.833-1.137 1.26a.494.494 0 00.265.315 1.64 1.64 0 001.247-.01c.655-.231 1.151-.722 1.152-1.142 0-.021 0-.067.081-.155a1.389 1.389 0 01-.2-.158.95.95 0 00-.7-.238"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 60999"
              d="M48.844 39.53a.107.107 0 01-.074-.183 8.256 8.256 0 012.244-.992.107.107 0 01.037.21 8.4 8.4 0 00-2.132.934.106.106 0 01-.075.03"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61000"
              d="M46.888 39.047c.707.249 1.369.1 1.479-.34s-.373-.993-1.08-1.243a1.324 1.324 0 00-1.362.125 2.128 2.128 0 01-.264.193s.132.107.132.152c0 .412.457.888 1.1 1.113"
              fill="#fff"
            />
            <path
              data-name="Path 61001"
              d="M47.547 39.274a2.112 2.112 0 01-.7-.126c-.665-.234-1.151-.733-1.167-1.192a.707.707 0 00-.093-.09.107.107 0 01.01-.173 1.909 1.909 0 00.243-.176 1.421 1.421 0 011.476-.153c.769.271 1.273.873 1.148 1.369a.644.644 0 01-.338.411 1.24 1.24 0 01-.585.13m-1.716-1.477c.067.075.067.116.067.137 0 .371.442.807 1.025 1.012a1.459 1.459 0 001.108.01.432.432 0 00.232-.275c.1-.378-.368-.889-1.012-1.116a1.238 1.238 0 00-1.248.1 1.2 1.2 0 01-.171.136"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61002"
              d="M48.845 39.063a.106.106 0 01-.074-.03 7.507 7.507 0 00-1.9-.834.107.107 0 11.037-.21 7.417 7.417 0 012.016.891.107.107 0 01-.075.183"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61003"
              d="M50.712 37.766c-.579.474-1.253.556-1.505.183s.013-1.061.593-1.535a1.323 1.323 0 011.323-.346 2.124 2.124 0 00.314.092s-.087.146-.072.187a1.472 1.472 0 01-.652 1.418"
              fill="#fff"
            />
            <path
              data-name="Path 61004"
              d="M49.719 38.291a.969.969 0 01-.139-.01.644.644 0 01-.457-.271c-.287-.424-.017-1.161.613-1.677a1.421 1.421 0 011.44-.357 1.924 1.924 0 00.288.082.107.107 0 01.068.159.714.714 0 00-.057.116 1.587 1.587 0 01-.691 1.517 1.769 1.769 0 01-1.065.441m1.084-2.187a1.577 1.577 0 00-.932.394c-.528.432-.79 1.07-.572 1.393a.431.431 0 00.311.18 1.455 1.455 0 001.038-.386 1.383 1.383 0 00.619-1.3.192.192 0 01.017-.151 1.187 1.187 0 01-.207-.069.59.59 0 00-.274-.06m.665.209"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61005"
              d="M48.846 38.454a.107.107 0 01-.1-.153 7.414 7.414 0 011.592-1.523.107.107 0 01.106.185 7.5 7.5 0 00-1.506 1.431.107.107 0 01-.1.06"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61006"
              d="M47.091 36.86c.533.437 1.154.512 1.386.169s-.012-.977-.546-1.413a1.218 1.218 0 00-1.218-.318 1.96 1.96 0 01-.289.085s.081.134.067.172a1.355 1.355 0 00.6 1.306"
              fill="#fff"
            />
            <path
              data-name="Path 61007"
              d="M48.009 37.352a1.637 1.637 0 01-.987-.408 1.472 1.472 0 01-.64-1.4.669.669 0 00-.051-.1.107.107 0 01.068-.159 1.756 1.756 0 00.263-.075 1.316 1.316 0 011.335.33c.584.478.833 1.161.566 1.556a.6.6 0 01-.427.254.889.889 0 01-.129.009m-.851-.574a1.333 1.333 0 00.949.353.389.389 0 00.281-.162c.2-.294-.042-.876-.525-1.271a1.132 1.132 0 00-1.1-.306 1.066 1.066 0 01-.183.062.176.176 0 01.012.136 1.265 1.265 0 00.567 1.187"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61008"
              d="M48.812 37.501a.107.107 0 01-.1-.06 6.891 6.891 0 00-1.383-1.314.107.107 0 11.106-.185 6.84 6.84 0 011.469 1.406.107.107 0 01-.1.153"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61009"
              d="M50.434 35.39c-.43.539-1.022.743-1.321.456s-.193-.958.237-1.5a1.219 1.219 0 011.125-.567 1.957 1.957 0 00.3.022s-.051.148-.029.183a1.356 1.356 0 01-.313 1.4"
              fill="#fff"
            />
            <path
              data-name="Path 61010"
              d="M49.459 36.083a.589.589 0 01-.419-.16c-.343-.33-.243-1.05.228-1.641a1.317 1.317 0 011.236-.6 1.754 1.754 0 00.273.018.107.107 0 01.1.141.657.657 0 00-.028.111 1.473 1.473 0 01-.332 1.507 1.525 1.525 0 01-1.007.625h-.051m.865-2.214a1.291 1.291 0 00-.889.546c-.389.488-.5 1.108-.247 1.354a.39.39 0 00.309.1 1.335 1.335 0 00.855-.545 1.266 1.266 0 00.306-1.28.176.176 0 01-.017-.136 1.068 1.068 0 01-.192-.022.459.459 0 00-.123-.016m.514.061"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61011"
              d="M48.847 36.976a.107.107 0 01-.107-.106 3.9 3.9 0 011.161-2.306.107.107 0 01.143.159 3.713 3.713 0 00-1.09 2.146.107.107 0 01-.106.107z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61012"
              d="M49.645 39.488c-.1.318-.6.452-1.191.344a.924.924 0 00-.341.112s-.138-.249-.227-.289c-.54-.243-.876-.637-.778-.953.213-.687 1.056-.04 1.372.058.363.113 1.377.045 1.165.728"
              fill="#fff"
            />
            <path
              data-name="Path 61013"
              d="M48.114 40.05a.107.107 0 01-.094-.055.922.922 0 00-.178-.244c-.6-.272-.948-.717-.835-1.081a.517.517 0 01.284-.348c.29-.123.666.067.968.22a2.065 2.065 0 00.254.116 2.3 2.3 0 00.309.056c.325.045.729.1.89.357a.521.521 0 01.035.449c-.114.368-.654.539-1.312.417a.92.92 0 00-.272.1.107.107 0 01-.049.012m-.617-1.555a.275.275 0 00-.286.237c-.078.25.232.6.72.824a.688.688 0 01.225.248.656.656 0 01.319-.078c.531.1.991-.018 1.069-.27a.317.317 0 00-.011-.271c-.109-.171-.459-.221-.74-.26a2.4 2.4 0 01-.343-.064 2.107 2.107 0 01-.287-.13 1.768 1.768 0 00-.666-.236m2.149.992z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61014"
              d="M48.289 39.306h-.008a.107.107 0 01-.1-.114.936.936 0 01.631-.719.107.107 0 01.063.2.736.736 0 00-.481.531.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61015"
              d="M49.597 36.916c-.078.253-.48.36-.947.273a.735.735 0 00-.271.089s-.11-.2-.181-.23c-.43-.193-.7-.507-.619-.758.169-.546.84-.032 1.092.047.289.09 1.1.036.927.58"
              fill="#fff"
            />
            <path
              data-name="Path 61016"
              d="M48.38 37.385a.107.107 0 01-.094-.055.763.763 0 00-.134-.186c-.489-.22-.768-.585-.674-.886a.432.432 0 01.238-.29c.24-.1.544.052.789.176a1.657 1.657 0 00.2.091 1.815 1.815 0 00.243.044c.263.037.59.083.724.294a.435.435 0 01.03.375c-.094.3-.534.446-1.069.347a.811.811 0 00-.2.079.106.106 0 01-.049.012m-.491-1.237a.2.2 0 00-.207.173c-.059.189.183.459.561.629a.526.526 0 01.179.189.5.5 0 01.249-.056c.412.076.767-.01.826-.2a.231.231 0 00-.007-.2c-.081-.128-.366-.168-.573-.2a1.9 1.9 0 01-.276-.051 1.7 1.7 0 01-.231-.1 1.4 1.4 0 00-.52-.186m1.709.768z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61017"
              d="M48.517 36.793h-.007a.107.107 0 01-.1-.114.768.768 0 01.517-.591.107.107 0 11.063.2.57.57 0 00-.367.4.107.107 0 01-.106.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61018"
              d="M15.611 42.967h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61019"
              d="M15.611 43.072h-.033a.306.306 0 01-.306-.306v-1.349a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.355a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.348a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61020"
              d="M25.853 39.987h-.035a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61021"
              d="M25.852 40.095h-.033a.306.306 0 01-.306-.306V38.44a.306.306 0 01.306-.306h.033a.306.306 0 01.306.306v1.349a.306.306 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61022"
              d="M18.198 44.433h-.033a.2.2 0 01-.2-.2v-1.349a.2.2 0 01.2-.2h.033a.2.2 0 01.2.2v1.349a.2.2 0 01-.2.2"
              fill="#fff"
            />
            <path
              data-name="Path 61023"
              d="M18.192 44.541h-.033a.306.306 0 01-.306-.306v-1.349a.307.307 0 01.306-.306h.033a.307.307 0 01.306.306v1.349a.307.307 0 01-.306.306m-.033-1.747a.093.093 0 00-.093.093v1.349a.093.093 0 00.093.093h.033a.093.093 0 00.093-.093v-1.349a.093.093 0 00-.093-.093z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61024" d="M26.166 36.621v2.321l-7.986 4.641v-2.321z" fill="#fff" />
            <path
              data-name="Path 61025"
              d="M18.178 43.689a.107.107 0 01-.107-.107v-2.321a.107.107 0 01.053-.092l7.986-4.641a.107.107 0 01.16.092v2.321a.107.107 0 01-.053.092l-7.986 4.641a.107.107 0 01-.054.014m.108-2.366v2.074l7.773-4.517v-2.073z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61026" d="M18.18 41.262v2.321l-2.926-1.689v-2.321z" fill="#fff" />
            <path
              data-name="Path 61027"
              d="M18.178 43.688a.107.107 0 01-.053-.014l-2.926-1.689a.106.106 0 01-.053-.093v-2.321a.107.107 0 01.16-.092l2.926 1.689a.107.107 0 01.053.093v2.321a.107.107 0 01-.107.107m-2.819-1.858l2.712 1.566v-2.074l-2.706-1.564z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61028" d="M26.166 36.62l-7.986 4.641-2.926-1.689 7.986-4.641z" fill="#fff" />
            <path
              data-name="Path 61029"
              d="M18.179 41.368a.107.107 0 01-.053-.014L15.2 39.665a.107.107 0 010-.185l7.986-4.641a.106.106 0 01.107 0l2.926 1.689a.107.107 0 010 .185l-7.986 4.641a.107.107 0 01-.054.015m-2.713-1.8l2.713 1.566 7.774-4.517-2.712-1.566z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61030"
              d="M20.71 42.223a.107.107 0 01-.107-.107v-2.32a.107.107 0 11.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61031"
              d="M23.524 40.586a.107.107 0 01-.107-.107v-2.32a.107.107 0 11.214 0v2.32a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Path 61032" d="M18.988 39.887l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61033"
              d="M18.987 40.106a.053.053 0 01-.053-.053v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.053.053 0 01-.027.007m.054-.188v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61034" d="M16.383 38.451l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61035"
              d="M18.988 39.94a.053.053 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.053.053 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61036" d="M18.988 39.887v.166l-2.606-1.436v-.167z" fill="#ffc200" />
            <path
              data-name="Path 61037"
              d="M18.987 40.106a.054.054 0 01-.026-.007l-2.606-1.436a.054.054 0 01-.028-.047v-.166a.053.053 0 01.026-.046.052.052 0 01.053 0l2.606 1.436a.054.054 0 01.028.047v.166a.054.054 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61038" d="M24.749 36.485l.156-.091v.166l-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61039"
              d="M24.748 36.702a.054.054 0 01-.027-.007.053.053 0 01-.027-.046v-.166a.053.053 0 01.027-.046l.156-.091a.053.053 0 01.08.046v.166a.053.053 0 01-.027.046l-.156.091a.055.055 0 01-.027.007m.054-.189v.042l.049-.029v-.042z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61040" d="M22.143 35.046l.156-.091 2.606 1.436-.156.091z" fill="#ffc200" />
            <path
              data-name="Path 61041"
              d="M24.749 36.536a.053.053 0 01-.026-.007l-2.606-1.436a.053.053 0 010-.093l.156-.091a.054.054 0 01.053 0l2.606 1.436a.053.053 0 010 .093l-.156.091a.053.053 0 01-.027.007m-2.5-1.491l2.5 1.376.048-.028-2.5-1.376z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61042" d="M24.748 36.482v.166l-2.606-1.436v-.17z" fill="#ffc200" />
            <path
              data-name="Path 61043"
              d="M24.748 36.701a.053.053 0 01-.026-.007l-2.606-1.436a.054.054 0 01-.028-.047v-.166a.053.053 0 01.026-.046.053.053 0 01.053 0l2.606 1.436a.054.054 0 01.028.047v.166a.054.054 0 01-.053.053m-2.552-1.521l2.5 1.377v-.044l-2.5-1.377z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61044"
              d="M17.248 40.154l7.8-4.545a.421.421 0 00.211-.365V29.09a.421.421 0 00-.632-.365l-7.8 4.545a.421.421 0 00-.211.365v6.154a.421.421 0 00.632.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 61045"
              d="M17.037 40.318a.53.53 0 01-.528-.529v-6.154a.53.53 0 01.264-.457l7.8-4.545a.528.528 0 01.793.457v6.154a.53.53 0 01-.264.457l-7.8 4.545a.528.528 0 01-.264.072m.211-.164zm7.593-11.378a.315.315 0 00-.157.043l-7.8 4.545a.316.316 0 00-.158.273v6.154a.315.315 0 00.472.273l7.8-4.545a.315.315 0 00.158-.273v-6.154a.312.312 0 00-.157-.273.315.315 0 00-.157-.043"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61046"
              d="M16.411 33.042l7.8-4.545a.418.418 0 01.455.022l.376.205a.417.417 0 00-.419 0l-7.8 4.545a.422.422 0 00-.211.365v6.154a.416.416 0 00.187.35l-.331-.187a.43.43 0 01-.116-.069.415.415 0 01-.149-.321v-6.154a.421.421 0 01.211-.365"
              fill="#ffc200"
            />
            <path
              data-name="Path 61047"
              d="M16.803 40.245a.106.106 0 01-.052-.014l-.326-.183a.54.54 0 01-.136-.082h-.005a.518.518 0 01-.187-.4v-6.154a.53.53 0 01.264-.457l7.8-4.545a.523.523 0 01.562.022l.359.2.016.009a.107.107 0 01-.1.186l-.013-.007a.311.311 0 00-.3.007l-7.8 4.545a.316.316 0 00-.158.273v6.154a.309.309 0 00.14.261.107.107 0 01-.006.181.109.109 0 01-.054.014m-.379-.444a.32.32 0 00.087.052.546.546 0 010-.065v-6.154a.53.53 0 01.264-.457l7.8-4.545.042-.022h-.007a.312.312 0 00-.34-.017l-7.8 4.545a.315.315 0 00-.157.272v6.154a.308.308 0 00.111.239m-.009-6.759z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61048"
              d="M17.476 39.718l7.31-4.252a.386.386 0 00.193-.334V29.5a.386.386 0 00-.579-.334l-7.31 4.252a.386.386 0 00-.193.334v5.632a.386.386 0 00.578.334"
              fill="#ffc200"
            />
            <path
              data-name="Path 61049"
              d="M17.284 39.823a.441.441 0 01-.439-.44v-5.632a.44.44 0 01.219-.38l7.31-4.252a.439.439 0 01.659.38v5.632a.44.44 0 01-.22.38l-7.31 4.252a.438.438 0 01-.219.06m.193-.106zm7.117-10.551a.333.333 0 00-.166.045l-7.31 4.252a.334.334 0 00-.166.288v5.632a.332.332 0 00.5.288l7.31-4.252a.333.333 0 00.166-.288v-5.632a.334.334 0 00-.332-.333"
              fill="#1d1d1b"
            />
            <path data-name="Path 61050" d="M35.299 42.773l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61051"
              d="M35.289 46.477a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.106.106 0 01-.054.015m.117-3.642l-.01 3.349.377-.219.009-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61052" d="M34.701 42.426l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61053"
              d="M35.299 42.88a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61054" d="M35.298 42.773l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61055"
              d="M35.288 46.477a.107.107 0 01-.053-.014l-.594-.343a.107.107 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61056" d="M24.417 46.042l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61057"
              d="M24.407 49.736a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.107.107 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61058" d="M23.823 45.694l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61059"
              d="M24.417 46.144a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.382-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61060" d="M24.417 46.041l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61061"
              d="M24.407 49.741a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61062" d="M35.295 44.164v.549l-8.235 4.786v-.549z" fill="#fff" />
            <path
              data-name="Path 61063"
              d="M27.058 49.606a.107.107 0 01-.107-.107v-.549a.106.106 0 01.053-.092l8.235-4.786a.107.107 0 01.16.093v.549a.107.107 0 01-.053.092l-8.235 4.786a.107.107 0 01-.054.014m.108-.594v.3l8.021-4.662v-.3z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61064" d="M27.06 48.95v.549l-2.646-1.528v-.549z" fill="#fff" />
            <path
              data-name="Path 61065"
              d="M27.058 49.606a.107.107 0 01-.053-.014l-2.647-1.524a.107.107 0 01-.053-.093v-.549a.107.107 0 01.16-.092l2.646 1.528a.107.107 0 01.053.093v.549a.107.107 0 01-.107.106m-2.54-1.7l2.433 1.4v-.3l-2.433-1.4z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61066"
              d="M34.7 43.818l.595.346-8.235 4.786-2.646-1.528.591-.34v-.688l7.648-4.444 2.049 1.183z"
              fill="#fff"
            />
            <path
              data-name="Path 61067"
              d="M27.06 49.057a.107.107 0 01-.053-.014l-2.646-1.528a.107.107 0 010-.185l.537-.309v-.626a.107.107 0 01.053-.092l7.654-4.446a.106.106 0 01.107 0l2.049 1.183a.107.107 0 01.053.093v.623l.541.315a.107.107 0 010 .185l-8.235 4.786a.107.107 0 01-.054.014m-2.433-1.635l2.432 1.4 8.023-4.662-.436-.254a.107.107 0 01-.053-.093v-.623l-1.941-1.121-7.543 4.389v.627a.107.107 0 01-.054.092z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61068" d="M27.059 47.561l.591-.343-.01 3.6-.591.343z" fill="#fff" />
            <path
              data-name="Path 61069"
              d="M27.048 51.265a.107.107 0 01-.107-.107l.01-3.6a.107.107 0 01.053-.092l.591-.343a.107.107 0 01.16.093l-.01 3.6a.107.107 0 01-.053.092l-.591.343a.106.106 0 01-.054.014m.117-3.642l-.009 3.349.377-.219.01-3.349z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61070" d="M26.463 47.218l.591-.343.594.343-.591.343z" fill="#fff" />
            <path
              data-name="Path 61071"
              d="M27.058 47.668a.106.106 0 01-.053-.014l-.594-.343a.107.107 0 010-.185l.591-.343a.106.106 0 01.107 0l.594.343a.107.107 0 010 .185l-.591.343a.107.107 0 01-.054.014m-.381-.45l.381.22.378-.22-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61072" d="M27.058 47.561l-.01 3.6-.594-.343.01-3.6z" fill="#fff" />
            <path
              data-name="Path 61073"
              d="M27.048 51.265a.107.107 0 01-.053-.014l-.594-.343a.106.106 0 01-.053-.093l.01-3.6a.107.107 0 01.16-.092l.594.343a.107.107 0 01.053.093l-.01 3.6a.107.107 0 01-.107.107m-.488-.512l.381.22.009-3.35-.381-.22z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61074" d="M35.891 41.747v.683l-8.831 5.132v-.683z" fill="#fff" />
            <path
              data-name="Path 61075"
              d="M27.058 47.664a.107.107 0 01-.107-.107v-.683a.107.107 0 01.053-.092l8.831-5.132a.107.107 0 01.16.093v.683a.107.107 0 01-.053.092l-8.831 5.132a.106.106 0 01-.054.015m.109-.728v.436l8.617-5.008v-.436z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61076" d="M27.06 46.879v.683l-3.235-1.868v-.683z" fill="#fff" />
            <path
              data-name="Path 61077"
              d="M27.058 47.669a.106.106 0 01-.053-.014l-3.235-1.868a.107.107 0 01-.053-.093v-.683a.107.107 0 01.16-.092l3.235 1.868a.107.107 0 01.053.093v.683a.107.107 0 01-.107.106m-3.128-2.036l3.022 1.745v-.437l-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61078" d="M35.891 41.747l-8.831 5.132-3.235-1.868 8.831-5.132z" fill="#fff" />
            <path
              data-name="Path 61079"
              d="M27.06 46.986a.106.106 0 01-.053-.014l-3.235-1.868a.107.107 0 010-.185l8.831-5.132a.106.106 0 01.107 0l3.235 1.868a.107.107 0 010 .185l-8.831 5.132a.107.107 0 01-.054.014m-3.022-1.975l3.022 1.745 8.618-5.008-3.022-1.745z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61080"
              d="M31.865 54.888h-.018a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61081"
              d="M31.865 54.997h-.02a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61082"
              d="M41.38 49.214h-.018a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61083"
              d="M41.381 49.321h-.018a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61084"
              d="M27.83 53.094h-.015a.232.232 0 01-.232-.232v-1.483a.232.232 0 01.232-.232h.018a.232.232 0 01.232.232v1.483a.232.232 0 01-.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61085"
              d="M27.83 53.203h-.022a.339.339 0 01-.338-.339v-1.483a.339.339 0 01.338-.338h.018a.339.339 0 01.338.338v1.483a.339.339 0 01-.338.339m-.018-1.947a.125.125 0 00-.125.125v1.483a.125.125 0 00.125.125h.018a.125.125 0 00.125-.125v-1.483a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61086" d="M40.938 45.095l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 61087"
              d="M40.928 48.762a.106.106 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.877-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.876.509a.106.106 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61088" d="M36.953 42.798l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 61089"
              d="M40.938 45.204a.106.106 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.877-.509a.107.107 0 01.107 0l3.984 2.3a.107.107 0 010 .185l-.876.509a.107.107 0 01-.054.014m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61090" d="M40.938 45.097l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 61091"
              d="M40.928 48.765a.106.106 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61092" d="M39.989 45.473v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 61093"
              d="M35.695 49.087a.106.106 0 01-.107-.107v-1.014a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61094" d="M35.698 47.966v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 61095"
              d="M35.695 49.087a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106m-2.989-1.956l2.883 1.665v-.768l-2.883-1.664z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61096" d="M39.989 45.473l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 61097"
              d="M35.698 48.073a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.015m-2.883-1.895l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61098" d="M35.697 47.967v1.014l-4.292 2.494v-1.014z" fill="#fff" />
            <path
              data-name="Path 61099"
              d="M31.403 51.581a.106.106 0 01-.107-.107V50.46a.107.107 0 01.053-.092l4.292-2.494a.107.107 0 01.16.093v1.014a.107.107 0 01-.053.092l-4.292 2.494a.107.107 0 01-.054.014m.109-1.059v.766l4.078-2.37v-.766z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61100" d="M31.406 50.46v1.014l-3.1-1.788v-1.014z" fill="#fff" />
            <path
              data-name="Path 61101"
              d="M31.403 51.581a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 01-.053-.093v-1.014a.107.107 0 01.16-.092l3.1 1.788a.107.107 0 01.053.093v1.014a.107.107 0 01-.107.106m-2.989-1.956l2.883 1.665v-.768l-2.883-1.664z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61102" d="M35.697 47.966l-4.292 2.494-3.1-1.788 4.292-2.494z" fill="#fff" />
            <path
              data-name="Path 61103"
              d="M31.404 50.568a.107.107 0 01-.053-.014l-3.1-1.788a.107.107 0 010-.185l4.292-2.494a.106.106 0 01.107 0l3.1 1.788a.107.107 0 010 .185l-4.292 2.494a.106.106 0 01-.054.015m-2.883-1.895l2.883 1.665 4.079-2.37-2.883-1.665z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61104"
              d="M37.598 44.196s.115.153.144.208a12.452 12.452 0 01.485 1.3.648.648 0 01-.007.3 2.762 2.762 0 00.131-.261 8.266 8.266 0 01.49-1.006 2.2 2.2 0 01.57-.58.6.6 0 01.218-.107.815.815 0 01-.157-.2l-.554-1.056a4.428 4.428 0 01-.106-.275.665.665 0 01-.19.2 1.953 1.953 0 00-.46.353 4.945 4.945 0 00-.438.967.369.369 0 01-.125.159"
              fill="#fff"
            />
            <path
              data-name="Path 61105"
              d="M38.22 46.103a.107.107 0 01-.1-.133.524.524 0 00.009-.235 12.709 12.709 0 00-.478-1.281 2.578 2.578 0 00-.134-.193.107.107 0 01.025-.152.263.263 0 00.085-.108 4.973 4.973 0 01.453-1 1.881 1.881 0 01.49-.38.559.559 0 00.154-.165.107.107 0 01.193.018c.039.109.087.236.1.261l.554 1.056a.705.705 0 00.133.166.107.107 0 01-.047.184.49.49 0 00-.173.084l-.12.1a1.687 1.687 0 00-.43.459 8.28 8.28 0 00-.48.986 2.816 2.816 0 01-.138.275.107.107 0 01-.093.054m-.479-1.894a1.543 1.543 0 01.1.145 11.413 11.413 0 01.463 1.226 8.047 8.047 0 01.452-.909 1.915 1.915 0 01.476-.509l.115-.093a.573.573 0 01.1-.069.7.7 0 01-.068-.1l-.554-1.056a1.513 1.513 0 01-.046-.11.574.574 0 01-.1.079 1.632 1.632 0 00-.431.326 4.493 4.493 0 00-.423.94.428.428 0 01-.082.136"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61106"
              d="M38.22 45.997a2.764 2.764 0 00.131-.261 8.259 8.259 0 01.49-1.006 2.2 2.2 0 01.57-.58.6.6 0 01.217-.107 1.545 1.545 0 01-.44 1.417 3.739 3.739 0 01-.968.537"
              fill="#fff"
            />
            <path
              data-name="Path 61107"
              d="M38.22 46.103a.107.107 0 01-.093-.159 2.679 2.679 0 00.125-.247 8.253 8.253 0 01.5-1.025 1.91 1.91 0 01.476-.509l.115-.093a.692.692 0 01.258-.129h.017a.1.1 0 01.03 0 .107.107 0 01.044.018.108.108 0 01.044.076 1.644 1.644 0 01-.475 1.507 3.794 3.794 0 01-1 .558.105.105 0 01-.037.007m1.313-1.909a.337.337 0 00-.053.036l-.12.1a1.683 1.683 0 00-.43.459 8.279 8.279 0 00-.48.986v.01a2.869 2.869 0 00.67-.4 1.362 1.362 0 00.417-1.186"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61108"
              d="M36.959 44.72s.066.155.079.208a10.986 10.986 0 01.141 1.21.568.568 0 01-.068.25 2.407 2.407 0 00.167-.2 7.285 7.285 0 01.63-.756 1.94 1.94 0 01.609-.375.523.523 0 01.208-.045.713.713 0 01-.092-.2l-.25-1.018a4.245 4.245 0 01-.033-.257.583.583 0 01-.205.132 1.716 1.716 0 00-.467.2 4.359 4.359 0 00-.578.734.325.325 0 01-.14.109"
              fill="#fff"
            />
            <path
              data-name="Path 61109"
              d="M37.111 46.495a.107.107 0 01-.094-.157.448.448 0 00.056-.192 11.187 11.187 0 00-.138-1.192 1.95 1.95 0 00-.073-.191.107.107 0 01.06-.141.216.216 0 00.089-.069 4.374 4.374 0 01.6-.757 1.671 1.671 0 01.5-.223.472.472 0 00.161-.1.107.107 0 01.183.064c.01.1.025.219.03.242l.25 1.019a.609.609 0 00.077.166.107.107 0 01-.088.167.414.414 0 00-.159.034l-.124.059a1.475 1.475 0 00-.46.3 7.283 7.283 0 00-.615.739 2.483 2.483 0 01-.177.207.107.107 0 01-.078.033m-.017-1.729a1.3 1.3 0 01.048.136 9.749 9.749 0 01.134 1.112 6.947 6.947 0 01.559-.656 1.692 1.692 0 01.517-.337c.038-.017.077-.035.117-.055a.5.5 0 01.09-.034.644.644 0 01-.031-.091l-.25-1.019c0-.017-.009-.05-.014-.087a.487.487 0 01-.088.038 1.584 1.584 0 00-.432.185 3.937 3.937 0 00-.556.71.389.389 0 01-.094.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61110"
              d="M37.11 46.388a2.436 2.436 0 00.167-.2 7.244 7.244 0 01.63-.756 1.935 1.935 0 01.609-.375.523.523 0 01.208-.045 1.333 1.333 0 01-.8 1.025 6.641 6.641 0 01-.817.346"
              fill="#fff"
            />
            <path
              data-name="Path 61111"
              d="M37.111 46.495a.107.107 0 01-.077-.18 2.288 2.288 0 00.157-.183 7.288 7.288 0 01.644-.772 1.694 1.694 0 01.517-.337c.038-.017.077-.035.117-.055a.637.637 0 01.257-.057.107.107 0 01.1.122 1.448 1.448 0 01-.852 1.1 6.65 6.65 0 01-.833.353.107.107 0 01-.034.006m1.48-1.348l-.026.011a3.34 3.34 0 01-.124.058 1.475 1.475 0 00-.46.3 6.974 6.974 0 00-.537.632c.139-.057.3-.127.434-.2a1.254 1.254 0 00.713-.8"
              fill="#1d1d1b"
            />
            <path data-name="Path 61112" d="M36.608 51.148l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 61113"
              d="M36.608 51.255a.106.106 0 01-.054-.015l-.877-.509a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.877.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.107.107m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61114" d="M36.598 46.648l-.877-.509 4.32-2.494.877.509z" fill="#fff" />
            <path
              data-name="Path 61115"
              d="M36.598 46.755a.107.107 0 01-.054-.014l-.876-.509a.107.107 0 010-.185l4.32-2.494a.106.106 0 01.107 0l.877.509a.107.107 0 010 .185l-4.32 2.494a.106.106 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61116" d="M40.918 44.154l.01 4.5-4.32 2.494-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 61117"
              d="M36.608 51.256a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.009-4.254z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61118"
              d="M29.18 49.504a2.281 2.281 0 01.26-.045c.365 0 .915.086 1.462.086.529 0 1.054-.086 1.406-.086a2.33 2.33 0 01.314.073s-.056-.283-.056-.353v-1.616a2.2 2.2 0 01.078-.328s-.27.039-.333.039c-.347 0-.871-.1-1.391-.1-.495 0-.985.1-1.315.1a3.226 3.226 0 01-.445-.1s.045.322.045.4v1.681c0 .05-.026.246-.026.246"
              fill="#fff"
            />
            <path
              data-name="Path 61119"
              d="M30.902 49.652c-.306 0-.613-.026-.884-.05-.215-.018-.419-.036-.578-.036a2.1 2.1 0 00-.237.042.107.107 0 01-.129-.118c.01-.079.025-.2.025-.233v-1.681c0-.05-.027-.261-.044-.383a.107.107 0 01.134-.118 2.95 2.95 0 00.417.091c.143 0 .324-.019.517-.04a7.554 7.554 0 01.8-.055c.292 0 .585.029.843.055.2.021.4.04.548.04.039 0 .209-.022.318-.038a.107.107 0 01.117.137 2.053 2.053 0 00-.074.3v1.616c0 .037.028.2.054.332a.107.107 0 01-.135.123 2.2 2.2 0 00-.284-.069c-.153 0-.348.017-.555.036-.26.023-.555.05-.851.05m-1.462-.3c.169 0 .376.018.6.037.267.023.569.049.866.049s.576-.026.832-.049c.211-.019.411-.037.574-.037a.937.937 0 01.178.032 1.658 1.658 0 01-.027-.205v-1.616a1.014 1.014 0 01.038-.2c-.074.01-.151.018-.186.018-.161 0-.36-.02-.57-.041-.254-.025-.541-.054-.822-.054a7.387 7.387 0 00-.776.054c-.2.021-.385.041-.539.041a1.819 1.819 0 01-.318-.059c.012.1.025.209.025.254v1.681c0 .023 0 .067-.009.113a.937.937 0 01.137-.018"
              fill="#1d1d1b"
            />
            <path data-name="Path 61120" d="M32.29 53.641l-.877-.509-.01-4.5.877.509z" fill="#fff" />
            <path
              data-name="Path 61121"
              d="M32.289 53.748a.108.108 0 01-.054-.014l-.876-.509a.107.107 0 01-.053-.092l-.01-4.5a.107.107 0 01.16-.093l.876.509a.107.107 0 01.053.092l.01 4.5a.107.107 0 01-.053.093.105.105 0 01-.053.014m-.77-.678l.663.385-.009-4.253-.663-.385z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61122" d="M32.279 49.141l-.876-.509 4.32-2.494.876.509z" fill="#fff" />
            <path
              data-name="Path 61123"
              d="M32.279 49.247a.107.107 0 01-.054-.014l-.876-.509a.107.107 0 010-.185l4.32-2.494a.106.106 0 01.107 0l.876.509a.107.107 0 010 .185l-4.32 2.494a.106.106 0 01-.053.014m-.664-.616l.664.386 4.107-2.371-.664-.386z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61124" d="M36.599 46.647l.01 4.5-4.32 2.494-.01-4.5z" fill="#fff" />
            <path
              data-name="Path 61125"
              d="M32.285 53.748a.107.107 0 01-.107-.107l-.01-4.5a.106.106 0 01.053-.093l4.32-2.494a.107.107 0 01.16.092l.01 4.5a.107.107 0 01-.053.093l-4.32 2.494a.106.106 0 01-.053.014m.1-4.546l.009 4.254 4.107-2.371-.009-4.254z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61126" d="M31.423 50.596l.877-.509-.01 3.558-.877.509z" fill="#fff" />
            <path
              data-name="Path 61127"
              d="M31.413 54.261a.107.107 0 01-.107-.107l.01-3.558a.107.107 0 01.053-.092l.876-.509a.107.107 0 01.16.093l-.01 3.558a.107.107 0 01-.053.092l-.877.509a.107.107 0 01-.054.014m.117-3.6l-.009 3.31.663-.385.009-3.31z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61128" d="M27.438 48.297l.876-.509 3.984 2.3-.876.509z" fill="#fff" />
            <path
              data-name="Path 61129"
              d="M31.422 50.703a.107.107 0 01-.053-.014l-3.985-2.3a.107.107 0 010-.185l.876-.509a.107.107 0 01.107 0l3.984 2.3a.107.107 0 010 .185l-.877.509a.106.106 0 01-.054.015m-3.772-2.406l3.771 2.176.664-.386-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61130" d="M31.422 50.597l-.01 3.558-3.985-2.3.01-3.558z" fill="#fff" />
            <path
              data-name="Path 61131"
              d="M31.412 54.26a.107.107 0 01-.053-.014l-3.984-2.3a.107.107 0 01-.053-.093l.01-3.558a.107.107 0 01.16-.092l3.984 2.3a.107.107 0 01.053.093l-.01 3.558a.107.107 0 01-.107.106m-3.878-2.467l3.771 2.176.009-3.312-3.771-2.176z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61132"
              d="M13.835 45.111a2.863 2.863 0 002.6-.026c.707-.423.691-1.1-.037-1.505a2.864 2.864 0 00-2.6.026c-.707.423-.691 1.1.037 1.505"
              fill="#fff"
            />
            <path
              data-name="Path 61133"
              d="M15.109 45.514a2.768 2.768 0 01-1.326-.31 1.029 1.029 0 01-.611-.845 1 1 0 01.572-.844 2.985 2.985 0 012.706-.028 1.028 1.028 0 01.611.845 1 1 0 01-.572.844 2.751 2.751 0 01-1.379.338m.012-2.128a2.531 2.531 0 00-1.269.311.8.8 0 00-.468.658.829.829 0 00.5.662 2.749 2.749 0 002.492-.025.8.8 0 00.468-.658.829.829 0 00-.5-.662 2.551 2.551 0 00-1.223-.286"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61134"
              d="M15.105 44.345h.021a.232.232 0 00.232-.232v-8.8a.232.232 0 00-.232-.232h-.021a.232.232 0 00-.232.232v8.8a.232.232 0 00.232.232"
              fill="#fff"
            />
            <path
              data-name="Path 61135"
              d="M15.126 44.452h-.021a.339.339 0 01-.338-.338v-8.8a.339.339 0 01.338-.338h.021a.339.339 0 01.338.338v8.8a.339.339 0 01-.338.338m-.021-9.258a.125.125 0 00-.125.125v8.8a.125.125 0 00.125.125h.021a.125.125 0 00.125-.125v-8.8a.125.125 0 00-.125-.125z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61136"
              d="M13.469 33.801a3.681 3.681 0 003.341-.034c.909-.544.888-1.41-.047-1.935a3.681 3.681 0 00-3.341.034c-.909.544-.888 1.41.047 1.935"
              fill="#ffc200"
            />
            <path
              data-name="Path 61137"
              d="M15.107 34.291a3.528 3.528 0 01-1.69-.4 1.294 1.294 0 01-.77-1.061 1.262 1.262 0 01.72-1.059 3.8 3.8 0 013.448-.035 1.294 1.294 0 01.77 1.061 1.262 1.262 0 01-.72 1.059 3.5 3.5 0 01-1.757.431m-1.586-.582a3.569 3.569 0 003.234-.032 1.062 1.062 0 00.616-.873 1.094 1.094 0 00-.661-.877 3.568 3.568 0 00-3.234.032 1.062 1.062 0 00-.616.873 1.094 1.094 0 00.661.877"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61138"
              d="M13.469 33.801a3.681 3.681 0 003.341-.034 1.17 1.17 0 00.668-.951v2.163a1.157 1.157 0 01-.668.975 3.681 3.681 0 01-3.341.034 1.186 1.186 0 01-.716-.984v-2.187a1.186 1.186 0 00.716.984"
              fill="#ffc200"
            />
            <path
              data-name="Path 61139"
              d="M15.107 36.478a3.527 3.527 0 01-1.69-.4 1.283 1.283 0 01-.77-1.078v-2.187h.213v.009a1.09 1.09 0 00.661.883 3.568 3.568 0 003.234-.032 1.071 1.071 0 00.616-.859h.213v2.163a1.256 1.256 0 01-.72 1.066 3.506 3.506 0 01-1.757.431m-2.247-3.049v1.576a1.085 1.085 0 00.661.891 3.569 3.569 0 003.234-.032 1.057 1.057 0 00.616-.88v-1.564a1.732 1.732 0 01-.507.44 3.8 3.8 0 01-3.448.035 1.762 1.762 0 01-.557-.466"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61140"
              d="M13.936 36.299a.116.116 0 01-.042-.009.093.093 0 01-.034-.023.047.047 0 01-.013-.016.1.1 0 01-.011-.018.113.113 0 01-.005-.02.065.065 0 010-.02.073.073 0 010-.021.129.129 0 01.005-.019.175.175 0 01.011-.019.084.084 0 01.013-.016.106.106 0 01.034-.022.1.1 0 01.082 0 .106.106 0 01.034.022l.014.016a.081.081 0 01.01.019.068.068 0 01.006.019.145.145 0 010 .021.13.13 0 010 .02.064.064 0 01-.006.02.059.059 0 01-.01.018.067.067 0 01-.014.016.1.1 0 01-.075.032"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61141"
              d="M13.829 36.786a.107.107 0 11.107.107.107.107 0 01-.107-.107m0-.3a.107.107 0 11.107.107.107.107 0 01-.107-.107"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61142"
              d="M13.936 37.191a.112.112 0 01-.076-.031.107.107 0 01-.031-.076.105.105 0 01.007-.041.1.1 0 01.023-.035.107.107 0 01.1-.029.064.064 0 01.02.006.059.059 0 01.018.01.064.064 0 01.016.013.089.089 0 01.023.035.106.106 0 01.009.041.109.109 0 01-.032.076l-.016.013a.059.059 0 01-.018.01.064.064 0 01-.02.006h-.02"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61143"
              d="M13.677 37.612a.259.259 0 10.259-.259.259.259 0 00-.259.259"
              fill="#ffc200"
            />
            <path
              data-name="Path 61144"
              d="M13.935 37.925a.312.312 0 11.312-.312.313.313 0 01-.312.312m0-.518a.206.206 0 10.206.206.206.206 0 00-.206-.206"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61145"
              d="M36.297 31.992l-2.041-1.189a.362.362 0 010-.627l1.926-1.112a1.1 1.1 0 011.1 0l2.041 1.189a.362.362 0 010 .627l-1.926 1.112a1.1 1.1 0 01-1.1 0"
              fill="#fff"
            />
            <path
              data-name="Path 61146"
              d="M36.851 32.248a1.207 1.207 0 01-.607-.164l-2.041-1.189a.469.469 0 010-.811l1.926-1.112a1.209 1.209 0 011.211 0l2.041 1.189a.469.469 0 010 .811l-1.926 1.112a1.207 1.207 0 01-.6.161m-.117-3.224a.992.992 0 00-.5.133l-1.926 1.112a.255.255 0 000 .442l2.041 1.189a1 1 0 001 0l1.926-1.112a.255.255 0 000-.442l-2.041-1.189a.992.992 0 00-.5-.135"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61147"
              d="M36.524 31.633l-1.667-.971a.207.207 0 010-.358l1.583-.914a.611.611 0 01.613 0l1.667.971a.207.207 0 010 .358l-1.583.914a.611.611 0 01-.613 0"
              fill="#fff"
            />
            <path
              data-name="Path 61148"
              d="M36.831 31.77a.664.664 0 01-.335-.09l-1.667-.971a.26.26 0 010-.45l1.583-.914a.667.667 0 01.667 0l1.667.971a.26.26 0 010 .45l-1.583.914a.665.665 0 01-.332.089m-.085-2.407a.557.557 0 00-.279.075l-1.583.914a.154.154 0 000 .266l1.667.971a.56.56 0 00.56 0l1.583-.914a.154.154 0 000-.266l-1.667-.971a.558.558 0 00-.281-.076"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61149"
              d="M38.258 31.042a.053.053 0 01-.027-.007l-1.2-.7a.559.559 0 00-.56 0l-1.121.647a.053.053 0 01-.053-.092l1.121-.647a.666.666 0 01.667 0l1.2.7a.053.053 0 01-.027.1"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61150"
              d="M37.321 31.279c0 .1-.171.185-.382.185s-.382-.083-.382-.185.171-.184.382-.184.382.083.382.184"
              fill="#fff"
            />
            <path
              data-name="Path 61151"
              d="M36.939 31.518c-.248 0-.435-.1-.435-.238s.187-.238.435-.238.435.1.435.238-.187.238-.435.238m0-.369c-.2 0-.328.078-.328.131s.128.131.328.131.328-.078.328-.131-.128-.131-.328-.131"
              fill="#1d1d1b"
            />
            <path data-name="Path 61152" d="M37.892 30.231a.146.146 0 11-.041-.17.127.127 0 01.041.17" fill="#fff" />
            <path
              data-name="Path 61153"
              d="M37.818 30.316h-.008a.228.228 0 01-.2-.167.141.141 0 01.149-.181.22.22 0 01.128.051.177.177 0 01.049.245.146.146 0 01-.115.051m-.07-.241a.042.042 0 00-.032.012.044.044 0 000 .037.122.122 0 00.1.085.043.043 0 00.035-.012c.015-.019 0-.064-.034-.1a.114.114 0 00-.065-.027"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61154"
              d="M37.986 29.262c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.184h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 61155"
              d="M37.717 29.499c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61156"
              d="M37.986 29.079c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 61157"
              d="M37.717 29.314c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61158"
              d="M38.049 29.634a.214.214 0 01-.214-.214v-1.236c0-.385-.2-.654-.375-.654s-.375.268-.375.654a.214.214 0 01-.428 0c0-.607.353-1.082.8-1.082s.8.475.8 1.082v1.236a.214.214 0 01-.214.214"
              fill="#fff"
            />
            <path
              data-name="Path 61159"
              d="M38.049 29.688a.268.268 0 01-.267-.268v-1.236c0-.372-.188-.6-.322-.6s-.322.228-.322.6a.268.268 0 11-.535 0c0-.637.376-1.135.857-1.135s.857.5.857 1.135v1.236a.268.268 0 01-.268.268m-.589-2.211c.207 0 .429.284.429.707v1.236a.161.161 0 10.321 0v-1.236c0-.577-.33-1.029-.75-1.029s-.75.452-.75 1.029a.161.161 0 10.321 0c0-.423.222-.707.429-.707"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61160"
              d="M38.675 29.618c0 .1-.121.183-.27.183s-.27-.082-.27-.183v-.185h.539z"
              fill="#fff"
            />
            <path
              data-name="Path 61161"
              d="M38.405 29.852c-.181 0-.323-.1-.323-.236v-.185a.053.053 0 01.053-.053h.539a.053.053 0 01.053.053v.185c0 .132-.142.236-.323.236m-.216-.367v.131c0 .07.1.129.216.129s.216-.059.216-.129v-.131z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61162"
              d="M38.675 29.435c0 .1-.121.183-.27.183s-.27-.082-.27-.183.121-.183.27-.183.27.082.27.183"
              fill="#fff"
            />
            <path
              data-name="Path 61163"
              d="M38.405 29.669c-.181 0-.323-.1-.323-.236s.142-.236.323-.236.323.1.323.236-.142.236-.323.236m0-.366c-.117 0-.216.059-.216.129s.1.129.216.129.216-.059.216-.129-.1-.129-.216-.129"
              fill="#1d1d1b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgConsumption
