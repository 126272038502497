import { useTranslation } from "react-i18next"
import { getLocale } from "../i18n/config"
import { assertUnreachable } from "./types"
import { EnergyUnit, PaymentsFrequency, DeliveryPointStatus, CommodityType } from "@nano-portal/shared"

export const formatCurrency = (amount: number, minFractionDigits = 0, maxFractionDigits = 2, currency = "CZK") => {
  const locale = getLocale()
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(amount)
}

export const formatNumber = (value: number, minFractionDigits = 0, maxFractionDigits = 2) => {
  const locale = getLocale()
  return new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(value)
}

export const formatEnergy = (amount: number, unit: EnergyUnit = EnergyUnit.kWh, maxFractionDigits = 2) => {
  const locale = getLocale()
  return (
    new Intl.NumberFormat(locale, { style: "decimal", maximumFractionDigits: maxFractionDigits }).format(amount) +
    " " +
    unit
  )
}

export const formatCurrencyPerEnergy = (
  amount: number,
  minFractionDigits = 0,
  maxFractionDigits = 2,
  currency = "CZK",
  unit: EnergyUnit = EnergyUnit.kWh
) => {
  return formatCurrency(amount, minFractionDigits, maxFractionDigits, currency) + "/" + unit
}

export const useFormatAdvanceFrequency = () => {
  const { t } = useTranslation("delivery_point")

  return (frequency: PaymentsFrequency) => {
    switch (frequency) {
      case PaymentsFrequency.NoPayments:
        return t("fundamental_info_payments_freq_no_payments")
      case PaymentsFrequency.Monthly:
        return t("fundamental_info_payments_freq_monthly")
      case PaymentsFrequency.Quarterly:
        return t("fundamental_info_payments_freq_quarterly")
      case PaymentsFrequency.SemiAnnually:
        return t("fundamental_info_payments_freq_semi_annually")
      case PaymentsFrequency.Annually:
        return t("fundamental_info_payments_freq_annually")
      default:
        return assertUnreachable(frequency)
    }
  }
}

export const useFormatDeliveryPointStatus = () => {
  const { t } = useTranslation("delivery_point")

  return (status: DeliveryPointStatus) => {
    switch (status) {
      case DeliveryPointStatus.ACTIVE:
        return t("contract_info_status_active")
      case DeliveryPointStatus.APPROVED:
        return t("contract_info_status_approved")
      case DeliveryPointStatus.CANCELLED:
        return t("contract_info_status_cancelled")
      case DeliveryPointStatus.ENDED:
        return t("contract_info_status_ended")
      case DeliveryPointStatus.ENDING:
        return t("contract_info_status_ending")
      case DeliveryPointStatus.IN_TRANSFER:
        return t("contract_info_status_in_transfer")
      default:
        return assertUnreachable(status)
    }
  }
}

export const useFormatCommodityType = () => {
  const { t } = useTranslation("delivery_point")

  return (commodity: CommodityType) => {
    switch (commodity) {
      case CommodityType.Electricity:
        return t("fundamental_info_commodity_electricity")
      default:
        return assertUnreachable(commodity)
    }
  }
}
