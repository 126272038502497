import { styled, theme } from "../../../../styles/stitches.config"

const underlineHeight = theme.space.s0_5

export const SContainer = styled("div", {
  display: "flex",
  gap: theme.space.s12,
  paddingLeft: theme.space.s5,

  borderBottom: `${underlineHeight} solid ${theme.colors.lines}`,
})

export const SButton = styled("button", {
  all: "unset",
  cursor: "pointer",

  paddingBottom: theme.space.s4,

  variants: {
    selected: {
      true: {
        borderBottom: `calc(${underlineHeight} + ${theme.space.spx}) solid ${theme.colors.secondary}`,
        marginBottom: `-${underlineHeight.value}`,
      },
    },
  },
})
