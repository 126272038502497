import { ArrowBack, NanoEnergiesLogo } from "../../images/xd/icons"
import { styled, theme } from "../../styles/stitches.config"

export const SLogo = styled(NanoEnergiesLogo, {
  height: theme.space.s14,
})

export const SForm = styled("form", { width: theme.space.s80 })

export const SChevronLeft = styled(ArrowBack, { width: theme.space.s6, height: theme.space.s6 })
