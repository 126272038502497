import { Button } from "../../components/button/Button"
import { Spacer } from "../../components/spacer/Spacer"
import { Text } from "../../components/Text"
import {
  SAddPhoto,
  SButton,
  SDeliveryPointDescription,
  SFitImage,
  SFlex,
  SFlexButton,
  SFlexFullSize,
  SInputWrapper,
  SLink,
  SListItem,
  SRadioGroupIndicator,
  SRadioGroupItem,
  SRadioGroupItemWrapper,
  SRadioGroupLabel,
  SRadioGroupRoot,
  STitle,
} from "./CreateMeasurement.styled"
import { theme } from "../../styles/stitches.config"
import { Flex } from "../../components/flex/Flex"
import { FormControl } from "../../components/form/FormControl"
import { Controller, useForm, UseFormReturn } from "react-hook-form"
import { useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useIsMaxMd } from "../../hooks/mediaHooks"
import { uploadImage } from "../../utils/storage"
import { toasts } from "../../utils/toasts"
import { useAsyncFn } from "react-use"
import { Div } from "../../components/div/Div"
import DateInput from "../../components/datepicker/DateInput"
import { formatIsoDate } from "../../utils/date"
import { startOfToday, subDays } from "date-fns"
import InfoBox from "../../components/info/InfoBox"
import { EnergyUnit, METER_READING_DAYS_UNTIL, ReadingType, readingTypes } from "@nano-portal/shared"
import { WEB_LINKS } from "../../constants/links"
import { SModalDescription } from "../../components/modal/Modal.styled"

export interface Measurement {
  lowTariff: number
  highTariff: number
  lowTariffPhoto?: File
  highTariffPhoto?: File
  createdAt: Date
  readingType: ReadingType
}

interface SaveReadingInput {
  highTariffConsumption: number
  lowTariffConsumption?: number | null
  highTariffImagePath?: string | null
  lowTariffImagePath?: string | null
  createdAt: Date
  readingType: ReadingType
}

interface CreateMeasurementProps {
  getUploadUrl: () => Promise<{ objectPath: string; url: string }>
  saveReading: (input: SaveReadingInput) => Promise<void>
  onMeasurementCreated?: (measurement: Measurement) => void
  onlyHighTariff: boolean
  ean: string
  enableExtraInvoiceOption?: boolean
  address: {
    street: string
    town: string
  }
}

function DeliveryPointDescription(props: { ean: string; address: { street: string; town: string } }) {
  const { address, ean } = props
  const { t } = useTranslation(["common"])

  return (
    <SDeliveryPointDescription>
      <Text type="headerH5" color="secondary" css={{ display: "block" }}>
        {t("common:ean", { ean })}
      </Text>

      <Text type="textsLarge">{address.street}, </Text>
      <Text type="textsLarge">{address.town}</Text>
    </SDeliveryPointDescription>
  )
}

function Header() {
  const { t } = useTranslation(["delivery_point"])

  return (
    <Div>
      <STitle>{t("delivery_point:create_measurement_new_measurement")}</STitle>
      <SLink href={WEB_LINKS.selfMeasurement} target="_blank">
        <Text type="headerH5" color="secondary">
          {t("delivery_point:create_measurement_how_to")}
        </Text>
      </SLink>
    </Div>
  )
}

function Disclaimer() {
  const { t } = useTranslation(["delivery_point"])
  return (
    <SModalDescription>
      <Trans t={t} i18nKey="delivery_point:create_measurement_disclaimer">
        <Text type="headerH5Negative" />
        <Text type="headerH5" />
      </Trans>
      <InfoBox inline align="start" css={{ verticalAlign: "bottom" }}>
        <Div css={{ width: theme.sizes.s87 }}>
          <Text type="headerH4">{t("delivery_point:create_measurement_disclaimer_tooltip_header")}</Text>
          <ul>
            <SListItem>
              <Text type="textsSmall">{t("delivery_point:create_measurement_disclaimer_tooltip_point_1")}</Text>
            </SListItem>
            <SListItem>
              <Text type="textsSmall">{t("delivery_point:create_measurement_disclaimer_tooltip_point_2")}</Text>
            </SListItem>
          </ul>
        </Div>
      </InfoBox>
    </SModalDescription>
  )
}

export function CreateMeasurement({
  onMeasurementCreated,
  onlyHighTariff,
  getUploadUrl,
  saveReading,
  ean,
  address,
  enableExtraInvoiceOption = false,
}: CreateMeasurementProps) {
  const uploadReadingImage = async (image: File) => {
    const { objectPath, url } = await getUploadUrl()

    await uploadImage(url, image)

    return objectPath
  }

  const onSubmit = async (measurement: Measurement) => {
    try {
      let highTariffImagePath = null
      let lowTariffImagePath = null

      if (measurement.highTariffPhoto) {
        highTariffImagePath = await uploadReadingImage(measurement.highTariffPhoto)
      }
      if (measurement.lowTariffPhoto) {
        lowTariffImagePath = await uploadReadingImage(measurement.lowTariffPhoto)
      }

      await saveReading({
        highTariffConsumption: measurement.highTariff,
        lowTariffConsumption: measurement.lowTariff,
        createdAt: measurement.createdAt,
        highTariffImagePath,
        lowTariffImagePath,
        readingType: measurement.readingType,
      })

      onMeasurementCreated?.(measurement)
      form.reset()
      toasts.success(t("delivery_point:create_measurement_success"))
    } catch {
      toasts.error(t("common:generic_error"))
    }
  }

  const [state, doSubmit] = useAsyncFn(onSubmit)

  const { t } = useTranslation(["overview", "delivery_point", "common"])
  const form = useForm<MeasurementForm>({
    mode: "onChange",
    defaultValues: {
      createdAt: formatIsoDate(new Date()),
      readingType: "CONTROL",
    },
  })

  // with custom controlled input it is suggested to just call register function as a dummy
  form.register("readingType")

  const isMaxMd = useIsMaxMd()

  const { minDate, maxDate } = useMemo(() => {
    const today = startOfToday()

    return {
      minDate: formatIsoDate(subDays(today, METER_READING_DAYS_UNTIL)),
      maxDate: formatIsoDate(today),
    }
  }, [])

  return (
    <SFlex direction="column">
      <form
        //  eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit((measurement: MeasurementForm) =>
          doSubmit({
            highTariff: measurement.highTariff,
            lowTariff: measurement.lowTariff ?? 0,
            createdAt: new Date(measurement.createdAt),
            highTariffPhoto: measurement.highTariffPhoto as File | undefined,
            lowTariffPhoto: measurement.lowTariffPhoto as File | undefined,
            readingType: measurement.readingType,
          })
        )}
      >
        <Flex justify="between" align="center">
          <Header />
          <DeliveryPointDescription ean={ean} address={address} />
        </Flex>

        <Spacer size={theme.space.s7} />

        <Disclaimer />

        <Spacer size={theme.space.s9} />

        <Flex direction={isMaxMd ? "column" : "row"}>
          <Flex direction={"column"} style={{ width: "100%" }}>
            <FormControl label={t("delivery_point:create_measurement_type")}>
              <Controller
                control={form.control}
                name="readingType"
                render={({ field: { ref, value, onChange } }) => (
                  <SRadioGroupRoot ref={ref} value={value} onValueChange={onChange}>
                    <SRadioGroupItemWrapper>
                      <SRadioGroupItem id={readingTypes.CONTROL} value={readingTypes.CONTROL}>
                        <SRadioGroupIndicator />
                      </SRadioGroupItem>
                      <SRadioGroupLabel htmlFor={readingTypes.CONTROL}>
                        {t("delivery_point:create_measurement_type_control_reading")}
                      </SRadioGroupLabel>
                    </SRadioGroupItemWrapper>
                    <SRadioGroupItemWrapper disabled={!enableExtraInvoiceOption}>
                      <SRadioGroupItem
                        id={readingTypes.EXTRA_INVOICE}
                        value={readingTypes.EXTRA_INVOICE}
                        disabled={!enableExtraInvoiceOption}
                      >
                        <SRadioGroupIndicator />
                      </SRadioGroupItem>
                      <SRadioGroupLabel htmlFor={readingTypes.EXTRA_INVOICE}>
                        {t("delivery_point:create_measurement_type_extra_invoice")}
                      </SRadioGroupLabel>
                      <Spacer size={theme.space.s2} />
                      <InfoBox>
                        <Div css={{ maxWidth: 360 }}>
                          <Text type="textsSmall">
                            {t("delivery_point:create_measurement_type_extra_invoice_disclaimer")}
                          </Text>
                        </Div>
                      </InfoBox>
                    </SRadioGroupItemWrapper>
                  </SRadioGroupRoot>
                )}
              />
            </FormControl>
          </Flex>

          <Spacer size={theme.space.s8} />

          <Flex direction={"column"} style={{ width: "100%" }}>
            <FormControl label={t("delivery_point:create_measurement_created_at")}>
              <Controller
                control={form.control}
                name="createdAt"
                render={({ field: { onChange, value } }) => (
                  <DateInput value={value} setValue={onChange} min={minDate} max={maxDate} />
                )}
              />
            </FormControl>
          </Flex>
        </Flex>

        <Spacer size={theme.space.s8} />

        <Flex direction={onlyHighTariff || isMaxMd ? "column" : "row"}>
          <MeasurementComponent
            numberInputName="highTariff"
            photoInputName="highTariffPhoto"
            label={t("overview:high_tariff")}
            form={form}
          />

          {!onlyHighTariff && (
            <>
              {!isMaxMd && <Spacer size={theme.space.s8} />}
              <MeasurementComponent
                numberInputName="lowTariff"
                photoInputName="lowTariffPhoto"
                label={t("overview:low_tariff")}
                form={form}
              />
            </>
          )}
        </Flex>

        <SFlexFullSize>
          <Button type="submit" disabled={!form.formState.isValid} isSubmitting={state.loading}>
            {t("delivery_point:create_measurement_add_record")}
          </Button>
        </SFlexFullSize>
      </form>
    </SFlex>
  )
}

type FlatFileType = Pick<File, "lastModified" | "name" | "webkitRelativePath">

export interface MeasurementForm {
  lowTariff?: number
  highTariff: number
  lowTariffPhoto?: FlatFileType
  highTariffPhoto: FlatFileType
  createdAt: string
  readingType: ReadingType
}

type FormInput = keyof MeasurementForm

const MeasurementComponent = (params: {
  label: string
  numberInputName: FormInput
  photoInputName: FormInput
  form: UseFormReturn<MeasurementForm, unknown>
}) => {
  const { t } = useTranslation("delivery_point")

  const fileInput = useRef<HTMLInputElement | null>(null)
  const [preview, setPreview] = useState<string | null>(null)

  const onFileInputChange = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      setPreview(reader.result as unknown as string)
    }
  }

  const handleFileInputClick = () => {
    if (fileInput.current) {
      fileInput.current.click()
    }
  }

  return (
    <Flex direction={"column"}>
      <SInputWrapper>
        <FormControl
          label={params.label}
          error={
            params.form.formState.errors[params.numberInputName] !== undefined
              ? t("create_measurement_invalud_value")
              : undefined
          }
          rightContent={
            <SFlexFullSize>
              <Text type="textsLarge">{EnergyUnit.kWh}</Text>
            </SFlexFullSize>
          }
        >
          <input
            placeholder={t("create_measurement_value_placeholder")}
            {...params.form.register(params.numberInputName, {
              setValueAs: (v) => (v === "" || Number.isNaN(Number(v)) || Number(v) % 1 !== 0 ? v : Number.parseInt(v)),
              validate: (v) => Number.isInteger(v) && Number(v) >= 0,
            })}
          />
        </FormControl>
      </SInputWrapper>
      <Controller
        control={params.form.control}
        name={params.photoInputName}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field: { onChange, ref, value: _, ...field } }) => (
          <input
            {...field}
            onChange={(e) => {
              const file = e.target.files?.[0]
              if (file) {
                onChange(file)
                onFileInputChange(file)
              }
            }}
            name={params.photoInputName}
            type="file"
            accept="image/*"
            id="fileToUpload"
            style={{ display: "none" }}
            //https://react-hook-form.com/faqs
            ref={(e) => {
              ref(e)
              fileInput.current = e
            }}
          />
        )}
      />

      <SButton isFullwidth={true} variant="secondary" type="button" onClick={handleFileInputClick}>
        {preview ? (
          <SFitImage src={preview} width="100%" height="100%" />
        ) : (
          <SFlexButton>
            <SAddPhoto />
            {t("create_measurement_add_photo")}
          </SFlexButton>
        )}
      </SButton>

      <Spacer size={theme.space.s8} />
    </Flex>
  )
}
