import * as React from "react"

function SvgNoReading(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoReading_svg__a">
          <path data-name="Rectangle 17153" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 16297" clipPath="url(#_NoReading_svg__a)">
        <path
          data-name="Path 51354"
          d="M18.202 6.729h-7.975a.618.618 0 00-.618.618v3.48a.618.618 0 00.618.618h2.585a.352.352 0 00.14 0h2.53a.352.352 0 00.14 0h2.58a.618.618 0 00.618-.618v-3.48a.618.618 0 00-.618-.618zm-3.268 3.48H13.5V7.965h1.434zm-4.088-2.244h1.421v2.244h-1.422zm6.739 2.244h-1.418V7.965h1.414z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51355"
          d="M15.263 13.941h-.483l.168-1.189a.488.488 0 00-.906-.318l-.768 1.349-.641 1.127a.47.47 0 00-.05.492.448.448 0 00.421.227h.481l-.169 1.191a.468.468 0 00-.006.074.489.489 0 00.485.488h.005a.484.484 0 00.422-.247q.2-.36 1.439-2.515l.014-.026a.454.454 0 00-.012-.453.449.449 0 00-.4-.2m-1.465 3.1z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51356"
          d="M14.693 20.115l.024-.026.813-.812-.025-.025H8.396v-14.2h11.64v9.774a2.37 2.37 0 011.236-.422v-9.97a.618.618 0 00-.618-.618H7.91l-4.537-2.12a.618.618 0 00-.88.561v19.79a.618.618 0 00.88.56l4.537-2.12h6.487a2.408 2.408 0 01.291-.372m-7.534-.639l-3.424 1.6V3.229l3.425 1.6z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 51357"
          d="M19.907 19.273l1.989-1.989a.7.7 0 00-.989-.984l-1.987 1.984-1.988-1.988-.015-.014a.7.7 0 00-.99.992l2 2-1.988 1.987-.014.015a.7.7 0 00.99.992l2-2 1.992 1.989.014.014a.703.703 0 101-.987l-.006-.006z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgNoReading
