import React, { CSSProperties, ComponentProps, ReactNode } from "react"
import { styled } from "@stitches/react"
import { Checkbox as CheckboxIcon, CheckboxChecked } from "../../images/xd/icons"
import { ColorType, CSSProp, theme } from "../../styles/stitches.config"
import Text from "../Text"
import { Flex } from "../flex/Flex"
import { Spacer } from "../spacer/Spacer"

interface BaseProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
  color?: ColorType
  size?: number
  checkboxCSS?: CSSProp
}

type Props = BaseProps & {
  label?: ReactNode
  labelCSS?: CSSProperties
  align?: ComponentProps<typeof Flex>["align"]
}

const HiddenCheckbox = styled("input", {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  width: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
})

const BoxWrapper = styled("div", {
  width: 22,
  height: 22,
  padding: 2,
  cursor: "pointer",
})

export function CheckboxBase({ checked, onChange, color, size, checkboxCSS }: BaseProps) {
  return (
    <>
      <HiddenCheckbox
        type="checkbox"
        onChange={() => {
          onChange?.(!checked)
        }}
      />
      <BoxWrapper css={{ width: size, height: size, ...checkboxCSS }}>
        {checked ? (
          <CheckboxChecked color={color ? theme.colors[color].value : theme.colors.secondary.value} />
        ) : (
          <CheckboxIcon color={color ? theme.colors[color].value : theme.colors.textsAlt.value} />
        )}
      </BoxWrapper>
    </>
  )
}

function Checkbox({ label, labelCSS, align = "center", ...checkboxProps }: Props) {
  return (
    <label style={labelCSS}>
      <Flex direction="row" align={align}>
        <CheckboxBase {...checkboxProps} />
        <Spacer size={theme.space.s2} />
        {label && <Text type="labelsAlt">{label}</Text>}
      </Flex>
    </label>
  )
}

export default Checkbox
