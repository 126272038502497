import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { InferQueryOutput } from "../../api/trpc/trpcHelper"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { EmptyPlaceholderWrapper, SWrapper } from "./HomeContent.styled"
import { HomeSupplyPointCard } from "./supplyPointCard/HomeSupplyPointCard"
import { Text } from "../../components/Text"
import { NoSupplyPoints } from "../../images/xd/icons"
import { DeliveryPointType } from "@nano-portal/shared"
import { ProductionDataTariffNotification } from "../../components/notifications/ProductionDataTariffNotification"
import { useIsMobileVersion } from "../../hooks/mediaHooks"
import { trpc } from "../../api/trpc/trpc"
import HomePlaceholder from "../../components/placeholder/HomePlaceholder"
import { resolveQueriesState } from "../../utils/queries"
import * as icons from "../../images/xd/icons"
import Separator from "../../components/Separator"
import { Flex } from "../../components/flex/Flex"

const SUPPLY_POINTS_DETAIL_LIMIT = 10 // HOTFIX load supply point details only when count is smaller than ...

type DeliveryPoint = InferQueryOutput<"deliveryPoints.list">[number]

interface Props {
  deliveryPoints: DeliveryPoint[]
}

const formatAddress = (deliveryPoint: DeliveryPoint) => {
  return `${deliveryPoint.address}`
}

export function HomeContent({ deliveryPoints }: Props) {
  const { t } = useTranslation("delivery_point")
  const isMobile = useIsMobileVersion()

  const deliveryPointIds = deliveryPoints.map((deliveryPoint) => deliveryPoint.deliveryPointId)
  const hasProduction = deliveryPoints.some((deliveryPoint) =>
    deliveryPoint.type.includes(DeliveryPointType.Production)
  )

  const statusesQuery = trpc.useQuery(
    ["flexibility.getProductionDataTariffApplicationStatusBatch", { deliveryPointIds }],
    { enabled: hasProduction }
  )
  const myselfQuery = trpc.useQuery(["users.myself"])
  const advancesDisplayModeQuery = trpc.useQuery(["payments.advancesDisplayMode"])
  const customerDetailsQuery = trpc.useQuery(["customers.details"])
  const { isLoading: queriesLoading } = resolveQueriesState([
    myselfQuery,
    advancesDisplayModeQuery,
    customerDetailsQuery,
  ])

  if (
    (hasProduction && (statusesQuery.isLoading || !statusesQuery.data)) ||
    queriesLoading ||
    !myselfQuery.data ||
    !advancesDisplayModeQuery.data ||
    !customerDetailsQuery.data
  ) {
    return <HomePlaceholder />
  }

  const applicationStatuses = statusesQuery.data
  const hideBanner =
    applicationStatuses?.some((status) => status.status === "APPROVED" || status.status === "PENDING") ||
    myselfQuery.data.proteusEnabled

  const Icon = icons["SupplyPoints"]
  const customerDetails = customerDetailsQuery.data

  const deliveryPointsByCustomer = (customerId: number) => {
    return deliveryPoints.filter((dp) => dp.customerId === customerId)
  }

  return (
    <>
      {deliveryPoints.length === 0 ? (
        <EmptyPlaceholderWrapper>
          <NoSupplyPoints width={theme.sizes.s42.value} />
          <Spacer size={theme.space.s2} />
          <Text type="headerH2Neutral">{t("you_have_no_supply_points")}</Text>
          <Spacer size={theme.space.s4} />
          <Text type="textsLarge">{t("you_have_no_supply_points_explanation")}</Text>
        </EmptyPlaceholderWrapper>
      ) : (
        <>
          <Spacer size={isMobile ? theme.space.s3 : theme.space.s6} />

          {hasProduction && !hideBanner && (
            <>
              <ProductionDataTariffNotification />
              <Spacer size={isMobile ? theme.space.s5 : theme.space.s10_5} />
            </>
          )}

          <Spacer size={isMobile ? theme.space.s5 : theme.space.s10_5} />

          {customerDetails.map(({ customerId, customerName }) => {
            const customerDps = deliveryPointsByCustomer(customerId)

            return (
              <Fragment key={customerId}>
                <Flex
                  direction={"row"}
                  align={"center"}
                  css={{
                    marginBottom: theme.space.s2_5,
                    paddingLeft: isMobile ? theme.space.s1_5 : undefined,
                    paddingRight: isMobile ? theme.space.s1_5 : undefined,
                  }}
                >
                  {Icon && (
                    <>
                      <Icon color={theme.colors.primary.value} height={"30"} style={{ flexShrink: 0 }} />
                      <Text
                        type="headerH2Neutral"
                        color="primary"
                        css={{ whiteSpace: "nowrap", margin: isMobile ? "0px 10px" : "0px 20px" }}
                      >
                        {customerName}
                      </Text>
                      <Separator orientation="horizontal" size={"extraLarge"} color={"gray"} />
                    </>
                  )}
                </Flex>
                <SWrapper>
                  {customerDps.length === 0 ? (
                    <EmptyPlaceholderWrapper>
                      <NoSupplyPoints width={theme.sizes.s42.value} />
                      <Spacer size={theme.space.s2} />
                      <Text type="headerH2Neutral">{t("you_have_no_supply_points")}</Text>
                      <Spacer size={theme.space.s4} />
                      <Text type="textsLarge">{t("you_have_no_supply_points_explanation")}</Text>
                    </EmptyPlaceholderWrapper>
                  ) : (
                    customerDps.map((deliveryPoint) => {
                      const isProduction = deliveryPoint.type.includes(DeliveryPointType.Production)
                      const applicationStatus = applicationStatuses?.find(
                        (status) => status.ean === deliveryPoint.ean
                      )?.status
                      const showNotification =
                        deliveryPoint.isActive &&
                        isProduction &&
                        !myselfQuery.data.proteusEnabled &&
                        applicationStatus === undefined

                      return (
                        <HomeSupplyPointCard
                          id={deliveryPoint.deliveryPointId}
                          key={deliveryPoint.ean}
                          ean={deliveryPoint.ean}
                          address={formatAddress(deliveryPoint)}
                          tags={deliveryPoint.type}
                          isActive={deliveryPoint.isActive}
                          disableDepositStateLoading={deliveryPoints.length > SUPPLY_POINTS_DETAIL_LIMIT}
                          showNotification={showNotification}
                        />
                      )
                    })
                  )}
                </SWrapper>
                <Spacer size={isMobile ? theme.space.s5 : theme.space.s10_5} />
              </Fragment>
            )
          })}
        </>
      )}
    </>
  )
}
