import * as React from "react"

function SvgRadioButton(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 400" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 401"
        d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0 01-8 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRadioButton
