import { CSSProp, fontVariants, styled, theme } from "../../styles/stitches.config"
import * as Modal from "@radix-ui/react-dialog"
import { Close } from "../../images/xd/icons"
import { createFadeInAndOut } from "../../styles/utils"

const [fadeInOverlay, fadeOutOverlay] = createFadeInAndOut(0, 0.4)
const [fadeInContent, fadeOutContent] = createFadeInAndOut(0, 1)

export const SOverlay = styled(Modal.Overlay, {
  position: "fixed",
  inset: 0,
  backgroundColor: theme.colors.texts,
  zIndex: 100,

  noReducedMotion: {
    "&[data-state='open']": {
      animation: `${fadeInOverlay} 150ms ease-out forwards`,
    },
    "&[data-state='closed']": {
      animation: `${fadeOutOverlay} 150ms ease-out forwards`,
    },
  },
})

const defaultContentPositioning: CSSProp = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
}

export const SContent = styled(Modal.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s14,
  maxHeight: "100%",
  zIndex: 1000,

  "&:focus": { outline: "none" },
  overflow: "auto",

  position: "fixed",

  noReducedMotion: {
    "&[data-state='open']": {
      animation: `${fadeInContent} 150ms ease-out forwards`,
    },
    "&[data-state='closed']": {
      animation: `${fadeOutContent} 150ms ease-out forwards`,
    },
  },

  variants: {
    isFullscreenOnMobile: {
      true: {
        top: 0,
        left: 0,
        width: "stretch",
        height: "100%",

        "@sm": {
          ...defaultContentPositioning,
        },
      },
      false: {
        ...defaultContentPositioning,
      },
    },
  },

  defaultVariants: {
    isFullscreenOnMobile: "true",
  },
})

export const SCloseButton = styled("button", {
  all: "unset",

  position: "absolute",
  top: theme.space.s4,
  right: theme.space.s4,

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",

  hover: {
    cursor: "pointer",
  },
})

export const SClose = styled(Close, {
  width: theme.space.s6,
  height: theme.space.s6,
  color: theme.colors.errors,
})

export const SModalClose = Modal.Close
export const SPortal = Modal.Portal
export const SModalRoot = Modal.Root
export const SModalTrigger = Modal.Trigger
export const SModalTitle = styled(Modal.Title, {
  ...fontVariants.headerH1,
})

export const SModalDescription = styled(Modal.Description, {
  ...fontVariants.textsSmall,
})
