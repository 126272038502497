import * as React from "react"

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 398" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 399"
        d="M15.5 14h-.79l-.28-.27a6.518 6.518 0 10-.7.7l.27.28v.79l4.25 4.25a1.054 1.054 0 001.49-1.49zm-6 0A4.5 4.5 0 1114 9.5 4.494 4.494 0 019.5 14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSearch
