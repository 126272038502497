import { MouseEvent, TouchEvent, useCallback } from "react"

function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith("touch")
}

interface Props {
  width: number
  height: number
  onChangeX: (x: number | null, xOffset: number) => void
  xOffset?: number
}

const ChartTooltipCanvas = ({ width, height, onChangeX, xOffset }: Props) => {
  const handleMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      // there is multiple event types, don't know how to import the right one
      const clientX = isTouchEvent(event) ? (event.touches[0] ?? event.changedTouches[0])?.clientX : event.clientX
      const element = event.currentTarget.getBoundingClientRect()

      const localX = (clientX ?? 0) - element.left

      onChangeX(localX, xOffset ?? 0)
    },
    [onChangeX, xOffset]
  )

  const handleOut = useCallback(() => {
    onChangeX(null, 0)
  }, [onChangeX])

  return (
    <rect
      x={xOffset ?? 0}
      y={0}
      fill="transparent"
      width={width}
      height={height}
      onMouseEnter={handleMove}
      onMouseMove={handleMove}
      onTouchStart={handleMove}
      onTouchMove={handleMove}
      onMouseOut={handleOut}
      onTouchEnd={handleOut}
    />
  )
}

export default ChartTooltipCanvas
