import * as React from "react"

function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 46" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M4.099 19.921h15.8a2.1 2.1 0 001.815-3.148l-7.9-13.65a2.1 2.1 0 00-3.63 0l-7.9 13.65a2.1 2.1 0 001.815 3.148zm7.9-7.344a1.052 1.052 0 01-1.049-1.049v-2.1a1.05 1.05 0 012.1 0v2.1A1.052 1.052 0 0112 12.577zm1.049 4.2h-2.1v-2.1h2.1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWarning
