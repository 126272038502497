import TableComponent, { ColumnAccessors, TableColumn, TableRow } from "../../../../../components/table/Table"
import { DateCell, DateCellValue } from "../../../../../components/table/renderers"
import { AttachmentsCellValue, ReadingsCell, ReadingsCellValue } from "./renderers"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import EmptyTablePlaceholder from "../../../../../components/table/EmptyTablePlaceholder"
import { theme } from "../../../../../styles/stitches.config"

export interface MeasurementsTableData {
  colDateReading: DateCellValue
  colReadingValue: ReadingsCellValue
  colAttachment: AttachmentsCellValue
}

interface Props {
  notSupported?: boolean
  selfMeasurementsEnabled?: boolean
  data: TableRow<MeasurementsTableData>[]
}

const minimalViewColumns: ColumnAccessors<MeasurementsTableData> = [
  "colDateReading",
  "colReadingValue",
  "colAttachment",
]

export default function MeasurementsTable({ data, notSupported, selfMeasurementsEnabled }: Props) {
  const { t } = useTranslation(["delivery_point"])
  // const isMobile = useIsMaxMd()
  const columns: TableColumn<MeasurementsTableData>[] = useMemo(
    () => [
      {
        Header: t("delivery_point:measurements_reading_date"),
        accessor: "colDateReading", // accessor is the "key" in the data
        Cell: DateCell,
      },
      {
        Header: t("delivery_point:measurements_reading_values"),
        accessor: "colReadingValue",
        Cell: ReadingsCell,
      },
      // {  TODO PROZATÍM SCHOVÁNO
      //   Header: isMobile ? "" : t("measurements_attachments"),
      //   accessor: "colAttachment",
      //   Cell: AttachmentsCell,
      // },
    ],
    [/*isMobile,*/ t]
  )

  const placeholderHeader = notSupported
    ? t("delivery_point:meter_readings_not_supported")
    : t("delivery_point:no_meter_readings")
  const placeholderExplanation = notSupported
    ? t("delivery_point:meter_readings_not_supported_description")
    : selfMeasurementsEnabled
    ? t("delivery_point:add_meter_reading")
    : t("delivery_point:no_meter_readings_description")

  return (
    <TableComponent
      columns={columns}
      data={data}
      minimalViewColumns={minimalViewColumns}
      size="small"
      emptyPlaceholder={
        <EmptyTablePlaceholder
          css={{ height: theme.sizes.s96 }}
          icon={notSupported ? "NoReading" : "NewReading"}
          mainText={placeholderHeader}
          explanationText={placeholderExplanation}
        />
      }
    />
  )
}
