import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getCurrency } from "../../i18n/config"
import { AmountCellValue, CustomUnitCellValue, getCustomUnitCell } from "../table/renderers"
import { TableColumn } from "../table/Table"
import Text from "../Text"
import { useIsMaxMd } from "../../hooks/mediaHooks"
import { usePeriodOverviewDescriptions } from "../../sections/overview/usePeriodOverviewDescription"
import {
  cellOptions,
  ColoredPeriodCell,
  CustomUnitFooterCell,
  defaultUnitStyle,
  defaultValueStyle,
  Header,
  PeriodCellValue,
} from "../../sections/overview/periodOverviewRenderers"
import { ConsumptionPeriodType, ProductionPeriodType, OverviewGroup, EnergyUnit } from "@nano-portal/shared"

export type OverviewTableData = {
  colPeriod: PeriodCellValue
  colElectricityVolume: CustomUnitCellValue | null
  colPowerElectricityPrice: CustomUnitCellValue
  colPowerElectricityEnergy: AmountCellValue
  colCharges: AmountCellValue | null
  colTotal: AmountCellValue | null
  colAdvance: AmountCellValue | null
  colSettlement?: AmountCellValue | null
  type: ConsumptionPeriodType | ProductionPeriodType
}

export interface OverviewTableSummary {
  settlement: number | null
  electricityVolume: number | null
  pricePerPowerUnit: number
  electricityPrice: number
  otherFees: number | null
  total: number | null
  advance: number | null
}

export const usePeriodOverviewColumns = (
  summary: OverviewTableSummary,
  group: OverviewGroup,
  type: "production" | "consumption"
) => {
  const { t } = useTranslation(["overview"])
  const isMaxMd = useIsMaxMd()

  const {
    all: periodDescriptions,
    consumption: consumptionPeriodDescriptions,
    production: productionPeriodDescriptions,
  } = usePeriodOverviewDescriptions()

  const columns: TableColumn<OverviewTableData>[] = useMemo(() => {
    const cols: TableColumn<OverviewTableData>[] = [
      {
        Header: <Header headerKey="period_header" textAlign="end" />,
        accessor: "colPeriod",
        Cell: (props) => (
          <ColoredPeriodCell
            {...props}
            value={{
              ...props.value,
              group,
              circleColor: periodDescriptions.find((info) => info.value === props.row.original.type)?.color,
            }}
          />
        ),
        Footer: (
          <div style={{ textAlign: "end" }}>
            <Text type="headerH4">{t("overview:total")}</Text>
          </div>
        ),
      },
      {
        Header: <Header headerKey={`volume_${type}`} textAlign="end" />,
        accessor: "colElectricityVolume",
        Cell: getCustomUnitCell({ unit: EnergyUnit.kWh, valueStyle: defaultValueStyle, unitStyle: defaultUnitStyle }),
        Footer: <CustomUnitFooterCell amount={summary.electricityVolume} unit={EnergyUnit.kWh} />,
      },
      {
        Header: (
          <Header
            headerKey="electrical_power_per_unit"
            tooltipKey={`electrical_power_per_unit_tooltip_${type}`}
            textAlign="end"
          />
        ),
        accessor: "colPowerElectricityPrice",
        Cell: getCustomUnitCell({
          unit: `${getCurrency().symbol}/${EnergyUnit.kWh}`,
          valueStyle: defaultValueStyle,
          unitStyle: defaultUnitStyle,
        }),
        Footer: (
          <CustomUnitFooterCell amount={summary.pricePerPowerUnit} unit={`${getCurrency().symbol}/${EnergyUnit.kWh}`} />
        ),
      },
      {
        Header: (
          <Header
            headerKey="electrical_power_total"
            tooltipKey={`electrical_power_total_${type}_tooltip`}
            textAlign="end"
          />
        ),
        accessor: "colPowerElectricityEnergy",
        Cell: getCustomUnitCell(cellOptions({ unit: "currency" })),
        Footer: <CustomUnitFooterCell amount={summary.electricityPrice} {...cellOptions({ unit: "currency" })} />,
      },
    ]

    if (type === "consumption") {
      cols.push(
        {
          Header: (
            <Header
              headerKey="distribution_and_other_fees"
              tooltipKey="distribution_and_other_fees_tooltip"
              textAlign="center"
            />
          ),
          accessor: "colCharges",
          Cell: getCustomUnitCell(cellOptions({ unit: "currency" })),
          Footer: <CustomUnitFooterCell amount={summary.otherFees} {...cellOptions({ unit: "currency" })} />,
        },
        {
          Header: (
            <Header
              headerKey="total_payment"
              tooltipKey="total_payment_tooltip"
              textAlign={isMaxMd ? "start" : "center"}
              tooltipAlign="end"
            />
          ),
          accessor: "colTotal",
          Cell: getCustomUnitCell({
            unit: getCurrency().symbol,
            unitStyle: { textType: "headerH3Neutral", ...defaultUnitStyle },
            valueStyle: { textType: "headerH3Neutral", ...defaultValueStyle },
          }),
          Footer: (
            <CustomUnitFooterCell
              amount={summary.total}
              {...cellOptions({
                unit: "currency",
                valueStyle: { textType: "headerH3Neutral", ...defaultValueStyle },
                unitStyle: { textType: "headerH3Neutral", ...defaultUnitStyle },
              })}
            />
          ),
        }
        // TODO prozatím schováno, dokud nebudeme mít historické zálohy
        // {
        //   Header: <Header headerKey="advance" tooltipKey="advance_tooltip" textAlign="center" tooltipAlign="end" />,
        //   accessor: "colAdvance",
        //   Cell: getCustomUnitCell(cellOptions({ unit: "currency" })),
        //   Footer: (
        //     <CustomUnitFooterCell
        //       amount={summary.advance}
        //       {...cellOptions({
        //         unit: "currency",
        //         valueStyle: { textType: "headerH3Neutral", ...defaultValueStyle },
        //         unitStyle: { textType: "headerH3Neutral", ...defaultUnitStyle },
        //       })}
        //     />
        //   ),
        // },
        // TODO schováno, dokud nebude zelený řádek
        // {
        //   Header: <Header headerKey="settlement" tooltipKey="settlement_tooltip" tooltipAlign="end" textAlign="end" />,
        //   accessor: "colSettlement",
        //   Cell: SettlementCell,
        //   Footer: (
        //     <CustomUnitFooterCell
        //       amount={summary.settlement}
        //       {...cellOptions({
        //         unit: "currency",
        //         valueStyle: { textType: getSettlementTextType(summary.settlement), ...defaultValueStyle },
        //         unitStyle: { textType: getSettlementTextType(summary.settlement), ...defaultUnitStyle },
        //       })}
        //     />
        //   ),
        // },
      )
    }

    return cols
  }, [t, summary, group, periodDescriptions, isMaxMd, type])

  return [columns, consumptionPeriodDescriptions, productionPeriodDescriptions] as const
}
