import * as React from "react"

function SvgQrCode(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_QRCode_svg__a">
          <path data-name="Rectangle 17217" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17105">
        <path data-name="Rectangle 17204" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 17110" clipPath="url(#_QRCode_svg__a)" fill="currentColor">
        <path data-name="Path 52404" d="M8.142 3H3v5.142h5.142zm-1.4 3.745H4.4V4.4h2.345z" />
        <path data-name="Path 52405" d="M3 21h5.142v-5.142H3zm1.4-3.745h2.345V19.6H4.4z" />
        <path data-name="Path 52406" d="M15.858 3v5.142H21V3zM19.6 6.745h-2.345V4.4H19.6z" />
        <path data-name="Path 52407" d="M12 7.5h2.25V3H12v2.25H9.75v4.5H12z" />
        <path data-name="Rectangle 17213" d="M9.75 14.25H12v2.25H9.75z" />
        <path data-name="Rectangle 17214" d="M3 12h2.25v2.25H3z" />
        <path data-name="Path 52408" d="M9.75 11.948V9.75h-4.5V12H7.5v2.25h2.25v-2.3z" />
        <path data-name="Path 52409" d="M12 12v2.25h2.25V12h2.25V9.75H12V12z" />
        <path data-name="Path 52410" d="M14.25 16.5H12v2.25H9.75V21h6.75v-2.25h-2.25z" />
        <path data-name="Path 52411" d="M16.5 14.25v4.5h2.25V16.5H21v-2.25h-2.25V12H16.5z" />
        <path data-name="Rectangle 17215" d="M18.75 9.75H21V12h-2.25z" />
      </g>
    </svg>
  )
}

export default SvgQrCode
