import * as Sentry from "@sentry/react"
import { ComponentType, useEffect } from "react"

Sentry.init({
  dsn: "https://f8a42040922a44578a706c55713eaabe@o31760.ingest.sentry.io/6252880",
  environment: process.env.ENVIRONMENT ?? "not-set",
  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb.type === "http" && breadcrumb.data?.url && breadcrumb.data?.url.length > 100) {
      breadcrumb.data.url = breadcrumb.data?.url.slice(0, 100) + "<ZKRACENO>"
    }

    return breadcrumb
  },
  enabled: process.env.NODE_ENV !== "development",
  release: process.env.SENTRY_RELEASE ?? "not-set",
  denyUrls: ["https://cdn.popupsmart.com/bundle.js"],
})

Sentry.configureScope((scope) => {
  scope.setTag("component", "nano-portal")
  scope.setTag("subcomponent", "client")
})

// TODO: hezkou error screenu
function ErrorScreen() {
  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <div>We are sorry, something went wrong. Please reload this page.</div>
    </div>
  )
}

export function SentryTest() {
  useEffect(() => {
    throw new Error("Sentry test error")
  }, [])

  return <div>sentry test</div>
}

export const withSentry = <P extends Record<string, unknown>>(Komponent: ComponentType<P>) => {
  function WithSentry(props: P) {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorScreen />} showDialog={false}>
        <Komponent {...props} />
      </Sentry.ErrorBoundary>
    )
  }

  return WithSentry
}
