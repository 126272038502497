import * as React from "react"

function SvgDepositChangeSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_DepositChangeSuccess_svg__a">
          <path data-name="Rectangle 512" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 1168" clipPath="url(#_DepositChangeSuccess_svg__a)">
        <path
          data-name="Path 372"
          d="M12.3 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.42 0 2.13.54 2.39 1.4a.925.925 0 00.87.7h.3a.949.949 0 00.9-1.27 4 4 0 00-2.96-2.54V4.5a1.5 1.5 0 00-3 0v.66C8.56 5.58 7 6.84 7 8.77c0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-1.65 0-2.5-.59-2.83-1.43a.985.985 0 00-.9-.67h-.28a.954.954 0 00-.89 1.3 4.5 4.5 0 003.4 2.53v.67a1.5 1.5 0 003 0v-.65c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4"
          fill="#00515f"
        />
        <path
          data-name="Path 373"
          d="M15.394 19.67l-1.956-1.956a.553.553 0 10-.79.775l.008.008L15 20.838a.558.558 0 00.788 0l5.923-5.918a.553.553 0 00-.782-.782z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgDepositChangeSuccess
