import { AppLayout } from "../components/layouts/appLayout/AppLayout"
import DeliveryPointContent from "../sections/deliveryPoint/DeliveryPointContent"
import DeliveryPointPlaceholder from "../components/placeholder/DeliveryPointPlaceholder"
import { useSafeNumberParam } from "../hooks/useSafeNumberParam"
import { useDeliveryPointFullDetail } from "../hooks/query/useDeliveryPointDetail"

const DeliveryPointWithData = () => {
  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const deliveryPointQuery = useDeliveryPointFullDetail(deliveryPointId)

  if (deliveryPointQuery.isLoading || !deliveryPointQuery.data) {
    //TODO až bude design, pokud budou chybět data nebo dojde k erroru, zde chceme renderovat error screen
    return <DeliveryPointPlaceholder />
  }

  return <DeliveryPointContent data={deliveryPointQuery.data} />
}

const DeliveryPoint = () => {
  return (
    <AppLayout>
      <DeliveryPointWithData />
    </AppLayout>
  )
}

export default DeliveryPoint
