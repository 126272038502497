import { useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"
import { InferQueryOutput } from "../../../api/trpc/trpcHelper"
import { Flex } from "../../../components/flex/Flex"
import InfoBox from "../../../components/info/InfoBox"
import { Spacer } from "../../../components/spacer/Spacer"
import Text from "../../../components/Text"
import { theme } from "../../../styles/stitches.config"
import { DueDateStateEnum, mapDueDateState } from "../../../utils/payments"
import TooltipDepositState from "./TooltipDepositState"

type DeliveryPointAdvancesState = InferQueryOutput<"deliveryPoints.deliveryPointPaymentState">["advancesState"]

type Props = {
  advancesState: DeliveryPointAdvancesState | undefined | null
  paymentsLink: string
}

export function HomeSupplyPointCardDepositState({ advancesState, paymentsLink }: Props) {
  const { t } = useTranslation(["common", "delivery_point"])

  const state = useMemo(() => {
    if (!advancesState || (advancesState && !advancesState.current)) {
      return { type: "noAdvances" }
    }

    if (advancesState.current && advancesState.current.amountToPair !== 0) {
      const { dueDate } = advancesState.current
      const dueDateState = mapDueDateState(dueDate)

      if (dueDateState.state === DueDateStateEnum.FUTURE) {
        return { type: "inFuture", days: dueDateState.days }
      }

      if (dueDateState.state === DueDateStateEnum.PAST) {
        return { type: "inPast", days: dueDateState.days }
      }
    }

    return { type: "ok" }
  }, [advancesState])

  if (state.type === "noAdvances" || !advancesState) {
    return (
      <Flex align="center" css={{ marginRight: theme.space.s6 }}>
        <Text type="headerH3Neutral">{t("common:no_advances")}</Text>
      </Flex>
    )
  }

  if (state.type === "ok" && advancesState) {
    return (
      <Flex align="center">
        <Text type="headerH3Positive">{t("common:in_order")}</Text>

        <Spacer size={theme.space.s2} />

        <InfoBox variant="success">
          <TooltipDepositState advancesState={advancesState} paymentsLink={paymentsLink} />
        </InfoBox>
      </Flex>
    )
  }

  if (state.type === "inFuture" && advancesState) {
    return (
      <Flex align="center">
        <Text type="headerH5Warning">
          <Trans t={t} i18nKey="delivery_point:supply_points_deposit_days_before_payment" count={state.days}>
            <Text type="headerH3Warning" />
          </Trans>
        </Text>

        <Spacer size={theme.space.s2} />

        <InfoBox variant="warning">
          <TooltipDepositState advancesState={advancesState} paymentsLink={paymentsLink} />
        </InfoBox>
      </Flex>
    )
  }

  if (state.type === "inPast" && advancesState) {
    return (
      <Flex align="center">
        <Text type="headerH5Negative">
          <Trans t={t} i18nKey="delivery_point:supply_points_deposit_days_after_payment" count={state.days}>
            <Text type="headerH3Negative" />
          </Trans>
        </Text>

        <Spacer size={theme.space.s2} />

        <InfoBox variant="error">
          <TooltipDepositState advancesState={advancesState} paymentsLink={paymentsLink} />
        </InfoBox>
      </Flex>
    )
  }

  return null
}
