import * as React from "react"

function SvgDate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 277" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 278"
        d="M9 11H7v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm2-7h-1V2h-2v2H8V2H6v2H5a1.991 1.991 0 00-1.99 2L3 20a2 2 0 002 2h14a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm0 16H5V9h14z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDate
