import { styled, theme } from "../../../../styles/stitches.config"
import StateContentContainer from "./StateContentContainer"

export const SUserDetailsContainer = styled("div", {
  padding: theme.space.s6,
})

export const SSwitchContainer = styled("div", {
  width: "stretch",
})

const duration = 150
const from = 100
const to = 0

export const SContainer = styled(StateContentContainer, {
  padding: `${theme.space.s4} ${theme.space.s6}`,
  gap: theme.space.s6,

  "&.menuState-enter": {
    transform: `translateX(${from}%)`,
  },
  "&.menuState-enter-active": {
    transform: `translateX(${to}%)`,
    transition: `transform ${duration}ms linear`,
  },
  "&.menuState-exit": {
    transform: `translateX(${to}%)`,
  },
  "&.menuState-exit-active": {
    transform: `translateX(${from}%)`,
    transition: `transform ${duration}ms linear`,
  },
})
