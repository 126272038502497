import * as Dropdown from "@radix-ui/react-dropdown-menu"

import { styled, theme } from "../../../../../styles/stitches.config"
import ScrollArea from "../../../../scrollarea/ScrollArea"
import { defaultRadixContentFadeInOutAnimation } from "../../../../../styles/utils"

export const STrigger = styled(Dropdown.Trigger, {
  border: "unset",
  outline: "unset",
  backgroundColor: theme.colors.transparent,

  marginLeft: theme.space.s21,
  minWidth: theme.space.s90,
  paddingInline: theme.space.s6,
  paddingTop: theme.space.s4,
  paddingBottom: theme.space.s4,
  height: "inherit",
  alignItems: "center",

  hover: {
    cursor: "pointer",
  },

  display: "none",
  "@lg": {
    display: "flex",
    justifyContent: "space-between",
  },
})

export const SContent = styled(Dropdown.Content, {
  minWidth: theme.space.s90,
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,

  ...defaultRadixContentFadeInOutAnimation,
})

export const SScrollableContent = styled(ScrollArea, {
  height: theme.space.s96,
})
