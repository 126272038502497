import { Navigate } from "react-router"
import { trpc } from "../../api/trpc/trpc"
import { Button } from "../../components/button/Button"
import { AppLayout } from "../../components/layouts/appLayout/AppLayout"
import Loader from "../../components/loader/Loader"
import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { resolveQueriesState } from "../../utils/queries"
import { LINKS } from "../../constants/links"
import Text from "../../components/Text"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { useTranslation } from "react-i18next"
import { DeliveryPointType } from "@nano-portal/shared"
import { SContentWrapper, SPlaceholderWrapper, ThankYouLabel } from "./Flexibility.styled"
import { Flex } from "../../components/flex/Flex"
import { useDeliveryPointDetail } from "../../hooks/query/useDeliveryPointDetail"
import { Advertisement } from "./Advertisement"

function Placeholder() {
  return (
    <SPlaceholderWrapper>
      <Loader />
    </SPlaceholderWrapper>
  )
}

export function Flexibility() {
  const { t } = useTranslation(["flexibility"])
  const apiContext = trpc.useContext()
  const myselfQuery = trpc.useQuery(["users.myself"])
  const deliveryPointId = useSafeNumberParam("deliveryPointId")
  const loginUrlQuery = trpc.useQuery(["flexibility.createProtheusLoginLink", { deliveryPointId }])
  const deliveryPointQuery = useDeliveryPointDetail(deliveryPointId)
  const applicationStatusQuery = trpc.useQuery([
    "flexibility.getProductionDataTariffApplicationStatus",
    { deliveryPointId },
  ])
  const onceLoggedInQuery = trpc.useQuery(["flexibility.hasDeliveryPointLoggedInToProteus", { deliveryPointId }])
  const requestProductionDataTariffMutation = trpc.useMutation(["flexibility.requestProductionDataTariff"], {
    onSuccess: async () => {
      await apiContext.invalidateQueries(["flexibility.getProductionDataTariffApplicationStatus", { deliveryPointId }])
    },
  })

  const { isError, isLoading } = resolveQueriesState([
    loginUrlQuery,
    myselfQuery,
    deliveryPointQuery,
    applicationStatusQuery,
    onceLoggedInQuery,
  ])

  if (
    isError ||
    isLoading ||
    !loginUrlQuery.data ||
    !myselfQuery.data ||
    !deliveryPointQuery.data ||
    !applicationStatusQuery.data ||
    !onceLoggedInQuery.data
  ) {
    return (
      <AppLayout>
        <SContentWrapper>
          <Placeholder />
        </SContentWrapper>
      </AppLayout>
    )
  }

  const productionData = deliveryPointQuery.data.typeData.get(DeliveryPointType.Production)
  const userShouldSeePage = (myselfQuery.data.proteusEnabled || !!productionData) && deliveryPointQuery.data.isActive

  if (!userShouldSeePage) {
    return <Navigate to={LINKS.home} />
  }

  const displayProteusLogin = myselfQuery.data.proteusEnabled || onceLoggedInQuery.data.hasLoggedInToProteus
  const displayProteusFirstLogin = applicationStatusQuery.data.status === "APPROVED"
  const applicationIsBeingProcessed = applicationStatusQuery.data.status === "PENDING"

  function renderFooter() {
    if (displayProteusLogin || displayProteusFirstLogin) {
      return (
        <Flex justify="center">
          <a href={loginUrlQuery.data} target="_blank" rel="noreferrer noopener">
            <Button>{t("flexibility:try_it_out")}</Button>
          </a>
        </Flex>
      )
    }

    if (applicationIsBeingProcessed) {
      return <ThankYouLabel>{t("flexibility:thank_you_submission")}</ThankYouLabel>
    }

    return (
      <>
        <Text type="headerH3">{t("flexibility:application_advertisement_label")}</Text>

        <Spacer size={theme.space.s6} />
        <Button
          css={{ width: "100%", "@sm": { width: 300 } }}
          isSubmitting={requestProductionDataTariffMutation.isLoading}
          onClick={() => requestProductionDataTariffMutation.mutate({ deliveryPointId })}
        >
          {t("flexibility:request_supplement")}
        </Button>
      </>
    )
  }

  return (
    <AppLayout>
      <SContentWrapper>
        <Advertisement />
        <Spacer size={theme.space.s9} />
        {renderFooter()}
        <Spacer size={theme.space.s50} />
      </SContentWrapper>
    </AppLayout>
  )
}
