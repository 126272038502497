import * as React from "react"

function SvgCheckbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 299" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 300"
        d="M18 19H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1zm1-16H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheckbox
