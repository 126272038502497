import * as React from "react"

function SvgNoSupplyPoints(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoSupplyPoints_svg__a">
          <path data-name="Rectangle 579" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9009" clipPath="url(#_NoSupplyPoints_svg__a)">
        <path
          data-name="Path 1951"
          d="M12.293 10.232L8.457 7.493a1.52 1.52 0 00-1.78 0l-3.836 2.739a1.531 1.531 0 00-.644 1.25v6.114a.769.769 0 00.767.767h3.068v-4.6h3.066v4.6h3.069a.769.769 0 00.767-.767v-6.113a1.531 1.531 0 00-.645-1.25"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1952"
          d="M16.003 12.226h-1.531v1.534h.567a2.112 2.112 0 01.324-.514 2.09 2.09 0 012.946-.228l.024.02.055.051.68.68.007-.007v-7.7a1.509 1.509 0 00-1.507-1.511h-6.955a1.509 1.509 0 00-1.51 1.511l.069.069c.061.038.123.069.184.107l3.836 2.739a3.087 3.087 0 011.181 1.711h1.63zm-1.534-4.6h1.534V9.16h-1.531z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1953"
          d="M15.363 17.482l.019-.022.05-.055.681-.681-.713-.713-.037-.043a2.1 2.1 0 01-.387-.671h-.5v3.069h.445a2.075 2.075 0 01.446-.884"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1954"
          d="M19.92 16.723l1.712-1.712a.608.608 0 000-.846.606.606 0 00-.853 0l-1.71 1.707-1.71-1.71-.013-.012a.603.603 0 00-.852.853l1.722 1.723-1.71 1.71-.012.013a.603.603 0 00.852.853l1.723-1.722 1.71 1.71.012.012a.603.603 0 00.853-.853z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgNoSupplyPoints
