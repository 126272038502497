import { useTranslation } from "react-i18next"
import SubSection from "../../../../components/subSection/SubSection"
import { FundamentalInfoData } from "./types"
import { styled } from "../../../../styles/stitches.config"
import InfoCell from "../infoCard/InfoCell"
import { formatEnergy } from "../../../../utils/format"
import InfoCellContainer from "../infoCard/InfoCellContainer"
import { Span } from "../../../../components/span/Span"
import { DeliveryPointType } from "@nano-portal/shared"
import { SubSectionTitle } from "../../../../components/subSection/SubSection.styled"
import InfoCardSeparator from "../infoCard/InfoCardSeparator"
import { GridInfoCard, SCard, SContainer } from "./FundamentalInfo.styled"

const EnergyVolumeCell = ({ header, volume }: { header: string; volume: number | null }) => {
  return <InfoCell label={header} value={volume} formatter={(value) => formatEnergy(value ?? 0)} />
}

export const EanCell = ({ eanEic }: { eanEic: string }) => {
  const { t } = useTranslation("delivery_point")
  return <InfoCell label={t("fundamental_info_ean_eic")} value={eanEic} />
}

export const EllipseSpan = styled(Span, { ellipsis: {} })

export const AddressCell = ({ street, town }: { street?: string; town?: string }) => {
  const { t } = useTranslation("delivery_point")
  return (
    <InfoCell
      label={t("fundamental_info_address")}
      value={
        <InfoCellContainer>
          <EllipseSpan>{`${street},${town}`}</EllipseSpan>
        </InfoCellContainer>
      }
    />
  )
}

export const FundamentalInfo = (props: FundamentalInfoData) => {
  const { t } = useTranslation("delivery_point")

  const isOnlyConsumption = props.deliveryPointType.every((type) => type === DeliveryPointType.Consumption)
  const isOnlyProduction = props.deliveryPointType.every((type) => type === DeliveryPointType.Production)
  const isCombined = !isOnlyConsumption && !isOnlyProduction

  function renderVolumeCells() {
    if (isOnlyConsumption) {
      return (
        <>
          <EnergyVolumeCell header={t("fundamental_info_consumption")} volume={props.consumption} />
        </>
      )
    }

    if (isOnlyProduction) {
      return (
        <>
          <EnergyVolumeCell header={t("fundamental_info_production")} volume={props.production} />
        </>
      )
    }

    return (
      <>
        <EnergyVolumeCell header={t("fundamental_info_consumption")} volume={props.consumption} />

        <EnergyVolumeCell header={t("fundamental_info_production")} volume={props.production} />
      </>
    )
  }

  function renderContent() {
    if (isCombined) {
      return (
        <GridInfoCard>
          <AddressCell street={props.addressStreet} town={props.addressTown} />
          <EanCell eanEic={props.eanEic} />

          {renderVolumeCells()}
        </GridInfoCard>
      )
    }

    return (
      <SContainer>
        <SCard>
          <AddressCell street={props.addressStreet} town={props.addressTown} />
          <InfoCardSeparator />
          <EanCell eanEic={props.eanEic} />
          <InfoCardSeparator />

          {renderVolumeCells()}
        </SCard>
      </SContainer>
    )
  }

  return (
    <SubSection
      width="max"
      title={<SubSectionTitle>{t("fundamental_info_header")}</SubSectionTitle>}
      content={renderContent()}
    />
  )
}
