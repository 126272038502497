import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { Flex } from "../../components/flex/Flex"
import { Spacer } from "../../components/spacer/Spacer"
import { theme } from "../../styles/stitches.config"
import { Div } from "../../components/div/Div"
import { PaymentMethod, PaymentState, PaymentType } from "@nano-portal/shared"
import { useIsMobileVersion } from "../../hooks/mediaHooks"
import Separator from "../../components/Separator"
import { InvoiceDetailPaymentSection } from "../../sections/invoiceDetail/InvoiceDetailPaymentSection"
import { InvoiceDetailInfoSection } from "../../sections/invoiceDetail/InvoiceDetailInfoSection"
import { mapDueDateState, mapDueDateStateStyle, usePaymentTypeToText } from "../../utils/payments"
import { Navigate } from "react-router"
import { LINKS } from "../../constants/links"
import { STitle } from "./InvoiceDetail.styled"

const InvoiceDetail = () => {
  // const paymentId = useSafeNumberParam("paymentId")
  const deliveryPointId = useSafeNumberParam("deliveryPointId")

  // return <InvoiceDetailWithData paymentId={paymentId} />
  return <Navigate to={LINKS.pdtPayments(deliveryPointId)} />
}

export default InvoiceDetail

type PaymentDetailProps = {
  dueDate: Date | null
  amount: number
  amountToPair: number
  accountNumber: string
  variableCode?: string | null
  qrPaymentCode: string
  paymentMethod: PaymentMethod
  paymentType: PaymentType
  paymentState: PaymentState
  paymentDate?: Date | null
}

export function PaymentDetail(props: PaymentDetailProps) {
  const isMobile = useIsMobileVersion()
  const paymentTypetoText = usePaymentTypeToText()
  const paymentTypeText = paymentTypetoText(props.paymentType)

  const dueDateState = mapDueDateState(props.dueDate)
  const dueDateStateStyle = mapDueDateStateStyle(dueDateState)

  const partialyPaid =
    props.paymentState === PaymentState.Unpaid && props.amountToPair > 0 && props.amountToPair < props.amount

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      justify="between"
      css={{ width: isMobile ? "100%" : theme.sizes.s192 }}
    >
      <Div>
        <STitle>{paymentTypeText}</STitle>
        <Spacer size={theme.space.s5} />
        <InvoiceDetailInfoSection
          amount={props.amount}
          dueDate={props.dueDate}
          paymentMethod={props.paymentMethod}
          dueDateStateStyle={dueDateStateStyle}
          amountToPair={props.amountToPair}
          partialyPaid={partialyPaid}
          paymentDate={props.paymentDate}
        />
      </Div>

      <Spacer size={theme.space.s6} />
      <Flex direction={isMobile ? "row" : "column"}>
        <Separator decorative orientation={isMobile ? "horizontal" : "vertical"} />
      </Flex>
      <Spacer size={theme.space.s6} />

      <InvoiceDetailPaymentSection
        paymentQrCodeString={props.qrPaymentCode}
        variableCode={props.variableCode}
        bankAccount={props.accountNumber}
      />
    </Flex>
  )
}
