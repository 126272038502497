import { Trans, useTranslation } from "react-i18next"
import { theme } from "../../styles/stitches.config"
import Text from "../Text"
import { StripeNotification } from "./StripeNotification"
import { Span } from "../span/Span"
import { useIsMobileVersion } from "../../hooks/mediaHooks"
import { WorkingOnFixNotification } from "./Notifications.styled"

export function WorkingOnDocumentsNotification() {
  const { t } = useTranslation(["notifications"])
  const isMobile = useIsMobileVersion()

  return (
    <StripeNotification
      css={{ cursor: isMobile ? "pointer" : undefined }}
      size={isMobile ? "medium" : "small"}
      type={"warning"}
      content={
        <WorkingOnFixNotification>
          <Text type="textsSmall" css={{ fontWeight: theme.fontWeights.regular }}>
            <Trans t={t} i18nKey={"notifications:working_on_historical_documents_import_notification_short"}>
              <Span css={{ color: theme.colors.warning, fontWeight: theme.fontWeights.semiBold }} />
            </Trans>
            {isMobile ? null : ` ${t("notifications:working_on_historical_documents_import_notification_description")}`}
          </Text>
        </WorkingOnFixNotification>
      }
    />
  )
}
