import { fontVariants, styled, theme } from "../../styles/stitches.config"
import { defaultRadixContentFadeInOutAnimation } from "../../styles/utils"
import { Flex } from "../flex/Flex"
import * as Popover from "@radix-ui/react-popover"
import * as HoverCard from "@radix-ui/react-hover-card"

export const Table = styled("table", {
  borderCollapse: "separate",
  borderSpacing: `0 1px`,

  width: "100%",

  boxShadow: theme.shadows.default,

  variants: {
    fixedLayout: {
      true: {
        tableLayout: "auto",

        "@sm": {
          tableLayout: "fixed",
        },
        false: {
          tableLayout: "auto",
        },
      },
    },
  },

  defaultVariants: {
    fixedLayout: false,
  },
})

export const Th = styled("th", {
  ...fontVariants.headerH5,
  height: theme.space.s8,
  verticalAlign: "middle",
  textAlign: "left",
  paddingRight: theme.space.s6,
  "&:first-of-type": {
    paddingLeft: theme.space.s6,
  },
  variants: {
    size: {
      default: {
        whiteSpace: "nowrap",
      },
      small: {
        maxWidth: theme.space.s32,
        fontSize: "10px",
        lineHeight: "14px",
        paddingTop: theme.space.s2,
        paddingBottom: theme.space.s2,
      },
    },
    alignment: {
      start: {
        textAlign: "start",
      },
      center: {
        textAlign: "center",
      },
      end: {
        textAlign: "end",
      },
    },
  },
  defaultVariants: {
    size: "default",
  },
})

export const Tr = styled("tr", {
  borderBottomWidth: theme.sizes.spx,
  borderBottomColor: theme.colors.lines,

  variants: {
    type: {
      default: {
        ...fontVariants.textsLarge,
        backgroundColor: theme.colors.white,
      },
      disabled: {
        ...fontVariants.textsLarge,
        backgroundColor: theme.colors.lines,
      },
      warning: {
        ...fontVariants.headerH3Warning,
        backgroundColor: theme.colors.warningLight,
      },
      negative: {
        ...fontVariants.headerH3Negative,
        backgroundColor: theme.colors.errorsLight,
      },
      success: {
        ...fontVariants.textsLarge,
        backgroundColor: theme.colors.white,
      },
      successLight: {
        ...fontVariants.textsLarge,
        backgroundColor: theme.colors.successesLight,
      },
    },
    size: {
      default: {
        height: theme.space.s12,
      },
      small: {
        fontSize: "14px",
        textAlign: "end",
        height: theme.space.s9,
      },
    },
    clickable: {
      true: {
        cursor: "pointer",
      },
    },
  },
  defaultVariants: {
    type: "default",
    size: "default",
  },
})

export const CellSubText = styled("span", {
  fontSize: theme.space.s3,
})

export const AmountCellContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "@md": {
    alignItems: "flex-end",
  },

  variants: {
    center: {
      true: {
        alignItems: "center",
      },
    },
  },
})

export const THead = styled("thead", {
  backgroundColor: theme.colors.textsDisabeled,
  boxShadow: theme.shadows.default,
})

export const TBody = styled("tbody", {})

export const Td = styled("td", {
  verticalAlign: "middle",
  paddingRight: theme.space.s6,
  "&:first-of-type": {
    paddingLeft: theme.space.s6,
  },

  variants: {
    paddingRight: {
      regular: {},
      large: {
        "&:last-of-type": {
          paddingRight: theme.space.s12,
        },
      },
    },
  },

  defaultVariants: {
    paddingRight: "regular",
  },
})

export const IconContainer = styled(Flex, {
  width: theme.sizes.s5,
  height: theme.sizes.s5,
  variants: {
    side: {
      left: {
        marginRight: theme.sizes.s2_5,
      },
      right: {
        marginLeft: theme.sizes.s2_5,
      },
    },
    size: {
      large: {
        width: theme.sizes.s6,
        height: theme.sizes.s6,
      },
    },
  },
})

export const ActionCellButton = styled(Flex, {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  cursor: "pointer",
})

export const STablePlaceholderContainer = styled("div", {
  paddingLeft: theme.sizes.s8,
  paddingRight: theme.sizes.s8,
  paddingBottom: theme.sizes.s16,
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

export const SHScrollWrapper = styled("div", {
  overflowX: "auto",
  boxShadow: theme.shadows.default,

  "& > *": {
    whiteSpace: "nowrap",
  },
})

export const SPopoverActionTrigger = styled("button", {
  all: "unset",

  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  cursor: "pointer",

  variants: {
    disabled: {
      true: {
        cursor: "default",
      },
      false: {
        cursor: "pointer",
      },
    },
  },

  defaultVariants: {
    disabled: false,
  },
})

export const SPopoverContent = styled(Popover.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  padding: theme.space.s4,
  marginTop: theme.space.s4,

  ...defaultRadixContentFadeInOutAnimation,
})

export const SPopoverArrow = styled(Popover.Arrow, {
  fill: theme.colors.white,
  width: theme.sizes.s5,
  height: theme.sizes.s3,
})

export const SHoverCardContent = styled(HoverCard.Content, {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.default,
  paddingTop: theme.space.s4,
  paddingBottom: theme.space.s4,
  paddingLeft: theme.space.s8,
  paddingRight: theme.space.s8,

  ...defaultRadixContentFadeInOutAnimation,
})

export const SHoverCardArrow = styled(HoverCard.Arrow, {
  fill: theme.colors.white,
  width: theme.sizes.s5,
  height: theme.sizes.s3,
})
