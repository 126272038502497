import * as HoverCard from "@radix-ui/react-hover-card"
import { styled, theme } from "../../styles/stitches.config"

export const InfoBoxTrigger = styled(HoverCard.Trigger, {
  height: theme.space.s4_5,
  width: theme.space.s4_5,
  flexShrink: 0,

  variants: {
    inline: {
      true: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    size: {
      regular: {
        height: theme.space.s4_5,
        width: theme.space.s4_5,
      },
      small: {
        height: theme.space.s4,
        width: theme.space.s4,
      },
    },
  },

  defaultVariants: {
    inline: "false",
    size: "regular",
  },
})

export const InfoBoxContent = styled(HoverCard.Content, {
  backgroundColor: theme.colors.white,
  filter: `drop-shadow(${theme.shadows.default})`,
  padding: theme.space.s6,
  maxWidth: `80vw`,
})

export const InfoBoxArrow = styled(HoverCard.Arrow, {
  fill: theme.colors.white,
  width: theme.space.s5,
  height: theme.space.s4,
})
