import * as React from "react"

function SvgNoInvoices(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="_NoInvoices_svg__a">
          <path data-name="Rectangle 580" fill="none" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 9011" clipPath="url(#_NoInvoices_svg__a)">
        <path
          data-name="Path 1955"
          d="M14.197 8.428H3.59a1.314 1.314 0 00-1.319 1.311v.014l-.006 7.948a1.32 1.32 0 001.315 1.325h10.608a1.326 1.326 0 001.325-1.316V9.753a1.32 1.32 0 00-1.316-1.325m-.008 8.611a.662.662 0 01-.662.662H4.252a.662.662 0 01-.662-.662v-3.312h10.6zm0-5.962H3.59V9.752h10.6z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1956"
          d="M16.829 12.41c.023 0 .046-.007.069-.007a1.592 1.592 0 011.133.469l.123.122V7.108a1.326 1.326 0 00-1.316-1.325H6.23a1.314 1.314 0 00-1.317 1.308v.012h11.916z"
          fill="#7ca7ad"
        />
        <path
          data-name="Path 1957"
          d="M19.909 16.721l1.717-1.717a.603.603 0 00-.856-.85l-1.715 1.713-1.716-1.716-.013-.012a.605.605 0 00-.855.857l1.728 1.728-1.716 1.715-.012.013a.605.605 0 00.855.857l1.729-1.73 1.715 1.716.012.012a.606.606 0 10.856-.857z"
          fill="#ffc200"
        />
      </g>
    </svg>
  )
}

export default SvgNoInvoices
