import * as React from "react"

function SvgArrowBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 21" d="M0 24V0h24v24z" fill="none" opacity={0.87} />
      <path
        data-name="Path 22"
        d="M14.71 15.88L10.83 12l3.88-3.88a1 1 0 10-1.41-1.41L8.71 11.3a1 1 0 000 1.41l4.59 4.59a1 1 0 001.41 0 1.017 1.017 0 000-1.42z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowBack
