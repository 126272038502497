import { fontVariants, styled, theme } from "../../styles/stitches.config"
import Text from "../Text"

export const SIconWrapper = styled("div", {
  width: theme.space.s4,
  height: theme.space.s4,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  // Safari hack, svg on safari is not rendered correctly if width and height are not set
  "& > svg": {
    width: theme.space.s4,
    height: theme.space.s4,
  },
})

export const SText = styled(Text, {})

export const SWrapper = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",

  padding: theme.space.s1,
  paddingLeft: theme.space.s3,
  paddingRight: theme.space.s3,

  borderRadius: theme.radii.default,

  variants: {
    variant: {
      primary: {
        backgroundColor: theme.colors.secondary,

        [`& ${SText}, & ${SIconWrapper}`]: {
          ...fontVariants.tagDark,
        },
      },
      secondary: {
        backgroundColor: theme.colors.primary,

        [`& ${SText}, & ${SIconWrapper}`]: {
          ...fontVariants.tagLight,
        },
      },
      tertiary: {
        backgroundColor: theme.colors.textsAlt,

        [`& ${SText}, & ${SIconWrapper}`]: {
          ...fontVariants.tagLight,
        },
      },
    },
    hasIcon: {
      true: {
        paddingLeft: theme.space.s2,
      },
    },
  },
})
