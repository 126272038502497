import { LINKS } from "../../../../constants/links"
import { SLink, SLogo } from "./TopMenuNanoEnergiesLogo.styled"

export default function TopMenuNanoEnergiesLogo() {
  return (
    <SLink to={LINKS.home}>
      <SLogo />
    </SLink>
  )
}
