import { ComponentProps, ReactNode, useMemo } from "react"
import { Column, useTable } from "react-table"
import { Table, THead, Th, Td, Tr, TBody, SHScrollWrapper } from "./Table.styled"
import { useIsMaxMd } from "../../hooks/mediaHooks"

export type TableColumn<T extends object = object> = Column<T>
export type TableRow<T extends object = object> = T & {
  rowType?: "default" | "disabled" | "warning" | "negative" | "success" | "successLight"
  onClick?: () => void
}
export type TableData<T extends object = object> = readonly TableRow<T>[]
export type ColumnAccessor<T extends object = object> = keyof T
export type ColumnAccessors<T extends object = object> = readonly ColumnAccessor<T>[]

interface Props<T extends object = object> {
  columns: ReadonlyArray<TableColumn<TableRow<T>>>
  data: TableData<T>
  minimalViewColumns?: ColumnAccessors<T>
  emptyPlaceholder?: ReactNode
  mergeColumns?: (keyof T)[]
  size?: "default" | "small"
  horizontallyScrollable?: boolean
  headerAlignment?: "start" | "center" | "end"
  fixedLayout?: boolean
  paddingRight?: ComponentProps<typeof Td>["paddingRight"]
}

function TableComponent<T extends object>({
  columns: _columns,
  data,
  minimalViewColumns,
  emptyPlaceholder,
  size,
  mergeColumns,
  horizontallyScrollable,
  headerAlignment,
  fixedLayout,
  paddingRight,
}: Props<T>) {
  const shrinkColumns = useIsMaxMd()

  const columns = useMemo(() => {
    if (shrinkColumns && minimalViewColumns) {
      return _columns.filter(({ accessor }) => accessor && minimalViewColumns.includes(accessor as ColumnAccessor))
    }

    return _columns
  }, [_columns, shrinkColumns, minimalViewColumns])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable<TableRow<T>>({
    columns,
    data,
  })

  const isTableEmpty = data.length === 0
  const showEmptyPlaceholder = isTableEmpty && emptyPlaceholder

  const renderTable = () => {
    /* eslint-disable react/jsx-key */
    // the jsx key is provided in the .get*Props() spreads, but eslint throws an error
    return (
      <>
        <Table {...getTableProps()} fixedLayout={fixedLayout}>
          <THead>
            {headerGroups.map((headerGroup) => {
              let skipHeader = false
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (skipHeader) {
                      skipHeader = false
                      return
                    }
                    let colSpan = 1
                    if (mergeColumns?.includes(column.id as keyof T)) {
                      colSpan++
                      skipHeader = true
                    }
                    return (
                      <Th {...column.getHeaderProps()} size={size} colSpan={colSpan} alignment={headerAlignment}>
                        {column.render("Header")}
                      </Th>
                    )
                  })}
                </tr>
              )
            })}
          </THead>
          <TBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)

              const rowCallback = row.original.onClick
              return (
                <Tr
                  {...row.getRowProps()}
                  type={row.original.rowType}
                  size={size}
                  onClick={rowCallback}
                  clickable={rowCallback != undefined}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td paddingRight={paddingRight} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </TBody>
          {columns.some((column) => !!column.Footer) && !isTableEmpty && (
            <>
              <tfoot>
                {footerGroups.map((group) => (
                  <Tr {...group.getFooterGroupProps()} type="disabled">
                    {group.headers.map((column) => (
                      <Td paddingRight={paddingRight} {...column.getFooterProps()}>
                        {column.render("Footer")}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </tfoot>
            </>
          )}
        </Table>
      </>
    )
  }

  if (horizontallyScrollable) {
    return (
      <>
        <SHScrollWrapper>{renderTable()}</SHScrollWrapper>
        {showEmptyPlaceholder && emptyPlaceholder}
      </>
    )
  }

  return (
    <>
      {renderTable()}
      {showEmptyPlaceholder && emptyPlaceholder}
    </>
  )
}

export default TableComponent
