import Circle from "../Circle"
import { Flex } from "../../../flex/Flex"
import { ColorType, theme } from "../../../../styles/stitches.config"
import Text from "../../../Text"

interface InfoFieldProps {
  amount: number
  isPrediction?: boolean
  formatter?: (amount: number) => string
  header: string
  circleVariant?: ColorType
}

export default function InfoField({ header, amount, formatter, isPrediction, circleVariant }: InfoFieldProps) {
  return (
    <Flex direction="column" align="end" css={{ gap: theme.space.s1 }}>
      {circleVariant ? (
        <Flex
          align="center"
          css={{
            gap: theme.space.s2,
          }}
        >
          <Text type="headerH5" css={{ textAlign: "end" }}>
            {header}
          </Text>
          <Circle color={circleVariant} opacity={isPrediction ? 0.3 : 1} />
        </Flex>
      ) : (
        <Text type="headerH5">{header}</Text>
      )}

      <Text type="textsLarge">{formatter ? formatter(amount) : amount}</Text>
    </Flex>
  )
}
