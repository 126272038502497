import { Div } from "../../components/div/Div"
import { Flex } from "../../components/flex/Flex"
import Text from "../../components/Text"
import { theme } from "../../styles/stitches.config"

type Props = {
  heading: string
  description: string
  isError?: boolean
}

export function Flexi2022ApiResponse(props: Props) {
  const { heading, description, isError } = props

  return (
    <Flex justify="center" align="center" css={{ fullheight: {} }}>
      <Div css={{ maxWidth: theme.space.s100, paddingLeft: theme.space.s8, paddingRight: theme.space.s8 }}>
        <Text type={isError ? "headerH3Negative" : "headerH3"} as="h3" css={{ textAlign: "center" }}>
          {heading}
        </Text>

        <Text type="textsSmall" as="p" css={{ textAlign: "center", lineHeight: 1.6 }}>
          {description}
        </Text>
      </Div>
    </Flex>
  )
}
