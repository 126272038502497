import { ComponentProps, memo } from "react"
import DeliveryPointDetails from "../DeliveryPointDetails"
import useSupplyPointNavigation from "../../../../../hooks/useSupplyPointNavigation"
import SelectItem from "./SelectItem"
import { SContainer } from "./SelectItemList.styled"
import Separator from "../../../../Separator"
import { Flex } from "../../../../flex/Flex"
import { theme } from "../../../../../styles/stitches.config"

interface Props {
  items: (ComponentProps<typeof DeliveryPointDetails> & { deliveryPointId: number })[]
  selectedEan: string
}

function SelectItemList({ items, selectedEan }: Props) {
  const navigate = useSupplyPointNavigation()

  return (
    <SContainer>
      {items.map((item) => (
        <Flex key={item.ean} direction={"column"} css={{ gap: theme.space.s1 }}>
          <SelectItem
            {...item}
            isSelected={item.ean === selectedEan}
            isDeliveryPointActive={item.isActive}
            onClick={navigate}
          />
          <Separator orientation="horizontal" decorative />
        </Flex>
      ))}
    </SContainer>
  )
}

export default memo(SelectItemList)
