import * as React from "react"

function SvgPhone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 51369" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 51370"
        d="M19.23 15.26l-2.54-.29a1.991 1.991 0 00-1.64.57l-1.84 1.84a15.045 15.045 0 01-6.59-6.59l1.85-1.85a1.991 1.991 0 00.57-1.64l-.29-2.52a2 2 0 00-1.99-1.77H5.03a1.985 1.985 0 00-2 2.07 16.992 16.992 0 0015.89 15.89 1.985 1.985 0 002.07-2v-1.73a1.982 1.982 0 00-1.76-1.98z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPhone
