import * as React from "react"

function SvgGrowattInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.747 15.265" {...props}>
      <defs>
        <clipPath id="_Growatt_inactive_svg__a">
          <path data-name="Rectangle 17262" fill="#c5d3d6" d="M0 0h62.747v15.265H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17670" clipPath="url(#_Growatt_inactive_svg__a)" fill="#c5d3d6">
        <path
          data-name="Path 52633"
          d="M8.057 0c.288.04.577.068.863.121a7.4 7.4 0 013.5 1.653c.13.111.252.233.381.346.069.061.072.113 0 .18q-.919.913-1.832 1.83c-.081.081-.127.045-.191-.015a4.51 4.51 0 00-2.542-1.23 4.586 4.586 0 00-4.03 1.351 4.556 4.556 0 00-1.353 3.669 4.692 4.692 0 004.037 4.283 4.638 4.638 0 004.248-1.6c.042-.046.078-.1.115-.147a.107.107 0 00.005-.032c-.054-.043-.119-.022-.178-.023H7.771c-.216 0-.219 0-.219-.216V7.739c0-.15.047-.2.2-.2h7.182c.14 0 .192.041.192.186v7.17c0 .15-.052.2-.2.2q-1.233-.007-2.466 0c-.143 0-.19-.049-.187-.191.008-.426 0-.852 0-1.278 0-.049.026-.116-.028-.142s-.081.036-.117.063A7.409 7.409 0 018.5 15.025a7.377 7.377 0 01-4.606-.892A7.462 7.462 0 01.355 9.841 6.721 6.721 0 01.024 8.15.657.657 0 000 8.044v-1a.727.727 0 00.024-.12 6.135 6.135 0 01.222-1.316A7.574 7.574 0 016.042.146C6.378.076 6.72.048 7.058 0z"
        />
        <path
          data-name="Path 52634"
          d="M35.374 12.736c.029-.088.06-.174.086-.261q.71-2.372 1.416-4.746a.225.225 0 01.26-.194q.679.015 1.358 0a.228.228 0 01.266.2q.649 2.446 1.311 4.888l.051.178c.076-.245.141-.449.2-.655q.664-2.2 1.323-4.4a.25.25 0 01.292-.217c.332.014.666 0 1 0 .2 0 .207.008.15.2l-.858 2.881c-.426 1.427-.855 2.855-1.275 4.285a.221.221 0 01-.253.186q-.787-.008-1.576 0c-.14 0-.2-.048-.233-.185q-.629-2.386-1.269-4.77l-.041-.147a.235.235 0 00-.066.14q-.706 2.364-1.406 4.733c-.049.166-.117.236-.3.231a39.839 39.839 0 00-1.546 0 .208.208 0 01-.237-.183q-.995-3.587-2-7.172c-.055-.2-.054-.2.153-.2h1.529a.188.188 0 01.215.166q.692 2.475 1.394 4.947c.008.029 0 .064.051.088"
        />
        <path
          data-name="Path 52635"
          d="M23.1 11.334a4.314 4.314 0 01.464-2.109 3.179 3.179 0 012.1-1.684 4.985 4.985 0 012.726.02 3.2 3.2 0 012.219 2.1 4.722 4.722 0 01-.037 3.4 3.206 3.206 0 01-2.326 2.044 5.025 5.025 0 01-2.56-.014 3.267 3.267 0 01-2.373-2.3 4.161 4.161 0 01-.213-1.454m2.17.036a3.392 3.392 0 00.034.563 3.152 3.152 0 00.474 1.4 1.491 1.491 0 002.069.375 1.969 1.969 0 00.688-1.085 4.589 4.589 0 00.159-1.8 3.5 3.5 0 00-.4-1.4 1.454 1.454 0 00-1.756-.708 1.481 1.481 0 00-.951.921 4.056 4.056 0 00-.315 1.728"
        />
        <path
          data-name="Path 52636"
          d="M50.535 11.365v1.73a2.276 2.276 0 00.039.4.5.5 0 00.559.431c.28 0 .28 0 .28.277v.717a.149.149 0 01-.118.173 3.311 3.311 0 01-1.682.085 1.239 1.239 0 01-.893-.752c-.074-.17-.106-.181-.242-.057a3.11 3.11 0 01-1.088.712 2.531 2.531 0 01-2.239-.161 2.029 2.029 0 01-.976-1.668 2.515 2.515 0 012.131-2.6 9.02 9.02 0 012.045-.183c.2 0 .208 0 .208-.21v-.577a.927.927 0 00-.883-1 3.99 3.99 0 00-2.049.262 5.053 5.053 0 00-.654.295c-.1.054-.14.038-.139-.081V8.035a.175.175 0 01.141-.189 7.545 7.545 0 012.69-.465 4.38 4.38 0 011.528.262 1.931 1.931 0 011.342 1.936c.005.6 0 1.195 0 1.793zm-1.973 1.074v-.624c0-.114-.046-.158-.159-.155-.239.006-.48-.008-.718.012a2.022 2.022 0 00-1.2.434 1.039 1.039 0 00-.277 1.178 1.015 1.015 0 001.018.574 2.3 2.3 0 001.2-.563.348.348 0 00.136-.311c-.008-.182 0-.364 0-.546"
        />
        <path
          data-name="Path 52637"
          d="M54.648 7.538h2.061c.147 0 .2.041.2.193-.009.3-.008.6 0 .9 0 .147-.042.2-.194.2-.421-.008-.843 0-1.265-.005-.131 0-.169.046-.168.173 0 1.195 0 2.39.005 3.585a2.889 2.889 0 00.064.556.869.869 0 00.855.728 2.266 2.266 0 00.683-.041c.117-.029.165 0 .164.124v.966a.138.138 0 01-.1.152 3.727 3.727 0 01-2.377 0 1.852 1.852 0 01-1.3-1.75c-.076-1.11-.017-2.222-.032-3.334q-.007-.5 0-1c0-.115-.039-.158-.154-.154-.176.007-.354 0-.531 0-.123 0-.163-.047-.162-.166.005-.317.008-.634 0-.951 0-.138.047-.18.182-.179.691.005 1.384 0 2.076 0"
        />
        <path
          data-name="Path 52638"
          d="M58.949 11.09V9.049c0-.219 0-.219-.226-.219-.156 0-.312-.005-.468 0-.123 0-.167-.05-.167-.167 0-.312.005-.623 0-.935 0-.15.055-.195.2-.195h3.574c.187 0 .375.005.562 0 .132 0 .172.05.17.175-.006.317-.007.634 0 .951 0 .13-.048.171-.174.17h-1.249c-.137 0-.18.042-.18.179.005 1.242 0 2.483.005 3.724a1.759 1.759 0 00.058.415.87.87 0 00.873.723 2.4 2.4 0 00.667-.042c.11-.025.154 0 .153.117v.966a.15.15 0 01-.125.166 3.864 3.864 0 01-2.214.036 1.88 1.88 0 01-1.425-1.7c-.075-.735-.017-1.474-.034-2.211v-.109"
        />
        <path
          data-name="Path 52639"
          d="M19.454 8.851a4.013 4.013 0 01.8-.917 2.49 2.49 0 011.8-.553c.127.01.183.062.181.2v1.5c0 .12-.034.157-.157.118a2.167 2.167 0 00-2.564 1 .48.48 0 00-.061.283v4.347c0 .267 0 .267-.268.268h-1.592c-.187 0-.2-.016-.2-.206V7.738c0-.15.051-.2.2-.195q.843.01 1.686 0c.135 0 .184.045.183.18-.006.378 0 .757 0 1.136"
        />
        <path
          data-name="Path 52640"
          d="M47.133 4.048a.563.563 0 01.335-.218.315.315 0 01.319.118c.062.092.1.07.162 0a.366.366 0 01.427-.1c.165.061.19.206.191.358v.669c0 .121-.055.149-.163.149s-.167-.026-.164-.147q.008-.265 0-.53c0-.1.038-.236-.126-.256-.12-.015-.259.13-.264.276q-.007.241 0 .483c0 .107-.015.176-.15.176s-.177-.046-.173-.173a7.977 7.977 0 000-.513c0-.1.032-.224-.12-.247-.105-.016-.265.133-.271.256a6.413 6.413 0 000 .5c0 .106-.011.179-.147.179s-.157-.061-.154-.173V4.03c0-.081-.015-.173.115-.176.107 0 .206 0 .183.19"
        />
        <path
          data-name="Path 52641"
          d="M37.331 4.849a.485.485 0 01-.336.177.414.414 0 01-.374-.186.809.809 0 01.045-.84.45.45 0 01.563-.117.146.146 0 00.177 0c.146-.094.265-.008.266.17 0 .255-.006.509 0 .763.018.579-.32.692-.768.645a1.552 1.552 0 01-.181-.044c-.1-.024-.1-.088-.079-.17.022-.1.08-.068.142-.051a.97.97 0 00.214.037c.27.009.361-.1.328-.379m.014-.47v-.093a.2.2 0 00-.189-.239.219.219 0 00-.246.17.588.588 0 00-.015.365.2.2 0 00.358.109c.108-.086.093-.2.092-.312"
        />
        <path
          data-name="Path 52642"
          d="M17.752 3.982c.041-.032.072-.06.106-.082a.407.407 0 01.6.147.783.783 0 01-.021.775.424.424 0 01-.576.184c-.117-.056-.134-.02-.127.084v.156c0 .18-.031.2-.216.19-.084 0-.126-.038-.125-.122v-1.34c0-.064.024-.114.1-.114.1 0 .226-.043.26.121m.446.458v-.092c-.023-.173-.109-.265-.236-.252a.257.257 0 00-.222.285v.248a.183.183 0 00.152.189.194.194 0 00.239-.094.586.586 0 00.071-.283"
        />
        <path
          data-name="Path 52643"
          d="M61.862 4.32c-.057.186-.12.37-.168.559-.03.118-.094.152-.21.154s-.163-.053-.191-.156c-.077-.285-.161-.569-.236-.855-.013-.05-.07-.137.027-.153s.216-.062.259.087a6.263 6.263 0 00.207.633c.058-.186.12-.373.173-.561.029-.1.061-.175.193-.174s.165.071.191.176c.045.181.1.36.15.553.068-.047.068-.114.085-.167.051-.157.1-.318.145-.476.029-.092.108-.091.18-.076.094.019.048.09.035.137-.087.3-.182.6-.265.9-.032.117-.106.13-.209.134a.16.16 0 01-.183-.144c-.046-.19-.1-.378-.15-.567h-.033"
        />
        <path
          data-name="Path 52644"
          d="M23.849 4.325c-.056.188-.117.375-.167.566-.03.113-.1.14-.2.141a.171.171 0 01-.195-.151c-.074-.286-.16-.569-.236-.856-.014-.052-.08-.143.037-.159.09-.012.207-.05.249.088.054.184.105.369.158.554a.118.118 0 00.051.087c.061-.2.124-.4.178-.6a.167.167 0 01.186-.144.165.165 0 01.184.146 3.249 3.249 0 00.192.578l.13-.425c.023-.074.043-.15.071-.223.035-.091.117-.084.186-.064s.038.086.025.132c-.089.3-.183.606-.269.911-.031.113-.108.118-.2.12s-.157-.024-.181-.125c-.048-.2-.1-.389-.156-.584h-.037"
        />
        <path
          data-name="Path 52645"
          d="M45.039 4.44a.6.6 0 11-1.2 0 .6.6 0 111.2 0m-.852 0a.7.7 0 00.033.229.223.223 0 00.22.163.214.214 0 00.216-.168.713.713 0 000-.457.225.225 0 00-.218-.17.236.236 0 00-.216.174.707.707 0 00-.032.229"
        />
        <path
          data-name="Path 52646"
          d="M58.83 3.827a.574.574 0 01.6.614.612.612 0 11-1.224-.009.573.573 0 01.624-.6m-.268.6a.555.555 0 00.031.217.23.23 0 00.449 0 .628.628 0 00-.018-.47.214.214 0 00-.2-.143.219.219 0 00-.207.14.517.517 0 00-.049.254"
        />
        <path
          data-name="Path 52647"
          d="M20.803 3.827a.564.564 0 01.609.614.608.608 0 11-1.215 0 .571.571 0 01.606-.619m-.249.606v.062c.009.213.087.326.229.335s.238-.081.276-.294a.544.544 0 00.009-.093c0-.224-.112-.4-.258-.408s-.257.149-.257.4"
        />
        <path
          data-name="Path 52648"
          d="M51.54 4.446a.563.563 0 01-.6.61.577.577 0 01-.612-.616.572.572 0 01.612-.613.555.555 0 01.6.619m-.851-.006a.564.564 0 00.045.259.2.2 0 00.192.134.209.209 0 00.216-.124.591.591 0 000-.54.222.222 0 00-.2-.131.226.226 0 00-.2.144.514.514 0 00-.045.258"
        />
        <path
          data-name="Path 52649"
          d="M34.082 4.04a.79.79 0 01.266-.188.332.332 0 01.46.259 4.834 4.834 0 01.012.823c0 .119-.1.091-.177.1s-.146-.01-.145-.114v-.545c0-.107.032-.246-.132-.272-.119-.018-.276.14-.282.281a5.955 5.955 0 000 .5c0 .115-.045.154-.157.154s-.174-.028-.172-.157c.005-.269 0-.539 0-.81 0-.079-.04-.194.1-.2.109-.006.25-.05.226.176"
        />
        <path
          data-name="Path 52650"
          d="M26.99 5.061a.621.621 0 01-.576-.277.674.674 0 010-.7.54.54 0 01.635-.234.514.514 0 01.322.562c-.009.084-.06.085-.119.085h-.437c-.146 0-.164.052-.1.173a.3.3 0 00.243.148.689.689 0 00.262-.024c.057-.016.137-.07.158.045a.13.13 0 01-.114.17l-.276.048m-.136-.734c.072-.017.184.037.182-.1 0-.107-.058-.187-.167-.183a.189.189 0 00-.185.194c-.008.131.106.073.17.092"
        />
        <path
          data-name="Path 52651"
          d="M41.988 5.055c-.276 0-.415-.127-.427-.378-.006-.13 0-.26 0-.39 0-.094.033-.2-.1-.249-.013 0-.019-.035-.023-.055-.027-.152.249-.393.4-.345a.061.061 0 01.051.067c-.013.129.056.162.169.157.058 0 .08.038.081.093s-.02.108-.086.1c-.153-.013-.169.075-.161.194.006.1 0 .2 0 .3 0 .143.015.278.208.282.052 0 .068.041.068.087s0 .087-.05.1a1.26 1.26 0 01-.124.033"
        />
        <path
          data-name="Path 52652"
          d="M29.465 4.02a.528.528 0 01.268-.18c.079-.018.154-.014.153.093 0 .078.048.179-.114.177-.216 0-.315.114-.315.334 0 .15-.005.3 0 .451.005.131-.074.137-.172.137s-.16-.017-.158-.135v-.856c0-.079-.023-.178.11-.179.106 0 .229-.033.226.16"
        />
        <path
          data-name="Path 52653"
          d="M56.157 4.021a.515.515 0 01.279-.186c.087-.017.148.008.145.107 0 .074.036.167-.112.167-.225 0-.314.105-.315.329 0 .14-.006.28 0 .42s-.038.177-.17.175-.163-.049-.16-.167c.005-.28 0-.56 0-.841 0-.078-.01-.166.11-.163.1 0 .225-.039.22.161"
        />
        <path
          data-name="Path 52654"
          d="M53.666 4.016a.514.514 0 01.285-.183c.091-.017.143.02.137.115 0 .069.035.156-.1.159-.247.005-.327.1-.328.351v.435c0 .145-.077.135-.173.137s-.159-.019-.157-.136v-.856c0-.079-.022-.177.111-.178.106 0 .229-.033.225.157"
        />
        <path
          data-name="Path 52655"
          d="M31.952 4.459v.45c0 .127-.087.117-.172.119s-.156-.007-.155-.12v-.932c0-.114.071-.12.156-.119s.173-.006.171.12v.481"
        />
        <path
          data-name="Path 52656"
          d="M31.807 3.673c-.109.006-.182-.008-.183-.137s.051-.153.16-.15.172.009.172.137-.049.161-.149.149"
        />
      </g>
    </svg>
  )
}

export default SvgGrowattInactive
