import * as React from "react"

function SvgNanoEnergiesLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 30" {...props}>
      <defs>
        <clipPath id="_NanoEnergiesLogo_svg__a">
          <path data-name="Rectangle 17654" transform="translate(0 -.033)" fill="none" d="M0 0h120v30H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17727">
        <path data-name="Path 59400" d="M26.52 26.915H3.543L15.032 3.03zM10.232 22.71h9.6l-4.8-9.98z" fill="#ffc200" />
        <g data-name="Group 17726">
          <g
            data-name="Group 17725"
            clipPath="url(#_NanoEnergiesLogo_svg__a)"
            fill="#00515f"
            transform="translate(0 .033)"
          >
            <path
              data-name="Path 59401"
              d="M58.593 15.778a6.451 6.451 0 001.337-4.117 7.156 7.156 0 00-.912-3.63 6.292 6.292 0 00-2.734-2.5 9.907 9.907 0 00-4.463-.912h-9.179v22.223h5.374V18.45h1.226l4.809 8.392h5.942l-5.28-8.738a6.826 6.826 0 003.88-2.326m-4.887-1.713a3.354 3.354 0 01-2.357.707h-3.333V9.147h3.332a3.227 3.227 0 012.342.755 2.771 2.771 0 01.77 2.074 2.729 2.729 0 01-.755 2.09"
            />
            <path
              data-name="Path 59402"
              d="M62.63 26.842h13.83v-4.306h-8.455v-4.934h7.512v-4.055h-7.512V8.895h8.455V4.621H62.63z"
            />
            <path
              data-name="Path 59403"
              d="M79.509 26.842h13.829v-4.306h-8.455v-4.934h7.512v-4.055h-7.512V8.895h8.455V4.621H79.509z"
            />
            <path
              data-name="Path 59404"
              d="M110.749 4.621v13.766l-8.984-13.766h-5.374v22.221h5.374V13.201l8.989 13.641h5.375V4.621z"
            />
            <path
              data-name="Path 59405"
              d="M31.802 10.108l2.215-4.532a11.276 11.276 0 00-12.972 2.162l2.452 5.1a6.241 6.241 0 018.3-2.731"
            />
            <path
              data-name="Path 59406"
              d="M30.454 18.244h4.337a6.252 6.252 0 01-6.944 3.637l1.7 3.543.744 1.547a11.316 11.316 0 009.275-7.083v-5.51H28.594z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgNanoEnergiesLogo
