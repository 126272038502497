import { trpc } from "../../../../../../api/trpc/trpc"
import { useMemo } from "react"
import { useNumberParam } from "../../../../../../hooks/useNumberParam"

export default function useDeliveryPointMenuData() {
  const deliveryPoints = trpc.useQuery(["deliveryPoints.list"])
  const deliveryPointId = useNumberParam("deliveryPointId")

  const selected = useMemo(() => {
    if (deliveryPoints.data) {
      return deliveryPoints.data.find((point) => point.deliveryPointId === deliveryPointId)
    }
  }, [deliveryPointId, deliveryPoints.data])

  return [selected, deliveryPoints] as const
}
