import * as React from "react"

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path data-name="Path 46" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 47"
        d="M12 2a10 10 0 1010 10A10 10 0 0012 2zM9.29 16.29L5.7 12.7a1 1 0 011.41-1.41L10 14.17l6.88-6.88a1 1 0 011.41 1.41l-7.59 7.59a1 1 0 01-1.41 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheck
