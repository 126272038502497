import { styled, theme } from "../../../../styles/stitches.config"

export const SInfoBar = styled("div", {
  display: "flex",
  flexDirection: "row",
  height: theme.space.s12,
  backgroundColor: theme.colors.secondary,
  alignItems: "center",
  justifyContent: "center",
})
