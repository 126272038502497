import { fontVariants, styled, theme } from "../../styles/stitches.config"

export const SPasswordButton = styled("button", {
  width: "100%",
  height: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "transparent",
  border: "none",

  cursor: "pointer",
})

export const SPasswordIcon = styled("svg", {
  width: theme.space.s6,
  height: theme.space.s6,

  ...fontVariants.disabledButtons,
})
