import { useTranslation } from "react-i18next"
import { toasts } from "./toasts"
import { captureException } from "@sentry/react"
import { TrpcError, isTRPCError } from "./types"
import { useCallback } from "react"
import { isProd } from "./env"

const TrpcAuthErrorCodes = new Set<TrpcError>(["UNAUTHORIZED", "FORBIDDEN"])
const TrpcErrorWhitelist = new Set(TrpcAuthErrorCodes)

const isTrpcErrorAndIsCodeInSet = (error: unknown, errorSet: Set<TrpcError>) => {
  return isTRPCError(error) && !!error.data?.code && errorSet.has(error.data?.code)
}

export const isAuthError = (error: unknown) => {
  return isTrpcErrorAndIsCodeInSet(error, TrpcAuthErrorCodes)
}

export const isWhitelistedError = (error: unknown) => {
  return isTrpcErrorAndIsCodeInSet(error, TrpcErrorWhitelist)
}

export const useGlobalErrorHandler = () => {
  const { t } = useTranslation(["common"])

  return useCallback(
    (error: unknown) => {
      if (isWhitelistedError(error)) {
        return
      }

      toasts.error(t("common:generic_error"))
      if (isProd()) {
        captureException(error, { extra: { globalErrorHandler: true } })
      }
    },
    [t]
  )
}
