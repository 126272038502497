import * as React from "react"

function SvgProduction(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <defs>
        <clipPath id="_Production_svg__a">
          <path data-name="Rectangle 17674" fill="none" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 17786">
        <path data-name="Path 61164" d="M57.966 42.859l-.011 1.86-25.876 15.04.01-1.86z" fill="#d0dadd" />
        <g data-name="Group 17787">
          <g data-name="Group 17786" clipPath="url(#_Production_svg__a)">
            <path
              data-name="Path 61165"
              d="M32.08 59.862a.107.107 0 01-.107-.107l.01-1.86a.107.107 0 01.053-.092l25.871-15.035a.107.107 0 01.16.093l-.011 1.86a.107.107 0 01-.053.092L32.132 59.848a.106.106 0 01-.054.015m.117-1.905l-.009 1.613 25.657-14.91.009-1.613z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61166" d="M32.09 57.894l-.011 1.86L6.039 44.72l.011-1.86z" fill="#d0dadd" />
            <path
              data-name="Path 61167"
              d="M32.079 59.862a.107.107 0 01-.053-.014L5.986 44.814a.107.107 0 01-.053-.093l.01-1.86a.107.107 0 01.16-.092l26.041 15.034a.107.107 0 01.053.093l-.011 1.86a.107.107 0 01-.107.106m-25.933-15.2l25.828 14.909.009-1.615L6.155 43.045z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61168" d="M57.961 42.86L32.09 57.894 6.05 42.86l25.871-15.035z" fill="#d0dadd" />
            <path
              data-name="Path 61169"
              d="M32.09 58.001a.107.107 0 01-.053-.014L5.997 42.953a.107.107 0 010-.185l25.871-15.035a.106.106 0 01.107 0l26.041 15.035a.107.107 0 010 .185L32.144 57.986a.106.106 0 01-.054.015M6.263 42.859L32.09 57.77l25.659-14.911-25.828-14.911z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 61170" d="M53.449 21.187l-.009 21.876-21.375 12.422.009-21.876z" fill="#fff" />
        <g data-name="Group 17789">
          <g data-name="Group 17788" clipPath="url(#_Production_svg__a)">
            <path
              data-name="Path 61171"
              d="M32.066 55.592a.107.107 0 01-.107-.107l.009-21.876a.107.107 0 01.053-.092l21.375-12.422a.107.107 0 01.16.092l-.009 21.876a.107.107 0 01-.053.092L32.119 55.577a.107.107 0 01-.054.014m.116-21.921l-.006 21.634 21.161-12.3.009-21.629z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61172"
              d="M32.074 33.609l-.009 21.876-21.514-12.421.009-21.876 10.741-4.405z"
              fill="#fff"
            />
            <path
              data-name="Path 61173"
              d="M32.065 55.592a.107.107 0 01-.053-.014L10.497 43.156a.107.107 0 01-.053-.092l.009-21.876a.107.107 0 01.066-.1l10.741-4.405a.107.107 0 01.13.041l10.774 16.827a.107.107 0 01.017.058l-.009 21.876a.106.106 0 01-.107.107m-21.408-12.59l21.3 12.3.009-21.66-10.709-16.725-10.591 4.347z"
              fill="#1d1d1b"
            />
          </g>
        </g>
        <path data-name="Path 61174" d="M32.017 33.609l21.428-12.46L42.723 4.245l-21.428 12.46z" fill="#00515f" />
        <g data-name="Group 17791">
          <g data-name="Group 17790" clipPath="url(#_Production_svg__a)">
            <path
              data-name="Path 61175"
              d="M32.017 33.716a.107.107 0 01-.09-.05l-10.721-16.9a.106.106 0 01.036-.149L42.67 4.157a.107.107 0 01.144.035l10.721 16.9a.107.107 0 01-.036.149l-21.428 12.46a.106.106 0 01-.054.014M21.445 16.742l10.607 16.727 21.245-12.356L42.688 4.389z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61176" d="M10.555 21.272l10.745-4.49 21.428-12.46-10.745 4.49z" fill="#00515f" />
            <path
              data-name="Path 61177"
              d="M10.555 21.379a.107.107 0 01-.054-.2L31.93 8.723l.012-.006 10.745-4.49a.107.107 0 01.095.191l-21.428 12.46-.012.006-10.745 4.49a.108.108 0 01-.041.008M32.031 8.911L12.018 20.546l9.235-3.859L41.268 5.05z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61178"
              d="M22.554 23.859c0 1.136-.805 1.6-1.789 1.033a3.97 3.97 0 01-1.789-3.1c0-1.136.805-1.6 1.789-1.033a3.97 3.97 0 011.789 3.1"
              fill="#00515f"
            />
            <path
              data-name="Path 61179"
              d="M21.555 25.243a1.722 1.722 0 01-.849-.258 4.079 4.079 0 01-1.843-3.192 1.2 1.2 0 011.949-1.126 4.079 4.079 0 011.843 3.192 1.355 1.355 0 01-.579 1.248 1.031 1.031 0 01-.522.135m-1.59-4.619a.82.82 0 00-.416.106 1.155 1.155 0 00-.472 1.063 3.843 3.843 0 001.736 3.007.988.988 0 001.629-.941 3.842 3.842 0 00-1.736-3.007 1.511 1.511 0 00-.741-.229"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61180"
              d="M21.453 24.743a3.97 3.97 0 01-1.789-3.1 1.3 1.3 0 01.462-1.115c-.675-.094-1.158.383-1.158 1.263a3.97 3.97 0 001.789 3.1 1.215 1.215 0 001.327.082 1.756 1.756 0 01-.631-.23"
              fill="#fff"
            />
            <path
              data-name="Path 61181"
              d="M21.556 25.241a1.724 1.724 0 01-.85-.256 4.079 4.079 0 01-1.843-3.192 1.456 1.456 0 01.426-1.139 1.042 1.042 0 01.853-.23.107.107 0 01.047.193 1.2 1.2 0 00-.417 1.028 3.842 3.842 0 001.736 3.007 1.65 1.65 0 00.592.217.107.107 0 01.047.193 1.014 1.014 0 01-.592.179m-1.69-4.611a.776.776 0 00-.436.185 1.271 1.271 0 00-.353.978 3.842 3.842 0 001.736 3.007 1.331 1.331 0 00.95.2 2.144 2.144 0 01-.36-.167 4.079 4.079 0 01-1.843-3.192 1.584 1.584 0 01.306-1.015"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61182"
              d="M26.478 18.236l6.677 10.626 7.25-4.275v-.406l-13.833-6.495z"
              fill="#ffc200"
            />
            <path
              data-name="Path 61183"
              d="M33.156 28.968a.107.107 0 01-.091-.05l-6.677-10.626a.107.107 0 01-.015-.075l.093-.549a.107.107 0 01.151-.079l13.833 6.495a.107.107 0 01.061.1v.406a.106.106 0 01-.053.092l-7.25 4.275a.106.106 0 01-.054.015m-6.565-10.756l6.6 10.5 7.108-4.191v-.278l-13.645-6.406z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61184" d="M26.571 17.686l6.677 10.626 7.157-4.131-6.707-10.591z" fill="#ffc200" />
            <path
              data-name="Path 61185"
              d="M33.248 28.419a.106.106 0 01-.09-.05l-6.677-10.626a.107.107 0 01.037-.149l7.127-4.1a.107.107 0 01.143.035l6.707 10.591a.107.107 0 01-.037.15l-7.157 4.131a.107.107 0 01-.053.014m-6.527-10.7l6.563 10.453 6.971-4.024-6.592-10.41z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61186"
              d="M36.811 26.355a.107.107 0 01-.09-.05l-6.717-10.594a.107.107 0 11.18-.114l6.717 10.594a.107.107 0 01-.09.164"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61187"
              d="M32.294 26.874a.107.107 0 01-.053-.2l7.106-4.1a.107.107 0 11.107.185l-7.106 4.1a.108.108 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61188"
              d="M30.37 23.838a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 01.107.185l-7.1 4.1a.107.107 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61189"
              d="M27.528 19.271a.107.107 0 01-.053-.2l7.088-4.093a.107.107 0 01.107.185l-7.088 4.093a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61190"
              d="M28.526 20.78a.107.107 0 01-.053-.2l7.059-4.076a.107.107 0 11.107.185l-7.059 4.076a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61191"
              d="M29.455 22.3a.107.107 0 01-.053-.2l7.072-4.083a.107.107 0 01.107.185l-7.072 4.083a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61192"
              d="M31.348 25.344a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 11.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61193"
              d="M35.486 13.372l6.68 10.629 7.25-4.275v-.406l-13.833-6.495z"
              fill="#ffc200"
            />
            <path
              data-name="Path 61194"
              d="M42.159 24.105a.107.107 0 01-.091-.05l-6.677-10.626a.107.107 0 01-.015-.075l.093-.549a.107.107 0 01.151-.079l13.833 6.495a.107.107 0 01.061.1v.406a.107.107 0 01-.053.092l-7.25 4.275a.106.106 0 01-.054.015m-6.565-10.756l6.6 10.5 7.108-4.191v-.278l-13.641-6.405z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61195" d="M35.578 12.823l6.677 10.626 7.157-4.131-6.707-10.59z" fill="#ffc200" />
            <path
              data-name="Path 61196"
              d="M42.256 23.556a.107.107 0 01-.091-.05L35.488 12.88a.107.107 0 01.037-.149l7.127-4.1a.107.107 0 01.143.035l6.707 10.595a.107.107 0 01-.037.15l-7.156 4.13a.106.106 0 01-.053.014m-6.528-10.694l6.563 10.445 6.971-4.024-6.592-10.41z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61197"
              d="M45.814 21.493a.107.107 0 01-.09-.05l-6.717-10.594a.107.107 0 01.18-.114l6.717 10.594a.107.107 0 01-.09.164"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61198"
              d="M41.298 22.011a.107.107 0 01-.053-.2l7.106-4.1a.107.107 0 11.107.185l-7.106 4.1a.107.107 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61199"
              d="M39.377 18.975a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 11.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61200"
              d="M36.535 14.408a.107.107 0 01-.054-.2l7.088-4.093a.107.107 0 11.107.185l-7.088 4.093a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61201"
              d="M37.533 15.917a.107.107 0 01-.053-.2l7.059-4.076a.107.107 0 11.107.185l-7.06 4.075a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61202"
              d="M38.457 17.437a.107.107 0 01-.053-.2l7.072-4.083a.107.107 0 01.107.185l-7.072 4.083a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61203"
              d="M40.355 20.481a.107.107 0 01-.053-.2l7.1-4.1a.107.107 0 01.107.185l-7.1 4.1a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 61204" d="M41.053 43.026l5.457-3.151v-9.437l-5.462 3.135z" fill="#d0dadd" />
            <path
              data-name="Path 61205"
              d="M41.052 43.13a.107.107 0 01-.107-.107l-.005-9.452a.107.107 0 01.053-.093l5.462-3.135a.107.107 0 01.16.093v9.437a.107.107 0 01-.053.092l-5.457 3.151a.106.106 0 01-.053.014m.1-9.5l.005 9.206 5.243-3.027v-9.19z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61206" d="M46.509 39.872v-9.445l-.909.519v9.45z" fill="#fff" />
            <path
              data-name="Path 61207"
              d="M45.6 40.503a.107.107 0 01-.107-.107v-9.45a.107.107 0 01.054-.093l.909-.519a.107.107 0 01.16.093v9.445a.107.107 0 01-.053.092l-.91.524a.107.107 0 01-.053.014m.108-9.494v9.2l.7-.4v-9.2zm.8 8.863z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61208" d="M41.965 44.068l5.453-3.151v-.525l-5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 61209"
              d="M41.965 44.179a.106.106 0 01-.107-.107v-.527a.106.106 0 01.053-.092l5.453-3.149a.107.107 0 01.16.092v.525a.107.107 0 01-.053.092l-5.453 3.151a.107.107 0 01-.053.014m.107-.572v.281l5.239-3.025v-.278z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61210" d="M41.965 44.073v-.527l-.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 61211"
              d="M41.965 44.179a.106.106 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-.526a.107.107 0 01.16-.093l.912.523a.107.107 0 01.054.093v.527a.107.107 0 01-.107.107m-.8-.693l.7.4v-.281l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61212" d="M41.964 43.545l5.453-3.149-1.818-1.05-4.553 2.63.005 1.045z" fill="#fff" />
            <path
              data-name="Path 61213"
              d="M41.964 43.652a.107.107 0 01-.053-.014l-.912-.523a.107.107 0 01-.054-.092l-.005-1.045a.107.107 0 01.053-.093l4.553-2.63a.107.107 0 01.107 0l1.818 1.05a.107.107 0 010 .185l-5.453 3.149a.106.106 0 01-.053.014m-.806-.692l.806.462 5.24-3.026-1.6-.926-4.446 2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Line 1100" fill="#fff" d="M43.411 32.241v8.399" />
            <path
              data-name="Path 61214"
              d="M43.411 40.748a.107.107 0 01-.107-.107v-8.4a.107.107 0 01.214 0v8.4a.107.107 0 01-.107.107"
              fill="#1d1d1b"
            />
            <path data-name="Line 1101" fill="#ffc200" d="M45.599 35.161l-4.552 2.613" />
            <path
              data-name="Path 61215"
              d="M41.047 37.881a.107.107 0 01-.053-.2l4.552-2.613a.107.107 0 01.106.185L41.1 37.866a.106.106 0 01-.053.014"
              fill="#1d1d1b"
            />
            <path data-name="Path 61216" d="M23.008 49.846l-5.457-3.151.062-13.074 5.462 3.135z" fill="#d0dadd" />
            <path
              data-name="Path 61217"
              d="M23.008 49.949a.107.107 0 01-.053-.014l-5.457-3.151a.107.107 0 01-.053-.093l.062-13.074a.107.107 0 01.16-.092l5.462 3.135a.107.107 0 01.054.093l-.068 13.096a.107.107 0 01-.107.106m-5.35-3.319l5.244 3.028.066-12.843-5.249-3.013z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61218" d="M17.552 46.696v-13.12l.909.519V47.22z" fill="#fff" />
            <path
              data-name="Path 61219"
              d="M18.461 47.327a.107.107 0 01-.053-.014l-.91-.524a.107.107 0 01-.053-.092v-13.12a.107.107 0 01.16-.093l.909.519a.107.107 0 01.054.093v13.125a.107.107 0 01-.107.107m-.8-.693l.7.4V34.157l-.7-.4z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61220" d="M22.096 50.896l-5.453-3.151v-.525l5.453 3.149z" fill="#fff" />
            <path
              data-name="Path 61221"
              d="M22.096 51.003a.108.108 0 01-.053-.014l-5.453-3.151a.107.107 0 01-.053-.092v-.525a.107.107 0 01.16-.092l5.453 3.149a.106.106 0 01.053.092v.527a.107.107 0 01-.107.107m-5.346-3.319l5.24 3.028v-.281l-5.24-3.025z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61222" d="M22.096 50.893v-.527l.912-.523v.526z" fill="#fff" />
            <path
              data-name="Path 61223"
              d="M22.098 51.004a.107.107 0 01-.107-.107v-.527a.107.107 0 01.054-.093l.912-.523a.107.107 0 01.16.093v.526a.107.107 0 01-.053.092l-.91.524a.108.108 0 01-.053.014m.107-.572v.281l.7-.4v-.279z"
              fill="#1d1d1b"
            />
            <path
              data-name="Path 61224"
              d="M22.096 50.369l-5.453-3.149 1.818-1.05 4.553 2.63-.005 1.045z"
              fill="#fff"
            />
            <path
              data-name="Path 61225"
              d="M22.096 50.476a.107.107 0 01-.053-.014l-5.453-3.149a.107.107 0 010-.185l1.818-1.05a.107.107 0 01.107 0l4.553 2.63a.107.107 0 01.053.093l-.005 1.045a.107.107 0 01-.054.092l-.912.523a.106.106 0 01-.053.014m-5.24-3.255l5.24 3.026.806-.462v-.922l-4.446-2.568z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61226" d="M19.374 41.865a.416.416 0 11.415-.416.416.416 0 01-.415.416" fill="#fff" />
            <path
              data-name="Path 61227"
              d="M19.373 41.973a.522.522 0 11.522-.522.523.523 0 01-.522.522m0-.831a.309.309 0 10.309.309.309.309 0 00-.309-.309"
              fill="#1d1d1b"
            />
            <path data-name="Path 61228" d="M20.464 38.225l-6.411-3.728 3.208-3.511 6.411 3.728z" fill="#00515f" />
            <path
              data-name="Path 61229"
              d="M20.464 38.332a.106.106 0 01-.054-.015l-6.411-3.728a.107.107 0 01-.025-.164l3.208-3.511a.107.107 0 01.132-.02l6.411 3.728a.107.107 0 01.025.164l-3.207 3.516a.106.106 0 01-.079.035m-6.243-3.86l6.222 3.618 3.06-3.35-6.222-3.618z"
              fill="#1d1d1b"
            />
            <path data-name="Path 61230" d="M20.464 38.226l3.208-3.511v1.662z" fill="#fff" />
            <path
              data-name="Path 61231"
              d="M20.464 38.332a.107.107 0 01-.079-.179l3.208-3.511a.107.107 0 01.186.072v1.662a.107.107 0 01-.053.092l-3.208 1.849a.107.107 0 01-.053.014m3.1-3.343l-2.558 2.8 2.558-1.474z"
              fill="#1d1d1b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgProduction
