import { Navigate, useParams } from "react-router"
import { useSafeNumberParam } from "../../hooks/useSafeNumberParam"
import { LINKS } from "../../constants/links"
import { trpc } from "../../api/trpc/trpc"
import { useEffectOnce } from "react-use"
import LoadingPage from "../Loading"
import { toasts } from "../../utils/toasts"
import { useDownloadSpotDataSheetMutation } from "../../utils/xlsx"
import { useTranslation } from "react-i18next"
import { eanSchema } from "@nano-portal/customers"

function InvoiceSpotDataContent({ paymentId, ean }: { paymentId: number; ean: string }) {
  const { t } = useTranslation(["payments"])

  const sheetDownloadMutation = useDownloadSpotDataSheetMutation()
  const getInvoiceSpotDataMutation = trpc.useMutation("spotData.invoiceSpotData")

  useEffectOnce(() => getInvoiceSpotDataMutation.mutate({ paymentId: String(paymentId), ean }))

  if (getInvoiceSpotDataMutation.isError) {
    let errorMessage = "Při exportu do formátu XLSX došlo k chybě."
    switch (getInvoiceSpotDataMutation.error.data?.code) {
      case "BAD_REQUEST":
        errorMessage = t("payments:invoice_attachment_spot_data_bad_request")
        break
      case "UNAUTHORIZED":
        errorMessage = t("payments:invoice_attachment_spot_data_unauthorized")
        break
      case "NOT_FOUND":
        errorMessage = t("payments:invoice_attachment_spot_data_not_found")
        break
      default:
        break
    }

    toasts.error(errorMessage)
    return <Navigate to={LINKS.home} />
  }

  if (sheetDownloadMutation.isError) {
    toasts.error(t("payments:invoice_attachment_spot_data_download_error"))
    return <Navigate to={LINKS.home} />
  }

  if (getInvoiceSpotDataMutation.isLoading || !getInvoiceSpotDataMutation.data || sheetDownloadMutation.isLoading) {
    return <LoadingPage />
  }

  const spotData = getInvoiceSpotDataMutation.data
  sheetDownloadMutation.mutate(spotData)

  const deliveryPointId = spotData.find((contractData) => contractData.ean === ean)?.deliveryPointId
  return <Navigate to={LINKS.pdtPayments(deliveryPointId)} />
}

export function InvoiceSpotData() {
  const { t } = useTranslation(["payments", "validation"])
  const paymentId = useSafeNumberParam("paymentId")
  const { ean } = useParams<{ ean: string }>()
  if (!ean) {
    toasts.error(t("payments:invoice_attachment_spot_data_ean_not_present"))
    return <Navigate to={LINKS.home} />
  }

  const eanValidationResult = eanSchema.safeParse(ean)
  if (!eanValidationResult.success) {
    toasts.error(t("validation:ean_invalid"))
    return <Navigate to={LINKS.home} />
  }

  return <InvoiceSpotDataContent ean={eanValidationResult.data} paymentId={paymentId} />
}
